import { Component, OnInit, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { RidiciMaticeItems } from 'src/app/Utils/Vyroba/ridici-matice-items.utils';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'app-vyroba-matice-polozky',
  templateUrl: './vyroba-matice-polozky.component.html',
  styleUrls: ['./vyroba-matice-polozky.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => VyrobaMaticePolozkyComponent),
    multi: true
  }]
})
export class VyrobaMaticePolozkyComponent implements OnInit, ControlValueAccessor {

  polozky: SelectItem[] = [];
  polozkyMaterialy: SelectItem[] = [];
  polozkyProstredky: SelectItem[] = [];
  enableMaterialy: boolean = false;
  enableProstredky: boolean = false;

  // Proměnné pro implementaci rozhraní ControlValueAccessor
  onModelChange: any = () => { };
  onModelTouched: any = () => { };
  disabled: boolean = false;

  @Input('value') _value: string[] = null;

  get value(): string[] {
    return this._value;
  }

  set value(val: string[]) {
    this._value = val;
    this.onModelChange(val);
    this.onModelTouched();

    this.enableMaterialy = (val != undefined && val.indexOf("Materialy") >= 0);
    this.enableProstredky = (val != undefined && val.indexOf("Prostredky") >= 0);
  }

  constructor() { }

  // Implementace rozhraní ControlValueAccessor
  writeValue(obj: any): void {
    this.value = obj;
  }

  // Implementace rozhraní ControlValueAccessor
  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  // Implementace rozhraní ControlValueAccessor
  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }

  // Implementace rozhraní ControlValueAccessor
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit(): void {
    for (var key in RidiciMaticeItems.Items) {
      var item: SelectItem = { value: key, label: RidiciMaticeItems.Items[key].popis };

      if (key.startsWith("Materialy")) {
        this.polozkyMaterialy.push(item);
      }
      else if (key.startsWith("Prostredky")) {
        this.polozkyProstredky.push(item);
      }
      else {
        this.polozky.push(item);
      }
    }
  }

  onGroupChange(group: string, checked: boolean): void {
    this["enable" + group] = checked;

    if (checked) {
      Object.keys(RidiciMaticeItems.Items).filter(k => k.startsWith(group)).forEach(k => {
        if (this.value.indexOf(k) < 0) {
          this.value.push(k);
        }
      });
    }
    else {
      this.value = this.value.filter(x => !x.startsWith(group));
    }
  }

  vse(): void {
    this.value = Object.keys(RidiciMaticeItems.Items);
    this.enableMaterialy = true;
    this.enableProstredky = true;
  }

  nic(): void {
    this.value = [];
    this.enableMaterialy = false;
    this.enableProstredky = false;
  }
}
