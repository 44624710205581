<p-toast position="top-center" key="vyroba-pruvodni-listy-toast"></p-toast>
<app-block-ui [block]="block"></app-block-ui>
<div class="pl-body">
  <p-toolbar styleClass="base-toolbar">
    <div class="p-toolbar-group-left">
      <h2 class="toolbar-label">Editace průvodních listů</h2>
      <i class="p-toolbar-separator"><fa-icon [icon]="faEllipsisV"></fa-icon></i>
      <button pButton type="button" label="Nový" class="icon-btn" (click)="add()"><fa-icon [icon]="faPlus"></fa-icon></button>
      <div style="display: inline-block">
        <div class="p-inputgroup">
          <input type="text" pInputText placeholder="Hledat" [(ngModel)]="filter" style="width: 20em" />
          <button pButton type="button" class="icon-btn hide-label-button" (click)="filter = undefined" [disabled]="filter == undefined"><fa-icon [icon]="faBackspace"></fa-icon></button>
        </div>
      </div>
      <button pButton type="button" label="Smazat" class="icon-btn p-button-danger" (click)="remove()" [disabled]="dataPl==undefined||dataPl.guid==undefined"><fa-icon [icon]="faMinus"></fa-icon></button>
    </div>
    <div class="p-toolbar-group-right">
      <button pButton type="button" label="Zpět" class="icon-btn" (click)="back()"><fa-icon [icon]="faUndo"></fa-icon></button>
    </div>
  </p-toolbar>

  <div class="p-grid p-align-stretch pl-main">
    <div class="p-col-5">
      <p-panel>
        <ng-template pTemplate="header">
          <div class="p-grid p-align-center">
            <div class="p-col panel-title"><span class="p-panel-title">Průvodní listy</span></div>
          </div>
        </ng-template>
        <div class="pl-data">
          <div class="pl-table-wrapper">
            <propla-table #pruvodniTable [cols]="cols" [allItems]="items" [selectionMode]="'single'" [filterGlobal]="filter"
                          (rowSelected)="onRowSelected($event)" (rowUnSelected)="onRowUnSelected($event)" (dataLoaded)="dataLoaded()"></propla-table>
          </div>
        </div>
      </p-panel>
    </div>
    <div class="p-col-7">
      <p-panel>
        <ng-template pTemplate="header">
          <div class="p-grid p-align-center">
            <div class="p-col panel-title"><span class="p-panel-title">Detail průvodního listu</span></div>
            <div class="p-col right" *ngIf="dataPl != undefined">
              <button pButton type="button" label="Uložit" class="icon-btn p-button-success" (click)="save()"><fa-icon [icon]="faSave"></fa-icon></button>
              <button pButton type="button" label="Uložit kopii" class="icon-btn p-button-success" (click)="saveCopy()" [disabled]="dataPl?.guid==undefined"><fa-icon [icon]="faCopy"></fa-icon></button>
              <button pButton type="button" label="Zrušit" class="icon-btn" (click)="cancel()"><fa-icon [icon]="faTimes"></fa-icon></button>
            </div>
          </div>
        </ng-template>
        <div class="pl-data" *ngIf="dataPl != undefined">
          <div style="height:50%" [formGroup]="formDataPl">
            <div class="p-grid" style="height: 100%;">
              <div class="p-col-12 cislo-pl">
                <label for="pl-cislo" style="width: 200px">Ćíslo průvodního listu</label>
                <input name="cislo" type="text" pInputText formControlName="cisloPl" id="pl-cislo" />
              </div>
              <div class="p-col-12" style="height: calc(100% - 40px);">
                <p-panel>
                  <ng-template pTemplate="header">
                    <div class="p-grid p-align-center">
                      <div class="p-col panel-title"><span class="p-panel-title">Řádky průvodního listu</span></div>
                      <div class="p-col right" *ngIf="dataPl != undefined">
                        <button pButton type="button" icon="pi pi-plus" (click)="addRadek()"></button>
                        <button pButton type="button" icon="pi pi-minus" class="p-button-danger" (click)="delRadek(i)"></button>
                      </div>
                    </div>
                  </ng-template>
                  <div class="pl-table-wrapper">
                    <p-table [value]="dataPl.radky" [styleClass]="'pds-table-compact'" selectionMode="single"
                             compareSelectionBy="equals" [scrollable]="true" scrollHeight="flex"
                             (onRowSelect)="onRowDetailSelected($event)" [(selection)]="dataRadek">
                      <ng-template pTemplate="header">
                        <tr>
                          <th style="width:5%" title="Číslo řádku">Č.</th>
                          <th style="width:20%">ČUJ</th>
                          <th style="width:8%">Dřev.</th>
                          <th style="width:8%">Typ SM</th>
                          <th style="width:16%">Množ.</th>
                          <th style="width:8%">Kg</th>
                          <th style="width:35%">Poznámka</th>
                        </tr>
                      </ng-template>
                      <ng-template pTemplate="body" let-row let-rowIndex="rowIndex">
                        <tr [pSelectableRow]="row" [pSelectableRowIndex]="rowIndex">
                          <td style="width:5%">{{row.cisloRadku}}</td>
                          <td style="width:20%">{{row.cisloUj}}</td>
                          <td style="width:8%"><app-ciselnik-kod [ciselnik]="'CVyrDrevina'" [guid]="row.drevinaGuid"></app-ciselnik-kod></td>
                          <td style="width:8%"><app-ciselnik-kod [ciselnik]="'CSazenice'" [guid]="row.sazeniceGuid"></app-ciselnik-kod></td>
                          <td style="width:16%">{{row.mnozstvi}}</td>
                          <td style="width:8%">{{row.kg?'Ano':'Ne'}}</td>
                          <td style="width:35%">{{row.poznamka}}</td>
                        </tr>
                      </ng-template>
                    </p-table>
                  </div>
                </p-panel>
              </div>
            </div>
          </div>
          <div style="height:50%" [formGroup]="formDataRadek">
            <p-card *ngIf="dataRadek">
              <div class="p-grid">
                <div class="p-col-6">
                  <div class="p-grid">
                    <div class="p-col-12">
                      <label for="pl-cuj">Číslo uznané jednotky</label>
                      <input #cisloUj name="cuj" type="text" pInputText formControlName="cisloUj" id="pl-cuj" />
                    </div>
                    <div class="p-col-6">
                      <label for="pl-drevina-dropdown">Dřevina</label>
                      <app-ciselnik-dropdown ciselnik="CVyrDrevina" formControlName="drevinaGuid" nullable="true" [virtualScroll]="true"
                                             id="pl-drevina-dropdown"></app-ciselnik-dropdown>
                    </div>
                    <div class="p-col-6">
                      <label for="pl-sazenice-dropdown">Typ sadebního materiálu</label>
                      <app-ciselnik-dropdown ciselnik="CSazenice" formControlName="sazeniceGuid" [virtualScroll]="true"
                                             nullable="true" id="pl-sazenice-dropdown"></app-ciselnik-dropdown>
                    </div>
                    <div class="p-col-6">
                      <label for="pl-mnozstvi">Množství</label>
                      <input name="mnozstvi" type="text" pInputText formControlName="mnozstvi" id="pl-mnozstvi" />
                    </div>
                    <div class="p-col-6">
                      <label>KG</label>
                      <p-dropdown [options]="kgOptions" label="label" styleClass="pds-ciselnik-dropdown" [style]="{'width':'100%'}"
                                  appendTo="body" formControlName="kg"></p-dropdown>
                    </div>
                    <div class="p-col-12">
                      <label for="pl-poznamka">Poznámka</label>
                      <input name="poznamka" type="text" pInputText formControlName="poznamka" id="pl-poznamka" />
                    </div>
                  </div>
                </div>
                <div class="p-col-6">
                  <p-panel>
                    <ng-template pTemplate="header">
                      <div class="p-grid p-align-center">
                        <div class="p-col panel-title"><span class="p-panel-title">Organizační úroveň</span></div>
                        <div class="p-col right">
                          <button pButton type="button" icon="pi pi-plus" (click)="addOrgUr()"></button>
                        </div>
                      </div>
                    </ng-template>
                    <div class="p-grid">
                      <div class="p-col-12">
                        <div formArrayName="orgUrGuids">
                          <div *ngFor="let orgurGuid of orgUrGuids.controls;let i = index;" [formGroup]="orgurGuid" [ngClass]="'orgur'">
                            <app-org-ur formControlName="orgurGuid" [ngClass]="'orgur-ciselnik'"></app-org-ur>
                            <button pButton type="button" icon="pi pi-minus" class="p-button-secondary" (click)="delOrgUr(i)"></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </p-panel>
                </div>
              </div>
            </p-card>
          </div>
        </div>
      </p-panel>
    </div>
  </div>
</div>

<ng-template #colDatum let-rowData let-col="column">
  {{rowData[col.field] | date:'d.M.yyyy'}}
</ng-template>

<p-confirmDialog #deleteConfirmDlg header="Smazat" icon="pi pi-exclamation-triangle">
  <p-footer>
    <button type="button" pButton icon="pi pi-times" label="Ne" (click)="deleteConfirmDlg.reject()"></button>
    <button type="button" pButton icon="pi pi-check" label="Ano" (click)="deleteConfirmDlg.accept()"></button>
  </p-footer>
</p-confirmDialog>


