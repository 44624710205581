import { Component, OnInit, ViewChild, TemplateRef, ElementRef } from '@angular/core';
import { faUndo, faSave, faPlus, faEllipsisV, faCopy, faBackspace, faTimes, faMinus } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { DPruvodniListListDto } from 'src/app/Dto/Vyroba/DPruvodniListListDto';
import { PruvodniListService } from 'src/app/Services/Vyroba/pruvodni-list.service';
import { MessagesUtils } from 'src/app/Utils/Shared/messages.utils';
import { DPruvodniListDetailDto } from 'src/app/Dto/Vyroba/DPruvodniListDetailDto';
import { DPruvodniListRadekDetailDto } from 'src/app/Dto/Vyroba/DPruvodniListRadekDetailDto';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { UuidUtils } from 'src/app/Utils/Shared/uuid.utils';
import { MessageService, ConfirmationService } from 'primeng/api';
import { TableComponent } from '../../Shared/table/table.component';

export interface IKgOption {
  value: boolean,
  label: string
}

@Component({
  selector: 'app-vyroba-pruvodni-listy',
  templateUrl: './vyroba-pruvodni-listy.component.html',
  styleUrls: ['./vyroba-pruvodni-listy.component.css']
})
export class VyrobaPruvodniListyComponent implements OnInit {

  block: boolean = false;
  filter: string;
  items: DPruvodniListListDto[];//pole dto pro grid PL
  dataPl: DPruvodniListDetailDto;//vybraný detail PL
  dataRadek: DPruvodniListRadekDetailDto;//vybraný řádek
  cols: any[];//sloupce gridu PL
  aktivniRadekIdx: number = 0;//index aktivního řádku
  private _clicked: boolean = false;//je potřeba odlišit změnu ve formgroup od nastavení při změně řádku

  @ViewChild("cisloUj") cisloUj: ElementRef;
  @ViewChild("pruvodniTable", { static: true }) table: TableComponent;


  private readonly MESSAGE_KEY: string = "vyroba-pruvodni-listy-toast";

  //ikony
  faPlus = faPlus;
  faSave = faSave;
  faUndo = faUndo;
  faCopy = faCopy;
  faEllipsisV = faEllipsisV;
  faBackspace = faBackspace;
  faTimes = faTimes;
  faMinus = faMinus;

  kgOptions: IKgOption[] = [
    { value: false, label: 'Ne' },
    { value: true, label: 'Ano' }
  ];

  formDataRadek: FormGroup = this.formBuilder.group({
    guid: [''],
    cisloRadku: [''],
    cisloUj: [''],
    drevinaGuid: [''],
    sazeniceGuid: [''],
    mnozstvi: [''],
    kg: [''],
    poznamka: [''],
    orgUrGuids: this.formBuilder.array([])
  });

  //editace čisla pl
  formDataPl: FormGroup = this.formBuilder.group({
    cisloPl: ['']
  });

  get orgUrGuids() {
    return (this.formDataRadek.get('orgUrGuids') as FormArray);
  }

  @ViewChild("colDatum", { static: true }) datumTemplate: TemplateRef<any>;

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private pruvodniListService: PruvodniListService,
    private messageUtils: MessagesUtils,
    private uuidUtils: UuidUtils,
    private confirmationService: ConfirmationService) { }

  ngOnInit(): void {
    this._initCols();
    this._loadItemns();

    this.formDataPl.valueChanges.subscribe(change => {
      if (this.dataPl != void 0 && !this._clicked) {
        this.dataPl.cisloPl = change.cisloPl;
      }
    });

    this.formDataRadek.valueChanges.subscribe(change => {
      if (this.dataPl != void 0 && !this._clicked) {
        let radekToUpdate = this.dataPl.radky[this.aktivniRadekIdx];
        if (radekToUpdate != void 0) {
          radekToUpdate.cisloRadku = change.cisloRadku * 1;
          radekToUpdate.cisloUj = change.cisloUj;
          radekToUpdate.drevinaGuid = change.drevinaGuid;
          radekToUpdate.guid = change.guid;
          radekToUpdate.kg = change.kg;
          radekToUpdate.mnozstvi = change.mnozstvi * 1;
          radekToUpdate.orgUrGuids = change.orgUrGuids;
          radekToUpdate.poznamka = change.poznamka;
          radekToUpdate.sazeniceGuid = change.sazeniceGuid;
          radekToUpdate.orgUrGuids = change.orgUrGuids.filter(x => x.orgurGuid != "").map(x => x.orgurGuid);//musí se odfiltrovat nevyplněné org ur
          this.dataPl.radky[this.aktivniRadekIdx] = radekToUpdate;
        }
      }
    });
  }

  /**
   * Načte položky gridu PL
   * */
  private _loadItemns(): void {
    this.pruvodniListService.list().subscribe(res => {
      if (res.success) {
        this.items = res.data;
      } else {
        this.messageUtils.showResponseMessage(this.MESSAGE_KEY, res);
      }
    });
  }

  /**
  * Inicializace sloupců pro grid PL
  */
  private _initCols() {
    this.cols = [
      { field: 'cisloPl', header: 'Číslo', class: 'col-24' },
      { field: 'vlozil', header: 'Vytvořil', class: 'col-25' },
      { field: 'vlozilKdy', header: 'Kdy vloženo', class: 'col-13', template: this.datumTemplate },
      { field: 'opravil', header: 'Opravil', class: 'col-25' },
      { field: 'opravilKdy', header: 'Kdy opraveno', class: 'col-13', template: this.datumTemplate }
    ];
  }


  /**
   *  Přidá novou položku PL
   * */
  add(): void {
    this._vymazRadek();
    this.dataPl = new DPruvodniListDetailDto();
    this.dataPl.radky = [];
    this.formDataPl.reset();
    this.focusFirst();
  }

  /**
   * Vrátí se do seznamu výroby
   * */
  back(): void {
    this.router.navigate(["/vyroba"]);
  }

  /**
   * Výběr detailu PL
   * @param dto
   */
  onRowSelected(dto: DPruvodniListListDto): void {
    this.pruvodniListService.get(dto.guid).subscribe(res => {
      if (res.success) {
        this.dataPl = res.data;
        this.formDataPl.patchValue(this.dataPl);
        if (res.data?.radky.length > 0) {
          this.onRowDetailSelected({ data: res.data?.radky[0], index: 0 });
        } else {
          //pokud neexistují řádky, tak promazat.
          this._vymazRadek();
        }
      } else {
        this.messageUtils.showResponseMessage(this.MESSAGE_KEY, res);
      }
    });
  }

  /**
   * Zrušení výběru řádku
   * @param dto
   */
  onRowUnSelected(dto: DPruvodniListListDto): void {
    this.cancel();
  }

  /**
   * Promaže aktuální řádek
   * */
  private _vymazRadek(): void {
    this.dataRadek = null;
    this.formDataRadek.reset();
    this.orgUrGuids.reset();
  }

  /**
   * Výběr řádku detailu PL
   * @param dto
   */
  onRowDetailSelected(event): void {
    this._clicked = true;
    this.focusFirst();
    this.aktivniRadekIdx = event.index;
    var dto: DPruvodniListRadekDetailDto = event.data;
    this.dataRadek = dto;
    if (dto != void 0) {
      this.formDataRadek?.patchValue(dto);
      this._setFormOrgUrArray(this.orgUrGuids, dto.orgUrGuids);
    }
    this._clicked = false;
  }

  /**
   * Přidá řádek detailu
   * */
  addRadek(): void {
    this.onRowDetailSelected({ data: this._createEmptyRowDto(), index: this.dataPl.radky.length });
    this.dataPl.radky.push(this.dataRadek);
    this.focusFirst();
  }

  /**
   * Smaže řádek detailu
   * */
  delRadek(): void {
    var i = this.dataPl.radky.indexOf(this.dataRadek);

    // úprava pole
    var array = this.dataPl.radky;
    array.splice(i, 1);

    //přepíše čísla řádku
    var cisloRadku = 1;
    array.forEach(function (v) {
      v.cisloRadku = cisloRadku++;
    });

    this.dataPl.radky = array; //... naopak potřebuju přiřadit do kolekce, aby to grid pochopil

    if (i >= this.dataPl.radky.length) i--; // pokud jsem smazal poslední řádek, chci vybrat poslední co zůstal

    this.dataRadek = this.dataPl.radky[i];
    this.onRowDetailSelected({ data: this.dataRadek, index: i });

    //pokud se smaže poslední řádek, tak promazat i detail řádku
    if (this.dataPl.radky.length == 0)
      this._vymazRadek();
  }

  /**
   *  Přidá prázdný řádek org ur
   * */
  addOrgUr(): void {
    this.dataRadek.orgUrGuids.push("");
    this._setFormOrgUrArray(this.orgUrGuids, this.dataRadek.orgUrGuids);
  }

  /**
   * Smaže vybranou org ur
   * @param i
   */
  delOrgUr(i): void {
    let selectedGuid = this.orgUrGuids.at(i).value;
    this.dataRadek.orgUrGuids = this.dataRadek.orgUrGuids.filter(x => x != selectedGuid.orgurGuid);
    this.orgUrGuids.removeAt(i);
  }

  /**
   * Vytvoří nový řádek detailu
   * */
  private _createEmptyRowDto(): DPruvodniListRadekDetailDto {
    let emptyRowDto = new DPruvodniListRadekDetailDto();
    emptyRowDto.guid = this.uuidUtils.GenerateUuid();
    emptyRowDto.kg = false;
    emptyRowDto.cisloRadku = Math.max(...this.dataPl.radky.map(o => o.cisloRadku), 0) + 1;
    emptyRowDto.cisloUj = null;
    emptyRowDto.drevinaGuid = null;
    emptyRowDto.guid = null;
    emptyRowDto.mnozstvi = null;
    emptyRowDto.orgUrGuids = [""];
    emptyRowDto.poznamka = null;
    emptyRowDto.sazeniceGuid = null;
    return emptyRowDto;
  }

  /**
   * Naplnění formArray org ur v modelu formuláře.
   * @param formArray
   * @param data
   */
  private _setFormOrgUrArray(formArray: FormArray, data: any[]): void {
    if (data.length > 0) {
      formArray.clear();
      data.forEach(orgurGuid => formArray.push(this.formBuilder.group({ orgurGuid })));
    }
  }

  /**
   * Uloží změny
   * */
  save(): void {
    this.pruvodniListService.saveOrUpdate(this.dataPl).subscribe(res => {
      if (res.success) {
        this.dataPl.guid = res.data.guid;
        this._loadItemns();
      }
      this.messageUtils.showResponseMessage(this.MESSAGE_KEY, res);
    });
  }

  /**
   * Uloží kopii PL. TODO - ukládání padá na chybě
   * */
  saveCopy(): void {
    this.dataPl.guid = undefined;
    this.pruvodniListService.saveOrUpdate(this.dataPl).subscribe(res => {
      if (res.success) {
        this.dataPl.guid = res.data.guid;
        this._loadItemns();
      }
      this.messageUtils.showResponseMessage(this.MESSAGE_KEY, res);
    });
  }

  /**
   * Zruší všechny změny
   * */
  cancel(): void {
    this._vymazRadek();
    this.dataPl = null;
    this.table.selectedRows = null;
  }

  /**
   * Po zobrazení panelu s gridem focus na první položku detailu
   * */
  focusFirst(): void {
    setTimeout(() => { if (this.cisloUj != void 0) this.cisloUj.nativeElement.focus(); });
  }

  /**
   * Smaže vybraný řádek
   * */
  remove(): void {
    this.confirmationService.confirm({
      message: 'Opravdu si přejete smazat vybraný řádek?',
      accept: () => {
        this.block = true;
        this.pruvodniListService.delete(this.dataPl.guid)
          .subscribe(resp => {
            if (resp.success) {
              resp.messages = ["Průvodní list " + this.dataPl.cisloPl + " byl smazán."];
              this.dataPl = null;
              this._vymazRadek();
              this.table.selectedRows = null;
              this._loadItemns();
            }
            this.messageUtils.showResponseMessage(this.MESSAGE_KEY, resp);
            this.block = false;
          });

      }
    });
  }

  /**
  * Zavolá se po načtení tabulky a vybere řádek
  * */
  dataLoaded(): void {
    this.table.selectRowByGuid(this.dataPl?.guid); 
  }
}
