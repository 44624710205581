import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faSearch, faCopy, faPencilAlt, faTrash, faBook, faTimes, faEllipsisV, faObjectUngroup, faCheckCircle, faCut } from '@fortawesome/free-solid-svg-icons';
import { faFolder } from '@fortawesome/free-regular-svg-icons';
import GeometryType from 'ol/geom/GeometryType';
import { NacrtMapItemDto, NacrtMapItemPostSaveDto } from '../../../Dto/Nacrty/NacrtMapItemDto';
import { ExtentUtils } from '../../../Utils/Mapa/extent.utils';
import { NacrtyService } from '../../../Services/Nacrty/nacrty.service';
import { MessagesUtils } from '../../../Utils/Shared/messages.utils';
import { ConfirmationService } from 'primeng/api';
import { NacrtProjektSourceDto } from '../../../Dto/Nacrty/NacrtProjectSourceDto';
import { MapInteractionService } from '../../../Services/Mapa/map-interaction.service';
import { NacrtyInteractionService } from '../../../Services/Nacrty/nacrty-interaction.service';
import { RoleUtils } from '../../../Utils/Shared/role.utils';
import { RoleEnum } from '../../../Dto/Core/RoleEnum';
import { ISchvalWorkflowDto } from '../../../Dto/Nacrty/ISchvalWorkflowDto';
import { NacrtSchvalWorkflowDto } from '../../../Dto/Nacrty/NacrtSchvalWorkflowDto';
import { NacrtySchvalitInteractionService } from '../../../Services/Nacrty/nacrty-schvalit-interaction.service';
import { ConstructMapLayerUtils } from '../../../Utils/Mapa/construnct-map-layer.utils';
import { MultiPolygon } from 'ol/geom';
import { Subscription } from 'rxjs';


/**
 * Komponenta pro zobrazení vybraných mapových položek z modulu Náčrty.
**/
@Component({
  selector: 'app-map-info-nacrt',
  templateUrl: './map-info-nacrt.component.html',
  styleUrls: ['./map-info-nacrt.component.css'],
  providers: [NacrtyInteractionService]
})
export class MapInfoNacrtComponent implements OnInit, OnDestroy {

  /**
   * Data náčrtu.
  **/
  _item: NacrtMapItemDto = null;
  @Input() set item(value: NacrtMapItemDto) {
    if (value != void 0) {
      this._item = value;
      this._setIsValidatable();
    }
  }


  /**
   * Seznam dostupných projektů náčrtu. Předává se editační komponentě.
  **/
  _nacrtProjects: NacrtProjektSourceDto[] = [];
  @Input() set nacrtProjects(value: NacrtProjektSourceDto[]) {
    if (value != void 0) {
      this._nacrtProjects = value;
      this._setIsValidatable();
    }
  }


  constructor(
    private router: Router,
    private extentUtils: ExtentUtils,
    private nacrtService: NacrtyService,
    private confirmationService: ConfirmationService,
    private messageUtils: MessagesUtils,
    private roleUtils: RoleUtils,
    private mapInteractionService: MapInteractionService,
    private nacrtySchvalitInteractionService: NacrtySchvalitInteractionService,
    private constructLayerUtils: ConstructMapLayerUtils)
  {
    
  }

  readonly TOAST_KEY: string = 'mapContainerToastKey';
  readonly CONFIRM_KEY: string = 'nacrtDeleteConfirm';
  faSearch = faSearch;
  faCopy = faCopy;
  faPencilAlt = faPencilAlt;
  faTrash = faTrash;
  faFolder = faFolder;
  faBook = faBook;
  faTimes = faTimes;
  faEllipsisV = faEllipsisV;
  faObjectUngroup = faObjectUngroup;
  faCheckCircle = faCheckCircle;
  faCut = faCut;
  disabledBtn: boolean = false;
  unselectDisabled: boolean = false;
  isValidatable: boolean = false;
  private _discardChangesSubs: Subscription;
  private _nacrtFinidsedSubs: Subscription;
  private _editFeatureSubs: Subscription;
  private _copySubs: Subscription;
  private _drawStartSubs: Subscription;
  private _schvalpostSubs: Subscription;
  private _deactivateBtnSubs: Subscription;


  ngOnInit() {
    this._deactivateBtnSubs = this.mapInteractionService.DeactivateButtons.subscribe(deactivate => this.disabledBtn = deactivate);
    this._discardChangesSubs = this.mapInteractionService.DiscardChanges.subscribe(_ => this.unselectDisabled = false);
    this._nacrtFinidsedSubs = this.mapInteractionService.NacrtFinished.subscribe(this.onNacrtFinished.bind(this));
    this._editFeatureSubs = this.mapInteractionService.EditFeature.subscribe(_ => this.unselectDisabled = true);
    this._copySubs = this.mapInteractionService.Copy.subscribe(_ => this.unselectDisabled = false);
    this._drawStartSubs = this.mapInteractionService.DrawStart.subscribe(_ => this.unselectDisabled = false);
    this._schvalpostSubs = this.nacrtySchvalitInteractionService.SchvaleniPostprocess.subscribe(this._schvalitPostProcess.bind(this));
  }


  ngOnDestroy() {
    this._deactivateBtnSubs.unsubscribe();
    this._discardChangesSubs.unsubscribe();
    this._nacrtFinidsedSubs.unsubscribe();
    this._editFeatureSubs.unsubscribe();
    this._copySubs.unsubscribe();
    this._drawStartSubs.unsubscribe();
    this._schvalpostSubs.unsubscribe();
  }


  /**
   * Přiblížení na extent vybraného náčrtu.
   */
  zoomTo(): void {
    this.mapInteractionService.zoomTo(this.extentUtils.addBuffer(this.extentUtils.getExtent(this._item.wkt)));
  }

  /**
   * Kopie náčrtu.
   */
  copy(): void {
    if (this.canCopy) {
      this.mapInteractionService.copy(this._item);
    }
  }


  /**
   * Editace náčrtu.
   */
  edit(): void {
    if (this.canEdit) {
      this.mapInteractionService.editFeature(this._item);
    }
  }


  /**
   * Handler ukončení editace náčrtu.
   * @param event {NacrtMapItemPostSaveDto} data náčrtu
   */
  onNacrtFinished(event: NacrtMapItemPostSaveDto): void {
    this.unselectDisabled = false;
    if (event.id == this._item.id) {
      this._item = event;
      this._setIsValidatable();
    }
  }

  /**
   * Smazání náčrtu.
   */
  delete(event): void {
    if (this.canDelete) {
      this.confirmationService.confirm({
        target: event.currentTarget,
        key: this.CONFIRM_KEY,
        message: 'Opravdu smazat náčrt?',
        icon: 'pi pi-exclamation-triangle',
        accept: this._deleteAccepted.bind(this, this._item),
        acceptLabel: 'Smazat',
        reject: () => { },
        rejectLabel: 'Zrušit'
      });
    }
  }


  /**
   * Potvrzení smazání náčrtu.
   * @param itemToDelete {NacrtMapItemDto} dto náčrtu ke smazání.
   */
  private _deleteAccepted(itemToDelete: NacrtMapItemDto): void {
    this.nacrtService.delete(itemToDelete.id).subscribe(resp => {
      this.messageUtils.showResponseMessage(this.TOAST_KEY, resp);
      if (resp.success) {
        this.mapInteractionService.nacrtDelete(itemToDelete);
      }
    });
  }

  /**
   * Navigace do knihy, pokud je s náčrtem svázané JRPL.
   */
  goToKniha(): void {
    let params = {};
    params[this._item.jprlType] = this._item.jprlId;
    this.router.navigate(['kniha'], { queryParams: params });
  }


  /**
   * Odebrání náčrtu z výběru.
  **/
  removeFromSelection(): void {
    if (!this.unselectDisabled)
      this.mapInteractionService.removeFromSelection(this._item.id);
  }


  /**
   * Vrací true, pokud má přihlášený uživatel právo editace náčrtů.
  **/
  get canEdit(): boolean {
    if (this._item.editableGeometries) {
      return this.roleUtils.checkRole([RoleEnum.NacrtyEditor, RoleEnum.NacrtyAdmin]);
    }
    else {
      return this.roleUtils.checkRole([RoleEnum.NacrtyAdmin]);
    }
  }


  /**
   * Vrací true, pokud má přihlášený uživatel právo k vytváření kopií náčrtu.
  **/
  get canCopy(): boolean {
    return this.roleUtils.checkRole([RoleEnum.NacrtyEditor, RoleEnum.NacrtyAdmin]);
  }


  /**
   * Vrací true, pokud může přihlášený uživatel smazat náčrt.
  **/
  get canDelete(): boolean {
    if (this._item.editableGeometries) {
      return this.canEdit;
    }
    else {
      return this.roleUtils.checkRole([RoleEnum.NacrtyAdmin]);
    }
  }


  /**
   * Vrací true, pokud má náčrt geometrii typu Multipolygon a obsahuje více než 1 polygon.
  **/
  get canExplode(): boolean {
    if (this._item != void 0 && this._item.geometryType == GeometryType.MULTI_POLYGON) {
      return (this.constructLayerUtils.convertFromSource([this._item])[0].getGeometry() as MultiPolygon).getPolygons().length > 1;
    }
    return false;
  }


  /**
   * Vrací true, pokud lze náčrt dělit hranicemi JRPL.
  **/
  get canSplit(): boolean {
    if (this._item != void 0 && (this._item.geometryType == GeometryType.MULTI_POLYGON) || this._item.geometryType == GeometryType.LINE_STRING) {
      return true;
    }
    return false;
  }


  /**
   * Náčrt musí mít vazbu na JRPL, aby jej šlo schválit. Dále musí být aktivní tlačítko a editace.
  **/
  get canSchvalit(): boolean {
    return !this.disabledBtn && this.canEdit && this._item?.jprlId != void 0;
  }

  /**
   * Text tooltipu při aktivaci/deaktivaci schválení
   */
  get schvalitTooltipText(): string {
    return this.canSchvalit ? "Schválit náčrt" : "Schválit je možné pouze náčrt, který leží uvnitř jedné JPRL (označení JPRL je viditelné v kartičce náčrtu)";
	}


  /**
   * Handler události předávající nově vzniklý polygon rozbitím multipolygonu.
   * Řeší se zde, protože komponenta MultipolygonExplode potřebuje vlastní instanci MapInteractionService pracující s její mapou.
   * @param event {NacrtMapItemPostSaveDto} Data nového náčrtu.
   */
  explodeHandler(event: NacrtMapItemPostSaveDto): void {
    this.mapInteractionService.nacrtFinished(event);
  }


  /**
   * Handler události předávající nově vzniklý náčrt po dělení hranicemi JPRL.
   * Řeší se zde, protože komponenta NacrtSplitComponent potřebuje vlastní instanci MapInteractionService pracující s její mapou.
   * @param event {NacrtMapItemPostSaveDto} Data nového náčrtu.
   */
  splitFinishedHandler(event: NacrtMapItemPostSaveDto): void {
    this.mapInteractionService.nacrtFinished(event);
  }


  /**
   * Click na tlačítko schválení náčrtu.
  **/
  schvalit(): void {
    if (this.canEdit && this.isValidatable) {

      this.nacrtService.schvalitNacrt(this._item.id).subscribe(resp => {
        this.messageUtils.showResponseMessage(this.TOAST_KEY, resp);
        if (resp.success)
          this.nacrtySchvalitInteractionService.schvaleniPostprocess(resp.data);
      });
    }
  }


  /**
   * Postproces schválení náčrtu.
   * @param event {ISchvalWorkflowDto[]} argumentry události
   */
  private _schvalitPostProcess(event: ISchvalWorkflowDto[]): void {
    let nacrtyPostprocess: ISchvalWorkflowDto = event.filter(dto => dto.modul == 'Nacrty')[0];
    if (nacrtyPostprocess != void 0 && nacrtyPostprocess.nacrtGuid == this._item.id) {
      this.item = (nacrtyPostprocess as NacrtSchvalWorkflowDto).schvalenyNacrt;
    }
  }


  /**
   * Nastavení viditelnosti tlačítka pro schválení náčrtu.
  **/
  private _setIsValidatable(): void {
    if (this._item != void 0 && this._nacrtProjects != void 0 && this._nacrtProjects.length > 0) {
      let schvalProjGuid = this._nacrtProjects.filter(p => p.id == this._item.projektGuid)[0].schvalProjektGuid;
      this.isValidatable = schvalProjGuid != void 0 && schvalProjGuid != '';
    }
  }
}
