<div class="p-grid p-align-stretch kniha-detail-form p-mb-2">
  <div class="p-col-1">
    <app-label-input-display [value]="bzl?.bzl" label="BZL" tooltip="Bezlesí" [formatConent]="false"></app-label-input-display>
  </div>
  <div class="p-col-1">
    <app-ciselnik-field key="CBzlDr" [guid]="bzl?.bzlDruhGuid" label="Druh BZL" variant="short" tooltip="Druh bezlesí"></app-ciselnik-field>
  </div>
   <div class="p-col-1">
   <app-label-input-display [value]="bzl?.chs" label="CHS" tooltip="Cílový hospodářský soubor" [numDecPlaces]="0"></app-label-input-display>
  </div>
  <div class="p-col-1">
    <app-ciselnik-field key="CSlt" [guid]="bzl?.sltGuid" label="SLT" variant="short" tooltip="Soubor lesních typů"></app-ciselnik-field>
  </div>
  <div class="p-col-1">
    <app-ciselnik-field key="CLt" [guid]="bzl?.ltGuid" label="LT" variant="short" tooltip="Lesní typ"></app-ciselnik-field>
  </div>
  <div class="p-col-1">
    <app-ciselnik-field key="CLvs" [guid]="bzl?.lvsGuid" label="LVS" variant="short" tooltip="Lesní vegetační stupeň"></app-ciselnik-field>
  </div>

  <div class="p-col">
    <span pTooltip="Způsob využití"  class="tooltip-label" tooltipPosition="top">Zp. využití</span>
    <div class="p-inputtext ui-corner-all overflow-scroll">{{bzl?.bzlVyuz}}</div>
  </div>
</div>
<app-kniha-rozdeleni-lesa [rozdeleniLesa]="bzl"></app-kniha-rozdeleni-lesa>
