import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { AuthUtils } from './auth.utils';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authUtils: AuthUtils
  ) {
  }

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    if (!(await this.authUtils.isLoggedIn())) {
      this.router.navigate(['/login'], { queryParams: { page: route.routeConfig.path } });
      return false;
    }
    return true;
  }
}
