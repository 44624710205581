<div *ngIf="show" class="switch-vertex" [ngStyle]="{'right':calculatedOffset}">
  <button pButton type="button" [ngClass]="{'switch-is-on': checked}" pTooltip="Přepnutí zobrazení lomových bodů"
          class="p-button-rounded p-button-secondary p-button-outlined icon-btn hide-label-button"
          (click)="toggle()">
    <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
      <path fill="#607D8B" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path>
      <path fill="#607D8B" d="M 158.812 155.132 L 263.1 155.132 L 263.1 257.68 L 158.812 257.68 L 158.812 219.224 L 158.812 155.132 M 184.884 180.769 L 184.884 232.043 L 237.028 232.043 L 237.028 180.769 L 184.884 180.769 Z"></path>
    </svg>
  </button>
</div>
