import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faCog, faEllipsisV, faFileDownload, faFilter, faLock, faPencilAlt, faPlus, faPrint, faSearch, faTrash, faExchangeAlt, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import { ConfirmationService, MenuItem, MessageService, SelectItem } from 'primeng/api';
import { ResultInfoDto, ResultInfoGenericDto } from 'src/app/Dto/Shared/ResultInfoDto';
import { VyrobaService } from 'src/app/Services/Vyroba/vyroba.service';
import { MessagesUtils } from 'src/app/Utils/Shared/messages.utils';
import { AuthService } from 'src/app/Services/Auth/auth.service';
import { OrgUrComponent } from 'src/app/Components/Shared/org-ur/org-ur.component';


export interface IDoklad {
  kod: string;
  popis: string;
  webApiKod: string;
}

export interface IDokladFilter {
  mesice: number[],
  roky: number[]
}

/**
 *  Typ konverze CD do VML.
**/
export enum KonverzeCdDoVmlTyp {
  HROMADNE = 1,
  DLE_JPRL = 2,
  DLE_DODAVATELE = 3,
  DLE_DOKLADU = 4
}

/**
 * Zastřešující komponenta výrobních dokladů.
**/
@Component({
  selector: 'app-vyroba',
  templateUrl: './vyroba.component.html',
  styleUrls: ['./vyroba.component.css']
})
export class VyrobaComponent implements OnInit {

  private readonly URL_PARAM_DOKLAD: string = 'doklad';
  private readonly URL_QUERY_PARAM_DOKLAD_GUID: string = 'dokladGuid';
  private readonly MESSAGE_KEY: string = 'vyroba-toast';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private vyrobaService: VyrobaService,
    private messageService: MessageService,
    private messagesUtils: MessagesUtils,
    private confirmationService: ConfirmationService,
    private authService: AuthService
  ) { }

  faSearch = faSearch;
  faPlus = faPlus;
  faEllipsisV = faEllipsisV;
  faTrash = faTrash;
  faPencilAlt = faPencilAlt;
  faFilter = faFilter;
  faFileDownload = faFileDownload;
  faLock = faLock;
  faCog = faCog;
  faPrint = faPrint;
  faExchangeAlt = faExchangeAlt;
  faLockOpen = faLockOpen;

  menuLock: MenuItem[];
  menuOpts: MenuItem[];
  menuShare: MenuItem[];
  menuVmlFromCd: MenuItem[];

  mesice: SelectItem[];
  roky: SelectItem[];

  selectedMesice: any[] = [];
  selectedRoky: any[] = [];
  eQFilter: string = "";//data rozšířeného filtru
  wrongOrgUr: boolean = false;

  // "model" pro výběr org ur v případě zakládání dokladu člověkem na divizi nebo LS
  orgUrGuid: string = '';

  @ViewChild('orgUr', { static: true }) orgUr: OrgUrComponent;

  public dokladTypes: IDoklad[] = [
    { popis: 'Výrobně mzdový lístek', kod: 'VML', webApiKod: 'Vml' },
    { popis: 'Odvozní lístek', kod: 'OL', webApiKod: 'Oli' },
    { popis: 'Číselník dříví', kod: 'CD', webApiKod: 'Kubirovani' }
  ];
  public selectedDokladType: IDoklad;
  openDetailDisabled: boolean = false;
  deleteDokladDisabled: boolean = true;
  selectedDoklady: any[] = [];
  block: boolean = false;
  reload: boolean = false;
  dokladGuid: string = null;

  // pokud true, jsou dostupná tlačítka pro hromadné operace s doklady
  batchOpsEnabled: boolean = false;

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.selectedDokladType = this.dokladTypes.find(d => d.kod == params[this.URL_PARAM_DOKLAD]?.toUpperCase());
      if (this.selectedDokladType == null) this.selectedDokladType = this.dokladTypes[0];

      this.route.queryParams.subscribe(queryParams => {
        this.dokladGuid = queryParams[this.URL_QUERY_PARAM_DOKLAD_GUID];
      });
    });

    this.menuLock = [
      { label: "Zamknout", command: this.zamknoutDoklady.bind(this) },
      { label: "Odemknout", command: this.odemknoutDoklady.bind(this) }
    ];

    this.menuOpts = [
      { label: "Řidicí matice", routerLink: ["..", "config", "matice"] },
      { label: "Škodliví činitelé", routerLink: ["..", "config", "cinitele"] },
      { label: "Průvodní listy", routerLink: ["..", "config", "pruvodni"] },
      { label: "Období - uzávěrky", routerLink: ["..", "config", "uzaverky"] },
      { label: "Chemikálie", routerLink: ["..", "config", "chemikalie"] },
      { label: "Kontroly dokladů", routerLink: ["..", "config", "kontroly"] },
      { label: "Veřejné zakázky", routerLink: ["..", "dns", "prohlizec"] },
      { label: "Nastavení skladu", routerLink: ["..", "config", "sklad"] },
      { label: "Druhy mzdy", routerLink: ["..", "config", "mzdy"] },
      { label: "Mzdy OKBase", routerLink: ["..", "config", "okBase"] }
    ];

    this.menuShare = [
      { label: "Excel" },
      { label: "PDF" }
    ];

    this.menuVmlFromCd = [
      {
        label: "Hromadně", title: "Z vybraných záznamů ČD vytvoří jeden VML.",
        command: this.vmlFromCd.bind(this, KonverzeCdDoVmlTyp.HROMADNE),
      },
      {
        label: "Dle JPRL", title: "Pro každou etáž vytvoří jeden VML. Pokud se stejná etáž objevuje ve více vybraných ČD, budou všechny na jednom VML.",
        command: this.vmlFromCd.bind(this, KonverzeCdDoVmlTyp.DLE_JPRL)
      },
      {
        label: "Dle dodavatele", title: "Pro každého dodavatele vytvoří jeden VML. Pokud se stejný dodavatel objevuje ve více vybraných ČD, budou všechny na jednom VML.",
        command: this.vmlFromCd.bind(this, KonverzeCdDoVmlTyp.DLE_DODAVATELE)
      },
      {
        label: "Dle dokladu", title: "Pro každý vybraný ČD vytvoří jeden VML, kde budou všechny sortimenty z daného ČD.",
        command: this.vmlFromCd.bind(this, KonverzeCdDoVmlTyp.DLE_DOKLADU)
      }
    ];

    this.mesice = [...Array(12).keys()].map((_, i) => { var item: SelectItem = { label: "" + (i + 1), value: i + 1 }; return item; });
    //this.roky = [2019, 2020].map((r) => { var item: SelectItem = { label: "" + r, value: r }; return item; });
  }


  /**
   * Změna typu dokladu
   * @param event
   */
  public dokladTypChangeHandler(event): void {
    this.selectedDoklady = [];
    this.router.navigate(['vyroba', event.value.kod.toLowerCase()]);
  }


  /**
   * Otevření detailu dokumentu.
   * @param event
   */
  public openDetailHandler(event): void {
    if (this.selectedDoklady.length == 1) {
      this.router.navigate(['vyroba', this.selectedDokladType.kod.toLowerCase(), "detail", this.selectedDoklady[0].guid]);
    }
  }

  /**
   * Otevření editace dokumentu.
   * @param event
   */
  public openEditHandler(event): void {
    if (this.selectedDoklady.length == 1) {
      this.router.navigate(['vyroba', this.selectedDokladType.kod.toLowerCase(), "edit", this.selectedDoklady[0].guid]);
    }
  }

  /**
   * Handler změny vybraných dokumentů.
   * @param event
   */
  public dokladSelectedHandler(event: any[]): void {
    this.openDetailDisabled = event.length == 1;
    this.deleteDokladDisabled = event.length == 0;
    this.batchOpsEnabled = event.length > 0;
    this.selectedDoklady = event;
  }


  /**
   * Vytvoření nového dokladu. Po úspěšném vytvoření dokladu přespěruje na jeho editaci.
  **/
  public createNovyDoklad(selectedOrgUrGuid: string = null): void {
    if (this.selectedDokladType == void 0) {
      this.messageService.add({
        key: this.MESSAGE_KEY, severity: 'error', summary: 'Chyba', detail: 'Není vybrán typ dokladu.', life: MessagesUtils.TOAST_LIFE
      });
      return;
    }

    this.block = true;

    switch (this.selectedDokladType.kod) {
      case 'VML':
        this.vyrobaService.newVml(selectedOrgUrGuid).subscribe(resp => this._createPostProcess(resp));
        break;
      case 'OL':
        this.vyrobaService.newOl(selectedOrgUrGuid).subscribe(resp => this._createPostProcess(resp));
        break;
      case 'CD':
        this.vyrobaService.newCd(selectedOrgUrGuid).subscribe(resp => this._createPostProcess(resp));
        break;
    }
  }


  /**
   * Post prosessing vytvoření nového dokladu:
   * -přesměrování na edit
   * -nebo zobrazení chyby
   * @param response
   */
  private _createPostProcess(response: ResultInfoGenericDto<string>): void {
    this.block = false;

    if (response.success) {
      this.router.navigate(['vyroba', this.selectedDokladType.kod.toLowerCase(), "edit", response.data]);
    }
    else {
      this.messagesUtils.showResponseMessage(this.MESSAGE_KEY, response);
    }
  }


  get filter(): IDokladFilter {
    return {
      mesice: this.selectedMesice.sort().slice(),
      roky: this.selectedRoky.sort().slice()
    };
  }


  /**
   * Handler kliknutí na tlačítko před filrem měsíců.
  **/
  onFilterMesiceAllClick(): void {
    if (this.selectedMesice.length == this.mesice.length) {
      this.selectedMesice = [];
    }
    else {
      this.selectedMesice = this.mesice.map(x => x.value);
    }
  }


  /**
   * Handler kliknutí na tlačítko před filrem roků.
  **/
  onFilterRokyAllClick(): void {
    if (this.selectedRoky.length == this.roky.length) {
      this.selectedRoky = [];
    }
    else {
      this.selectedRoky = this.roky.map(x => x.value);
    }
  }


  /**
   * Nastavení senznamu roků dle kterých lze filtrovat. Je vytažení na klientu z došlých dat.
   * Pokud neobsahuje aktuální rok, tak se tam doplní.
   * @param event
   */
  yearsChanged(event: number[]): void {
    event.sort();
    if (event.indexOf(new Date().getFullYear()) == -1) event.push(new Date().getFullYear());
    this.roky = event.map((r) => { var item: SelectItem = { label: "" + r, value: r }; return item; });
    this.setImplicitFilter();
  }


  /**
   * Nastaví implicitní filtr na aktuální a předchozí měsíc.
  **/
  private setImplicitFilter(): void {
    let now = new Date();
    let letos = now.getFullYear();
    this.selectedRoky = [now.getFullYear()];

    let aktualniMesic = now.getMonth() + 1;
    let predchoziMesic = aktualniMesic - 1;
    if (predchoziMesic == 0) {
      predchoziMesic = 12;
      let vloni = letos - 1;
      if (this.roky.findIndex(r => r.value == vloni) == -1) {
        this.roky.splice(this.roky.length - 2, 0, { label: "" + vloni, value: vloni });
      }
      this.selectedRoky.unshift(vloni);
    }
    this.selectedMesice = [predchoziMesic, aktualniMesic];
  }


  /**
   * Smazání dokladu.
   * @param event
   */
  smazatDoklad(): void {

    this.confirmationService.confirm({
      message: 'Opravdu si přejete smazat vybraný doklad?',
      accept: () => {
        this.block = true;

        this.vyrobaService.delete(this.selectedDoklady.map(x => x.guid), this.selectedDokladType.kod)
          .subscribe(resp => this._postProcess(resp, true));
      }
    });
  }


  /**
   * Post prosessing smazání dokladu (i ze SAPu):
   * -reload seznamu dokladů
   * -nebo zobrazení chyby
   * @param response
   */
  private _postProcess(response: ResultInfoDto, reload: boolean): void {
    this.block = false;

    if (response.success) {
      this.messageService.add({ key: this.MESSAGE_KEY, severity: 'success', summary: 'Ok', detail: response.messages[0], life: MessagesUtils.TOAST_LIFE });
      this.reload = reload;
    }
    else {
      this.messagesUtils.showResponseMessage(this.MESSAGE_KEY, response);
    }
  }


  /**
   * Handler načtení dokladů (pozor - data jsou načtena, ale bez číselníků.
   * Je to jen pro info, že další reload se již nemá dělat dokud nebude zase potřeba).
  **/
  dataLoaded(): void {
    this.reload = false;
  }


  /**
   * Uzamčení dokladů.
  **/
  zamknoutDoklady(): void {
    this.block = true;
    this.vyrobaService.lock(this.selectedDoklady.map(x => x.guid), this.selectedDokladType.kod)
      .subscribe(resp => this._postProcess(resp, false));
  }


  /**
   * Odemčení dokladů.
  **/
  odemknoutDoklady(): void {
    this.block = true;
    this.vyrobaService.unLock(this.selectedDoklady.map(x => x.guid), this.selectedDokladType.kod)
      .subscribe(resp => this._postProcess(resp, false));
  }

  /**
   *  Tisk dokladu
   * */
  print(): void {
    let guidsString = this.selectedDoklady.map(x => x.guid).join('&g=');
    if (this.selectedDokladType != void 0) {
      window.open("api/" + this.selectedDokladType.webApiKod + "/Export/?g=" + guidsString);
    }

  }

  /**
   * Zachytí změnu rozšířeného filtru
   * @param value data filtru v json
   */
  filterHandler(value: string): void {
    this.eQFilter = value;
    this.reload = true;
  }


  /**
   * Vytvoření VML z CD dle zvolené metody.
   * @param metoda
   */
  vmlFromCd(metoda: KonverzeCdDoVmlTyp): void {
    this.vyrobaService.vmlNewFromCd(metoda, this.selectedDoklady.map(x => x.guid)).subscribe(resp => {
      if (resp.success) {
        resp.data.forEach(guid => window.open(window.location.origin + '/vyroba/vml/edit/' + guid, '_blank'));
      }
      this.messagesUtils.showResponseMessage(this.MESSAGE_KEY, resp);
    });
  }

  orgUrChanged(guid: string): void {
    if (this.wrongOrgUr && guid != void 0)
      this.createNovyDoklad(guid);
  }

  createNewHandler(): void {
    this.authService.info().subscribe(res => {
      this.wrongOrgUr = this.orgUr.checkUserOrgUr(res?.orgUrGuid);
      if (!this.wrongOrgUr)
        this.createNovyDoklad();
    });
  }
}
