import { forwardRef } from '@angular/core';
import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TreeNode } from 'primeng/api';
import { LayerTreeSourceDto } from '../../../Dto/Mapa/LayerTreeSourceDto';
import { LocalStorageLayersInteractionService } from '../../../Services/Mapa/local-storage-layers-interaction.service';
import { LayerTreeGenerator } from '../../../Utils/Mapa/layer-tree-generator';
import { TreeSearchUtils } from '../../../Utils/Shared/tree-search.utils';

@Component({
  selector: 'app-nacrt-project-tree',
  templateUrl: './nacrt-project-tree.component.html',
  styleUrls: ['./nacrt-project-tree.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => NacrtProjectTreeComponent),
    multi: true
  }]
})
export class NacrtProjectTreeComponent implements ControlValueAccessor {

  /**
   * Zdrojová data pro vytvoření stromové struktury vrstev.
  **/
  @Input() set layerTreeSources(value: LayerTreeSourceDto[]) {
    if (value != void 0 && value.length > 0) {
      this._treeDtos = value;
      this.projectsAll = this._generateTree(this._treeDtos, false, null);
      this.projects = this._generateTree(this._treeDtos, this.onlyVisibleProjects, this.filterValue);
    }
  }


  /**
   * Přepínač zobrazení přepínače mezi viditelnými/všemi projekty.
   * True, pokud má být přepínač zobrazen (default).
  **/
  @Input() allowSwitch: boolean = true;


  constructor(
    private treeGenerator: LayerTreeGenerator,
    private treeSearchUtils: TreeSearchUtils,
    private localStoragelayersInteractionService: LocalStorageLayersInteractionService)
  { }

  // Implementace rozhraní ControlValueAccessor
  writeValue(obj: string): void {
    this._valueWrited = true;
    if (obj != void 0 && obj != '') {
      this.value = this.treeSearchUtils.findNode(obj, this.projectsAll);
    }
    else {
      this.value = null;
    }
  }
  // Implementace rozhraní ControlValueAccessor
  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }
  // Implementace rozhraní ControlValueAccessor
  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }
  // Implementace rozhraní ControlValueAccessor
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  // Proměnné pro implementaci rozhraní ControlValueAccessor
  onModelChange: any = () => { };
  onModelTouched: any = () => { };
  disabled: boolean = false;

  @Input('value') _value: TreeNode = null;

  get value() {
    return this._value;
  }

  set value(val: TreeNode) {
    if (val != this._value) {
      this._value = val;
      if (!this._valueWrited) {
        this.onModelChange(val?.key);
        this.onModelTouched();
      }
    }
    this._valueWrited = false;
  }


  /**
   * Data pro sestavení stromu vrstev.
  **/
  private _treeDtos: LayerTreeSourceDto[] = [];


  /**
   * Přepínač obsahu nabídky projektů vše/jen viditelné.
  **/
  onlyVisibleProjects: boolean = true;
  

  /**
   * Obsah nabídky projektů.
  **/
  projects: TreeNode[] = [];

  /**
   * Seznam všech projektů.
   * Používá se pro vyhledávání uzlu stromu při výběru uzlu zvenčí (nastavení hodnoty formuláře)
   * a to z důvodu, že je možné se dostat k editaci náčrtu, jehož mapová vrstva není viditelná (např. přechodem do mapy ze seznamu náčrtů a zahájení editace takového náčrtu).
  **/
  projectsAll: TreeNode[] = [];

  /**
   * Příznak nastavení hodnoty "zvenku". Pokud se nastavuje hodnota "zvenku", nevypouští se události o "onModelChange" a "onModelTouched".
  **/
  private _valueWrited: boolean = false;


  /**
   * Generování stromu nabídky projektů.
   * @param sources {LayerDefinitionDto[]} definice vrstev, ze kterých se bude strom generovat
   * @param onlyVisible {boolean} true, pokud má strom obsahovat pouze v mapě zobrazené vrstvy, jinak false
   * @param filter {string} filter vyhledávání dle popisku vrstvy
   */
  private _generateTree(sources: LayerTreeSourceDto[], onlyVisible: boolean, filter: string): TreeNode[] {
    
    let sourceData = sources.slice();

    if (this.allowSwitch && onlyVisible) {
      let visibleNames = this._getVisibleLayerNames();
      sourceData = sourceData.filter(s => visibleNames.includes(s.id));
    }

    if (filter != void 0 && filter != '') {
      sourceData = sourceData.filter(x => x.label.toLowerCase().includes(filter.toLowerCase()));
    }

    let tree = this.treeGenerator.getTree(sourceData);

    return tree;
  }


  /**
   * Vrátí seznam guidů viditelných projeků náčrtů.
  **/
  private _getVisibleLayerNames(): string[] {
    let names = this.localStoragelayersInteractionService.getVisibleList();
    names = names.filter(x => x.startsWith('nacrty_')).map(x => x.substr('nacrty_'.length));
    return names;
  }


  /**
   * Handler přepnutí viditelných/všech vrstev.
   * @param event
   */
  onlyVisibleChanged(event): void {
    if (this.allowSwitch) {
      this.projects = this._generateTree(this._treeDtos, this.onlyVisibleProjects, this.filterValue);
    }
  }


  /**
   * Aktuální hodnota filtru.
  **/
  filterValue: string = null;


  /**
   * Filtrování stromu. Hledaný text se hledá jako podmnožina popisku uzlu.
   */
  filterKeyUp(): void {
    this.projects = this._generateTree(this._treeDtos, this.onlyVisibleProjects, this.filterValue);
    this._expandRecursive(this.projects, true);
  }


  /**
   * Rozbalení/sbalení všech úrovní stromu.
   * @param nodes {TreeNode[]} seznam uzlů
   * @param expand {boolean} true - rozbalit, false - sbalit
   */
  private _expandRecursive(nodes: TreeNode[], expand: boolean): void {
    nodes.forEach(node => {
      node.expanded = expand;
      if (node.children) {
        this._expandRecursive(node.children, expand);
      }
    });
  }
}
