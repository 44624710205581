import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TreeNode } from 'primeng/api';
import { PodCiselnikListDto } from '../../../Dto/Shared/PodCiselnikListDto';
import { AuthService } from '../../../Services/Auth/auth.service';
import { CiselnikyService } from '../../../Services/Shared/ciselniky.service';
import { OrgUrUtils } from '../../../Utils/Shared/org-ur.utils';

@Component({
  selector: 'app-org-ur-multi-select',
  templateUrl: './org-ur-multi-select.component.html',
  styleUrls: ['./org-ur-multi-select.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => OrgUrMultiSelectComponent),
    multi: true
  }]
})
export class OrgUrMultiSelectComponent implements ControlValueAccessor, OnInit {

  /**
   * Guid zákazníka, v rámci kterého se nabízí org. úrovně.
   */
  @Input() zakaznikGuid: string;

  /**
   * Text na záhlaví panelu
   */
  @Input() header: string = "Organizační úrovně";

  /**
   * Ovládání zobrazení dialogu pro přidání org. úrovně
   */
  displayDialog: boolean = false;

  // Proměnné pro implementaci rozhraní ControlValueAccessor
  onModelChange: any = () => { };
  onModelTouched: any = () => { };
  disabled: boolean = false;

  @Input('value') _value: string[] = [];

  /*
   * Volba způsobu načítání obsahu.
   * - 'admin' - pro roli SpuperAdmin, kde je nutné zadat zakaznikGuid
   * - 'base' - základní filtrování podle přihlášeného zákazníka na straně serveru
  */
  @Input() mode: string = "base";

  /*
   * Událost informující o přidání org. ur. do výběru. Předává guid org. ur.
  */
  @Output() orgUrAdded: EventEmitter<string> = new EventEmitter<string>();

  /*
   * Událost informující o odebrání org. úrovně. Předává guid org. ur.
  */
  @Output() orgUrRemoved: EventEmitter<string> = new EventEmitter<string>();

  get value() {
    return this._value;
  }

  set value(val) {
    if (val != this._value) {
      this._value = val;
      this.onModelChange(val);
      this.onModelTouched();
    }
  }

  constructor
  (
    private ciselnikyService: CiselnikyService,
    private authService: AuthService,
    private orgUrUtils: OrgUrUtils
  )
  {
  }

  ngOnInit(): void {
    this.loadOrgUrList();
  }

  // Implementace rozhraní ControlValueAccessor
  writeValue(obj: any): void {
    this._value = obj;
  }

  // Implementace rozhraní ControlValueAccessor
  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  // Implementace rozhraní ControlValueAccessor
  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }

  // Implementace rozhraní ControlValueAccessor
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /*
   * Vrátí povolené org. úrovně uživatele
  */
  public get userOrgUrs() {
    return this._userOrgUrs;
  }

  private _userOrgUrs: string[]
  private _lastAddedOrgUr: string;
  private _beforeRemovedOrgUrs: string[]; // Array, aby se zachovalo pořadí, ve kterém byly org. úrovně původně přidány

  /**
   * Přidá org. úroveň vybranou z dialogu do kolekce (pokud v ní ještě není) a zavře dialog. 
   */
  addOrgUr(): void {
    if (this.value.indexOf(this.selectedOrgUr.data.guid) < 0) {
      var oldValue = this.value;
      oldValue.push(this.selectedOrgUr.data.guid);
      this.value = oldValue.slice();
      this._lastAddedOrgUr = this.selectedOrgUr.data.guid;
      this.orgUrAdded.emit(this.selectedOrgUr.data.guid);
    }

    this.displayDialog = false;
  }

  /**
   * Odstraní org. úroveň z kolekce
   * @param guid
   */
  removeOrgUr(guid: string) {
    this._beforeRemovedOrgUrs = [...this.value];
    this.value = this.value.filter(x => x != guid);
    this.orgUrRemoved.emit(guid);
  }

  /**
   * Odstraní poslední přidanou org. úroveň
   */
  public revertLastAdded() {
    if (this._lastAddedOrgUr != void 0) {
      this.value = this.value.filter(x => x != this._lastAddedOrgUr);
      this._lastAddedOrgUr = null;
    }
  }

  /**
   * Přidá poslední odstraněnou org. úroveň
  */
  public revertLastRemoved() {
    if (this._beforeRemovedOrgUrs != void 0) {
      this.value = [...this._beforeRemovedOrgUrs];
this._beforeRemovedOrgUrs = null;
    }
  }


  // Následující je zkopírováno z lhc-org-ur-settings a výrazně upraveno.
  // TODO Revidovat a zpětně zjednodušit řešení v lhc-org-ur-settings

  /**
   * Org. úroveň vybraná ve stromu v dialogu
   */
  selectedOrgUr: TreeNode<PodCiselnikListDto>;

  /**
   * Strom org. úrovní pro komponentu p-tree
   */
  orgUrTree: TreeNode<PodCiselnikListDto>[];

  /**
   * Seznam org. úrovní tak, jak je načten z WebAPI
   */
  private orgUrList: PodCiselnikListDto[];

  /**
   * Indikace stavu načítání org. úrovní (pokud probíhá načítání dat, je nastaveno na true)
   */
  loadingOrgUr: boolean = false;

  /**
   * Načtení seznamu organizačních úrovní
   */
  private loadOrgUrList() {
    this.loadingOrgUr = true;

    if (this.mode == "admin" && this.zakaznikGuid != void 0) {
      this.ciselnikyService.getOrgUr(this.zakaznikGuid).subscribe(res => {
        this.orgUrList = (res as PodCiselnikListDto[]).sort((a, b) => parseInt(a.kod) > parseInt(b.kod) ? 1 : -1); //seřadit podle kódu;
        this.orgUrTree = this._buildTreeBranch(this.orgUrList, null);
        this.loadingOrgUr = false;
      });
    }

    if (this.mode == "base") {
      this.ciselnikyService.getCislenik("COrgUr", null).subscribe(cOrgUr => {
        this.orgUrList = (cOrgUr as PodCiselnikListDto[]).sort((a, b) => parseInt(a.kod) > parseInt(b.kod) ? 1 : -1); //seřadit podle kódu;
        this.authService.userOrgUrs().subscribe(res => {
          if (res.success) {
            this._userOrgUrs = res.data;
            this.orgUrList = this._filterUserOrgUrs(this.orgUrList, this.orgUrList.filter(x => this._userOrgUrs.includes(x.guid)), []);
            this.orgUrTree = this.orgUrUtils.gerTreeFromCiselnik(this.orgUrList, res.data, true, false, true);
            this.loadingOrgUr = false;
          }
        });
      });
    }
  }

  /**
   * Rekurzivní metoda, která vybere pouze organizační úrovně relevantní přihlášenému uživateli
   * @param source Původní zdroj dat
   * @param input Vstup předaný do výběru
   * @param result Konečný výsledek výběru
   */
  private _filterUserOrgUrs(source: PodCiselnikListDto[], input: PodCiselnikListDto[], result: PodCiselnikListDto[]): PodCiselnikListDto[] {
    if (input?.length > 0) {
      result = result.concat(input.filter(x => !result.includes(x)));
      return this._filterUserOrgUrs(source, source.filter(x => input.map(y => y.parentGuid).includes(x.guid)), result);
    }
    else return result;
  }

  /**
   * Metoda sestaví větev stromu vycházejícího ze zadaného parentGuid. Rekurzivně volá sebe sama.
   * @param data
   * @param parentGuid
   */
  private _buildTreeBranch(data: PodCiselnikListDto[], parentGuid: string): TreeNode<PodCiselnikListDto>[] {
    let currentLevel = data.filter(x => x.parentGuid == parentGuid);
    var result: TreeNode<PodCiselnikListDto>[] = [];

    for (var i in currentLevel) {
      let dto = currentLevel[i];
      let node: TreeNode<PodCiselnikListDto> = {
        label: dto.kod + " - " + dto.popis,
        data: dto,
        children: this._buildTreeBranch(data, dto.guid),
        selectable: true
      };

      // TODO: vrátit jen tehdy, pokud je na "selectable" levelu nebo má potomky (pro případ univezrálnější komponenty)
      result.push(node);
    }

    return result;
  }
}
