import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OptionsService {

  private apiVersion: string = "api/Options/Version";

  constructor(
    private http: HttpClient
  ) { }

  getApiVersion(): Observable<any> {
    return this.http.get(this.apiVersion, { responseType: "text" });
  }
}
