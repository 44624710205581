<p-dialog [(visible)]="showDialog" position="bottom" [modal]="true">
  <ng-template pTemplate="header">
    <span class="ui-widget-header">Náhled výstupů</span>
    <div style="display: inline-block; margin-left: 5em;">
      <div class="p-inputgroup">
        <span class="p-inputgroup-addon">Doklad</span>
        <span class="p-inputgroup-addon" style="font-weight: normal;">Datum</span>
        <div class="p-inputtext ui-widget ui-state-default ui-corner-all ui-div-inputtext" style="width: 10em;">{{data.datum | date:'d.M.yyyy'}}</div>
        <span class="p-inputgroup-addon" style="font-weight: normal;">Org. úroveň</span>
        <div class="p-inputtext ui-widget ui-state-default ui-corner-all ui-div-inputtext" style="width: 10em;">
          <app-org-ur-display [guid]="data.orgUrGuid" variant="short"></app-org-ur-display>
        </div>
        <span class="p-inputgroup-addon" style="font-weight: normal;">Číslo</span>
        <div class="p-inputtext ui-widget ui-state-default ui-corner-all ui-div-inputtext" style="width: 5em;">{{data.cislo}}</div>
      </div>
    </div>
  </ng-template>
  <div class="p-grid">
    <div class="p-col-6">
      <p-table [value]="uce" [styleClass]="'pds-table-compact'" [style]="{ 'margin-bottom': '1em' }">
        <ng-template pTemplate="caption">Účetnictví</ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 13%;">Výkon</th>
            <th style="width: 13%;">Prostředek</th>
            <th style="width: 13%;">Org. úroveň</th>
            <th style="width: 13%;">Plocha</th>
            <th style="width: 22%;">MJ</th>
            <th style="width: 13%;">Množství</th>
            <th style="width: 13%;">Kč</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-row let-rowIndex="rowIndex">
          <tr>
            <td class="right"><app-ciselnik-kod ciselnik="CVykon" [guid]="row.vykonGuid"></app-ciselnik-kod></td>
            <td class="right"><app-ciselnik-kod ciselnik="CProstredek" [guid]="row.prostredekGuid"></app-ciselnik-kod></td>
            <td><app-org-ur-display [guid]="row.orgUrGuid" variant="short"></app-org-ur-display></td>
            <td class="right">{{row.plocha | number:'1.2-2'}}</td>
            <td><app-ciselnik-kod ciselnik="CMj" [guid]="row.mjGuid" [showPopisIn]="true"></app-ciselnik-kod></td>
            <td class="right">{{row.mnozstvi | number:'1.3-3'}}</td>
            <td class="right">{{row.kc | number:'1.2-2'}}</td>
          </tr>
        </ng-template>
      </p-table>
      <p-table [value]="mzdy" [styleClass]="'pds-table-compact'">
        <ng-template pTemplate="caption">Mzdy</ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 40%;">Pracovník</th>
            <th style="width: 20%;">Výkon</th>
            <th style="width: 20%;">Druh mzdy</th>
            <th style="width: 20%;">Kč</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-row let-rowIndex="rowIndex">
          <tr>
            <td><app-ciselnik-kod ciselnik="CDodavatel" [guid]="row.dodavatelGuid" [showPopisIn]="true"></app-ciselnik-kod></td>
            <td class="right"><app-ciselnik-kod ciselnik="CVykon" [guid]="row.vykonGuid"></app-ciselnik-kod></td>
            <td class="right"><app-ciselnik-kod ciselnik="CDruhMzdy" [guid]="row.druhMzdyGuid"></app-ciselnik-kod></td>
            <td class="right">{{row.kc | number:'1.2-2'}}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <div class="p-col-6">
      <p-table [value]="sklad" [styleClass]="'pds-table-compact'">
        <ng-template pTemplate="caption">Sklad</ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th>Výkon</th>
            <th>Podvýkon</th>
            <th colspan="2">Pohyb</th>
            <th>Sortiment</th>
            <th>Hodnota</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-row let-rowIndex="rowIndex">
          <tr>
            <td class="right"><app-ciselnik-kod ciselnik="CPodvykon" parentCiselnik="CVykon" [guid]="row.podvykonGuid"></app-ciselnik-kod></td>
            <td class="right"><app-ciselnik-kod ciselnik="CPodvykon" [guid]="row.podvykonGuid"></app-ciselnik-kod></td>
            <td class="right">
              <fa-icon [icon]="faPlus" *ngIf="row.smer > 0" [styles]="{'color': '#34A835'}"></fa-icon>
              <fa-icon [icon]="faMinus" *ngIf="row.smer < 0" [styles]="{'color': '#a90000'}"></fa-icon>
            </td>
            <td>
              <app-ciselnik-kod ciselnik="CLokalita" [guid]="row.lokalitaGuid" [showPopisIn]="true"></app-ciselnik-kod>
            </td>
            <td class="right"><app-ciselnik-kod ciselnik="CSortiment" [guid]="row.sortimentGuid"></app-ciselnik-kod></td>
            <td class="right">{{row.mnozstvi | number:'1.3-3'}}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</p-dialog>
