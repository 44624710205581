<div class="p-grid">
  <div class="p-col">
    Heslo
    <p-password class="p-fluid" [toggleMask]="true" (change)="onPasswordInput($event)"
                [(ngModel)]="initialPasswordValue" [style]="{'width':'100%'}"
                weakLabel="Slabé heslo" mediumLabel="Středně silné heslo" strongLabel="Silné heslo"
                promptLabel="Vyplňte heslo"
                appendTo="body">
      <ng-template pTemplate="footer">
        <p-divider></p-divider>
        <p class="p-mt-2">Požadavky:</p>
        <ul class="p-pl-2 p-ml-2 p-mt-0" style="line-height: 1.5">
          <li>Alespoň jedno malé písmeno</li>
          <li>Alespoň jedno velké písmeno</li>
          <li>Alespoň jedno číslo</li>
          <li>Alespoň jeden speciální znak</li>
          <li>Alespoň 8 znaků</li>
        </ul>
      </ng-template>
    </p-password>
  </div>
  <div class="p-col">
    Potvrzení hesla
    <p-password class="p-fluid" [feedback]="false" (change)="onPasswordInput($event)" [(ngModel)]="checkPasswordValue" [style]="{'width':'100%'}"></p-password>
  </div>
  <div class="p-formgroup-inline">
    <div class="p-col">
      <button pButton pTooltip="Generovat heslo" (click)="onGeneratePassword()" class="icon-btn hide-label-button"><fa-icon [icon]="faDice"></fa-icon></button>
    </div>
    <div #clipboardContainer class="p-col">
      <button pButton pTooltip="Kopírovat heslo" ngxClipboard [cbContent]="initialPasswordValue" [container]="clipboardContainer" class="icon-btn hide-label-button"><fa-icon [icon]="faCopy"></fa-icon></button>
    </div>
  </div>
</div>



