import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxResizeObserverModule } from 'ngx-resize-observer';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { AuthRegisterComponent } from './Components/Auth/auth-register/auth-register.component';
import { AuthLoginComponent } from './Components/Auth/auth-login/auth-login.component';
import { httpInterceptorProviders } from './HttpInterceptors';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularResizedEventModule } from 'angular-resize-event';

import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { TableModule } from 'primeng/table';
import { FieldsetModule } from 'primeng/fieldset';
import { PanelModule } from 'primeng/panel';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DropdownModule } from 'primeng/dropdown';
import { MessageService, ConfirmationService} from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import { KeyFilterModule } from 'primeng/keyfilter';
import { BlockUIModule } from 'primeng/blockui';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DialogModule } from 'primeng/dialog';
import { FileUploadModule } from 'primeng/fileupload';
import { TreeModule } from 'primeng/tree';
import { TreeSelectModule } from 'primeng/treeselect';
import { BreadcrumbModule } from 'primeng/breadcrumb'
import { TooltipModule } from 'primeng/tooltip';
import { SelectButtonModule } from 'primeng/selectbutton';
import { CalendarModule } from 'primeng/calendar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MenuModule } from 'primeng/menu';
import { CheckboxModule } from 'primeng/checkbox';
import { MultiSelectModule } from 'primeng/multiselect';
import { ProgressBarModule } from 'primeng/progressbar';
import { ListboxModule } from 'primeng/listbox';
import { SidebarModule } from 'primeng/sidebar';
import { PickListModule } from 'primeng/picklist';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { TabViewModule } from 'primeng/tabview';
import { BadgeModule } from 'primeng/badge';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputNumberModule } from 'primeng/inputnumber';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { GalleriaModule } from 'primeng/galleria';
import { PasswordModule } from 'primeng/password';
import { DividerModule } from 'primeng/divider';
import { TimelineModule } from 'primeng/timeline';
import { TagModule } from 'primeng/tag';
import { SkeletonModule } from 'primeng/skeleton';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { PlochaComponent } from './Components/plocha/plocha.component';
import { UserInfoComponent } from './Components/Auth/user-info/user-info.component';
import { SkodyIndexComponent } from './Components/Skody/skody-index/skody-index.component';
import { TableComponent } from './Components/Shared/table/table.component';
import { AuthChangePassComponent } from './Components/Auth/auth-change-pass/auth-change-pass.component';
import { DecimalInputComponent } from './Components/Shared/decimal-input/decimal-input.component';
import { ImportComponent } from './Components/Lhp/import/import.component';
import { BlockUiComponent } from './Components/Shared/block-ui/block-ui.component';
import { ListLhcComponent } from './Components/Lhp/list-lhc/list-lhc.component';


import localeCs from '@angular/common/locales/cs';
import localeCsExtra from '@angular/common/locales/extra/cs';
import { registerLocaleData, DecimalPipe } from '@angular/common';
import { KnihaLhcComponent } from './Components/Lhp/kniha-lhc/kniha-lhc.component';
import { KnihaTreeComponent } from './Components/Lhp/kniha-tree/kniha-tree.component';
import { KnihaOddComponent } from './Components/Lhp/kniha-odd/kniha-odd.component';
import { KnihaDilComponent } from './Components/Lhp/kniha-dil/kniha-dil.component';
import { KnihaPorComponent } from './Components/Lhp/kniha-por/kniha-por.component';
import { KnihaPskComponent } from './Components/Lhp/kniha-psk/kniha-psk.component';
import { KnihaEtazComponent } from './Components/Lhp/kniha-etaz/kniha-etaz.component';
import { KnihaDrevinaComponent } from './Components/Lhp/kniha-drevina/kniha-drevina.component';
import { KnihaBzlComponent } from './Components/Lhp/kniha-bzl/kniha-bzl.component';
import { KnihaOpComponent } from './Components/Lhp/kniha-op/kniha-op.component';
import { KnihaJpComponent } from './Components/Lhp/kniha-jp/kniha-jp.component';
import { KnihaRozdeleniLesaComponent } from './Components/Lhp/kniha-rozdeleni-lesa/kniha-rozdeleni-lesa.component';
import { SkodyDetailComponent } from './Components/Skody/skody-detail/skody-detail.component';
import { SkodyDetailZuComponent } from './Components/Skody/skody-detail-zu/skody-detail-zu.component';
import { SkodyDetailS6Component } from './Components/Skody/skody-detail-s6/skody-detail-s6.component';
import { SkodyDetailS72Component } from './Components/Skody/skody-detail-s72/skody-detail-s72.component';
import { SkodyDetailS91Component } from './Components/Skody/skody-detail-s91/skody-detail-s91.component';
import { SkodyDetailS91KrokyComponent } from './Components/Skody/skody-detail-s91-kroky/skody-detail-s91-kroky.component';
import { SkodyDetailS72KrokyComponent } from './Components/Skody/skody-detail-s72-kroky/skody-detail-s72-kroky.component';
import { SkodyDetailS6KrokyComponent } from './Components/Skody/skody-detail-s6-kroky/skody-detail-s6-kroky.component';
import { SkodyDetailVysledekComponent } from './Components/Skody/skody-detail-vysledek/skody-detail-vysledek.component';
import { UjmyDetailZuComponent } from './Components/Skody/ujmy-detail-zu/ujmy-detail-zu.component';
import { UjmyDetailU7Component } from './Components/Skody/ujmy-detail-u7/ujmy-detail-u7.component';
import { UjmyDetailU7KrokyComponent } from './Components/Skody/ujmy-detail-u7-kroky/ujmy-detail-u7-kroky.component';
import { UjmyDetailU8Component } from './Components/Skody/ujmy-detail-u8/ujmy-detail-u8.component';
import { UjmyDetailU8KrokyComponent } from './Components/Skody/ujmy-detail-u8-kroky/ujmy-detail-u8-kroky.component';
import { UjmyDetailU10Component } from './Components/Skody/ujmy-detail-u10/ujmy-detail-u10.component';
import { UjmyDetailU10KrokyComponent } from './Components/Skody/ujmy-detail-u10-kroky/ujmy-detail-u10-kroky.component';

import { VyrobaComponent } from './Components/Vyroba/vyroba/vyroba.component';
import { VyrobaVmlComponent } from './Components/Vyroba/vyroba-vml/vyroba-vml.component';
import { VyrobaOlComponent } from './Components/Vyroba/vyroba-ol/vyroba-ol.component';
import { VyrobaCdComponent } from './Components/Vyroba/vyroba-cd/vyroba-cd.component';
import { CiselnikFieldComponent } from './Components/Shared/ciselnik-field/ciselnik-field.component';
import { VyrobaCdEditComponent } from './Components/Vyroba/vyroba-cd-edit/vyroba-cd-edit.component';
import { CiselnikDropdownComponent } from './Components/Shared/ciselnik-dropdown/ciselnik-dropdown.component';
import { PodvykonDropdownComponent } from './Components/Vyroba/podvykon-dropdown/podvykon-dropdown.component';
import { EtazInputComponent } from './Components/Lhp/etaz-input/etaz-input.component';
import { OrgUrComponent } from './Components/Shared/org-ur/org-ur.component';
import { CalendarComponent } from './Components/Shared/calendar/calendar.component';
import { FiltrComponent } from './Components/Shared/filtr/filtr.component';
import { NextOnEnterDirective } from './Directives/next-on-enter.directive';
import { OrgUrDisplayComponent } from './Components/Shared/org-ur-display/org-ur-display.component';
import { DatumDenInputComponent } from './Components/Shared/datum-den-input/datum-den-input.component';
import {VyrobaHelpComponent} from 'src/app/Components/Vyroba/vyroba-help/vyroba-help.component'
import { VyrobaEqConditionsEditorComponent } from './Components/Vyroba/vyroba-eq-conditions-editor/vyroba-eq-conditions-editor.component';
import { VyrobaSkladConfigComponent } from './Components/Vyroba/vyroba-sklad-config/vyroba-sklad-config.component';
import { VykonProstredkuDropdownComponent } from './Components/Vyroba/vykon-prostredku-dropdown/vykon-prostredku-dropdown.component';
import { CheckBoxSPopisemComponent } from './Components/Shared/checkbox-s-popisem/checkbox-s-popisem.component';
import { VyrobaVmlNahledComponent } from './Components/Vyroba/vyroba-vml-nahled/vyroba-vml-nahled.component';
import { PestebniIndexComponent } from './Components/Vyroba/pestebni-index/pestebni-index.component';
import { GeneralEqComponent } from './Components/Shared/general-eq/general-eq.component';
import { VyrobaSkladReportComponent } from './Components/Vyroba/vyroba-sklad-report/vyroba-sklad-report.component';
import { VyrobaDochazkaComponent } from './Components/Vyroba/vyroba-dochazka/vyroba-dochazka.component';
import { CanDeactivateGuard } from './Directives/can-deactivate-guard';
import { AccordionModule } from 'primeng/accordion';
import { ConstructMapLayerUtils } from './Utils/Mapa/construnct-map-layer.utils';
import { ProvozniDenikDetailComponent } from './Components/Vyroba/provozni-denik-detail/provozni-denik-detail.component';
import { CiselnikKodComponent } from './Components/Shared/ciselnik-kod/ciselnik-kod.component';
import { VyrobaOlEditComponent } from './Components/Vyroba/vyroba-ol-edit/vyroba-ol-edit.component';
import { LhcAutocompleteComponent } from './Components/Lhp/lhc-autocomplete/lhc-autocomplete.component';
import { JprlAutocompleteComponent } from './Components/Lhp/jprl-autocomplete/jprl-autocomplete.component';
import { VyrobaVmlEditComponent } from './Components/Vyroba/vyroba-vml-edit/vyroba-vml-edit.component';
import { VyrobaMaticeComponent } from './Components/Vyroba/vyroba-matice/vyroba-matice.component';
import { VyrobaKopieDokladuComponent } from './Components/Vyroba/vyroba-kopie-dokladu/vyroba-kopie-dokladu.component';
import { VyrobaKontrolaComponent } from './Components/Vyroba/vyroba-kontrola/vyroba-kontrola.component';
import { VyrobaPruvodniListyComponent } from './Components/Vyroba/vyroba-pruvodni-listy/vyroba-pruvodni-listy.component';
import { VyrobaMaticePolozkyComponent } from './Components/Vyroba/vyroba-matice-polozky/vyroba-matice-polozky.component';
import { VyrobaUzaverkyComponent } from './Components/Vyroba/vyroba-uzaverky/vyroba-uzaverky.component';
import { VyrobaUzaverkyInitComponent } from './Components/Vyroba/vyroba-uzaverky-init/vyroba-uzaverky-init.component';
import { VyrobaUzaverkyUpdateComponent } from './Components/Vyroba/vyroba-uzaverky-update/vyroba-uzaverky-update.component';
import { VyrobaKontraktAutocompleteComponent } from './Components/Vyroba/vyroba-kontrakt-autocomplete/vyroba-kontrakt-autocomplete.component';
import { VyrobaKontrolyComponent } from './Components/Vyroba/vyroba-kontroly/vyroba-kontroly.component';
import { ProvozniDenikComponent } from './Components/Vyroba/provozni-denik/provozni-denik.component';
import { KnihaDetailComponent } from './Components/Lhp/kniha-detail/kniha-detail.component';
import { LhpKnihaTiskComponent } from './Components/Lhp/lhp-kniha-tisk/lhp-kniha-tisk.component';
import { DeleteComponent } from './Components/Lhp/delete/delete.component';
import { LhcOrgUrSettingsComponent } from './Components/Lhp/lhc-org-ur-settings/lhc-org-ur-settings.component';
import { TreeTableModule } from 'primeng/treetable';
import { ListCustomerComponent } from './Components/Zakaznik/list-customer/list-customer.component';
import { KnihaDetailTreeComponent } from './Components/Lhp/kniha-detail-tree/kniha-detail-tree.component';
import { KnihaDetailLhcComponent } from './Components/Lhp/kniha-detail-lhc/kniha-detail-lhc.component';
import { MapContainerComponent } from './Components/Mapa/map-container/map-container.component';
import { MapComponent } from './Components/Mapa/map/map.component';
import { KnihaDetailPorComponent } from './Components/Lhp/kniha-detail-por/kniha-detail-por.component';
import { ListOrgurComponent } from './Components/Zakaznik/list-orgur/list-orgur.component';
import { MapInfoPanelComponent } from './Components/Mapa/map-info-panel/map-info-panel.component';
import { MapLayerPanelComponent } from './Components/Mapa/map-layer-panel/map-layer-panel.component';
import { MapLayerListComponent } from './Components/Mapa/map-layer-list/map-layer-list.component';
import { KnihaDetailPskComponent } from './Components/Lhp/kniha-detail-psk/kniha-detail-psk.component';
import { KnihaDetailEtazComponent } from './Components/Lhp/kniha-detail-etaz/kniha-detail-etaz.component';
import { KnihaDetailDrevinaComponent } from './Components/Lhp/kniha-detail-drevina/kniha-detail-drevina.component';
import { KnihaDetailBzlComponent } from './Components/Lhp/kniha-detail-bzl/kniha-detail-bzl.component';
import { KnihaDetailJpComponent } from './Components/Lhp/kniha-detail-jp/kniha-detail-jp.component';
import { KnihaDetailOpComponent } from './Components/Lhp/kniha-detail-op/kniha-detail-op.component';
import { MapInfoLhpComponent } from './Components/Mapa/map-info-lhp/map-info-lhp.component';
import { MapInfoNacrtComponent } from './Components/Mapa/map-info-nacrt/map-info-nacrt.component';
import { MapInfoKatastrComponent } from './Components/Mapa/map-info-katastr/map-info-katastr.component';
import { LabelInputDisplayComponent } from './Components/Shared/label-input-display/label-input-display.component';
import { CiselnikyListComponent } from './Components/Core/ciselniky-list/ciselniky-list.component';
import { CiselnikyEditComponent } from './Components/Core/ciselniky-edit/ciselniky-edit.component';
import { FormatDelkaPipe } from './Directives/format-delka.pipe';
import { FormatPlochaPipe } from './Directives/format-plocha.pipe';
import { FormatDateTimePipe } from './Directives/format-date-time.pipe';
import { ListUserComponent } from './Components/Zakaznik/list-user/list-user.component';
import { MapNacrtCreateComponent } from './Components/Mapa/map-nacrt-create/map-nacrt-create.component';
import { MapInfoNacrtEditComponent } from './Components/Mapa/map-info-nacrt-edit/map-info-nacrt-edit.component';
import { CiselnikyOpravneniEditComponent } from './Components/Core/ciselniky-opravneni-edit/ciselniky-opravneni-edit.component';
import { NacrtPrilohaComponent } from './Components/Nacrty/nacrt-priloha/nacrt-priloha.component';
import { NacrtPrilohaFotoComponent } from './Components/Nacrty/nacrt-priloha-foto/nacrt-priloha-foto.component';
import { NacrtPrilohaFotoEditComponent } from './Components/Nacrty/nacrt-priloha-foto-edit/nacrt-priloha-foto-edit.component';
import { NacrtPrilohaEditComponent } from './Components/Nacrty/nacrt-priloha-edit/nacrt-priloha-edit.component';
import { NacrtPrilohaAudioComponent } from './Components/Nacrty/nacrt-priloha-audio/nacrt-priloha-audio.component';
import { ValidatedPasswordComponent } from './Components/Zakaznik/validated-password/validated-password.component';
import { NacrtyListComponent } from './Components/Nacrty/nacrty-list/nacrty-list.component';
import { NacrtyProjektListComponent } from './Components/Nacrty/nacrty-projekt-list/nacrty-projekt-list.component';
import { OrgUrMultiSelectComponent } from './Components/Shared/org-ur-multi-select/org-ur-multi-select.component';
import { MapInfoNacrtNewComponent } from './Components/Mapa/map-info-nacrt-new/map-info-nacrt-new.component';
import { PreviewPointComponent } from './Components/Nacrty/preview-point/preview-point.component';
import { PreviewLineComponent } from './Components/Nacrty/preview-line/preview-line.component';
import { PreviewPolygonComponent } from './Components/Nacrty/preview-polygon/preview-polygon.component';
import { MapInfoHelpComponent } from './Components/Mapa/map-info-help/map-info-help.component';
import { CalendarRangeComponent } from './Components/Shared/calendar-range/calendar-range.component';
import { NacrtEditFormComponent } from './Components/Nacrty/nacrt-edit-form/nacrt-edit-form.component';
import { NacrtPrilohaAudioEditComponent } from './Components/Nacrty/nacrt-priloha-audio-edit/nacrt-priloha-audio-edit.component';
import { NacrtEditComponent } from './Components/Nacrty/nacrt-edit/nacrt-edit.component';
import { KnihaDetailLheComponent } from './Components/Lhp/kniha-detail-lhe/kniha-detail-lhe.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { NacrtyProjektEditComponent } from './Components/Nacrty/nacrty-projekt-edit/nacrty-projekt-edit.component';
import { MapInfoLhpListComponent } from './Components/Mapa/map-info-lhp-list/map-info-lhp-list.component';
import { MapNacrtUndoComponent } from './Components/Mapa/map-nacrt-undo/map-nacrt-undo.component';
import { LheEditComponent } from './Components/Lhe/lhe-edit/lhe-edit.component';
import { BlockableDivComponent } from './Components/Shared/blockable-div/blockable-div.component';
import { MultipolygonJoinIntoComponent } from './Components/Nacrty/multipolygon-join-into/multipolygon-join-into.component';
import { NacrtDetailComponent } from './Components/Nacrty/nacrt-detail/nacrt-detail.component';
import { MultipolygonExplodeComponent } from './Components/Nacrty/multipolygon-explode/multipolygon-explode.component';
import { OverViewMapComponent } from './Components/Mapa/overview-map/overview-map.component';
import { SchvalitLheComponent } from './Components/Lhe/schvalit-lhe/schvalit-lhe.component';
import { NacrtMapComponent } from './Components/Mapa/nacrt-map/nacrt-map.component';
import { NacrtProjectTreeComponent } from './Components/Nacrty/nacrt-project-tree/nacrt-project-tree.component';
import { IconLibraryComponent } from './Components/Nacrty/icon-library/icon-library.component';
import { CiselnikEditInfoComponent } from './Components/Core/ciselnik-edit-info/ciselnik-edit-info.component';
import { DotazovacComponent } from './Components/Dotazovac/dotazovac/dotazovac.component';
import { NewDotazComponent } from './Components/Dotazovac/new-dotaz/new-dotaz.component';
import { EasyQueryComponent } from './Components/Shared/easy-query/easy-query.component';
import { ReleaseNotesComponent } from './Components/Core/release-notes/release-notes.component';
import { DotazovacModelDisplayComponent } from './Components/Dotazovac/dotazovac-model-display/dotazovac-model-display.component';
import { LineTypeLibraryComponent } from './Components/Nacrty/line-type-library/line-type-library.component';
import { ButtonIconStackComponent } from './Components/Shared/button-icon-stack/button-icon-stack.component';
import { EasyQueryParamsComponent } from './Components/Shared/easy-query-params/easy-query-params.component';
import { DotazParamsComponent } from './Components/Dotazovac/dotaz-params/dotaz-params.component';
import { DotazovacKategorieComponent } from './Components/Dotazovac/dotazovac-kategorie/dotazovac-kategorie.component';
import { DotazKategorieInputComponent } from './Components/Dotazovac/dotaz-kategorie-input/dotaz-kategorie-input.component';
import { NewSqlDotazComponent } from './Components/Dotazovac/new-sql-dotaz/new-sql-dotaz.component';
import { FeautresListComponent } from './Components/Nacrty/feautres-list/feautres-list.component';
import { NacrtSplitComponent } from './Components/Nacrty/nacrt-split/nacrt-split.component';
import { MouseupDirective } from './Directives/mouseup.directive';
import { MapNacrtSwitchEnclaveComponent } from './Components/Mapa/map-nacrt-switch-enclave/map-nacrt-switch-enclave.component';
import { ZakaznikDropdownComponent } from './Components/Zakaznik/zakaznik-dropdown/zakaznik-dropdown.component';
import { MapSnapSwitchComponent } from './Components/Mapa/map-snap-switch/map-snap-switch.component';
import { AreaOverlayComponent } from './Components/Mapa/area-overlay/area-overlay.component';
import { VertexSwitchComponent } from './Components/Mapa/vertex-switch/vertex-switch.component';
import { MapInfoPesComponent } from './Components/Mapa/map-info-pes/map-info-pes.component';
import { PesComponent } from './Components/Pes/pes/pes.component';
import { SeznamParcelComponent } from './Components/Pes/seznam-parcel/seznam-parcel.component';

registerLocaleData(localeCs, 'cs', localeCsExtra);


@NgModule({
  declarations: [
    AppComponent,
    AuthRegisterComponent,
    AuthLoginComponent,
    AuthLoginComponent,
    PlochaComponent,
    UserInfoComponent,
    SkodyIndexComponent,
    TableComponent,
    AuthChangePassComponent,
    DecimalInputComponent,
    BlockUiComponent,
    ListLhcComponent,
    ImportComponent,
    KnihaLhcComponent,
    KnihaTreeComponent,
    KnihaOddComponent,
    KnihaDilComponent,
    KnihaPorComponent,
    KnihaPskComponent,
    KnihaEtazComponent,
    KnihaDrevinaComponent,
    KnihaBzlComponent,
    KnihaOpComponent,
    KnihaJpComponent,
    KnihaRozdeleniLesaComponent,
    SkodyDetailComponent,
    SkodyDetailZuComponent,
    SkodyDetailS6Component,
    SkodyDetailS72Component,
    SkodyDetailS91Component,
    SkodyDetailS91KrokyComponent,
    SkodyDetailS72KrokyComponent,
    SkodyDetailS6KrokyComponent,
    SkodyDetailVysledekComponent,
    UjmyDetailZuComponent,
    UjmyDetailU7Component,
    UjmyDetailU7KrokyComponent,
    UjmyDetailU8Component,
    UjmyDetailU8KrokyComponent,
    UjmyDetailU10Component,
    UjmyDetailU10KrokyComponent,
    VyrobaComponent,
    VyrobaVmlComponent,
    VyrobaOlComponent,
    VyrobaCdComponent,
    CiselnikFieldComponent,
    VyrobaCdEditComponent,
    CiselnikDropdownComponent,
    PodvykonDropdownComponent,
    CiselnikKodComponent,
    VyrobaOlEditComponent,
    LhcAutocompleteComponent,
    JprlAutocompleteComponent,
    EtazInputComponent,
    VyrobaVmlEditComponent,
    OrgUrComponent,
    VyrobaMaticeComponent,
    CalendarComponent,
    FiltrComponent,
    VyrobaKopieDokladuComponent,
    VyrobaKontrolaComponent,
    VyrobaPruvodniListyComponent,
    VyrobaMaticePolozkyComponent,
    NextOnEnterDirective,
    OrgUrDisplayComponent,
    DatumDenInputComponent,
    VyrobaOlComponent,
    VyrobaUzaverkyComponent,
    VyrobaUzaverkyInitComponent,
    VyrobaUzaverkyUpdateComponent,
    VyrobaKontraktAutocompleteComponent,
    VyrobaKontrolyComponent,
    ProvozniDenikComponent,
    ProvozniDenikDetailComponent,
    VyrobaEqConditionsEditorComponent,
    VyrobaSkladConfigComponent,
    VykonProstredkuDropdownComponent,
    CheckBoxSPopisemComponent,
    VyrobaVmlNahledComponent,
    PestebniIndexComponent,
    GeneralEqComponent,
    VyrobaSkladReportComponent,
    VyrobaDochazkaComponent,
    VyrobaHelpComponent,
    KnihaDetailComponent,
    LhpKnihaTiskComponent,
    DeleteComponent,
    LhcOrgUrSettingsComponent,
    ListCustomerComponent,
    KnihaDetailTreeComponent,
    ListOrgurComponent,
    MapContainerComponent,
    MapComponent,
    MapInfoPanelComponent,
    MapLayerPanelComponent,
    MapLayerListComponent,
    KnihaDetailLhcComponent,
    KnihaDetailPorComponent,
    KnihaDetailPskComponent,
    KnihaDetailEtazComponent,
    KnihaDetailDrevinaComponent,
    KnihaDetailBzlComponent,
    KnihaDetailJpComponent,
    KnihaDetailOpComponent,
    MapInfoLhpComponent,
    MapInfoNacrtComponent,
    MapInfoKatastrComponent,
    LabelInputDisplayComponent,
    CiselnikyListComponent,
    CiselnikyEditComponent,
    FormatDelkaPipe,
    FormatPlochaPipe,
    FormatDateTimePipe,
    MapNacrtCreateComponent,
    ListUserComponent,
    MapInfoNacrtEditComponent,
    CiselnikyOpravneniEditComponent,
    NacrtPrilohaComponent,
    NacrtPrilohaFotoComponent,
    NacrtPrilohaFotoEditComponent,
    NacrtPrilohaEditComponent,
    NacrtPrilohaAudioComponent,
    NacrtyListComponent,
    NacrtyProjektListComponent,
    ValidatedPasswordComponent,
    OrgUrMultiSelectComponent,
    MapInfoNacrtNewComponent,
    PreviewPointComponent,
    PreviewLineComponent,
    PreviewPolygonComponent,
    MapInfoHelpComponent,
    CalendarRangeComponent,
    NacrtEditFormComponent,
    NacrtPrilohaAudioEditComponent,
    NacrtEditComponent,
    NacrtyProjektEditComponent,
    KnihaDetailLheComponent,
    MapInfoLhpListComponent,
    MapNacrtUndoComponent,
    LheEditComponent,
    BlockableDivComponent,
    MultipolygonJoinIntoComponent,
    NacrtDetailComponent,
    MultipolygonExplodeComponent,
    OverViewMapComponent,
    SchvalitLheComponent,
    NacrtMapComponent,
    NacrtProjectTreeComponent,
    IconLibraryComponent,
    CiselnikEditInfoComponent,
    DotazovacComponent,
    NewDotazComponent,
    EasyQueryComponent,
    ReleaseNotesComponent,
    DotazovacModelDisplayComponent,
    LineTypeLibraryComponent,
    ButtonIconStackComponent,
    EasyQueryParamsComponent,
    DotazParamsComponent,
    DotazovacKategorieComponent,
    DotazKategorieInputComponent,
    NewSqlDotazComponent,
    FeautresListComponent,
    NacrtSplitComponent,
    MouseupDirective,
    ZakaznikDropdownComponent,
    MapNacrtSwitchEnclaveComponent,
    MapSnapSwitchComponent,
    AreaOverlayComponent,
    VertexSwitchComponent,
    MapInfoPesComponent,
    PesComponent,
    SeznamParcelComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgxResizeObserverModule,
    FormsModule,
    FieldsetModule,
    PanelModule,
    InputTextModule,
    ButtonModule,
    CardModule,
    TableModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AutoCompleteModule,
    DropdownModule,
    MessagesModule,
    MessageModule,
    FontAwesomeModule,
    ToolbarModule,
    ToastModule,
    KeyFilterModule,
    BlockUIModule,
    ProgressSpinnerModule,
    DialogModule,
    FileUploadModule,
    TreeModule,
    TreeSelectModule,
    BreadcrumbModule,
    TooltipModule,
    SelectButtonModule,
    CalendarModule,
    OverlayPanelModule,
    InputTextareaModule,
    ConfirmDialogModule,
    ConfirmPopupModule,
    MenuModule,
    CheckboxModule,
    MultiSelectModule,
    ProgressBarModule,
    ListboxModule,
    AccordionModule,
    SidebarModule,
    PickListModule,
    ScrollPanelModule,
    AngularResizedEventModule,
    TreeTableModule,
    TabViewModule,
    BadgeModule,
    RadioButtonModule,
    InputNumberModule,
    ToggleButtonModule,
    GalleriaModule,
    PasswordModule,
    ClipboardModule,
    DividerModule,
    ColorPickerModule,
    TimelineModule,
    TagModule,
    SkeletonModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'cs' },
    httpInterceptorProviders,
    MessageService,
    ConfirmationService,
    CanDeactivateGuard,
    ConstructMapLayerUtils
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
