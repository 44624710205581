/**
 * Enum datového typu sloupce číselníku
 **/
export enum CiselnikSloupecTypEnum {
  Text = 0,
  Datum = 1,
  DatumCas = 2,
  CeleCislo = 3,
  DesetinneCislo = 4,
  FK = 5
}
