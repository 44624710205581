import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { faBackspace, faEllipsisV, faPlus, faSave, faTimes, faUndo} from '@fortawesome/free-solid-svg-icons';
import { MessageService } from 'primeng/api';
import { ResultInfoGenericDto } from '../../../Dto/Shared/ResultInfoDto';
import { ZakaznikDetailDto } from '../../../Dto/Zakaznik/ZakaznikDetailDto';
import { CiselnikyService } from '../../../Services/Shared/ciselniky.service';
import { ZakaznikService } from '../../../Services/Zakaznik/zakaznik.service';
import { MessagesUtils } from '../../../Utils/Shared/messages.utils';
import { TableComponent } from '../../Shared/table/table.component';

/** Komponenta pro práci se seznamem zákázníků */
@Component({
  selector: 'app-list-customer',
  templateUrl: './list-customer.component.html',
  styleUrls: ['./list-customer.component.css']
})
export class ListCustomerComponent implements OnInit {

  constructor
  (
    private ciselnikyService: CiselnikyService,
    private messageService: MessageService,
    private zakaznikService: ZakaznikService,
    private messageUtils: MessagesUtils,
    private router: Router
  )
  {
  }

  @ViewChild("zakazniciTable", { static: true }) table: TableComponent;
  @ViewChild("nazevZakaznika") nazevZakaznika: ElementRef;

  zakaznici: ZakaznikDetailDto[];
  data: ZakaznikDetailDto;

  filter: string;
  cols: any[] = [];
  block: boolean = false;

  faEllipsisV = faEllipsisV;
  faBackspace = faBackspace;
  faUndo = faUndo;
  faPlus = faPlus;
  faTimes = faTimes;
  faSave = faSave;

  private MESSAGE_KEY = 'zakaznici-list-toast'

  ngOnInit(): void {
    this._initCols();
    this._loadList();
  }

  /**
   * Navigace na úvodní stránku
  */
  public back(): void {
    this.router.navigate(["/"]);
  }
  
  /** Inicializace sloupců tabulky */
  private _initCols() {
    this.cols = [
      { field: 'nazev', header: 'Název', class: 'col-4' }
    ];
  }

  /** Načte seznam zákazníků */
  private _loadList(): void {
    this.ciselnikyService.getZakaznici().subscribe(res => {
      if (res != null) {
        this.zakaznici = res;
      }
      else {
        this.messageService.add({ severity: 'error', summary: 'Chyba', key: this.MESSAGE_KEY, detail: 'Došlo k neočekávané chybě.', life: MessagesUtils.TOAST_LIFE })
      }
    });
  }

  /**
   * Zpracuje změnu detailu seznamu
   * @param resp
   * @param editMode
   * @param successMessage
   */
  private _processDetailResponse(resp: ResultInfoGenericDto<ZakaznikDetailDto>, editMode: boolean, successMessage?: string) {
    if (resp.success) {
      this.data = resp.data;
      
      this.focusFirst();
      if (!editMode) {
        let index: number = this.zakaznici.findIndex(x => x.guid == this.data.guid);
        if (index != -1)
        {
          this.zakaznici[index] = this.data;
          this.table.selectedRows = undefined;
        }
        else {
          this.zakaznici.push(this.data);
        }
        this.zakaznici = [...this.zakaznici];
        this.data = undefined
      }

      if (successMessage != undefined) {
        this.messageService.add({ severity: 'success', summary: 'OK', key: this.MESSAGE_KEY, detail: successMessage, life: MessagesUtils.TOAST_LIFE });
      }
    }
    else if (!resp.success)
      this.messageUtils.showResponseMessage(this.MESSAGE_KEY, resp);
    else
      this.messageService.add({ severity: 'error', summary: 'Chyba', key: this.MESSAGE_KEY, detail: 'Došlo k neočekávané chybě.', life: MessagesUtils.TOAST_LIFE });

    this.block = false;
  }

  /**
   * Navazuje na výběr řádku
   * @param dto
   */
  onRowSelected(dto: ZakaznikDetailDto): void {
    this.focusFirst();
    this._processDetailResponse({ data: { guid: dto.guid, nazev: dto.nazev }, messages: undefined, success: true }, true);
  }

  /**
   * Navazuje na ukončení výběru řádku
   * @param dto
   */
  onRowUnselected(dto: ZakaznikDetailDto): void {
    this.cancel();
  }

  /**
 * Po zobrazení panelu focus na první položku
 * */
  focusFirst(): void {
    setTimeout(() => { if (this.nazevZakaznika != void 0) this.nazevZakaznika.nativeElement.focus() });
  }

  /** Otevře panel pro přidání nového řádku  */
  add(): void {
    this.data = new ZakaznikDetailDto();
    this.focusFirst();
    this.table.selectedRows = null;
  }

  /** Uloží řádek */
  save(): void {
    this.block = true;
    this.zakaznikService.saveOrUpdate(this.data).subscribe(res => {
      this._processDetailResponse(res, false, "Zákazník byl úspěšně uložen.");
    });
  }

  /** Ukončí ukládání/výběř řádku */
  cancel(): void {
    this.data = undefined;
    this.table.selectedRows = null;
  }

  /**
 * Zavolá se po načtení tabulky a vybere řádek
 * */
  dataLoaded(): void {
    this.table.selectedRows = this.zakaznici?.find(x => x.guid == this.data?.guid);
  }
}
