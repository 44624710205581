import { Component, Input, OnInit } from '@angular/core';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'
import { TiskService } from '../../../Services/Lhp/tisk.service';

/**
 *  Komponenta pro tisk porostu
 */
@Component({
  selector: 'app-lhp-kniha-tisk',
  templateUrl: './lhp-kniha-tisk.component.html',
  styleUrls: ['./lhp-kniha-tisk.component.css']
})
export class LhpKnihaTiskComponent {

  constructor(private tiskService: TiskService) {
  }

  /**
   *  Guid porostu
   */
  @Input() guid: string = "";

  faFilePdf = faFilePdf;

  /** Vytiskne jprl podle guidu */
  onTisk() {
    this.tiskService.porostTisk(this.guid);
  }
}
