<p-treeSelect [disabled]="disabled" [(ngModel)]="value" [options]="projects" scrollHeight="270px"
              placeholder="Vyberte projekt" appendTo="body">
  <ng-template pTemplate="header">
    <div class="p-tree-filter-container" style="padding: 0.5rem;">
      <input  type="text" pInputText [(ngModel)]="filterValue" autocomplete="off" class="p-tree-filter" style="padding-right: 1.5rem;"
             (keyup)="filterKeyUp()">
      <span class="p-tree-filter-icon pi pi-search" style="margin-left: -1.5rem;"></span>
    </div>
    <div *ngIf="allowSwitch" style="margin: 0.5rem 0.5rem 0 0.5rem; padding-bottom: 1rem; border-bottom: 1px solid #ced4da ">
      <p-checkbox [(ngModel)]="onlyVisibleProjects" binary="true" inputId="onlyVisibleId"
                  (onChange)="onlyVisibleChanged($event)"
                  pTooltip="Zobrazit pouze projekty viditelné v mapě (aktivní vrstvy)"></p-checkbox>
      <label id="onlyVisibleId" style="margin-left: 0.5rem;">Pouze viditelné</label>
    </div>
  </ng-template>
  <ng-template pTemplate="empty">
    Nebyla nelezena žádná data.
  </ng-template>
</p-treeSelect>

