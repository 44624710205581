import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { SkodyServices } from 'src/app/Services/Skody/skody.services';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { SkodyDetailVysledekComponent } from '../skody-detail-vysledek/skody-detail-vysledek.component';
import { DruhVypoctu } from 'src/app/Utils/Skody/druh-vypoctu.utils';
import { tap, switchMap } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { MessagesUtils } from 'src/app/Utils/Shared/messages.utils';
import { VypocetDetailDto } from 'src/app/Dto/Skody/VypocetDetailDto';
import { LhpEtazProVypocetDto } from 'src/app/Dto/Skody/LhpEtazProVypocetDto';
import { LhcListDto } from 'src/app/Dto/Lhp/LhcListDto';
import { VypocetInfoDto } from 'src/app/Dto/Skody/VypocetInfoDto';
import { ObdobiListDto } from 'src/app/Dto/Skody/ObdobiListDto';
import { LhpDrevinaProVypocetDto } from 'src/app/Dto/Skody/LhpDrevinaProVypocetDto';


/**
 * Zastřešující komponenta detailu škody. Dynamicky zobrazuje komponenty dle typu škody.
**/
@Component({
  selector: 'skody-detail',
  templateUrl: './skody-detail.component.html',
  styleUrls: ['./skody-detail.component.css']
})
export class SkodyDetailComponent implements OnInit {

  constructor(
    private skodyServices: SkodyServices,
    private messageService: MessageService,
    private messageUtils: MessagesUtils
  ) {
    this.initDto();
  }

  @ViewChild('vysledek') vysledek: SkodyDetailVysledekComponent;

  _vypocetId: string;
  @Input() set vypocetId(value: string) {
    this._vypocetId = value;
    this.getDetail(this._vypocetId);
  }
  druhVypoctu: string;
  @Input() selectedObdobi: ObdobiListDto;
  @Output() prepocitej: EventEmitter<any> = new EventEmitter();
  @Output() skodaSaved: EventEmitter<any> = new EventEmitter();

  private MESSAGE_SKODY_KEY: string = "skodyDetailMessages";
  faSave = faSave;
  isNewRow: boolean = false;
  skoda: VypocetDetailDto;
  vypocet: VypocetInfoDto;
  krokyDto: any;
  blockedPrepocet: boolean = false;
  lhcListDto: LhcListDto;

  ngOnInit() {
    this.prepocitej
      .pipe(
        tap((data) => {
          this.blockedPrepocet = true;
        }),
        switchMap(data => this.skodyServices.prepocti(data))
      )
      .subscribe(res => {
        if (res != void 0 && res.data != void 0) {
          this.krokyDto = res.data;
          this.vypocet.vysledek = res.data.vysledek;
          this.vypocet.vysledekNaKus = res.data.vysledekNaKus;
        }

        this.blockedPrepocet = false;
      },
        err => {
          this.blockedPrepocet = false;
        });
  };


  /**
   * Inicializace prázdných Dto.
   * @param event
  **/
  initDto(): void {
    this.skoda = new VypocetDetailDto();
    if (this.selectedObdobi != void 0) {
      this.skoda.typ = this.selectedObdobi.typ == 's' ? DruhVypoctu.S6 : DruhVypoctu.U7;
      this.skoda.obdobiGuid = this.selectedObdobi.guid;
    }
    else {
      this.skoda.typ = DruhVypoctu.S6;
      this.skoda.obdobiGuid = null;
    }

    this.skoda.lhpEtaz = new LhpEtazProVypocetDto();
    this.skoda.lhpDrevina = new LhpDrevinaProVypocetDto();
    this.skoda.drevinaSkup = null;
    this.skoda.drevinaSkupGuid = null;

    this.vypocet = new VypocetInfoDto();

    this.krokyDto = {};
  };


  /**
   * Získá detail vybrané škody.
  **/
  getDetail(id: string): void {
    if (id != void 0) {
      this.messageService.clear(this.MESSAGE_SKODY_KEY);
      this.skodyServices.detail(id).subscribe(res => {
        if (res != void 0 && res.data != void 0) {
          this.skoda = res.data;

          this.druhVypoctu = this.skoda.typ;
        }
      });
    }
  };


  /**
   * Změna druhu výpočtu.
   * @param event
   */
  onDruhVypoctuChanged(event): void {
    this.druhVypoctu = event;
    this.skoda.typ = this.druhVypoctu;
    this.vysledek.setHeader(event);
  };


  /**
   * Handler změny dat v základních údajích.
   * @param event
  **/
  dataChangedZuHandler(event): void {
    this.skoda.drzitelGuid = event.drzitel != void 0 ? event.drzitel.guid : null;
    this.skoda.uzivatelGuid = event.uzivatel != void 0 ? event.uzivatel.guid : null;
    this.skoda.lhcGuid = event.lhc != void 0 ? event.lhc.guid : null;
    this.skoda.jprl = event.jprl != void 0 ? event.jprl.jprl : null;
    this.skoda.etaz = event.etaz != void 0 ? event.etaz.etaz : null;
    this.skoda.drevinaSkup = null;
    this.skoda.drevinaSkupGuid = event.drevina != void 0 ? event.drevina.guid : null;

    if (this.skoda.lhcGuid != null && this.skoda.jprl != null && this.skoda.etaz != null && this.skoda.drevinaSkupGuid != null) {
      this.prepocitej.emit(this.skoda);
    }
  };


  /**
   * Handler pro předání dat LHP etáže.
   * @param event
   */
  lhpEtazSet(event) {
    this.skoda.lhpEtaz = event;
  }


  /**
   * Handler pro předání dat LHP dřeviny.
   * @param event
   */
  lhpDrevinaSet(event) {
    this.skoda.lhpDrevina = event;
  }


  /**
   * Změna LHC.
   * @param event
   */
  lhcChanged(event) {
    this.lhcListDto = event;
  }


  /**
   * Handler změny dat ve formuláři detailu škody.
   * @param event
  **/
  dataChangedHandler(event): void {
    var that = this;
    Object.keys(event).forEach(function (key) {
      if (key != 'guid' && key != 'typ') {
        that.skoda[key] = event[key];
      }
    });

    this.prepocitej.emit(this.skoda);
  }


  /**
   * Uložení vybrané/nové škody.
  **/
  saveSkoda(): void {
    this.messageService.clear(this.MESSAGE_SKODY_KEY);

    if (this.selectedObdobi == void 0 || this.selectedObdobi == null) {
      this.messageService.add({ severity: 'error', summary: 'Chyba', detail: 'Vyberte období', key: this.MESSAGE_SKODY_KEY });
      return;
    }

    this.skodyServices.save(this.skoda).subscribe(res => {
      this.isNewRow = false;
      this.messageUtils.showResponseMessage(this.MESSAGE_SKODY_KEY, res);
      if(res.success) this.skodaSaved.emit();
    });
  };


  /**
   * Vymazání hodnot z položek formuláře.
   * Reinicializuje Dto, tj. vyvolá změny v child componentách.
   * @param isNewRow
   */
  clearForm(isNewRow: boolean): void {
    this.isNewRow = isNewRow;

    this.initDto();
    if (this.isNewRow) {
      this.druhVypoctu = this.selectedObdobi != void 0 && this.selectedObdobi.typ == 's' ? DruhVypoctu.S6 : DruhVypoctu.U7;
    }
    else {
      this.druhVypoctu = '';
    }
  };
}
