/**
 * Typ vrstvy
**/
export enum LayerSourceType {
  //TileLayer
  tile,

  tile_wmts,

  //VectorLayer
  vector,

  //MbTile
  mbTile,

  //WFS Layer
  wfs
}
