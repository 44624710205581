import { Injectable } from "@angular/core";


/**
 * Pomocná třída poskytující barvy k odlišení náčrtů v přehledové mapě.
**/
@Injectable({
  providedIn: 'root'
})
export class ColorPalleteUtils {
  /**
   * Barevná škála.
   * Zroj: https://htmlcolors.com
  **/
  private _colorPallete = {
    0: 'rgb(211, 47, 47)', //0 (původní pořadí z palety z htmlcolor.com)
    1: 'rgb(81, 45, 168)', //3
    2: 'rgb(2, 136, 209)', //6
    3: 'rgb(56, 142, 60)', //9
    4: 'rgb(251, 192, 45)', //12
    5: 'rgb(230, 74, 25)', //15
    6: 'rgb(194, 24, 91)', //1
    7: 'rgb(25, 118, 210)', //5
    8: 'rgb(104, 159, 56)', //10
    9: 'rgb(245, 124, 0)', //14
    10: 'rgb(123, 31, 162)', //2 
    11: 'rgb(0, 151, 167)', //7
    12: 'rgb(255, 160, 0)', //13
    13: 'rgb(48, 63, 159)', //4
    14: 'rgb(0, 121, 107)', //8
    15: 'rgb(175, 180, 43)', //11
  }


  /**
   * Vrátí barvu z barevné škály.
   * @param kod {number} kód barvy/pořadí polygonu
  **/
  public explodeColor(kod: number): string {
    let key = kod % Object.keys(this._colorPallete).length;
    return this._colorPallete[key];
  }
}
