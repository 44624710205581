<form>
  <app-block-ui [block]="blockedPrepocet" [target]="krokyU7" [small]="true"></app-block-ui>
  <p-panel #krokyU7 header="Kroky výpočtu" [style]="{'margin-bottom': '1em'}"
           [toggleable]="true" [collapsed]="true" toggler="header">
    <!-- Tady je použité vlastní grid pro 8 sloupců - je založen na principu FlexGridu Primeng. -->
    <div class="p-grid" style="font-size:12px;">
      <div class="p-col-10">
        <div class="p-grid">
          <div class="p-8-col-1">
            <label>
              Plocha pro výpočet (m<sup>2</sup>)
              <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{vystavkyDto?.plochaProVypocet}}</span>
            </label>
          </div>
          <div class="p-8-col-1">
            <label>
              Skupina dřevin
              <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{vystavkyDto?.drevinaSkupinaZkratka}}</span>
            </label>
          </div>
          <div class="p-8-col-1">
            <label>
              P0
              <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{vystavkyDto?.koeficienty?.p0}}</span>
            </label>
          </div>
          <div class="p-8-col-1">
            <label>
              P1
              <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{vystavkyDto?.koeficienty?.p1}}</span>
            </label>
          </div>
          <div class="p-8-col-1">
            <label>
              P2
              <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{vystavkyDto?.koeficienty?.p2}}</span>
            </label>
          </div>
          <div class="p-8-col-1">
            <label>
              P3
              <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{vystavkyDto?.koeficienty?.p3}}</span>
            </label>
          </div>
          <div class="p-8-col-1">
            <label>
              P4
              <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{vystavkyDto?.koeficienty?.p4}}</span>
            </label>
          </div>
          <div class="p-8-col-1">
            <label>
              Celk. zásoba (m<sup>3</sup>)
              <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{vystavkyDto?.drZasCel}}</span>
            </label>
          </div>
        </div>

        <div class="p-grid">
          <div class="p-8-col-1">
            <label>
              THLPa
              <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{vystavkyDto?.thlpa}}</span>
            </label>
          </div>
          <div class="p-8-col-1">
            <label>
              HLPa
              <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{vystavkyDto?.hlpa}}</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </p-panel>
</form>
