import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { DateUtils } from 'src/app/Utils/Shared/date.utils';
import { CVyrSkladDetailDto } from 'src/app/Dto/Vyroba/CVyrSkladDetailDto';
import { ResultInfoGenericDto, ResultInfoDto } from 'src/app/Dto/Shared/ResultInfoDto';
import { CVyrSkladListDto } from 'src/app/Dto/Vyroba/CVyrSkladListDto';
import { ServiceUtils } from 'src/app/Utils/Shared/service.utils';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VyrobaSkladService {

  constructor(
    private http: HttpClient
  ) { }

  private apiGet: string = 'api/vyroba/SkladConfig/Get';
  private apiList: string = 'api/vyroba/SkladConfig/List';
  private apiSave: string = 'api/vyroba/SkladConfig/Save';
  private apiDelete: string = 'api/vyroba/SkladConfig/Delete';

  private matice: any = {};

  /**
   * Vrátí nastavení skladu pro kombinaci podvýkon, lokalita a datum. Nastavení pro zadané kombinace kešují.
   * @param podvykonGuid
   * @param lokalitaGuid
   * @param datum
   */
  get(podvykonGuid: string, lokalitaGuid: string, datum: Date): Observable<CVyrSkladDetailDto> {
    let requestDate: string = DateUtils.formatDate(datum);

    let idx = lokalitaGuid + '_' + podvykonGuid + '_' + requestDate;
    if (this.matice[idx] != void 0) {
      return of(this.matice[idx]);
    }

    var request = new EventEmitter<CVyrSkladDetailDto>();

    this.http.get<ResultInfoGenericDto<CVyrSkladDetailDto>>(this.apiGet + '/' + podvykonGuid + '/' + requestDate + '/' + lokalitaGuid)
      .subscribe(resp => {
        if (resp.success) {
          this.matice[idx] = resp.data;
          request.emit(this.matice[idx]);
        }
        else {
          request.emit(null);
        }
      });

    return request;
  }

  getByGuid(guid: string): Observable<ResultInfoGenericDto<CVyrSkladDetailDto>> {
    //return of({
    //  success: true,
    //  messages: [],
    //  data: {
    //    guid: '123',
    //    lokalitaGuid: null,
    //    opravil: null,
    //    opravilKdy: null,
    //    vlozil: 'jkosulic',
    //    vlozilKdy: new Date(),
    //    platnostOd: new Date('2020-01-01'),
    //    platnostDo: null,
    //    podvykonGuid: '4D5D3490-F587-4882-B36D-7DCB2A0E0312'.toLowerCase(),
    //    pohyby: [
    //      {
    //        druhKod: 's',
    //        guid: 'asd',
    //        lokalitaGuid: '999E15C2-60DF-F9D8-319C-EE59523CA89F'.toLowerCase(),
    //        smer: +1
    //      },
    //      {
    //        druhKod: 's',
    //        guid: 'qwe',
    //        lokalitaGuid: '736069DC-30E9-FB18-9C32-2F64577CE9E8'.toLowerCase(),
    //        smer: -1
    //      }
    //    ]
    //  }
    //});
    return this.http.get<ResultInfoGenericDto<CVyrSkladDetailDto>>(this.apiGet + '/' + guid);
  }

  list(): Observable<ResultInfoGenericDto<CVyrSkladListDto[]>> {
    //return of({
    //  success: true,
    //  messages: [],
    //  data: [{
    //    guid: '123',
    //    lokalitaGuid: null,
    //    opravil: null,
    //    opravilKdy: null,
    //    vlozil: 'jkosulic',
    //    vlozilKdy: new Date(),
    //    platnostOd: new Date('2020-01-01'),
    //    platnostDo: null,
    //    podvykonGuid: '4D5D3490-F587-4882-B36D-7DCB2A0E0312'.toLowerCase(),
    //    podvykon: 1,
    //    podvykonPopis: 'Hroubí z prořezávek',
    //    vykon: 111,
    //  }]
    //});
    return this.http.get<ResultInfoGenericDto<CVyrSkladListDto[]>>(this.apiList);
  }

  save(data: CVyrSkladDetailDto): Observable<ResultInfoGenericDto<CVyrSkladDetailDto>> {
    //console.log(data);
    return this.http.post<ResultInfoGenericDto<CVyrSkladDetailDto>>(this.apiSave, data, {})
      .pipe(catchError(ServiceUtils.transformError<ResultInfoGenericDto<CVyrSkladDetailDto>>()));
  }

  /**
   * Smazani zaznamu matice
   * @param guid guid zaznamu matice
   */
  delete(guid: string): Observable<ResultInfoDto> {
    return this.http.delete<ResultInfoDto>(this.apiDelete + '/' + guid);
  }
}
