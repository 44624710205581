<div class="p-grid p-align-stretch kniha-detail-form">
  <div class="p-col-6">
    <div class="p-grid">
      <div class="p-col-3">
        <app-label-input-display [value]="porost?.por" label="POR" tooltip="Kód porostu" [formatConent]="false"></app-label-input-display>
      </div>
      <div class="p-col-9">
        <span>Organizační úrověň</span>
        <app-org-ur [ngModel]="porost?.orgUrGuid" variant="full" [edit]="false"></app-org-ur>
      </div>
    </div>
  </div>
  <div class="p-col-6">
    <div class="p-grid">
      <div class="p-col">
        <app-ciselnik-field key="CPasOhr" [guid]="porost?.pasOhrGuid" label="Pás. ohr." variant="short" tooltip="Pásmo ohrožení imisemi"></app-ciselnik-field>
      </div>
      <div class="p-col">
        <app-label-input-display [value]="porost?.sdrPor" label="Sdr. por." tooltip="Sdružený porost"></app-label-input-display>
      </div>
      <div class="p-col">
        <app-ciselnik-field style="padding-bottom: inherit;padding-top:inherit;" key="CLesObl" [guid]="porost?.lesOblGuid" label="Les. obl." variant="short" tooltip="Lesní oblast"></app-ciselnik-field>
      </div>
      <div class="p-col">
        <app-ciselnik-field style="padding-bottom: inherit;padding-top:inherit;" key="CLesPodobl" [guid]="porost?.lesPodoblGuid" label="Les. podobl." variant="short" tooltip="Lesní podoblast"></app-ciselnik-field>
      </div>
    </div>
  </div>

  <div class="p-col-6">
    <p-table [value]="porost?.kategories" styleClass="p-datatable-gridlines p-datatable-striped pds-table-compact p-mt-2">
      <ng-template pTemplate="header">
        <tr>
          <th>Kategorie</th>
          <th>Specifikace</th>
          <th style="width: 75%;">Popis</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-kateg>
        <tr>
          <td><app-ciselnik-kod ciselnik="CKateg" [guid]="kateg?.kategGuid"></app-ciselnik-kod></td>
          <td>{{kateg.specifikace}}</td>
          <td>{{kateg.popis}}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div class="p-col-6">
    <p-table [value]="porost?.zvlStatuts" styleClass="p-datatable-gridlines p-datatable-striped pds-table-compact p-mt-2">
      <ng-template pTemplate="header">
        <tr>
          <th pTooltip="Zvláštní statut" class="tooltip-label" tooltipPosition="top">Zvl. stat.</th>
          <th style="width: 75%;">Popis</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-statut>
        <tr>
          <td>{{statut.kod}}</td>
          <td>{{statut.popis}}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <div class="p-col-12">
    Poznámka
    <div class="p-inputtext ui-corner-all two-row overflow-scroll">{{porost?.porText}}</div>
  </div>

  <p-card class="p-col-6 p-mt-2">
    <div class="p-grid p-align-stretch">
      <div class="p-col-2">
        <app-label-input-display [value]="maj?.kod" label="Majetek" [formatConent]="false"></app-label-input-display>
      </div>
      <div class="p-col-2">
        <app-ciselnik-field class="p-col-12" pcolleft="p-col-2" pcolright="p-col-10" key="CDruhVlast" [guid]="maj?.druhVlastGuid" label="Druh" variant="short" tooltip="Druh vlastnictví"></app-ciselnik-field>
      </div>
      <div class="p-col-8">
        <app-label-input-display [value]="maj?.popis" label="Název" [formatConent]="false"></app-label-input-display>
      </div>
    </div>
</p-card>

  <p-card class="p-col-6 p-mt-2">
    <div class="p-grid p-align-stretch">
      <div class="p-col-2">
        <app-label-input-display [value]="porost?.olhId" label="OLH" [formatConent]="false"></app-label-input-display>
      </div>
      <div class="p-col-2">
        <app-ciselnik-field class="p-col-12" pcolleft="p-col-2" pcolright="p-col-10" key="COlhPravTit" [guid]="porost?.olhPravTitGuid" label="Práv. tit." variant="short" tooltip="Právní titul"></app-ciselnik-field>
      </div>
      <div class="p-col-3">
        <app-label-input-display [value]="porost?.olhLic" label="Licence"></app-label-input-display>
      </div>
      <div class="p-col-5">
        <app-label-input-display [value]="porost?.olh" label="Jméno"></app-label-input-display>
      </div>
    </div>
  </p-card>


  <p-card class="p-col-12" header="Historie">
    <div class="p-grid p-align-stretch">
      <div class="p-col-6">
        Název LHC
        <div class="p-inputtext ui-corner-all">{{porost?.histLhc}}</div>
      </div>
      <div class="p-col-2">
        Platnost OD
        <div class="p-inputtext ui-corner-all">{{porost?.histLhpod}}</div>
      </div>
      <div class="p-col-2">
        Platnost DO
        <div class="p-inputtext ui-corner-all">{{porost?.histLhpdo}}</div>
      </div>
      <div class="p-col-2">
        Rozdělení
        <div class="p-inputtext ui-corner-all">{{porost?.histRozd}}</div>
      </div>
    </div>
  </p-card>
</div>
