import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { faBackspace, faCopy, faEllipsisV, faPencilAlt, faPlus, faSave, faTimes, faTrash, faUndo } from '@fortawesome/free-solid-svg-icons';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { Dropdown } from 'primeng/dropdown';
import { ResultInfoGenericDto } from 'src/app/Dto/Shared/ResultInfoDto';
import { DVyrKontrolaDetailDto } from 'src/app/Dto/Vyroba/DVyrKontrolaDetailDto';
import { DVyrKontrolaListDto } from 'src/app/Dto/vyroba/DVyrKontrolaListDto';
import { KontrolyService } from 'src/app/Services/Vyroba/kontroly.service';
import { MessagesUtils } from 'src/app/Utils/Shared/messages.utils';
import { TableComponent } from '../../Shared/table/table.component';

@Component({
  selector: 'app-vyroba-kontroly',
  templateUrl: './vyroba-kontroly.component.html',
  styleUrls: ['./vyroba-kontroly.component.css']
})
export class VyrobaKontrolyComponent implements OnInit {

  constructor(private router: Router, private kontrolyService: KontrolyService,
    private messageService: MessageService,
    private messageUtils: MessagesUtils,
    private confirmationService: ConfirmationService,
    private formBuilder: FormBuilder) { }

  faPlus = faPlus;
  faTrash = faTrash;
  faSave = faSave;
  faUndo = faUndo;
  faCopy = faCopy;
  faEllipsisV = faEllipsisV;
  faBackspace = faBackspace;
  faTimes = faTimes;
  faPencilAlt = faPencilAlt;

  items: DVyrKontrolaListDto[];
  selectedItem: DVyrKontrolaListDto;
  cols: any[] = [];
  block: boolean = false;
  filter: string;
  showDetail: boolean = false;
  showEQEditor: boolean = false;
  eQContentType: string;//obsahuje název property, která se edituje

  private readonly MESSAGE_KEY: string = "vyroba-kontroly-toast";

  @ViewChild("colDatum", { static: true }) datumTemplate: TemplateRef<any>;
  @ViewChild("colAnoNe", { static: true }) anoNeTemplate: TemplateRef<any>;
  @ViewChild("kontrolyTable", { static: true }) table: TableComponent;
  @ViewChild("dokladTyp") dokladTyp: Dropdown;

  doklady: SelectItem[] = [
    { value: 'VML', label: 'VML' },
    { value: 'OL', label: 'OL' }
  ];

  chybaOptions: SelectItem[] = [
    { value: null, label: '' },
    { value: true, label: 'Ano' },
    { value: false, label: 'Ne' }
  ];

  ngOnInit(): void {
    this._initCols();
    this._loadList();
  }

  /**
  * Inicializace sloupců pro grid
  */
  private _initCols() {
    this.cols = [
      { field: 'dokladTypKod', header: 'Doklad', class: 'col-7' },
      { field: 'chyba', header: 'Chyba', class: 'col-7', template: this.anoNeTemplate },
      { field: 'popis', header: 'Popis', class: 'col-26' },
      { field: 'poznamka', header: 'Poznámka', class: 'col-40' },
      { field: 'platnostOd', header: 'Platnost od', class: 'col-10', template: this.datumTemplate },
      { field: 'platnostDo', header: 'Platnost do', class: 'col-10', template: this.datumTemplate }
    ];
  }

  //formulář
  form = this.formBuilder.group({
    guid: [''],
    dokladTypKod: ['', Validators.required],
    chyba: ['', Validators.required],
    popis: ['', Validators.required],
    poznamka: [''],
    platnostOd: ['', Validators.required],
    platnostDo: [''],
    podminkaMeta: [''],
    podminkaCloud: ['', Validators.required],
    podminkaMobil: ['', Validators.required]
  });

  /**
   *  Nový záznam
   * */
  add(): void {
    var newData = new DVyrKontrolaDetailDto();
    newData.dokladTypKod = this.doklady[0].value;
    this.data = newData;
    this.showDetail = true;
    this.focusFirst();
  }

  /**
   *  Handler tlačítka zpět
   * */
  back(): void {
    this.router.navigate(["/vyroba"]);
  }

  /**
   * Výběr řádku
   * @param dto
   */
  onRowSelected(dto: DVyrKontrolaListDto): void {
    this.block = true;
    this.kontrolyService.getByGuid(dto.guid).subscribe(resp => { this._processDetailResponse(resp); });
    this.selectedItem = dto;
    this.showDetail = true;
  }

  /**
   * Zrušení výběru řádku
   * @param value
   */
  onRowUnSelected(value): void {
    this.showDetail = false;
    this.selectedItem = null;
  }

/**
 *  Handler načtení dat tabulky
 * */
  dataTableLoaded(): void {
    if (this.selectedItem != void 0)
      this.table.selectRowByGuid(this.selectedItem.guid);
  }

  /**
   * Uložit formulář
   * */
  save(): void {
    this.block = true;
    this.kontrolyService.save(this.data).subscribe(resp => {
      this._processDetailResponse(resp, 'Nastavení bylo uloženo.');
      this._updateInGrid(resp);
    });
  }

  /**
   *  Uložit kopii
   * */
  saveCopy(): void {
    this.block = true;
    this.form.patchValue({ guid: undefined });
    this.kontrolyService.save(this.data).subscribe(resp => {
      this._processDetailResponse(resp, 'Nastavení bylo uloženo.');
      this._updateInGrid(resp);
    });
  }

  /**
   * Handler na tlačítko zrušit
   * */
  cancel(): void {
    this.add(); // reset form
    this.showDetail = false;
    this.table.unselectAll();
    this.selectedItem = undefined;
  }

  /**
   * Po zobrazení panelu focus na první položku
   * */
  focusFirst(): void {
    setTimeout(() => { if (this.dokladTyp != void 0) this.dokladTyp.applyFocus() });
  }

  get data(): DVyrKontrolaDetailDto {
    return this.form.value;
  }

  set data(val: DVyrKontrolaDetailDto) {
    this.form.reset();
    this.form.patchValue(val);
  }

  /**
   * Zpracování odpovědi s detailem (načtení detailu do formuláře, případně zobrazení hlášky)
   * @param resp
   * @param successMessage
   */
  private _processDetailResponse(resp: ResultInfoGenericDto<DVyrKontrolaDetailDto>, successMessage?: string) {
    if (resp.success) {
      this.data = resp.data;
      this.block = false;
      this.showDetail = true;
      this.focusFirst();
      if (successMessage != undefined) {
        this._showMessage(resp, successMessage);
      }
    }
    else{
      this._showMessage(resp);
      this.block = false;
    }
  }

  /**
   * Podle obsahu response zobrazi prislusnou zpravu
   * @param resp response
   * @param successMessage text message zobrazeny v pripade, ze resp.success je true
   */
  private _showMessage(resp: ResultInfoGenericDto<DVyrKontrolaDetailDto>, successMessage?: string) {
    if (resp.success) {
      if (successMessage != undefined) {
        this.messageService.add({ severity: 'success', summary: 'OK', key: this.MESSAGE_KEY, detail: successMessage, life: MessagesUtils.TOAST_LIFE })
      }
    }
    else if (!resp.success) {
      this.messageUtils.showResponseMessage(this.MESSAGE_KEY, resp);
    }
    else {
      this.messageService.add({ severity: 'error', summary: 'Chyba', key: this.MESSAGE_KEY, detail: 'Došlo k neočekávané chybě.', life: MessagesUtils.TOAST_LIFE })
    }
  }


  /**
  * Aktualizace dat v gridu po uložení
  * @param resp
  */
  private _updateInGrid(resp: ResultInfoGenericDto<DVyrKontrolaDetailDto>) {
    if (resp.success) {
      var listDto = this.items.find(x => x.guid == resp.data.guid);

      if (listDto == undefined) {
        listDto = new DVyrKontrolaDetailDto();
        listDto.guid = resp.data.guid;
        this.items.push(listDto);
        this.items = [...this.items];
        this.selectedItem = listDto;
      }

      Object.keys(resp.data).forEach(key => {
        listDto[key] = resp.data[key];
      });
    }
  }

  /**
  * Smaže vybraný řádek
  * */
  remove(): void {
    this.confirmationService.confirm({
      message: 'Opravdu si přejete smazat vybraný řádek?',
      accept: () => {
        this.block = true;
        this.kontrolyService.delete(this.selectedItem.guid)
          .subscribe(resp => {
            this.cancel();
            this._showMessage(resp as ResultInfoGenericDto<DVyrKontrolaDetailDto>, "Kontrola byla smazána.");
            if (resp.success) {
              this._loadList();
            }
            this.block = false;
          });
      }
    });
  }


  /**
  * Načte seznam kontrol dokladu
  * */
  private _loadList(): void {
    this.kontrolyService.list().subscribe(resp => {
      if (resp.success) {
        this.items = resp.data;
      }
      else if (!resp.success) {
        this.messageUtils.showResponseMessage(this.MESSAGE_KEY, resp);
      }
      else {
        this.messageService.add({ severity: 'error', summary: 'Chyba', key: this.MESSAGE_KEY, detail: 'Došlo k neočekávané chybě.', life: MessagesUtils.TOAST_LIFE })
      }
    });
  }

  editPodminkyHandler(property: string): void {
    this.eQContentType = property;
    this.showEQEditor = true;
  }

  closeEQhandler(): void {
    this.showEQEditor = false;
  }

  eQChangedHandler(value: string): void {
    this.form.get(this.eQContentType).setValue(value);
  }
}
