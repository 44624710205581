<app-block-ui [block]="block"></app-block-ui>

<p-panel *ngIf="visible" [style]="{'margin-top': '1em'}">
  <ng-template pTemplate="header">
    <div class="p-grid p-align-center">
      <div class="p-col"><span class="p-panel-title">Změna data uzávěrky</span></div>
      <div class="p-col right">
        <button pButton type="button" label="Uložit" class="icon-btn p-button-success" (click)="update()" [disabled]="form.invalid"><fa-icon [icon]="faSave"></fa-icon></button>
        <button pButton type="button" label="Zrušit" class="icon-btn" (click)="cancel()"><fa-icon [icon]="faTimes"></fa-icon></button>
      </div>
    </div>
  </ng-template>
  <div class="p-grid validated-form-container" [formGroup]="form">
    <div class="p-col-6">
      <label for="uzaverky-update-role">Role</label>
      <p-dropdown [options]="role" formControlName="role" optionLabel="name"
                  id="uzaverky-update-role" [style]="{'width':'100%'}"></p-dropdown>
    </div>

    <div class="p-col-6">
      <label for="uzaverky-update-datum">Datum</label>
      <app-calendar formControlName="datum" id="uzaverky-update-datum"></app-calendar>
    </div>
  </div>
</p-panel>
