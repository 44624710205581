import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faCalculator, faFlagCheckered, faQuestion, faSave, faUndo, faWalking } from '@fortawesome/free-solid-svg-icons';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { NextOnEnterDirective } from 'src/app/Directives/next-on-enter.directive';
import { EtazVolbaDto } from 'src/app/Dto/Lhp/EtazVolbaDto';
import { CiselnikListDto } from 'src/app/Dto/Shared/CiselnikListDto';
import { CDruhMzdyDetailDto } from 'src/app/Dto/Vyroba/CDruhMzdyDetailDto';
import { CVyrMaticeDetailDto } from 'src/app/Dto/Vyroba/CVyrMaticeDetailDto';
import { VmlDetailDto } from 'src/app/Dto/Vyroba/VmlDetailDto';
import { VmlMaterialDetailDto } from 'src/app/Dto/Vyroba/VmlMaterialDetailDto';
import { VmlPremieNahradaDetailDto } from 'src/app/Dto/Vyroba/VmlPremieNahradaDetailDto';
import { VmlProstredekDetailDto } from 'src/app/Dto/Vyroba/VmlProstredekDetailDto';
import { VmlRadekDetailDto } from 'src/app/Dto/Vyroba/VmlRadekDetailDto';
import { CiselnikyService } from 'src/app/Services/Shared/ciselniky.service';
import { MaticeService } from 'src/app/Services/Vyroba/matice.service';
import { VyrobaService } from 'src/app/Services/Vyroba/vyroba.service';
import { MessagesUtils } from 'src/app/Utils/Shared/messages.utils';
import { UuidUtils } from 'src/app/Utils/Shared/uuid.utils';
import { RidiciMaticeItems } from 'src/app/Utils/Vyroba/ridici-matice-items.utils';
import { CanDeactivateComponent } from '../../Shared/can-deactivate/can-deactivate.component';
import { VyrobaDochazkaComponent } from '../vyroba-dochazka/vyroba-dochazka.component';
import { VyrobaKontrolaComponent } from '../vyroba-kontrola/vyroba-kontrola.component';

@Component({
  selector: 'app-vyroba-vml-edit',
  templateUrl: './vyroba-vml-edit.component.html',
  styleUrls: ['./vyroba-vml-edit.component.css']
})
export class VyrobaVmlEditComponent implements OnInit, AfterViewInit, CanDeactivateComponent {

  private readonly MESSAGE_KEY: string = "vml-edit-toast";
  private readonly SAVE_DLG_KEY: string = 'vml-confirm-dlg';
  private dataLoaded: boolean = false;
  edit: boolean = false;
  block: boolean = false;
  showHelp: boolean = false;
  private readonly NEZUCT_PLADBY_KOD: string = "981";
  private nezuctPlatbyGuid: string;
  private readonly ZPUSOB_PROVEDENI_CIZIMI_KOD: string = "50";

  @ViewChild('radekForm') radekForm: ElementRef;
  @ViewChild('kontrolaTree') kontrolaTree: VyrobaKontrolaComponent;
  @ViewChild(NextOnEnterDirective) nextOnEnter: NextOnEnterDirective;
  @ViewChild('dochazka') dochazka: VyrobaDochazkaComponent;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private vyrobaService: VyrobaService,
    private ciselnikyService: CiselnikyService,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private messageUtils: MessagesUtils,
    private uuidUtils: UuidUtils,
    private maticeService: MaticeService,
    private confirmationService: ConfirmationService
  ) { }

  faSave = faSave;
  faFlagCheckered = faFlagCheckered;
  faUndo = faUndo;
  faQuestion = faQuestion;
  faCalculator = faCalculator;
  faWalking = faWalking;

  etazReset: EventEmitter<any> = new EventEmitter();//inicializace komponenty etáže při změně řádku

  mzdOptions: SelectItem[] = [
    { value: null, label: '' },
    { value: true, label: 'Ano' },
    { value: false, label: 'Ne' }
  ];


  /**
   * Úplná data číselníku druhu mzdy pro obecné dohledávání (mohu si uložit, když už na něj stejně sahám...)
   */
  druhMzdyVse: CDruhMzdyDetailDto[] = [];

  /**
   * Data číselníku druhu mzdy omezená na "základní" druhy (nejsou zde prémie/náhrady a odchylka)
   */
  druhMzdyZaklad: CDruhMzdyDetailDto[] = [];

  /**
   * Data číselníku druhu mzdy pro prémie/náhrady
   */
  druhMzdyPremieNahrady: CDruhMzdyDetailDto[] = [];

  formData = this.formBuilder.group({
    guid: [''],
    datum: [''],
    orgUrGuid: [''],
    cislo: [''],
    radky: this.formBuilder.array([])
  });

  get radky() {
    return (this.formData.get('radky') as FormArray);
  }

  get dokladGuid() {
    return this.formData.get('guid').value;
  }

  get orgUrGuid() {
    return this.formData.get('orgUrGuid').value;
  }

  aktivniRadekGroup: FormGroup = this._createEmptyRowGroup();

  get aktivniVykonGuid() {
    return this.aktivniRadekGroup.get('vykonGuid');
  }

  /**
   * Informace, zda je aktivní řádek souhrnným řádkem
   */
  get aktivniJeSouhrn() {
    return (1 * this.aktivniRadekGroup.get('cislo').value) < 0;
  }

  get aktivniMaterialy() {
    return (this.aktivniRadekGroup.get('materialy') as FormArray);
  }

  get aktivniProstredky() {
    return (this.aktivniRadekGroup.get('prostredky') as FormArray);
  }

  get aktivniPremieNahrady() {
    return (this.aktivniRadekGroup.get('premieNahrady') as FormArray);
  }


  aktivniRadek: VmlRadekDetailDto = null;
  aktivniRadekIdx: number = 0;
  //aktivuje/deaktivuje aktualizaci formData. Jde o to, aby se při změně řádku zbytečně neaktualizoval
  //formulářový model, ale pouze aktivniRadekGroup
  enableUpdate: boolean = true;

  materialyActivableFields = Object.keys(RidiciMaticeItems.Items)
    .filter(key => RidiciMaticeItems.Items[key].parent == 'materialy');
  prostredkyActivableFields = Object.keys(RidiciMaticeItems.Items)
    .filter(key => RidiciMaticeItems.Items[key].parent == 'prostredky');
  //seznam povolených položek definovaný řídící maticí
  _activeFields: string[] = [];

  povolMaterialy: boolean = false;
  povolProstedky: boolean = false;
  povolPremieNahrady: boolean = false;

  //je potřeba odlišit focus při inicializaci, aby neskočil na JPRL
  private _focusAfterInit: boolean = true;

  /**
   * Seznam povolených MJ pro aktivní řádek.
  **/
  povoleneMj: CiselnikListDto[] = [];

  showDochazka: boolean = false;


  /**
   * Způsob provedení s kódem 50
   **/
  private _zpusobProvedeniCiczimiGuid: string;

  vzabaProstredkuPopis: any;

  /**
   * Vytvoří prázdný formGroup pro řádek dokladu.
  **/
  private _createEmptyRowGroup(): FormGroup {
    this.etazReset.emit();
    var cislo = this.radky.length == 0 ? '1' : null;
    return this.formBuilder.group({
      guid: [null],
      cislo: [cislo],
      zaznam: [null],
      podvykonGuid: [null],
      etazGuid: [null],
      indexHoliny: [null],
      druhTezbyGuid: [null],
      lokalitaGuid: [null],
      zpusobProvedeniGuid: [null],
      plocha: [null],
      sortimentGuid: [null],
      sazeniceGuid: [null],
      drevinaGuid: [null],
      plRadekGuid: [null],
      mjGuid: [null],
      mnozstvi: [null],
      dodavatelGuid: [null],
      druhMzdyGuid: [null],
      tarif: [null],
      kc: [null],
      poznamka: [null],
      hmotnatost: [null],
      zakazkaGuid: [null],
      odpracDny: [null],
      hodiny: [null],
      normohodiny: [null],
      pribVzd: [null],
      skodlivyCinitelGuid: [null],
      akceGuid: [null],
      datumAplikace: [null],
      mzd: [null],
      lhc: [null],
      odd: [null],
      dil: [null],
      por: [null],
      psk: [null],
      etaz: [null],
      vykonGuid: [null],
      procPlneni: [null],
      prostredky: this.formBuilder.array([]),
      materialy: this.formBuilder.array([]),
      premieNahrady: this.formBuilder.array([]),
      pocetVj: [null],
      pocetVjPlocha: [null]
    });
  }

  /**
   * Funkce pro seřazení řádků s přihlednutím k souvisejícím (stejná hodnota pole "záznam") a souhrnným řádkům (pro řádky se stejným "záhnam")
   * @param a
   * @param b
   */
  private rowCompareFn = (a, b) => {
    if (a.zaznam == b.zaznam) {
      if (a.cislo > 0 && b.cislo < 0) {
        return -1; // A je "normální" řádek a B je "souhrnný" řádek - souhrnné jsou vždy až za normálníma...
      }
      else if (a.cislo < 0 && b.cislo > 0) {
        return 1; // ... opačný scénář (A je "souhrnný"...)
      }
      else {
        // abs je opět díky souhrnným, aby se nezařadily v opačném pořadí pracovníků, než je natypované
        return Math.abs(a.cislo) - Math.abs(b.cislo);
      }
    }
    else {
      return a.zaznam - b.zaznam;
    }
  };

  ngOnInit(): void {

    this.vzabaProstredkuPopis = { "true": "Účelový", "false": "Využitý"  };

    this.ciselnikyService.getCislenik('CZpusobProvedeni', null).subscribe(resp => {
      if (resp != void 0 && resp.length > 0) {
        this._zpusobProvedeniCiczimiGuid = resp.find(x => x.kod == this.ZPUSOB_PROVEDENI_CIZIMI_KOD)?.guid;
      }
    })

    //Handler aktualizací hodnot políček ve formuláři pod gridem, tj. aktivního řádku.
    //Zajistí, že se nová hodnota přepíše do formData.
    Object.keys(this.aktivniRadekGroup.controls).forEach(key => {
      this.aktivniRadekGroup.get(key).valueChanges.subscribe(changedValue => {
        if (this.enableUpdate) {
          let rowToUpdate = ((this.radky.controls[this.aktivniRadekIdx]) as FormGroup);
          if (rowToUpdate != void 0) {
            let itemToUpdate = rowToUpdate.get(key);
            if (itemToUpdate != void 0) {
              if (key == 'materialy' || key == 'prostredky' || key == 'premieNahrady') {
                this.enableUpdate = false;
                //FIXME: v changes přijdou hodnoty pouze z aktivních polí,
                //tj.důsledkem je, že se z formData smažou hodnoty(i FormControl) pro neaktivní položky materialu a prostredku
                this._setFormArray((itemToUpdate as FormArray), changedValue);
                setTimeout(() => { this.enableUpdate = true }, 0, this);
              }
              else {
                itemToUpdate.setValue(changedValue);
              }
            }
          }
        }
      });
    });

    this.ciselnikyService.getCislenik('CDruhMzdy', null).subscribe((data) => {
      this.block = false;
      this.druhMzdyVse = data.map(x => x as CDruhMzdyDetailDto);
      this.druhMzdyZaklad = this.druhMzdyVse.filter(x => !x.nahrada && !x.premie && !x.odchylka);
      this.druhMzdyPremieNahrady = this.druhMzdyVse.filter(x => x.premie || x.nahrada);
      this.nezuctPlatbyGuid = this.druhMzdyVse.find(x => x.kod.trim() == this.NEZUCT_PLADBY_KOD)?.guid;

      this._initDoklad();
    });

  }

  ngAfterViewInit(): void {
    setTimeout(() => { this.block = !this.dataLoaded; });
  }


  /**
   * Inicializace dokladu.
  **/
  private _initDoklad(): void {
    //dotažení detailu dokladu dle guidu z URL
    this.activatedRoute.params.subscribe(param => {
      var guid = param.guid;
      this.edit = param.state == "edit" ? true : false;
      this.vyrobaService.getVmlDetail(guid).subscribe(resp => {
        if (resp.success) {
          this._setFormModelData(resp.data);

          if (resp.data.radky.length > 0) {
            this.aktivniRadek = this.radky.controls[this.aktivniRadekIdx].value;
            this._fillRadekFormGroup(this.aktivniRadekGroup, this.aktivniRadek);
            this.setFocusOnFirst();
          }
          else {
            this.addRadek();
          }
        }
        else if (!resp.success) {
          this.messageUtils.showResponseMessage(this.MESSAGE_KEY, resp);
        }
        else {
          this.messageService.add({ severity: 'error', summary: 'Chyba', key: this.MESSAGE_KEY, detail: 'Došlo k neočekávané chybě.', life: MessagesUtils.TOAST_LIFE })
        }
        setTimeout(() => {
          this.dataLoaded = true;
          this.updateMzdy();
        }, 200);
      });
    });
  }

  /**
   * Překlopí dto s daty do fomuláře.
   * @param dto
   */
  private _setFormModelData(dto: VmlDetailDto): void {
    dto.radky = dto.radky.sort(this.rowCompareFn);
    this.formData.patchValue(dto);
    this._setRowsFormArray((this.radky as FormArray), dto.radky);
  }


  /**
   * Naplnění formArray řádků v modelu formuláře.
   * @param formArray
   * @param data
   */
  private _setRowsFormArray(formArray: FormArray, data: VmlRadekDetailDto[]): void {
    if (data.length > 0) {
      formArray.clear();
      data.forEach((row: VmlRadekDetailDto) => {
        let rowGroup = this._createEmptyRowGroup();
        rowGroup.patchValue(row);
        this._setFormArray((rowGroup.get('prostredky') as FormArray), row.prostredky);
        this._setFormArray((rowGroup.get('materialy') as FormArray), row.materialy);
        this._setFormArray((rowGroup.get('premieNahrady') as FormArray), row.premieNahrady);
        formArray.push(rowGroup);
      });
    }
  }


  /**
   * Naplnění formArray v řádku modelu formuláře.
   * @param formArray
   * @param data
   */
  private _setFormArray(formArray: FormArray, data: any[]): void {
    formArray.clear();
    if (data != void 0)
      data.forEach(item => formArray.push(this.formBuilder.group(item)));
  }


  /**
   * Stisk klávesy. U "klíčových" kláves provede požadovanou akci, ale nenapíše znak, pokud je tisknutelný (např. "*", "/").
   * @param event
   */
  onKeyDown(event: KeyboardEvent): void {
    switch (event.key) {
      case "PageUp":
        event.preventDefault();
        this.onArrowUp();
        break;
      case "PageDown":
        event.preventDefault();
        this.onArrowDown();
        break;
      case "*":
        event.preventDefault();
        this.addRadek();
        break;
      case "/":
        event.preventDefault();
        this._copyValue(event);
        break;
    }
  }


  /**
   * Pohyb v řádcích gridu nahoru.
  **/
  onArrowUp(): void {
    this.dataLoaded = false;
    this.block = true;
    this.etazReset.emit();
    var i = this.aktivniRadekIdx - 1;
    if (i > -1) {
      this.aktivniRadekIdx = i;
      this.aktivniRadek = this.radky.value[this.aktivniRadekIdx];
      this._fillRadekFormGroup(this.aktivniRadekGroup, this.aktivniRadek);
      this.setFocusOnFirst();
    }
  }


  /**
   * Pohyb v řádcích gridu dolů.
  **/
  onArrowDown(): void {
    this.dataLoaded = false;
    this.block = true;
    this.etazReset.emit();
    var i = this.aktivniRadekIdx + 1;

    if (i < this.radky.value.length) {
      this.aktivniRadekIdx = i;
      this.aktivniRadek = this.radky.value[this.aktivniRadekIdx];
      this._fillRadekFormGroup(this.aktivniRadekGroup, this.aktivniRadek);
      this.setFocusOnFirst();
    }
  }


  /**
   * Zkopíruje hodnotu políčka z předcházejícího řádku. Vyžaduje atribut 'name' u vstupního políčka pro které se má hodnota kopírovat shodný
   * s klíčem odpovídající položky v modelu formuláře.
   * @param event
   */
  private _copyValue(event: KeyboardEvent): void {
    let id: string = (event.target as any).id;
    if (id) {
      // ID má "dohodnutou" strukturu {kde}-{co}-{typ}, zde potřebuji {co}
      let tokens = id.split("-");
      let controlName: string;
      if (tokens.length > 1) {
        controlName = tokens[1];
        let value = this.radky.value[this.aktivniRadekIdx - 1][controlName];
        this.aktivniRadekGroup.get(controlName).setValue(value);
        this.nextOnEnter.onEnter(event);
      }
    }
  }


  /**
   * Založní nového řádku. Kopíruje z aktivního řádku sortiment a JPRL (etáž).
  **/
  addRadek(): void {
    this.etazReset.emit();
    let newRow = this._createEmptyRowDto();
    if (this.radky.value.length > 0) {
      newRow.cislo = Math.max(...this.radky.value.map(r => r.cislo)) + 1;
    }

    if (this.aktivniRadek != null) {
      newRow.podvykonGuid = this.radky.value[this.aktivniRadekIdx].podvykonGuid;
      newRow.vykonGuid = this.radky.value[this.aktivniRadekIdx].vykonGuid;
      newRow.mjGuid = this.radky.value[this.aktivniRadekIdx].mjGuid;
      newRow.zaznam = this.radky.value[this.aktivniRadekIdx].zaznam + 1;
    }
    else {
      newRow.zaznam = 1;
    }

    this.aktivniRadekIdx = this.radky.length;
    this.aktivniRadek = newRow;
    this._fillRadekFormGroup(this.aktivniRadekGroup, this.aktivniRadek);
    this._fillRadekFormGroup(this._createEmptyRowGroup(), this.aktivniRadek, true); //zde se nesmí pro push do formModelu použít aktivniRadekGroup kvůli referencim
    this.setFocusOnFirst();
  }


  /**
   * Smazání vybraného datového řádku.
  **/
  delRadek(): void {
    this.etazReset.emit();
    if (this.aktivniRadek != null) {
      (this.radky as FormArray).removeAt(this.aktivniRadekIdx);
      if (this.radky.value.length == this.aktivniRadekIdx) this.aktivniRadekIdx = this.radky.value.length - 1;
      this.aktivniRadek = this.radky.value[this.aktivniRadekIdx];
      if (this.aktivniRadek == undefined) {
        this.addRadek();
      }
      else {
        let counter = 0;
        this.radky.controls.forEach((r: FormGroup) => {
          var cislo = r.get("cislo");
          if (cislo.value >= 0) { // rovnost je pro opravu dřívějšího nepořádku
            cislo.setValue(++counter);
          }
        });
        this._fillRadekFormGroup(this.aktivniRadekGroup, this.aktivniRadek);
        this.setFocusOnFirst()
      }
    }
  }


  /**
   * Handler výběru řádku.
   * @param event
   */
  rowSelectHandler(event) {
    this.dataLoaded = false;
    this.block = true;
    this.etazReset.emit();
    this.aktivniRadekIdx = event.index;
    this.aktivniRadek = this.radky.value[this.aktivniRadekIdx];
    this._fillRadekFormGroup(this.aktivniRadekGroup, this.aktivniRadek);
    this.setFocusOnFirst();
  }


  /**
   * Handler odvybrání řádku. Zařídí, že se řádek znova vybere - tj. vždy je řádek vybraný.
   * @param event
   */
  rowUnSelectHandler(event) {
    this.etazReset.emit();
    setTimeout((that) => {
      that.aktivniRadek = event.data;
      that.setFocusOnFirst()
    }, 0, this, event);
  }


  /**
   * Vytvoří novou instanci formGroup řádku a naplní ji daty.
   * @param rowToSet
   */
  private _fillRadekFormGroup(group: FormGroup, rowToSet: VmlRadekDetailDto, addToModel: boolean = false): void {
    this.enableUpdate = false;
    group.patchValue(rowToSet);
    this._setFormArray((group.get('prostredky') as FormArray), rowToSet.prostredky);
    this._setFormArray((group.get('materialy') as FormArray), rowToSet.materialy);
    this._setFormArray((group.get('premieNahrady') as FormArray), rowToSet.premieNahrady);
    setTimeout(() => { this.enableUpdate = true }, 0, this);

    if (addToModel) {
      (this.radky as FormArray).push(group);
    }
  }


  /**
   * Vytvoří prázdné dto řádku se všemi propertami. Guid vygeneruje - je to potřeba pro indetifikaci nových řádků při jejich editaci.
  **/
  private _createEmptyRowDto(): VmlRadekDetailDto {
    let emptyRowDto = new VmlRadekDetailDto();
    Object.keys(this.aktivniRadekGroup.value).forEach(key => {
      emptyRowDto[key] = null;
    });

    emptyRowDto.guid = this.uuidUtils.GenerateUuid();
    emptyRowDto.materialy = [];
    emptyRowDto.prostredky = [];
    emptyRowDto.cislo = 1;
    emptyRowDto.premieNahrady = [];
    emptyRowDto.mzd = false;

    return emptyRowDto;
  }


  /**
   * Uložení dokladu.
   * @param dochazka po uložení se zobrazí dialog s docházkou
   */
  save(dochazka: boolean = false): void {
    this.block = true;
    let data: VmlDetailDto = this.dataToSave();

    if (data != void 0)
      this.vyrobaService.saveVml(data).subscribe(res => {
        this.block = false;
        this.messageUtils.showResponseMessage(this.MESSAGE_KEY, res);
        this._cleanDirty();
        if (dochazka) {
          this.showDochazka = true;
        }
      }, error => {
        this.block = false;
        this.messageService.add({ key: this.MESSAGE_KEY, severity: 'error', summary: 'Chyba', detail: "Neočekávaná chyba.", life: MessagesUtils.TOAST_LIFE });
      });
  }

  /**
   * Uložení a dokončení dokladu
  **/
  saveAndFinish(): void {
    this.confirmationService.confirm({
      key: this.SAVE_DLG_KEY,
      header: 'Uložit a dokončit',
      message: 'V případě, že doklad neobsahuje chyby, bude odeslán do ekonomické agendy. Přejete si pokračovat?',
      accept: () => {
        this.block = true;
        let data: VmlDetailDto = this.dataToSave();
        if (data != void 0)
          this.vyrobaService.saveAndFinish(data, "vml").subscribe(res => {
            if (!res.success && res.data?.length > 0) {
              this.kontrolaTree.resolveResult(res);
            } else {
              this.messageUtils.showResponseMessage(this.MESSAGE_KEY, res);
              if (res.success) {
                this.router.navigate(['vyroba', 'vml', "detail", data.guid]);
              }
            }


            this.block = false;
          }, error => {
            this.block = false;
            this.messageService.add({ key: this.MESSAGE_KEY, severity: 'error', summary: 'Chyba', detail: "Neočekávaná chyba.", life: MessagesUtils.TOAST_LIFE });
          });
      }
    });
  }

  /**
   * Připraví data pro uložení
   * */
  dataToSave(): VmlDetailDto {
    let data: VmlDetailDto = this.formData.value;
    if (data.radky.map(r => r.podvykonGuid).filter(p => p == null || p == '').length > 0) {
      this.block = false;
      this.messageService.add({ key: this.MESSAGE_KEY, summary: 'Pozor', severity: 'warn', detail: 'Některý řádek nemá vyplněný podvýkon.', life: MessagesUtils.TOAST_LIFE });
      return;
    }

    // Oprava data aplikace v případě změny odbdobí dokladu. Při typování dne aplikace se to hlídá, ale je potřeba opravit
    // řádky, které uživatel needitoval po případné změně data dokladu.
    // Chování v případě neplatného dne po změně obodbí (např. datum aplikace 2020-07-31, období se změní na 2020-06)
    // je v diskuzi(tahle implementace z toho vyrobí 2020 - 07 - 01)
    data.radky.forEach(x => {
      if (x.datumAplikace && (x.datumAplikace.getMonth() != data.datum.getMonth() || x.datumAplikace.getFullYear() != data.datum.getFullYear())) {
        x.datumAplikace.setFullYear(data.datum.getFullYear());
        x.datumAplikace.setMonth(data.datum.getMonth());
      }

      //tu se doplní chybjející property v dto, které mohou chybět kvůli deaktivovaným polím dle matice
      if (x.prostredky.length > 0) {
        x.prostredky.forEach(prostredek => {
          if (prostredek.podvykonGuid == void 0) prostredek.podvykonGuid = null;
          if (prostredek.vykonGuid == void 0) prostredek.vykonGuid = null;
          if (prostredek.domOrgUrGuid == void 0) prostredek.domOrgUrGuid = null;
          if (prostredek.hodiny == void 0) prostredek.hodiny = null;
          if (prostredek.mnozstvi == void 0) prostredek.mnozstvi = null;
        });
      }
    });
    return data;
  }


  /**
   * Ukončení editace detailu návratem na seznam dokladů VML. Pokud nebyly uloženy změny, tak vyběhne confirm dialog.
  **/
  cancel(): void {
    this.router.navigate(['vyroba', 'vml'], { queryParams: { "dokladGuid": this.formData.get('guid').value } });
  }

  /**
   * Přidá nový materiál do aktívního řádku.
  **/
  addMaterial(): void {
    if (this.povolMaterialy) {
      let newMaterial = new VmlMaterialDetailDto();
      newMaterial.guid = null;
      newMaterial.materialGuid = null;
      newMaterial.aplDavka = null;
      newMaterial.mnozstvi = null;

      (this.aktivniMaterialy as FormArray).push(this.formBuilder.group(newMaterial));
      this._activateSubRowFields(this.materialyActivableFields, this.aktivniMaterialy, this._activeFields);
    }
  }


  /**
   * Odebere materiál z aktívního řádku.
   * @param idx
   */
  removeMaterial(idx): void {
    if (this.povolMaterialy) {
      (this.aktivniMaterialy as FormArray).removeAt(idx);
    }
  }


  /**
   * Přidá nový prostredek do aktívního řádku.
  **/
  addProstredek(): void {
    if (this.povolProstedky) {
      this.ciselnikyService.getCislenik('CProstredek', null).subscribe(resp => {
        if (resp != void 0 && resp.length > 0) {
          let newProstredek = new VmlProstredekDetailDto();
          newProstredek.guid = null;
          newProstredek.prostredekGuid = null;
          newProstredek.domOrgUrGuid = null;
          newProstredek.hodiny = null;
          newProstredek.mnozstvi = null;
          newProstredek.vykonGuid = null;
          newProstredek.podvykonGuid = null;
          newProstredek.ucelovy = false;

          (this.aktivniProstredky as FormArray).push(this.formBuilder.group(newProstredek));
          this._activateSubRowFields(this.prostredkyActivableFields, this.aktivniProstredky, this._activeFields);
        }
      });
    }
  }


  /**
   * Odebere materiál z aktívního řádku.
   * @param idx
   */
  removeProstredek(idx): void {
    if (this.povolProstedky) {
      (this.aktivniProstredky as FormArray).removeAt(idx);
    }
  }


  addPremieNahrada(): void {
    if (this.povolPremieNahrady) {
      let newPremieNahrada: VmlPremieNahradaDetailDto = {
        guid: null,
        druhMzdyGuid: null,
        sazba: null,
        kc: null
      };
      this.aktivniPremieNahrady.push(this.formBuilder.group(newPremieNahrada));
    }
  }

  removePremieNahrada(idx): void {
    if (this.povolPremieNahrady) {
      this.aktivniPremieNahrady.removeAt(idx);
    }
  }

  /**
   * Handler změny podvýkonu
   * @param event
   */
  onPodvykonChange(event): void {
    this.block = true;
    this.ciselnikyService.getCislenik('CPodvykon', null).subscribe(resp => {
      let podvykon = resp.find(x => x.guid == event);
      if (podvykon != void 0) {
        this.maticeService.get('VML', podvykon.guid, this.formData.get('datum').value).subscribe(matice => {
          if (matice == null) {
            this._clearPovoleneMj();
            this.activateFields([]);
          }
          else {
            var inputs = this.radekForm.nativeElement.querySelectorAll('input:not([disabled])');
            var podvykonInput: HTMLInputElement;
            if (inputs.length >= 2) {
              podvykonInput = <HTMLInputElement>inputs[2];
            }
            this._setPovoleneMj(matice);
            this.activateFields(matice.polozky, podvykonInput);
          }
          this.block = false;
        });
      }
      else {
        this._clearPovoleneMj();
        this.activateFields([]);
        this.block = false;
      }
    });
  }


  /**
   * Naplní, resp. omezí nabídku povolených MJ a přednastaví fakturační MJ.
   * @param matice
   */
  private _setPovoleneMj(matice: CVyrMaticeDetailDto): void {
    this.ciselnikyService.getCislenik('CMj', null).subscribe(mjResp => {
      this.povoleneMj = mjResp.filter(x => matice.povolMj.includes(x.guid));
    });

    if (this.aktivniRadek != void 0) {
      this.aktivniRadek.mjGuid = matice.mjFakturaceGuid;
      this.aktivniRadekGroup.get('mjGuid').setValue(matice.mjFakturaceGuid);
    }
  }


  /**
   * Naplní nabídku povolených MJ bez omezení maticí a vyprázdní přednastavenou fakturační MJ.
  **/
  private _clearPovoleneMj(): void {
    this.ciselnikyService.getCislenik('CMj', null).subscribe(mjResp => {
      this.povoleneMj = mjResp;
    });

    if (this.aktivniRadek != void 0) {
      this.aktivniRadek.mjGuid = null;
      this.aktivniRadekGroup.get('mjGuid').setValue(null);
    }
  }


  /**
   * Aktivace polí formuláře dle řídící matice.
   * @param activeFields
   */
  activateFields(activeFields: string[], podvykonInput: HTMLInputElement = null): void {
    this._activeFields = activeFields;
    this.enableUpdate = false;

    Object.keys(RidiciMaticeItems.Items).forEach(maticeKey => {
      if (RidiciMaticeItems.Items[maticeKey].parent == null) { // v tomto průchodu řeším jen první úroveň = nemá parent 
        let item = this.aktivniRadekGroup.get(RidiciMaticeItems.Items[maticeKey].formKey);
        if (item != void 0) {
          if (this._activeFields.indexOf(maticeKey) > -1) {
            item.enable();
          }
          else {
            item.disable();
          }
        }
      }
    });

    this.povolMaterialy = this._activeFields.indexOf('Materialy') > -1;
    this.povolProstedky = this._activeFields.indexOf('Prostredky') > -1;
    this.povolPremieNahrady = this._activeFields.indexOf('PremieNahrady') > -1;

    if (this.povolMaterialy) {
      this._activateSubRowFields(this.materialyActivableFields, this.aktivniMaterialy, this._activeFields);
    }

    if (this.povolProstedky) {
      //pokud není zapnutý výkon, nebo podvýkon, tak vypnout oba
      var vykonItems = ["Prostredky_PodvykonGuid", "Prostredky_VykonGuid"]
      if (this._activeFields.indexOf("Prostredky_PodvykonGuid") == -1 || this._activeFields.indexOf("Prostredky_VykonGuid") == -1)
        this._activeFields = this._activeFields.filter(x => !vykonItems.includes(x));

      this._activateSubRowFields(this.prostredkyActivableFields, this.aktivniProstredky, this._activeFields);
    }

    setTimeout(() => {
      this.enableUpdate = true;
      if (podvykonInput != void 0 && !this._focusAfterInit) {//pokud došlo ke změně, input existuje a už proběhla inicializace, tak se přejde na další pole.
        let ev = new KeyboardEvent('keyup', {
          code: 'Enter',
          key: 'Enter',
          view: window,
          bubbles: true
        });
        podvykonInput.dispatchEvent(ev);
      } else {//v ostatních případech focus na výkon
        if (podvykonInput != void 0) {//při inicializaci se volá dvakrát. U prvního input ještě neexistuje.
          this._focusAfterInit = false;
          this._cleanDirty();
        }
        this.setFocusOnFirst();
      }
    }, 0, this);
  }


  /**
   * Aktivování/deaktivování políček materiálů a prostředků.
   * @param fieldsToActive
   * @param arrayToProcess
   * @param activeFields
   */
  private _activateSubRowFields(fieldsToActive: string[], arrayToProcess: FormArray, activeFields: string[]): void {
    fieldsToActive.forEach(field => {
      let activate = activeFields.indexOf(field) > -1;
      let fieldName = RidiciMaticeItems.Items[field].formKey;
      arrayToProcess.controls.forEach((group: FormGroup) => {
        let item = group.get(fieldName);
        if (item != void 0) {
          if (activate) {
            item.enable();
          } else {
            item.disable();
          }
        }
      })
    });
  }

  /**
   * Handler změny jprl
   * @param value
   */
  jprlChanged(value: EtazVolbaDto): void {
    if (value != void 0) {
      Object.keys(value).forEach(key => {
          this.aktivniRadekGroup.get([key]).setValue(value[key]);
      });
    }
  }


  /**
   * Nastaví focus na první pole formuláře = položka "Záznam".
  **/
  public setFocusOnFirst(): void {
    setTimeout(() => {
      // tím bych měl získat pole "záznam", které je první a vždy aktivní (nevztahuje se na něj řídicí matice)
      var input = this.radekForm.nativeElement.querySelector('input');
      if (input) {
        input.focus();
        input.select();
      }
      this.dataLoaded = true;
      this.block = false;
    });
  }


  /**
   * Aktualizuje částky v kolekci prémie/náhrady zadaného řádku (použitelné pro datový i souhrnný řádek)
   * @param values
   */
  private _updatePremieNahrady(): void {
    if (this.aktivniRadekGroup.value.premieNahrady) {
      this.enableUpdate = false;
      for (var i = 0; i < this.aktivniPremieNahrady.length; i++) {
        let ppControl = this.aktivniPremieNahrady.at(i);
        let pp = ppControl.value;
        let sazba = pp.sazba;

        let druh = this.druhMzdyPremieNahrady.find(x => x.guid == pp.druhMzdyGuid);
        var kc: number;
        if (sazba && this.aktivniRadekGroup.get('kc').value && druh?.premie) { // sazba prémie je v % a počítá se z korunového základu řádku
          kc = this._roundKc(druh, this.aktivniRadekGroup.get('kc').value * sazba / 100);
        }
        else if (sazba && druh?.nahrada) { // sazba náhrady je v Kč a počítá se z výkonových jednotek (případně plochy)
          kc = this._roundKc(druh, (druh.plocha ? this.aktivniRadekGroup.get('pocetVjPlocha').value : this.aktivniRadekGroup.get('pocetVj').value) * sazba);
        }

        ppControl.get('kc').setValue(kc);
      }

      let premieAktivnihoRadku = (this.formData.get('radky') as FormArray).at(this.aktivniRadekIdx)?.get('premieNahrady') as FormArray;
      if (premieAktivnihoRadku != void 0)
        this._setFormArray(premieAktivnihoRadku, this.aktivniPremieNahrady.value);
      this.enableUpdate = true;
    }
  }

  /**
   * Vypočítá a vrátí koruny pro zadaný řádek podle nastavení číselníku druh mzdy.
   * K použití na aktualizovaném "obyčejném" řádku a při výpočtu souhrnného řádku.
   * @param values
   */
  private _calculateKc(values: VmlRadekDetailDto): number {
    let druh = this.druhMzdyZaklad.find(x => x.guid == values.druhMzdyGuid);
    let kc: number = values.kc;

    if (druh && !druh.smluvniOdmena) {
      if (druh.casovaMzda) {
        // časová mzda je vždy tarif * hodiny
        kc = 1.0 * values.hodiny * values.tarif;
      }
      else {
        // jinak se řídíme nastavením, zda použít plochu a/nebo normohodiny
        let mnozstvi = 0;

        if (druh.plocha) {
          mnozstvi = druh.normohodiny ? values.pocetVjPlocha : values.plocha;
        }
        else {
          mnozstvi = druh.normohodiny ? values.pocetVj : values.mnozstvi;
        }

        kc = mnozstvi * values.tarif;
      }

      kc = this._roundKc(druh, kc);
    }

    return isNaN(kc) ? 0 : kc;
  }

  /**
   * Zaokrouhlí vypočtené koruny podle druhu mzdy.
   * @param druh
   * @param kc
   */
  private _roundKc(druh: CDruhMzdyDetailDto, kc: number): number {
    //console.log('Kč před zaokrouhlením:', kc, Math.round(kc * 1000) / 1000);
    // prní zaokrouhlím na 3 místa díky nepřesnostem floating point čísel v JS
    let result = Math.round(kc * 1000) / 1000;

    if (druh.celeKc) {
      result = Math.round(result);
    }
    else {
      result = Math.round(result * 10) / 10;
    }

    return result;
  }

  /**
   * zjistí, jestli má být pole Kč pro daný druh mzdy jen ke čtení
   * @param druhMzdyGuid
   */
  public isKcReadonly(druhMzdyGuid: string): boolean {
    let druh = this.druhMzdyVse.find(x => x.guid == druhMzdyGuid);

    return druh ? !druh.smluvniOdmena : false;
  }

  /**
   * Přepočet mezd včetně (re)konstrukce souhrnných řádků
   */
  public updateMzdy(): void {
    //přepočet má význam dělat až po načtení formuláře
    if (this.dataLoaded) {
      let values: VmlRadekDetailDto = this.aktivniRadekGroup.value;

      let zaznam = 1 * values.zaznam;

      // na souhrnném řádku je spešl výpočet - jen prémie a náhrady
      if (values.cislo < 0) {
        this._updatePremieNahrady();
      }
      else if (this._updateSouhrny(zaznam)) {
        // pokud k danému řádku existuje souhrnný řádek, tak je nutné zde smazat procento plnění a mzdu, protože ty se objeví na souhrnném řádku
        this.aktivniRadekGroup.get('procPlneni').setValue(null);
        this.aktivniRadekGroup.get('kc').setValue(null);
      }
      else {
        if (values.mnozstvi && values.normohodiny && values.hodiny && values.hodiny > 0) {
          values.procPlneni = 100.0 * values.mnozstvi * values.normohodiny / values.hodiny;
        }

        // kdyby nebylo množství nebo normohodiny zadány, měla by se sem dostat hodnota 0.0
        values.pocetVj = 1.0 * values.mnozstvi * values.normohodiny;
        values.pocetVjPlocha = 1.0 * values.plocha * values.normohodiny;

        values.kc = this._calculateKc(values);

        // aktualizace modelu
        this.aktivniRadekGroup.get('procPlneni').setValue(values.procPlneni);
        this.aktivniRadekGroup.get('pocetVj').setValue(values.pocetVj);
        this.aktivniRadekGroup.get('pocetVjPlocha').setValue(values.pocetVjPlocha);
        this.aktivniRadekGroup.get('kc').setValue(values.kc);

        // aktualizace prémií/náhrad
        this._updatePremieNahrady();
      }
    }
  }

  /**
   * Aktualizace souhrnných řádků pro daný záznam (vč. případného vytvoření/smazání, podle situace v datech)
   * @param zaznam
   */
  private _updateSouhrny(zaznam: number): boolean {
    let zaznamRows: VmlRadekDetailDto[] = this.radky.value.filter((x: VmlRadekDetailDto) => x.zaznam == zaznam && x.cislo > 0);

    if (zaznamRows.length > 1) {
      // řádky, kde je vyplněn pracovník
      let pracovnici = zaznamRows.filter(x => x.dodavatelGuid);

      // pro každého pracovníka v četě bude samostatný sumární řádek
      pracovnici.forEach((prac, i) => {
        let newRow: VmlRadekDetailDto = this.radky.value.find((x: VmlRadekDetailDto) => x.zaznam == zaznam && x.cislo < 0 && x.dodavatelGuid == prac.dodavatelGuid);
        let addToModel = false;

        if (!newRow) {
          newRow = this._createEmptyRowDto();
          newRow.cislo = -1 * (i + 1);
          newRow.zaznam = zaznam;
          addToModel = true;
        }

        newRow.podvykonGuid = zaznamRows[0].podvykonGuid;
        newRow.vykonGuid = zaznamRows[0].vykonGuid;
        newRow.mjGuid = zaznamRows[0].mjGuid;
        newRow.dodavatelGuid = prac.dodavatelGuid;
        newRow.tarif = prac.tarif;
        newRow.hodiny = prac.hodiny;
        newRow.odpracDny = prac.odpracDny;
        newRow.druhMzdyGuid = prac.druhMzdyGuid;
        newRow.pocetVj = 0.0; // property pro součin množství a normočasu
        newRow.pocetVjPlocha = 0.0;
        newRow.mnozstvi = 0.0;
        newRow.plocha = 0.0;

        let calculatedRow = zaznamRows.reduce((accumulator, current) => {
          accumulator.mnozstvi = accumulator.mnozstvi + (1.0 * current.mnozstvi);
          accumulator.plocha = accumulator.plocha + (1.0 * current.plocha);
          accumulator.pocetVj = accumulator.pocetVj + (1.0 * current.mnozstvi * current.normohodiny);
          accumulator.pocetVjPlocha = accumulator.pocetVjPlocha + (1.0 * current.plocha * current.normohodiny);
          return accumulator;
        }, newRow);

        // pokud je více pracovníků, rozpočítají se sumární údaje podle zadaných hodin jednotlivých pracovníků
        if (pracovnici.length > 1) {
          let sumHod = pracovnici.map(x => x.hodiny).reduce((a, c) => a += c, 0);
          calculatedRow.mnozstvi = calculatedRow.mnozstvi / sumHod * calculatedRow.hodiny;
          calculatedRow.plocha = calculatedRow.plocha / sumHod * calculatedRow.hodiny;
          calculatedRow.pocetVj = calculatedRow.pocetVj / sumHod * calculatedRow.hodiny;
          calculatedRow.pocetVjPlocha = calculatedRow.pocetVjPlocha / sumHod * calculatedRow.hodiny;
        }

        calculatedRow.procPlneni = 100.0 * calculatedRow.pocetVj / calculatedRow.hodiny;
        calculatedRow.kc = this._calculateKc(calculatedRow);

        //zaznamRows[0].pocetVj = null;
        zaznamRows[0].procPlneni = null;
        zaznamRows[0].kc = null;

        this._fillRadekFormGroup(this._createEmptyRowGroup(), calculatedRow, addToModel);
      }); // end forEach

      let reSortedRadky: VmlRadekDetailDto[] = this.radky.value.sort(this.rowCompareFn);
      this.radky.patchValue(reSortedRadky);
      this.aktivniRadekIdx = reSortedRadky.findIndex(x => x.cislo == this.aktivniRadek.cislo);

      return true;
    }
    else {
      return false;
    }
  }

  /**
   * Načtení docházky při otevření dialogu.
  **/
  onShowDochazka(): void {
    this.dochazka.loadDochazky();
  }

  /**
   * Detekce změn ve formuláři
   **/
  get dirty() {
    return this.formData.dirty || this.aktivniRadekGroup.dirty;
  }

  private _cleanDirty(): void {
    this.formData.markAsUntouched();
    this.formData.markAsPristine();
    this.aktivniRadekGroup.markAsUntouched();
    this.aktivniRadekGroup.markAsPristine();
  }

  /**
   * Handler kliknutí na tlačítko docházek
   * */
  showDochazkaHandler(): void {
    if (this.dirty) {
      this.confirmationService.confirm({
        header: 'Neuložené změny!',
        message: 'Doklad není uložený. Přejete si uložit a editovat docházku?',
        accept: () => {
          this.save(true);
        },
        key: this.SAVE_DLG_KEY
      });
    } else {
      this.showDochazka = true;
    }
  }

  //přetížené metody pro direktivu odchodu ze stránky
  canDeactivate(): boolean {
    return !this.dirty
  }

  //při zavření okna vyběhne defaultní hláška prohlížeče
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification(event: BeforeUnloadEvent): void {
    if (this.dirty) {
      event.returnValue = true;
    }
  }
}

