export class DochazkaEditDruhMzdyDto {
  druhMzdyGuid: string;
  dokladGuid: string;
  dokladOrgUr: string;
  dokladDatum: Date;
  dokladCislo: number;
  odpracHodiny: number;
  odpracDny: number;
  hodiny: number[];
}
