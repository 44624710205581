<p-toast position="top-center" key="vyroba-matice-toast"></p-toast>
<app-block-ui [block]="block"></app-block-ui>

<p-toolbar styleClass="base-toolbar">
  <div class="p-toolbar-group-left">
    <h2 class="toolbar-label">Řídicí matice</h2>
    <i class="p-toolbar-separator"><fa-icon [icon]="faEllipsisV"></fa-icon></i>
    <button pButton type="button" label="Nová" class="icon-btn" (click)="add()"><fa-icon [icon]="faPlus"></fa-icon></button>
    <div style="display: inline-block">
      <div class="p-inputgroup">
        <input type="text" pInputText placeholder="Hledat" [(ngModel)]="filter" style="width: 20em" />
        <button pButton type="button" class="icon-btn hide-label-button" (click)="filter = undefined" [disabled]="filter == undefined"><fa-icon [icon]="faBackspace"></fa-icon></button>
      </div>
    </div>
    <button pButton type="button" label="Smazat" class="icon-btn p-button-danger" (click)="remove()" [disabled]="selectedItem == undefined || selectedItem.guid == undefined">
      <fa-icon [icon]="faTrash"></fa-icon>
    </button>
  </div>
  <div class="p-toolbar-group-right">
    <button pButton type="button" label="Zpět" class="icon-btn" (click)="back()"><fa-icon [icon]="faUndo"></fa-icon></button>
  </div>
</p-toolbar>

<propla-table #maticeTable [cols]="cols" [allItems]="items" [selectionMode]="'single'" [filterGlobal]="filter"
              (rowSelected)="onRowSelected($event)" (rowUnSelected)="onRowUnSelected($event)" (dataLoaded)="dataTableLoaded()"></propla-table>

<ng-template #colDatum let-rowData let-col="column">
  {{rowData[col.field] | date:'d.M.yyyy'}}
</ng-template>

<p-panel *ngIf="showDetail" [style]="{'margin-top': '1em'}">
  <ng-template pTemplate="header">
    <div class="p-grid p-align-center">
      <div class="p-col"><span class="p-panel-title">Nastavení matice</span></div>
      <div class="p-col right">
        <button pButton type="button" label="Uložit" class="icon-btn p-button-success" (click)="save()" [disabled]="form.invalid"><fa-icon [icon]="faSave"></fa-icon></button>
        <button pButton type="button" label="Uložit kopii" class="icon-btn p-button-success" (click)="saveCopy()" [disabled]="form.invalid"><fa-icon [icon]="faCopy"></fa-icon></button>
        <button pButton type="button" label="Zrušit" class="icon-btn" (click)="cancel()"><fa-icon [icon]="faTimes"></fa-icon></button>
      </div>
    </div>
  </ng-template>
  <div class="p-grid validated-form-container" [formGroup]="form">
    <div class="p-col-1">
      Doklad
      <p-dropdown #dokladTyp [options]="doklady" formControlName="dokladTypKod" styleClass="pds-ciselnik-dropdown" appendTo="body" [style]="{'width': '100%'}" class="required"></p-dropdown>
    </div>
    <div class="p-col-2">
      Výkon
      <app-ciselnik-dropdown ciselnik="CVykon" [nullable]="true" [virtualScroll]="true"
                             [panelStyle]="{'width':'400px'}"
                             formControlName="vykonGuid" class="required"></app-ciselnik-dropdown>
    </div>
    <div class="p-col-5">
      Podvýkon
      <app-podvykon-dropdown [nullable]="true" [vykonGuid]="vykonGuid.value" [virtualScroll]="true"
                             [panelStyle]="{'width':'600px'}"
                             formControlName="podvykonGuid" class="required"></app-podvykon-dropdown>
    </div>
    <div class="p-col-2">
      <label for="matice-platnost-od">Platnost od</label>
      <app-calendar formControlName="platnostOd" class="required" id="matice-platnost-od"></app-calendar>
    </div>
    <div class="p-col-2">
      <label for="matice-platnost-do">Platnost do</label>
      <app-calendar formControlName="platnostDo" id="matice-platnost-do"></app-calendar>
    </div>

    <div class="p-col-12">
      <app-vyroba-matice-polozky formControlName="polozky"></app-vyroba-matice-polozky>
    </div>

    <div class="p-col-8">
      <label for="matice-poznamka-textbox">Poznámka</label>
      <input type="text" pInputText formControlName="poznamka" id="matice-poznamka-textbox" />
    </div>
    <div class="p-col-4">
      Fakturovaná MJ
      <app-ciselnik-dropdown ciselnik="CMj" [nullable]="true" [virtualScroll]="true"
                             [panelStyle]="{'width':'200px'}"
                             formControlName="mjFakturaceGuid"></app-ciselnik-dropdown>
    </div>
    <div class="p-col-12">
      Povolené MJ
      <p-multiSelect [options]="mjList" formControlName="povolMj" [filter]="true" styleClass="pds-ciselnik-dropdown"
                     [style]="{'width': '100%'}" [panelStyle]="{'width': '20em'}" defaultLabel="Vyberte..." maxSelectedLabels="50"></p-multiSelect>
    </div>
  </div>
</p-panel>


<p-confirmDialog #confirmDlg header="Smazat" icon="pi pi-exclamation-triangle">
  <p-footer>
    <button type="button" pButton icon="pi pi-times" label="Ne" (click)="confirmDlg.reject()"></button>
    <button type="button" pButton icon="pi pi-check" label="Ano" (click)="confirmDlg.accept()"></button>
  </p-footer>
</p-confirmDialog>
