import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CVyrMaticeDetailDto } from '../../Dto/Vyroba/CVyrMaticeDetailDto';
import { ResultInfoGenericDto, ResultInfoDto } from '../../Dto/Shared/ResultInfoDto';
import { CVyrMaticeListDto } from '../../Dto/Vyroba/CVyrMaticeListDto';
import { DateUtils } from '../../Utils/Shared/date.utils';
import { ServiceUtils } from '../../Utils/Shared/service.utils';


@Injectable({
  providedIn: 'root'
})
export class MaticeService {
  constructor(
    private http: HttpClient
  ) { }

  private apiMaticeGet: string = 'api/vyroba/Matice/Get';
  private apiMaticeList: string = 'api/vyroba/Matice/List';
  private apiMaticeSave: string = 'api/vyroba/Matice/Save';
  private apiMaticeDelete: string = 'api/vyroba/Matice/Delete';

  private matice: any = {};

  /**
   * Vrátí řídící matici pro kombinaci typ dokladu, podvýkon a datum. Matice se pro zadané kombinace kešují.
   * @param dokladTypKod
   * @param podvykonGuid
   * @param datum
   */
  get(dokladTypKod: string, podvykonGuid: string, datum: Date): Observable<CVyrMaticeDetailDto> {
    let requestDate: string = DateUtils.formatDate(datum);

    let idx = dokladTypKod + '_' + podvykonGuid + '_' + requestDate;
    if (this.matice[idx] != void 0) {
      return of(this.matice[idx]);
    }

    var request = new EventEmitter<CVyrMaticeDetailDto>();

    this.http.get<ResultInfoGenericDto<CVyrMaticeDetailDto>>(this.apiMaticeGet + '/' + dokladTypKod + '/' + podvykonGuid + '/' + requestDate)
      .subscribe(resp => {
        if (resp.success) {
          this.matice[idx] = resp.data;
          request.emit(this.matice[idx]);
        }
        else {
          request.emit(null);
        }
      });

    return request;
  }

  getByGuid(guid: string): Observable<ResultInfoGenericDto<CVyrMaticeDetailDto>> {
    return this.http.get<ResultInfoGenericDto<CVyrMaticeDetailDto>>(this.apiMaticeGet + '/' + guid);
  }

  list(): Observable<ResultInfoGenericDto<CVyrMaticeListDto[]>> {
    return this.http.get<ResultInfoGenericDto<CVyrMaticeListDto[]>>(this.apiMaticeList);
  }

  save(data: CVyrMaticeDetailDto): Observable<ResultInfoGenericDto<CVyrMaticeDetailDto>> {
    return this.http.post<ResultInfoGenericDto<CVyrMaticeDetailDto>>(this.apiMaticeSave, data, {})
      .pipe(catchError(ServiceUtils.transformError<ResultInfoGenericDto<CVyrMaticeDetailDto>>()));
  }

  /**
   * Smazani zaznamu matice
   * @param guid guid zaznamu matice
   */
  delete(guid: string): Observable<ResultInfoDto> {
    var param = new HttpParams();
    param = param.append('guid', guid);
    return this.http.delete<ResultInfoDto>(this.apiMaticeDelete, { params: param });
  }

}
