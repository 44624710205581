import { HttpClient, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { RoleDto } from '../../Dto/Auth/RoleDto'; 
import { CiselnikListDto } from '../../Dto/Shared/CiselnikListDto';
import { CislenikyDto } from '../../Dto/Shared/CislenikyDto';
import { COdberatelKontraktListDto } from '../../Dto/Vyroba/COdberatelKontraktListDto';
import { ZakaznikDetailDto } from '../../Dto/Zakaznik/ZakaznikDetailDto';
import { AuthService } from '../Auth/auth.service';

@Injectable({
  providedIn: "root"
})
export class CiselnikyService {
  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {
    authService.loggedOut.subscribe(() => {
      this._ciselniky = null;
      this._request = null;
      this._initialCisKey = null;
    });
  }

  private apiGetCiselniky: string = "api/Ciselniky/GetCiselniky";
  private apiFindKontrakt: string = "api/Ciselniky/FindKontrakt";
  private apiFindVykonByProstredek: string = "api/Ciselniky/FindVykonByProstredek";
  private apiVykonyTezebni: string = "api/Ciselniky/VykonyTezebni";
  private apiZakaznici: string = "api/Ciselniky/Zakaznici";
  private apiOrgUr: string = "api/Ciselniky/OrgUr";
  private apiRole: string = "api/Ciselniky/Roles";

  //číselníky, které sa mají stáhnout
  public static ModelsTouse: string[] = ['CImise', 'CDrevin', 'COpDruh', 'COrgUr', 'CAkce', 'CCertifikace', 'CDodavatel',
    'CDopravce', 'CDruhMzdy', 'CLokalita', 'CMaterial', 'CMj', 'COdberatel', 'CProstredek', 'CSazenice', 'CSkodlivyCinitel',
    'CSortiment', 'CVykon', 'CVyrDrevina', 'CVyrDruhTezby', 'CVyrTypVypoctu', 'CZakazka', 'CZpusobProvedeni',
    'DPruvodniListRadek', 'CPodvykon', 'CBzlDr', 'CChko', 'CChkoZona', 'CDruhTezby', 'CDruhZal',
    'CDuvTezby', 'CGenKlas', 'CGenPuvod', 'CHospTv', 'CHospZp', 'CJpDruh', 'CKraj', 'CKvalP', 'CLesObl', 'CLesPodobl',
    'CLhcHs', 'CLhcSchu', 'CLhcMaj', 'CLvs', 'CMZZasoby', 'CNaleh', 'CNalHosp', 'CNp', 'CNpZona', 'COdvozTez', 'COlhPravTit', 'COrp',
    'CPasOhr', 'CPlt', 'CPo', 'CPp', 'CPr', 'CPuvDr', 'CSlt', 'CTerTyp', 'CZdrRepM', 'CZpusTezby', 'CDrevinaSkup', 'CUzivatel', 'CDrzitel', 'CLt',
    'CKateg', 'CDruhVlast', 'CLheCinnost', 'COmezTez'];

  loaded: EventEmitter<any> = new EventEmitter();//došlo k načtení ze serveru

  // stažená data všech číselníků. Načte se pouze jednou v rámci životního cyklu CiselnikService
  private _ciselniky: CislenikyDto = null;
  // event emmitery umožňují neopakovat request na server, ale nechat další "zájemce" počkat, až se dokončí první stažení všeho
  private _request: EventEmitter<any>[] = null;
  //první kód číselníku, pro který se provede request
  private _initialCisKey: string = null;


  /**
   * Vrací observable, který po stažení číselníku jej poskytne dál
   * všechny číselníky jsou staženy najednou a pouze jedinkrát, realizace viz #17737
   * @param cisKey - zvolený číselník
   * @param nullItem - prázdná položka číselníku
   * @param ciselniky - seznam číselníků z vnějšku, jaké se mají stáhnout. Pokud se nepoužije, tak se stáhnou všechny.
   */
  public getCislenik(cisKey: string, nullItem: CiselnikListDto, ciselniky: string[] = null): Observable<CiselnikListDto[]> {
    if (this._initialCisKey == null) this._initialCisKey = cisKey;
    if (this._ciselniky != null) {
      return of(this._getCiselnikInner(cisKey, nullItem, ciselniky));
    }
    else if (this._request != null) {
      var localEmmiter = new EventEmitter<any>();
      this._request[this._initialCisKey].subscribe(() => {
        localEmmiter.emit(this._getCiselnikInner(cisKey, nullItem, ciselniky));
      });
      return localEmmiter;
    }
    else {
      this._request = [];
      this._request[cisKey] = new EventEmitter<any>();

      //pokud jsou nastaveny ciselniky z vnejsku, tak ty pouzit a zbyle dofiltrovat
      if (ciselniky != void 0)
        CiselnikyService.ModelsTouse = CiselnikyService.ModelsTouse.filter(x => {
          return ciselniky?.find(y => y == x) != void 0;
        });

      this.http.post<CislenikyDto>(this.apiGetCiselniky, CiselnikyService.ModelsTouse)
        .subscribe((result) => {
          this._ciselniky = result;
          this.loaded.emit();
          this._request[cisKey].emit(this._getCiselnikInner(cisKey, nullItem, ciselniky));

        });

      return this._request[cisKey];
    }
  }

  private _getCiselnikInner(cisKey: string, nullItem: CiselnikListDto, ciselniky: string[] = null): CiselnikListDto[] {

    //pokud není číselník povolený z vnějšku, tak vrátit prázdné pole
    if (ciselniky != void 0 && ciselniky.indexOf(cisKey) == -1)
      return [];

    if (this._ciselniky[cisKey] == void 0) {
      console.error("Číselník " + cisKey + " nebyl nalezen");
      return [];
    }

    // vysvětlení slice:
    // vytvori to kopii pole. pak se tam potencialne vklada nullItem. pokud bych nevytvoril kopii, tak by se ten nullItem vlozil do puvodniho dto a to nechceme

    var cis = this._ciselniky[cisKey].slice();

    if (nullItem != null) {
      cis.unshift(nullItem);
    }

    return cis;
  }

  /**
   * Vrátí kontrakt podle textu a odběratele
   * @param text
   * @param odberatelGuid
   */
  public findKontrakt(text: string, odberatelGuid: string): Observable<COdberatelKontraktListDto[]> {
    var param = new HttpParams();
    param = param.append('text', text);
    param = param.append('odberatelGuid', odberatelGuid);

    return this.http.get<COdberatelKontraktListDto[]>(this.apiFindKontrakt, { params: param });
  }

  /**
   * Vrátí seznam guidů výkonu podle prostředku
   * @param prostredekGuid
   */
  public findVykonByProstredek(prostredekGuid: string): Observable<string[]> {
    return this.http.get<string[]>(this.apiFindVykonByProstredek + '/' + prostredekGuid);
  }


  private vykony: any = {};

  /**
   * Vrátí seznam težebních výkonů (seznam se kešuje).
  **/
  public getVykonyTezebni(): Observable<CiselnikListDto[]> {
    let idx = 'tezebni';
    if (this.vykony[idx] != void 0) {
      return of(this.vykony[idx]);
    }

    var request = new EventEmitter<CiselnikListDto[]>();

    this.http.get<CiselnikListDto[]>(this.apiVykonyTezebni)
      .subscribe(resp => {
        this.vykony[idx] = resp;
        request.emit(this.vykony[idx]);
      });

    return request;
  }

  /**
   * Vrátí seznam zákazníků
   * */
  public getZakaznici(): Observable<ZakaznikDetailDto[]> {
    return this.http.get<ZakaznikDetailDto[]>(this.apiZakaznici);
  }

  /**
   * Vrátí seznam rolí
  */
  public getRoles(): Observable<RoleDto[]> {
    return this.http.get<RoleDto[]>(this.apiRole);
  }

  /**
   * stažená data číselníků org. ur. pro jednotlivé zákazníky
   **/
  private _orgUrZakazinkContainer = [];

  /**
   * Vrátí seznam Org. Ur. podle zákazníka pro administraci LHC
   * @param zakaznikGuid
   * @param refresh Indikuje, zda se má provést nový request a nebo použít dříve stažená data číselníku org. ur.
   */
  public getOrgUr(zakaznikGuid: string, refresh: boolean = false): Observable<CiselnikListDto[]> {
    if (this._orgUrZakazinkContainer[zakaznikGuid] != null && refresh == false) {
      return of(this._orgUrZakazinkContainer[zakaznikGuid])
    }
    var request = new EventEmitter<CiselnikListDto[]>();
    this.http.get<CislenikyDto>(this.apiOrgUr + "/" + zakaznikGuid).subscribe(res => {
      this._orgUrZakazinkContainer[zakaznikGuid] = res["COrgUr"];
      request.emit(this._orgUrZakazinkContainer[zakaznikGuid]);
    });

    return request;
  }
}
