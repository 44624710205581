import { Component } from '@angular/core';
import { faList } from '@fortawesome/free-solid-svg-icons';
import { MapItemDto } from '../../../Dto/Mapa/MapItemDto';
import { MapInteractionService } from '../../../Services/Mapa/map-interaction.service';
import { ExternalMessagingService } from '../../../Services/Shared/external-messaging.service';

/**
 * Komponenta předávající seznam vybraných parcel pozemkového katastru do aplikace PES (#45248).
**/
@Component({
  selector: 'app-seznam-parcel',
  templateUrl: './seznam-parcel.component.html',
  styleUrls: ['./seznam-parcel.component.css']
})
export class SeznamParcelComponent {

  constructor(
    private mapInteractionService: MapInteractionService,
    private externalMessagingService: ExternalMessagingService)
  {
    this.mapInteractionService.SelectedItems.subscribe(this._itemsSelectedHandler.bind(this));
  }

  private readonly PES_MODUL: string = 'Pes';
  faList = faList;
  parcely: MapItemDto[] = [];


  /**
   * Handler výběru mapových objektů. Vyfiltruje z vybraných objektů ty, které patří modulu PES.
   * @param items {MapItemDto[]} seznam vybraných mapových objektů.
   */
  private _itemsSelectedHandler(items: MapItemDto[]): void {
    this.parcely = items.filter(i => i.modul == this.PES_MODUL);
  }


  /**
   * Odeslání seznamu PAR_ID vybraných parcel do aplikace PES.
  **/
  sendToPes(): void {
    let parIds = this.parcely.map(p => p['parId']);
    parIds = parIds.map(id => parseInt(id));
    this.externalMessagingService.sendMessage({ event: 'showSeznamParcel', data: parIds });
  }
}
