import Feature from 'ol/Feature';
import ImageStyle from "ol/style/Image";
import { Fill, Stroke, Circle, Style, Text, Icon } from 'ol/style';
import { VectorStyleDefinitionDto } from "../../Dto/Mapa/VectorStyleDefinitionDto";
import { NacrtyService } from '../../Services/Nacrty/nacrty.service';
import { IconItemDto } from '../../Dto/Nacrty/IconItemDto';
import { LineString, Point } from 'ol/geom';
import { FormatDelkaPipe } from '../../Directives/format-delka.pipe';
import { DEVICE_PIXEL_RATIO } from 'ol/has';
import { Injectable } from '@angular/core';
import { NacrtyStyleInteractionService } from '../../Services/Nacrty/nacrty-style-interaction.service';

/**
 * Pomocná třída pro tvorbu stylů mapových vrstev.
**/
@Injectable({
  providedIn: 'root'
})
export class LayerStyleUtils {

  /**
   * Knihovna ikon.
  **/
  private _iconLibrary: any = null;

  /**
   * Knihovna typů čar.
  **/
  private _lineTypeLibrary: any = null;

  /**
   * Tloušťka textu.
  **/
  private readonly TEXT_STROKE_WIDTH: number = 3;

  /**
   * Styl konce linie/značky linie.
  **/
  private readonly LINE_CAP: CanvasLineCap = 'butt';

  /*
   * Maximální zoom, při kterém by měly být vidět popisky bodů a linií
  */
  private readonly POPISEK_MAX_ZOOM: number = 3;


  /**
   * Uložiště vyrendrovaných canvasů.
  **/
  private _canvasStore = {};


  constructor(
    private nacrtyService: NacrtyService,
    private delkaPipe: FormatDelkaPipe,
    private nacrtStyleInteractionService: NacrtyStyleInteractionService)
  {
    this.nacrtyService.getIconLibrary().subscribe(icons => {
      this._iconLibrary = icons;
    });

    this.nacrtyService.getLineTypeLibrary().subscribe(lineTypes => {
      this._lineTypeLibrary = lineTypes;
    });

    this.nacrtStyleInteractionService.RemovePinCanvas.subscribe(name => {
      delete this._canvasStore[name];
    });
  }


  /**
   * Vytvoří grafický styl bodu.
   * @param styleSource {VectorStyleDefinitionDto} data pro vytvoření stylu
   * @param resolution {numer} rozlišení mapového okna
   * @param name {string} název vrstvy
   */
  public getPointImageStyle(styleSource: VectorStyleDefinitionDto, resolution: number, name: string): ImageStyle
  {
    let imageStyle: ImageStyle;
    if (styleSource.iconName != void 0 && styleSource.iconName != '' && this._iconLibrary.hasOwnProperty(styleSource.iconName)) {
      let icon: IconItemDto = this._iconLibrary[styleSource.iconName];

      let paddingX: number = 8;
      let paddingY: number = 8;
      let arrowPadding: number = 10;
      let h: number = icon.height + paddingX * 2 + arrowPadding;
      let w: number = icon.width + paddingX * 2;
      //pokud je ikona vyšší než širší, uděláme jí rámeček do čtverce
      if (icon.height > icon.width) {
        w = h - arrowPadding;
        paddingX += (icon.height - icon.width) / 2;
      }

      let img: HTMLCanvasElement = this._getCanvasIcon(w, h, paddingX, paddingY, arrowPadding, icon, styleSource, name);

      imageStyle = new Icon({
        imgSize: [w, h],
        img: img,
        displacement: [0, icon.height / 2 + arrowPadding],
        scale: this._scaleSize(1, resolution)
      });
    }
    else {
      imageStyle = new Circle({
        fill: new Fill({
          color: styleSource.circleFillRgba
        }),
        stroke: new Stroke({
          color: styleSource.circleStrokeRgba,
          width: styleSource.circleStrokeWidth
        }),
        radius: this._scaleSize(styleSource.circleRadius, resolution)
      });
    }
    return imageStyle;
  }


  /**
   * Vykreslení canvasu s ikonou, jejím rámečkem, pozadím a šipkou.
   * @param w {number} šířka canvasu
   * @param h {number} výška canvasu
   * @param paddingX {number} padding ikony po ose X
   * @param paddingY {number} padding ikony po ose Y
   * @param arrowPadding {number} bottom padding, kvůli šipce pod ikonou
   * @param icon {IconItemDto} data ikony
   * @param styleSource {VectorStyleDefinitionDto} definice stylu vektorové vrstvy
   * @param name {string} název vrstvy
   */
  private _getCanvasIcon(w: number, h: number, paddingX: number, paddingY: number, arrowPadding: number, icon: IconItemDto, styleSource: VectorStyleDefinitionDto, name: string): HTMLCanvasElement
  {
    if (this._canvasStore[name] != void 0) {
      return this._canvasStore[name];
    }

    //canvas
    let canvas: HTMLCanvasElement = document.createElement('canvas');
    canvas.width = w;
    canvas.height = h;

    //pomocné proměnné
    let x: number = 2;
    let frameH: number = h - arrowPadding;

    let ctx = canvas.getContext('2d');
    //rámeček ikony
    ctx.beginPath();
    ctx.lineWidth = x * 2;
    ctx.strokeStyle = styleSource.circleStrokeRgba;
    ctx.moveTo(x * 4, x);
    ctx.lineTo(w - x * 4, x);
    ctx.quadraticCurveTo(w - x / 2, x / 2, w - x, x * 4);
    ctx.lineTo(w - x, frameH - x * 4);
    ctx.quadraticCurveTo(w - x / 2, frameH - x / 2, w - x * 4, frameH - x);
    ctx.lineTo(x * 4, frameH - x);
    ctx.quadraticCurveTo(x / 2, frameH - x / 2, x, frameH - x * 4);
    ctx.lineTo(x, x * 4);
    ctx.quadraticCurveTo(x / 2, x / 2, x * 4, x);
    ctx.stroke();

    //šipka pod ikonou
    ctx.beginPath();
    ctx.moveTo(w / 2 - 6, frameH + 1);
    ctx.lineTo(w / 2 + 6, frameH + 1);
    ctx.lineTo(w / 2, frameH + 8);
    ctx.closePath();
    ctx.fillStyle = styleSource.circleStrokeRgba;
    ctx.fill();

    //bílé pozadí
    ctx.fillStyle = styleSource.circleFillRgba;
    ctx.fillRect(x * 2, x * 2, w - x * 4, frameH - x * 4);

    //ikona
    let img: HTMLImageElement = new Image(icon.width, icon.height);
    img.onload = this._imgLoadedHandler.bind(this, name, canvas, paddingX, paddingY, icon);
    img.src = 'data:image/svg+xml;base64,' + icon.data;

    return canvas;
  }


  /**
   * Handler načtení obrázku. Teprve nyní lze obrázek přidat do canvasu a canvas do uložiště.
   * @param iconKey {string} klíč ikony v uložišti vyrendrovaných canvasů
   * @param canvas {HtmlCanvasElement} canvas
   * @param paddingX {number} padding ikony po ose X
   * @param paddingY {number} padding ikony po ose Y
   * @param icon {IconItemDto} data ikony
   * @param event {Event} událost načtení obrázku
   */
  private _imgLoadedHandler(iconKey: string, canvas: HTMLCanvasElement, paddingX: number, paddingY: number, icon: IconItemDto, event): void {
    let ctx = canvas.getContext('2d');
    ctx.drawImage(event.target, paddingX, paddingY, icon.width, icon.height);
    this._canvasStore[iconKey] = canvas;
    this.nacrtStyleInteractionService.renderMapSync();
  }

  /**
   * Upraví rozměr v závislosti na rozlišení mapy.
   * @param styleSource {number} rozměr ke škálování
   * @param resolution {number} rozlišení mapy
   */
  private _scaleSize(size: number, resolution: number): number
  {
    return resolution > 1 ? (size / resolution) : size;
  }


  /**
   * Vrátí styl popisku.
   * @param styleSource {VectorStyleDefinitionDto} definice stylu
   * @param feature {Feature<any>} mapový objekt
   * @param resolution {number} rozlišení
   */
  public getTextStyle(styleSource: VectorStyleDefinitionDto, feature: Feature<any>, resolution: number): Text
  {
    let geometry: any = feature.getGeometry();
    if (resolution > this.POPISEK_MAX_ZOOM && (geometry instanceof Point || geometry instanceof LineString)) return null;
  
    return new Text({ //TODO - #35248
      fill: new Fill({ color: "black" }),
      stroke: new Stroke({ color: "#ffffff", width: this.TEXT_STROKE_WIDTH }),
      font: "17px Roboto, Helvetica, Arial, sans-serif",//cast z fontu primng
      text: feature.get('popis'),
      offsetY: this._pointTextOffsetY(styleSource, feature, resolution)
    });
  }


  /**
   * Nastaví offset popisků bodů v závislosti na rozlišení mapy. Pokud je rozlišení větší než 1 (mapa je více oddálena), přesunou se popisky nad bod, aby nepřekrývaly grafiku.
   * @param styleSource
   * @param feature
   * @param resolution
   */
  private _pointTextOffsetY(styleSource: VectorStyleDefinitionDto, feature: Feature<any>, resolution: number): number {
    return feature.getGeometry() instanceof Point && resolution > 1 ? ((styleSource.circleRadius / resolution) + styleSource.circleStrokeWidth + this.TEXT_STROKE_WIDTH) * -1 : 0;
  }


  /**
   * Konstrukce stylu vrstvy pro zobrazování velikosti úseček.
   * @param feature kreslený náčrt 
   * @param resolution rozlišení mapy
   */
  public dimLineStyleFunction(feature) {
    return new Style({
      text: new Text({
        fill: new Fill({ color: "black" }),
        stroke: new Stroke({ color: "#ffffff", width: this.TEXT_STROKE_WIDTH }),
        font: "bold 14px Roboto, Helvetica, Arial, sans-serif",//cast z fontu primng
        text: this.delkaPipe.transform((feature.getGeometry() as LineString).getLength()),
        placement: "line"
      })
    });
  }



  /**
   * Konstrukce vzoru pro výplň vybraných mapových objektů. Výplň je tvořena jako čtverce otočené o 45°.
   * Zdroj: https://openlayers.org/en/latest/examples/canvas-gradient-pattern.html
  **/
  public getPattern() {
    let canvas = document.createElement('canvas');
    canvas.width = 10 * DEVICE_PIXEL_RATIO;
    canvas.height = canvas.width;

    let context = canvas.getContext('2d');
    context.rotate(0.25 * Math.PI);

    context.beginPath();
    context.lineWidth = 1;
    context.strokeStyle = "rgb(0,0,255)";
    let rectSize = Math.sqrt(Math.pow(canvas.width, 2) / 2);
    context.strokeRect(rectSize / 2, -rectSize / 2, rectSize, rectSize);
    context.stroke();

    return context.createPattern(canvas, 'repeat');
  }


  /**
   * Vrátí styl linie.
   * @param styleSource {VectorStyleDefinitionDto} informace pro konstukci stylu
   */
  public getStoke(styleSource: VectorStyleDefinitionDto): Stroke {
    let stroke = new Stroke({
      color: styleSource.strokeRgba,
      width: styleSource.strokeWidth,
      lineCap: this.LINE_CAP
    });

    if (styleSource.lineTypeName != void 0 && styleSource.lineTypeName != '' && this._lineTypeLibrary.hasOwnProperty(styleSource.lineTypeName)) {
      let lineTypeDef = this._lineTypeLibrary[styleSource.lineTypeName];
      //TODO zvážit, zda hodnoty nenásobit šířkou linie (tak se bude chovat/chová mapsui na mobilu)
      stroke.setLineDash(lineTypeDef.dashArray);
      stroke.setLineDashOffset(lineTypeDef.dashOffset);
    }

    return stroke;
  }
}
