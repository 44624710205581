<p-dialog header="Editace SQL dotazů" [(visible)]="display" keepInViewport="true" (onShow)="onShowDialog()"
          [style]="{width: '640px', height: '400px'}" [baseZIndex]="10000">
  <div class="panel-body">
    <div id="QueryPanel"></div>
  </div>
  <p-footer>
    <button type="button" pButton icon="pi pi-check" label="Použít" (click)="save()"></button>
    <button type="button" pButton icon="pi pi-times" label="Zavřít" (click)="cancel()" class="p-button-secondary"></button>
  </p-footer>
</p-dialog>
