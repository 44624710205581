import { Component, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CalendarComponent),
    multi: true
  }]
})
export class CalendarComponent implements ControlValueAccessor {

  constructor() {
  }


  @Input() id: string;
  @Input() edit: boolean = true;
  @Input() showTime: boolean = false;

  @ViewChild('calendar') calendar;

  // proměnné pro implementaci rozhraní ControlValueAccessor
  onModelChange: any = () => { };
  onModelTouched: any = () => { };
  disabled: boolean = false;

  private _value: Date = null;

  get value() {
    return this._value;
  }

  set value(val) {
    this._value = val;
    this.onModelChange(this._localToUtc(val));
    this.onModelTouched();
  }

  //převede na utc
  private _localToUtc(date: Date): Date {
    if (date != void 0 && date.toString() != "") {
      return new Date((new Date(date.getTime() - (date.getTimezoneOffset() * 60000))).toISOString());
    }
    else
      return null;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }
  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * Propagace metody toggle - ve výrobních dokladech potřebujeme kontrolu při opuštění pole
   */
  toggle() {
    this.calendar?.toggle();
  }
}
