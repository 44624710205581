<p-card styleClass="map-info-panel-item">
  <button pButton type="button" icon="pi pi-times" class="p-button-rounded map-info-close-button" (click)="removeFromSelection()" pTooltip="Odebrat z výběru" tooltipPosition="left"></button>
  <div class="p-grid p-nogutter p-ai-center">
    <div class="p-col-7">
      <div class="lhp-i-primary">
        <b class="lhp-i-odd">{{item.odd}}</b>
        <b class="lhp-i-dil">{{item.dil}}</b>
        <b class="lhp-i-por">{{item.por}}</b>
        <b class="lhp-i-psk" *ngIf="item.typ == 'psk'">{{item.psk}}</b>
        <b [ngClass]="'lhp-i-'+item.typ" *ngIf="item.typ != 'psk'">{{item.bzlJpOp}}</b>
      </div>
      <div class="lhp-i-secondary">
        <b>{{item.plocha | number : '.2-2'}} ha</b>,
        LHC
        <b class="lhp-i-lhc">{{item.lhcKod}}</b>
        ({{item.lhpOdRoku}})
      </div>
      <div class="lhp-i-secondary">
        <app-org-ur-display [guid]="item.orgUrGuid"></app-org-ur-display>
      </div>
    </div>
    <div class="p-col-5 right">
      <span class="p-buttonset">
        <button pButton type="button" class="icon-btn hide-label-button"
                [pTooltip]="'Přiblížit na ' + item.typ.toUpperCase()" tooltipPosition="bottom"
                (click)="zoomTo()">
          <fa-icon [icon]="faSearch"></fa-icon>
        </button>
        <button pButton type="button" class="icon-btn hide-label-button"
                pTooltip="Kopírovat jako náčrt" tooltipPosition="bottom" [disabled]="disabledBtn"
                (click)="copy()">
          <fa-icon [icon]="faCopy"></fa-icon>
        </button>
        <button pButton type="button" class="icon-btn hide-label-button"
                pTooltip="Kniha" tooltipPosition="bottom"
                (click)="goToKniha()">
          <fa-icon [icon]="faBook"></fa-icon>
        </button>
      </span>
    </div>
  </div>
</p-card>
