<p-panel [header]="header">
  <ng-template pTemplate="icons">
    <button pButton type="button" icon="pi pi-plus" (click)="displayDialog = true" [disabled]="disabled" class="p-mr-2"></button>
  </ng-template>

  <div class="p-grid" *ngFor="let v of value">
    <div class="p-col"><app-org-ur-display [zakaznikGuid]="zakaznikGuid" [guid]="v" [mode]="mode" variant="medium"></app-org-ur-display></div>
    <div class="p-col-fixed">
      <button [disabled]="disabled" type="button" pButton icon="pi pi-minus" (click)="removeOrgUr(v)" class="p-button-secondary"></button>
    </div>
  </div>

</p-panel>

<p-dialog header="Organizační úrovně" [(visible)]="displayDialog" appendTo="body">
  <p-tree [value]="orgUrTree" [loading]="loadingOrgUr" selectionMode="single" [(selection)]="selectedOrgUr" scrollHeight="flex"
          emptyMessage="Nenalezeny žádné org. úrovně"></p-tree>
  <p-footer>
    <button type="button" pButton icon="pi pi-check" label="Použít" (click)="addOrgUr()" [disabled]="!selectedOrgUr"></button>
    <button type="button" pButton icon="pi pi-times" label="Zavřít" (click)="displayDialog = false" class="p-button-secondary"></button>
  </p-footer>
</p-dialog>
