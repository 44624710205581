import WKT from 'ol/format/WKT';
import { Extent } from 'ol/extent';
import { Injectable } from '@angular/core';
import Map from 'ol/Map';

@Injectable({
  providedIn: 'root'
})
export class ExtentUtils {

  /**
   * Koeficient bufferu, o který se extent zvětšuje.
  **/
  private readonly EXTENT_RATIO = 0.15;

  /**
   * Minimální velikost bufferu v metrech.
  **/
  private readonly MIN_EXTENT_SIZE = 20;

  /**
   * Vrátí bounding box
   * @param wkt {string} geometrie ve formátu WKT.
   */
  public getExtent(wkt: string): Extent {
    let format = new WKT();
    let feature = format.readFeature(wkt);
    return feature.getGeometry().getExtent();
  }


  /**
   * Rozšíří extent o vypočítanou hodnotu.
   * Pokud je extent měnší než MIN_EXTENT_SIZE, tak se na MIN_EXTENT_SIZE zvětší.
   * @param extent {Extent} bouding-box ke zvětšení
   */
  public addBuffer(extent: Extent): Extent {
    let extentSizeX: number = extent[2] - extent[0];
    let extentSizeY: number = extent[3] - extent[1];

    if (extentSizeX < this.MIN_EXTENT_SIZE || extentSizeY < this.MIN_EXTENT_SIZE) {
      let difX = this.MIN_EXTENT_SIZE - extentSizeX;
      extentSizeX += difX;
      extent[0] -= difX / 2;
      extent[2] += difX / 2;

      let difY = this.MIN_EXTENT_SIZE - extentSizeY;
      extentSizeY += difY;
      extent[1] -= difY / 2;
      extent[3] += difY / 2;
    }

    let bufferX: number = extentSizeX * this.EXTENT_RATIO;
    let bufferY: number = extentSizeY * this.EXTENT_RATIO;

    return [extent[0] - bufferX, extent[1] - bufferY, extent[2] + bufferX, extent[3] + bufferY];
  }


  /**
   * Vrátí extent mapy.
   * @param map {Map} mapa
   */
  public getMapExtent(map: Map): Extent {
    return map.getView().calculateExtent(map.getSize());
  }
}
