import { Component, OnInit } from '@angular/core';
import GeometryType from 'ol/geom/GeometryType';
import { MapItemDto } from '../../../Dto/Mapa/MapItemDto';
import { OffsetlInfoDto } from '../../../Dto/Mapa/OffsetlInfoDto';
import { MapInteractionService } from '../../../Services/Mapa/map-interaction.service';


/**
 * Komponenta přepívání zobrazení lomových bodů vybraných mapových objektů.
**/
@Component({
  selector: 'app-vertex-switch',
  templateUrl: './vertex-switch.component.html',
  styleUrls: ['./vertex-switch.component.css']
})
export class VertexSwitchComponent implements OnInit {

  constructor(private mapInteractionService: MapInteractionService) {
    this.mapInteractionService.ControlOffset.subscribe(this._offsetHandler.bind(this));
    this.mapInteractionService.SelectedItems.subscribe(this._toggleHandler.bind(this));
    this.mapInteractionService.DrawStart.subscribe(geom => this.show = geom != GeometryType.POINT);
    this.mapInteractionService.DiscardChanges.subscribe(_ => this.show = this.selectedItems.length > 0);
  }

  ngOnInit(): void {
    //nastavení zobrazení lomových bodů je uloženo v localStorage, aby to uživatel nemusel při každém naštění mapy znova nastavovat
    //nastavení je potřeba předat mapě (po jejím vytvoření)
    this.checked = localStorage.getItem(this.VERTEX_STATE_KEY) === 'true';
    this.mapInteractionService.MapCreated.subscribe(_ =>
      this.mapInteractionService.vertexToggle(this.checked)
    );
  }

  //klíč pro uložení stavu zobrazení lomových bodů do localStorage
  private readonly VERTEX_STATE_KEY = 'showVerticies';
  //zobrazení komponenty
  show: boolean = false;
  //hodnota přepínače
  checked: boolean = false;
  //offset tlačítka přepínače od pravého okraje obrazovky
  calculatedOffset: string = '5rem';
  //vybrané mapové objekty - slouží pro identifikaci, zda má být zobrazen přepínač
  selectedItems: MapItemDto[] = [];

  /**
   * Handler zobrazení/skrytí info panelu mapy. Zajistí odsazení tlačítka.
   * @param value {OffsetlInfoDto} informace k odsazeni tlačítka
   */
  private _offsetHandler(value: OffsetlInfoDto): void {
    if (value.panel == 'infoPanel') {
      this.calculatedOffset = 'calc(5rem + ' + value.offset + ')';
    }
  }


  /**
   * Handler přepnutí viditelnosti komponenty.
   * @param value {boolean} true, pokud má být zobrazena
   */
  private _toggleHandler(mapItems: MapItemDto[]): void {
    this.selectedItems = mapItems.filter(item => !item.wkt.startsWith('POINT'));
    this.show = this.selectedItems.length > 0;
  }


  /**
   * Implementace přepnutí.
  **/
  toggle(): void {
    this.checked = !this.checked;
    localStorage.setItem(this.VERTEX_STATE_KEY, this.checked.toString());
    this.mapInteractionService.vertexToggle(this.checked);
  }
}
