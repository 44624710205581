import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { DotazListDto } from '../../../Dto/Dotazovac/DotazListDto';
import { EasyQueryParamsComponent } from '../../Shared/easy-query-params/easy-query-params.component';

@Component({
  selector: 'app-dotaz-params',
  templateUrl: './dotaz-params.component.html',
  styleUrls: ['./dotaz-params.component.css']
})
export class DotazParamsComponent {

  constructor() { }

  faCheck = faCheck;
  faTimes = faTimes;

  /**
   * ID vybraného datového modelu
   */
  public eqModel: string;

  /**
   * Data komponenty EasyQuery (JSON string) pro zavedení až po otevření dialogu.
   */
  public eqData: string;

  /**
   * Data komponenty EasyQuery (JSON string)
   */
  @Input() public data: string;

  /**
   * Zobrazení/skrytí dialogu
   */
  @Input() display: boolean = false;

  /**
   * Vybraný dotaz
   */
  @Input() dotaz: DotazListDto;

  @ViewChild("eq", { static: true }) easyQuery: EasyQueryParamsComponent;

  /**
   * Událost informující o potvrzení parametrů
   */
  @Output() onRun: EventEmitter<string> = new EventEmitter<string>();


  /**
   * Handler otevření dialogu. Při nastavení modelu musíme počkat na tuto událost, aby došlo ke správnému sestavení easyquery.
   **/
  public onShowDialog(): void {
    this.eqModel = this.dotaz?.datovyModel;
    this.eqData = this.data;
  }

  /**
   * Handler změny parametrů
   * @param event
   */
  public onChangeQuery(event): void {
    this.eqData = event;
  }

  /**
   * Odeslat změněné parametry pro další zpracování
   **/
  public run(): void {
    // Hodnota dat EQ se změní až při blur eventě editovaného pole. Pokud se hodnota změní a pak rovnou kliknu na tlačítko, tak se odešle dotaz beze změn.
    setTimeout(() => {
      this.onRun.emit(this.eqData);
      this.close();
    }, 500);
  }

  /**
   * Zavřít dialog a promazat dotaz
   **/
  public close(): void {
    this.display = false;
    this.data = null;
    this.eqData = null;
    this.easyQuery.context.clearQuery();
  }
}
