import { Directive, HostListener } from "@angular/core";
import { Router } from "@angular/router";

/**
 * Direktiva pro událost mouseup
 **/
@Directive({
  selector: '[mouseup]'
})
export class MouseupDirective {

  /**
   * Název třídy obrázku loga v hlavičce
   */ 
  private readonly _logoImgClassName: string = "logo-img";

  constructor(private router: Router) { }

  /**
   * Handler mouseup
   * @param event
   */
  @HostListener('mouseup', ['$event']) onClick(event) {
    //Navigace na úvodní stránku po kliknutí na logo. Pokud se použije ctrl, nebo klik na kolečko myši, tak se otevře nový tab.
    if (event.target.className == this._logoImgClassName) {
      if (event.button === 1 || (event.ctrlKey && event.button === 0)) {
        //Přístupem k window.open přes element se vyřeší problém se Same-origin policy
        var doc = event.target.ownerDocument;
        var win = doc.defaultView || doc.parentWindow;
        win.open('/', '_blank');
      } else if (event.button === 0) {
        this.router.navigate(['/']);
      }
    }
  }

}
