<p-messages key="skodyDetailMessages"></p-messages>

<skody-detail-zu *ngIf="druhVypoctu?.startsWith('S')"
                 [skoda]="skoda"
                 (druhVypoctuEvent)="onDruhVypoctuChanged($event)"
                 (dataChanged)="dataChangedZuHandler($event)"
                 (copyLhpEtazEvent)="lhpEtazSet($event)"
                 (copyLhpDrevinaEvent)="lhpDrevinaSet($event)"></skody-detail-zu>
<ujmy-detail-zu *ngIf="druhVypoctu?.startsWith('U')"
                [skoda]="skoda"
                (druhVypoctuEvent)="onDruhVypoctuChanged($event)"
                (dataChanged)="dataChangedZuHandler($event)"
                (copyLhpEtazEvent)="lhpEtazSet($event)"
                (copyLhpDrevinaEvent)="lhpDrevinaSet($event)"
                (lhcChangedEvent)="lhcChanged($event)"></ujmy-detail-zu>

<div class="p-grid">
  <div class="p-col-10 overflow-parent">
    <skody-detail-s6 *ngIf="druhVypoctu == 'S6'"
                     [skoda]="skoda" [newRow]="isNewRow"
                     [lhpEtaz]="skoda.lhpEtaz" [lhpDrevina]="skoda.lhpDrevina"
                     (dataChanged)="dataChangedHandler($event)"></skody-detail-s6>
    <skody-detail-s72 *ngIf="druhVypoctu == 'S7.2'"
                      [skoda]="skoda" [newRow]="isNewRow"
                      [lhpEtaz]="skoda.lhpEtaz" [lhpDrevina]="skoda.lhpDrevina"
                      (dataChanged)="dataChangedHandler($event)"></skody-detail-s72>
    <skody-detail-s91 *ngIf="druhVypoctu == 'S9.1'"
                      [skoda]="skoda" [newRow]="isNewRow"
                      [lhpEtaz]="skoda.lhpEtaz" [lhpDrevina]="skoda.lhpDrevina"
                      (dataChanged)="dataChangedHandler($event)"></skody-detail-s91>
    <ujmy-detail-u7 *ngIf="druhVypoctu == 'U7'"
                    [skoda]="skoda" [newRow]="isNewRow"
                    [lhpEtaz]="skoda.lhpEtaz" [lhpDrevina]="skoda.lhpDrevina"
                    (dataChanged)="dataChangedHandler($event)"></ujmy-detail-u7>
    <ujmy-detail-u8 *ngIf="druhVypoctu == 'U8'"
                    [skoda]="skoda" [newRow]="isNewRow"
                    [lhpEtaz]="skoda.lhpEtaz" [lhpDrevina]="skoda.lhpDrevina"
                    (dataChanged)="dataChangedHandler($event)"></ujmy-detail-u8>
    <ujmy-detail-u10 *ngIf="druhVypoctu == 'U10'"
                     [skoda]="skoda" [newRow]="isNewRow"
                     [lhpEtaz]="skoda.lhpEtaz" [lhpDrevina]="skoda.lhpDrevina"
                     [lhcListDto]="lhcListDto"
                     (dataChanged)="dataChangedHandler($event)"></ujmy-detail-u10>
  </div>
  <div class="p-col-2">
    <skody-detail-vysledek #vysledek *ngIf="druhVypoctu != ''"
                           [vypocet]="vypocet" [blockedPrepocet]="blockedPrepocet" [druhVypoctu]="druhVypoctu"
                           class="overflow-parent"></skody-detail-vysledek>
  </div>
</div>

<skody-detail-s6-kroky *ngIf="druhVypoctu == 'S6'"
                       [skoda]="skoda" [zniceniDto]="krokyDto" [blockedPrepocet]="blockedPrepocet"
                       class="overflow-parent"></skody-detail-s6-kroky>

<skody-detail-s72-kroky *ngIf="druhVypoctu == 'S7.2'"
                        [skoda]="skoda" [okusDto]="krokyDto" [blockedPrepocet]="blockedPrepocet"
                        class="overflow-parent"></skody-detail-s72-kroky>

<skody-detail-s91-kroky *ngIf="druhVypoctu == 'S9.1'"
                        [skoda]="skoda" [loupaniDto]="krokyDto" [blockedPrepocet]="blockedPrepocet"
                        class="overflow-parent"></skody-detail-s91-kroky>
<ujmy-detail-u7-kroky *ngIf="druhVypoctu == 'U7'"
                      [skoda]="skoda" [vystavkyDto]="krokyDto" [blockedPrepocet]="blockedPrepocet"
                      class="overflow-parent"></ujmy-detail-u7-kroky>
<ujmy-detail-u8-kroky *ngIf="druhVypoctu == 'U8'"
                       [ponechaniDriviDto]="krokyDto" [blockedPrepocet]="blockedPrepocet"
                      class="overflow-parent"></ujmy-detail-u8-kroky>
<ujmy-detail-u10-kroky *ngIf="druhVypoctu == 'U10'"
                       [skoda]="skoda" [omezeniTezbyDto]="krokyDto" [blockedPrepocet]="blockedPrepocet"
                       class="overflow-parent"></ujmy-detail-u10-kroky>

<p-toolbar>
  <button pButton type="button" label="Ulož" [ngClass]="'icon-btn'"
          (click)="saveSkoda()">
    <fa-icon [icon]="faSave"></fa-icon>
  </button><!--[disabled]="!skoda.valid"-->
</p-toolbar>
