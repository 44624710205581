import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EqContext, i18n } from '@easyquery/core';
import { AdvancedSearchView, EqViewOptions } from '@easyquery/ui';
import { EqLocaleInfo } from '../../../Utils/Shared/eq-locale-info.utils';

/**
 * Komponenta pro editaci parametrů EasyQuery
 **/
@Component({
  selector: 'app-easy-query-params',
  templateUrl: './easy-query-params.component.html',
  styleUrls: ['./easy-query-params.component.css']
})
export class EasyQueryParamsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  public view: AdvancedSearchView;
  public context: EqContext;

  /**
  * Data k načtení pro editaci. Po načtení se zahodí, aby nedošlo k opakovanému načtení. 
  */
  private _dataToLoad: string;

  private _model: string;

  /**
   * True, pokud EQ komponenta byla zinicializována
   */
  private _ready: boolean = false;

  @Input() set modelId(value: string) {
    this._model = value;
    if (this._model != void 0) {
      this._loadEasyQuery();
    }
  }

  /**
   * Data pro editaci
   */
  @Input() set data(value: string) {
    this._dataToLoad = value;
    this._loadData();
  }

  /**
  * Událost o změně
  */
  @Output() onChange: EventEmitter<string> = new EventEmitter<string>();

  //inicializace easy query
  private _loadEasyQuery(): void {
    i18n.updateLocaleInfo('cs', EqLocaleInfo.Czech);
    i18n.setCurrentLocale('cs');

    const options: EqViewOptions = {
      loadModelOnStart: true,
      defaultModelId: this._model,
      endpoint: "/api/easyquery",
      handlers: {
        onError: (context, status) => {
          console.error(status.action + " error:\n" + status.text);
        }
      },
      widgets: {
        entitiesPanel: {
        },
        columnsPanel: {
        },
        queryPanel: {
          showAddRow: false,
          showRootRow: false
        },
      },
      result: {
        showChart: false
      }
    };

    this.view = new AdvancedSearchView();
    this.context = this.view.getContext();
    this.context.useEnterprise(() => this.view.init(options));

    this.context.addEventListener("syncQuery", this._contextHanlder.bind(this));
    this.context.addEventListener("ready", this._readyHanlder.bind(this));


  }

  private _contextHanlder(context: EqContext) {
    this.onChange.emit(context.getQuery().toJSON());
  }


  private _readyHanlder(context: EqContext) {
    this._ready = true;
    this._loadData();
  }

  /**
   * Načte data z JSON a udělá aktualizaci na výstupu
   */
  private _loadQueryFromJson(): void {
    const query = this.context.getQuery();
    query.loadFromDataOrJson(JSON.parse(this._dataToLoad));
    this._dataToLoad = null;
    this.view.getContext().refreshWidgets();
  }

  /**
  * Pokud data ješte nebyla načtena a komponenta je inicializována, tak načíst
  **/
  private _loadData() {
    if (this._dataToLoad != void 0 && this._ready) {
      this._loadQueryFromJson();
    }
  }
}
