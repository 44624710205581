<p-toast position="top-center" key="vyroba-toast"></p-toast>
<app-block-ui [block]="block"></app-block-ui>

<p-menu #mLock [popup]="true" [model]="menuLock"></p-menu>
<p-menu #mOpts [popup]="true" [model]="menuOpts"></p-menu>
<p-menu #mShare [popup]="true" [model]="menuShare"></p-menu>
<p-menu #vmlFromCd [popup]="true" [model]="menuVmlFromCd"></p-menu>

<p-toolbar styleClass="base-toolbar">
  <div class="p-toolbar-group-left menu-toolbar">
    <p-selectButton [options]="dokladTypes" [(ngModel)]="selectedDokladType" optionLabel="popis"
                    (onChange)="dokladTypChangeHandler($event)"></p-selectButton>

    <i class="p-toolbar-separator"><fa-icon [icon]="faEllipsisV"></fa-icon></i>

    <button pButton label="Nový" [ngClass]="'icon-btn'" (click)="createNewHandler()">
      <fa-icon [icon]="faPlus"></fa-icon>
    </button>

    <button pButton label="Detail" [ngClass]="'icon-btn'" [disabled]="!openDetailDisabled" (click)="openDetailHandler($event)">
      <fa-icon [icon]="faSearch"></fa-icon>
    </button>

    <button pButton label="Opravit" [ngClass]="'icon-btn'" [disabled]="!openDetailDisabled" (click)="openEditHandler($event)">
      <fa-icon [icon]="faPencilAlt"></fa-icon>
    </button>

    <button pButton label="Smazat" [ngClass]="'icon-btn'" [disabled]="deleteDokladDisabled" (click)="smazatDoklad()">
      <fa-icon [icon]="faTrash"></fa-icon>
    </button>

    <app-vyroba-kopie-dokladu [disabled]="!openDetailDisabled" [kod]="selectedDokladType?.kod" [guid]="selectedDoklady[0]?.guid"></app-vyroba-kopie-dokladu>

    <app-vyroba-kontrola [kod]="selectedDokladType?.kod" [doklady]="selectedDoklady" [disabled]="selectedDoklady?.length==0"></app-vyroba-kontrola>

    <!--<button pButton title="Exportovat" class="icon-btn hide-label-button" (click)="mShare.toggle($event)" [disabled]="!batchOpsEnabled">
    <fa-icon [icon]="faFileDownload"></fa-icon>
  </button>-->

    <button pButton title="Tisk" class="icon-btn hide-label-button" (click)="print()" [disabled]="!batchOpsEnabled">
      <fa-icon [icon]="faPrint"></fa-icon>
    </button>

    <button pButton title="Zámek dokladu" class="icon-btn hide-label-button" (click)="mLock.toggle($event)" [disabled]="!batchOpsEnabled">
      <fa-icon [icon]="faLock"></fa-icon>
    </button>

    <button pButton label="Číselník dříví do VML" [ngClass]="'icon-btn'" (click)="vmlFromCd.toggle($event)" [disabled]="selectedDokladType?.kod!=='CD' || selectedDoklady?.length==0">
      <fa-icon [icon]="faExchangeAlt"></fa-icon>
    </button>

  </div>
  <div class="p-toolbar-group-right">
    <button pButton title="Nastavení" class="icon-btn hide-label-button" (click)="mOpts.toggle($event)">
      <fa-icon [icon]="faCog"></fa-icon>
    </button>
  </div>
</p-toolbar>

<p-toolbar styleClass="base-toolbar">
  <div class="p-toolbar-group-left">
    <b class="toolbar-label">Měsíce</b>
    <button pButton label="Vše" (click)="onFilterMesiceAllClick()" class="base-toolbar-small-btn"></button>
    <p-selectButton [options]="mesice" [(ngModel)]="selectedMesice" multiple="multiple"></p-selectButton>
    <b class="toolbar-label">Roky</b>
    <button pButton label="Vše" (click)="onFilterRokyAllClick()" class="base-toolbar-small-btn"></button>
    <p-selectButton [options]="roky" [(ngModel)]="selectedRoky" multiple="multiple"></p-selectButton>
  </div>
  <div class="p-toolbar-group-right">
    <app-filtr id="filter" (onChange)="filterHandler($event)" [dokladType]="selectedDokladType.kod"></app-filtr>
  </div>
</p-toolbar>

<app-vyroba-vml *ngIf="selectedDokladType?.kod==='VML'" [filter]="filter" [reload]="reload" [dokladGuid]="dokladGuid" [eQFilter]="eQFilter"
                (dataLoaded)="dataLoaded()" (selectionChange)="dokladSelectedHandler($event)" (years)="yearsChanged($event)"></app-vyroba-vml>
<app-vyroba-ol *ngIf="selectedDokladType?.kod==='OL'" [filter]="filter" [reload]="reload" [dokladGuid]="dokladGuid" [eQFilter]="eQFilter"
               (dataLoaded)="dataLoaded()" (selectionChange)="dokladSelectedHandler($event)" (years)="yearsChanged($event)"></app-vyroba-ol>
<app-vyroba-cd *ngIf="selectedDokladType?.kod==='CD'" [filter]="filter" [reload]="reload" [dokladGuid]="dokladGuid" [eQFilter]="eQFilter"
               (dataLoaded)="dataLoaded()" (selectionChange)="dokladSelectedHandler($event)" (years)="yearsChanged($event)"></app-vyroba-cd>

<p-confirmDialog #confirmDlg header="Smazat" icon="pi pi-exclamation-triangle">
  <p-footer>
    <button type="button" pButton icon="pi pi-times" label="Ne" (click)="confirmDlg.reject()"></button>
    <button type="button" pButton icon="pi pi-check" label="Ano" (click)="confirmDlg.accept()"></button>
  </p-footer>
</p-confirmDialog>

<app-org-ur #orgUr [(ngModel)]="orgUrGuid" [showInput]="false" (ngModelChange)="orgUrChanged($event)"></app-org-ur>
