import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { faBackspace, faEllipsisV, faPlus, faUndo } from '@fortawesome/free-solid-svg-icons';
import { FilterService, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { RoleEnum } from '../../../Dto/Core/RoleEnum';
import { NacrtProjektDto } from '../../../Dto/Nacrty/NacrtProjektDto';
import { SyncDto } from '../../../Dto/Sync/syncDto';
import { NacrtyService } from '../../../Services/Nacrty/nacrty.service';
import { MessagesUtils } from '../../../Utils/Shared/messages.utils';
import { RoleUtils } from '../../../Utils/Shared/role.utils';
import { UuidUtils } from '../../../Utils/Shared/uuid.utils';

/**
 * Komponenta pro editaci projektů náčrtu
 * */
@Component({
  selector: 'app-nacrty-projekt-list',
  templateUrl: './nacrty-projekt-list.component.html',
  styleUrls: ['./nacrty-projekt-list.component.css']
})
export class NacrtyProjektListComponent implements OnInit {

  constructor
  (
    private nacrtyService: NacrtyService,
    private messagesUtils: MessagesUtils,
    private filterService: FilterService,
    private router: Router,
    private messageService: MessageService,
    private uuidUtils: UuidUtils,
    private roleUtils: RoleUtils
  )
  {
  }

  public projekty: NacrtProjektDto[];
  public loading: boolean = false;
  public selectedProjekt: NacrtProjektDto;

  public readonly NACRT_PROJEKT_TOAST: string = "nacrt_projekt_toast";

  /**
   * Velikost canvas pro náhled
   */
  public readonly previewSize: number = 23;

  @ViewChild('projektyNacrtu') projektyNacrtu: Table;

  faUndo = faUndo;
  faEllipsisV = faEllipsisV;
  faBackspace = faBackspace;
  faPlus = faPlus;

  /**
   * Seznam názvů skupin náčrtů.
  **/
  public skupiny: string[];

  /*
   * Options pro filtr skupin. Pocita se s property nazev
  */
  public skupinyOptions: any[];

  ngOnInit(): void {

    this.filterService.register('filterOrgUr', this.filterOrgUr.bind(this));
    this.loading = true;
    this.nacrtyService.getProjekty().subscribe(res => {
      if (res.success) {
        this.projekty = res.data;
        this._updateSkupiny(res.data);
      } else {
        this.messagesUtils.showResponseMessage(this.NACRT_PROJEKT_TOAST, res);
      }
      this.loading = false;
    }, error => {
      this._errorMessage();
    });
  }

  private _updateSkupiny(projekty: NacrtProjektDto[]): void {
    this.skupiny = [...new Set(projekty.reduce((a, c) => [...a, c.skupina], []))];
    this.skupinyOptions = this.skupiny.map(x => { return { nazev: x } });
  }

  private _errorMessage(): void {
    this.loading = false;
    this.messageService.add({
      key: this.NACRT_PROJEKT_TOAST, summary: 'Chyba', severity: 'error',
      detail: 'Při načítání seznamu projektů došlo k chybě.',
      life: MessagesUtils.TOAST_LIFE
    });
  }

  /**
   * Převede typ boolean na text
   * @param value
   */
  public boolToText(value: boolean): string {
    return value ? "Povolena" : "Zakázána";
  }


  /**
   * Metoda pro filtrování org. ur.
   * @param value - hodnota řádku
   * @param filter - filtrovaná hodnota
   */
  public filterOrgUr(value, filter): boolean {

    if (filter === undefined || filter === null || value === undefined || value === null) {
      return true;
    }

    return value[0] == filter;
  }

  /**
   * Spustí filtr podle zvolené org. ur.
   * @param orgUrGuid
   */
  public filterOrgUrHandler(orgUrGuid: string): void {
    this.projektyNacrtu.filter(orgUrGuid, "orgUrGuids", "filterOrgUr");
  }

  /**
   * Navigace na úvodní stránku
   */
  public back(): void {
    this.router.navigate(["/"]);
  }

  /*
   * Přidání nového řádku
  */
  add() {
    if (!this.canEdit) {
      this.messageService.add({ severity: 'error', summary: 'Chyba', detail: 'Nemáte oprávnění pro vytváření nových stylů projektů.', key: this.NACRT_PROJEKT_TOAST });
      return;
    }
    let data: NacrtProjektDto = new NacrtProjektDto();
    data.guid = this.uuidUtils.GenerateUuid();
    data.editableStyle = true;
    data.editableGeometries = false;
    data.orgUrGuids = [];
    data.bod = false;
    data.linie = false;
    data.plocha = false;
    data.fillRgba = 'rgba(255,255,255,1)';
    data.circleFillRgba = 'rgba(255,255,255,1)';
    data.circleStrokeRgba = 'rgba(255,255,255,1)';
    data.strokeRgba = 'rgba(255,255,255,1)';
    data.circleRadius = 16;
    data.circleStrokeWidth = 3;
    data.strokeWidth = 3;

    data.sync = new SyncDto();
    data.sync.i = data.guid;
    data.sync.u = 0;
    data.sync.h = [];

    this.selectedProjekt = data;
  }

  /*
   * Zrušení editačního formuláře
  */
  onFormCanceled() {
    this.selectedProjekt = undefined;
  }

  /*
   * Po uložení editačního formuláře
  */
  onFormSaved(dto: NacrtProjektDto) {
      let dtoToReplace: NacrtProjektDto = this.projekty.find(x => x.guid == dto.guid);
      if (dtoToReplace != void 0) this.projekty[this.projekty.indexOf(dtoToReplace)] = dto;
      else this.projekty.push(dto);

      this.projekty = [...this.projekty];
      this.selectedProjekt = undefined;
      this._updateSkupiny(this.projekty);
  }

  /**
   * Smazání projektu daného guid ze seznamu
   * @param guid {string} guid smazaného projektu
   */
  onDelete(guid: string) {
    this.projekty = this.projekty.filter(x => x.guid != guid)
    this.selectedProjekt = undefined;
  }


  /**
   * Vrací true, pokud má přihlášený uživatel právo editace náčrtů.
  **/
  get canEdit(): boolean {
    return this.roleUtils.checkRole([RoleEnum.NacrtyEditor, RoleEnum.NacrtyAdmin]);
  }
}
