import { Component, Input, OnInit } from '@angular/core';
import { EqContext, i18n } from '@easyquery/core';
import { AdvancedSearchView, EqViewOptions } from '@easyquery/ui';
import { EqLocaleInfo } from 'src/app/Utils/Shared/eq-locale-info.utils';

@Component({
  selector: 'app-general-eq',
  templateUrl: './general-eq.component.html',
  styleUrls: ['./general-eq.component.css']
})
export class GeneralEqComponent implements OnInit {

  _model: string = "";
  view: AdvancedSearchView;
  context: EqContext;

  constructor() { }

  ngOnInit(): void {
    this._loadEasyQuery();
    (window as any).EQView = this.view;
  }

  @Input() set model(value: string) {
    //Ponechávám tímto způsobem, kdyby došlo k rozšíření
    this._model = value;
  }

  //inicializace easy query
  private _loadEasyQuery(): void {
    i18n.updateLocaleInfo('cs', EqLocaleInfo.Czech);
    i18n.setCurrentLocale('cs');

    const options: EqViewOptions = {
      loadModelOnStart: true,
      defaultModelId: this._model,
      endpoint: "/api/easyquery",
      handlers: {
        onError: (context, status) => {
          console.error(status.action + " error:\n" + status.text);
        }
      },
      widgets: {
        entitiesPanel: {
        },
        columnsPanel: {
        },
        queryPanel: {
        },
      },
      result: {
        showChart: false
      }
    };

    this.view = new AdvancedSearchView();
    this.context = this.view.getContext();
    this.view.init(options);
  }
}
