import { EventEmitter, Injectable, Output } from "@angular/core";
import { SnappingChangedArgs, SnappingType } from "../../Dto/Mapa/SnappingChangeArgs";

/**
 * Service pro komunikaci přichytávání mezi komponentami.
**/
@Injectable({
  providedIn: 'root'
})
export class SnapInteractionService {

  /**
   * Událost pro zobrazení/skrytí přepínače přichytávání.
  **/
  @Output() ShowSnapSwitch: EventEmitter<boolean> = new EventEmitter<boolean>();


  /**
   * Událost předávající změnu nastavení přichytávání.
  **/
  @Output() SnapingChanged: EventEmitter<SnappingChangedArgs> = new EventEmitter<SnappingChangedArgs>();


  /**
   * Událost předávající informaci k aktivaci interakce přichytávání.
  **/
  @Output() Activate: EventEmitter<boolean> = new EventEmitter<boolean>();


  /**
   * Vypuštění události změny zobrazení přepínače přichytávání.
   * @param show {boolean} true, pokud má být přepínač zobrazen
   */
  showSnapSwitch(show: boolean): void {
    this.ShowSnapSwitch.emit(show);
  }


  /**
   * Vypuštění události změny nastavení přichytávání.
   * @param type {SnappingType} typ přichytávání
   * @param isOn {boolean} true, pokud je typ přichytávání zapnutý
   */
  snapingChanged(type: SnappingType, isOn: boolean): void {
    this.SnapingChanged.emit(new SnappingChangedArgs(type, isOn));
  }


  /**
   * Vypuštění události pro aktivaci přichytávání.
   * @param activate {boolean} true, pokud má být přichytávání aktivní
   */
  activate(activate: boolean): void {
    this.Activate.emit(activate);
  }
}
