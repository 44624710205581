import { Injectable } from '@angular/core';

/**
 * Pomocná třída pro přenos rozparsovaného JPRL. Odd je zde jako string, protože se následně tato hodnota posílá jako parametr requestu.
**/
export class Jprl {
  odd: string = '';
  dil: string = '';
}


/**
 * Pomocník pro parsování JPRL. Pro aktuální potřeby parsuje pouze oddělení a dílec.
**/
@Injectable({
  providedIn: 'root'
})
export class JprlUtils {

  public parseJprl(jprl: string): Jprl {
    var res = new Jprl();

    var arr = jprl.split("");
    for (var i = 0; i < arr.length; i++) {
      if (!isNaN(Number(arr[i]))) {
        res.odd += arr[i];
        continue;
      }

      if (typeof arr[i] == "string") {
        res.dil += arr[i];
        break;
      }
    }

    return res;
  }
}
