<p-toast position="top-center" key="vyroba-uzaverky-toast"></p-toast>
<app-block-ui [block]="block"></app-block-ui>

<p-toolbar styleClass="base-toolbar">
  <div class="p-toolbar-group-left">
    <h2 class="toolbar-label">Období - uzávěrky</h2>
    <i class="p-toolbar-separator"><fa-icon [icon]="faEllipsisV"></fa-icon></i>
    <div style="display: inline-block">
      <div class="p-inputgroup">
        <label style="margin-right:.5em;margin-left:.5em;padding: 7px 0;">Filtr na organizační úroveň</label>
        <app-org-ur variant="full" [(ngModel)]="filter" [allLevelsSelectable]="true"
                    (valueChanged)="filtruj($event)"></app-org-ur>
      </div>
    </div>
    <i class="p-toolbar-separator pi pi-bars"></i>
    <button pButton type="button" label="Smazat" class="icon-btn p-button-danger" (click)="remove()" style="margin-left:7px;"
            [disabled]="selectedItems==undefined||selectedItems.length==0"><fa-icon [icon]="faMinus"></fa-icon></button>
  </div>
  <div class="p-toolbar-group-right">
    <button pButton type="button" label="Zpět" class="icon-btn" (click)="back()"><fa-icon [icon]="faUndo"></fa-icon></button>
  </div>
</p-toolbar>

<propla-table #uzavekryTable [cols]="cols" [allItems]="items" [selectionMode]="'multi'" [checkboxes]="true"
              (selectionChanged)="onSelectionChanged($event)"></propla-table>

<ng-template #colDatum let-rowData let-col="column">
  {{rowData[col.field] | date:'d.M.yyyy'}}
</ng-template>

<ng-template #orgUr let-rowData>
  <app-org-ur-display [guid]="rowData['orgUrGuid']"></app-org-ur-display>
</ng-template>

<app-vyroba-uzaverky-init [visible]="initVisible"
                          (reloadUzaverky)="reloadUzaverky()" ></app-vyroba-uzaverky-init>
<app-vyroba-uzaverky-update [visible]="updateVisible" [selectedItems]="selectedItems"
                            (reloadUzaverky)="reloadUzaverky()"></app-vyroba-uzaverky-update>

<p-confirmDialog #confirmDlg header="Smazat" icon="pi pi-exclamation-triangle">
  <p-footer>
    <button type="button" pButton icon="pi pi-times" label="Ne" (click)="confirmDlg.reject()"></button>
    <button type="button" pButton icon="pi pi-check" label="Ano" (click)="confirmDlg.accept()"></button>
  </p-footer>
</p-confirmDialog>
