import { Component, Input, ViewChild } from '@angular/core';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Map from 'ol/Map';
import { Feature } from 'ol';
import { Extent } from 'ol/extent';
import Layer from 'ol/layer/Layer';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Fill, Stroke, Style, Text } from 'ol/style';
import { MapInteractionService } from '../../../Services/Mapa/map-interaction.service';
import { ExtentUtils } from '../../../Utils/Mapa/extent.utils';
import { MapComponent } from '../map/map.component';

@Component({
  selector: 'app-overview-map',
  templateUrl: './overview-map.component.html',
  styleUrls: ['./overview-map.component.css']
})
export class OverViewMapComponent
{
  /**
   * Kolekce mapových objektů k zobrazení.
  **/
  _features: Feature<any>[] = [];
  @Input() set features(value: Feature<any>[]) {
    if (value != void 0) {
      this._features = value;
      this._addFeatures(this._features);
    }
  }


  @ViewChild('overViewMap') overViewMap: MapComponent;

  constructor(
    private mapInteractionService: MapInteractionService,
    private extentUtils: ExtentUtils)
  {
    this.mapInteractionService.MapCreated.subscribe(this.mapCreatedHandler.bind(this));
  }


  faSearch = faSearch;
  readonly MAP_ID: string = 'dialogOverViewMap';
  //příznak, zda je již mapa vytvořena
  private _mapCreated: boolean = false;
  //vrstvy komponenty
  layers: Layer<any, any>[] = [];
  //vrstva pro vykreslení mapových objektů
  layer: Layer<any, any> = null;
  _extent: Extent = null;


  /**
   * Handler vytvoření mapy.
   * @param map {Map} Instance vytvořené mapy
   */
  private mapCreatedHandler(map: Map): void {
    if (map.getTargetElement().id == this.MAP_ID) {
      this._mapCreated = true;
      this.layers.push(this.layer);
      this.layers = this.layers.slice();
      this._addFeatures(this._features);
    }
  }

  /**
   * Přidání mapových objektů do vrstvy.
   * @param features {Feature<any>[]} seznam mapových objektů
  **/
  private _addFeatures(features: Feature<any>[]): void {
    if (features != void 0 && features.length > 0) {
      var source = this.layer.getSource();
      source.addFeatures(features);
      this._extent = source.getExtent();
      this.zoomToExtent(this._extent);
    }
    else {
      this.clearLayer();
    }
  }


  /**
   * Přiblížení na extent mapových objektů.
   * @param extent {Extent} bouding box k přiblížení
  **/
  zoomToExtent(extent: Extent): void {
    if (extent != void 0) {
      this.mapInteractionService.zoomTo(this.extentUtils.addBuffer(extent));
    }
  }


  /**
   * Inicializace komponenty.
  **/
  public init(): void {
    if (!this._mapCreated) {
      this.layer = this._constructLayer();
      this.mapInteractionService.createMap(this.MAP_ID);
    }
    else {
      this._addFeatures(this._features);
    }
  }


  /**
   * Vyprázdnění mapové vrstvy.
  **/
  public clearLayer(): void {
    if (this.layer?.getSource() != void 0) {
      this.layer.getSource().clear();
    }
  }


  /**
   * Konstrukce vrstvy pro zobrzení mapových objektů.
  **/
  private _constructLayer(): Layer<any, any> {
    let layer = new VectorLayer({
      source: new VectorSource(),
      style: (feature: Feature<any>, resolution: number) => { return this._styleFunction(feature); },
      properties: { name: 'exploded-layer' }
    });

    return layer;
  }


  /**
   * Style funkce pro featury.
   * @param feature {Feature<any>} mapové objekty
   */
  private _styleFunction(feature: Feature<any>): Style {
    let color: string = feature.get('barva');
    return new Style({
      stroke: new Stroke({
        color: color,
        width: 3
      }),
      fill: new Fill({
        color: color
      }),
      text: new Text({
        fill: new Fill({ color: "black" }),
        stroke: new Stroke({ color: "#ffffff", width: 3 }),
        text: feature.get('popis').toString()
      })
    });
  }


  /**
   * Aktualizace velikosti mapy.
  **/
  public resize(): void {
    if (this.overViewMap != void 0) {
      this.overViewMap.onResized(null);
      if (this._extent != void 0) {
        this.mapInteractionService.zoomTo(this.extentUtils.addBuffer(this._extent));
      }
    }
  }
}
