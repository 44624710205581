import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SkodyDetailSBaseComponent } from 'src/app/Components/Skody/skody-detail-s-base/skody-detail-s-base.component';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { LhpEtazProVypocetDto } from 'src/app/Dto/Skody/LhpEtazProVypocetDto';
import { LhpDrevinaProVypocetDto } from 'src/app/Dto/Skody/LhpDrevinaProVypocetDto';

@Component({
  selector: 'ujmy-detail-u8',
  templateUrl: './ujmy-detail-u8.component.html',
  styleUrls: ['./ujmy-detail-u8.component.css']
})
export class UjmyDetailU8Component extends SkodyDetailSBaseComponent implements OnInit {

  constructor(
    fb: FormBuilder
  ) {
    super(fb);
  }

  ngOnInit(): void {

    this.data.valueChanges
      .pipe(
        debounceTime(600),
        distinctUntilChanged((prev, curr) => prev.guid === curr.guid && prev.objem === curr.objem
          && prev.naklady === curr.naklady),
        filter(data => this.validateVstupyProPrepocet(data))
      )
      .subscribe(() => {
        this.dataChanged.emit(this.data.value);
      });

    this.data.get('objem').setValue(this._skoda.objem);
    this.data.get('naklady').setValue(this._skoda.naklady);
  }


  data: FormGroup = this.fb.group({
    guid: [''],
    objem: [''],
    naklady: ['']
  });

  /**
   * Kontrola dat z U8 pro přepočet škody.
   * @param data
   */
  validateVstupyProPrepocet(data): boolean {
    return data.objem != void 0
      && data.naklady != void 0;
  }


  /**
   * Zkopíruje data etáže z LHP do vstupu.
  **/
  kopirujLhpEtazDoVstupu(dto: LhpEtazProVypocetDto): void {

  }


  /**
   * Zkopíruje data dřeviny z LHP do vstupu.
  **/
  kopirujLhpDrevinuDoVstupu(dto: LhpDrevinaProVypocetDto): void {

  }
}
