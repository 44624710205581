import { Injectable } from '@angular/core';
import { UserInfoDto } from '../../Dto/Auth/UserInfoDto';

/**
 * Utilita pro ověření role přihlášeného uživatele
 **/
@Injectable({
  providedIn: 'root'
})
export class RoleUtils {

  /**
   * Ověření role přihlášeného uživatele
   * @param values - Seznam povolených rolí
   */
  checkRole(values: string[]): boolean {
    let userInfo: UserInfoDto = JSON.parse(localStorage.getItem('userInfo'));
    if (userInfo != void 0
      && userInfo.roles.length > 0
      && userInfo.roles.filter((val) => { return values.includes(val) }).length > 0) {
      return true;
    }
    return false;
  }

}
