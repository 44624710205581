import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { MessageService } from 'primeng/api';
import { AuthUtils } from 'src/app/Auth/auth.utils';
import { CredentialsDto } from 'src/app/Dto/Auth/CredentialsDto';
import { AuthService } from 'src/app/Services/Auth/auth.service';

@Component({
  selector: 'app-auth-login',
  templateUrl: './auth-login.component.html',
  styleUrls: ['./auth-login.component.css']
})
export class AuthLoginComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private authUtils: AuthUtils,
    protected activatedRoute: ActivatedRoute,
    protected router: Router,
    private messageService: MessageService
  ) {
    this.loginForm = new FormGroup({
      'userName': new FormControl('', { validators: [Validators.required] }),
      'password': new FormControl('', { validators: [Validators.required] })
    });
  }

  public readonly AuthLoginMsg: string = "auth-login-msg";

  ngOnInit() {
    this.authService.isLoged().subscribe(res => {
      if (res) this.router.navigate(['/plocha']);
    });
  }

  url: string;
  loginForm: FormGroup;
  get userName() { return this.loginForm.get('userName'); }
  get password() { return this.loginForm.get('password'); }
  faCheck = faCheck;
  submitted: boolean = false;
  loading: boolean = false;

  get f() { return this.loginForm.controls; }

  login(): void {
    this.submitted = true;
    if (this.loginForm.valid) {

      var cred = new CredentialsDto();
      cred.userName = this.loginForm.value.userName;
      //TODO: hashování
      cred.password = this.loginForm.value.password;

      this.loading = true;
      this.messageService.clear();
      this.authService.login(cred)
        .subscribe(res => {
          if (res != void 0 && res.token?.auth_token != void 0) {
            this.authUtils.setJwtToken(res.token);
            this.authUtils.setRefreshToken(res.refreshToken);
            let page = this.activatedRoute.snapshot.queryParams['page'];
            if (page == void 0) page = "plocha";//pokud neexistuje adresa pro redirect, tak přesměrovat vždy na plochu
            this.router.navigateByUrl('/' + page);
          } else {
            this.messageService.add({ key: this.AuthLoginMsg, severity: 'error', summary: 'Chyba', detail: "Nesprávné jméno nebo heslo" });
          }

          this.loading = false;
        });
    }
  }


  goToPolicyRestected() {
    ;
  }

  goToRoleRestected() {
    this.authService.goToRoleRestected().subscribe(res => {
      if (res != void 0) {

      }
    });
  }

  goToPublic() {
    this.authService.goToPublic().subscribe(res => {
      if (res != void 0) {

      }
    });
  }
}
