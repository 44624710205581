<form>
  <app-block-ui [block]="blockedPrepocet" [target]="krokyS72"></app-block-ui>
  <p-panel #krokyS72 header="Kroky výpočtu" [style]="{'margin-bottom': '1em'}"
           [toggleable]="true" [collapsed]="true" toggler="header">
    <div class="p-grid">
      <div class="p-col-10">
        <div class="p-grid p-align-center">
          <div class="p-10-col-1">
            <label>
              Plocha dřev. (m2)
              <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{skoda?.plochaVypocet}}</span>
            </label>
          </div>
          <div class="p-10-col-1">
            <label>
              Skupina dřev. (3)
              <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{okusDto?.skupinaDrevin3Nazev}}</span>
            </label>
          </div>
          <div class="p-10-col-1">
            <label>
              Z
              <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{okusDto?.prirustZ}}</span>
            </label>
          </div>
          <div class="p-10-col-1">
            <label>
              Skupina dřev. (6)
              <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{okusDto?.skupinaDrevin6Nazev}}</span>
            </label>
          </div>
          <div class="p-10-col-1">
            <label>
              Vyhl. počet
              <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{okusDto?.vyhlPocet}}</span>
            </label>
          </div>
          <div class="p-10-col-1">
            <label>
              N
              <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{okusDto?.n}}</span>
            </label>
          </div>
          <div class="p-10-col-1">
            <label>
              Npmax
              <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{okusDto?.npMax}}</span>
            </label>
          </div>
          <div class="p-10-col-1">
            <label>
              Nmax
              <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{okusDto?.nMax}}</span>
            </label>
          </div>
          <div class="p-10-col-1">
            <label>
              Np pro výpočet
              <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{okusDto?.npVypocet}}</span>
            </label>
          </div>
          <div class="p-10-col-1">
            <label>
              N pro výpočet
              <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{okusDto?.nVypocet}}</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </p-panel>
</form>
