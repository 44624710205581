export class NacrtProjektDto {
  guid: string;
  nazev: string;
  bod: boolean;
  linie: boolean;
  plocha: boolean;
  fillRgba: string;
  strokeRgba: string;
  strokeWidth: number;
  circleRadius: number;
  circleStrokeWidth: number;
  circleStrokeRgba: string;
  circleFillRgba: string;
  editableGeometries: boolean;
  editableStyle: boolean;
  povolAkce: boolean;
  skupina: string;
  zIndex: number;
  orgUrGuids: string[];
  sync: any;
  type: string;
  schvalProjektGuid: string;
  iconName: string;
  lineTypeName: string;
}
