import { Component, Input } from '@angular/core';
import { EtazDetailDto } from '../../../Dto/Lhp/EtazDetailDto';

@Component({
  selector: 'app-kniha-detail-etaz',
  templateUrl: './kniha-detail-etaz.component.html',
  styleUrls: ['./kniha-detail-etaz.component.css']
})
export class KnihaDetailEtazComponent {

  constructor() { }

  public readonly PL_SKUT_TOOLTIP = "Plocha skutečná";
  public readonly PL_PARC_TOOLTIP = "Plocha parciální";
  public readonly HS_TOOLTIP = "Hospodářský soubor";
  public readonly DOBA_OBN_TOOLTIP = "Doba obnovy";
  public readonly POC_OBN_TOOLTIP = "Počátek obnovy";
  public readonly PODIL_MZD_TOOLTIP = "Podíl MZD";
  public readonly ZAKM_TOOLTIP = "Zakmenění";
  public readonly HOSP_TV_TOOLTIP = "Hospodářský tvar";
  public readonly M_TEZ_PROC_TOOLTIP = "Modelové těžěbní procento";
  public readonly ODV_TEZ_TOOLTIP = "Způsob odvození těžby";
  public readonly M_Z_Z_ZAS_TOOLTIP = "Metoda zjištění zásoby";
  public readonly PRO_P_TOOLTIP = "Plocha prořezávky";

  public readonly PRO_POC_TOOLTIP = "Počet prořezávek";
  public readonly ODVOZ_TEZ_TOOLTIP = "Způsob odvození těžby";
  public readonly MZZ_TOOLTIP = "Metoda zjištění zásoby";
  public readonly PRO_NAL_TOOLTIP = "Naléhavost";
  public readonly PRO_NAL_HOSP_TOOLTIP = "Naléhavost hospodářská";
  public readonly TV_P_TOOLTIP = "Plocha TV";
  public readonly TV_NAL_TOOLTIP = "Naléhavost TV";
  public readonly TV_NAL_HOSP_TOOLTIP = "Naléhavost hospodářská TV";
  public readonly TV_POC_TOOLTIP = "Počet TV zásahů";
  public readonly TO_P_TOOLTIP = "Plocha TO";
  public readonly TO_NAL_TOOLTIP = "Naléhavost TO";
  public readonly TO_NAL_HOSP_TOOLTIP = "Naléhavost hospodářská TO";
  public readonly ZPUSOB_TO_TOOLTIP = "Způsob TO";
  public readonly DUVOD_TO_TOOLTIP = "Důvod TO";
  public readonly TYVB_P_TOOLTIP = "Plocha TVYB";
  public readonly TYVB_NAL_TOOLTIP = "Nalhavost TVYB";
  public readonly TYVB_NAL_HOSP_TOOLTIP = "Naléhavost hospodářská TVYB";
  public readonly ZAL_DRUH_TOOLTIP = "Druh zalesnení";
  public readonly ZAL_P_TOOLTIP = "Plocha zalesnění";

  @Input() etaz: EtazDetailDto;

}
