import { Component, OnInit, Input } from '@angular/core';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { VyrobaService } from 'src/app/Services/Vyroba/vyroba.service';
import { ResultInfoDto, ResultInfoGenericDto } from 'src/app/Dto/Shared/ResultInfoDto';
import { MessageService, TreeNode } from 'primeng/api';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { DVyrKontrolaListDto } from 'src/app/Dto/vyroba/DVyrKontrolaListDto';
import { MessagesUtils } from 'src/app/Utils/Shared/messages.utils';


@Component({
  selector: 'app-vyroba-kontrola',
  templateUrl: './vyroba-kontrola.component.html',
  styleUrls: ['./vyroba-kontrola.component.css']
})
export class VyrobaKontrolaComponent implements OnInit {

  constructor(private vyrobaService: VyrobaService, private messageUtils: MessagesUtils) { }

  ngOnInit(): void {
  }

  faCheck = faCheck;

  @Input() showBtn: boolean = true;
  @Input() disabled: boolean;
  @Input() kod: string;
  @Input() set doklady(value: any[]) {
    this._guids = value.map(x => x.guid);
  };

  display: boolean = false;
  messagesTree: TreeNode[];
  loading: boolean = false;

  private MESSAGE_KONTROLY_KEY: string = "kontroly-toast";
  private _guids;

  /**
   * spuštění kontroly
   * */
  check(): void {
    this.loading = true;
    this.vyrobaService.check(this._guids, this.kod).subscribe(res => {
      this.resolveResult(res);
    });
  }

  /**
   * Zpracuje výsledek dotazu
   * @param value
   */
  resolveResult(value: ResultInfoGenericDto<Array<ResultInfoGenericDto<Array<ResultInfoGenericDto<DVyrKontrolaListDto>>>>>): void {
    this.messagesTree = [];
    if (!value.success) {
      value.data.forEach(function (item) {
        var currentNode: TreeNode = { label: item.messages[0], data: item };
        this._fillTree(item.data, currentNode);
        if (!item.success)
          currentNode.expanded = true;

        this.messagesTree.push(currentNode);
        this.display = true;
      }, this);
    } else {
      this.messageUtils.showResponseMessage(this.MESSAGE_KONTROLY_KEY, value);
    }
    this.loading = false;
  }

  /**
   * Naplní větev
   * @param value
   */
  private _fillTree(value: Array<ResultInfoGenericDto<Array<ResultInfoDto>>>, node: TreeNode): void {
    if (value != void 0) {
      var nodes: TreeNode[] = [];//podřízené větve
      value.forEach(function (item) {
        var currentNode: TreeNode = { label: item.messages[0], data: item };
        if (item.data != void 0 && Array.isArray(item.data)) {//pokud obsahuje další pole
          this._fillTree((item.data as unknown as Array<ResultInfoGenericDto<Array<ResultInfoDto>>>), currentNode);
        }
        else if (item.data != void 0 && !Array.isArray(item.data)) {//pokud se jedná o objekt, tak převede na větev
          currentNode = this._kontrolaToTreeNode(item.data);
          if (!item.success)//zvýraznění chyb a upozornění
            currentNode.styleClass = (item.data as DVyrKontrolaListDto)?.chyba ? "error-node" : "warning-node";            
        }

        if (item.success) {
          currentNode.icon = 'pi pi-check';
        } else {
          currentNode.icon = 'pi pi-times';
          currentNode.expanded = true;
        }
        nodes.push(currentNode);
      }, this);
      node.children = nodes;
    }
  }

  /**
   * Převede DVyrKontrolaListDto na TreeNode
   * @param value
   */
  private _kontrolaToTreeNode(value: DVyrKontrolaListDto): TreeNode {
    return {
      label: value.popis + " - " + value.poznamka,
      data: value
    };
  }
}
