import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { faSave, faSync } from '@fortawesome/free-solid-svg-icons';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { NextOnEnterDirective } from 'src/app/Directives/next-on-enter.directive';
import { CDodavatelListDto } from 'src/app/Dto/Vyroba/CDodavatelListDto';
import { CDruhMzdyDetailDto } from 'src/app/Dto/Vyroba/CDruhMzdyDetailDto';
import { CSvatekListDto } from 'src/app/Dto/Vyroba/CSvatekListDto';
import { DochazkaEditDruhMzdyDto } from 'src/app/Dto/Vyroba/DochazkaEditDruhMzdyDto';
import { DochazkaEditDto } from 'src/app/Dto/Vyroba/DochazkaEditDto';
import { DochazkaEditPracovnikDto } from 'src/app/Dto/Vyroba/DochazkaEditPracovnikDto';
import { DochazkaService } from 'src/app/Services/Vyroba/dochazka.service';
import { SvatkyService } from 'src/app/Services/Vyroba/svatky.service';
import { MessagesUtils } from 'src/app/Utils/Shared/messages.utils';

@Component({
  selector: 'app-vyroba-dochazka',
  templateUrl: './vyroba-dochazka.component.html',
  styleUrls: ['./vyroba-dochazka.component.css']
})
export class VyrobaDochazkaComponent implements OnInit {

  private readonly MESSAGE_KEY: string = "dochazka-edit-toast";

  @ViewChild(NextOnEnterDirective) nextOnEnter: NextOnEnterDirective;

  @ViewChild('dochazkaTable') dochazkaTable: Table;

  constructor(private formBuilder: FormBuilder,
    private dochazkaService: DochazkaService,
    private messageService: MessageService,
    private messageUtils: MessagesUtils,
    private svatkyService: SvatkyService,
    private route: ActivatedRoute
  ) { }

  faSave = faSave;
  faSync = faSync;

  ngOnInit(): void {
    this.route.url.subscribe(seqments => {
      this.fromDoklad = !(seqments.length > 0 && seqments[0].path == 'dochazka');
      if (!this.fromDoklad) {
        this.loadDochazky();
      }
    });
  }

  @Input() edit: boolean = true;
  @Input() vml: boolean = false;
  @Input() dokladGuid: string;

  formData: FormGroup = this.formBuilder.group({
    dny: this.formBuilder.array([])
  });

  radky: RadekDto[];
  selectedRadek: RadekDto = null;
  dochazkaData: DochazkaEditDto = new DochazkaEditDto();
  block: boolean = false;
  private fromDoklad: boolean = true;
  tydnyHeader: TydnyHeaderDto[] = [];
  dnyHeader: DnyHeaderDto[] = [];
  private _dnyNaz = ['Ne', 'Po', 'Ut', 'St', 'Čt', 'Pa', 'So'];
  svatky: CSvatekListDto[];
  //příznak, zda se má přepočítávat docházka (hodiny a dny).
  //Jde o to, že change handler musí být na celém formArray(abychom mohli pracovat s nově vloženými daty ve formuláři) a ten se volá
  //i při výběru řádku (tolikrát, kolik je dní...)
  private recalculate: boolean = false;

  newRadek: RadekDto = new RadekDto();



  /**
   * Vytvoření struktury tabulky
   * */
  private setHeaderTable(): void {
    this.tydnyHeader = [];
    this.dnyHeader = [];
    let rok = this.dochazkaData?.rok;
    let mesic = this.dochazkaData?.mesic - 1;
    var numOfDays = new Date(rok, mesic + 1, 0).getDate();
    if (!isNaN(numOfDays)) {
      for (var den = 1; den <= numOfDays; den++) {
        let aktualniDenObj: Date = new Date(rok, mesic, den);
        let svatekDto = this.svatky.find(x => x.mesic == this.dochazkaData.mesic && x.den == den);
        let nazevDne = this._dnyNaz[aktualniDenObj.getDay()];
        let nepracovni = false;
        let svatek = false
        let popis: string;
        if (svatekDto != void 0 && svatekDto.svatekCr) {
          nepracovni = true;
          svatek = true;
          popis = svatekDto.popis;
        } else if (nazevDne == "Ne" || nazevDne == "So") {
          nepracovni = true;
          svatek = nazevDne == "Ne";
          popis = "Víkend";
        }
        this.dnyHeader.push({ cislo: den, nazev: nazevDne, popis: popis, svatek: svatek, nepracovni: nepracovni });
        var firstDayOfYear = new Date(Date.UTC(rok, 0, 1));//první den v roce
        var weekNo = Math.ceil((((aktualniDenObj.getTime() - firstDayOfYear.getTime()) / 86400000) + firstDayOfYear.getDay() + 1) / 7);//číslo týdne na aktuální den
        var tyden = this.tydnyHeader.find(x => x.cislo == weekNo);
        if (tyden == void 0) {
          this.tydnyHeader.push(this.tydnyHeader.length == 0 ? { cislo: weekNo, pocetDni: 2 } : { cislo: weekNo, pocetDni: 1 });//první týden je potřeba ošetřit
        } else if (den != numOfDays) {//i poslední týden je potřeba ošetřit
          tyden.pocetDni++;
        }
      }
      this._createContentTable();
    }
  }

  /**
   * Sestavení obsahu tabulky 
   * */
  private _createContentTable(): void {
    if (this.tydnyHeader.length > 0 && this.dnyHeader.length > 0) {
      this.radky = [];
      if (this.dochazkaData != void 0) {
        var radekIndex = 1;
        this.dochazkaData.pracovnici.forEach(function (pracovnik) {
          var denIndex = 0;
          pracovnik.druhyMzdy.forEach(function (mzda) {
            var denIndex = 0
            var dny: DenDto[] = [];
            mzda.hodiny.forEach(function (hodinyDne) {
              denIndex++;
              var den: DenDto = new DenDto();
              den.hodiny = hodinyDne;
              den.nepracovni = false;
              var svatek = this.svatky.find(x => x.mesic == this.dochazkaData.mesic && x.den == denIndex);
              var nazevDne = this.dnyHeader.find(x => x.cislo == denIndex)?.nazev;
              if ((svatek != void 0 && svatek.svatekCr) || nazevDne == "So" || nazevDne == "Ne") {
                den.nepracovni = true;
              }
              dny.push(den);
            }, this);

            var radek: RadekDto = {
              cislo: radekIndex++,
              druhMzdyGuid: mzda.druhMzdyGuid,
              pracovnikGuid: pracovnik.pracovnikGuid,
              dokladGuid: mzda.dokladGuid,
              dokladCislo: mzda.dokladCislo,
              dokladDatum: mzda.dokladDatum,
              dokladOrgUr: mzda.dokladOrgUr,
              dochazkaHodiny: this.calculateHodiny(mzda.hodiny),
              dochazkaDny: this.calculateDny(mzda.hodiny),
              odpracHodiny: mzda.odpracHodiny,
              odpracDny: mzda.odpracDny,
              dny: dny
            };
            this.radky.push(radek);
          }, this);
        }, this);
      }
    }
  }

  getDny() {
    return this.dny.controls;
  }

  get dny() {
    return (this.formData.get("dny") as FormArray);
  }

  //načte docházky dokladu
  loadDochazky(): void {
    this.block = true;
    this.clearRadky();
    this.dochazkaService.edit(this.dochazkaData?.rok?.toString(), this.dochazkaData?.mesic?.toString(), null, this.dokladGuid).subscribe(resp => {
      if (resp.success) {
        this.dochazkaData = resp.data;
        if (resp.data.rok != void 0) {
          this.svatkyService.list(resp.data.rok).subscribe(resp => {
            if (resp.success)
              this.svatky = resp.data;

            this.setHeaderTable();
          });
        }
      }
      this.block = false;
      resp.messages = ["Docházky načteny"];
      this.messageUtils.showResponseMessage(this.MESSAGE_KEY, resp);
    }, error => {
      this.block = false;
        this.messageService.add({ severity: 'error', summary: 'Chyba', key: this.MESSAGE_KEY, detail: 'Došlo k neočekávané chybě.', life: MessagesUtils.TOAST_LIFE })
    });
  }


  //uložení
  save() {
    this.block = true;

    //aktualizace dat
    this.radky.forEach(function (radek: RadekDto) {
      var pracovnik = this.dochazkaData.pracovnici.find(x => x.pracovnikGuid == radek.pracovnikGuid);
      if (pracovnik && !pracovnik.druhyMzdy.find(x => x.druhMzdyGuid == radek.druhMzdyGuid)) {//pracovník exituje, ale nexistuje mzda
        pracovnik.druhyMzdy.push(this._newMzda(radek, pracovnik));
      } else if (!pracovnik) {//pracovník neexistuje
        var newPracovnik = new DochazkaEditPracovnikDto();
        newPracovnik.pracovnikGuid = radek.pracovnikGuid;
        newPracovnik.druhyMzdy = [];
        newPracovnik.druhyMzdy.push(this._newMzda(radek, pracovnik));
        this.dochazkaData.pracovnici.push(newPracovnik);
      } else {//aktualizace existujících záznamů
        var druhMzdy = pracovnik.druhyMzdy.find(x => x.druhMzdyGuid == radek.druhMzdyGuid);
        if (pracovnik.pracovnikGuid == radek.pracovnikGuid && druhMzdy) {
          druhMzdy.hodiny = [];
          radek.dny.forEach(function (den) {
            druhMzdy.hodiny.push(den.hodiny);
          });
        }
      }
    }, this)

    this.dochazkaService.save(this.dochazkaData).subscribe(resp => {
      if (resp.success) {
        resp.messages = ["Docházka byla uložena"];
      }
      this.messageUtils.showResponseMessage(this.MESSAGE_KEY, resp);
      this.block = false;
    }, error => {
        this.messageService.add({ severity: 'error', summary: 'Chyba', key: this.MESSAGE_KEY, detail: 'Došlo k neočekávané chybě.', life: MessagesUtils.TOAST_LIFE });
      this.block = false;
    });
  }

  /**
   * Vrátí novou novou mzdu pro vybraný řádek a pracovníka
   * @param radek
   * @param pracovnik
   */
  private _newMzda(radek: RadekDto, pracovnik: DochazkaEditPracovnikDto): DochazkaEditDruhMzdyDto {
    var result: DochazkaEditDruhMzdyDto = new DochazkaEditDruhMzdyDto();
    if (!pracovnik || (pracovnik && !pracovnik.druhyMzdy.find(x => x.druhMzdyGuid == radek.druhMzdyGuid))) {
      result.druhMzdyGuid = radek.druhMzdyGuid;
      result.hodiny = [];
      radek.dny.forEach(function (den) {
        result.hodiny.push(den.hodiny);
      }, this);
    }
    return result;
  }

  //Výběr řádku
  onRowSelected(radek: any): void {
    this.selectedRadek = radek.data;
    this.dny.clear();

    this.selectedRadek.dny.forEach(function (den) {
      let formDay: FormGroup = this.formBuilder.group(den);
      //handler zmeny konkrétního dne (inputu)
      formDay.valueChanges.subscribe(val => {
        this.recalculate = true;
      });
      this.dny.push(formDay);
    }, this);

    //handler zmeny formArray - zde už bude zapsaná provedená změna v konkrétním dni
    this.dny.valueChanges.subscribe(val => {
      if (this.recalculate) {
        this.updateFromFormData();
      }
      this.recalculate = false;
    });
  }

  //zrušení výběru řádku
  onRowUnSelected(radek: any): void {
    this.selectedRadek = null;
    this.dny.clear();
  }


  /**
   * Aktualizace dat docházky dle dat ve formuláři dnů (vloženo uživatelem).
   * Volá se před rowSelect/rowUnselect.
   */
  updateFromFormData(): void {
    this.selectedRadek.dny = this.dny.value;
    this.selectedRadek.dochazkaHodiny = this.calculateHodiny(this.selectedRadek.dny.map(d => d.hodiny));
    this.selectedRadek.dochazkaDny = this.calculateDny(this.selectedRadek.dny.map(d => d.hodiny));
  }

  /**
   * Vymaže řádky a vynuluje vybraný řádek.
  **/
  private clearRadky(): void {
    this.radky = [];
    this.selectedRadek = null;
  }

  /**
   * Přidá nový řádek
   * */
  addRadek(): void {
    if (this.newRadek.pracovnikGuid != void 0 && this.newRadek.druhMzdyGuid != void 0) {
      this.newRadek.cislo = this.radky.length == 0 ? 1 : Math.max(...this.radky.map(o => o.cislo), 0) + 1;
      let rok = this.dochazkaData?.rok;
      let mesic = this.dochazkaData?.mesic;
      if (rok == void 0 || mesic == void 0) {
        this.messageService.add({ severity: 'error', summary: 'Chyba', key: this.MESSAGE_KEY, detail: 'Vyberte rok a měsíc.', life: MessagesUtils.TOAST_LIFE });
      } else {
        let pocetDni = new Date(rok, mesic, 0).getDate();
        this.newRadek.dny = [];
        for (var i = 0; i < pocetDni; i++) {
          var den: DenDto = { hodiny: null, nepracovni: false };
          var svatek = this.svatky.find(x => x.mesic == this.dochazkaData.mesic && x.den == i);
          var nazevDne = this.dnyHeader.find(x => x.cislo == i)?.nazev;
          if ((svatek != void 0 && svatek.svatekCr) || nazevDne == "So" || nazevDne == "Ne") {
            den.nepracovni = true;
          }
          this.newRadek.dny.push(den);
        }
        this.radky.push(this.newRadek);
        this.onRowSelected({ data: this.newRadek });
        this._setDochazkaPage();
        //vymazat obsah editace přidávání nového řádku
        this.newRadek = new RadekDto();
        this.newRadek.pracovnikGuid = null;
        this.newRadek.druhMzdyGuid = null;
      }

    } else {
      this.messageService.add({ severity: 'error', summary: 'Chyba', key: this.MESSAGE_KEY, detail: 'Pro přidání nového záznamu musí být zvolený pracovník i druh mzdy.', life: MessagesUtils.TOAST_LIFE });
    }

  }

  /**
   * Filtr číselníku pracovníků
   * @param ciselnik
   */
  filterPracovnici(ciselnik: CDodavatelListDto): boolean {
    return ciselnik.zamestnanec;
  }

  /**
   * Filtr druhu mezd
   * @param mzda
   */
  filterMzdy(mzda: CDruhMzdyDetailDto): boolean {
    return mzda.odchylka;
  }


  /**
   * Výpočet celkového počtu hodin v měsíci.
   * @param hodiny
   */
  private calculateHodiny(hodiny: number[]): number {
    return hodiny.reduce((sum, val) => { return sum + val; }, 0);
  }


  /**
   * Vrátí počet dní, ve kterých jsou vykázané hodiny.
   * @param hodiny
   */
  private calculateDny(hodiny: number[]): number {
    return hodiny.reduce((sum, val) => { return sum = val != 0 && val != void 0 ? ++sum : sum; }, 0);
  }

  /**
   * Přejde na stránku tabulky docházky s vybraným řádkem
   * */
  private _setDochazkaPage(): void {
    var items = (this.dochazkaTable != void 0 && this.dochazkaTable.filteredValue != void 0) ? this.dochazkaTable.filteredValue : this.radky;
    if (items != void 0 && this.selectedRadek != void 0) {
      var index = items.indexOf(this.selectedRadek);
      if (index != -1) {
        this.dochazkaTable.first = (Math.floor(index / this.dochazkaTable.rows) * this.dochazkaTable.rows);
        this.dochazkaTable.firstChange.emit(this.dochazkaTable.first);
      }
    }
  }
}

//TDOčka v rámci komponenty. Jinde se nepoužijí.

export class DnyHeaderDto {
  cislo: number;
  nazev: string;
  svatek: boolean;
  nepracovni: boolean;
  popis: string;
}

export class TydnyHeaderDto {
  cislo: number;
  pocetDni: number;
}

export class RadekDto {
  cislo: number;
  pracovnikGuid: string;
  druhMzdyGuid: string;
  dokladGuid: string;
  dokladOrgUr: string;
  dokladDatum: Date;
  dokladCislo: number;
  dochazkaHodiny: number;
  dochazkaDny: number;
  odpracHodiny: number;
  odpracDny: number;
  dny: DenDto[]
}

export class DenDto {
  hodiny: number;
  nepracovni: boolean;
}

