<button pButton type="button" class="icon-btn" (click)="displayDialog=true" label="Editace oprávnění" *ngIf="superAdminGuard.canActivate()"><fa-icon [icon]="faUserLock"></fa-icon></button>

<p-dialog header="Editace oprávnění přístupu k číselníkům" [(visible)]="displayDialog" [modal]="true" [style]="{width: '70vw'}" [baseZIndex]="10000"
          [draggable]="false" [resizable]="false" [contentStyle]="{height: '100vw', 'padding-bottom': '0px'}" (onHide)="close()">

  <p-toast position="top-center" [key]="TOAST_KEY"></p-toast>

  <div class="p-grid ciselniky-opravneni-edit-body">
    <div class="p-col-2">
      <p-panel header="Výběr role">
        <p-listbox [options]="role" [(ngModel)]="selectedRole" (onChange)="roleChanged($event?.value?.popis)" optionLabel="popis"></p-listbox>
      </p-panel>
    </div>
    <div class="p-col-10" style="display: flex; height: calc(100% - 3rem); ">
      <p-table #opravneniTable [value]="ciselnikyToDisplay" [scrollable]="true" scrollHeight="flex" [(selection)]="selectedCiselniky"
               styleClass="p-datatable-striped pds-table-compact" (onHeaderCheckboxToggle)="opravneniChangedHandler()"
               (onRowSelect)="opravneniChangedHandler()" (onRowUnselect)="opravneniChangedHandler()"
               [globalFilterFields]="['nazev','popis']" [ngStyle]="{'width':'100%'}">
        <ng-template pTemplate="caption">
          <div style="float: right;">
            <span class="p-input-icon-left p-mr-2">
              <i class="pi pi-search"></i>
              <input pInputText type="text" (input)="opravneniTable.filterGlobal($event.target.value, 'contains')" placeholder="Hledat" />
            </span>
            <p-dropdown name="filter" [options]="filterOptions" [(ngModel)]="filter" (onChange)="filtruj($event?.value)" baseZIndex="100000" appendTo="body"
                        [style]="{'width': '10rem'}" pTooltip="Zobrazit" tooltipPosition="bottom" tooltipZIndex="100010"></p-dropdown>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th class="use-col" tooltipPosition="top" pTooltip="Povolit prohlížení číselníku pro zvolenou roli" tooltipZIndex="100000"
                style="flex-basis: 10%;">
              <p-tableHeaderCheckbox tooltipPosition="bottom" pTooltip="Vybrat vše" tooltipZIndex="100000" class="p-mr-2"></p-tableHeaderCheckbox>
              Prohlížet
            </th>
            <th class="edit-col" tooltipPosition="top" pTooltip="Povolit editaci číselníku pro zvolenou roli" tooltipZIndex="100000"
                style="flex-basis: 10%;">
              <p-checkbox [(ngModel)]="selectAllEdit" [binary]="true" (onChange)="selectAllEditChanged()" *ngIf="this.selectedCiselniky?.length>0"
                          tooltipPosition="bottom" pTooltip="Vybrat vše" tooltipZIndex="100000" class="p-mr-2"></p-checkbox>
              Editovat
            </th>
            <th pSortableColumn="nazev" style="flex-basis: 35%;">Název <p-sortIcon field="nazev"></p-sortIcon></th>
            <th pSortableColumn="popis" style="flex-basis: 15%;">Popis <p-sortIcon field="popis"></p-sortIcon></th>
            <th pSortableColumn="schema" style="flex-basis: 15%;">Schéma <p-sortIcon field="schema"></p-sortIcon></th>
            <th class="typ-col" pSortableColumn="typ" style="flex-basis: 15%;">Typ číselníku <p-sortIcon field="typ"></p-sortIcon></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr>
            <td class="use-col" style="flex-basis: 10%;">
              <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
            </td>
            <td class="edit-col" style="flex-basis: 10%;">
              <p-checkbox value="0" [(ngModel)]="rowData.opravneni" [binary]="true" *ngIf="checkSelected(rowData)" (onChange)="onEditChange()"></p-checkbox>
            </td>
            <td style="flex-basis: 35%;">
              {{rowData.nazev}}
            </td>
            <td style="flex-basis: 15%;">
              {{rowData.popis}}
            </td>
            <td style="flex-basis: 15%;">
              {{rowData.schema}}
            </td>
            <td class="typ-col" style="flex-basis: 15%;">
              {{getTypPopisByKod(rowData.typ)}}
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <button pButton label="Uložit" class="icon-btn p-button-success" (click)="save()" [disabled]="!changed"><fa-icon [icon]="faSave"></fa-icon></button>
    <button pButton (click)="close()" label="Zavřít" class="icon-btn p-button-secondary p-button-outlined"><fa-icon [icon]="faTimes"></fa-icon></button>
  </ng-template>
</p-dialog>
