<propla-table #cdTable [cols]="cols" [allItems]="doklady" [checkboxes]="true"
              (selectionChanged)="selectionChangeHandler($event)"></propla-table>

<ng-template #colDatumDokladu let-rowData>
  {{rowData.datum | date:'d.M.yyyy'}}
</ng-template>

<ng-template #colNumber let-rowData let-col="column">
  <div class="right">{{rowData[col.field] | number:'1.2-2'}}</div>
</ng-template>

<ng-template #colOrgUr let-rowData>
  <app-org-ur-display [guid]="rowData['orgUrGuid']"></app-org-ur-display>
</ng-template>

<ng-template #colCiselnik let-rowData let-col="column">
  <app-ciselnik-kod [guid]="rowData[col.field]" [ciselnik]="col.ciselnik"></app-ciselnik-kod>
</ng-template>
