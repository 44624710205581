<button pButton [ngClass]="{'icon-btn show-layers-button':true, 'show-layers-button-nav':displayNav}" class="icon-btn hide-label-button p-button-secondary p-button-outlined"
        (click)="layersVisible = true" *ngIf="!layersVisible" [disabled]="_loadingLayers">
  <fa-icon [icon]="showPanelIcon" [spin]="_loadingLayers"></fa-icon>
</button>

<app-block-ui [block]="layersVisible && _loadingLayers" [target]="mapLayerListBlockable"></app-block-ui>

<p-sidebar #layersSideBar [(visible)]="layersVisible" [modal]="true" [dismissible]="true" [showCloseIcon]="false"
           styleClass="map-side-bar layers-side-bar" [style]="{width:'24rem'}" [ngClass]="{'map-side-bar-nav':displayNav}"
           (onShow)="onLayersPanelShow()" (onHide)="onLayersPanelHide()">

  <p-toolbar [style]="{'margin-bottom':'0.5em'}">
    <div class="p-toolbar-group-left">
      <fa-icon [icon]="faLayerGroup" style="color: var(--text-color-secondary); margin-right: 8px; font-size: 1.8em;"></fa-icon>
    </div>
    <div class="p-toolbar-group-right">
      <button pButton class="icon-btn hide-label-button layers-panel-button"
              pTooltip="Obnovit mapu"
              (click)="reload()"
              [disabled]="reloadDisabled">
        <fa-icon [icon]="faSync"></fa-icon>
      </button>
      <i class="p-toolbar-separator"><fa-icon [icon]="faEllipsisV"></fa-icon></i>
      <button pButton class="icon-btn hide-label-button"
              pTooltip="Rozbalí seznam vrstev"
              (click)="mapLayerList.expandAll()">
        <fa-layers [fixedWidth]="true">
          <fa-icon [icon]="faChevronDown" transform="up-3"></fa-icon>
          <fa-icon [icon]="faChevronDown" transform="down-4"></fa-icon>
        </fa-layers>
      </button>
      <button pButton class="icon-btn hide-label-button layers-panel-button"
              pTooltip="Sbalí seznam vrstev"
              (click)="mapLayerList.collapseAll()">
        <fa-layers [fixedWidth]="true">
          <fa-icon [icon]="faChevronUp" transform="up-3"></fa-icon>
          <fa-icon [icon]="faChevronUp" transform="down-4"></fa-icon>
        </fa-layers>
      </button>
      <i class="p-toolbar-separator"><fa-icon [icon]="faEllipsisV"></fa-icon></i>
      <button pButton class="icon-btn hide-label-button"
              [pTooltip]="docked ? 'Uvolní panel' : 'Přichytí panel'"
              (click)="dock()">
        <fa-icon [icon]="faThumbtack" [rotate]="docked ? 0 : 90"></fa-icon>
      </button>
      <button pButton class="icon-btn hide-label-button layers-panel-button"
              pTooltip="Zavře panel"
              (click)="close()">
        <fa-icon [icon]="faTimes"></fa-icon>
      </button>
    </div>
  </p-toolbar>

  <blockable-div #mapLayerListBlockable class="map-side-bar-content">
    <app-map-layer-list #mapLayerList [layerSources]="layerSources"
                        (visibleLayersChanged)="onVisibleLayersChanged($event)"></app-map-layer-list>
  </blockable-div>
</p-sidebar>
