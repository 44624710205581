<div class="p-grid content-height">
  <div class="p-col-1 content-height">
    <p-table [value]="lheListFilter" [(selection)]="selectedLheListFilter" selectionMode="single" dataKey="value"
             (onRowSelect)="onLheFilterSelect()" (onRowUnselect)="onLheFilterUnselect()" styleClass="p-datatable-gridlines p-datatable-striped pds-table-compact"
             [scrollable]="true" scrollHeight="flex">
      <ng-template pTemplate="header">
        <tr>
          <th>Etáž/IH</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-lhe>
        <tr [pSelectableRow]="lhe">
          <td><b>{{lhe?.value}}</b></td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div class="p-col lhe-list-body">
    <div class="lhe-list content-height">
      <p-table [value]="lheCurrentList" [(selection)]="selectedLheList" selectionMode="single" dataKey="guid"
               styleClass="p-datatable-gridlines p-datatable-striped pds-table-compact"
               [scrollable]="true" scrollHeight="flex">
        <ng-template pTemplate="header">
          <tr>
            <th style="flex-basis: 34%">Činnost</th>
            <th style="flex-basis: 18%">Datum</th>
            <th style="flex-basis: 16%">Plocha (ha)</th>
            <th style="flex-basis: 16%">Množství (m<sup>3</sup>)</th>
            <th style="flex-basis: 16%">Dřevina</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-lhe>
          <tr [pSelectableRow]="lhe">
            <td style="flex-basis: 34%"><b><app-ciselnik-kod #ciselnikCinnosti ciselnik="CLheCinnost" [guid]="lhe.lheCinnostGuid"></app-ciselnik-kod> - {{ciselnikCinnosti?.popis}}</b></td>
            <td style="text-align:right; flex-basis:18%">{{lhe.mesic | number : '2.0-0' : 'cs'}}.{{lhe.rok}}</td>
            <td style="text-align:right; flex-basis:16%">{{lhe.plocha | number : '.4-4' : 'cs'}}</td>
            <td style="text-align:right; flex-basis:16%">{{lhe.mnozstvi | number : '.3-3' : 'cs'}}</td>
            <td style="flex-basis:16%"><b><app-ciselnik-kod ciselnik="CDrevin" [guid]="lhe.drevinaGuid"></app-ciselnik-kod></b></td>
         </tr>
        </ng-template>
        <ng-template pTemplate="footer">
          <tr>
            <td style="flex-basis:34%;text-align: left;font-weight: bold;" tooltipPosition="bottom" pTooltip="Sumární řádek">∑</td>
            <td style="flex-basis:18%"></td>
            <td style="text-align:right; flex-basis:16%">{{sumLheColumn('plocha') | number : '.4-4' : 'cs'}}</td>
            <td style="text-align:right; flex-basis: 16%">{{sumLheColumn('mnozstvi') | number : '.3-3' : 'cs'}}</td>
            <td style="flex-basis:16%"></td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <div class="lhe-edit">
      <app-lhe-edit [data]="selectedLheList" (onChanged)="onLhcEditChanged($event)"
                    [lheListFilter]="selectedLheListFilter" [pskGuid]="pskGuid"></app-lhe-edit>
    </div>
  </div>
</div>
