import { EventEmitter, Injectable, Output } from "@angular/core";
import { DNacrtPrilohaSyncDto } from "../../Dto/Nacrty/DNacrtPrilohaSyncDto";
import { IPriloha } from "../../Dto/Nacrty/IPriloha";
import { NacrtMapItemDto } from "../../Dto/Nacrty/NacrtMapItemDto";
import { NacrtPrilohyChangedArgs } from "../../Dto/Nacrty/NacrtPrilohyChangedArgs";
import { LoggingEventEmitter } from "../../Extensions/LoggingEventEmitter";
import { UuidUtils } from "../../Utils/Shared/uuid.utils";
import { NacrtyService } from "./nacrty.service";

/**
 * Service sloužící ke komunikaci mezi komponentami náčrtu.
**/
@Injectable()
export class NacrtyInteractionService
{

  constructor(
    private nacrtyService: NacrtyService,
    private uuidUtils: UuidUtils)
  { }

  private _nacrtData: NacrtMapItemDto = null;


  /**
   * Kolekce příloh náčrtu.
  **/
  private _prilohy: DNacrtPrilohaSyncDto[] = null;
  get prilohy(): DNacrtPrilohaSyncDto[] {
    return this._prilohy;
  }

  /**
   * Vyprázdní seznam příloh.
  **/
  clearPrilohy(): void {
    this._prilohy = null;
  }


  /**
   * Událost inicializace editace příloh náčrtu.
  **/
  @Output() OnInitEdit: EventEmitter<NacrtPrilohyChangedArgs> = new LoggingEventEmitter<NacrtPrilohyChangedArgs>('OnInitEdit');

  /**
   * Zahájení editace příloh náčrtu.
   * @param nacrtData {NacrtMapItemDto} Data náčrtu
   * @param newNacrt {boolean} true, pokud jde o tvorbu nového náčrtu
   */
  initEdit(nacrtData: NacrtMapItemDto, newNacrt: boolean): void {
    this._nacrtData = nacrtData;
    if (this._prilohy == void 0) {
      this._prilohy = [];
      if (newNacrt) {
        this.OnInitEdit.emit({ prilohy: this._prilohy, added: [], deleted: null, nacrtData: nacrtData });
      }
      else {
        this.nacrtyService.getPrilohy(nacrtData.id).subscribe(resp => {
          if (resp != void 0) {
            this._prilohy = resp;
            this._prilohy.forEach(p => p._dirty = true);
            this.OnInitEdit.emit({ prilohy: this._prilohy, added: [], deleted: null, nacrtData: nacrtData });
          }
        });
      }
    }
    else {
      this.OnInitEdit.emit({ prilohy: this._prilohy, added: [], deleted: null, nacrtData: nacrtData });
    }    
  }


  /**
   * Událost inicializace prohlížení příloh náčrtu.
  **/
  @Output() OnInitView: EventEmitter<NacrtPrilohyChangedArgs> = new LoggingEventEmitter<NacrtPrilohyChangedArgs>('OnInitView');

  /**
   * Zahájení prohlížení příloh náčrtu.
   * @param nacrtData {NacrtMapItemDto} Data náčrtu
   */
  initView(nacrtData: NacrtMapItemDto): void {
    this._nacrtData = nacrtData;
    if (this._prilohy == void 0) {
      this._prilohy = [];
      this.nacrtyService.getPrilohy(nacrtData.id).subscribe(resp => {
        if (resp != void 0) {
          this._prilohy = resp;
          this._prilohy.forEach(p => p._dirty = true);
          this.OnInitView.emit({ prilohy: this._prilohy, added: [], deleted: null, nacrtData: nacrtData });
        }
      });
    }
    else {
      this.OnInitView.emit({ prilohy: this._prilohy, added: [], deleted: null, nacrtData: nacrtData });
    }
  }


  /**
   * Událost přidání přílohy k náčrtu.
  **/
  @Output() OnAdd: EventEmitter<NacrtPrilohyChangedArgs> = new LoggingEventEmitter<NacrtPrilohyChangedArgs>('OnAdd');

  /**
   * Přidání přílohy k náčrtu.
   * @param file {File} Metadata uploadovaného soubor přílohy
   * @param base64data {string} Base64 data přílohy
   * @param typ {string} Typ přílohy
   */
  add(file: File, base64data: string, typ: string): void {
    let guid = this.uuidUtils.GenerateUuid();

    let newPriloha: DNacrtPrilohaSyncDto = {
      guid: guid,
      nacrtGuid: this._nacrtData.id,
      typ: typ,
      souborNazev: file.name,
      soubor: base64data,
      type: 'DNacrtPriloha',
      sync: {
        d: false,
        i: guid,
        u: 0,
        h: []
      },
      _dirty: false
    };

    this._prilohy.push(newPriloha);

    this.OnAdd.emit({ prilohy: this._prilohy, added: [newPriloha], deleted: null, nacrtData: null });
  }


  /**
   * Událost smazání přílohy.
  **/
  @Output() OnDelete: EventEmitter<NacrtPrilohyChangedArgs> = new LoggingEventEmitter<NacrtPrilohyChangedArgs>('OnDelete');

  /**
   * Smazání přílohy.
   * @param deleted {IPriloha} Metadata smazané přílohy
   */
  delete(deleted: IPriloha): void {
    let i = this._prilohy.findIndex(x => x.guid == deleted.id);
    let deletedPriloha = this._prilohy[i];
    if (deletedPriloha._dirty) {
      deletedPriloha.sync.d = true;
    }
    else {
      this._prilohy.splice(i, 1);
    }

    this.OnDelete.emit({ prilohy: this._prilohy, added: [], deleted: deletedPriloha, nacrtData: null });
  }
}
