import { Component, Input, OnInit } from '@angular/core';
import { faInfoCircle, faKey, faSquare, faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { CiselnikInfoDto } from '../../../Dto/Core/CiselnikInfoDto';
import { CiselnikSeznamSloupecDto } from '../../../Dto/Core/CiselnikSeznamSloupecDto';

/**
 * Komponenta pro zobrazení vlastností sloupců tabulky číselniku
 **/
@Component({
  selector: 'app-ciselnik-edit-info',
  templateUrl: './ciselnik-edit-info.component.html',
  styleUrls: ['./ciselnik-edit-info.component.css']
})
export class CiselnikEditInfoComponent implements OnInit {

  faInfoCircle = faInfoCircle;
  faKey = faKey;
  faSquare = faSquare;
  faCheckSquare = faCheckSquare;
  public displayDialog: boolean = false;
  public items: CiselnikInfoDto[] = [];
  public cols: any[];

  constructor() { }

  @Input() set sloupce(values: CiselnikSeznamSloupecDto[]) {
    this.items = values?.map(x => {
      return { nazev: x.nazev, popis: x.popis, canEdit: x.canEdit, isPk: x.isPk };
    });
  }

  ngOnInit(): void {
    this.cols = [
      { field: 'nazev', header: 'Název v DB', width:"20%" },
      { field: 'canEdit', header: 'Lze upravovat', width: "15%" },
      { field: 'isPk', header: 'Primární klíč', width: "15%" },
      { field: 'popis', header: 'Popis', width: "50%" }
    ];
  }
}
