<div class="app-body">
  <div class="p-component pds-header" *ngIf="displayNav">
    <div class="p-grid p-align-center">
      <div class="p-col">
        <h1 class="logo">
          <img width="50" alt="Pds" src="assets/logo.png" class="logo-img" mouseup>
        </h1>
        <p class="app-version">Verze: {{apiVersion}}</p>
      </div>
      <div class="p-col right">
        <app-user-info></app-user-info>
      </div>
    </div>
  </div>

  <div class="app-main">
    <router-outlet></router-outlet>
  </div>
</div>
