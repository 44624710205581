import { HttpClient, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { Coordinate } from 'ol/coordinate';
import { MapItemDto } from '../../Dto/Mapa/MapItemDto';
import { LayerDefinitionDto } from '../../Dto/Mapa/LayerDefinitionDto';

@Injectable({
  providedIn: "root"
})
export class MapServices
{
  private apiMapSources: string = 'api/lhpo/Map/GetMapSources';
  private apiWhatIsHere: string = 'api/lhpo/Map/WhatIsHere';
  private apiGetMapItem: string = 'api/lhpo/Map/GetMapItem';
  private apiGetSnapGeometries: string = 'api/lhpo/Map/GetSnapGeometries';

  private messageSource = new BehaviorSubject('layer visibiltiy change');
  currentChangeVisiblityMessage = this.messageSource.asObservable();

  /**
   * Cache podkladů pro vytvoření mapových vrstev.
  **/
  private _layerSources: LayerDefinitionDto[] = null;

  /**
   * Probíhající/nezpracovaný request získání podkladů mapových vrstev.
  **/
  private _layerSourcesRequest: EventEmitter<LayerDefinitionDto[]> = null;

  constructor
  (
    private http: HttpClient
  ) 
  { }


  /**
   * Vrátí podklady pro vytvoření mapových vrstev.
  **/
  public getLayerSources(): Observable<LayerDefinitionDto[]> {
    return this.http.get<LayerDefinitionDto[]>(this.apiMapSources);
  }


  /**
   * Vrátí podklady pro vytvoření mapových vrstev a uloží je do cache. Pokud jsou zdoje již v cache, vrátí je z cache/nevolá web api.
  **/
  public getCachedLayerSource(): Observable<LayerDefinitionDto[]> {
    if (this._layerSources != void 0) {
      return of(this._layerSources);
    }
    else if (this._layerSourcesRequest == void 0) {
      this._layerSourcesRequest = new EventEmitter<LayerDefinitionDto[]>();
      this.http.get<LayerDefinitionDto[]>(this.apiMapSources).subscribe(resp => {
        this._layerSources = resp;
        this._layerSourcesRequest.emit(this._layerSources);
      });
    }
    return this._layerSourcesRequest;
  }


  /**
   * Pošle request, kterým zjistí, co za objekty se nachází na uvedených souřadnicích.
   * @param coords {Coordinate} souřadnice.
   */
  public whatIsHere(coords: Coordinate, zoom: number): Observable<MapItemDto[]> {
    let param = new HttpParams();
    param = param.append('x', coords[0].toString());
    param = param.append('y', coords[1].toString());
    param = param.append('zoom', zoom.toString());
    let visibleLayers = [];
    if (localStorage.getItem('visibleLayersUnProcessed') != void 0)
      visibleLayers = localStorage.getItem('visibleLayersUnProcessed').split(',');
    return this.http.post<MapItemDto[]>(this.apiWhatIsHere, visibleLayers, { params: param });
  }


  /**
   * Vrátí mapový objekt dle id a typu.
   * @param id {string} PK (guid) objektu pro zobrazení v mapě
   * @param typ {string} standardizované označení zdroje ze kterého daný objekt pochází
   */
  public getMapItemById(id: string, typ: string): Observable<MapItemDto> {
    let param = new HttpParams();
    param = param.append('id', id);
    param = param.append('typ', typ);
    return this.http.get<MapItemDto>(this.apiGetMapItem, { params: param });
  }


  /**
   * Vrátí mapový objekt dle JRPL
   * @param lhc {number} kód LHC
   * @param odd {number} kód oddělení
   * @param dil {string} označení dílce
   * @param por {string} označení porostu
   * @param jprl {string} označení jrpl
   * @param typ {string} typ jprl
   */
  public getMapItemByJprl(lhc: number, odd: number, dil: string, por: string, jprl: string, typ: string): Observable<MapItemDto> {
    let params = [lhc, odd, dil, por, typ, encodeURIComponent(jprl)];
    return this.http.get<MapItemDto>(this.apiGetMapItem + '/' + params.join('/'));
  }


  /**
   * Vrátí geometrie JRPL pro přichytávání.
   * @param minX {number} Minimální souřadnice v ose X.
   * @param minY {number} Minimální souřadnice v ose Y.
   * @param maxX {number} Maximální souřadnice v ose X.
   * @param maxY {number} Maximální souřadnice v ose Y.
   * @param lhcGuids {string[]} Seznam guidů LHC.
   */
  public getSnapGeometries(minX: number, minY: number, maxX: number, maxY: number, lhcGuids: string[]): Observable<string[]> {
    let param = new HttpParams();
    param = param.append('minX', minX.toString());
    param = param.append('minY', minY.toString());
    param = param.append('maxX', maxX.toString());
    param = param.append('maxY', maxY.toString());
    return this.http.post<string[]>(this.apiGetSnapGeometries, lhcGuids, {params: param } );
  }
}

