import { Injectable, Output, EventEmitter } from "@angular/core";
import { Feature } from "ol";

/**
 * Service pro obsluhu overlay popisků ploch
**/
@Injectable({
  providedIn: 'root'
})
export class AreaOverlayInteractionService
{
  /**
   * Událost pro přidání popisků do mapy.
   * Předává seznam mapových objektů, kterým mají být popisky přidány.
  **/
  @Output() Add: EventEmitter<Feature<any>[]> = new EventEmitter<Feature<any>[]>();


  /**
   * Událost pro aktualizaci popisků v mapě.
   * Předává seznam mapových objektů, kterým mají být popisky aktualizovány.
  **/
  @Output() Update: EventEmitter<Feature<any>> = new EventEmitter<Feature<any>>();


  /**
   * Událost pro odebrání popisky mapového objektu z mapy.
   * Předává mapový objekt, kterému se má popisek odebrat.
  **/
  @Output() Remove: EventEmitter<Feature<any>> = new EventEmitter<Feature<any>>();


  /**
   * Vypuštění události pro přidání popisků.
   * @param features {Feature<any>[]} seznam mapových objektů, kterým se mají popisky přidat
   */
  public add(features: Feature<any>[]): void {
    this.Add.emit(features);
  }


  /**
   * Vypuštění události pro aktualizaci popisků.
   * @param features {Feature<any>[]} seznam mapových objektů, kterým se mají popisky aktualizovat
   */
  public update(features: Feature<any>): void {
    this.Update.emit(features);
  }


  /**
   * Vypuštění události pro odebrání popisků.
   * @param feature {Feature<any>[]} mapový objekt, kterému se ma popisek odebrat
   */
  public remove(feature: Feature<any>): void {
    this.Remove.emit(feature);
  }
}
