import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import { Feature } from 'ol';
import { MultiPolygon } from 'ol/geom';
import WKB from 'ol/format/WKB';
import { MessageService } from 'primeng/api';
import { MapItemDto } from '../../../Dto/Mapa/MapItemDto';
import { FeatureDataDto } from '../../../Dto/Nacrty/FeatureDataDto';
import { NacrtMapItemDto, NacrtMapItemPostSaveDto } from '../../../Dto/Nacrty/NacrtMapItemDto';
import { MapInteractionService } from '../../../Services/Mapa/map-interaction.service';
import { MapServices } from '../../../Services/Mapa/map.service';
import { NacrtySchvalitInteractionService } from '../../../Services/Nacrty/nacrty-schvalit-interaction.service';
import { NacrtyService } from '../../../Services/Nacrty/nacrty.service';
import { SyncService } from '../../../Services/Sync/sync.service';
import { ByteArrayUtils } from '../../../Utils/Mapa/byte-array.utils';
import { ColorPalleteUtils } from '../../../Utils/Mapa/color-pallete.utils';
import { ConstructMapLayerUtils } from '../../../Utils/Mapa/construnct-map-layer.utils';
import { MessagesUtils } from '../../../Utils/Shared/messages.utils';
import { UuidUtils } from '../../../Utils/Shared/uuid.utils';
import { OverViewMapComponent } from '../../Mapa/overview-map/overview-map.component';
import { LayersInteractionService } from '../../../Services/Mapa/layers-interaction.service';

/**
 * Komponenta dělení náčrtu hranicemi JPRL.
**/
@Component({
  selector: 'app-nacrt-split',
  templateUrl: './nacrt-split.component.html',
  styleUrls: ['./nacrt-split.component.css'],
  providers: [MapInteractionService, NacrtySchvalitInteractionService, LayersInteractionService] //https://angular.io/guide/providers#limiting-provider-scope-with-components
})
export class NacrtSplitComponent
{
  /**
   * Data náčrtu.
  **/
  _item: NacrtMapItemDto = null;
  @Input() set item(value: NacrtMapItemDto) {
    if (value != void 0) {
      this._item = value;
    }
  }

  /**
   * Událost předávající data nově vytvořeného náčrtu po dělení hranicemi JPRL.
  **/
  @Output() SplittedNacrt: EventEmitter<NacrtMapItemPostSaveDto> = new EventEmitter<NacrtMapItemPostSaveDto>();


  /**
   * Komponenta obsahující přehledovou mapu s rozděleným náčrtem.
  **/
  @ViewChild('splitMapOverview') splitMap: OverViewMapComponent;


  constructor(
    private nacrtyService: NacrtyService,
    private syncService: SyncService,
    private mapService: MapServices,
    private messageUtils: MessagesUtils,
    private messageService: MessageService,
    private uuidUtils: UuidUtils,
    private colorPalleteUtils: ColorPalleteUtils,
    private byteArrayUtils: ByteArrayUtils,
    private constructMapLayerUtils: ConstructMapLayerUtils)
  { }

  readonly TOAST_KEY: string = 'splitToast';
  //viditelnost dialogu
  showDlg: boolean = false;
  //blokování UI
  blocked: boolean = false;
  faQuestion = faQuestion;
  //seznam nově vzniklých návrhů náčrtů
  splittedData: FeatureDataDto[] = [];
  //seznam vybraných návrhů náčrtů k vytvoření
  selectedData: FeatureDataDto[] = [];
  //data featur vzniklých rozdělením zdrojového náčrtu
  features: Feature<any>[] = [];

  /**
   * Zobrazení dialogu rozdělení náčrtu dle JPRL.
  **/
  public show(): void {
    this.showDlg = true;
  }

  /**
   * Handler zobrazení dialogu. Mapa se musí vytvořit až když je html element, do kterého se mapa generuje, vykreslen - tedy po zobrazení dialogu.
  **/
  onShowHandler(): void {
    this.splitMap.init();
    this._split(this._item);
  }


  /**
   * Handler skytí dialogu.
  **/
  onHideHandler(): void {
    this.splittedData = [];
    this.features = [];
    this.splitMap.clearLayer();
  }


  /**
   * Handler změny velikosti seznamu vytvořených náčrtů (udává výšku dialgou, pokud je větší než přednastavená výška).
   * Je to použito místo původní "onResizeEnd" dialogu, protože dialog neumí reagovat na změnu velikosti z důvodu změny velikosti jeho obsahu,
   * ale pouze na změnu velikosti vyvolanou uživatelem (potažení za roh okna dialogu/maximalizace).
   */
  featureListResized(): void {
    this.splitMap.resize();
  }


  /**
   * Handler změny vybraných náčrtů po rozdělění původního multipolygonu.
   * @param event {FeatureDataDto[]} vybraná data
   */
  onSelectionChanged(event: FeatureDataDto[]): void {
    this.selectedData = event;
  }


  /**
   * True, pokud jsou data validní a lze uložit nově vzniklé náčrty.
  **/
  get canSave(): boolean {
    return this.selectedData.length > 0 && this.selectedData.filter(x => x.popis == '').length == 0;
  }


  /**
   * Uložení vybraných náčrtů.
  **/
  save(): void {
    if (this.selectedData.length > 0) {
      if (this.selectedData.filter(x => x.popis == '').length > 0) {
        this.messageService.add({ summary: 'Chyba', severity: 'error', detail: 'U některého náčrtů k uložení není vyplněný povinný popis.', key: this.TOAST_KEY });
        return;
      }

      this.blocked = true;
      let format = new WKB({ hex: false, ewkb: false });
      let syncData: any[] = [];
      this.selectedData.forEach(x => {
        let wkb = format.writeGeometry(x.feature.getGeometry());

        syncData.push({
          nacrt: {
            guid: x.id,
            akce: [],
            nacrtProjektGuid: this._item.projektGuid,
            popis: x.popis,
            poznamka: ''
          },
          nacrtGeometry: {
            guid: this.uuidUtils.GenerateUuid(),
            wkb: this.byteArrayUtils.ToBase64(wkb as ArrayBuffer)
          },
          type: 'DNacrt',
          sync: {
            i: x.id,
            u: 0,
            h: [],
            d: false
          }
        });
      });

      this.syncService.post(syncData).subscribe(resp => {
        if (resp.processedSuccessfully.length > 0) {
          this._savePostProcess(resp.processedSuccessfully);
        }
        else {
          if (resp.isError) {
            let messages = resp.errors.map(err => { return { severity: 'error', summary: 'Chyba', detail: err.text, key: this.TOAST_KEY }; });
            this.messageService.addAll(messages);
          }
          else if (resp.isConflict) {
            this.messageService.add({
              key: this.TOAST_KEY, summary: 'Konflikt', severity: 'error',
              detail: 'Při ukládání náčrtu došlo ke konfliktu. Prosím obnovte stránku a proveďte změny znovu.'
            });
          }
        }
      });
    }
    else {
      this.showDlg = false;
    }
  }


  /**
   * Post process uložení náčrtů vzniklých rozdělením náčrtu dle hranic JPRL.
   * @param guids {string} Id úspěšně uložených polygonů
   */
  private _savePostProcess(guids: string[]): void {
    let c = 0;
    guids.forEach(guid => {
      this.mapService.getMapItemById(guid, 'Nacrty').subscribe(mapResp => {
        c++;
        mapResp['targetLayerName'] = 'nacrty_' + this._item.projektGuid;
        this.SplittedNacrt.emit(mapResp as NacrtMapItemPostSaveDto);
        if (c == guids.length) {
          this.blocked = false;
          this.showDlg = false;
        }
      });
    })
  }


  /**
   * Rozdělení náčrtu.
   * @param item {NacrtMapItemDto} data náčrtu k rozdělení
   */
  _split(item: NacrtMapItemDto): void {
    this.blocked = true;
    this.nacrtyService.splitByJprl(item.id).subscribe(resp => {
      if (resp.success) {
        this._splitPostProcess(item, resp.data);
      }
      else {
        this.blocked = false;
        this.messageUtils.showResponseMessage(this.TOAST_KEY, resp);
      }
    });
  }


  /**
   * Postprocesní zpracování dělení náčrtu.
   * @param originalItem {NacrtMapItemDto} data zdrojového náčrtu
   * @param data {string[]} WKT nových náčrtů
   */
  private _splitPostProcess(originalItem: NacrtMapItemDto, data: string[]) {
    var splitted: FeatureDataDto[] = [];
    
    for (var i = 0; i < data.length; i++)
    {
      let id = this.uuidUtils.GenerateUuid();
      let f: Feature<any> = this.constructMapLayerUtils.convertFromSource([{ id: id, wkt: data[i], popis: i.toString(), modul: '' } as MapItemDto])[0];

      let color = this.colorPalleteUtils.explodeColor(i);
      f.set('barva', color);

      splitted.push({
        id: id,
        feature: f,
        poradoveCislo: i,
        plocha: f.getGeometry() instanceof MultiPolygon ? (f as Feature<MultiPolygon>).getGeometry().getArea() : 0, //Dělit lze i linie, kde výpočet plochy nelze provést
        popis: originalItem.popis,
        color: color
      });
    }

    this.splittedData = splitted;
    this.features = this.splittedData.map(x => x.feature);
    this.blocked = false;
  }
}
