import { RegisterCredentialsDto } from './RegisterCredentialsDto';

/**
 * Dto s podrobnějšími informacemi o uživateli
*/
export class UserDetailDto extends RegisterCredentialsDto {
  orgUrGuid: string;
  orgUrGuids: string[];
  role: string[];
}
