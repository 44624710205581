import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EqContext, i18n } from '@easyquery/core';
import { AdvancedSearchView, EqViewOptions } from '@easyquery/ui';
import { EqLocaleInfo } from '../../../Utils/Shared/eq-locale-info.utils';
import '@easyquery/enterprise';

@Component({
  selector: 'app-easy-query',
  templateUrl: './easy-query.component.html',
  styleUrls: ['./easy-query.component.css']
})
export class EasyQueryComponent {

  constructor() { }

  public view: AdvancedSearchView;
  public context: EqContext;

  /**
   * Vybraný datový moel
   */
  private _model: string;

  /**
   * Data k načtení pro editaci. Po načtení se zahodí, aby nedošlo k opakovanému načtení. 
   */
  private _dataToLoad: string;

  /**
   * True, pokud EQ komponenta byla zinicializována
   */
  private _ready: boolean = false;

  @Input() set modelId(value: string) {
    this._model = value;
    if (this._model != void 0) {
      this.loadEasyQuery();
    }
  }

  /**
   * Data pro editaci
   */
  @Input() set data(value: string) {
    this._dataToLoad = value;
    this._loadData();
  }

  /**
  * Událost o změně
  */
  @Output() onChange: EventEmitter<string> = new EventEmitter<string>();


  //inicializace easy query
  public loadEasyQuery(): void {
    i18n.updateLocaleInfo('cs', EqLocaleInfo.Czech);
    i18n.setCurrentLocale('cs');

    const options: EqViewOptions = {
      handlers: {
        onError: (context, status) => {
          console.error(status.action + " error:\n" + status.text);
        }
      },
      widgets: {
        entitiesPanel: {
        },
        columnsPanel: {
        },
        queryPanel: {
          allowParameterization: true,
          showPoweredBy: true,
          buttons: { condition: ["delete", "enable", "menu"] }
        },
      },
      result: {
        showChart: false
      }
    };

    if (this._model != void 0) {
      options.loadModelOnStart = true;
      options.defaultModelId = this._model;
    }

    this.view = new AdvancedSearchView();
    this.context = this.view.getContext();
    this.context.useEndpoint("/api/easyquery");
    this.context.useEnterprise(() => this.view.init(options));

    (window as any).EQView = this.view;


    this.context.addEventListener("syncQuery", this._syncQueryHanlder.bind(this));
    this.context.addEventListener("ready", this._readyHanlder.bind(this));
  }

  /**
   * Nařte data z JSON provde synchronizaci se serverem a udělá aktualizaci na výstupu
   */
  private _loadQueryFromJson(): void {
    const query = this.context.getQuery();
    query.loadFromDataOrJson(JSON.parse(this._dataToLoad));
    this._dataToLoad = null;
    this.view.getContext().refreshWidgets();
  }

  private _syncQueryHanlder(context: EqContext) {
      this.onChange.emit(context.getQuery().toJSON());
  }

  private _readyHanlder(context: EqContext) {
    this._ready = true;
    this._loadData();
  }

  /**
   * Pokud data ješte nebyla načtena a komponenta je inicializována, tak načíst
   **/
  private _loadData() {
    if (this._dataToLoad != void 0 && this._ready) {
      this._loadQueryFromJson();
    }
  }

}
