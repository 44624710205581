<p-dialog [(visible)]="showDlg" styleClass="explode-dlg"
          [modal]="true" [appendTo]="'body'" [style]="{width: '75vw','min-height': '472px'}"
          (onShow)="onShowHandler()" (onHide)="onHideHandler()" (onResizeEnd)="dlgResizeEndHandler()">
  <p-toast position="top-center" key="{{TOAST_KEY}}"></p-toast>
  <ng-template pTemplate="header">
    <span class="p-dialog-title">Rozdělení multipolygonu</span>
    <button type="button" pButton style="margin-left: auto;" class="p-mr-2 p-button-icon-only"
            pTooltip="Nápověda" tooltipPosition="bottom"
            (click)="opHelp.toggle($event)">
      <fa-icon [icon]="faQuestion" style="line-height:1;"></fa-icon>
    </button>
    <button type="button" pButton icon="pi pi-check" class="p-mr-2"
            pTooltip="Uložit" tooltipPosition="bottom"
            (click)="save()" [disabled]="!canSave"></button>
  </ng-template>
  <div class="p-grid" style="flex-grow: 1;">
    <div class="p-col-7">
      <app-feautres-list [data]="explodedData"
                         (selectionChanged)="onSelectionChanged($event)"></app-feautres-list>
    </div>
    <div class="p-col-5 multipolygon-explode-nahled">
      <app-overview-map #explodeMapOverview [features]="features"></app-overview-map>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <button type="button" pButton icon="pi pi-check" label="Použít" (click)="save()" [disabled]="!canSave"
            pTooltip="Uložit" tooltipPosition="bottom"></button>
    <button type="button" pButton icon="pi pi-times" label="Zavřít" (click)="showDlg = false" class="p-button-secondary"></button>
  </ng-template>
</p-dialog>

<p-blockUI [blocked]="blocked">
  <p-progressSpinner></p-progressSpinner>
</p-blockUI>

<p-overlayPanel #opHelp>
  <ng-template pTemplate>
    <h3 style="margin-top: 0;">Nápověda</h3>
    <div>Zaškrtněte pole u řádků, ze kterých chcete vytvořit nové náčrty.</div>
    <div>Původní multipolygon zůstane nezměněn.</div>
  </ng-template>
</p-overlayPanel>
