import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faCopy, faPencilAlt, faPlus, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { MenuItem } from 'primeng/api';
import { RoleEnum } from '../../../Dto/Core/RoleEnum';
import { DotazDetailDto } from '../../../Dto/Dotazovac/DotazDetailDto';
import { DotazovacService } from '../../../Services/Dotazovac/dotazovac.service';
import { MessagesUtils } from '../../../Utils/Shared/messages.utils';
import { RoleUtils } from '../../../Utils/Shared/role.utils';
import { EasyQueryComponent } from '../../Shared/easy-query/easy-query.component';

@Component({
  selector: 'app-new-dotaz',
  templateUrl: './new-dotaz.component.html',
  styleUrls: ['./new-dotaz.component.css']
})
export class NewDotazComponent implements OnInit {

  constructor(protected formBuilder: FormBuilder,
    protected dotazovacService: DotazovacService,
    protected messagesUtils: MessagesUtils,
    protected roleUtils: RoleUtils  ) { }

  /**
   * Zobrazení skrytí dialogu
   */
  public display: boolean = false;


  public displaySql: boolean = false;

  /**
   * Seznam modelů pro menu výběru modelů
   */
  public modelsMenu: MenuItem[];

  /**
   * Text v hlavičce dialogu pro editaci/nový záznam
   */
  public popisDialogu: string;

  /**
   * True pokud došlo ke změně
   */
  public changed: boolean = false;

  /**
   * Query id editovaného dotazu
   */
  public queryId: string;

  /**
   * ID vybraného datového modelu
   */
  public eqModel: string;

  /**
   * Data komponenty EasyQuery (JSON string)
   */
  public data: string;

  /**
   * Vybraný dotaz pro možnost editace.
   */
  public dotaz: DotazDetailDto;

  faPlus = faPlus;
  faTimes = faTimes;
  faSave = faSave;
  faPencilAlt = faPencilAlt;
  faCopy = faCopy;

  public readonly NEW_DOTAZ_TOAST = "new_dotaz_toast";

  @ViewChild("eq", { static: true }) easyQuery: EasyQueryComponent;

  /**
   * Událost o vložení dotazu
   */
  @Output() onSave: EventEmitter<DotazDetailDto> = new EventEmitter<DotazDetailDto>();

  /**
   * True/False pro editaci/nový záznam dotazu
   */
  @Input() edit: boolean = false;

  /**
   * True/False pro kopírování existujícího dotazu
  */
  @Input() copy: boolean = false;

  /**
   * Guid vybraného dotazu
   */
  @Input() guid: string;


  ngOnInit(): void {
    this._loadModely();
    this.form.valueChanges.subscribe(e => {
      this.changed = true;
    });
  }

  /**
   * Uchovává se pro zavedení až s otevřením dialogu
   */
  private _eqModel: string;

  form: FormGroup = this.formBuilder.group({
    nazev: ['', [Validators.required, Validators.maxLength(100)]],
    kategorie: ['', [Validators.required, Validators.maxLength(500)]],
    addDistinct: [false]
  });

  /**
   * Promaže formulář i EasyQuery komponentu a zavře dialog
   **/
  public close(): void {
    this.display = false;
    this.form.reset();
    this.form.get('addDistinct').setValue(false);
    this.form.markAsPristine();
    this.data = null;
    if (this.easyQuery.context != void 0) this.easyQuery.context.clearQuery();
    this.changed = false;
  }

 /**
  * Přečte data z formuláře i z easyquery a uloží
  * @param value - dto pro uložení
  */
  public save(value: DotazDetailDto = null): void {
    let dto = value != void 0 ? value : this._formToDto();
    if (this.copy) dto.id = null;
    this.dotazovacService.saveDotaz(dto).subscribe(res => {
      this.messagesUtils.showResponseMessage(this.NEW_DOTAZ_TOAST, res);
      if (res.success) this.onSave.emit(res.data);
      this.close();
    });
  }

  /**
   * Formulář i data převede na dto detailu
   **/
  private _formToDto(): DotazDetailDto {
    let result: DotazDetailDto = new DotazDetailDto();
    if (this.dotaz != void 0) result = this.dotaz;

    Object.keys(this.form.value).forEach(key => {
      result[key] = this.form.value[key];
    });

    result.data = this.data;
    result.modelId = this.eqModel;

    if (!this.edit) result.zamceno = true;

    return result;
  }

  /**
   * Hanlder změny easy query
   * @param event
   */
  public onChangeQuery(event): void {
    if (this.data != event) this.changed = true;
    if (this.display) this.data = event;
  }

  /**
   * Handler výběru modelu
   * @param value
   */
  public onModelSelect(value): void {
      this._eqModel = value?.item?.id;
      this.display = true;
  }

  /**
   * Hadler výběru editace SQL dotazu
   **/
  public onSqlSelect(): void {
    this.displaySql = true;
  }

  /**
   * Handler otevření dialogu. Při nastavení modelu musíme počkat na tuto událost, aby došlo ke správnému sestavení easyquery.
   **/
  public onShowDialog(): void {
    this.eqModel = this._eqModel;
    this.data = this.dotaz?.data;

    if (this.copy) this.popisDialogu = "Kopírování dotazu v rámci modelu";
    else this.popisDialogu = this.edit ? "Editace dotazu pro model" : "Nový dotaz pro datový model";
  }

  /**
   * Načte modely do menu
   **/
  private _loadModely(): void {
    this.dotazovacService.listModels().subscribe(res => {
      if (res.success) {
        this.modelsMenu = res.data?.map(item => {
            return { label: item.nazev, id: item.modelId, command: this.onModelSelect.bind(this) };
          });
        if (this.roleUtils.checkRole([RoleEnum.SuperAdmin])) {
          this.modelsMenu.push({ separator: true })
          this.modelsMenu.push({ label: "SQL dotaz", command: this.onSqlSelect.bind(this) })
        }
      }
      else {
        this.messagesUtils.showResponseMessage(this.NEW_DOTAZ_TOAST, res);
      }
    });
  }

  /**
   * Handler kliknutí na tlačítko kopírování 
  */
  public onCopyClick(): void {
    this._createDotazDialog();
  }

  /*
   * Handler kliknutí na tlačítko editace
   */
  public onEditClick(): void {
    this._createDotazDialog();
  }

  /**
   * Zobrazí dialog pro editaci/kopírování dotazu 
  */
  private _createDotazDialog() {
    if (this.guid != void 0)
      this.dotazovacService.getDotaz(this.guid).subscribe(res => {
        if (res.success) {
          this.dotaz = res.data;
          if (res.data.data == void 0 && res.data.sqlData != void 0) {
            this.displaySql = true;
          } else {
            this._eqModel = res.data?.modelId;
            this.form.patchValue(res.data);
            this.changed = false;
            this.display = true;
          }
        } else {
          this.messagesUtils.showResponseMessage(this.NEW_DOTAZ_TOAST, res);
        }
      });
  }

  /**
   * Hanlder uložení SQL dotazu
   **/
  public onSaveSqlDotaz(value: DotazDetailDto): void {
    this.save(value);
  }
}
