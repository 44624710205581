<p-toast position="top-center" key="vyroba-sklad-config-toast"></p-toast>
<app-block-ui [block]="block"></app-block-ui>

<p-toolbar styleClass="base-toolbar">
  <div class="p-toolbar-group-left">
    <h2 class="toolbar-label">Nastavení skladu</h2>
    <i class="p-toolbar-separator"><fa-icon [icon]="faEllipsisV"></fa-icon></i>
    <button pButton type="button" label="Nová kombinace" class="icon-btn" (click)="add()"><fa-icon [icon]="faPlus"></fa-icon></button>
    <div style="display: inline-block">
      <div class="p-inputgroup">
        <input type="text" pInputText placeholder="Hledat" [(ngModel)]="filter" style="width: 20em" />
        <button pButton type="button" class="icon-btn hide-label-button" (click)="filter = undefined" [disabled]="filter == undefined"><fa-icon [icon]="faBackspace"></fa-icon></button>
      </div>
    </div>
    <button pButton type="button" label="Smazat" class="icon-btn p-button-danger" (click)="remove()" [disabled]="selectedItem == undefined || selectedItem.guid == undefined">
      <fa-icon [icon]="faTrash"></fa-icon>
    </button>
  </div>
  <div class="p-toolbar-group-right">
    <button pButton type="button" label="Zpět" class="icon-btn" (click)="back()"><fa-icon [icon]="faUndo"></fa-icon></button>
  </div>
</p-toolbar>

<propla-table #skladTable [cols]="cols" [allItems]="items" [selectionMode]="'single'" [filterGlobal]="filter"
              (rowSelected)="onRowSelected($event)" (rowUnSelected)="onRowUnSelected($event)" (dataLoaded)="dataTableLoaded()"></propla-table>

<ng-template #colDatum let-rowData let-col="column">
  {{rowData[col.field] | date:'d.M.yyyy'}}
</ng-template>

<ng-template #colLokalita let-rowData>
  <app-ciselnik-kod ciselnik="CLokalita" [guid]="rowData['lokalitaGuid']" [showPopisIn]="true"></app-ciselnik-kod>
</ng-template>

<p-panel *ngIf="showDetail" [style]="{'margin-top': '1em'}">
  <ng-template pTemplate="header">
    <div class="p-grid p-align-center">
      <div class="p-col"><span class="p-panel-title">Nastavení</span></div>
      <div class="p-col right">
        <button pButton type="button" label="Uložit" class="icon-btn p-button-success" (click)="save()" [disabled]="form.invalid"><fa-icon [icon]="faSave"></fa-icon></button>
        <button pButton type="button" label="Uložit kopii" class="icon-btn p-button-success" (click)="saveCopy()" [disabled]="form.invalid"><fa-icon [icon]="faCopy"></fa-icon></button>
        <button pButton type="button" label="Zrušit" class="icon-btn" (click)="cancel()"><fa-icon [icon]="faTimes"></fa-icon></button>
      </div>
    </div>
  </ng-template>
  <div class="validated-form-container" [formGroup]="form">
    <div class="p-grid">
      <div class="p-col-2">
        Výkon
        <app-ciselnik-dropdown #skladVykon ciselnik="CVykon" [nullable]="true" [virtualScroll]="true"
                               [panelStyle]="{'width':'400px'}"
                               formControlName="vykonGuid" class="required"></app-ciselnik-dropdown>
      </div>
      <div class="p-col-4">
        Podvýkon
        <app-podvykon-dropdown [nullable]="true" [vykonGuid]="vykonGuid.value" [virtualScroll]="true"
                               [panelStyle]="{'width':'600px'}"
                               formControlName="podvykonGuid" class="required"></app-podvykon-dropdown>
      </div>
      <div class="p-col-2">
        Lokalita
        <app-ciselnik-dropdown ciselnik="CLokalita" [nullable]="true" [virtualScroll]="true"
                               [panelStyle]="{'width':'400px'}"
                               formControlName="lokalitaGuid"></app-ciselnik-dropdown>
      </div>
      <div class="p-col-2">
        <label for="matice-platnost-od">Platnost od</label>
        <app-calendar formControlName="platnostOd" class="required" id="sklad-platnost-od"></app-calendar>
      </div>
      <div class="p-col-2">
        <label for="matice-platnost-do">Platnost do</label>
        <app-calendar formControlName="platnostDo" id="sklad-platnost-do"></app-calendar>
      </div>
    </div>

    <p-panel>
      <ng-template pTemplate="header">
        <div class="p-grid p-align-center">
          <div class="p-col"><span class="p-panel-title">Seznam položek</span></div>
          <div class="p-col right">
            <button pButton type="button" label="Nic" (click)="nic()"></button>
          </div>
        </div>
      </ng-template>
      <div formArrayName="lokality" class="p-grid validated-form-container">
        <div *ngFor="let lok of form.get('lokality').controls; let i = index;" [formGroup]="lok" class="p-col-3 validated-form-container">
          <p-panel>
            <ng-template pTemplate="header">
              <app-ciselnik-kod ciselnik="CLokalita" [guid]="lok.get('lokalitaGuid').value" [showPopisIn]="true"></app-ciselnik-kod>
            </ng-template>
            <div class="polozka" [formGroup]="lok.get('prijem')">
              <p-checkbox formControlName="enabled" label="příjem" [binary]="true"></p-checkbox>
              <p-dropdown formControlName="druhKod" [options]="druhPohybu" [styleClass]="lok.get('prijem').get('enabled').value ? 'required' : ''"></p-dropdown>
            </div>
            <div class="polozka" [formGroup]="lok.get('vydej')">
              <p-checkbox formControlName="enabled" label="výdej" [binary]="true"></p-checkbox>
              <p-dropdown formControlName="druhKod" [options]="druhPohybu" [styleClass]="lok.get('prijem').get('enabled').value ? 'required' : ''"></p-dropdown>
            </div>
          </p-panel>
        </div>
      </div>
    </p-panel>

  </div>

</p-panel>

<p-confirmDialog #confirmDlg header="Smazat" icon="pi pi-exclamation-triangle">
  <p-footer>
    <button type="button" pButton icon="pi pi-times" label="Ne" (click)="confirmDlg.reject()"></button>
    <button type="button" pButton icon="pi pi-check" label="Ano" (click)="confirmDlg.accept()"></button>
  </p-footer>
</p-confirmDialog>
