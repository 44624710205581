<table>
  <tbody>
    <tr>
      <td>
        <button pButton type="button" label="Tab" class="p-button-raised p-button-info" disabled="disabled"></button>
        <button pButton type="button" label="Enter" class="p-button-raised p-button-info" disabled="disabled"></button>
      </td>
      <td>posun na další okénko</td>
    </tr>
    <tr>
      <td><button pButton type="button" label="Mezerník" class="p-button-raised p-button-info" disabled="disabled"></button></td>
      <td>na žlutém poli vyvolá číselník</td>
    </tr>
    <tr>
      <td>
        <button pButton type="button" label="PgUp" class="p-button-raised p-button-info" disabled="disabled"></button>
        <button pButton type="button" label="PgDn" class="p-button-raised p-button-info" disabled="disabled"></button>
      </td>
      <td>pohyb mezi řádky dat</td>
    </tr>
    <tr>
      <td><button pButton type="button" label="/" class="p-button-raised p-button-info" disabled="disabled"></button></td>
      <td>opakování hodnoty z předchozího řádku</td>
    </tr>
    <tr>
      <td><button pButton type="button" label="*" class="p-button-raised p-button-info" disabled="disabled"></button></td>
      <td>založí nový řádek <span *ngIf="coKopiruje">a zkopíruje</span> {{coKopiruje}}</td>
    </tr>
  </tbody>
</table>
