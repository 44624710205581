import { Component, Input } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import GeometryType from 'ol/geom/GeometryType';
import { AllowedGeometries } from '../../../Dto/Mapa/AllowedGeometries';
import { NacrtProjektSourceDto } from '../../../Dto/Nacrty/NacrtProjectSourceDto';

/**
 * Komponenta editačního formuláře metadat náčrtu.
**/
@Component({
  selector: 'app-nacrt-edit-form',
  templateUrl: './nacrt-edit-form.component.html',
  styleUrls: ['./nacrt-edit-form.component.css']
})
export class NacrtEditFormComponent {

  _nacrtProjects: NacrtProjektSourceDto[] = [];

  /**
    * Seznam dostupných projektů náčrtu pro editaci.
   **/
  @Input() set nacrtProjects(value: NacrtProjektSourceDto[]) {
    if (value != void 0) {
      this._nacrtProjects = value;
    }
  }


  /**
   * Data náčrtu (sync data, data zdrojového náčrtu při kopii).
  **/
  @Input() set inputNacrtData(data: any) {
    if (data != void 0) {
      this.nacrtProjektGuid?.setValue(data.nacrtProjektGuid);
      this.popis?.setValue(data.popis);
      this.poznamka?.setValue(data.poznamka);
      this.cisloHoliny?.setValue(data.cislo);
    }
  }


  /**
   * Typ geometrie editovaného/kresleného náčrtu.
  **/
  @Input() set featureType(value: string) {
    if (value != void 0) {
      this._nacrtProjects = this._processProjects(this._nacrtProjects, value);
    }
  }


  /**
   * Přepínač zobrazení přepínače mezi viditelnými/všemi projekty.
   * True, pokud má být přepínač zobrazen (default).
  **/
  @Input() allowSwitchVisibleProjects: boolean = true;


  constructor(
    private formBuilder: FormBuilder)
  { }


  _form: FormGroup = this.formBuilder.group({
    nacrtProjektGuid: [null, Validators.required ],
    popis: ['', Validators.required],
    poznamka: ['']
  });

  get formValue(): any {
    return this._form.value;
  }

  get isValid(): boolean {
    return this._form.valid;
  }

  get isDirty(): boolean {
    return this._form.dirty;
  }

  get nacrtProjektGuid(): AbstractControl {
    return this._form.get('nacrtProjektGuid');
  }

  get popis(): AbstractControl {
    return this._form.get('popis');
  }

  get poznamka(): AbstractControl {
    return this._form.get('poznamka');
  }

  get cisloHoliny(): AbstractControl {
    return this._form.get('cislo');
  }


  /**
   * Reset formuláře.
  **/
  reset(): void {
    this._form.reset();
  }


  /**
   * Zpracování projektů náčrtů do uživatelské nabídky, omezené typem geometrie, který lze do daného projektu vytvářet.
   * @param projects {NacrtProjektSourceDto[]} data projektů pro sestavení nabídky
   * @param featureGeometry {string} typ kresleného náčrtu
   */
  private _processProjects(projects: NacrtProjektSourceDto[], featureGeometry: string) {
    return projects.filter(proj => this._validateProjectsByGeometry(proj.allowedGeometries, featureGeometry));
  }


  /**
  * Kontrola projektu z hlediska povolených typů geometrie.
  * @param allowedGeometries {AllowedGeometries} povolené typy geometrií v projektu
  * @param featureGeometry {string} typ kresleného náčrtu
  */
  private _validateProjectsByGeometry(allowedGeometries: AllowedGeometries, featureGeometry: string): boolean {
    return featureGeometry == GeometryType.POINT && allowedGeometries.bod
      || featureGeometry == GeometryType.LINE_STRING && allowedGeometries.linie
      || featureGeometry == GeometryType.MULTI_LINE_STRING && allowedGeometries.linie //funkce "split by JRPL" umí rozdělit i linie - mohou tak vzniknout i multilinie
      || featureGeometry == GeometryType.POLYGON && allowedGeometries.plocha
      || featureGeometry == GeometryType.MULTI_POLYGON && allowedGeometries.plocha;
  }


  /**
   * Označení formuláře jako "nezměněného". Komponenta formuláře žije na stránce seznamu náčrtů a její instance je tedy recyklována pro editace všech náčrtů.
  **/
  public markAsNotDirty(): void {
    this._form.markAsPristine();
  }
}
