import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { faBackspace, faEllipsisV, faPlus, faSave, faTimes, faUndo } from '@fortawesome/free-solid-svg-icons';
import { MessageService } from 'primeng/api';
import { RoleDto } from '../../../Dto/Auth/RoleDto';
import { UserDetailDto } from '../../../Dto/Auth/UserDetailDto';
import { UserListDto } from '../../../Dto/Auth/UserListDto';
import { RoleEnum } from '../../../Dto/Core/RoleEnum';
import { ResultInfoGenericDto } from '../../../Dto/Shared/ResultInfoDto';
import { ZakaznikDetailDto } from '../../../Dto/Zakaznik/ZakaznikDetailDto';
import { CiselnikyService } from '../../../Services/Shared/ciselniky.service';
import { UzivatelService } from '../../../Services/Zakaznik/uzivatel.service';
import { RoleUtils } from '../../../Utils/Shared/role.utils';

/**
* Komponenta pro práci se seznamem uživatelů
*/
@Component({
  selector: 'app-list-user',
  templateUrl: './list-user.component.html',
  styleUrls: ['./list-user.component.css']
})
export class ListUserComponent implements OnInit {

  constructor
  (
    private ciselnikyService: CiselnikyService,
    private messageService: MessageService,
    private roleUtils: RoleUtils,
    private userService: UzivatelService,
    private formBuilder: FormBuilder,
    private router: Router
  )
  {
  }

  @ViewChild("orgUr", { static: true }) orgUrTemplate: TemplateRef<any>;

  block: boolean = false;
  roles: RoleDto[];
  showForm: boolean = false;
  accessMode: string = 'admin';

  selectedZakaznik: ZakaznikDetailDto;

  selectedUzivatel: UserListDto;
  uzivatelItems: UserListDto[] = [];

  //aktuálně vybrané řádky
  selectedRows: any = [];

  get data(): UserDetailDto {
    return this.form?.value;
  }

  set data(val: UserDetailDto) {
    this.form.reset();
    this.form.patchValue(val);
  }

  uzivatelCols: any[] = [];
  totalRecords: number;

  //formulář
  form = this._setForm(true);
  faEllipsisV = faEllipsisV;
  faBackspace = faBackspace;
  faPlus = faPlus;
  faSave = faSave;
  faUndo = faUndo;
  faTimes = faTimes;
  filter: string;

  private MESSAGE_KEY = 'user-list-toast';
  private _superAdmin: boolean = false;

  ngOnInit(): void {

    this._init();

    this.uzivatelCols = [
      { field: 'userName', header: 'Login', width: '10%' },
      { field: 'lastName', header: 'Přijmení', width: '15%' },
      { field: 'firstName', header: 'Jméno', width: '15%' },
      { field: 'email', header: 'E-Mail', width: '21%' },
      { field: 'phoneNumber', header: 'Telefon', width: '14%' },
      { field: 'orgUrGuid', header: 'Organizační úroveň', width: '25%', template: this.orgUrTemplate },
    ];
  }

  /**
   * Navigace na úvodní stránku
  */
  public back(): void {
    this.router.navigate(["/"]);
  }

  /**
   * Incializace komponenty 
  */
  private _init() {
    this.block = true;
    this._superAdmin = this.roleUtils.checkRole([RoleEnum.SuperAdmin]);
    this._fillRolesDropdown();
  }

  /**
   * Načte dropdown rolí
  */
  private _fillRolesDropdown() {
    this.ciselnikyService.getRoles().subscribe(res => {
      if (res != void 0 && res.length > 0) {
        this.roles = res.sort((a, b) => a.popis.toLowerCase() > b.popis.toLowerCase() ? 1 : -1);
        if (!this._superAdmin) {
          this.accessMode = 'base';
          this.roles = this.roles.filter(x => x.popis != RoleEnum.SuperAdmin);
        }
      }
      else {
        this.messageService.add({ severity: 'error', summary: 'Chyba', key: this.MESSAGE_KEY, detail: 'Došlo k neočekávané chybě.' });
      }
      this.block = false;
    });
  }

  /**
   * Naplní tabulku uživatelů podle zákazníka
  */
  private _loadUzivatelTable(zakaznikGuid: string) {
    if (zakaznikGuid != void 0 && zakaznikGuid != '') {
      this.userService.list(zakaznikGuid).subscribe(res => {
        if (res != void 0) {
          this.uzivatelItems = [...res];
        }
        else {
          this.messageService.add({ severity: 'error', summary: 'Chyba', key: this.MESSAGE_KEY, detail: 'Došlo k chybě při načítání uživatelů zákazníka.' });
        }
      });
    }
  }

  /**
   * Vytvoří form podle toho, jestli je určený k editaci/vytvoření uživatele
  */
  private _setForm(passwordRequired: boolean): FormGroup {
    var passwordValidators = [Validators.pattern('(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,}')];
    if (passwordRequired) passwordValidators.push(Validators.required);

    return this.formBuilder.group({
      guid: [''],
      userName: ['', [Validators.required, Validators.pattern('[A-z0-9-@_.]*')]],
      password: ['', passwordValidators],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', [Validators.pattern('[+ 0-9]{0,18}')]],
      orgUrGuid: ['', Validators.required],
      orgUrGuids: [[]],
      role: [[]]
    });
  }

  /**
   * Zpracovává ukládací operace
  */
  private _processSave<T>(successMessage: string, result: ResultInfoGenericDto<T>) {
    if (result.success) {
      this.messageService.add({ severity: 'success', summary: 'OK', key: this.MESSAGE_KEY, detail: successMessage });
      this.data = undefined;
      this.showForm = false;
      this.selectedUzivatel = undefined;
      this.selectedRows = undefined;
      this._loadUzivatelTable(this.selectedZakaznik?.guid);
    }
    else {
      this.messageService.add({ severity: 'error', summary: 'Chyba', key: this.MESSAGE_KEY, detail: result.messages[0] });
    }
    this.block = false;
  }

  /**
 * Zpracuje změnu zákazníka v dropdownu
 * @param zakaznik
 */
  onZakaznikChange(dto: ZakaznikDetailDto): void {
    this._loadUzivatelTable(dto?.guid);
  }

  /**
 * Uloží vybraný řádek do proměnné
 */
  onRowSelected(dto: UserListDto): void {
    this.selectedUzivatel = dto;
    if (this.showForm) this.showForm = false;
    this.form = this._setForm(false);

    this.block = true;
    this.userService.get(dto.guid).subscribe(res => {
      if (res.success) {
        var resData = res.data;
        resData.role = this.roles.filter(x => resData.role.includes(x.popis)) as any[];
        this.data = resData;

        this.showForm = true;
      }
      else {
        this.messageService.add({ severity: 'error', summary: 'Chyba', key: this.MESSAGE_KEY, detail: res.messages[0] });
      }

      this.block = false;
    });

  }

  /**
  * Odstraní vybraný řádek z proměnné
  */
  onRowUnselected(dto: UserListDto): void {
    this.selectedUzivatel = undefined;
    this.showForm = false;
  }

  /**
   * Otevře panel pro přidání nového uživatele
  */
  add() {
    this.data = new UserDetailDto();
    this.form = this._setForm(true);
    this.showForm = true;
  }

  /**
   * Uloží uživatele
  */
  save() {
    this.block = true;

    this.data.role = this.data.role.map(x => (x as any).popis);

    if (this.data?.guid == "") {
      this.userService.create(this.data).subscribe(res => {
        this._processSave<UserDetailDto>('Nový uživatel byl úspěšně vytvořen.', res);
        this.uzivatelItems.push(
          {
            guid: res.data.guid, userName: res.data.userName, firstName: res.data.firstName, lastName: res.data.lastName,
            email: res.data.email, phoneNumber: res.data.phoneNumber, orgUrGuid: res.data.orgUrGuid
          });
        this.uzivatelItems = [...this.uzivatelItems];
      });
    }
    else {
      this.userService.update(this.data).subscribe(res => {
        this._processSave<UserDetailDto>('Uživatel byl úspěšně upraven.', res);
      });
    }
  }

  /**
   * Zruší ukládání/výběr uživatele
  */
  cancel() {
    this.data = undefined;
    this.selectedRows = undefined;
    this.selectedUzivatel = undefined;
    this.showForm = false;
  }
}
