<p-toast position="top-center" key="dochazka-edit-toast"></p-toast>
<app-block-ui [block]="block"></app-block-ui>
<p-toolbar styleClass="base-toolbar">
  <div class="p-toolbar-group-left dochazka-left-box top-toolbar" nextOnEnter>
    <div style="display: inline-block">
      <div style="display: flex">
        <label for="dochazka-rok-textbox">Rok</label>
        <input [(ngModel)]="dochazkaData.rok" id="dochazka-rok-textbox" type="number" pInputText *ngIf="!vml" />
        <div class="p-inputtext ui-widget ui-state-default ui-corner-all ui-div-inputtext" *ngIf="vml" title="{{dochazkaData.rok}}">{{dochazkaData.rok}} </div>
      </div>
    </div>
    <div style="display: inline-block">
      <div style="display: flex">
        <label for="dochazka-mesic-textbox">Měsíc</label>
        <input [(ngModel)]="dochazkaData.mesic" id="dochazka-mesic-textbox" type="number" pInputText *ngIf="!vml" />
        <div class="p-inputtext ui-widget ui-state-default ui-corner-all ui-div-inputtext" *ngIf="vml" title="{{dochazkaData.mesic}}">{{dochazkaData.mesic}} </div>
      </div>
    </div>
  </div>
  <div class="p-toolbar-group-right">
    <div class="right buttons">
      <button pButton type="button" label="Načíst" (click)="loadDochazky()" [ngClass]="'icon-btn'" *ngIf="!vml"><fa-icon [icon]="faSync"></fa-icon></button>
      <button pButton type="button" (click)="save()" label="Uložit" [ngClass]="'icon-btn'"><fa-icon [icon]="faSave"></fa-icon></button>
    </div>
  </div>
</p-toolbar>


<p-table #dochazkaTable [value]="radky" [paginator]="true" [rows]="10" sortMode="multiple"
          selectionMode="single" [(selection)]="selectedRadek"
          (onRowSelect)="onRowSelected($event)" (onRowUnselect)="onRowUnSelected($event)">
  <ng-template pTemplate="header">
    <tr>
      <th rowspan="2" [ngStyle]="{'width': (fromDoklad) ? '4%' : '5%'}" [pSortableColumn]="'cislo'">
        <div class="sortable-header">
          č.
          <p-sortIcon [field]="'cislo'"></p-sortIcon>
        </div>
      </th>
      <th rowspan="2" [ngStyle]="{'width': (fromDoklad) ? '28%' : '25%'}" [pSortableColumn]="'pracovnikGuid'">
        <div class="sortable-header">
          Pracovník
          <p-sortIcon [field]="'pracovnikGuid'"></p-sortIcon>
        </div>
      </th>
      <th rowspan="2" [ngStyle]="{'width': (fromDoklad) ? '28%' : '25%'}" [pSortableColumn]="'druhMzdyGuid'">
        <div class="sortable-header">
          Druh mzdy
          <p-sortIcon [field]="'druhMzdyGuid'"></p-sortIcon>
        </div>
      </th>
      <th rowspan="2" *ngIf="!fromDoklad" [ngStyle]="{'width': '5%'}" [pSortableColumn]="'dokladCislo'">
        <div class="sortable-header">
          Číslo dokladu
          <p-sortIcon [field]="'dokladCislo'"></p-sortIcon>
        </div>
      </th>
      <th rowspan="2" *ngIf="!fromDoklad" [ngStyle]="{'width': '11%'}" [pSortableColumn]="'dokladDatum'">
        <div class="sortable-header">
          Datum dokladu
          <p-sortIcon [field]="'dokladDatum'"></p-sortIcon>
        </div>
      </th>
      <th rowspan="2" *ngIf="!fromDoklad" [ngStyle]="{'width': '11%'}" [pSortableColumn]="'dokladOrgUr'">
        <div class="sortable-header">
          Org. úroveň dokladu
          <p-sortIcon [field]="'dokladOrgUr'"></p-sortIcon>
        </div>
      </th>
      <th colspan="2">Doklad</th>
      <th colspan="2">Docházka</th>
    </tr>
    <tr>
      <th [ngStyle]="{'width': (fromDoklad) ? '10%' : '7%'}">Hodiny</th>
      <th [ngStyle]="{'width': (fromDoklad) ? '10%' : '7%'}">Dny</th>
      <th [ngStyle]="{'width': (fromDoklad) ? '10%' : '7%'}">Hodiny</th>
      <th [ngStyle]="{'width': (fromDoklad) ? '10%' : '7%'}">Dny</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-radek>
    <tr [pSelectableRow]="radek" [ngClass]="{'row-with-doklad' : radek.dokladGuid}">
      <td>{{radek.cislo}}</td>
      <td><app-ciselnik-kod [ciselnik]="'CDodavatel'" [guid]="radek.pracovnikGuid" [showPopisIn]="true"></app-ciselnik-kod></td>
      <td><app-ciselnik-kod [ciselnik]="'CDruhMzdy'" [guid]="radek.druhMzdyGuid" [showPopisIn]="true"></app-ciselnik-kod></td>
      <td *ngIf="!fromDoklad">{{radek.dokladCislo}}</td>
      <td *ngIf="!fromDoklad">{{radek.dokladDatum | date:'d.M.yyyy'}}</td>
      <td *ngIf="!fromDoklad"><app-org-ur-display [guid]="radek.dokladOrgUr" *ngIf="radek?.dokladGuid"></app-org-ur-display></td>
      <td>{{radek.odpracHodiny}}</td>
      <td>{{radek.odpracDny}}</td>
      <td [ngClass]="{'red' : radek.odpracHodiny != radek.dochazkaHodiny}">{{radek.dochazkaHodiny}}</td>
      <td [ngClass]="{'red' : radek.odpracHodiny != radek.dochazkaHodiny}">{{radek.dochazkaDny}}</td>
    </tr>
  </ng-template>
  <ng-template pTemplate="paginatorright" let-state>
    <div class="paginator-right">
      Zobrazeno {{state.totalRecords < 1 ? 0 : state.first + 1}} -
      {{
        state.totalRecords > 0 ? ((state.rows+ state.first) <= state.totalRecords ? (state.rows + state.first) :
        state.totalRecords) : 0
      }} z {{state.totalRecords ? state.totalRecords : 0}} záznamů
    </div>
  </ng-template>
</p-table>

<p-toolbar styleClass="base-toolbar" *ngIf="!dokladGuid">
  <div class="p-toolbar-group-left dochazka-left-box">
    <div style="display: flex">
      <div style="display: flex">
        <label for="dochazka-dodavatelGuid-dropdown">Pracovník</label>
        <app-ciselnik-dropdown [ciselnik]="'CDodavatel'" [nullable]="false" [virtualScroll]="true"
                                [panelStyle]="{'width':'400px'}" [edit]="edit" [filter]="filterPracovnici"
                                [(ngModel)]="newRadek.pracovnikGuid" id="dochazka-dodavatelGuid-dropdown"></app-ciselnik-dropdown>
      </div>
      <div style="display: flex">
        <label for="dochazka-druhMzdyGuid-dropdown">Druh mzdy</label>
        <app-ciselnik-dropdown [filter]="filterMzdy" [nullable]="false" [(ngModel)]="newRadek.druhMzdyGuid" [ciselnik]="'CDruhMzdy'"
                                [edit]="edit" id="dochazka-druhMzdyGuid-dropdown"></app-ciselnik-dropdown>
      </div>
    </div>
  </div>

  <div class="p-toolbar-group-right">
    <button pButton type="button" icon="pi pi-plus" (click)="addRadek()" label="Přidat" tooltip="Přidat docházku pracovníka."></button>
  </div>
</p-toolbar>


<div *ngIf="selectedRadek!=null" [formGroup]="formData"  class="ui-widget p-table" style="margin-top: 1em;" nextOnEnter>
  <table *ngIf="tydnyHeader.length>0" formArrayName="dny">
    <thead class="p-table-thead">
      <tr>
        <th width="5%">Týden</th>
        <th *ngFor="let tyden of tydnyHeader" [attr.colspan]="tyden.pocetDni">
          {{tyden.cislo}}
        </th>
      </tr>
      <tr>
        <th width="5%" rowspan="2">Den</th>
        <th *ngFor="let denHeader of dnyHeader" [ngClass]="{'nepracovni-svatky': denHeader.svatek}" title="{{denHeader.svatek||denHeader.nepracovni?denHeader.popis:''}}">
          {{denHeader.cislo}}
        </th>
      </tr>
      <tr>
        <th *ngFor="let denHeaderPop of dnyHeader" [ngClass]="{'nepracovni-svatky': denHeaderPop.svatek}" title="{{denHeaderPop.svatek||denHeaderPop.nepracovni?denHeaderPop.popis:''}}">
          {{denHeaderPop.nazev}}
        </th>
      </tr>
    </thead>
    <tbody class="p-table-tbody">
      <tr>
        <td></td>
        <td [formGroupName]="i" *ngFor="let den of getDny(); index as i" [ngClass]="{'nepracovni': den.value.nepracovni}">
          <decimal-input formControlName="hodiny" [edit]="edit"></decimal-input>
        </td>
      </tr>
    </tbody>
  </table>
</div>
