<p-toast position="top-center" key="orgur-list-toast"></p-toast>

<app-block-ui [block]="block"></app-block-ui>

<p-toolbar styleClass="base-toolbar" [style]="{'margin-bottom': '1em'}">
  <div class="p-toolbar-group-left">
    <h2 class="toolbar-label">Organizační úrovně</h2>
    <i class="p-toolbar-separator"><fa-icon [icon]="faEllipsisV"></fa-icon></i>
    <button pButton type="button" [disabled]="selectedZakaznik == undefined || showForm == true || selectedOrgUr != undefined" label="Nová" class="icon-btn" (click)="add()"><fa-icon [icon]="faPlus"></fa-icon></button>
    <app-zakaznik-dropdown [(ngModel)]="selectedZakaznik" [mode]="orgUrMode" (ngModelChange)="onZakaznikChange($event)"></app-zakaznik-dropdown>
    <div style="display: inline-block">
      <div class="p-inputgroup">
        <input type="text" pInputText placeholder="Hledat" [(ngModel)]="filter" style="width: 20em" />
        <button pButton type="button" class="icon-btn hide-label-button" (click)="filter = undefined" [disabled]="filter == undefined"><fa-icon [icon]="faBackspace"></fa-icon></button>
      </div>
    </div>

  </div>
  <div class="p-toolbar-group-right">
    <button pButton type="button" label="Zpět" class="icon-btn" (click)="back()"><fa-icon [icon]="faUndo"></fa-icon></button>
  </div>
</p-toolbar>


<p-treeTable #orgurList *ngIf="selectedZakaznik != undefined" [value]="lhcTree" sortField="kod" selectionMode="single" [(selection)]="selectedOrgUr"
              dataKey="guid" (onNodeSelect)="orgUrSelect($event)">

  <ng-template pTemplate="header">
    <tr>
      <th class="col-width" ttSortableColumn="kod">Kód<p-treeTableSortIcon field="kod"></p-treeTableSortIcon></th>
      <th  ttSortableColumn="popis">Název<p-treeTableSortIcon field="popis"></p-treeTableSortIcon></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
      <tr [ttRow]="rowNode" [ttSelectableRow]="rowNode">
        <td>
          <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
          {{rowData.kod}}
        </td>
        <td>
          {{rowData.popis}}
        </td>
      </tr>
  </ng-template>
</p-treeTable>

<p-panel *ngIf="showForm" [style]="{'margin-top': '1em'}">
  <ng-template pTemplate="header">
    <div class="p-grid p-align-center">
      <div class="p-col"><span class="p-panel-title">Organizační úroveň - {{data.nazev}}</span></div>
      <div class="p-col right">
        <button [disabled]="!form.valid" pButton type="button" label="Uložit" class="icon-btn p-button-success" (click)="save()"><fa-icon [icon]="faSave"></fa-icon></button>
        <button pButton type="button" label="Zrušit" class="icon-btn" (click)="cancel()"><fa-icon [icon]="faTimes"></fa-icon></button>
      </div>
    </div>
  </ng-template>
  <div class="p-grid validated-form-container" [formGroup]="form">
    <div class="p-col-2" >
      Nadřazená úroveň
      <app-org-ur variant="medium" [allLevelsSelectable]="true" [mode]="orgUrMode" [zakaznikGuid]="selectedZakaznik?.guid" [useAll]="true"
                   formControlName="parentGuid"></app-org-ur>
    </div>
    <div class="p-col-2">
      Název
      <input type="text" pInputText  formControlName="nazev" />
    </div>
    <div class="p-col-2">
      Kód
      <input type="number" pInputText  formControlName="kod" />
    </div>
    <div class="p-col-2">
      Poznámka
      <input type="text" pInputText  formControlName="poznamka"/>
    </div>
    <div class="p-col-2">
      Platnost od
      <app-calendar formControlName="platnostOd" id="platnostOd" [edit]="true"></app-calendar>
    </div>
    <div class="p-col-2">
      Platnost do
      <app-calendar formControlName="platnostDo" id="platnostDo" [edit]="true"></app-calendar>
    </div>
  </div>
</p-panel>
