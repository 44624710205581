export class CVyrSkladListDto {
  guid: string;
  podvykonGuid: string;
  lokalitaGuid: string;
  vykon: number;
  podvykon: number;
  podvykonPopis: string;
  platnostOd: Date;
  platnostDo: Date;
  vlozil: string;
  vlozilKdy: Date;
  opravil: string;
  opravilKdy: Date;
}
