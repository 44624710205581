export class DPruvodniListRadekDetailDto {
  guid: string;
  cisloRadku: number;
  cisloUj: string;
  drevinaGuid: string;
  sazeniceGuid: string;
  mnozstvi: number;
  kg: boolean;
  poznamka: string;

  orgUrGuids: string[];
}
