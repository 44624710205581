import { AfterViewInit, Component, ElementRef } from '@angular/core';
import { PreviewBase } from '../preview-base';

/**
 * Komponenta pro pro vykreslení náhledu polygonu
 **/
@Component({
  selector: 'app-preview-polygon',
  template: '<canvas width="{{previewSize}}" height="{{previewSize}}">Chyba zobrazení polygonu</canvas>'
})
export class PreviewPolygonComponent extends PreviewBase implements AfterViewInit {

  constructor(public hostElement: ElementRef) {
    super(hostElement);
  }

  ngAfterViewInit(): void {
    this._drawPreview();
  }

  /**
   * Metoda pro vykreslení náhledu polygonu
   */
  private _drawPreview() {
    super.drawPreview();
    if (this.initialized) {
      this.context.strokeStyle = this.projekt.strokeRgba;
      this.context.lineWidth = this.projekt.strokeWidth;
      this.context.strokeRect(0, 0, this.previewSize, this.previewSize);

      this.context.fillStyle = this.projekt.fillRgba;
      this.context.fillRect(0, 0, this.previewSize, this.previewSize);
    }
  }

}
