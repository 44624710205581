export class LhpDrevinaProVypocetDto {
  guid: string;
  zkratka: string;
  bonR: number;
  bonA: number;
  imise: string;
  zast: string;
  drZasCel: number;
  drTv: number;
  drTo: number;
}
