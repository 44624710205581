import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { KnihaService } from 'src/app/Services/Lhp/kniha.service';
import { JpDetailDto } from 'src/app/Dto/Lhp/JpDetailDto';
import { KnihaBase } from 'src/app/Components/Lhp/kniha-base';

@Component({
  selector: 'app-kniha-jp',
  templateUrl: './kniha-jp.component.html',
  styleUrls: ['./kniha-jp.component.css']
})
export class KnihaJpComponent extends KnihaBase {

  constructor(
    route: ActivatedRoute,
    knihaService: KnihaService
  ) {
    super(route, knihaService);
  }

  model: JpDetailDto;

  loadModel() {
    this.knihaService.getJp(this.lhcKod, this.odd, this.dil, this.por, this.jp, this.katuzeKod, this.guid)
      .subscribe(response => {
        this.model = response;
        this.jpGuid = this.model.guid;
        this.porGuid = this.model.lhpGuids.porGuid;
        this.dilGuid = this.model.lhpGuids.dilGuid;
        this.oddGuid = this.model.lhpGuids.oddGuid;
        this.lhcGuid = this.model.lhpGuids.lhcGuid;
        this.constructBreadCrumbs();
      });
  }
}
