<app-block-ui [block]="block"></app-block-ui>
<p-toast position="top-center" key="ol-edit-toast"></p-toast>
<app-vyroba-kontrola #kontrolaTree [showBtn]="false"></app-vyroba-kontrola>

<div [formGroup]="formData" class="ol-body" nextOnEnter>
  <p-panel>
    <ng-template pTemplate="header">
      <div class="p-grid p-align-center">
        <div class="p-col"><h2 class="p-panel-title">Odvozní lístek</h2></div>
        <div class="p-col right">
          <button pButton type="button" label="Uložit rozpracované" class="icon-btn p-button-success" (click)="save()" *ngIf="edit"><fa-icon [icon]="faSave"></fa-icon></button>
          <button pButton type="button" label="Uložit a dokončit" class="icon-btn p-button-warning" (click)="saveAndFinish()" *ngIf="edit"><fa-icon [icon]="faFlagCheckered"></fa-icon></button>
          <button pButton type="button" label="Zpět" class="icon-btn" (click)="cancel()"><fa-icon [icon]="faUndo"></fa-icon></button>
        </div>
      </div>
    </ng-template>
    <div class="p-grid">
      <div class="p-col-3">
        <div class="p-grid">
          <div class="p-col">
            Datum dokladu
            <app-calendar #datumDokladu formControlName="datum" [edit]="edit"></app-calendar>
          </div>
          <div class="p-col">
            Organizační úroveň
            <app-org-ur formControlName="orgUrGuid" [edit]="edit"></app-org-ur>
          </div>
        </div>
      </div>
      <div class="p-col-4">
        <div class="p-grid">
          <div class="p-col-1">
            <label for="ol-cislo-textbox" title="Číslo dokladu">Č.</label>
            <input formControlName="cislo" id="ol-cislo-textbox" type="text" pInputText *ngIf="edit" />
            <div class="p-inputtext ui-widget ui-state-default ui-corner-all ui-div-inputtext" *ngIf="!edit" title="{{formData.get('cislo')?.value}}">{{formData.get('cislo')?.value}} </div>
          </div>
          <div class="p-col-3">
            Výkon
            <app-ciselnik-dropdown [ciselnikData]="tezebniVykony" [nullable]="true" [virtualScroll]="true"
                                   [panelStyle]="{'width':'400px'}"
                                   formControlName="vykonGuid" [edit]="edit"></app-ciselnik-dropdown>
          </div>
          <div class="p-col-3">
            Podvýkon
            <app-podvykon-dropdown [ciselnik]="'CPodvykon'" [nullable]="true" [vykonGuid]="vykonGuid.value" [virtualScroll]="true"
                                   [panelStyle]="{'width':'600px'}"
                                   formControlName="podvykonGuid" [edit]="edit"></app-podvykon-dropdown>
          </div>
          <div class="p-col-3">
            Lokalita
            <app-ciselnik-dropdown [ciselnik]="'CLokalita'" [nullable]="true" formControlName="lokalitaGuid"
                                   (onChange)="lokalitaChangeHandler($event)" [edit]="edit"></app-ciselnik-dropdown>
          </div>
          <div class="p-col-2">
            Ujeté km
            <input formControlName="ujeteKm" type="text" pInputText *ngIf="edit" />
            <div class="p-inputtext ui-widget ui-state-default ui-corner-all ui-div-inputtext" *ngIf="!edit" title="{{formData.get('ujeteKm')?.value}}">{{formData.get('ujeteKm')?.value}} </div>
          </div>
        </div>
      </div>
      <div class="p-col-5">
        <div class="p-grid">
          <div class="p-col-5">
            Odběratel
            <app-ciselnik-dropdown formControlName="odberatelGuid" [ciselnik]="'COdberatel'" [nullable]="true" [virtualScroll]="true"
                                   [panelStyle]="{'width':'300px'}" [edit]="edit"></app-ciselnik-dropdown>
          </div>
          <div class="p-col-7">
            Kontrakt
            <app-vyroba-kontrakt-autocomplete formControlName="kontraktGuid" [odberatelGuid]="odberatelGuid.value" [edit]="edit"
                                              (onChange)="kontraktChanged($event)"></app-vyroba-kontrakt-autocomplete>
          </div>
        </div>
      </div>
    </div>
  </p-panel>

  <div class="p-grid p-align-stretch ol-main" (keydown)="onKeyDown($event)">
    <div class="p-col-5">
      <p-panel [styleClass]="'data-panel'" >
        <ng-template pTemplate="header">
          <div class="p-grid p-align-center">
            <div class="p-col"><span class="p-panel-title">Data řádků</span></div>
            <div class="p-col right" *ngIf="edit">
              <button pButton type="button" icon="pi pi-plus" (click)="addRadek()"></button>
              <button pButton type="button" icon="pi pi-minus" class="p-button-danger" (click)="delRadek()"></button>
            </div>
          </div>
        </ng-template>
        <div class="ol-data">
          <div class="ol-table-wrapper">
            <p-table [value]="radky.value" [styleClass]="'pds-table-compact'" [scrollable]="true" scrollHeight="flex"
                     selectionMode="single" [(selection)]="aktivniRadek" dataKey="guid"
                     (onRowSelect)="rowSelectHandler($event)" (onRowUnselect)="rowUnSelectHandler($event)">
              <ng-template pTemplate="header">
                <tr>
                  <th rowspan="2" title="Číslo řádku" style="width:4%">Č.</th>
                  <th *ngIf="showJprl" rowspan="2" style="width:15%">Lhc</th>
                  <th *ngIf="showJprl" rowspan="2" style="width:7%">Odd</th>
                  <th *ngIf="showJprl" rowspan="2" style="width:7%">Dil</th>
                  <th *ngIf="showJprl" rowspan="2" style="width:7%">Por</th>
                  <th *ngIf="showJprl" rowspan="2" style="width:15%">Psk</th>
                  <th *ngIf="showJprl" rowspan="2" style="width:15%">Etáž</th>
                  <th colspan="2" style="width:30%">Sortiment</th>
                  <th rowspan="2" style="width:15%">Množství</th>
                </tr>
                <tr>
                  <th>Obchodní</th>
                  <th>Výrobní</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-row let-rowIndex="rowIndex">
                <tr [pSelectableRow]="row" [pSelectableRowIndex]="rowIndex" class="ol-data-row">
                  <td style="width:4%">{{row.cislo}}</td>
                  <td *ngIf="showJprl" style="width:15%">{{row.lhc}}</td>
                  <td *ngIf="showJprl" style="width:7%">{{row.odd}}</td>
                  <td *ngIf="showJprl" style="width:7%">{{row.dil}}</td>
                  <td *ngIf="showJprl" style="width:7%">{{row.por}}</td>
                  <td *ngIf="showJprl" style="width:15%">{{row.psk}}</td>
                  <td *ngIf="showJprl" style="width:15%">{{row.etaz}}</td>
                  <td style="width:15%">
                    <app-ciselnik-kod [ciselnikData]="sortimentyObchodni" [guid]="row.sortimentObchodniGuid"></app-ciselnik-kod>
                  </td>
                  <td style="width:15%">
                    <app-ciselnik-kod ciselnik="CSortiment" [guid]="row.sortimentGuid"></app-ciselnik-kod>
                  </td>
                  <td style="width:15%" class="right">{{row.mnozstvi | number:'1.2-2'}}</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
          <p-card>
            <div [formGroup]="aktivniRadekGroup">
              <div class="p-grid">
                <div *ngIf="showJprl" class="p-col-12">
                  <app-etaz-input #etazInput formControlName="etazGuid" (onChange)="jprlChanged($event)" [(reset)]="etazReset" [edit]="edit" [orgUrGuid]="orgUrGuid"></app-etaz-input>
                </div>
                <div class="p-col-12">
                  <label for="ol-sortimentObchodniGuid-dropdown">Obchodní sortiment</label>
                  <app-ciselnik-dropdown #sortimentDropDown [ciselnikData]="sortimentyObchodni" [nullable]="true" [virtualScroll]="true" [edit]="edit"
                                         formControlName="sortimentObchodniGuid" id="ol-sortimentObchodniGuid-dropdown"
                                         (onChange)="sortimentObchodniChanged($event)"></app-ciselnik-dropdown>
                </div>
                <div class="p-col-8">
                  <label for="ol-sortimentGuid-dropdown">Výrobní sortiment</label>
                  <app-ciselnik-dropdown ciselnik="CSortiment" [nullable]="true" [virtualScroll]="true" [edit]="edit"
                                         formControlName="sortimentGuid" id="ol-sortimentGuid-dropdown"></app-ciselnik-dropdown>
                </div>
                <div class="p-col-4">
                  <label for="ol-mnozstvi-textbox">Množství</label>
                  <decimal-input formControlName="mnozstvi" [edit]="edit" id="ol-mnozstvi-textbox"></decimal-input>
                </div>
              </div>
            </div>
          </p-card>
        </div>
      </p-panel>
    </div>
    <div class="p-col-4 ol-second-column">
      <p-panel class="ol-prostredky">
        <ng-template pTemplate="header">
          <div class="p-grid p-align-center">
            <div class="p-col"><span class="p-panel-title">Prostředky</span></div>
            <div class="p-col right" *ngIf="edit">
              <button pButton type="button" icon="pi pi-plus" (click)="addProstredek()"></button>
            </div>
          </div>
        </ng-template>
        <div>
          <div formArrayName="prostredky">
            <div *ngFor="let prostredek of prostredky.controls;let i = index;" [formGroup]="prostredek" [ngClass]="'prostredek'">
              <app-ciselnik-dropdown [ciselnik]="'CProstredek'" [nullable]="true" [virtualScroll]="true" [ngClass]="'prostredek-ciselnik'"
                                     formControlName="prostredekGuid" [edit]="edit"></app-ciselnik-dropdown>
              <button pButton type="button" icon="pi pi-minus" class="p-button-danger" (click)="removeProstredek(i)" *ngIf="edit"></button>
            </div>
          </div>
        </div>
      </p-panel>
      <p-panel header="Data dokladu" class="ol-data-dokladu">
        <div class="ol-data-dokladu-form">
          <div>
            Jméno dopravce a SPZ
            <app-ciselnik-dropdown [ciselnik]="'CDopravce'" [nullable]="true" [virtualScroll]="true"
                                   formControlName="dopravceGuid" [edit]="edit"></app-ciselnik-dropdown>
          </div>

          <div>
            Číslo CMR
            <input type="text" pInputText formControlName="cisloCmr" *ngIf="edit" />
            <div class="p-inputtext ui-widget ui-state-default ui-corner-all ui-div-inputtext" *ngIf="!edit" title="{{formData.get('cisloCmr')?.value}}">{{formData.get('cisloCmr')?.value}} </div>
          </div>

          <div>
            Číslo záměru
            <input type="text" pInputText formControlName="cisloZameru" *ngIf="edit" />
            <div class="p-inputtext ui-widget ui-state-default ui-corner-all ui-div-inputtext" *ngIf="!edit" title="{{formData.get('cisloZameru')?.value}}">{{formData.get('cisloZameru')?.value}} </div>
          </div>

          <div>
            Zakázka
            <app-ciselnik-dropdown [ciselnik]="'CZakazka'" [nullable]="true" [virtualScroll]="true"
                                   formControlName="zakazkaGuid" [edit]="edit"></app-ciselnik-dropdown>
          </div>

          <div>
            Certifikace
            <app-ciselnik-dropdown [ciselnik]="'CCertifikace'" [nullable]="true" formControlName="certifikaceGuid" [edit]="edit"></app-ciselnik-dropdown>
          </div>
        </div>
      </p-panel>
    </div>
    <div class="p-col-3 ol-third-column">
      <p-panel>
        <ng-template pTemplate="header">
          <div class="p-grid p-align-center">
            <div class="p-col"><span class="p-panel-title">Čísla vagónů</span></div>
            <div class="p-col right" *ngIf="edit">
              <button pButton type="button" icon="pi pi-plus" (click)="addVagon()"></button>
            </div>
          </div>
        </ng-template>
        <div>
          <div formArrayName="vagony">
            <div *ngFor="let vagon of vagony.controls; let i = index;" [formGroup]="vagon" [ngClass]="'vagon'">
              <input type="text" pInputText formControlName="cisloVagonu" *ngIf="edit" />
              <button pButton type="button" icon="pi pi-minus" class="p-button-danger" (click)="removeVagon(i)" *ngIf="edit"></button>
              <div class="p-inputtext ui-widget ui-state-default ui-corner-all ui-div-inputtext" *ngIf="!edit" title="{{vagon?.value?.cisloVagonu}}">{{vagon?.value?.cisloVagonu}} </div>
            </div>
          </div>
        </div>
      </p-panel>
      <p-panel header="Poznámka" class="ol-poznamka">
        <div>
          <div>
            Popis kontraktu
            <span class="read-only-box">
              {{kontraktDto?.kontraktPopis}}
            </span>
          </div>
          <div>
            Poznámka
            <textarea pInputTextarea formControlName="poznamka" *ngIf="edit"></textarea>
            <div class="p-inputtext ui-widget ui-state-default ui-corner-all ui-div-inputtext" *ngIf="!edit" title="{{formData.get('poznamka')?.value}}">{{formData.get('poznamka')?.value}} </div>
          </div>
        </div>
      </p-panel>
      <p-panel header="Ovládání" [toggleable]="true" class="ol-help" *ngIf="edit">
        <app-vyroba-help coKopiruje="LHC, JPRL a etáž"></app-vyroba-help>
      </p-panel>
    </div>
  </div>
</div>


<p-confirmDialog #confirmDlg header="Uložit a dokončit" icon="pi pi-exclamation-triangle" key="ol-confirm-dlg">
  <p-footer>
    <button type="button" pButton icon="pi pi-times" label="Ne" (click)="confirmDlg.reject()"></button>
    <button type="button" pButton icon="pi pi-check" label="Ano" (click)="confirmDlg.accept()"></button>
  </p-footer>
</p-confirmDialog>
