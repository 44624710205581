export class CVyrMaticeListDto {
  guid: string;
  dokladTypKod: string;
  vykon: number;
  podvykon: number;
  podvykonPopis: string;
  poznamka: string;
  platnostOd: Date;
  platnostDo: Date;
  vlozil: string;
  vlozilKdy: Date;
  opravil: string;
  opravilKdy: Date;
}
