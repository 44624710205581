import { Component, OnInit, Input } from '@angular/core';
import { VypocetInfoDto } from 'src/app/Dto/Skody/VypocetInfoDto';
import { DruhVypoctu } from 'src/app/Utils/Skody/druh-vypoctu.utils';


/**
 * Zobrazení výsledku škody.
**/
@Component({
  selector: 'skody-detail-vysledek',
  templateUrl: './skody-detail-vysledek.component.html',
  styleUrls: ['./skody-detail-vysledek.component.css']
})
export class SkodyDetailVysledekComponent implements OnInit {

  constructor() {
    this.initSkodyHeaders();
  }

  @Input() vypocet: VypocetInfoDto;
  @Input() set druhVypoctu(value: string) {
    this.setHeader(value);
  }
  @Input() blockedPrepocet: boolean;

  skodaHeader: string = '';
  skodyHeaders: { [id: string]: string } = {};

  ngOnInit() {
  }

  initSkodyHeaders(): void {
    this.skodyHeaders[DruhVypoctu.S6] = 'Škoda zničením';
    this.skodyHeaders[DruhVypoctu.S7_2] = 'Škoda okusem';
    this.skodyHeaders[DruhVypoctu.S9_1] = 'Škoda loupáním';
    //TODO hlavicky
    this.skodyHeaders[DruhVypoctu.U7] = 'Újma U7';
    this.skodyHeaders[DruhVypoctu.U8] = 'Újma U8';
    this.skodyHeaders[DruhVypoctu.U10] = 'Újma U10';
  };

  /**
   * Nastavení hlavičky dle druhu škody.
   * @param druhVypoctu
   */
  setHeader(druhVypoctu: string): void {
    this.skodaHeader = this.skodyHeaders[druhVypoctu];
  };
}
