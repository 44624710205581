/**
 * Enum rolí uživatele
 * */
export enum RoleEnum {
  LhpProhlizec = "LhpProhlizec",
  SuperAdmin = "SuperAdmin",
  LhpAdmin = "LhpAdmin",
  ZakaznikAdmin = "ZakaznikAdmin",
  NacrtyProhlizec = "NacrtyProhlizec",
  NacrtyEditor = "NacrtyEditor",
  NacrtyAdmin = "NacrtyAdmin",
  SkodyAdmin = "SkodyAdmin",
  DotazovacAdmin = "DotazovacAdmin",
  DotazovacEditor = "DotazovacEditor",
  DotazovacProhlizec = "DotazovacProhlizec"
}
