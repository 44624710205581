import { Component, Input } from '@angular/core';
import { VypocetDetailDto } from 'src/app/Dto/Skody/VypocetDetailDto';
import { PonechaniDriviDto } from 'src/app/Dto/Skody/PonechaniDriviDto';

@Component({
  selector: 'ujmy-detail-u8-kroky',
  templateUrl: './ujmy-detail-u8-kroky.component.html',
  styleUrls: ['./ujmy-detail-u8-kroky.component.css']
})
export class UjmyDetailU8KrokyComponent {

  constructor() { }

  @Input() blockedPrepocet: boolean;
  @Input() ponechaniDriviDto: PonechaniDriviDto;
}
