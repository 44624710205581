<div class="p-grid p-align-stretch kniha-detail-form p-mb-2">
  <div class="p-col-1">
    <app-label-input-display [value]="op?.op" label="OP" tooltip="Ostatní plocha" [formatConent]="false"></app-label-input-display>
  </div>
  <div class="p-col-1">
    <app-ciselnik-field key="COpDruh" [guid]="op?.opDruhGuid" label="Druh OP" variant="short" tooltip="Druh ostatní plochy"></app-ciselnik-field>
  </div>

  <div class="p-col">
    Zp. využití
    <div class="p-inputtext ui-corner-all overflow-scroll">{{op?.opVyuz}}</div>
  </div>
</div>
<app-kniha-rozdeleni-lesa [rozdeleniLesa]="op"></app-kniha-rozdeleni-lesa>
