import { Injectable } from "@angular/core";
import { TreeNode } from "primeng/api";
import { LayerBehaviourType } from "../../Dto/Mapa/LayerBehaviourType";
import { LayerTreeSourceDto } from "../../Dto/Mapa/LayerTreeSourceDto";


/**
  * Rozhranní výsledku hledání uzlu stomu.
**/
export interface IFindNodeArgs {
  //uzel
  node: TreeNode;
  //zebo-based úroveň zanoření, na které uzel neexistuje
  level: number;
  //true, pokud byl uzel nalezen, jinak false
  exists: boolean;
}


/**
 * Generátor stromové struktury mapových vrstev.
**/
@Injectable({
  providedIn: 'root'
})
export class LayerTreeGenerator {
  public readonly GROUP_TEMPLATE_NAME: string = "layerGroup";
  public readonly SEKCE_EXTERNI_ZDROJE: string = "externi_zdroje";

  /**
   * Vrátí stromovou strukturu mapových vrstev.
   * @param sources {LayerTreeSourceDto[]} definiční dto mapových vrstev
   */
  public getTree(sources: LayerTreeSourceDto[]): TreeNode[] {
    try {
      return this._createTree(sources);
    }
    catch (err) {
      console.error(err);
    }
  }


  /**
   * Sestavení stromu.
   * @param sources {LayerTreeSourceDto[]} definiční dto mapových vrstev
   */
  private _createTree(sources: LayerTreeSourceDto[]): TreeNode[] {
    let tree: TreeNode[] = [];

    sources.forEach(source => {
      //1) vytvoření terminálního uzlu
      let leafNode = this._createLeafNode(source);

      //2) vyhledáme rodičovcký uzel k terminálnímu uzlu z bodu 1)
      let parentNodeInfo: IFindNodeArgs = this._findNode(source.groupName, tree);

      //2a) rodičovský uzel neexistuje - budeme jej vytvářet
      if (!parentNodeInfo.exists) {
        //rozdelením názvu skupiny získáme hierarchický seznam skupin, kam terminální uzel z bodu 1) patří
        let parts = source.groupName.split('/');

        //protože již může existovat část hierarchické úrovně, vezmene si pouze ty skupiny, které ještě neexistují
        let partsToCreate = parts.splice(parentNodeInfo.level); //toto v 'parts' ponechá pouze názvy uzlů, které již existují

        //odzadu (zdola nahoru) vytvoříme uzly skupin
        //nově vytvořený rodičovský uzel
        let newParent: TreeNode = this._generateNodesBottomToUp(parts, partsToCreate, source, leafNode);

        //přidáme uzel nejvyšší hierarchické úrovně do 'tree' na příslušnou úroveň zanoření
        //pokud v 'parts' nezůstal žádný název, znamená to, že jsme generovali top level uzel, který se přidává přímo do 'tree'
        if (parts.length == 0) {
          tree.push(newParent);
        }
        else {
          //vyhledáme existující uzel hierarchicky nad nově vytvořenými uzly a pokud se uzel...
          let nodeInfo: IFindNodeArgs = this._findNode(parts.join('/'), tree);
          //...nenašel, jde o nějakou 'nepěknou' chybu :(
          if (!nodeInfo.exists) {
            console.error('Uzel stromu ' + parts.join('/') + ' neexistuje. Nepodařilo se přidat uzel ' + partsToCreate.join('/'));
          }
          //...našel, tak jej přidáme
          else {
            nodeInfo.node.children.push(newParent);
            newParent.parent = nodeInfo.node;
          }
        }
      }

      //2b) rodičovský uzel existuje - přidáme do něj terminální uzel z bodu 1)
      else {
        parentNodeInfo.node.children.push(leafNode);
        leafNode.parent = parentNodeInfo.node;
      }
    });

    //3) strom je hotový :-)
    return tree;
  }


  /**
   * Vyhledání uzlu stromu.
   * @param name {string} název skupiny oddělený '/'
   * @param tree {TreeNode[]} množina uzlů stromu, ve kterých se kontrolune výskyt uzlu
   */
  private _findNode(name: string, tree: TreeNode[]): IFindNodeArgs {
    return this.__findNodeInner(name, tree, 0);
  }


  /**
   * Není určeno pro přímé volání (kvůli nastavování paramteru "level").
   * Vyhledání uzlu stromu.
   * @param name {string} název skupiny oddělený '/'
   * @param tree {TreeNode[]} množina uzlů stromu, ve kterých se kontrolune výskyt uzlu
   * @param level {number} úroveň zanoření (0 == top level node)
   */
  private __findNodeInner(name: string, tree: TreeNode[], level: number): IFindNodeArgs {
    let parts = name.split('/');

    let node = tree.filter(node => node.key == parts.slice(0, level + 1).join('/'));
    if (parts.length == level + 1) {
      if (node.length != 0) {
        return { node: node[0], level: null, exists: true }
      }
      else {
        return { node: null, level: level, exists: false }
      }
    }
    else {
      if (node.length == 0) {
        return { node: null, level: level, exists: false };
      }
      else {
        return this.__findNodeInner(name, node[0].children, ++level);
      }
    }
  }


  /**
   * Vytvoření koncového uzlu stromu.
   * @param dto {LayerTreeSourceDto} definiční dto mapové vrstvy
   */
  private _createLeafNode(dto: LayerTreeSourceDto): TreeNode {
    let node: TreeNode = {
      key: dto.id,
      data: dto.data,
      label: dto.label,
      leaf: true,
      type: dto.behaviour?.toString(),
      selectable: dto.selectableLeaf
    }

    if (dto.boudingBox != void 0) {
      node["boudingBox"] = dto.boudingBox;
    }

    return node;
  }


  /**
   * Vytvoření skupinového uzlu stromu.
   * @param id {string} id uzlu
   * @param name {string} název uzlu
   * @param dto {LayerTreeSourceDto} definiční dto mapové vrstvy
   */
  private _createGroupNode(id: string, name: string, dto: LayerTreeSourceDto): TreeNode {
    return {
      key: id,
      data: dto.data,
      label: name,
      leaf: false,
      children: [],
      type: this.GROUP_TEMPLATE_NAME,
      styleClass: dto.section != void 0 ? 'pds_' + dto.section : '',
      selectable: dto.selectableGroup
    }
  }


  /**
   * Vygenerování stromové struktury zdola nahoru. Vrací uzel {TreeNode} nejvyšší vytvořené hierarchické úrovně.
   * @param parts {string[]} hierarchicky seřazené pole názvů uzlů, které již existují
   * @param partsToCreate {string[]} hierarchicky seřazené pole názvů uzlů, které je potřeba vytvořit
   * @param source {LayerTreeSourceDto} definiční dto mapové vrstvy
   * @param leafNode {TreeNode} terminální uzel stromu (nejnižší úrovneň)
   */
  private _generateNodesBottomToUp(parts: string[], partsToCreate: string[], source: LayerTreeSourceDto, leafNode: TreeNode): TreeNode {
    //uzel, který byl vytvořen v přechozí iteraci. Jde o uzel, který se nachází na nižší hierarchické úrovni
    let previousNode: TreeNode = null;
    for (var i = partsToCreate.length - 1; i >= 0; i--) {
      //vytvoříme uzel aktuální hierarchické úrovně
      let groupId = parts.length > 0 ? parts.join('/') + '/' : '';
      groupId += partsToCreate.slice(0, i + 1).join('/');
      let parentNode = this._createGroupNode(groupId, partsToCreate[i], source);

      //pokud nejde o nejnižší hierarchickou úroveň, přiřadíme do něj uzel vytvořený v předchozí iteraci...
      if (previousNode != null) {
        parentNode.children.push(previousNode);
        previousNode.parent = parentNode;
      }
      //... v opačném případě do něj vložíme terminální uzel z bodu 1)
      else {
        parentNode.children.push(leafNode);
        leafNode.parent = parentNode;
      }

      //uzel uložíme pro další iteraci. V 'previousNode' je tedy od této chvíle vždy uložen uzel akutálně nejvyšší hierarchické úrovně, kterou jsme vytvořili
      previousNode = parentNode;
    }

    return previousNode;
  }
}
