import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NacrtyService } from '../../../Services/Nacrty/nacrty.service';
import { faCheck, faTimes, faAlignCenter } from '@fortawesome/free-solid-svg-icons';

/**
 * Komponenta knihovny typů čar linií. Zprostředkovává výběr z knihovny typů a náhled vybraného typu.
 * Typy čar jsou v náhledech v barvě a tloušťce dle nastavení projektu.
 * Ven předává klíč vybraného typu čáry (knihovna je uložena v sessionStorage).
**/
@Component({
  selector: 'app-line-type-library',
  templateUrl: './line-type-library.component.html',
  styleUrls: ['./line-type-library.component.css']
})
export class LineTypeLibraryComponent {

  /**
   * Klíč typu čáry.
  **/
  private _lineTypeKey: string = null;
  @Input() set lineTypeKey(value: string) {
    this._lineTypeKey = value;
    this._setIconByKey(this._lineTypeKey, this.lineTypes);
  }

  /**
   * Barva čáry.
  **/ 
  private _lineColor: string = 'black';
  @Input() set lineColor(value: string) {
    this._lineColor = value;
    this._prepareLibrary();
  }


  /**
   * Tloušťka čáry.
  **/
  private _width: number = 3;
  @Input() set width(value: number) {
    this._width = value;
    this._prepareLibrary();
  }


  /**
   * Odsazení zobrazeného náhledu typu linie tak, aby byl zobrazen vertikálně uprostřed.
  **/
  get paddingTop(): string {
    return 'calc(2.5rem / 2 - ' + (this._width / 2) + 'px)';
  }


  /**
   * Událost předávající vybraný typ čáry.
  **/
  @Output() SelectedLineTypeName: EventEmitter<string> = new EventEmitter<string>();


  constructor(private nacrtyService: NacrtyService)
  {
    this._prepareLibrary();
  }


  /**
   * Příprava knihovny. Ta se přegeneruje s kažkou změnou barvy/tloušťky čáry.
  **/
  _prepareLibrary(): void {
    this.nacrtyService.getLineTypeLibrary().subscribe(lineTypes => {
      var items = [];
      Object.keys(lineTypes).forEach(key => {
        items.push({
          name: key,
          title: lineTypes[key].title,
          value: lineTypes[key],
          cssStyle: this._generateCss(lineTypes[key], this._width, this._lineColor)
        });
      });
      this.lineTypes = items;
      this._setIconByKey(this._lineTypeKey, this.lineTypes);
    });
  }


  /**
   * Vygenerování css stylu pro HMTL prezentaci galerie s nastavenými barvami a tloušťkou čáry dle nastavení projektu.
   * @param lineType {any} definice typu čáry
   * @param width {number} tloušťka čáry
   * @param color {string} barva čáry
   */
  _generateCss(lineType: any, width: number, color: string): string {
    let style = 'height: ' + width + 'px; max-height: 2.5rem; background-image: repeating-linear-gradient(90deg, ';

    let fromStart = 0;

    if (lineType.dashArray.length > 0) {
      for (var i = 0; i < lineType.dashArray.length; i++) {
        let intervalCurr = lineType.dashArray[i];
        let odd = i % 2 == 0;
        let usedColor = odd ? color : 'transparent';

        if (lineType.dashOffset > 0 && i == 0) {
          style += 'transparent ' + fromStart + 'px, transparent ' + lineType.dashOffset + 'px, ';
        }
        else {
          style += usedColor + ' ' + fromStart + 'px, ' + usedColor + ' ' + (fromStart + intervalCurr) + 'px';
          if (i + 1 < lineType.dashArray.length) {
            style += ', ';
          }
        }
        fromStart += intervalCurr;
      }

      style += ');';
    }
    else {
      style += color + '0px, ' + color + ' 1px);';
    }
    return style;
  }


  lineTypes: any[] = [];
  selectedLineType: {key:string, any} = null;
  usedLineType: any = null;
  showDlg: boolean = false;
  faCheck = faCheck;
  faTimes = faTimes;
  faAlignCenter = faAlignCenter;


  /**
   * Výběr typu čáry z knihovny dle klíče.
   * @param key {string} klíč vybraného typu čáry
   * @param lineTypes {any[]} seznam dostupných typů čar
   */
  private _setIconByKey(key: string, lineTypes: any[]) {
    if (key != void 0 && key != '' && lineTypes.length > 0) {
      this.selectedLineType = lineTypes.find(x => x.name == key);
    }
    else {
      this.selectedLineType = null;
    }
    this.usedLineType = this.selectedLineType;
  }


  /**
    * Výběr typu čáry.
  **/
  pouzitTypCary(): void {
    this.usedLineType = this.selectedLineType;
    this.SelectedLineTypeName.emit(this.usedLineType.name);
    this.showDlg = false;
  }
}
