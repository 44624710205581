<p-blockUI [blocked]="blocked">
  <p-progressSpinner></p-progressSpinner>
</p-blockUI>
<p-toast position="top-center" key="{{TOAST_KEY}}"></p-toast>
<p-confirmPopup key="{{CONFIRM_KEY}}"></p-confirmPopup>

<div *ngIf="visible" style="margin-top:0.5rem;" class="nacrt-edit-component">
  <p-toolbar styleClass="base-toolbar" [style]="{'margin-bottom': '0.5rem'}">
    <div class="p-toolbar-group-left">
      <h2 class="toolbar-label">Úprava náčrtu</h2>
    </div>
    <div class="p-toolbar-group-right">
      <button pButton type="submit" label="Uložit" class="icon-btn"
              pTooltip="Uložit změny" tooltipPosition="bottom"
              [disabled]="!editForm.isValid"
              (click)="save()">
        <fa-icon [icon]="faCheck"></fa-icon>
      </button>
      <button pButton type="button" label="Zrušit" class="icon-btn" style="margin-left:0.5rem;"
              pTooltip="Zrušit změny" tooltipPosition="bottom"
              [disabled]="canDeactivate()"
              (click)="cancel($event)">
        <fa-icon [icon]="faTimes"></fa-icon>
      </button>
    </div>
  </p-toolbar>

  <div class="flex-container">
    <p-card [ngStyle]="{'flex-grow': 1, 'flex-basis': '15%', 'overflow-y': 'auto' }">
      <app-nacrt-edit-form #editForm [inputNacrtData]="formData" [nacrtProjects]="_nacrtProjects" [allowSwitchVisibleProjects]="false" [featureType]="featureType"></app-nacrt-edit-form>
    </p-card>
    <p-card [ngStyle]="{'flex-grow': 3, 'flex-basis': '35%', 'margin-left': '.5rem', 'margin-right': '.5rem' }">
      <app-nacrt-priloha-foto-edit></app-nacrt-priloha-foto-edit>
    </p-card>
    <app-nacrt-priloha-audio-edit style="flex-grow: 2; flex-basis: 25%; overflow-y: auto;margin-right: 0.5rem;"></app-nacrt-priloha-audio-edit>
    <app-nacrt-map style="flex-grow: 2; flex-basis: 25%;"></app-nacrt-map>
  </div>
</div>
