import { Component, forwardRef, HostBinding, Input, Output, EventEmitter, ViewChild, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { LhpoService } from 'src/app/Services/Lhp/lhpo.service';
import { PskInfoDto } from 'src/app/Dto/Lhp/PskInfoDto';
import { AutoComplete } from 'primeng/autocomplete';

@Component({
  selector: 'app-jprl-autocomplete',
  templateUrl: './jprl-autocomplete.component.html',
  styleUrls: ['./jprl-autocomplete.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => JprlAutocompleteComponent),
    multi: true
  }]
})
export class JprlAutocompleteComponent implements ControlValueAccessor, OnInit {

  private _id = '';
  private _lhcGuid: string = null;
  private _jprl: string = null;

  /**
   * Pokud je true, tak to znamená chybně zadanou hodnotu, input se zobrazí červeně
   */
  jprlAlert: boolean = false;

  /**
   * Vybrané JPRL - úplná informace
   */
  jprlDto: PskInfoDto = null;

  /**
   * Návrhy pro autocomplete
   */
  navrhovaneJprl: PskInfoDto[];

  onModelChange: any = () => { };
  onModelTouched: any = () => { };
  @Input() disabled: boolean = false;

  // finta s ID odkoukána zde: https://coryrylan.com/blog/angular-custom-form-controls-with-reactive-forms-and-ngmodel
  @HostBinding('attr.id')
  externalId = '';

  @Input() edit: boolean = true;

  @Input()
  set id(value: string) {
    this._id = value;
    this.externalId = null;
  }

  get id() {
    return this._id;
  }

  @Input('value') _value: string = null;

  get value() {
    return this._value;
  }

  set value(val) {
    this._workaround();
    this._value = val;
    this.onModelChange(val);
    this.onModelTouched();
    this.onChange.emit(this.jprlDto);
  }

  /**
   * GUID LHC, v rámci kterého se pohybujeme
   */
  @Input()
  set lhcGuid(value: string) {
    if (value != this._lhcGuid) {
      this._lhcGuid = value;
      this._setAfterInit();
    }
  }

  get lhcGuid(): string {
    return this._lhcGuid;
  }

  /**
   * Vstupní info o textu, který se má zobrazit v inputu (aby se nemuselo načítat podle GUID, nehledě na to, že někdy ten GUID nemusím řešit)
   */
  @Input()
  set jprl(val: string) {
    if (val != this._jprl) {
      this._jprl = val;
      this._setAfterInit();
      this._workaround();
    }
  }

  /**
   * Id inputu v autocomplete
   **/
  @Input() jprlInputId: string;

  /**
   *  Pokusí se najít psk po inicializaci, aby mohla být odeslána eventa pro případné naplnění autocomplete etáže
   * */
  private _setAfterInit(): void {
    if (this._jprl != void 0 && this.lhcGuid != void 0) {
      this.lhpoService.pskList(this.lhcGuid, this._jprl).subscribe(res => {
        this.jprlDto = res.find(x => x.jprl == this._jprl);
        if (this.jprlDto != void 0) {
          this.value = this.jprlDto?.guid;
        }
      });
    }      
  }

  get jprl(): string {
    return this._jprl;
  }

  @Output() onChange: EventEmitter<PskInfoDto> = new EventEmitter();

  // workaround na bug v autocomplete: https://github.com/primefaces/primeng/issues/3641
  @ViewChild("jprlAutocomplete") autocomplete: AutoComplete;

  constructor(private lhpoService: LhpoService) { }

  ngOnInit(): void {
    this._workaround();
  }

  writeValue(obj: any): void {      
    this.value = obj;// vyvolá OnChange
    // TODO pořešit změnu zvenčí. Ve výrobě se s GUID PSK nepracuje, proto teď neřeším.
    // kdybychom komponentu chtěli takto použít, tak by si při nastavení GUID zvenčí
    // musela z WebAPI stáhnout všechny potřebné informace pro zobrazení.
    //if (this.value != null && this._allLhc.length > 0) {
    //  this.lhcDto = this._allLhc.find(x => x.guid == this.value);
    //  this.lhcKod = this.lhcDto?.lhcKod;
    //}
  }

  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * Vrátí seznam lhc pro autocomplete.
   * @param event
   */
  hledejJprl(event): void {
    if (this.lhcGuid != null) {
      let toSearch = event.query;
      this.jprlAlert = false;

      this._fillNavrhovaneJprl(toSearch);
    }
    else {
      this.navrhovaneJprl = [];
    }
  };

  /**
   * Zajistí dotažení informací i v případě, že uživatel zapsal vše ručně.
   * @param event
   */
  onBlur(event) {
    if (this.lhcGuid != null && event.target.value != void 0 && typeof event.target.value == 'string') {
      let toSearch = event.target.value;
      this._fillNavrhovaneJprl(toSearch);

      this.jprlDto = this.navrhovaneJprl.find(x => x.jprl == toSearch);
      this.jprlAlert = (this.jprlDto == undefined);
      this.jprl = this.jprlDto?.jprl;
      this.value = this.jprlDto?.guid;
    }
  }

  /**
   * Ošetření výběru ze seznamu autocomplete (potřebuji celé DTO, nikoli jen jednu položku)
   * @param value
   */
  onSelect(value: PskInfoDto): void {
    this.jprlDto = value;
    this.jprlAlert = false;
    this.jprl = this.jprlDto?.jprl;
    this.value = value.guid; // vyvolá OnChange
  }

  /**
   * pomůcka pro obsluhu služby a nastavení kolekce návrhů (volá se při autocomplete a blur)
   * @param toSearch
   */
  private _fillNavrhovaneJprl(toSearch: string): void {
    if (RegExp(/^\d{1,3}[A-Za-z]/).test(toSearch)) { //hledání jprl spustíme teprve až je zadáno oddělení a dílec
      this.lhpoService.pskList(this.lhcGuid, toSearch).subscribe(res => {
        var toSearchUpperDil = toSearch.replace(/^(\d{1,3}[a-z])/, function (v) { return v.toUpperCase(); });
        this.navrhovaneJprl = res.filter(x => x.jprl.startsWith(toSearchUpperDil)).slice();
        if (this.navrhovaneJprl.findIndex(x => x.jprl == toSearchUpperDil) == -1) this.jprlAlert = true;
      });
    }
    else {
      this.navrhovaneJprl = [];
      this.jprlAlert = true;
    }
  }

  /**
   * workaround na bug v autocomplete: https://github.com/primefaces/primeng/issues/3641
   */
  private _workaround(): void {
    if (this.autocomplete != void 0)
      setTimeout(() => this.autocomplete.inputEL.nativeElement.value = (this.jprl != undefined ? this.jprl : ""));
  }
}
