import { Injectable, EventEmitter } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { LhcListDto } from 'src/app/Dto/Lhp/LhcListDto';
import { JprlInfoDto } from 'src/app/Dto/Skody/JprlInfoDto';
import { JprlUtils } from 'src/app/Utils/Lhp/jprl.utils';
import { PskInfoDto } from 'src/app/Dto/Lhp/PskInfoDto';
import { EtazDetailDto } from 'src/app/Dto/Lhp/EtazDetailDto';
import { LayerDefinitionDto } from '../../Dto/Mapa/LayerDefinitionDto';
import { AuthService } from '../Auth/auth.service';

@Injectable({
  providedIn: "root"
})
export class LhpoService {
  constructor(
    private http: HttpClient,
    private jprlUtils: JprlUtils,
    private authService: AuthService
  ) {
    authService.loggedOut.subscribe(() => {
      this._ciselnikLhc = null;
      this._ciselnikJprl = {};
      this._orgUrGuid = null;
    });
  }


  private apiLhc: string = "api/Lhc/List";
  private apiJprl: string = "api/Lhc/JprlList";
  private apiPskInfo: string = "api/Lhc/PskInfoList";
  private apiEtazDetail: string = "api/Lhc/EtazDetail";
  private apiGetMapPorostni: string = "api/Lhc/GetMapPorostni";

  private _ciselnikLhc: any = null;
  private _ciselnikJprl: any = {};
  private _orgUrGuid: string;//Omezení listu podle orgur. Provádí se ve výrobních dokladech. 


  public lhc(guid: string): Observable<LhcListDto> {
    return this.lhcList(null).pipe(map((v, i) => v.filter(d => d.guid == guid).shift()));
  };

  public lhcList(orgUrGuid: string = null): Observable<LhcListDto[]> {
    if (this._ciselnikLhc != null && this._orgUrGuid == orgUrGuid) {
      return of(this._ciselnikLhc);
    }
    this._orgUrGuid = orgUrGuid;

    var request = new EventEmitter<LhcListDto[]>();

    this.http.get(this.apiLhc + "?orgUrGuid=" + orgUrGuid).subscribe(res => {
      this._ciselnikLhc = res;
      request.emit(this._ciselnikLhc);
    });

    return request;
  };


  public jprl(lhcKod: number, jprl: string): Observable<JprlInfoDto> {
    return this.jprlList(lhcKod, jprl).pipe(map((v, i) => v.filter(j => j.jprl == jprl).shift()));
  };

  public jprlList(lhcKod: number, jprl: string): Observable<JprlInfoDto[]> {

    var parsed = this.jprlUtils.parseJprl(jprl);

    var index: string = (lhcKod + "_" + parsed.odd + parsed.dil).toLowerCase();
    if (this._ciselnikJprl != null && this._ciselnikJprl[index]) {
      return of(this._ciselnikJprl[index]);
    }

    var request = new EventEmitter<JprlInfoDto[]>();

    var param = new HttpParams();
    param = param.append("lhcKod", lhcKod.toString());
    param = param.append("odd", parsed.odd);
    param = param.append("dil", parsed.dil);
    this.http.get<JprlInfoDto>(this.apiJprl, { params: param }).subscribe(res => {
      this._ciselnikJprl[index] = res;
      request.emit(this._ciselnikJprl[index]);
    });

    return request;
  };

  public pskList(lhcGuid: string, jprl: string): Observable<PskInfoDto[]> {
    var parsed = this.jprlUtils.parseJprl(jprl);

    if (!parsed.odd || !parsed.dil) {
      return of([]);
    }

    var index: string = (lhcGuid + "_" + parsed.odd + parsed.dil).toLowerCase();
    if (this._ciselnikJprl != null && this._ciselnikJprl[index]) {
      return of(this._ciselnikJprl[index]);
    }

    var param = new HttpParams();
    param = param.append("lhcGuid", lhcGuid);
    param = param.append("odd", parsed.odd);
    param = param.append("dil", parsed.dil);

    return this.http.get<PskInfoDto[]>(this.apiPskInfo, { params: param })
      .pipe(
        tap(res => this._ciselnikJprl[index] = res)
      );
  }

  public etazDetail(guid: string): Observable<EtazDetailDto> {
    return this.http.get<EtazDetailDto>(this.apiEtazDetail + "/" + guid);
  }


  /**
   * Vrátí dostupné porostní mapy pro přihlášeného uživatele.
   */
  getMapPorostni(): Observable<LayerDefinitionDto[]> {
    return this.http.get<LayerDefinitionDto[]>(this.apiGetMapPorostni);
  }
}
