/**
 * Typové chování vrstvy.
**/
export enum LayerBehaviourType {
  //základní vrstva, její zobrazení ovlivňuje jiné základní vrstvy,
  //tj. vrstva není zobrazena současně s jinou základní vrstvou ze stejné skupiny {LayerSourceDto.groupName}.
  //prezentována jako radio-button
  baseLayer,

  //překryvná vrstva
  //prezentována jako check-box
  //výjimka ISLH mapy - prezentovány jako check-boxy, ale ovlivňují své ostatní varianty v rámci LHC.
  overlayLayer
}
