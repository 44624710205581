import { Component } from '@angular/core';
import { Feature } from "ol";
import { faUndo } from '@fortawesome/free-solid-svg-icons';
import { OffsetlInfoDto } from '../../../Dto/Mapa/OffsetlInfoDto';
import { MapInteractionService } from '../../../Services/Mapa/map-interaction.service';
import { LineString, MultiPolygon, Polygon } from 'ol/geom';

/**
 * Komponenta s tlačítkem pro obsluhu vrácení provedených změn v geometrii náčrtu.
**/
@Component({
  selector: 'app-map-nacrt-undo',
  templateUrl: './map-nacrt-undo.component.html',
  styleUrls: ['./map-nacrt-undo.component.css']
})
export class MapNacrtUndoComponent {

  constructor(private mapInteractionService: MapInteractionService) {
    this.mapInteractionService.ControlOffset.subscribe(this._offsetHandler.bind(this));
    this.mapInteractionService.ShowUndo.subscribe(args => {
      this._drawingFeature = args.feature;
      this.btnVisible = args.visible;
    });
  }


  faUndo = faUndo;
  calculatedOffset: string = '2rem';
  btnVisible: boolean = false;
  //featura kresleného náčrtu (interakce draw)
  private _drawingFeature: Feature<any> = null;
  //minimální počet souřadnic při kreslení polygonu (interní věc OL)
  private readonly MIN_POLYGON_COORDS_CNT: number = 4;
  //minimální počet souřadnic při kreslení linie (interní věc OL)
  private readonly MIN_LINE_COORDS_CNT: number = 2;


  /**
   * Handler zobrazení/skrytí info panelu mapy. Zajistí odsazení tlačítka.
   * @param value {OffsetlInfoDto} informace k odsazeni tlačítka
   */
  private _offsetHandler(value: OffsetlInfoDto): void {
    if (value.panel == 'infoPanel') {
      this.calculatedOffset = 'calc(2rem + ' + value.offset + ')';
    }
  }



  /**
   * Klik na tlačítko undo.
  **/
  undoClick(): void {
    if (this._drawingFeature != void 0) {
      this.mapInteractionService.undoDraw();
      let geom = this._drawingFeature.getGeometry();
      if (geom instanceof MultiPolygon || geom instanceof Polygon) {
        this.btnVisible = this._drawingFeature.getGeometry().flatCoordinates.length > this.MIN_POLYGON_COORDS_CNT;
      }
      else if (geom instanceof LineString) {
        this.btnVisible = this._drawingFeature.getGeometry().flatCoordinates.length > this.MIN_LINE_COORDS_CNT;
      }
    }
    else {
      this.mapInteractionService.undoEdit();
    }
  }
}
