<p-toast position="top-center" key="{{ToastKey}}"></p-toast>
<p-panel>
  <ng-template pTemplate="header">
    <div class="p-grid p-align-center">
      <div class="p-col"><span class="p-panel-title">Správa záznamu LHE</span></div>
      <div class="p-col right">
        <button pButton type="button"  class="icon-btn" label="Přidat" [disabled]="!form.valid"
                pTooltip="Obsah formuláře bude uložen jako nový záznam." tooltipPosition="top" 
                (click)="add()">
          <fa-icon [icon]="faPlus"></fa-icon>
        </button>
        <button pButton type="button" label="Uložit" class="icon-btn p-button-success"
                [disabled]="!form.valid||!form.dirty||disabledSaveBtn||selectedData==null"
                pTooltip="Uložit změny záznamu LHE." tooltipPosition="top" 
                (click)="update()">
          <fa-icon [icon]="faSave"></fa-icon>
        </button>
        <button pButton type="button" label="Zrušit" class="icon-btn"
                pTooltip="Vyprázdnit formulář, změny ve formuláři nebudou uloženy." tooltipPosition="top" 
                (click)="cancel()">
          <fa-icon [icon]="faTimes"></fa-icon>
        </button>
        <button pButton type="button" class="icon-btn p-button-secondary" label="Smazat" [disabled]="selectedData==null"
                pTooltip="Smazat záznam LHE." tooltipPosition="top" 
                (click)="delete($event)">
          <fa-icon [icon]="faTrash"></fa-icon>
        </button>
      </div>
    </div>
  </ng-template>
  <div class="p-grid validated-form-container" [formGroup]="form">
    <div class="p-col-2" >
      Činnost
      <app-ciselnik-dropdown  [ciselnikData]="selectedCinnosti" formControlName="lheCinnostGuid" (ngModelChange)="onLheCinnostChange($event)" ></app-ciselnik-dropdown>
    </div>
    <div class="p-col-2">
      Datum
      <p-calendar [style]="{'width':'100%'}" formControlName="datum" view="month" dateFormat="mm.yy" [yearNavigator]="true" yearRange="1991:2050" ></p-calendar>
    </div>
    <div class="p-col-2">
      Plocha
      <input type="number" pInputText  formControlName="plocha" />
    </div>
    <div class="p-col-2">
      Množství
      <input type="number" pInputText  formControlName="mnozstvi"/>
    </div>
    <div class="p-col-2" >
     Dřevina
      <app-ciselnik-dropdown ciselnik="CDrevin" formControlName="drevinaGuid" [nullable]="true"></app-ciselnik-dropdown>
    </div>
    <div class="p-col-2">
      Index holiny
      <input type="number" pInputText  formControlName="indexHoliny"/>
    </div>
  </div>
</p-panel>

<p-confirmPopup key="{{ConfirmKey}}"></p-confirmPopup>
