import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faBackspace, faEllipsisV, faUndo } from '@fortawesome/free-solid-svg-icons';
import { MessageService, SelectItem } from 'primeng/api';
import { CiselnikTypEnum } from '../../../Dto/Core/CiselnikTypEnum';
import { CiselnikUserSeznamDto } from '../../../Dto/Core/CiselnikUserSeznamDto';
import { CiselnikyAdminService } from '../../../Services/Core/ciselniky-admin.service';
import { MessagesUtils } from '../../../Utils/Shared/messages.utils';

/**
 * Komponenta pro správu číselníků
 **/
@Component({
  selector: 'app-ciselniky-list',
  templateUrl: './ciselniky-list.component.html',
  styleUrls: ['./ciselniky-list.component.css']
})
export class CiselnikyListComponent implements OnInit {

  constructor(private router: Router,
    private messageService: MessageService,
    private ciselnikyAdminService: CiselnikyAdminService) {
    this.typyCiselniku = [
      { value: null, label: "Všechny typy číselníků" },
      { value: CiselnikTypEnum.Systemovy, label: "Systémový" },
      { value: CiselnikTypEnum.Globalni, label: "Globální" },
      { value: CiselnikTypEnum.Zakaznicky, label: "Zákaznický" }];
  }

  ngOnInit(): void {
    this.loadCiselniky();
  }

  faBackspace = faBackspace;
  faUndo = faUndo;
  faEllipsisV = faEllipsisV;

  public readonly CISELNIKY_LIST_TOAST = "ciselniky-list-toast";
  public block: boolean = false;
  public typyCiselniku: SelectItem[];
  public cols: any[] = [];
  public items: CiselnikUserSeznamDto[];

  /**
   * Hodnota globálního filtru
   */
  public globalFilter: string;

  /**
   * Navigace na úvodní stránku
   */
  public back(): void {
    this.router.navigate(["/"]);
  }

  public loadCiselniky() {
    this.block = true;
    this.ciselnikyAdminService.getSeznamCiselniku().subscribe(res => {
      this.items = res.sort((a, b) => ('' + a.nazev).localeCompare(b.nazev));//v základu seřadit podle názvu
      this.block = false;
    }, error => {
      this.messageService.add({
        key: this.CISELNIKY_LIST_TOAST, summary: 'Chyba', severity: 'error',
        detail: 'Při načítání číselníků došlo k chybě.',
        life: MessagesUtils.TOAST_LIFE
      });
      this.block = false;
    });
  }

  /**
   * Handler výběru řádku. Přesměruje na editaci.
   * @param event
   */
  public onRowSelected(event: any): void {
    if (event.data != void 0) this.router.navigate(['config/ciselniky/edit', event.data.nazev, event.data.guid]);
  }

  /**
   * Vrátí popis typu podle kódu
   * @param kod
   */
  public getTypPopisByKod(kod: number): string {
    return this.typyCiselniku.find(x => x.value == kod)?.label;
  }
}
