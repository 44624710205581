import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { AuthUtils } from '../../Auth/auth.utils';
import { AuthResultDto } from '../../Dto/Auth/AuthResultDto';
import { ChangePassDto } from '../../Dto/Auth/ChangePassDto';
import { CredentialsDto } from '../../Dto/Auth/CredentialsDto';
import { RegisterCredentialsDto } from '../../Dto/Auth/RegisterCredentialsDto';
import { TokenDto } from '../../Dto/Auth/TokenDto';
import { UserInfoDto } from '../../Dto/Auth/UserInfoDto';
import { ResultInfoGenericDto } from '../../Dto/Shared/ResultInfoDto';
import { MessagesUtils } from '../../Utils/Shared/messages.utils';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private authUtils: AuthUtils,
    private messageUtils: MessagesUtils,

  ) { }

  private apiLogin = 'api/Auth/Login';
  private apiRegister = 'api/Auth/Register';
  public readonly apiSignout = 'api/Auth/Signout';
  private apiChangePass = 'api/Auth/ChangePass';
  private apiLoged = 'api/Auth/IsLogged';
  private apiRefreshToken = 'api/Auth/RefreshToken';

  /**
   * Eventa po provedeném odhlášení
   */
  public loggedOut: EventEmitter<any> = new EventEmitter();

  login(credentialsDto: CredentialsDto): Observable<AuthResultDto> {
    return this.http.post(this.apiLogin, credentialsDto, {})
      .pipe(
        catchError(this.handleError('login', [])),
        map((res: AuthResultDto) => {
          if (res.token != void 0) res.token = JSON.parse(res.token.toString());//ze serveru chodí string
          return res;
        })
      );
  };


  register(registerCredentialsDto: RegisterCredentialsDto): Observable<any> {
    return this.http.post(this.apiRegister, registerCredentialsDto, {});
  };


  changePass(changePassDto: ChangePassDto): Observable<any> {
    return this.http.post(this.apiChangePass, changePassDto, {})
      .pipe(
        catchError(this.handleError('changePass', []))
      );
  };

  isLoged(): Observable<any> {
    return this.http.get(this.apiLoged, {})
      .pipe(
        catchError(this.handleError('changePass', []))
      );
  };

  signout(): Observable<any> {
    return this.http.post(this.apiSignout, null, {})
      .pipe(
        catchError(this.handleError('logout', []))
      );
  }


  ///test
  goToPolicyRestected(): Observable<any> {
    return this.http.get('api/Auth/TestPolicyAuth', {});
  };

  goToRoleRestected(): Observable<any> {
    return this.http.get('api/Auth/TestRoleAuth', {});
  };
  goToPublic(): Observable<any> {
    return this.http.get('api/Auth/TestPublic', {});
  };

  info(): Observable<UserInfoDto> {
    return this.http.get<UserInfoDto>('api/Auth/info', {});
  };

  /**
   * Získá všechny org. ur. uživatele
   * */
  userOrgUrs(): Observable<ResultInfoGenericDto<string[]>> {
    return this.http.get<ResultInfoGenericDto<string[]>>('api/Auth/FindUserOrgUrs', {});
  };

  /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      //this.messageService.add(`LhcService: ${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /**
   * Provede refresh jwt tokenu, pokud je uživatel přihlášený
   * @param refreshToken
   */
  public refreshToken(refreshToken: string): Observable<any> {
    return this.http.post(this.apiRefreshToken, JSON.stringify(refreshToken), { headers: new HttpHeaders({ 'content-type': 'text/json' }) })
      .pipe(catchError(this.handleError('refreshToken', [])));
  }
}
