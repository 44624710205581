<p-toolbar [style]="{'margin-bottom': '1em'}">
  <div class="p-toolbar-group-left">
    <label for="obdobi" style="position: relative;top: 1.3px;">
      Škody zvěří v období:
    </label>
    <p-dropdown id="obdobi" [options]="obdobi" [(ngModel)]="selectedObdobi" optionLabel="nazev"
                [style]="{'min-width':'250px'}"
                (onChange)="obdobiChanged()">
      <ng-template let-item pTemplate="selectedItem">
        {{item?.value?.typ == 's' ? 'Škody' : 'Újmy'}} - {{item?.value?.nazev}}
      </ng-template>
      <ng-template let-obd pTemplate="item">
        {{obd?.value?.typ == 's' ? 'Škody' : 'Újmy'}} - {{obd?.value?.nazev}}
      </ng-template>
    </p-dropdown>
  </div>
  <div class="p-toolbar-group-right">
    <button pButton type="button" label="Nový řádek" (click)="newRow()" [ngClass]="'icon-btn'"><fa-icon [icon]="faPlus"></fa-icon></button>
  </div>
</p-toolbar>

<div style="margin-bottom:2em;">
  <propla-table #skodyTable [cols]="skodyCols" [allItems]="skodyItems" [selectionMode]="'single'"
                (rowSelected)="onRowSelected($event)" (rowUnSelected)="onRowUnSelected($event)"
                class="overflow-parent"></propla-table>
</div>
<skody-detail #detail [ngStyle]="{'visibility': (isNewRow || isRowSelected) ? 'visible' : 'hidden', 'margin-top': '2em'}"
              [vypocetId]="vypocetId" [selectedObdobi]="selectedObdobi"
              (skodaSaved)="skodaSavedHandler()"></skody-detail>
