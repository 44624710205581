import { Component, Input } from '@angular/core';
import { OpDetailDto } from '../../../Dto/Lhp/OpDetailDto';

@Component({
  selector: 'app-kniha-detail-op',
  templateUrl: './kniha-detail-op.component.html',
  styleUrls: ['./kniha-detail-op.component.css']
})
export class KnihaDetailOpComponent {

  constructor() { }

  @Input() op: OpDetailDto;

}
