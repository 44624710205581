import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-vyroba-help',
  templateUrl: './vyroba-help.component.html',
  styleUrls: ['./vyroba-help.component.css']
})
export class VyrobaHelpComponent {

  @Input() coKopiruje: string;

  constructor() { }

}
