import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { ResultInfoGenericDto } from '../../Dto/Shared/ResultInfoDto';
import { CSvatekListDto } from '../../Dto/Vyroba/CSvatekListDto';

@Injectable({
  providedIn: 'root'
})
export class SvatkyService {
  constructor(
    private http: HttpClient
  ) { }

  private apiSvatkyList: string = 'api/vyroba/Svatek/List';

  /**
   * Vrátí všechny svátky v roce
   * @param rok
   */
  list(rok: number): Observable<ResultInfoGenericDto<CSvatekListDto[]>> {
    var param = new HttpParams();
    param = param.append('rok', rok.toString());
    return this.http.get<ResultInfoGenericDto<CSvatekListDto[]>>(this.apiSvatkyList, { params: param });
  }
}
