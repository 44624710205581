import { OnInit, Output, EventEmitter, Input, Directive } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { VypocetDetailDto } from 'src/app/Dto/Skody/VypocetDetailDto';
import { LhpEtazProVypocetDto } from 'src/app/Dto/Skody/LhpEtazProVypocetDto';
import { LhpDrevinaProVypocetDto } from 'src/app/Dto/Skody/LhpDrevinaProVypocetDto';


/**
 * Base komponenta pro detaily škod dle typu škody.
**/
@Directive()
export abstract class SkodyDetailSBaseComponent implements OnInit{

  constructor(
    protected fb: FormBuilder
  ) { }

  ngOnInit() {}

  /**
   * Eventa předávající aktualizovaná data škody.
  **/
  @Output() dataChanged: EventEmitter<any> = new EventEmitter();

  _skoda: VypocetDetailDto;
  @Input() set skoda(value: VypocetDetailDto) {
    this._skoda = value;
    this.data.reset();
    this.populateForm(this._skoda);
  }

  @Input() newRow: boolean = false;

  @Input() set lhpEtaz(value: LhpEtazProVypocetDto) {
    this._skoda.lhpEtaz = value
    if (this.newRow && value != null) this.kopirujLhpEtazDoVstupu(this._skoda.lhpEtaz);
  }

  @Input() set lhpDrevina(value: LhpDrevinaProVypocetDto) {
    this._skoda.lhpDrevina = value;
    if (this.newRow && value != null) this.kopirujLhpDrevinuDoVstupu(this._skoda.lhpDrevina);
  }


  /**
   * Formulář škody.
  **/
  protected abstract data: FormGroup;
  
  /**
   * Kontrola dat z S6 pro přepočet škody.
   * @param data
   */
  abstract validateVstupyProPrepocet(data): boolean;


  /**
   * Zkopíruje data etáže z LHP do vstupu.
  **/
  abstract kopirujLhpEtazDoVstupu(dto: LhpEtazProVypocetDto): void;


  /**
   * Zkopíruje data dřeviny z LHP do vstupu.
  **/
  abstract kopirujLhpDrevinuDoVstupu(dto: LhpDrevinaProVypocetDto): void;


  /**
   * Naplnění modelu formuláře daty.
   * @param skoda
   */
  populateForm(skoda: VypocetDetailDto): void {
    var that = this;
    Object.keys(this.data.value).forEach(function (key) {
      that.data.get(key).setValue(skoda[key]);
    });
  };
}
