import { Injectable, EventEmitter, Output } from "@angular/core";

/**
 * Service předávající informace o načtení odpovědi z loaderů vrstev.
**/
@Injectable({
  providedIn: 'root'
})
export class FeatureLoaderInteractionService {

  /**
   * Událost informující o načtení dat vrsty loaderem.
   * Předává název vrstvy.
  **/
  @Output() FeaturesLoaded: EventEmitter<string> = new EventEmitter<string>();


  /**
   * Vypuštění události o načtení dat loaderem.
   * @param layerName {string} Název vrstvy, kde došlo k načtení odpovědi loaderu.
   */
  featuresLoaded(layerName: string): void {
    this.FeaturesLoaded.emit(layerName);
  }
}
