<p-toast position="top-center" key="user-info-toast"></p-toast>
<div class="pds-user-info" *ngIf="loaded">
  <div class="p-component ui-corner-all">
    <div class="p-grid p-align-center">
      <div class="p-col-2 center">
        <fa-icon [icon]="faUser" size="2x" [styles]="{'color': '#a6a6a6'}"></fa-icon>
      </div>
      <div class="p-col-6">
        <p>
          <strong>{{info.firstName}} {{info.lastName}}</strong>
        </p>
        <p>
          <fa-icon [icon]="faBuilding" pTooltip="Organizace a organizační úroveň" tooltipPosition="bottom"></fa-icon> {{info.company}},
          <app-org-ur-display [guid]="info?.orgUrGuid"></app-org-ur-display>
        </p>
      </div>
      <div class="p-col-4 right">
        <button pButton (click)="menu.toggle($event)" class="icon-btn hide-label-button" [ngClass]="{'p-button-warning': releaseNotesAlert}" [disabled]="visibleInfoPanel != null">
          <fa-layers>
            <fa-icon [icon]="faQuestion"></fa-icon>
            <fa-layers-counter content="" *ngIf="releaseNotesAlert"></fa-layers-counter>
          </fa-layers>
        </button>
        <button pButton title="Změnit heslo" (click)="changePass()" class="icon-btn hide-label-button"><fa-icon [icon]="faCog"></fa-icon></button>
        <button pButton title="Odhlásit" (click)="signOut()" class="p-button-danger icon-btn hide-label-button"><fa-icon [icon]="faSignOutAlt"></fa-icon></button>
      </div>
    </div>
  </div>
</div>

<p-menu #menu [popup]="true" [model]="items"></p-menu>

<p-overlayPanel #op>
  <ng-template pTemplate>
  </ng-template>
</p-overlayPanel>

<p-sidebar [visible]="visibleInfoPanel == 'releaseNotes'" styleClass="user-info-sidebar" [modal]="false" [dismissible]="false" [showCloseIcon]="false" position="right">
  <button pButton type="button" icon="pi pi-times" class="p-button-rounded p-button-secondary p-button-text sidebar-close-btn" (click)="visibleInfoPanel = null"></button>
  <h2>Novinky v aplikaci</h2>
  <app-release-notes (dataLoaded)="releaseNotesLoaded($event)" [visitedReleaseVersion]="visitedReleaseVersion"></app-release-notes>
</p-sidebar>

<p-sidebar [visible]="visibleInfoPanel == 'permissions'" styleClass="user-info-sidebar" [modal]="false" [dismissible]="false" [showCloseIcon]="false" position="right">
  <button pButton type="button" icon="pi pi-times" class="p-button-rounded p-button-secondary p-button-text sidebar-close-btn" (click)="visibleInfoPanel = null"></button>
  <h2>Přístupová práva</h2>
  <div class="p-grid">
    <div class="p-col-6">
      <p-panel header="Oprávnění">
        <ul>
          <li *ngFor="let role of info?.roles">{{role}}</li>
        </ul>
      </p-panel>
    </div>
    <div class="p-col-6">
      <p-panel header="Organizační úrovně">
        <b>Domovská org. úroveň: </b>
        <app-org-ur-display [guid]="info?.orgUrGuid"></app-org-ur-display>
        <p><b>Další org. úrovně:</b></p>
        <ul>
          <ng-container *ngFor="let orgUrGuid of info?.orgUrGuids">
            <li *ngIf="orgUrGuid != info?.orgUrGuid"><app-org-ur-display [guid]="orgUrGuid"></app-org-ur-display></li>
          </ng-container>
        </ul>
      </p-panel>
    </div>
  </div>
</p-sidebar>

<p-sidebar [visible]="visibleInfoPanel == 'help'" styleClass="user-info-sidebar" [modal]="false" [dismissible]="false" [showCloseIcon]="false" position="right">
  <button pButton type="button" icon="pi pi-times" class="p-button-rounded p-button-secondary p-button-text sidebar-close-btn" (click)="visibleInfoPanel = null"></button>
  <h2>Nápověda</h2>
  <p>Nápovědu připravujeme. Děkujeme za trpělivost.</p>
</p-sidebar>

<p-sidebar [visible]="visibleInfoPanel == 'about'" styleClass="user-info-sidebar" [modal]="false" [dismissible]="false" [showCloseIcon]="false" position="right">
  <button pButton type="button" icon="pi pi-times" class="p-button-rounded p-button-secondary p-button-text sidebar-close-btn" (click)="visibleInfoPanel = null"></button>
  <h2>O aplikaci</h2>
  <h1><img alt="Pds" src="assets/logo.png"> ProPla</h1>
  <p-card [style]="{'width': '40rem', 'margin': '0 auto'}">
    <h2>Kompletní systém pro vlastníka lesa a jeho hospodáře</h2>
    <p>Aplikaci vyvíjí a dodává <b>PDS s.r.o.</b>, IČO: 25523121</p>
    <p>
      Web: <a href="https://www.pds.eu" target="_blank">www.pds.eu</a> |
      Kancelář: <a href="https://mapy.cz/zakladni?x=16.6185925&y=49.1926575&z=17&source=addr&id=8901802">Vlhká 194/25, 602 00 Brno-Trnitá</a>
    </p>
    <p>Uživatelská podpora: <a href="mailto:dohled.lesy@pds.eu">dohled.lesy@pds.eu</a></p>
  </p-card>
</p-sidebar>
