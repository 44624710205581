import { Component, OnInit } from '@angular/core';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LheSchvalWorkflowDto } from '../../../Dto/Lhe/LheSchvalWorkflowDto';
import { MessageService } from 'primeng/api';
import { SyncService } from '../../../Services/Sync/sync.service';
import { DHolinaSyncDto } from '../../../Dto/Lhe/DHolinaSyncDto';
import { NacrtySchvalitInteractionService } from '../../../Services/Nacrty/nacrty-schvalit-interaction.service';
import { ISchvalWorkflowDto } from '../../../Dto/Nacrty/ISchvalWorkflowDto';

/**
 * Komponenta postprocesního zpracování náčrtu v modulu LHE. Nabízí uživateli vznik nového záznamu LHE včetně holiny ihned po schválení náčrtu.
**/
@Component({
  selector: 'app-schvalit-lhe',
  templateUrl: './schvalit-lhe.component.html',
  styleUrls: ['./schvalit-lhe.component.css']
})
export class SchvalitLheComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private syncService: SyncService,
    private nacrtySchvalitInteractionService: NacrtySchvalitInteractionService,
    private messageService: MessageService)
  { }

  ngOnInit() {
    this.nacrtySchvalitInteractionService.SchvaleniPostprocess.subscribe(this._schvalitPostprocessHandler.bind(this));

    let thisYear = new Date().getFullYear();
    this.maxDate = new Date(thisYear, 11, 31);
    this.minDate = new Date(thisYear - 1, 0, 1);
    this.yearRangeLhe = this.minDate.getFullYear() + ':' + this.maxDate.getFullYear();
  }

  //id notifikační service - zde se posílá do notifikací mapy
  readonly TOAST_KEY: string = 'mapContainerToastKey';

  //ikony
  faCheck = faCheck;
  faTimes = faTimes;

  //viditelnosti komponenty
  visible: boolean = false;

  //blokování komponenty
  blocked: boolean = false;

  //podkladová data pro vytvoření záznamu LHE/Holiny
  private _schvalData: LheSchvalWorkflowDto = null;

  //rozpětí rolů pro které je možné typovat lhe
  yearRangeLhe: string = '';
  maxDate: Date;
  minDate: Date;
  
  //formulář komponenty
  form: FormGroup = this.formBuilder.group({
    datum: ['', Validators.required],
    indexHoliny: ['', Validators.required]
  });

  get datum(): AbstractControl {
    return this.form.get('datum');
  }
  get indexHoliny(): AbstractControl {
    return this.form.get('indexHoliny');
  }

  /**
   * Handler post procesního zpracování náčrtu v modulu LHE.
   * @param data {ISchvalWorkflowDto[]} podkladová data pro vytvoření záznamu LHE/Holiny
   */
  private _schvalitPostprocessHandler(data: ISchvalWorkflowDto[]): void {
    let lheSchvalData = data.find(x => x.modul == 'Lhe');
    if (lheSchvalData != void 0) {
      this._schvalData = lheSchvalData as LheSchvalWorkflowDto;
      this._fillForm(this._schvalData);
      this.visible = true;
    }
  }


  /**
   * Naplnění formuláře daty.
   * @param data {LheSchvalWorkflowDto} data postprocesingu schválení náčrtu
   */
  private _fillForm(data: LheSchvalWorkflowDto): void {
    this.form.get('indexHoliny').setValue(data.holina.indexHoliny);
    this.form.get('datum').setValue(new Date(data.holina.rok + '-' + data.holina.mesic));
    this.form.markAsPristine();
  }


  /**
   * Uložení nově vzniklého záznamu LHE/Holiny.
  **/
  save(): void {
    if (this.form.valid) {
      this.blocked = true;

      let req: any[] = [];
      let holina = this._toHolinaData(this._schvalData, this.form.value);
      req.push(holina);

      this.syncService.post(req).subscribe(resp => {
        this._savePostprocess(resp);
      });
    }
  }


  /**
   * Zpracování odpovědi sync requestu na uložení LHE/Holiny.
   * @param resp {any} odpověď sync post requestu.
   */
  private _savePostprocess(resp: any): void {
    if (resp.isError) {
      let messages = resp.errors.map(err => { return { severity: 'error', summary: 'Chyba', detail: err.text, key: this.TOAST_KEY }; });
      this.messageService.addAll(messages);
    }
    else if (resp.isConflict) {
      this.messageService.add({ severity: 'error', summary: 'Chyba', detail: 'Při aktualizaci záznamu došlo ke konfliktu. Změny nebyly uloženy.', key: this.TOAST_KEY });
    }
    else {
      this.messageService.add({ severity: 'success', summary: 'Úspěch', detail: 'Změny byly úspěšně uloženy.', key: this.TOAST_KEY });
      this._clear();
    }
    this.blocked = false;
  }


  /**
   * Vrátí sync data upraveného záznamu holiny.
   * @param schvalData {LheSchvalWorkflowDto} podkladová data pro aktualizaci záznamu holiny
   * @param formValue {any} aktuální data formuláře
  **/
  private _toHolinaData(schvalData: LheSchvalWorkflowDto, formValue: any): DHolinaSyncDto {
    return {
      guid: schvalData.holina.guid,
      pskGuid: schvalData.holina.pskGuid,
      nacrtGuid: schvalData.nacrtGuid,
      indexHoliny: formValue.indexHoliny,
      rok: formValue.datum.getFullYear(),
      mesic: formValue.datum.getMonth() + 1,
      sync: schvalData.holina.sync,
      type: 'DHolina'
    }
  }


  /**
   * Zavření/skrytí formuláře editace holiny.
   */
  close(): void {
    this._clear();
  }


  /**
   * Vyčištění dat komponenty.
  **/
  private _clear(): void {
    this.form.reset();
    this._schvalData = null;
    this.visible = false;
  }
}
