<form [formGroup]="data">
  <p-panel header="Vstupy pro výpočet" [style]="{'margin-bottom': '1em'}">
    <!-- Tady je použité vlastní grid pro 8 sloupců - je založen na principu FlexGridu Primeng. -->
    <div class="p-grid p-align-center">
      <div class="p-8-col-1 p-8-offset-1">Plocha (ha)</div>
      <div class="p-8-col-1">Věk</div>
      <div class="p-8-col-1">Zakmenění</div>
      <div class="p-8-col-1">Zastoupení</div>
      <div class="p-8-col-1">BonR</div>
      <div class="p-8-col-1">Úprava HLPa</div>
      <div class="p-8-col-1">Objem k rozpadu</div>
    </div>
    <div class="p-grid p-align-center">
      <div class="p-8-col-1">
        LHP
      </div>
      <div class="p-8-col-1">
        <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{_skoda?.lhpEtaz?.etazPs}}</span>
      </div>
      <div class="p-8-col-1">
        <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{_skoda?.lhpEtaz?.vek}}</span>
      </div>
      <div class="p-8-col-1">
        <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{_skoda?.lhpEtaz?.zakm}}</span>
      </div>
      <div class="p-8-col-1">
        <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{_skoda?.lhpDrevina?.zast}}</span>
      </div>
      <div class="p-8-col-1">
        <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{_skoda?.lhpDrevina?.bonR}}</span>
      </div>
    </div>
    <div class="p-grid p-align-center">
      <div class="p-8-col-1">
        Vstup
      </div>
      <div class="p-8-col-1">
        <input pInputText type="number" formControlName="plocha" />
      </div>
      <div class="p-8-col-1">
        <input pInputText type="number" formControlName="vek" />
      </div>
      <div class="p-8-col-1">
        <input pInputText type="number" formControlName="zakm" />
      </div>
      <div class="p-8-col-1">
        <input pInputText type="number" formControlName="zast" />
      </div>
      <div class="p-8-col-1">
        <input pInputText type="number" formControlName="bonR" />
      </div>
      <div class="p-8-col-1">
        <input pInputText type="number" formControlName="upravaHlpa" />
      </div>
      <div class="p-8-col-1">
        <input pInputText type="number" formControlName="objem"
               pTooltip="Objem stromů ponechaných fyzickému rozpadu v m3." tooltipPosition="top" />
      </div>
    </div>
  </p-panel>
</form>
