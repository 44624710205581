import { Component, Input, EventEmitter, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UzaverkyService } from 'src/app/Services/Vyroba/uzaverky.service';
import { MessagesUtils } from 'src/app/Utils/Shared/messages.utils';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { DVyrUzaverkyInitDto } from 'src/app/Dto/Vyroba/DVyrUzaverkyListDto';

/**
 * Komponenta pro inicializaci uzávěrek.
**/
@Component({
  selector: 'app-vyroba-uzaverky-init',
  templateUrl: './vyroba-uzaverky-init.component.html',
  styleUrls: ['./vyroba-uzaverky-init.component.css']
})
export class VyrobaUzaverkyInitComponent {

  private TOAST_KEY: string = "vyroba-uzaverky-toast";
  @Input() visible: boolean;
  @Output() reloadUzaverky: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private formBuilder: FormBuilder,
    private uzaverkyService: UzaverkyService,
    private messagesUtils: MessagesUtils
  ) { }

  faSave = faSave;
  faTimes = faTimes;
  block: boolean = false;


  form = this.formBuilder.group({
    orgUrGuid: [''],
    obdobiOd: ['', Validators.required],
    obdobiDo: ['', Validators.required],
    datumHajny: [''],
    datumPolesny: [''],
    datumMetodik: ['']
  });


  /**
   * Inicializace (vznik nových) uzávěrek.
  **/
  init(): void {
    let values = this.form.value;
    
    this.block = true;
    this.uzaverkyService.init((values as DVyrUzaverkyInitDto), values.orgUrGuid).subscribe(resp => {
      if (resp.success) {
        this.clear();
        this.reloadUzaverky.emit();
      }
      this.block = false;
      this.messagesUtils.showResponseMessage(this.TOAST_KEY, resp);
    });
  }


  /**
   * Zrušení inicializace/vymaže formulář.
  **/
  cancel(): void {
    this.clear();
  }


  /**
   * Vymaže formulář.
  **/
  clear(): void {
    this.form.reset();
  }
}
