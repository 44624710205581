<p-galleria [value]="photoSources"
            [showThumbnails]="false" [showIndicators]="true"
            [circular]="true" [showItemNavigators]="true" [showItemNavigatorsOnHover]="true"
            [(activeIndex)]="activeIndex">
  <ng-template pTemplate="item" let-item>
    <img [src]="item.src" [alt]="item.nazev" class="gallery-imge"/>
  </ng-template>
</p-galleria>

<label for="addPhotoBtn" class="icon-btn p-button p-component add-photo-btn"
        pTooltip="Přidat fotografii" tooltipPosition="bottom">
  <fa-layers [fixedWidth]="true">
    <fa-icon [icon]="faImage" size="lg" transform="up-2 left-2"></fa-icon>
    <fa-icon [icon]="faPlus" size="xs" transform="down-10 right-14"></fa-icon>
  </fa-layers>
  <span class="p-button-label">Přidat fotografii</span>
</label>
<input #addPhotoBtn id="addPhotoBtn" class="add-photo-btn-input"
       type="file" multiple="multiple" accept="image/*"
       (change)="addPhoto($event)" />

<button pButton type="button" class="icon-btn hide-label-button remove-photo-btn" [disabled]="photoSources.length == 0"
        pTooltip="Smazat fotografii" tooltipPosition="bottom"
        (click)="removePhoto($event)">
  <fa-icon [icon]="faTrash"></fa-icon>
</button>

<p-confirmPopup key="{{CONFIRM_KEY}}"></p-confirmPopup>
