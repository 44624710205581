import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TokenDto } from '../Dto/Auth/TokenDto';

@Injectable({
  providedIn: 'root'
})
export class AuthUtils {
  constructor(
    private http: HttpClient
  ) { }

  public readonly JWT_TOKEN: string = 'jwtToken';
  public readonly WIN_TOKEN: string = 'winToken';
  public readonly REFRESH_TOKEN: string = 'refreshToken';

  /**
   * O kolik ms před expirací se má provést refresh
   */
  public readonly timeToExpMs: number = 5 * 60000;//5 minut

  /**
   * Časový interval v ms pro expiraci win tokenu
   */
  public readonly validIntevalWinMs: number = 20 * 60000;//20 minut

  private _getToken(tokenName: string): TokenDto {
    var storedToken = localStorage.getItem(tokenName);
    if (storedToken != void 0) {
      return JSON.parse(storedToken);
    }
    return null;
  }

  private _setToken(tokenName: string, token: TokenDto): void {
    localStorage.setItem(tokenName, JSON.stringify(token));
  }

  public setJwtToken(token: TokenDto): void {
    this._setToken(this.JWT_TOKEN, token);
  }

  public getJwtToken(): TokenDto {
    return this._getToken(this.JWT_TOKEN);
  }

  public setWinToken(token: TokenDto): void {
    this._setToken(this.WIN_TOKEN, token);
  }

  public getWinToken(): TokenDto {
    return this._getToken(this.WIN_TOKEN);
  }


  public async isLoggedIn(): Promise<boolean> {

    let token = this._getToken(this.JWT_TOKEN);
    let now = new Date().getTime();

    //windowsAuthentication
    if (token == null) {
      token = this._getToken(this.WIN_TOKEN);
    }

    if (token != null && Date.parse(token.valid_until) > now) {
      return true;
    }
    return false;
  }

  public clearToken(tokenName:string): void {
    localStorage.setItem(tokenName, null);
  }

  public setRefreshToken(token: string): void {
    localStorage.setItem(this.REFRESH_TOKEN, token);
  }

  public getRefreshToken(): string {
    return localStorage.getItem(this.REFRESH_TOKEN);
  }
}
