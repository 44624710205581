import { Component, OnInit, Input } from '@angular/core';
import { CiselnikListDto } from 'src/app/Dto/Shared/CiselnikListDto';
import { CiselnikyService } from 'src/app/Services/Shared/ciselniky.service';
import { PodCiselnikListDto } from '../../../Dto/Shared/PodCiselnikListDto';

@Component({
  selector: 'app-ciselnik-field',
  templateUrl: './ciselnik-field.component.html',
  styleUrls: ['./ciselnik-field.component.css']
})
export class CiselnikFieldComponent implements OnInit {

  constructor(private _ciselnikService: CiselnikyService) {
  }

  ciselnik: CiselnikListDto;

  @Input() set guid(value: string) {
    this._guid = value;
    this._load();
  };
  @Input() set kod(value: string) {
    this._kod = value;
    this._load();
  };
  @Input() set key(value: string) {
    this._key = value;
    this._load();
  };
  @Input() label: string;
  @Input() pcolleft: string = "p-col-4";
  @Input() pcolright: string = "p-col-8";

  //Pokud je vyplněný parentKey, tak se bude hledat obsah jako předek pro ciselnik
  @Input() set parentKey(value: string) {
    this._parentKey = value;
    this._load();
  };

  /**
   * Varianta zobrazení podle dostupného prostoru:
   * - 'short' - jen kod
   * - 'full' - kod + název
   */
  @Input() variant: string = "full";

  /**
   * Bublínková nápověda pro popisek
   */
  @Input() tooltip: string;

  private _key: string;
  private _guid: string;
  private _kod: string;
  private _parentKey: string;


  ngOnInit(): void {
  }

  private _load(): void {
    if (this._key != void 0)
      this._ciselnikService.getCislenik(this._key, null).subscribe(res => {
        this.ciselnik = null;//musí se promazat u detailu při překlikávání v tabulce
        if (this._parentKey != void 0) {
          if (this._guid != void 0) {
            var childCiselnik = res.find(res => res.guid == this._guid);
            if (this._parentKey != void 0)
            this._ciselnikService.getCislenik(this._parentKey, null).subscribe(resParent => {
              if ((childCiselnik as PodCiselnikListDto).parentGuid != void 0)
                this.ciselnik = resParent.find(x => x.guid == (childCiselnik as PodCiselnikListDto).parentGuid);
            });
          }
        } else {
          if (this._kod != void 0)
            this.ciselnik = res.find(res => res.kod == this._kod);

          if (this._guid != void 0)
            this.ciselnik = res.find(res => res.guid.toLowerCase() == this._guid.toLowerCase());
        }
    });
  }

}
