import { Component, Input, } from '@angular/core';
import { NacrtMapItemDto } from '../../../Dto/Nacrty/NacrtMapItemDto';
import { NacrtProjektDto } from '../../../Dto/Nacrty/NacrtProjektDto';
import { LayersInteractionService } from '../../../Services/Mapa/layers-interaction.service';
import { NacrtyInteractionService } from '../../../Services/Nacrty/nacrty-interaction.service';
import { SyncService } from '../../../Services/Sync/sync.service';

@Component({
  selector: 'app-nacrt-detail',
  templateUrl: './nacrt-detail.component.html',
  styleUrls: ['./nacrt-detail.component.css'],
  providers: [NacrtyInteractionService, LayersInteractionService]
})
export class NacrtDetailComponent {

  /**
   * Seznam projektů náčrtů.
  **/
  _nacrtProjects: NacrtProjektDto[] = [];
  @Input() set nacrtProjects(value: NacrtProjektDto[]) {
    if (value != void 0) {
      this._nacrtProjects = value;
    }
  }


  @Input() set item(value: NacrtMapItemDto) {
    if (value != void 0) {
      this._item = value;
      this._init(this._item);
    }
    else {
      this.nacrtyInteractionService.clearPrilohy();
    }
  }


  /**
   * Viditelnost komponenty.
  **/
  @Input() visible: boolean = false;


  constructor(
    private nacrtyInteractionService: NacrtyInteractionService,
    private syncService: SyncService)
  { }


  private _item: NacrtMapItemDto = null;
  private _syncData: any = null;
  blocked: boolean = false;


  /**
   * Zahájení prohlížení detailu náčrtu.
   * @param itemToView {NacrtMapItemDto} data náčrtu
   */
  private _init(itemToView: NacrtMapItemDto): void {
    this.blocked = true;
    this.nacrtyInteractionService.clearPrilohy();
    this.nacrtyInteractionService.initView(itemToView);
    this._getNacrtData(itemToView);
  }


  /**
   * Získá data náčrtu přes sync api.
   * @param value {NacrtMapItemDto} náčrt
   */
  private _getNacrtData(value: NacrtMapItemDto): void {
    this.syncService.get(value?.id).subscribe(resp => {
      this._syncData = resp;
      this.blocked = false;
    });
  }


  /**
   * Getter projektu náčrtu.
  **/
  get nacrtProjekt(): string {
    if (this._syncData != void 0) {
      return this._nacrtProjects.filter(x => x.guid == this._syncData.nacrt.nacrtProjektGuid)[0].nazev;
    }
    else {
      return '';
    }
  }


  /**
   * Getter popisu náčrtu.
  **/
  get popis(): string {
    if (this._syncData != void 0) {
      return this._syncData.nacrt.popis;
    }
    else {
      return '';
    }
  }

  
  /**
   * Getter poznámky náčrtu.
  **/
  get poznamka(): string {
    if (this._syncData != void 0) {
      return this._syncData.nacrt.poznamka;
    }
    else {
      return '';
    }
  }
}
