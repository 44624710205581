import { DatePipe } from "@angular/common";
import { Injectable, Pipe, PipeTransform } from "@angular/core";

/**
 * Formát data.
**/
@Pipe({
  name: 'formatDateTime'
})
@Injectable({
  providedIn: 'root'
})
export class FormatDateTimePipe implements PipeTransform {
  transform(value: Date, ...args: any[]): any {
    return new DatePipe('cs-CZ').transform(value, 'd.M.yyyy, H:mm');
  }
}
