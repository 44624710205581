import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UzaverkyService } from 'src/app/Services/Vyroba/uzaverky.service';
import { DVyrUzaverkyListDto } from 'src/app/Dto/Vyroba/DVyrUzaverkyListDto';
import { faUndo, faEllipsisV, faMinus } from '@fortawesome/free-solid-svg-icons';
import { ConfirmationService } from 'primeng/api';
import { MessagesUtils } from 'src/app/Utils/Shared/messages.utils';
import { OrgUrDisplayComponent } from '../../Shared/org-ur-display/org-ur-display.component';

/**
 * Komponenta pro správu uzávěrek.
**/
@Component({
  selector: 'app-vyroba-uzaverky',
  templateUrl: './vyroba-uzaverky.component.html',
  styleUrls: ['./vyroba-uzaverky.component.css']
})
export class VyrobaUzaverkyComponent implements OnInit {

  @ViewChild("colDatum", { static: true }) datumTemplate: TemplateRef<any>;
  @ViewChild("orgUr", { static: true }) orgUrTemplate: OrgUrDisplayComponent;

  constructor(
    private router: Router,
    private uzaverkyService: UzaverkyService,
    private confirmationService: ConfirmationService,
    private messageUtils: MessagesUtils
  ) { }

  faUndo = faUndo;
  faEllipsisV = faEllipsisV;
  faMinus = faMinus;
  cols: any[] = [];
  block: boolean = true;
  initVisible: boolean = true;
  updateVisible: boolean = false;
  items: DVyrUzaverkyListDto[] = [];
  allItems: DVyrUzaverkyListDto[] = [];
  selectedItems: DVyrUzaverkyListDto[] = [];

  private readonly TOAST_KEY: string = "vyroba-uzaverky-toast";
  filter: any;

  ngOnInit(): void {
    this._initCols();
  }


  /**
   * Inicializace sloupců pro grid
   */
  private _initCols() {
    this.cols = [
      { field: 'orgUrGuid', header: 'Organizační úroveň', class: 'col-10', template: this.orgUrTemplate },
      { field: 'obdobiOd', header: 'Období od', class: 'col-10', template: this.datumTemplate },
      { field: 'obdobiDo', header: 'Období do', class: 'col-10', template: this.datumTemplate },
      { field: 'datumHajny', header: 'Haný', class: 'col-10', template: this.datumTemplate },
      { field: 'datumPolesny', header: 'Polesný', class: 'col-10', template: this.datumTemplate },
      { field: 'datumMetodik', header: 'Metodik', class: 'col-10', template: this.datumTemplate },
      { field: 'vlozil', header: 'Vložil', class: 'col-15' },
      { field: 'vlozilKdy', header: 'Vloženo kdy', class: 'col-10', template: this.datumTemplate },
      { field: 'opravil', header: 'Opravil', class: 'col-15' },
      { field: 'opravilKdy', header: 'Opraveno kdy', class: 'col-10', template: this.datumTemplate }
    ];

    this.loadUzaverky();
  }


  /**
   * Handler změny vybraných uzávěrek.
   * @param event
   */
  onSelectionChanged(event): void {
    this.selectedItems = event;
    this.toggleActionPanels();
  }


  /**
   * Přepnutí viditelných panelů:
   * a) jsou vybrané uzávěrky - zobrazí se panel pro editaci data
   * b) nejsou vybrané uzávěrky - zobrazí se panel pro inicializaci nové uzávěrky
  **/
  private toggleActionPanels() {
    if (this.selectedItems.length > 0) {
      this.initVisible = false;
      this.updateVisible = true;
    }
    else {
      this.initVisible = true;
      this.updateVisible = false;
    }
  }


  /**
   * Navigace zpět na výrobu.
  **/
  back(): void {
    this.router.navigate(["/vyroba"]);
  }


  /**
   * Načtení seznamu uzávěrek.
  **/
  loadUzaverky(): void {
    this.selectedItems = [];
    this.uzaverkyService.list().subscribe(resp => {
      this.items = resp;
      this.allItems = this.items.slice();
      this.block = false;
    });
  }


  /**
   * Přenačtení seznamu uzávěrk.
  **/
  reloadUzaverky(): void {
    this.block = true;
    this.loadUzaverky();
    this.toggleActionPanels();
  }


  /**
   * Filtrace uzávěrek dle organizační úrovně.
   * @param event
   */
  filtruj(event): void {
    this.items = this.allItems.slice();
    if (event != void 0) {
      this.uzaverkyService.getTreeOfGuids(event).subscribe(resp => {
        this.items = this.items.filter(item => resp.includes(item.orgUrGuid));
      });
    }
  }

  /**
   * Smaže vybrané řadky
   * */
  remove(): void {
    this.confirmationService.confirm({
      message: 'Opravdu si přejete smazat vybrané řádky?',
      accept: () => {
        this.block = true;
        this.uzaverkyService.delete(this.selectedItems.map(x => x.guid))
          .subscribe(resp => {
            if (resp.success) {
              this.selectedItems = null;
              this.loadUzaverky();
              this.toggleActionPanels();
              resp.messages = ["Řádky byly smazány."];
            }
            this.messageUtils.showResponseMessage(this.TOAST_KEY, resp)
          });
      }
    });
  }
}
