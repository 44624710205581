<form>
  <app-block-ui [block]="blockedPrepocet" [target]="krokyS6"></app-block-ui>
  <p-panel #krokyS6 header="Kroky výpočtu" [style]="{'margin-bottom': '1em'}"
           [toggleable]="true" [collapsed]="true" toggler="header">
    <div class="p-grid">
      <div class="p-col-10">
        <div class="p-grid p-align-center">
          <div class="p-9-col-1">
            <label>
              Znič. plocha (m<sup>2</sup>)
              <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{zniceniDto?.znicenaPlocha}}</span>
            </label>
          </div>
          <div class="p-9-col-1">
            <label>
              Skupina dřevin
              <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{zniceniDto?.skupinaDrevin3Nazev}}</span>
            </label>
          </div>
          <div class="p-9-col-1">
            <label>
              P0
              <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{zniceniDto?.koeficienty?.p0}}</span>
            </label>
          </div>
          <div class="p-9-col-1">
            <label>
              P1
              <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{zniceniDto?.koeficienty?.p1}}</span>
            </label>
          </div>
          <div class="p-9-col-1">
            <label>
              P2
              <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{zniceniDto?.koeficienty?.p2}}</span>
            </label>
          </div>
          <div class="p-9-col-1">
            <label>
              P3
              <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{zniceniDto?.koeficienty?.p3}}</span>
            </label>
          </div>
          <div class="p-9-col-1">
            <label>
              P4
              <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{zniceniDto?.koeficienty?.p4}}</span>
            </label>
          </div>
          <div class="p-9-col-1">
            <label>
              THLPa
              <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{zniceniDto?.thlpa}}</span>
            </label>
          </div>
          <div class="p-9-col-1">
            <label>
              HLPa
              <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{zniceniDto?.hlpa}}</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </p-panel>
</form>
