<div class="pds-release-notes-panel">
  <p-timeline [value]="data">
    <ng-template pTemplate="content" let-event>
      <div [innerHTML]="event.releaseNotes"></div>
      <p-divider></p-divider>
    </ng-template>
    <ng-template pTemplate="opposite" let-event>
      <p-tag [value]="event.cisloVerze" styleClass="release-tag" [severity]="event.cisloVerze > visitedReleaseVersion ? 'warning' : null"></p-tag>
    </ng-template>
  </p-timeline>
</div>
