import { Injectable } from "@angular/core";
import { LayerBehaviourType } from "../../Dto/Mapa/LayerBehaviourType";
import { LayerDefinitionDto } from "../../Dto/Mapa/LayerDefinitionDto";
import { LayerTreeSourceDto } from "../../Dto/Mapa/LayerTreeSourceDto";
import { VectorSourceDto } from "../../Dto/Mapa/VectorSourceDto";
import { NacrtProjektSourceDto } from "../../Dto/Nacrty/NacrtProjectSourceDto";
import { NacrtProjektDto } from "../../Dto/Nacrty/NacrtProjektDto";

/**
 * Pomocná třída pro mapování zdrojových dto (LayerDefinitionDto, NacrtProjektDto) na dto, které je potřeba pro konstrukci stromu vrstev.
**/
@Injectable({
  providedIn: 'root'
})
export class MapToTreeDtoUtils {

  /**
   * Mapování z LayerDefinitionDto na LayerTreeSourceDto.
   * Používá se při vytváření seznamu vrstev v mapě.
   * @param data {LayerDefinitionDto[]} zdrojová dto vrstev
   */
  public fromLayerDefinitionToTreeDto(data: LayerDefinitionDto[]): LayerTreeSourceDto[]
  {
    return data.map(x => {
      return {
        id: x.id,
        data: x,
        label: x.label,
        groupName: x.groupName,
        behaviour: x.behaviour,
        boudingBox: x.source['boudingBox'],
        section: x.section,
        selectableGroup: x.section != 'externi_zdroje',
        selectableLeaf: x.behaviour == LayerBehaviourType.overlayLayer
      }
    });
  }


  /**
   * Mapování z LayerDefinitionDto dto NacrtProjektSourceDto.
   * Používá se pro sestavení nabídky projektů při editaci metadat náčrtu v mapě.
   * @param data {LayerDefinitionDto[]} zdrojová dto vrstev
   * @param prefixLen {number} počet znaků prefixu k odstranění
   */
  public fromLayerDefinitionProjSourceDto(data: LayerDefinitionDto[], prefixLen: number): NacrtProjektSourceDto[] {
    return data.map(x => {
      return {
        id: x.id.substr(prefixLen),
        data: x,
        label: x.label,
        groupName: x.groupName,
        behaviour: null,
        boudingBox: null,
        section: null,
        allowedGeometries: (x.source as VectorSourceDto).allowedGeometries,
        editableGeometries: (x.source as VectorSourceDto).editableGeometries,
        schvalProjektGuid: (x.source as VectorSourceDto).schvalProjektGuid,
        selectableLeaf: true,
        selectableGroup: false
      }
    });
  }


  /**
   * Mapování z NacrtProjektDto na NacrtProjektSourceDto.
   * Používá se pro sestavení nabídky projektů při editaci metadat náčrtů v seznamu náčrtů.
   * @param data {NacrtDetailDto[]} detaily projektů náčrtů
   */
  public fromProjectDetailToProjSourceDto(data: NacrtProjektDto[]): NacrtProjektSourceDto[] {
    return data.map(x => {
      return {
        id: x.guid,
        data: x,
        label: x.nazev,
        groupName: x.skupina,
        behaviour: null,
        boudingBox: null,
        section: null,
        allowedGeometries: { bod: x.bod, linie: x.linie, plocha: x.plocha },
        editableGeometries: x.editableGeometries,
        schvalProjektGuid: x.schvalProjektGuid,
        selectableLeaf: true,
        selectableGroup: false
      }
    });
  }
}
