<span pTooltip="{{tooltip}}" *ngIf="tooltip!=null" class="tooltip-label" tooltipPosition="top">{{label}}</span>
<span *ngIf="tooltip==null">{{label}}</span>
<div class="p-inputtext ui-div-inputtext ui-corner-all" [ngClass]="{'ciselnik-field-content':ciselnik?.popis!=null&&ciselnik?.popis!==''}" *ngIf="variant != 'full'" pTooltip="{{ciselnik?.popis}}" tooltipPosition="bottom">{{ciselnik?.kod}}</div>
<div class="p-grid p-align-stretch p-nogutter" *ngIf="variant == 'full'">
  <div class="{{pcolleft}}">
    <div class="p-inputtext ui-div-inputtext ui-corner-all" [ngClass]="{'ciselnik-field-content':ciselnik?.popis!=null&&ciselnik?.popis!==''}">{{ciselnik?.kod}}</div>
  </div>
  <div class="{{pcolright}}">
    <div class="p-inputgroup-addon ui-div-inputtext ui-corner-all">{{ciselnik?.popis}}</div>
  </div>
</div>
