import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ChangePassDto } from 'src/app/Dto/Auth/ChangePassDto';
import { AuthService } from 'src/app/Services/Auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-auth-change-pass',
  templateUrl: './auth-change-pass.component.html',
  styleUrls: ['./auth-change-pass.component.css']
})
export class AuthChangePassComponent implements OnInit {

  constructor(private authService: AuthService,
    protected activatedRoute: ActivatedRoute,
    protected router: Router,
    private messageService: MessageService) { }

  ngOnInit() {
    this.passForm = new FormGroup({
      'oldPass': new FormControl('', { validators: [Validators.required], updateOn: 'blur' }),
      'newPass': new FormControl('', { validators: [Validators.required], updateOn: 'blur' }),
      'agNewPass': new FormControl('', { validators: [Validators.required] })
    });

    document.getElementById("newPass").setAttribute("autocomplete", "new-password");
    document.getElementById("agNewPass").setAttribute("autocomplete", "new-password");
  }

  passForm: FormGroup;
  get oldPass() { return this.passForm.get('oldPass'); }
  get newPass() { return this.passForm.get('newPass'); }
  get agNewPass() { return this.passForm.get('agNewPass'); }
  faCheck = faCheck;
  submitted: boolean = false;
  loading: boolean = false;

  changePass(): void {
    this.submitted = true;
    if (this.passForm.valid && this.newPass.value == this.agNewPass.value) {
      var pass = new ChangePassDto();
      pass.password = this.passForm.value.oldPass;
      pass.newPassword = this.passForm.value.agNewPass;

      this.loading = true;

      this.messageService.clear();
      this.authService.changePass(pass)
        .subscribe(res => {
          if (res != void 0) {
            if (res.success) {
              this.messageService.add({ severity: 'success', summary: 'V pořádku', detail: res.messages[0] });
            } else {
              this.messageService.add({ severity: 'error', summary: 'Chyba', detail: res.messages[0] });
            }
          }

          this.loading = false;
        });
    } else {
      this.passForm.updateValueAndValidity();
    }
  }

}
