<p-toast [key]="NACRT_PROJEKT_EDIT_TOAST" position="top-center"></p-toast>
<p-confirmPopup [key]="NACRT_PROJEKT_EDIT_CONFIRM_POPUP"></p-confirmPopup>

<app-block-ui [block]="block">
</app-block-ui>

<p-panel *ngIf="canShowForm && showForm" [style]="{'margin-top': '1em'}">
  <ng-template pTemplate="header">
    <div class="p-grid p-align-center">
      <div class="p-col"><span class="p-panel-title">Projekt - {{form.get('nazev').value}}</span></div>
      <div class="p-col right">
        <button pButton type="button" label="Uložit" class="icon-btn p-button-success"
                [disabled]="!userIsAdmin && !form.get('editableStyle').value ||
                !form.valid ||
                !validBeforeEditableStyleUnchecked && !form.get('editableStyle').value"
                (click)="save()">
          <fa-icon [icon]="faSave"></fa-icon>
        </button>
        <button pButton type="button" label="Smazat" class="icon-btn p-button-danger" [disabled]="newNacrt" (click)="delete($event)">
          <fa-icon [icon]="faTrash"></fa-icon>
        </button>
        <button pButton type="button" label="Zrušit" class="icon-btn" (click)="cancel()"><fa-icon [icon]="faTimes"></fa-icon></button>
      </div>
    </div>
  </ng-template>
  <div class="p-grid validated-form-container" [formGroup]="form">


    <p-card [style]="{'height':'100%'}" class="p-col-2" header="Zařazení projektu">
      <div>
        Skupina
        <p-autoComplete formControlName="skupina" [suggestions]="skupinaResults"
                        [style]="{'width':'100%'}" appendTo="body"
                        [inputStyle]="{'width':'100%'}"
                        (completeMethod)="searchSkupiny($event)"></p-autoComplete>
      </div>
      <div>
        Název
        <input type="text" pInputText formControlName="nazev" />
      </div>
      <div class="orgur-multiselect">
        Organizační úrovně
        <app-org-ur-multi-select #orgUrMultiselect formControlName="orgUrGuids" header="Organizační úrovně"
                                 (orgUrAdded)="onOrgUrAdded($event)"
                                 (orgUrRemoved)="onOrgUrRemoved($event)">
        </app-org-ur-multi-select>
      </div>
    </p-card>
    <p-card [style]="{'height':'100%'}" class="p-col-2" header="Styl linie a plochy">
      <div>
        Barva výplně
        <input [disabled]="!form.get('editableStyle').value" [(colorPicker)]="fillRgba"
               [style.background]="fillRgba"
               [style]="{'height':'2.5rem', 'width':'100%'}" appendTo="body"
               cpOutputFormat='rgba' cpAlphaChannel='always' readonly="readonly" />
      </div>
      <div>
        Barva okraje
        <input [disabled]="!form.get('editableStyle').value" [(colorPicker)]="strokeRgba"
               [style.background]="strokeRgba"
               [style]="{'height':'2.5rem', 'width':'100%'}" appendTo="body"
               cpOutputFormat='rgba' cpAlphaChannel='always' readonly="readonly" />
      </div>
      <div>
        Šířka okraje
        <p-inputNumber formControlName="strokeWidth" inputId="integeronly"></p-inputNumber>
      </div>
      <div>
        <app-line-type-library [width]="form?.get('strokeWidth')?.value" [lineColor]="strokeRgba" [lineTypeKey]="lineType?.value" (SelectedLineTypeName)="onLineTypeChange($event)"></app-line-type-library>
      </div>
    </p-card>
    <p-card [style]="{'height':'100%'}" class="p-col-2" header="Styl bodu">
      <div>
        Barva výplně
        <input [disabled]="!form.get('editableStyle').value" [(colorPicker)]="circleFillRgba"
               [style.background]="circleFillRgba"
               [style]="{'height':'2.5rem', 'width':'100%'}" appendTo="body"
               cpOutputFormat='rgba' cpAlphaChannel='always' readonly="readonly" />
      </div>
      <div>
        Barva okraje
        <input [disabled]="!form.get('editableStyle').value" [(colorPicker)]="circleStrokeRgba"
               [style.background]="circleStrokeRgba"
               [style]="{'height':'2.5rem', 'width':'100%'}" appendTo="body"
               cpOutputFormat='rgba' cpAlphaChannel='always' readonly="readonly" />
      </div>
      <div>
        Poloměr bodu
        <p-inputNumber formControlName="circleRadius" inputId="integeronly"></p-inputNumber>
      </div>
      <div>
        Šířka okraje
        <p-inputNumber formControlName="circleStrokeWidth" inputId="integeronly"></p-inputNumber>
      </div>
      <app-icon-library [disabled]="!form?.get('editableStyle').value" [iconKey]="iconName?.value" (SelectedIconName)="onIconChange($event)"></app-icon-library>
    </p-card>
    <p-card [style]="{'height':'100%'}" class="p-col-2" header="Geometrie">
      <div>
        <p-checkbox [binary]="true" label="Bod" formControlName="bod"
                    class="p-col"></p-checkbox>
      </div>
      <div>
        <p-checkbox [binary]="true" label="Linie" formControlName="linie"
                    class="p-col"></p-checkbox>
      </div>
      <div>
        <p-checkbox [binary]="true" label="Plocha" formControlName="plocha"
                    class="p-col"></p-checkbox>
      </div>
    </p-card>
    <p-card [style]="{'height':'100%'}" class="p-col-2 edit-left-site" header="Zobrazení">
      <div>
        Z - index
        <p-inputNumber [inputStyle]="{'width':'100%'}" formControlName="zIndex" [useGrouping]="false" appendTo="body">
        </p-inputNumber>
        <ul>
          <li>Pořadí vrstvy v mapě.</li>
          <li>Čím vyšší hodnota z-index, tím výše se vrstva nachází (překrývá vrstvy s nižší hodnotou).</li>
          <li>Hodnoty nižší než 1000 jsou vyhrazeny pro mapy LHP a externí podklady (ČUZK, LČR apod.)</li>
          <li>Vrstvy se stejnou hodnotou z-index jsou řazeny abecedně podle názvu.</li>
        </ul>
      </div>
    </p-card>
    <div class="p-col-2 flex-card-container">
      <p-card class="upravy-card" [style]="{'height':'100%'}"  header="Úpravy">
        <div>
          <p-checkbox [binary]="true" label="Úprava stylu" formControlName="editableStyle"
                      (onChange)="onEditableStyleChange($event.checked)"
                      class="p-col"></p-checkbox>
        </div>
        <div>
          <p-checkbox [binary]="true" label="Úprava náčrtu"
                      formControlName="editableGeometries"
                      class="p-col"></p-checkbox>
        </div>
      </p-card>
      <p-card class="projekt-schvaleni-card" [style]="{'height':'100%'}"  header="Projekt pro schválení">
        Projekt
        <app-nacrt-project-tree [allowSwitch]="false" [layerTreeSources]="nacrtyProjectsTreeSources"
                                formControlName="schvalProjektGuid"></app-nacrt-project-tree>
      </p-card>
    </div>
  </div>
</p-panel>
