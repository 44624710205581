import { Component, OnInit, TemplateRef, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { VyrobaService } from 'src/app/Services/Vyroba/vyroba.service';
import { KubirovaniListDto } from 'src/app/Dto/Vyroba/KubirovaniListDto';
import { IDokladFilter } from '../vyroba/vyroba.component';
import { TableComponent } from '../../Shared/table/table.component';


/**
 * Komponenta výrobního dokladu číselníku dříví (kubírování).
**/
@Component({
  selector: 'app-vyroba-cd',
  templateUrl: './vyroba-cd.component.html',
  styleUrls: ['./vyroba-cd.component.css']
})
export class VyrobaCdComponent implements OnInit {

  @ViewChild("colDatumDokladu", { static: true }) datumDokladuTemplate: TemplateRef<any>;
  @ViewChild("colNumber", { static: true }) numberTemplate: TemplateRef<any>;
  @ViewChild("colOrgUr", { static: true }) orgUrTemplate: TemplateRef<any>;
  @ViewChild("colCiselnik", { static: true }) ciselnikTemplate: TemplateRef<any>;
  @ViewChild('cdTable', { static: true }) table: TableComponent;

  @Output() dataLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() selectionChange: EventEmitter<KubirovaniListDto[]> = new EventEmitter<KubirovaniListDto[]>();
  @Output() years: EventEmitter<number[]> = new EventEmitter<number[]>();

  constructor(
    private vyrobaService: VyrobaService  )
  { }

  doklady: KubirovaniListDto[];
  dokladyAll: KubirovaniListDto[];
  cols: any[] = [];

  @Input() eQFilter: string;
  
  ngOnInit(): void {
    this.initCols();
    this.loadDoklady();
  }


  /**
   * Inicializace sloupců gridu - je potřeba provést až v ngOnInit aby byly dostupné template reference
   */
  private initCols() {
    this.cols = [
      { field: 'cislo', header: 'Č.', width: '4%' },
      { field: 'datum', header: 'Datum', width: '7%', template: this.datumDokladuTemplate },
      { field: 'orgUrGuid', header: 'Org. ur.', width: '23%', template: this.orgUrTemplate },
      { field: 'vykonGuid', header: 'Výkon', width: '5%', template: this.ciselnikTemplate, ciselnik: 'CVykon' },
      { field: 'lokalitaGuid', header: 'Lok.', width: '4%', template: this.ciselnikTemplate, ciselnik: 'CLokalita' },
      { field: 'lhc', header: 'LHC', width: '6%' },
      { field: 'jprl', header: 'JPRL', width: '6%' },
      { field: 'mnozstvi', header: 'Množ. (m ³)', width: '5%', class: 'number', template: this.numberTemplate },
      { field: 'druhMzdyGuid', header: 'Druh mzdy', width: '5%', template: this.ciselnikTemplate, ciselnik: 'CDruhMzdy' },
      { field: 'tarif', header: 'Tarif', width: '5%', class: 'number', template: this.numberTemplate },
      { field: 'zpusobProvedeniGuid', header: 'Způsob prov.', width: '5%', template: this.ciselnikTemplate, ciselnik: 'CZpusobProvedeni' },
      { field: 'stavKod', header: 'Stav kód', width: '4%' },
      { field: 'stavPopis', header: 'Stav popis', width: '6%' },
      { field: 'vlozil', header: 'Vložil', width: '6%' },
      { field: 'opravil', header: 'Opravil', width: '6%' },
      { field: 'poznamka', header: 'Poznámka', width: '13%' }
    ];
  }


  /**
   * Handler změny výběru dokldů.
   * @param event
   */
  selectionChangeHandler(event): void {
    this.selectionChange.emit(event);
  }


  _filter: IDokladFilter;
  @Input() set filter(value: IDokladFilter) {
    if (value != void 0 && JSON.stringify(value) != JSON.stringify(this._filter)) {
      this._filter = value;
      this.filtruj();
    }
  }

  /**
   * Filtrace dokladů rychlým filtrem dle měsíce a roku.
   */
  private filtruj(): void {
    if (this.dokladyAll == void 0) return;

    if (this._filter != void 0) {
      this.doklady = this.dokladyAll.filter(d =>
        this._filter.roky.indexOf(d.datum.getFullYear()) > -1
        && this._filter.mesice.indexOf(d.datum.getMonth() + 1) > -1
      );
    }
    else {
      this.doklady = this.dokladyAll;
    }
  }


  /**
   * Načtení seznamu dokladů.
   */
  loadDoklady(): void {

    //TODO: ziskat hodnotu rozsirenoho filtru
    //toto zde mam jenom pro test nakonec se to  mozna bude resit uplne jinak
    //let filter = "%7B%22modelId%22%3A%22VyrDokladCD%22%2C%22modelName%22%3A%22VyrDokladCD%22%2C%22id%22%3A%22quer-8py36efc74%22%2C%22name%22%3A%22%5BNew%20query%5D%22%2C%22cols%22%3A%5B%5D%2C%22justsortcols%22%3A%5B%5D%2C%22root%22%3A%7B%22tag%22%3A51%2C%22linking%22%3A3%2C%22conds%22%3A%5B%7B%22tag%22%3A1%2C%22op%22%3A%22GreaterThan%22%2C%22exprs%22%3A%5B%7B%22tag%22%3A2%2C%22dtype%22%3A4%2C%22id%22%3A%22v_vyr_doklad_ciselnik_drivi.cislo%22%2C%22val%22%3A%22v_vyr_doklad_ciselnik_drivi.cislo%22%7D%2C%7B%22tag%22%3A1%2C%22dtype%22%3A4%2C%22kind%22%3A0%2C%22val%22%3A%221%22%2C%22txt%22%3A%221%22%7D%5D%7D%5D%7D%2C%22extraData%22%3A%7B%7D%2C%22timezoneOffset%22%3A-120%7D";
    //filter sql injection - delete from dotazovac.d_sestava_model
    //let filter = "%7B%22modelId%22%3A%22VyrDokladCD%22%2C%22modelName%22%3A%22VyrDokladCD%22%2C%22id%22%3A%22quer-8py36efc74%22%2C%22name%22%3A%22%5BNew%20query%5D%22%2C%22cols%22%3A%5B%5D%2C%22justsortcols%22%3A%5B%5D%2C%22root%22%3A%7B%22tag%22%3A51%2C%22linking%22%3A3%2C%22conds%22%3A%5B%7B%22tag%22%3A1%2C%22op%22%3A%22GreaterThan%22%2C%22exprs%22%3A%5B%7B%22tag%22%3A2%2C%22dtype%22%3A4%2C%22id%22%3A%22v_vyr_doklad_ciselnik_drivi.cislo%22%2C%22val%22%3A%22v_vyr_doklad_ciselnik_drivi.cislo%22%7D%2C%7B%22tag%22%3A1%2C%22dtype%22%3A0%2C%22kind%22%3A0%2C%22val%22%3A%221)%3B%20delete%20from%20dotazovac.d_sestava_model%20--%22%2C%22txt%22%3A%221)%3B%20delete%20from%20dotazovac.d_sestava_model%20--%22%7D%5D%7D%5D%7D%2C%22extraData%22%3A%7B%7D%2C%22timezoneOffset%22%3A-120%7D";

    this.vyrobaService.getCdList(this.eQFilter).subscribe(resp => {
      if (resp != void 0) {
        this.dokladyAll = resp;

        //TODO: časem asi předělat na WebApi
        let uniqueYears = [...new Set(this.dokladyAll.map(x => x.datum.getFullYear()))];
        this.years.emit(uniqueYears);
        this.filtruj();

        this.dataLoaded.emit(true);
      }
      else {
        this.dokladyAll = [];
        this.filtruj();
        this.dataLoaded.emit(false)
      }
    });
  }


  /**
   * Trochu zneužitý Input pro informování child komponenty,
   * že má provést reload dokladů. Nelze zde použít přímé volání
   * metody z child komponenty, protože je existence child komponenty podmíněna *ngIf.
   */
  @Input() set reload(value: boolean) {
    if (value == true) {
      this.loadDoklady();
    }
  }




  /**
   * Id dokladu, který se má v tabulce vybrat.
  **/
  @Input() set dokladGuid(value: string) {
    if (value != void 0 && this.table != void 0) {
      if (this.table.loading) {
        this.table.dataLoaded.subscribe(_ => {
          this.table.selectRowByGuid(value);
        });
      }
      else {
        this.table.selectRowByGuid(value);
      }
    }
  }
}
