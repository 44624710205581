import { Output, EventEmitter, Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ReleaseNotesDto } from '../../../Dto/Core/ReleaseNotesDto';
import { ReleaseNotesService } from '../../../Services/Core/release-notes.service';

@Component({
  selector: 'app-release-notes',
  templateUrl: './release-notes.component.html',
  styleUrls: ['./release-notes.component.css']
})
export class ReleaseNotesComponent implements OnInit {

  data: ReleaseNotesDto[] = [];

  /**
   * Propagace načtených dat release notes
   */
  @Output()
  dataLoaded: EventEmitter<ReleaseNotesDto[]> = new EventEmitter();

  /**
   * Poslední verze, kterou uživatel "zná" (použije se pro grafické odlišení "nových" tagů)
   */
  @Input()
  visitedReleaseVersion: string = null;

  constructor(
    private releaseNotesService: ReleaseNotesService
  ) { }

  ngOnInit(): void {
    this.releaseNotesService.getReleaseNotes().subscribe(res => {
      this.data = res;
      this.dataLoaded.emit(this.data);
    });
  }

}
