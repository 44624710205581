<p-toast position="top-center" key="{{TOAST_KEY}}"></p-toast>
<app-block-ui [block]="loading"></app-block-ui>
<p-blockUI [blocked]="noDataLoaded" [ngClass]="{'kniha-block-ui':displayNav}"></p-blockUI>

<div class="kniha-detail-body">
  <p-toolbar styleClass="base-toolbar">
    <div class="p-toolbar-group-left">
      <div style="width: 15em;">
        <app-org-ur variant="short" [backSpace]="true" [sitemapIcon]="true" (valueChanged)="onOrgUrChanged($event)"></app-org-ur>
      </div>
      <div style="display: inline-block; margin-right: .5em">
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon">LHC</span>
          <input type="text" pInputText [(ngModel)]="lhcKod" style="width: 6em" />
          <span class="p-inputgroup-addon" [ngClass]="{'lhc-invalid': isInvalid(selectedLhc)}">{{selectedLhc?.lhpOd?.getFullYear()}} - {{selectedLhc?.lhpDo?.getFullYear()}}</span>
        </div>
      </div>
      <div style="display: inline-block; margin-right: .5em">
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon">Oddělení</span>
          <input type="text" pInputText [(ngModel)]="odd" style="width: 3em" />
          <span class="p-inputgroup-addon">{{oddPlocha | number : '.2-2' : 'cs'}} ha</span>
        </div>
      </div>
      <div style="display: inline-block; margin-right: .5em">
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon">Dílec</span>
          <input type="text" pInputText [(ngModel)]="dil" style="width: 2em" />
          <span class="p-inputgroup-addon">{{dilPlocha | number : '.2-2' : 'cs'}} ha</span>
        </div>
      </div>
      <div style="display: inline-block; margin-right: .5em">
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon">Porost</span>
          <input type="text" pInputText [(ngModel)]="porost" style="width: 2em" />
          <span class="p-inputgroup-addon">{{porostPlocha | number : '.2-2' : 'cs'}} ha</span>
        </div>
      </div>
      <app-kniha-detail-tree [(lhcTreeDict)]="lhcTreeDict" [porDetail]="porDetail" [lhcList]="lhcList" [showDialog]="selectPorDialog"
                             (onSelectPor)="onPorSelect($event)"></app-kniha-detail-tree>
    </div>
    <div class="p-toolbar-group-right">
      <app-lhp-kniha-tisk [guid]="porDetail?.guid"></app-lhp-kniha-tisk>
    </div>
  </p-toolbar>

  <div class="p-grid p-align-start">
    <div class="p-col-5">
      <p-table [value]="porDetail?.kategories" styleClass="p-datatable-gridlines p-datatable-striped pds-table-compact">
        <ng-template pTemplate="header">
          <tr>
            <th>Kategorie</th>
            <th>Spec.</th>
            <th style="width: 70%;">Popis</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-kateg>
          <tr>
            <td><app-ciselnik-kod ciselnik="CKateg" [guid]="kateg?.kategGuid"></app-ciselnik-kod></td>
            <td>{{kateg.specifikace}}</td>
            <td>{{kateg.popis}}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <div class="p-col-7 p-col-align-stretch">
      <p-panel header="Poznámka">
        {{porDetail?.porText}}
      </p-panel>
    </div>

    <div class="p-col-12">
      <p-tabView [(activeIndex)]="activeMiddleIndex" styleClass="lhp-kniha-tabs-rl" (onChange)="middleTabViewChange($event)">
        <p-tabPanel [disabled]="porTree?.psk?.length==0">
          <ng-template pTemplate="header">
            <span>PSK</span>
            <p-badge value="{{porTree?.psk?.length}}"></p-badge>
          </ng-template>
          <div class="p-grid">
            <div class="p-col-3  middle-part">
              <p-table [value]="porTree?.psk" [(selection)]="selectedPskTree" selectionMode="single" dataKey="guid"
                       (onRowSelect)="onPskSelect()" (onRowUnselect)="onPskUnselect()" styleClass="p-datatable-gridlines p-datatable-striped pds-table-compact"
                       [scrollable]="true" scrollHeight="flex">
                <ng-template pTemplate="header">
                  <tr>
                    <th class="tooltip-label" tooltipPosition="top" pTooltip="Porostní skupina">PSK</th>
                    <th>Plocha (ha)</th>
                    <th class="tooltip-label" tooltipPosition="top" pTooltip="Soubor lesních typů">SLT</th>
                    <th class="tooltip-label" tooltipPosition="top" pTooltip="Lesní typ">LT</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-psk>
                  <tr [pSelectableRow]="psk">
                    <td><b>{{psk?.psk}}</b></td>
                    <td style="text-align:right">{{psk?.plocha | number : '.2-2' : 'cs'}}</td>
                    <td><app-ciselnik-kod ciselnik="CSlt" [guid]="psk?.sltGuid"></app-ciselnik-kod></td>
                    <td><app-ciselnik-kod ciselnik="CLt" [guid]="psk?.ltGuid"></app-ciselnik-kod></td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
            <div class="p-col-4  middle-part">
              <p-table [value]="selectedPskTree?.etaze" [(selection)]="selectedEtazTree" selectionMode="single" dataKey="guid"
                       (onRowSelect)="onEtazSelect()" (onRowUnselect)="onEtazUnselect()" styleClass="p-datatable-gridlines p-datatable-striped pds-table-compact"
                       [scrollable]="true" scrollHeight="flex">
                <ng-template pTemplate="header">
                  <tr>
                    <th>Etáž</th>
                    <th class="tooltip-label" tooltipPosition="top" pTooltip="Plocha etáže skutečná">PS</th>
                    <th class="tooltip-label" tooltipPosition="top" pTooltip="Plocha etáže parciální">PP</th>
                    <th class="tooltip-label" tooltipPosition="top" pTooltip="Hospodářský soubor">HS</th>
                    <th>Věk</th>
                    <th class="tooltip-label" tooltipPosition="top" pTooltip="Zakmenění">Zakm.</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-etaz>
                  <tr [pSelectableRow]="etaz">
                    <td><b>{{etaz?.etaz}}</b></td>
                    <td style="text-align:right">{{etaz?.etazPs | number : '.2-2' : 'cs'}}</td>
                    <td style="text-align:right">{{etaz?.etazPp | number : '.2-2' : 'cs'}}</td>
                    <td><app-ciselnik-kod ciselnik="CLhcHs" [guid]="etaz?.hsGuid"></app-ciselnik-kod></td>
                    <td style="text-align:right">{{etaz?.vek}}</td>
                    <td style="text-align:right">{{etaz?.zakm}}</td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
            <div class="p-col-5 middle-part">
              <p-table [value]="selectedEtazTree?.dreviny" [(selection)]="selectedDrevinaTree" selectionMode="single" dataKey="guid"
                       (onRowSelect)="onDrevinaSelect()" (onRowUnselect)="onDrevinaUnselect()" styleClass="p-datatable-gridlines p-datatable-striped pds-table-compact"
                       [scrollable]="true" scrollHeight="flex">
                <ng-template pTemplate="header">
                  <tr>
                    <th class="tooltip-label" tooltipPosition="top" pTooltip="Zkratka dřeviny">Dřevina</th>
                    <th class="tooltip-label" tooltipPosition="top" pTooltip="Zastoupení">Zast. %</th>
                    <th class="tooltip-label" tooltipPosition="top" pTooltip="Plocha dřeviny (parciální plocha etáže / zastoupení)">Plocha (ha)</th>
                    <th>Tloušťka</th>
                    <th>Výška</th>
                    <th class="tooltip-label" tooltipPosition="top" pTooltip="Absolutní výšková bonita">AVB</th>
                    <th class="tooltip-label" tooltipPosition="top" pTooltip="Zásoba">m<sup>3</sup>/ha</th>
                    <th class="tooltip-label" tooltipPosition="top" pTooltip="Zásoba celkem">m<sup>3</sup></th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-drevina>
                  <tr [pSelectableRow]="drevina">
                    <td style="text-align:left"><b><app-ciselnik-kod ciselnik="CDrevin" [guid]="drevina?.cDrevinGuid"></app-ciselnik-kod></b></td>
                    <td style="text-align:right">{{drevina?.zast}}</td>
                    <td style="text-align:right">{{drevina?.plocha | number : '.2-2' : 'cs'}}</td>
                    <td style="text-align:right">{{drevina?.tloustka}}</td>
                    <td style="text-align:right">{{drevina?.vyska}}</td>
                    <td style="text-align:right">{{drevina?.avb}}</td>
                    <td style="text-align:right">{{drevina?.drZasHa}}</td>
                    <td style="text-align:right">{{drevina?.drZasCel}}</td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="footer">
                  <tr>
                    <td style="text-align: left;font-weight: bold;" tooltipPosition="bottom" pTooltip="Sumární řádek">∑</td>
                    <td style="text-align:right">{{sumByKey('zast')}}</td>
                    <td style="text-align:right">{{sumByKey('plocha') | number : '.2-2' : 'cs'}}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td style="text-align:right">{{sumDrZas | number : '.2-2' : 'cs'}}</td>
                    <td style="text-align:right">{{sumByKey('drZasCel')}}</td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </p-tabPanel>
        <p-tabPanel [disabled]="porTree?.bzl?.length==0">
          <ng-template pTemplate="header">
            <span>Bezlesí</span>
            <p-badge value="{{porTree?.bzl?.length}}"></p-badge>
          </ng-template>
          <div class="p-grid">
            <div class="p-col-12 middle-part">
              <p-table [value]="porTree?.bzl" [(selection)]="selectedBzlTree" selectionMode="single" dataKey="guid"
                       (onRowSelect)="onBzlSelect()" (onRowUnselect)="onBzlUnselect()" styleClass="p-datatable-gridlines p-datatable-striped pds-table-compact"
                       [scrollable]="true" scrollHeight="flex">
                <ng-template pTemplate="header">
                  <tr>
                    <th>Označení</th>
                    <th class="tooltip-label" tooltipPosition="top" pTooltip="Identifikátor katastrálního území v rámci ČR">Kód k.ú.</th>
                    <th>Plocha (ha)</th>
                    <th class="tooltip-label" tooltipPosition="top" pTooltip="Druh bezlesí">Druh</th>
                    <th class="tooltip-label" tooltipPosition="top" pTooltip="Soubor lesních typů">SLT</th>
                    <th class="tooltip-label" tooltipPosition="top" pTooltip="Lesní typ">LT</th>
                    <th class="tooltip-label" tooltipPosition="top" pTooltip="Lesní vegetační stupeň">LVS</th>
                    <th>Způsob využití</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-bzl>
                  <tr [pSelectableRow]="bzl">
                    <td>{{bzl?.oznaceni}}</td>
                    <td>{{bzl?.kuKod}}</td>
                    <td>{{bzl?.plochaP0 | number : '.4-4' : 'cs'}}</td>
                    <td><app-ciselnik-kod ciselnik="CBzlDr" [guid]="bzl?.druhGuid"></app-ciselnik-kod></td>
                    <td><app-ciselnik-kod ciselnik="CSlt" [guid]="bzl?.sltGuid"></app-ciselnik-kod></td>
                    <td><app-ciselnik-kod ciselnik="CLt" [guid]="bzl?.ltGuid"></app-ciselnik-kod></td>
                    <td><app-ciselnik-kod ciselnik="CLvs" [guid]="bzl?.lvsGuid"></app-ciselnik-kod></td>
                    <td>{{bzl?.vyuz}}</td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </p-tabPanel>
        <p-tabPanel [disabled]="porTree?.jp?.length==0">
          <ng-template pTemplate="header">
            <span>Jiný pozemek</span>
            <p-badge value="{{porTree?.jp?.length}}"></p-badge>
          </ng-template>
          <div class="p-grid">
            <div class="p-col-12 middle-part">
              <p-table [value]="porTree?.jp" [(selection)]="selectedJpTree" selectionMode="single" dataKey="guid"
                       (onRowSelect)="onJpSelect()" (onRowUnselect)="onJpUnselect()" styleClass="p-datatable-gridlines p-datatable-striped pds-table-compact"
                       [scrollable]="true" scrollHeight="flex">
                <ng-template pTemplate="header">
                  <tr>
                    <th>Označení</th>
                    <th class="tooltip-label" tooltipPosition="top" pTooltip="Identifikátor katastrálního území v rámci ČR">Kód k.ú.</th>
                    <th>Plocha (ha)</th>
                    <th class="tooltip-label" tooltipPosition="top" pTooltip="Druh jiného pozemku">Druh</th>
                    <th class="tooltip-label" tooltipPosition="top" pTooltip="Lesní vegetační stupeň">LVS</th>
                    <th>Způsob využití</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-jp>
                  <tr [pSelectableRow]="jp">
                    <td>{{jp?.oznaceni}}</td>
                    <td>{{jp?.kuKod}}</td>
                    <td>{{jp?.plochaP0 | number : '.4-4' : 'cs'}}</td>
                    <td><app-ciselnik-kod ciselnik="CJpDruh" [guid]="jp?.druhGuid"></app-ciselnik-kod></td>
                    <td><app-ciselnik-kod ciselnik="CLvs" [guid]="jp?.lvsGuid"></app-ciselnik-kod></td>
                    <td>{{jp?.vyuz}}</td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </p-tabPanel>
        <p-tabPanel [disabled]="porTree?.op?.length==0">
          <ng-template pTemplate="header">
            <span>Ostatní plocha</span>
            <p-badge value="{{porTree?.op?.length}}"></p-badge>
          </ng-template>
          <div class="p-grid">
            <div class="p-col-12 middle-part">
              <p-table [value]="porTree?.op" [(selection)]="selectedOpTree" selectionMode="single" dataKey="guid"
                       (onRowSelect)="onOpSelect()" (onRowUnselect)="onOpUnselect()" styleClass="p-datatable-gridlines p-datatable-striped pds-table-compact"
                       [scrollable]="true" scrollHeight="flex">
                <ng-template pTemplate="header">
                  <tr>
                    <th>Označení</th>
                    <th class="tooltip-label" tooltipPosition="top" pTooltip="Identifikátor katastrálního území v rámci ČR">Kód k.ú.</th>
                    <th>Plocha (ha)</th>
                    <th class="tooltip-label" tooltipPosition="top" pTooltip="Druh ostatní plochy">Druh</th>
                    <th>Způsob využití</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-op>
                  <tr [pSelectableRow]="op">
                    <td>{{op?.oznaceni}}</td>
                    <td>{{op?.kuKod}}</td>
                    <td>{{op?.plochaP0 | number : '.4-4' : 'cs'}}</td>
                    <td><app-ciselnik-kod ciselnik="COpDruh" [guid]="op?.druhGuid"></app-ciselnik-kod></td>
                    <td>{{op?.vyuz}}</td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </p-tabPanel>
      </p-tabView>
    </div>
  </div>
  <div class="p-grid kniha-detail-bottom p-align-start">
    <div class="p-col-9 kniha-detail-jprl-panel">
      <p-tabView [(activeIndex)]="activeBottomIndex" (onChange)="selectJprlTab($event)" styleClass="lhp-kniha-tabs-detail">
        <p-tabPanel tooltip="Lesní hospodářský celek">
          <ng-template pTemplate="header">
            <span class="p-tabview-title">LHC <p-tag *ngIf="isInvalid(selectedLhc)" severity="danger" [value]="'do ' + selectedLhc?.lhpDo?.getFullYear()" [rounded]="true"></p-tag></span>
          </ng-template>
          <app-kniha-detail-lhc [lhc]="selectedLhcDetail"></app-kniha-detail-lhc>
        </p-tabPanel>
        <p-tabPanel header="Porost">
          <app-kniha-detail-por [porost]="porDetail"></app-kniha-detail-por>
        </p-tabPanel>
        <p-tabPanel [header]="rlTabHeader" [tooltip]="rlTabTooltip" [disabled]="rlTabDisabled">
          <app-kniha-detail-psk [psk]="pskDetail" *ngIf="selectedRlType=='PSK'"></app-kniha-detail-psk>
          <app-kniha-detail-bzl [bzl]="bzlDetail" *ngIf="selectedRlType=='BZL'"></app-kniha-detail-bzl>
          <app-kniha-detail-jp [jp]="jpDetail" *ngIf="selectedRlType=='JP'"></app-kniha-detail-jp>
          <app-kniha-detail-op [op]="opDetail" *ngIf="selectedRlType=='OP'"></app-kniha-detail-op>
        </p-tabPanel>
        <p-tabPanel header="Etáž" [disabled]="!selectedEtazTree">
          <app-kniha-detail-etaz [etaz]="etazDetail"></app-kniha-detail-etaz>
        </p-tabPanel>
        <p-tabPanel header="Dřevina" [disabled]="!selectedDrevinaTree">
          <app-kniha-detail-drevina [drevina]="drevinaDetail"></app-kniha-detail-drevina>
        </p-tabPanel>
        <p-tabPanel header="LHE" [disabled]="!lheEnabled" class="lhe-tab">
          <app-kniha-detail-lhe [pskTree]="selectedPskTree"></app-kniha-detail-lhe>
        </p-tabPanel>
      </p-tabView>
    </div>

    <div class="p-col-3 p-col-align-stretch" style="padding-bottom:0px;">
      <p-panel header="Mapa" styleClass="lhp-mapa">
        <ng-template pTemplate="icons">
          <button id="highliteBtn" pButton type="button" class="p-panel-header-icon icon-btn hide-label-button jprl-highlight"
                  [ngClass]="{'jprl-highlight-on': this.selectedMapItem.length > 0}"
                  pTooltip="Zvýraznění vybrané JPRL"
                  (click)="highlight()">
            <fa-icon [icon]="faHighlighter"></fa-icon>
          </button>
          <button pButton type="button" class="p-panel-header-icon icon-btn hide-label-button"
                  pTooltip="Přechod do mapy na vybrané JRPL"
                  (click)="goToMapHandler()">
            <fa-icon [icon]="faExternalLinkAlt"></fa-icon>
          </button>
        </ng-template>
        <app-map #knihaMap id="{{mapElementId}}" (resized)="knihaMap.onResized($event)" (resized)="zoomToActualMapItem()"
                 [Layers]="layers" [ShowMapControls]="false" [VisibleLayers]="visibleLayers"></app-map>
      </p-panel>
    </div>
  </div>

