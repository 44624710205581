import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faPlus, faSave, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DHolinaSyncDto } from '../../../Dto/Lhe/DHolinaSyncDto';
import { DLheListDto, LheListFilterDto } from '../../../Dto/Lhe/DLheListDto';
import { DLheSyncDto } from '../../../Dto/Lhe/DLheSyncDto';
import { CLheCinnostDto } from '../../../Dto/Shared/CLheCinnostDto';
import { CiselnikyService } from '../../../Services/Shared/ciselniky.service';
import { SyncService } from '../../../Services/Sync/sync.service';
import { MessagesUtils } from '../../../Utils/Shared/messages.utils';
import { UuidUtils } from '../../../Utils/Shared/uuid.utils';

/**
 * Komponenta pro editaci LHE
 **/
@Component({
  selector: 'app-lhe-edit',
  templateUrl: './lhe-edit.component.html',
  styleUrls: ['./lhe-edit.component.css']
})
export class LheEditComponent implements OnInit {

  /**
   * Vstupní data LHE ze seznamu LHE.
  **/
  @Input() set data(value: DLheListDto) {
    if (this.form != void 0) {
      this.form.reset();
    }

    if (value != void 0) {
      this.selectedData = { ...value };
      this.form.patchValue({ datum: new Date(value.rok + "-" + value.mesic) });
      this.form.patchValue(value);
      this.form.markAsPristine();
    }

    this._setHolina();
    this._createCinnostiList();
  }


  /**
   * Vybraná hodnota z filtru LHE.
   */
  @Input() set lheListFilter(value: LheListFilterDto) {
    this._selectedLheListFilter = value;
    if (this._selectedLheListFilter?.holinaGuid == void 0 && this._selectedLheListFilter?.etazGuid != void 0) {
      this._selectOnlyEt = true;
    } else if (this._selectedLheListFilter == void 0 || (this._selectedLheListFilter?.holinaGuid == void 0 && this._selectedLheListFilter?.etazGuid == void 0)) {
      this._selectOnlyEt = null;
    } else if (this._selectedLheListFilter?.holinaGuid != void 0 && this._selectedLheListFilter?.etazGuid == void 0) {
      this._selectOnlyEt = false;
    }

    this._clean(false);
  }


  /**
   * Id porostní skupiny.
  **/
  @Input() pskGuid: string;


  /**
   * Událost o změně záznamu LHE.
   */
  @Output() onChanged: EventEmitter<DLheListDto> = new EventEmitter<DLheListDto>();


  constructor(private formBuilder: FormBuilder,
    private ciselnikyService: CiselnikyService,
    private syncService: SyncService,
    private messageService: MessageService,
    private uuidUtils: UuidUtils,
    private confirmationService: ConfirmationService) { }


  ngOnInit(): void {
    this.ciselnikyService.getCislenik("CLheCinnost", null).subscribe(res => {
      this._cinnostiAll = res as CLheCinnostDto[];
      this._createCinnostiList();
    });
  }


  faPlus = faPlus;
  faSave = faSave;
  faTimes = faTimes;
  faTrash = faTrash;

  readonly ToastKey: string = "lhe-edit-toast";
  readonly ConfirmKey: string = "delete-confirm";

  disabledSaveBtn: boolean = false;
  selectedData: DLheListDto = null;

  form: FormGroup = this.formBuilder.group({
    lheCinnostGuid: ['', Validators.required],
    datum: ['', Validators.required],
    plocha: [''],
    mnozstvi: [''],
    drevinaGuid: [''],
    indexHoliny: ['']
  });

  
  /**
   * vybraný filtr
   */
  private _selectedLheListFilter: LheListFilterDto;

  /**
   *  Info o výběru filtru. True, pokud se jedná o etáž/ False pokud se jedná o holinu / null pokud se nefiltruje
   */
  private _selectOnlyEt: boolean;

  /**
   * Číselník všech činností
   */
  private _cinnostiAll: CLheCinnostDto[];

  /**
   * Číselník čínností pro vybrané LHE
   */
  public selectedCinnosti: CLheCinnostDto[];



  private _savePostProcess(res: any, savedLheData: DLheListDto, del: boolean = false): void {
    if (res != void 0) {
      if (res.processedSuccessfully.length > 0) {
        this.onChanged.emit(savedLheData);
        if (del) this._clean();
        let detailText: string = del ? "Došlo ke smazání záznamu LHE" : "Došlo k uložení záznamu LHE";
        this.messageService.add({ key: this.ToastKey, severity: 'success', summary: 'Úspěch', detail: detailText, life: MessagesUtils.TOAST_LIFE });
      } else if (res.isConflict) {
        this.disabledSaveBtn = true;//zamezit uložení při konfliktu
        this.messageService.add({
          key: this.ToastKey, summary: 'Konflikt', severity: 'error',
          detail: 'Při ukládání LHE došlo ke konfliktu. Prosím obnovte stránku znovunačtením.',
          life: MessagesUtils.TOAST_LIFE
        });
      }
      else if (res.isError) {
        this.messageService.add({
          key: this.ToastKey, summary: 'Chyba', severity: 'error',
          detail: res.errors[0].text,
          life: MessagesUtils.TOAST_LIFE
        });
      }
    } else {
      this.messageService.add({ key: this.ToastKey, severity: 'error', summary: 'Chyba', detail: "Chyba uložení LHE", life: MessagesUtils.TOAST_LIFE });
    }
  }


  /**
   * Zrušení provedených změn LHE:
  **/
  public cancel(): void {
    this._clean();
  }


  /**
   * Vyčistí data komponenty a připraví ji tak na zpracování dalšího záznamu.
   * @param emitChange {boolean} true, pokud se má vypustit událost o změně záznamu LHE.
  **/
  private _clean(emitChange: boolean = true): void {
    this.selectedData = null;
    this.form.reset();
    this._createCinnostiList();
    this._validByCinnost('indexHoliny', LheOption.Disabled);
    if (emitChange)
      this.onChanged.emit(null);
  }


  /**
   * Smaže vybraný záznam LHE.
   **/
  public delete(event): void {
    let syncDto = this._dataToSave(this.selectedData);
    this.confirmationService.confirm({
      target: event.currentTarget,
      key: this.ConfirmKey,
      message: 'Opravdu si přejete smazat vybraný záznam LHE?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        syncDto.sync.d = true;
        this.syncService.post([syncDto]).subscribe(resp => {
          this._savePostProcess(resp, (syncDto as DLheListDto), true);
        });
      },
      acceptLabel: 'Smazat',
      reject: () => { },
      rejectLabel: 'Zrušit'
    });
  }


  /**
   * Uložení změn existujícího záznamu LHE.
  **/
  update(): void {
    let syncDto = this._dataToSave(this.selectedData);
    this.syncService.post([syncDto]).subscribe(resp => {
      this._savePostProcess(resp, (syncDto as DLheListDto), false);
    });
  }


  /**
   * Přidání nového záznamu LHE
   **/
  public add(): void {
    if (!this.form?.valid) {
      this.messageService.add({ severity: 'error', summary: 'Chyba', detail: 'Formulář obsahuje chyby.', key: this.ToastKey });
      return;
    }

    let newLhe: DLheSyncDto = this._newDataToSave();

    let req = [];

    let cinnost = this._getCinnost(this.form.get('lheCinnostGuid').value);
    if (cinnost != void 0 && cinnost.etaz && cinnost.holina && this._selectedLheListFilter?.holinaGuid == void 0)
    { //vybranná činnost je "plocha vzniklé holiny"
      let holina: DHolinaSyncDto = this._toHolinaSyncDto(newLhe.mesic, newLhe.rok);
      newLhe.holinaGuid = holina.guid;
      req.push(holina);
    }
    else if (this._selectedLheListFilter?.holinaGuid) {
      newLhe.holinaGuid = this._selectedLheListFilter?.holinaGuid;
    }
    else if (this._selectedLheListFilter?.etazGuid) {
      newLhe.etazGuid = this._selectedLheListFilter?.etazGuid;
    }
    else {
      this.messageService.add({ severity: 'error', summary: 'Chyba', detail: 'Není známa etáž ani holina pro vytvoření záznamu LHE:', key: this.ToastKey });
      return;
    }
    req.push(newLhe);
    this.syncService.post(req).subscribe(resp => {
      this._savePostProcess(resp, (newLhe as DLheListDto), false);
    });
  }


  /**
   * Naplní pole pro obsah číselníku činností v závislosti na vybrané etáži/holině, nebo podle filtru, pokud není vybraný řádek.
   **/
  private _createCinnostiList(): void {
    let dataEmpty: Boolean = this.selectedData == void 0;
    if (this._cinnostiAll != void 0 &&
      ((!dataEmpty && this.selectedData?.etazGuid != void 0) ||
        (dataEmpty && this._selectOnlyEt))) {//Je zvolena etáž, nebo je zvolený filtr etáží
      this.selectedCinnosti = this._cinnostiAll?.filter(x => x.etaz);
    } else if (this._cinnostiAll != void 0 &&
      ((!dataEmpty && this.selectedData?.holinaGuid != void 0) ||
      (dataEmpty && this._selectOnlyEt == false && this._selectedLheListFilter?.holinaGuid != void 0))) {//je zvolená holina, nebo je zvolený filtr holin
      this.selectedCinnosti = this._cinnostiAll?.filter(x => x.holina);
    } else if (this._cinnostiAll != void 0 &&
      ((!dataEmpty && this.selectedData?.etazGuid != void 0 && this.selectedData?.holinaGuid != void 0)
        || (dataEmpty && this._selectOnlyEt == void 0))) {//je zvolena etáž i holina, nebo je filtr na vše/bez filtrace
      this.selectedCinnosti = this._cinnostiAll?.filter(x => x.etaz && x.holina);
    } else {
      this.selectedCinnosti = [];
    }
  }


  /**
   * Vrátí data LHE pro uložení.
   * @param sync {DLheSyncDto} původní data LHE
   */
  private _dataToSave(originalData: DLheSyncDto): DLheSyncDto {
    let result: DLheSyncDto = this._dataToSaveInner();
    result.sync = { ...originalData.sync };
    result.guid = originalData.guid;
    result.holinaGuid = originalData.holinaGuid;
    result.etazGuid = originalData.etazGuid;
    
    return result;
  }


  /**
   * Vrátí data nového záznamu LHE pro uložení. Vytvárí nový sync záznam.
   */
  private _newDataToSave(): DLheSyncDto {
    let id = this.uuidUtils.GenerateUuid();
    let result: DLheSyncDto = this._dataToSaveInner();
    result.guid = id;
    result.sync = { i: id, d: false, u: 0, h: [], l: null };
    result.holinaGuid = null;
    result.etazGuid = null;
    return result;
  }


  /**
   * Převede data formuláře do dto pro uložení via sync api.
  **/
  private _dataToSaveInner(): DLheSyncDto {
    let result: DLheSyncDto = new DLheSyncDto();

    Object.keys(this.form.value).forEach(key => {
      if (key == 'datum') {
        let dat = this.form.get(key).value;
        result.rok = dat.getFullYear();
        result.mesic = dat.getMonth() + 1;
      }
      else if(key != 'indexHoliny') {
        result[key] = this.form.value[key];
      }
    });

    return result;
  }


  /**
   * Vrátí dto holiny pro uložení via sync api.
   * @param mesic {number} mesic vzniku holiny
   * @param rok {number} rok vzniku holiny
   */
  private _toHolinaSyncDto(mesic: number, rok: number): DHolinaSyncDto {
    let holinaGuid = this.uuidUtils.GenerateUuid();
    let holina: DHolinaSyncDto = {
      guid: holinaGuid,
      indexHoliny: this.form.get("indexHoliny").value,
      mesic: mesic,
      rok: rok,
      pskGuid: this.pskGuid,
      nacrtGuid: null,
      sync: { u: 0, h: [], d: false, i: holinaGuid, l: null },
      type: "DHolina"
    };

    return holina;
  }


  /**
   * Hanler změny činnosti
   * @param guid
   */
  public onLheCinnostChange(guid: string): void {
    let selectedCinnost = this._getCinnost(guid);

    if (selectedCinnost != void 0) {
      this._validByCinnost('plocha', selectedCinnost.plocha);
      this._validByCinnost('mnozstvi', selectedCinnost.objem);
      this._validByCinnost('drevinaGuid', selectedCinnost.drevina);

      if (selectedCinnost.etaz && selectedCinnost.holina && this._selectedLheListFilter?.holinaGuid == void 0) {
        this._validByCinnost('indexHoliny', LheOption.Required);
      } else {
        this._validByCinnost('indexHoliny', LheOption.Disabled);
      }
    }
  }


  /**
   * Vrátí dto činnosti z aktuálně používaného seznamu činností.
   * @param guid {string} guid hledané činnosti
   */
  private _getCinnost(guid: string): CLheCinnostDto {
    return guid != void 0 ? this._cinnostiAll.find(x => x.guid == guid) : null;
  }


  /**
   * Aktivace a validace položek formuláře
   * @param controlName {string}  Název položky formuláře
   * @param option {string} hodnota nastavení položky formuláře
   */
  private _validByCinnost(controlname: string, option: string): void {
    let formItem = this.form.get(controlname);
    formItem.clearValidators();

    let validators = [Validators.required];

    //nastaveni desetinnych mist
    switch (controlname) {
      case "plocha":
        validators.push(Validators.pattern('^-?\\d{1,7}(\\.\\d{1,4})?$')); //plocha max. na 7 mist a na 4 desetinna mista. 7 mist je omezeni v db a desetinna mista jsou stanovena v #43458
        break;
      case "mnozstvi":
        validators.push(Validators.pattern('^-?\\d{1,10}(\\.\\d{1,3})?$'));//mnozstvi max. na 10 mist a na 3 desetinna mista. 10 mist je omezeni v db a desetinna mista jsou stanovena v #43458
        break;
			default:
		}

    switch (option) {
      case LheOption.Required:
        formItem.setValidators(validators);
        formItem.enable({ onlySelf: true });
        break;
      case LheOption.Optional:
        formItem.enable({ onlySelf: true });
        break;
      case LheOption.Disabled:
        formItem.disable({ onlySelf: true });
        break;
      default:
        formItem.disable({ onlySelf: true });
    }
    formItem.updateValueAndValidity();
  }


  /**
   * Pokud existuje holina, vyplní se index holiny do formuláře jako needitovatelné pole.
   **/
  private _setHolina(): void {
    if (this._selectedLheListFilter != void 0 && this._selectOnlyEt == false) {
      this.form.patchValue({ indexHoliny: this._selectedLheListFilter?.indexHoliny });
      this._validByCinnost('indexHoliny', LheOption.Disabled);
    }
    else if (this.selectedData?.holinaGuid != void 0) {
      this.form.patchValue({ indexHoliny: this.selectedData.indexHoliny });
      this._validByCinnost('indexHoliny', LheOption.Disabled);
    }
    else {
      this.form.get('indexHoliny').reset();
    }
  }
}

/**
 * enum nastavení položky formuláře
 **/
enum LheOption {
  Disabled = "d",
  Required = "r",
  Optional = "o"
}

