import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { RoleEnum } from '../Dto/Core/RoleEnum';
import { RoleUtils } from '../Utils/Shared/role.utils';

/**
 * Guard hlídající přístup uživatele podle role ZakaznikAdmin
**/
@Injectable({
  providedIn: 'root'
})
export class ZakaznikAdminGuard implements CanActivate {

  constructor(private roleUtils: RoleUtils) { }

  canActivate() {
    return this.roleUtils.checkRole([RoleEnum.SuperAdmin, RoleEnum.ZakaznikAdmin]);
  }
}
