<form [formGroup]="data">
  <p-panel header="Vstupy pro výpočet" [style]="{'margin-bottom': '1em'}">
    <!-- Tady je použitý vlastní grid pro 3 sloupce - je založen na principu FlexGridu Primeng. -->
    <div class="p-grid p-align-center">
      <div class="p-1-col-1"></div>
      <div class="p-2-col-1">Objem m3</div>
      <div class="p-2-col-1">Náklady Kč/m3</div>
    </div>
    <div class="p-grid p-align-center">
      <div class="p-1-col-1">
        Vstup
      </div>
      <div class="p-2-col-1">
        <input pInputText type="number" formControlName="objem" pTooltip="Objem dřevní hmoty podle jednotlivých dřevin a sortimentů v m3" tooltipPosition="top"/>
      </div>
      <div class="p-2-col-1">
        <input pInputText type="number" formControlName="naklady" pTooltip="Ekonomicky oprávněné náklady na soustřeďování dříví na odvozní místo v Kč/m3" tooltipPosition="top"/>
      </div>
    </div>
  </p-panel>
</form>
