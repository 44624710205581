import { Component, Input } from '@angular/core';
import { faBookOpen, faSearch, faTimes, faCopy } from '@fortawesome/free-solid-svg-icons';
import { PesMapItemDto } from '../../../Dto/Pes/PesMapItemDto';
import { MapInteractionService } from '../../../Services/Mapa/map-interaction.service';
import { ExtentUtils } from '../../../Utils/Mapa/extent.utils';
import { ExternalMessagingService } from '../../../Services/Shared/external-messaging.service';

/**
 * Komponenta pro zobrazení vybraných mapových objektů z modulu Pes.
**/
@Component({
  selector: 'app-map-info-pes',
  templateUrl: './map-info-pes.component.html',
  styleUrls: ['./map-info-pes.component.css']
})
export class MapInfoPesComponent {

  /**
   * Data parcey.
  **/
  _item: PesMapItemDto = null;
  @Input() set item(value: PesMapItemDto) {
    if (value != void 0) {
      this._item = value;
    }
  }

  constructor(
    private mapInteractionService: MapInteractionService,
    private extentUtils: ExtentUtils,
    private externalMessagingService: ExternalMessagingService)
  {
    this.mapInteractionService.DeactivateButtons.subscribe(deactivate => this.disabledBtn = deactivate);
  }


  faBookOpen = faBookOpen;
  faSearch = faSearch;
  faTimes = faTimes;
  faCopy = faCopy;
  disabledBtn: boolean = false;


  /**
   * Přiblížení na parcelu.
  **/
  zoomTo(): void {
    this.mapInteractionService.zoomTo(this.extentUtils.addBuffer(this.extentUtils.getExtent(this._item.wkt)));
  }


  /**
   * Handler kliknutí na tlačítko zkopírování parcely do náčrtu.
   */
  copy(): void {
    this.mapInteractionService.copy(this._item);
  }


  /**
   * Zobrazení pasportu parcely.
  **/
  goToPasport(): void {
    this.externalMessagingService.sendMessage({ event: 'showPassport', data: this._item.parId });
  }


  /**
   * Odebrání parcely z výběru.
   */
  removeFromSelection(): void {
    this.mapInteractionService.removeFromSelection(this._item.id);
  }
}
