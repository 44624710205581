import { EventEmitter, Input, OnInit, Output, Directive } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { LhcListDto } from 'src/app/Dto/Lhp/LhcListDto';
import { EtazInfoDto } from 'src/app/Dto/Skody/EtazInfoDto';
import { JprlInfoDto } from 'src/app/Dto/Skody/JprlInfoDto';
import { LhpDrevinaProVypocetDto } from 'src/app/Dto/Skody/LhpDrevinaProVypocetDto';
import { VypocetDetailDto } from 'src/app/Dto/Skody/VypocetDetailDto';
import { LhpoService } from 'src/app/Services/Lhp/lhpo.service';
import { CiselnikListDto } from '../../../Dto/Shared/CiselnikListDto';
import { CiselnikyService } from '../../../Services/Shared/ciselniky.service';

/**
 * Base třída pro zadávání základních údajů škody a újmy.
**/
@Directive()
export abstract class DetailZuComponent implements OnInit {
  constructor(
    protected fb: FormBuilder,
    protected ciselnikService: CiselnikyService,
    protected lhpoService: LhpoService
  ) { }

  @Output() druhVypoctuEvent: EventEmitter<any> = new EventEmitter();
  @Output() dataChanged: EventEmitter<any> = new EventEmitter();
  @Output() dataChangedPrepocitej: EventEmitter<any> = new EventEmitter();
  @Output() copyLhpEtazEvent: EventEmitter<any> = new EventEmitter();
  @Output() copyLhpDrevinaEvent: EventEmitter<any> = new EventEmitter();
  @Output() lhcChangedEvent: EventEmitter<LhcListDto> = new EventEmitter();

  abstract druhVypoctuList: SelectItem[];

  _skoda: VypocetDetailDto;
  @Input() set skoda(value: VypocetDetailDto) {
    this._skoda = value;
    this.populateForm(this._skoda);
  }

  jprlAlert: boolean = false;

  ngOnInit(): void {
    this.ciselnikService.getCislenik("CDrevinaSkup", null).subscribe(res => {
      this.drevinyAll = res;
    });
  }

  data: FormGroup = this.fb.group({
    guid: [''],
    typ: [''],
    lhc: [''],
    jprl: [''],
    etaz: [''],
    drevina: ['']
  });

  /** ---------- gettery ---------- **/
  get lhc() {
    let l = this.data.get("lhc").value;
    if (l == void 0) return '';
    return l.lhcKod;
  }

  get jprl() {
    let j = this.data.get("jprl").value;
    if (j == void 0) return '';
    return j.jprl;
  }

  get etaz() {
    let e = this.data.get('etaz').value;
    if (e == void 0) return '';
    return e.etaz;
  }

  get drevina() {
    let d = this.data.get('drevina').value;
    if (d == void 0) return '';
    return d.zkratka;
  }

  /**
   * Kontrola dat ze základních údajů  pro přepočet škody.
   * @param data
   */
  validateVstupyProPrepocet(data): boolean {
    return data.drevina != void 0
      && data.drevina.guid != void 0;
  };

  /**
   * Naplnění modelu formuláře daty škody.
   * @param skoda
   */
  populateForm(skoda: VypocetDetailDto): void {
    this.data.get('guid').setValue(skoda.guid);
    this.data.get('typ').setValue(skoda.typ);

    this.lhpoService.lhc(skoda.lhcGuid).subscribe(lhc => {
      this.data.get('lhc').setValue(lhc);
      if (lhc != void 0) {
        this.setModelJprl(lhc.lhcKod, skoda.jprl, skoda.etaz);
      }
      else {
        this.jprlAlert = false;
        this.data.get('jprl').setValue(null);
        this.data.get('etaz').setValue(null);
      }
    });

    this.ciselnikService.getCislenik("CDrevinaSkup", null).subscribe(dr => {
      this.data.get('drevina').setValue(dr);
    });
  };

  /**
   * Nastaví ve formuláři JprlListDto.
   * @param lhcKod
   * @param jprlKod
   */
  setModelJprl(lhcKod: number, jprlKod: string, etaz: string): void {
    this.lhpoService.jprl(lhcKod, jprlKod).subscribe(jprl => {
      if (jprl != void 0) {
        this.data.get('jprl').setValue(jprl);
        this.jprlAlert = false;
      }
      else {
        jprl = new JprlInfoDto();
        jprl.jprl = jprlKod;
        jprl.pskGuid = null;
        jprl.etaze = [];
        this.data.get('jprl').setValue(jprl);
        this.jprlAlert = true;
      }

      this.setModelEtaz(jprl, etaz);
    });
  }

  /**
   * Nastaví ve formuláři EtazInfoDto.
   * @param jprl
   * @param etaz
   */
  setModelEtaz(jprl: JprlInfoDto, etaz: string): void {
    let e
    if (jprl.etaze.length > 0) {
      e = jprl.etaze.find(x => x.etaz == etaz);
    }
    else {
      e = new EtazInfoDto();
      e.etaz = etaz;
      e.dreviny = [];
    }
    this.data.get('etaz').setValue(e);
  }

  /**
   * Handler změny typu škody.
  **/
  druhVypoctuChanged(event) {
    this.druhVypoctuEvent.emit(event.value);
  };

  /**
   * Zajistí, že v modelu formuláře bude uložen LhcListDto i v případě, že uživatel zapsal LHC ručně.
   * @param event
   */
  lhcBlurHandler(event) {
    if (event.target.value != void 0 && typeof event.target.value == 'string') {
      this.lhpoService.lhcList().subscribe(lhcs => {
        let lhc = lhcs.filter(x => x.lhcKod == event.target.value);
        this.data.get('lhc').setValue(lhc[0]);
        this.lhcChangedEvent.emit(lhc[0]);
      });
    }
  }

  /**
   * Zajistí, že v modelu formuláře bude jprl uloženo jako JprlInfoDto i v případě, že uživatel zapsal JPRL ručně.
   * @param event
   */
  jprlBlurHandler(event) {
    if (event.target.value != void 0 && typeof event.target.value == 'string') {
      this.setModelJprl(this.lhc, event.target.value, this.etaz);
    }
  }

  /**
   * Zajistí, že v modelu formuláře bude etaz uložena jako EtazIfnoDto i v případě, že uživatel zapsal etáž ručně.
   * @param event
   */
  etazBlurHandler(event) {
    if (event.target.value != void 0) {
      if (typeof event.target.value == 'string') {
        let jprl = (this.data.get('jprl').value as JprlInfoDto);
        this.setModelEtaz(jprl, event.target.value);
      }
      this.getEtazInfo();
    }
  }

  /**
   * Zajistí, že v modelu formuláře bude dřevina uložena jako LhpDrevinaProVypocetDto i v případě, že uživatel zapsal dřevinu ručně.
   * @param event
   */
  drevinaBlurHandler(event): void {
    if (event.target.value != void 0 && typeof event.target.value == 'string') {
      let drevina = new LhpDrevinaProVypocetDto();
      drevina.zkratka = event.target.value;
      drevina.guid = null;
      this.data.get('drevina').setValue(drevina);
    }

    this.getDrevinaInfo();
  }

  /** ---------- předání LHP dat etáže a dřeviny pro další zpracování ---------- **/
  /**
   * Předá data etáže nadřazené komponentě pro další zpracování.
   */
  getEtazInfo(): void {
    let etaz = this.data.get('etaz').value;
    if (etaz == void 0) {
      etaz = null;
    }
    this.copyLhpEtazEvent.emit(etaz);
  };

  /**
   * Načte data dřeviny z dané etáže z LHP a předá je nadřazené komponentě pro další zpracování.
   */
  getDrevinaInfo(): void {
    let etaz = (this.data.get('etaz').value as EtazInfoDto);
    if (etaz != void 0) {
      let drevina = etaz.dreviny.find(x => x.zkratka == this.drevina);
      if (drevina == void 0) { //je možné žádat o náhradu škody i pro dřevinu, která není v LHP
        drevina = null;
      }
      this.copyLhpDrevinaEvent.emit(drevina);
    }
  };

  /** ---------- autocomplete ---------- **/
  navrhovaneDreviny: CiselnikListDto[];
  navrhovaneLhc: LhcListDto[];
  navrhovaneJprl: JprlInfoDto[];
  navrhovaneEtaze: EtazInfoDto[];

  drevinyAll: CiselnikListDto[];

  /**
   * Vrátí seznam dřevin pro autocomplete.
   * @param event
   */
  hledejDreviny(event): void {
    let toSearch = event.query.toUpperCase();
    this.navrhovaneDreviny = this.drevinyAll.filter(x => x.kod.startsWith(toSearch));
  };

  /**
   * Vrátí seznam lhc pro autocomplete.
   * @param event
   */
  hledejLhc(event): void {
    let toSearch = event.query;

    this.lhpoService.lhcList().subscribe(res => {
      this.navrhovaneLhc = res.filter(x => x.lhcKod.toString().startsWith(toSearch));
    });
  };

  /**
   * Vyhledání JPRL pro oddělení a dílec v LHC. Nabídka se zobrazí až je zadáno oddělení a dílec.
   * @param event
   */
  hledejJprl(event): void {
    var lhcKod = this.lhc;
    if (lhcKod != '') {
      let toSearch = event.query;
      this.jprlAlert = false;

      if (RegExp(/^\d{1,3}[A-Za-z]/).test(toSearch)) { //hledání jprl spustíme teprve až je zadáno oddělení a dílec
        this.lhpoService.jprlList(lhcKod, toSearch).subscribe(res => {
          var toSearchUpperDil = toSearch.replace(/^(\d{1,3}[a-z])/, function (v) { return v.toUpperCase(); });
          this.navrhovaneJprl = res.filter(x => x.jprl.startsWith(toSearchUpperDil)).slice();
          if (this.navrhovaneJprl.findIndex(x => x.jprl == toSearchUpperDil) == -1) this.jprlAlert = true;
        });
      }
      else {
        this.navrhovaneJprl = [];
        this.jprlAlert = true;
      }
    }
    else {
      this.navrhovaneJprl = [];
    }
  }

  /**
   * Vyhledání etáží v rámci daného JPRL.
   * @param event
   */
  hledejEtaze(event): void {
    let toSearch = event.query;

    let jprl = this.data.get('jprl').value;
    if (jprl != void 0) {
      let etaze = (jprl as JprlInfoDto).etaze;
      if (etaze.length > 0) {
        this.navrhovaneEtaze = etaze.filter(x => x.etaz.startsWith(toSearch)).slice();
      }
      else {
        this.navrhovaneEtaze = [];
      }
    }
    else {
      this.navrhovaneEtaze = [];
    }
  }
}
