<p-toast [key]="DOTAZOVAC_TOAST" position="top-center"></p-toast>
<p-confirmPopup key="{{DOTAZOVAC_DELETE_CONFIRM}}"></p-confirmPopup>

<p-dialog header="SQL" (onHide)="hideSql()" [(visible)]="displaySql" [modal]="true" [style]="{width: '900px'}">
  <p>{{selectedDotazDetail?.sqlData}}</p>
</p-dialog>

<p-toolbar styleClass="base-toolbar" [style]="{'margin-bottom': '1em'}">
  <div class="p-toolbar-group-left">
    <h2 class="toolbar-label">Dotazovač</h2>
    <i class="p-toolbar-separator"><fa-icon [icon]="faEllipsisV"></fa-icon></i>
    <app-new-dotaz (onSave)="onSaveDotaz($event)"></app-new-dotaz>
    <app-new-dotaz *ngIf="canEdit" (onSave)="onSaveDotaz($event)" [guid]="selectedDotaz?.id" [edit]="true"></app-new-dotaz>
    <app-new-dotaz *ngIf="canEdit" (onSave)="onSaveDotaz($event)" [guid]="selectedDotaz?.id" [copy]="true"></app-new-dotaz>
    <button pButton type="button" label="Spustit" class="icon-btn p-button-success" (click)="runDotaz()" [disabled]="!selectedDotaz"><fa-icon [icon]="faPlayCircle"></fa-icon></button>
    <app-button-icon-stack *ngIf="administrator" [disabled]="!selectedDotaz" label="SQL" class="p-button-success" (click)="sql()" [iconBack]="faFile" [iconFront]="faDatabase"></app-button-icon-stack>
    <button pButton type="button" label="Odemknout" class="icon-btn p-button-success" (click)="unlock($event)" [disabled]="!selectedDotaz || !canUnlock"><fa-icon [icon]="faLockOpen"></fa-icon></button>
    <button pButton type="button" label="Zamknout" class="icon-btn p-button-success" (click)="lock($event)" [disabled]="!selectedDotaz || !canLock"><fa-icon [icon]="faLock"></fa-icon></button>
    <button pButton type="button" label="Smazat" class="icon-btn p-button-danger" (click)="delete($event)" [disabled]="!selectedDotaz || !canDelete"><fa-icon [icon]="faTrash"></fa-icon></button>
  </div>
  <div class="p-toolbar-group-right">
    <button pButton type="button" label="Zpět" class="icon-btn" (click)="back()"><fa-icon [icon]="faUndo"></fa-icon></button>
  </div>
</p-toolbar>

<div class="p-grid p-align-start dotazovac-body">
  <div class="p-col-2">
    <p-panel header="Kategorie">
       <app-dotazovac-kategorie #kategorieTree [kategorie]="kategorie" (onChange)="onKategorieChange($event)"></app-dotazovac-kategorie>
    </p-panel>
  </div>
  <div class="p-col-10">
    <div class="table-up">
      <p-table #dotazyTable [value]="dotazy" dataKey="id" selectionMode="single" [(selection)]="selectedDotaz" (onFilter)="onDotazyTableFilter($event)"
               [rows]="10" [rowsPerPageOptions]="ROWS_PER_PAGE" styleClass="p-datatable-gridlines p-datatable-striped pds-table-compact"
               (onRowSelect)="onRowSelect($event.data)" (onRowUnSelect)="onRowUnselect($event.data)"
               [paginator]="true" sortField="nazev" [sortOrder]="1" [globalFilterFields]="['kategorie']"
               (onSort)="onSort()">
        <ng-template pTemplate="header">
          <tr>
            <th style="width:30%;" pSortableColumn="nazev">Název<p-sortIcon field="nazev"></p-sortIcon></th>
            <th style="width:30%;" pSortableColumn="popis">Datový model <p-sortIcon field="datovyModel"></p-sortIcon></th>
            <th style="width:25%;" pSortableColumn="insLogin">Autor<p-sortIcon field="insLogin"></p-sortIcon></th>
            <th style="width:5%;" pSortableColumn="zamceno">Stav<p-sortIcon field="zamceno"></p-sortIcon></th>
            <th style="width:10%;" pSortableColumn="insDate">Čas vzniku<p-sortIcon field="insDate"></p-sortIcon></th>
          </tr>
          <tr>
            <th style="width:30%;">
              <p-columnFilter type="text" field="nazev">
                <ng-template pTemplate="filter" let-filter="filterCallback">
                  <input type="text" pInputText [(ngModel)]="filterModels['nazev']" (ngModelChange)="filter($event)" />
                </ng-template>
              </p-columnFilter>
            </th>
            <th style="width:30%;">
              <p-columnFilter field="datovyModel" matchMode="in" [showMenu]="false">
                <ng-template pTemplate="filter" let-filter="filterCallback">
                  <p-multiSelect [(ngModel)]="filterModels['datovyModel']" [options]="modelyOptions" placeholder="Vše" (onChange)="filter($event?.value)" optionLabel="nazev" optionValue="modelId">
                    <ng-template let-option pTemplate="item">
                      <span class="p-ml-1">{{option.nazev}}</span>
                    </ng-template>
                  </p-multiSelect>
                </ng-template>
              </p-columnFilter>
            </th>
            <th style="width:25%;">
              <p-columnFilter type="text" field="insLogin">
                <ng-template pTemplate="filter" let-filter="filterCallback">
                  <input type="text" pInputText [(ngModel)]="filterModels['insLogin']" (ngModelChange)="filter($event)" />
                </ng-template>
              </p-columnFilter>
            </th>
            <th colspan="2"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-dotaz>
          <tr [pSelectableRow]="dotaz">
            <td style="width:30%;">{{dotaz.nazev}}</td>
            <td style="width:30%;"><app-dotazovac-model-display [modelId]="dotaz.datovyModel"></app-dotazovac-model-display></td>
            <td style="width:25%;">{{dotaz.insLogin}}</td>
            <td class="lock-container" style="width:5%;">
              <fa-icon class="lock" *ngIf="dotaz.zamceno" [icon]="faLock"></fa-icon>
              <fa-icon class="lock-open" *ngIf="!dotaz.zamceno" [icon]="faLockOpen"></fa-icon>
            </td>
            <td style="width:10%;">{{dotaz.insDate | date:'d.M.yyyy HH:mm'}}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="paginatorright" let-state>
          <div class="paginator-right">
            Zobrazeno {{state.totalRecords < 1 ? 0 : state.first + 1}} -
            {{
            state.totalRecords > 0 ? ((state.rows+ state.first) <= state.totalRecords ? (state.rows + state.first) :
            state.totalRecords) : 0
            }} z {{state.totalRecords ? state.totalRecords : 0}} záznamů
          </div>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="8">Nebyl nalezen žádný dotaz</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <div class="table-down">
      <p-table *ngIf="selectedDotaz!=null&&selectedDotazInfo!=null" [columns]="dotazDataCols" [value]="dotazDataRows" responsiveLayout="scroll"
               styleClass="p-datatable-gridlines p-datatable-striped pds-table-compact" [rows]="10" [rowsPerPageOptions]="ROWS_PER_PAGE"
               [paginator]="true" [lazy]="true" (onLazyLoad)="loadDataRows($event)" [totalRecords]="dataTotalRecords" [loading]="loadingData">
        <ng-template pTemplate="caption">
          <div class="base-toolbar">
            <div class="p-toolbar-group-left">
              <h2 class="toolbar-label">Výsledek dotazu {{selectedDotaz?.nazev}}</h2>
            </div>
            <div class="p-toolbar-group-right">
              <button type="button" pButton pRipple icon="pi pi-file-excel" class="p-button-success p-ml-2" pTooltip="XLS" tooltipPosition="bottom"
                      (click)="exportExcel()" [disabled]="!exportExcelEnabled"></button>
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns">
              {{col.header}}
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr>
            <td *ngFor="let col of columns">
              {{rowData[col.field]}}
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="paginatorright" let-state>
          <div class="paginator-right">
            Zobrazeno {{state.totalRecords < 1 ? 0 : state.first + 1}} -
            {{
            state.totalRecords > 0 ? ((state.rows+ state.first) <= state.totalRecords ? (state.rows + state.first) :
            state.totalRecords) : 0
            }} z {{state.totalRecords ? state.totalRecords : 0}} záznamů
          </div>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td>Dotaz nevrátil žádná data</td>
          </tr>
        </ng-template>
      </p-table>
    </div>

  </div>
</div>

<app-block-ui [block]="block"></app-block-ui>
<app-dotaz-params (onRun)="onSaveDotazParams($event)" [dotaz]="selectedDotaz" [data]="paramsEQData" [display]="editParams"></app-dotaz-params>
