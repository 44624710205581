import { Component, forwardRef, Input, ViewChild, EventEmitter, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { COdberatelKontraktListDto } from 'src/app/Dto/Vyroba/COdberatelKontraktListDto';
import { CiselnikyService } from 'src/app/Services/Shared/ciselniky.service';
import { AutoComplete } from 'primeng/autocomplete';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-vyroba-kontrakt-autocomplete',
  template: '<p-autoComplete #ac [(ngModel)]="selectedDto" field="kontrakt" [suggestions]="kontrakty"'
    + ' [dropdown]="true" [disabled]="disabled || !odberatelGuid" [forceSelection]="true" (onClear)="onClear()"'
    + ' [style]="style" [inputStyle]="inputStyle" (completeMethod)="searchKontrakt($event)" (onSelect)="onSelect($event)" *ngIf="edit"></p-autoComplete>'
    + '<div class= "p-inputtext ui-widget ui-state-default ui-corner-all ui-div-inputtext" *ngIf="!edit" title="{{selectedDto?.kontrakt}}">{{selectedDto?.kontrakt}}</div>',
  styles: [],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => VyrobaKontraktAutocompleteComponent),
    multi: true
  }]
})
export class VyrobaKontraktAutocompleteComponent implements ControlValueAccessor {

  // GUID vybraného kontraktu
  private _value: string = null;

  // GUID odběratele
  private _odberatelGuid: string = null;

  // všechny kontrakty pro daného odběratele
  private _kontraktyVse: COdberatelKontraktListDto[] = null;

  // běžící request pro stažení dat
  private _request: EventEmitter<COdberatelKontraktListDto[]> = null;

  @ViewChild('ac', { static: true }) kontraktAutocomplete: AutoComplete;

  constructor(private ciselnikyService: CiselnikyService) { }

  @Input() style: any = { 'width': '100%' };
  @Input() inputStyle: any = { 'width': 'calc(100% - 35px)' };

  @Input() set odberatelGuid(val: string) {
    if (val != this._odberatelGuid) {
      this._odberatelGuid = val;
      this._request = null;
      this._kontraktyVse = null;

      if (this._odberatelGuid) {
        this.initValue();
      }
      else {
        this.value = null;
      }
    }
  }

  get odberatelGuid() {
    return this._odberatelGuid;
  }

  @Output() onChange: EventEmitter<COdberatelKontraktListDto> = new EventEmitter<COdberatelKontraktListDto>();

  // Kontrakt vybraný v autocomplete
  selectedDto: COdberatelKontraktListDto = null;

  // Nabídka kontraktů pro autocomplete
  kontrakty: COdberatelKontraktListDto[] = [];

  // proměnné pro implementaci rozhraní ControlValueAccessor
  onModelChange: any = () => { };
  onModelTouched: any = () => { };
  disabled: boolean = false;

  @Input() edit: boolean = true;

  get value() {
    return this._value;
  }

  set value(val) {
    this._value = val;

    if (this.value) {
      this.initValue();
    }

    this.onModelChange(val);
    this.onModelTouched();
  }

  // implementace rozhraní ControlValueAccessor
  writeValue(obj: any): void {
    this.value = obj;
  }
  // implementace rozhraní ControlValueAccessor
  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }
  // implementace rozhraní ControlValueAccessor
  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }
  // implementace rozhraní ControlValueAccessor
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * Stažení dat z WebAPI - hlídá jedinečnost requestu, pokud se zavolá vícekrát, tak vrací observable na stejný request
   */
  private getData(): Observable<COdberatelKontraktListDto[]> {
    if (this._kontraktyVse) {
      return of(this._kontraktyVse);
    }
    else if (this._request) {
      return this._request;
    }
    else {
      this._request = new EventEmitter<COdberatelKontraktListDto[]>();
      this.ciselnikyService.findKontrakt('', this._odberatelGuid).subscribe(data => {
        this._kontraktyVse = data;
        this._request.emit(data);
      });
      return this._request;
    }
  }

  /**
   * Inicializace hodnoty - stáhne seznam kontraktů odběratele a inicializuje DTO podle GUIDu
   */
  private initValue() {
    if (this._odberatelGuid) {
      this.getData().subscribe(data => {
        if (this._value) {
          this.selectedDto = data.find(x => x.guid == this._value);
          if (!this.selectedDto) { // pokud není kontrakt GUID nalezen v odpovědi serveru, musí se vynulovat (= změna odběratele)
            this.value = null;
          }
        }
        else if (data && data.length == 1) {
          this.selectedDto = data[0];
          this.value = this.selectedDto.guid;
        }
        else {
          this.selectedDto = null;
          this.value = null;
        }
        this._kontraktWorkaround();
        this.onChange.emit(this.selectedDto);
      });
    }
  }

  /**
   * Callback výběru kontraktu z nabídky autocomplete
   * @param value
   */
  onSelect(value: COdberatelKontraktListDto) {
    this.value = value.guid;
    this.onChange.emit(value);
  }

  /**
   * Callback vyčištění inputu
   */
  onClear() {
    this.value = null;
    this.selectedDto = null;
    this.onChange.emit(null);
  }

  /**
 * Callback pro vyhledání kontraktu v autocomplete komponentě.
 * @param event
 */
  searchKontrakt(event) {
    this.getData().subscribe(data => {
      this.kontrakty = data.filter(x => x.kontrakt.includes(event.query));
    });
  }

  /**
   * workaround na bug v autocomplete: https://github.com/primefaces/primeng/issues/3641
   */
  private _kontraktWorkaround(): void {
    if (this.kontraktAutocomplete != void 0) {
      setTimeout(() => this.kontraktAutocomplete.inputEL.nativeElement.value = this.selectedDto?.kontrakt);
    }
  }
}
