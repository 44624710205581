import { Component, OnInit } from '@angular/core';
import { VmlDetailDto } from 'src/app/Dto/Vyroba/VmlDetailDto';
import { VyrobaService } from 'src/app/Services/Vyroba/vyroba.service';
import { SkladZdrojDto } from 'src/app/Dto/Vyroba/SkladZdrojDto';
import { faPlusSquare, faMinusSquare } from '@fortawesome/free-solid-svg-icons';

interface IVypocetUce {
  vykonGuid: string,
  prostredekGuid: string,
  orgUrGuid: string,
  mjGuid: string,
  plocha: number,
  mnozstvi: number,
  kc: number
}

interface IVypocetMzdy {
  dodavatelGuid: string,
  vykonGuid: string,
  druhMzdyGuid: string,
  kc: number
}

@Component({
  selector: 'app-vyroba-vml-nahled',
  templateUrl: './vyroba-vml-nahled.component.html',
  styleUrls: ['./vyroba-vml-nahled.component.css']
})
export class VyrobaVmlNahledComponent implements OnInit {

  showDialog: boolean = false;

  faPlus = faPlusSquare;
  faMinus = faMinusSquare;

  uce: IVypocetUce[] = [];
  mzdy: IVypocetMzdy[] = [];
  sklad: SkladZdrojDto[] = [];
  
  data: VmlDetailDto = new VmlDetailDto();

  constructor(
    private vyrobaService: VyrobaService 
  ) { }

  ngOnInit(): void {
  }

  /**
   * Spočítá souhrny z dokladu a zobrazí okno
   */
  public show(data: VmlDetailDto): void {

    this.data = data;
    this.sklad = [];
    this.vyrobaService.vmlGetSklad(data).subscribe(result => {
      if (result.success) {
        this.sklad = result.data;
      }
    });

    let mzdy: IVypocetMzdy[] = [];
    let uce: IVypocetUce[] = [];
  
    data.radky.forEach((r) => {
      // do mezd a účta jdou řádky s korunami
      if (r.kc) {
        if (r.dodavatelGuid) {
          if (r.druhMzdyGuid) {
            mzdy.push({
              dodavatelGuid: r.dodavatelGuid,
              druhMzdyGuid: r.druhMzdyGuid,
              vykonGuid: r.vykonGuid,
              kc: r.kc
            });
          }

          r.premieNahrady.forEach((p) => {
            if (p.druhMzdyGuid) {
              mzdy.push({
                dodavatelGuid: r.dodavatelGuid,
                druhMzdyGuid: p.druhMzdyGuid,
                vykonGuid: r.vykonGuid,
                kc: p.kc
              });
            }
          });
        }

        let jeProstredek = false;

        r.prostredky.forEach((p) => {
          // TODO: odlišit účelový/využitý?
          if (p.prostredekGuid && p.vykonGuid && p.podvykonGuid) {
            jeProstredek = true;
            uce.push({
              vykonGuid: p.vykonGuid,
              prostredekGuid: p.prostredekGuid,
              orgUrGuid: p.domOrgUrGuid,
              mjGuid: r.mjGuid,
              plocha: r.plocha,
              kc: r.kc,
              mnozstvi: p.mnozstvi
            });
          }
        });

        if (!jeProstredek) {
          uce.push({
            vykonGuid: r.vykonGuid,
            prostredekGuid: null,
            // měla by tu být LS, ale třeba nebude vadit když zde bude vidět i úsek... pokud bude vadit, budem muset řešit
            orgUrGuid: data.orgUrGuid,
            mjGuid: r.mjGuid,
            plocha: r.plocha,
            kc: r.kc,
            mnozstvi: r.mnozstvi
          });
        }
      }
    });

    this.mzdy = mzdy;
    this.uce = uce;

    this.showDialog = true;
  }
}
