import { EventEmitter, Input, Output } from '@angular/core';
import { ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NacrtProjektSourceDto } from '../../../Dto/Nacrty/NacrtProjectSourceDto';
import { faTimes, faTrash, faSave } from '@fortawesome/free-solid-svg-icons';
import { ConfirmationService, MessageService } from 'primeng/api';
import { NacrtProjektDto } from '../../../Dto/Nacrty/NacrtProjektDto';
import { SyncService } from '../../../Services/Sync/sync.service';
import { MapToTreeDtoUtils } from '../../../Utils/Mapa/map-to-tree-dto.utils';
import { MessagesUtils } from '../../../Utils/Shared/messages.utils';
import { RoleUtils } from '../../../Utils/Shared/role.utils';
import { OrgUrMultiSelectComponent } from '../../Shared/org-ur-multi-select/org-ur-multi-select.component';
import { NacrtyStyleInteractionService } from '../../../Services/Nacrty/nacrty-style-interaction.service';

/*
 * Komponenta editace projektu náčrtů ze seznamu projektu náčrtů
*/
@Component({
  selector: 'app-nacrty-projekt-edit',
  templateUrl: './nacrty-projekt-edit.component.html',
  styleUrls: ['./nacrty-projekt-edit.component.css']
})
export class NacrtyProjektEditComponent implements OnInit {

  constructor
  (
    private formBuilder: FormBuilder,
    private roleUtils: RoleUtils,
    private syncService: SyncService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private mapToTreeDtoUtils: MapToTreeDtoUtils,
    private nacrtStyleInteractionService: NacrtyStyleInteractionService
  )
  {
  }

  faSave = faSave;
  faTrash = faTrash;
  faTimes = faTimes;

  public readonly NACRT_PROJEKT_EDIT_TOAST: string = "nacrt_projekt_edit_toast";
  public readonly NACRT_PROJEKT_EDIT_CONFIRM_POPUP: string = "nacrt_projekt_edit_confirm_popup";

  @ViewChild("orgUrMultiselect") orgUrMultiselect: OrgUrMultiSelectComponent;

  /*
   * Skupiny projektů náčrtů
  */
  @Input() skupiny: string[];

  /*
  * Indikuje, jestli stojí editační formulář nad novým náčrtem
  */
  public get newNacrt(): boolean {
    return !this._projekty?.includes(this._selectedProjekt);
  }

  /*
   * Source dto pro vytvoření stromu projektu náčrtů  
  */
  nacrtyProjectsTreeSources: NacrtProjektSourceDto[] = [];

  /*
   * Projekty náčrtů
  */
  @Input() set projekty(value: NacrtProjektDto[]) {
    if (value != void 0) {
      this._projekty = value;
      this.nacrtyProjectsTreeSources = this.mapToTreeDtoUtils.fromProjectDetailToProjSourceDto(value);
    }
  }

  private _projekty: NacrtProjektDto[];

  /*
   * Vybraný projekt ze seznamu projektu náčrtů
  */
  @Input() set selectedProjekt(val: NacrtProjektDto) {
    if (val != void 0) {
      this._selectedProjekt = val;
      this.nacrtyProjectsTreeSources = this.nacrtyProjectsTreeSources.filter(x => x.data?.guid != val?.guid);

      this.fillRgba = val.fillRgba;
      this.strokeRgba = val.strokeRgba;
      this.circleFillRgba = val.circleFillRgba;
      this.circleStrokeRgba = val.circleStrokeRgba;

      this.form.reset();
      this.form.patchValue(val);
      this.showForm = true;
      this.onEditableStyleChange(val.editableStyle);
    }
    else this.showForm = false;
  }

  private _selectedProjekt: NacrtProjektDto;

  /*
   * Emituje zrušení editačního formuláře
  */
  @Output() onCancel = new EventEmitter();

  /*
   * Emituje uložení editačního formuláře
  */
  @Output() onSave: EventEmitter<NacrtProjektDto> = new EventEmitter();

  /*
   * Událost předávající guid smazaného projektu.
  */
  @Output() onDelete: EventEmitter<string> = new EventEmitter();

  /*
   * Indikuje, zda je blokované UI komponenty
  */
  block: boolean = false;

  /*
   * Indikuje, zda je možné formulář zobrazit na základě uživatelských rolí
  */
  canShowForm: boolean = false;

  /*
   * Indikuje zobrazení formuláře
  */
  showForm: boolean = false;

  /*
   * Indikuje, zda je uživatel admin
  */
  userIsAdmin: boolean = false;

  /*
   * Indikuje, zda byl formulář validní před odškrtnutím úpravy stylu
  */
  validBeforeEditableStyleUnchecked: boolean = false;

  /*
   * Názvy skupin pro autocomplete
  */
  skupinaResults: string[] = [];

  /*
   * Editační formulář projektů náčrtů
  */
  form: FormGroup;

  /*
   * Barva výplně plochy náčrtu
  */
  fillRgba: string = 'rgba(255,255,255,1)';

  /*
   * Barva okraje plochy a linie náčrtu
  */
  strokeRgba: string = 'rgba(255,255,255,1)';

  /*
   * Barva výplně bodu náčrtu
  */
  circleFillRgba: string = 'rgba(255,255,255,1)';

  /*
   * Barva okraje bodu nýčrtu
  */
  circleStrokeRgba: string = 'rgba(255,255,255,1)';


  ngOnInit(): void {

    if (this.roleUtils.checkRole(['NacrtyAdmin'])) {
      this.userIsAdmin = true;
      this.canShowForm = true;
    }
    else if (this.roleUtils.checkRole(['NacrtyEditor'])) {
      this.canShowForm = true;
    }

    this.form = this._createForm();
  }

  /**
 * Prohleda skupiny kvuli autocompletu skupiny ve formu
 * @param event
 */
  searchSkupiny(event): void {
    let query: string = event.query.trim().toLowerCase();
    query == '' ? this.skupinaResults = [] : this.skupinaResults = this.skupiny.filter(x => x.trim().toLowerCase().includes(query));
  }

  /**
   * Zruší editaci projektu
  */
  cancel(): void {
    this.onCancel.emit();
  }

  /**
    * Uloží editovaný projekt
  */
  save(): void {
    let saveableDto: NacrtProjektDto = this._toSaveableDto();
    this._syncPost(saveableDto);
  }

  /*
   * Otevře popup s potvrzením smazání projektu náčrtu
  */
  delete(event): void {
    this.confirmationService.confirm({
      target: event.currentTarget,
      key: this.NACRT_PROJEKT_EDIT_CONFIRM_POPUP,
      message: 'Skutečně si přejete smazat vybraný projekt náčrtů?',
      icon: 'pi pi-exclamation-triangle',
      accept: this._deleteAccept.bind(this),
      acceptLabel: 'Smazat',
      acceptButtonStyleClass: 'p-button-danger',
      reject: () => { },
      rejectLabel: 'Zavřít'
    });
  }

  /*
   * Smaže editovaný náčrt, pokud to uživatel schválí
  */
  private _deleteAccept() {
    let copyDto: NacrtProjektDto = Object.assign({}, this.form.value);
    copyDto.type = 'DNacrtProjekt';
    copyDto.sync.d = true;
    this._syncPost(copyDto);
  }

  /**
   * Zpracuje syncApi post podle obsahu dto
   * @param dto {NacrtProjektDto} Dto, které se pošle na syncApi
   */
  private _syncPost(dto: NacrtProjektDto): void {
    this.block = true;
    this.nacrtStyleInteractionService.removePinCanvas('nacrty_' + dto.guid);

    this.syncService.post([dto]).subscribe(res => {
      let keyword: string = dto.sync?.d ? "mazání" : "ukládání"; 

      if (res.isConflict) {
        this.messageService.add({
          key: this.NACRT_PROJEKT_EDIT_TOAST, summary: 'Konflikt', severity: 'error',
          detail: `Při ${keyword} projektu náčrtů došlo ke konfliktu.`,
          life: MessagesUtils.TOAST_LIFE
        });
      }
      else if (res.isError) {

        res.errors?.forEach(error => {
          this.messageService.add({
            key: this.NACRT_PROJEKT_EDIT_TOAST, summary: 'Chyba', severity: 'error',
            detail: error.text,
            life: MessagesUtils.TOAST_LIFE
          });
        });

        if (res.errors == void 0 || res.errors.length == 0) {
          this.messageService.add({
            key: this.NACRT_PROJEKT_EDIT_TOAST, summary: 'Chyba', severity: 'error',
            detail: `Při ${keyword} projektu náčrtů došlo k chybě.`,
            life: MessagesUtils.TOAST_LIFE
          });
				}        
      }
      else {
        keyword = dto.sync?.d ? "smazán" : "uložen";
        this.messageService.add({
          key: this.NACRT_PROJEKT_EDIT_TOAST, summary: 'Úspěch', severity: 'success',
          detail: `Projekt náčrtů byl úspěšně ${keyword}.`,
          life: MessagesUtils.TOAST_LIFE
        });

        if (dto.sync?.d) this.onDelete.emit(dto.guid);
        else this.onSave.emit(dto);
      }
      this.block = false;
    });
  }

  /**
 * Zpracuje změnu úpravy stylu při editaci
 * @param event
 */
  onEditableStyleChange(checked: boolean): void {
    this.validBeforeEditableStyleUnchecked = this.form.valid;
    for (const key in this.form.controls)
    {
      if (key != 'editableStyle') {
        checked ? this.form.get(key).enable() : this.form.get(key).disable();
      }
    }
  }

  /**
   * Handler přidání org. úrovně do seznamu
   * @param orgUr
   */
  onOrgUrAdded(orgUr: string): void {
    if (!this.orgUrMultiselect.userOrgUrs.includes(orgUr)) {
      this.orgUrMultiselect.revertLastAdded();

      this.messageService.add({
        key: this.NACRT_PROJEKT_EDIT_TOAST,
        summary: 'Chyba',
        severity: 'error',
        detail: "K přidání této organizační úrovně nemáte oprávnění!",
        life: MessagesUtils.TOAST_LIFE
      });
    }
  }

  /**
   * Handler odstranění org. úrovně ze seznamu
  */
  onOrgUrRemoved(orgUr: string): void {
    if (!this.orgUrMultiselect.userOrgUrs.includes(orgUr)) {
      this.orgUrMultiselect.revertLastRemoved();

      this.messageService.add({
        key: this.NACRT_PROJEKT_EDIT_TOAST,
        summary: 'Chyba',
        severity: 'error',
        detail: "K odebrání této organizační úrovně nemáte oprávnění!",
        life: MessagesUtils.TOAST_LIFE
      });
    }
  }

  /*
   * Vytvoří správnou podobu formu
  */
  private _createForm(): FormGroup {
    return this.formBuilder.group({
      guid: [''],
      skupina: ['', [Validators.required, Validators.maxLength(255)]],
      nazev: ['', [Validators.required, Validators.maxLength(255)]],
      zIndex: [1001, [Validators.required, Validators.min(1000)]],
      orgUrGuids: [[], Validators.required],
      bod: [true, this._checkedGeometriesValidator()],
      linie: [true, this._checkedGeometriesValidator()],
      plocha: [true, this._checkedGeometriesValidator()],
      strokeWidth: [3],
      circleStrokeWidth: [3],
      circleRadius: [16],
      editableStyle: this.userIsAdmin ? [{ value: false, disabled: false }] : [{ value: false, disabled: true }],
      editableGeometries: [false],
      schvalProjektGuid: [''],
      sync: [null],
      iconName: [''],
      lineTypeName: ['']
    });
  }

  /*
   * Vrací validátor kontrolující, jestli je vybraný alespoň jeden checkbox geometrie
  */
  private _checkedGeometriesValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let checkboxes: AbstractControl[] = this.form == void 0 ? [] : [this.form.get('bod'), this.form.get('linie'), this.form.get('plocha')]
      let pristineCount: number = checkboxes.filter(x => x.pristine).length;
      let uncheckedCount: number = checkboxes.filter(x => !x.value).length;
      if (pristineCount < checkboxes.length) {
        checkboxes.forEach(geometrie => { geometrie.setErrors(null); });
      }
      return uncheckedCount > 2 ? { tooManyUnchecked: { value: control.value } } : null;
    };
  }

  /**
   * Konverze dat formuláře do dto, které se pošle na web-api.
  */
  private _toSaveableDto(): NacrtProjektDto {
    let dto: NacrtProjektDto = this.form?.value;
    dto.editableStyle = this.form.get('editableStyle').value;
    dto.type = 'DNacrtProjekt';
    if (dto != void 0) {

      if (!dto.editableStyle) {
        Object.keys(this.form.controls).forEach(key => dto[key] = this.form.get(key)?.value);
      }
      
      dto.circleFillRgba = this.circleFillRgba;
      dto.circleStrokeRgba = this.circleStrokeRgba;
      dto.fillRgba = this.fillRgba;
      dto.strokeRgba = this.strokeRgba;
    }

    return dto;
  }


  /**
   * Handler změny ikony.
   * @param event {string} název vybrany ikony z knihovny, nebo null
   */
  onIconChange(event: string): void {
    this.iconName?.setValue(event);
  }

  /**
   * Getter názvu ikony z formuláře.
  **/
  get iconName(): AbstractControl {
    return this.form?.get('iconName');
  }


  /**
   * Handler změny typu čáry.
   * @param event {string} klíč typu čáry v knihovně typů čar
   */
  onLineTypeChange(event: string): void {
    this.lineType.setValue(event);
  }


  /**
   * Getter klíče typu čáry.
  **/
  get lineType(): AbstractControl {
    return this.form?.get('lineTypeName');
  }
}
