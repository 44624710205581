import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { RoleEnum } from '../Dto/Core/RoleEnum';
import { RoleUtils } from '../Utils/Shared/role.utils';

/**
 * Guard hlídající přístup uživatele dle role "SkodyAdmin".
**/
@Injectable({
  providedIn: 'root'
})
export class SkodyAdminGuard implements CanActivate {
  constructor(private roleUtils: RoleUtils) { }

  canActivate() {
    return this.roleUtils.checkRole([RoleEnum.SkodyAdmin]);
  }
}
