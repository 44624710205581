import { Component, Input, OnInit } from '@angular/core';
import { DotazovacService } from '../../../Services/Dotazovac/dotazovac.service';

@Component({
  selector: 'app-dotazovac-model-display',
  templateUrl: './dotazovac-model-display.component.html'
})
export class DotazovacModelDisplayComponent implements OnInit {

  constructor(private dotazovacService: DotazovacService) { }

  ngOnInit(): void {
  }

  nazev: string;

  @Input() set modelId(id: string) {
    this.dotazovacService.listModels().subscribe(res => {
      if (res.success) {
        this.nazev = res.data?.find(x => x.modelId == id)?.nazev;
      }
      if (this.nazev == void 0) this.nazev = "Nenalezeno";
    });
  }
}
