<p-toast position="top-center" key="lhc-list-toast"></p-toast>

<p-toolbar styleClass="base-toolbar" [style]="{'margin-bottom': '1em'}">
  <div class="p-toolbar-group-left">
    <h2 class="toolbar-label">Seznam LHC</h2>
    <i class="p-toolbar-separator"><fa-icon [icon]="faEllipsisV"></fa-icon></i>
    <button pButton type="button" label="Import" [ngClass]="'icon-btn'" (click)="displayImport=true"><fa-icon [icon]="faFileImport"></fa-icon></button>
    <div style="display: inline-block">
      <div class="p-inputgroup">
        <input type="text" pInputText placeholder="Hledat" [(ngModel)]="filter" style="width: 20em" />
        <button pButton type="button" class="icon-btn hide-label-button" (click)="filter = undefined" [disabled]="filter == undefined"><fa-icon [icon]="faBackspace"></fa-icon></button>
      </div>
    </div>
    <button pButton type="button" label="Detail" [ngClass]="'icon-btn'" [disabled]="!selectedLhc" (click)="goToDetail()"><fa-icon [icon]="faSearch"></fa-icon></button>
    <app-lhc-org-ur-settings [lhc]="selectedLhc" [disabled]="!selectedLhc"></app-lhc-org-ur-settings>
    <app-delete [data]="selectedLhc"
                (deleteSuccess)="onDeleteSuccess()">
    </app-delete>
  </div>
  <div class="p-toolbar-group-right">
    <button pButton type="button" label="Zpět" class="icon-btn" (click)="back()"><fa-icon [icon]="faUndo"></fa-icon></button>
  </div>
</p-toolbar>

<propla-table [cols]="lhcCols" [allItems]="lhcItems" [selectionMode]="'single'" [filterGlobal]="filter"
  (rowSelected)="onRowSelected($event)"
  (rowUnSelected)="onRowUnselected($event)">
</propla-table>

<ng-template #colDatum let-rowData let-col="column">
  {{rowData[col.field] | date:'d.M.yyyy'}}
</ng-template>

<ng-template #colDatumCas let-rowData let-col="column">
  {{rowData[col.field] | date:'d.M.yyyy h:mm:ss'}}
</ng-template>

<app-import [display]="displayImport" (close)="displayImport=false"></app-import>

