import { Component, Input } from '@angular/core';
import { PskDetailDto } from '../../../Dto/Lhp/PskDetailDto';

@Component({
  selector: 'app-kniha-detail-psk',
  templateUrl: './kniha-detail-psk.component.html',
  styleUrls: ['./kniha-detail-psk.component.css']
})
export class KnihaDetailPskComponent {

  constructor() { }

  @Input() psk: PskDetailDto;

}
