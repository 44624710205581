<p-toast position="top-center" key="org-ur-settings-toast"></p-toast>
<button pButton type="button" icon="pi pi-check-circle" title="Nastavit organizační úroveň" label="Organizační úroveň" (click)="showDialog()"
        [disabled]="disabled"></button>

<p-dialog header="Nastavení organizační úrovně pro LHC {{lhcKod}} - {{lhcNazev}}" [(visible)]="displayDialog" [modal]="true"
          [style]="{width: '50%'}" [contentStyle]="{height: '70vw', 'padding-bottom': '0px'}" (onHide)="onHideDialog()">
  <div class="p-grid lhc-org-ur-settings">
    <div class="p-col-5">
      <p-panel>
        <ng-template pTemplate="header">
          <span style="font-weight:bold"> Volba organizační úrovně </span><app-org-ur-display mode="admin" [zakaznikGuid]="zakaznikGuid" [guid]="orgUrGuid" *ngIf="orgUrGuid"></app-org-ur-display>
        </ng-template>
        <p-tree [value]="orgUrTree" [loading]="loadingOrgUr" selectionMode="single" (onNodeSelect)="selectOrgUr()" [(selection)]="selectedOrgUr" scrollHeight="flex"
                emptyMessage="Ve zvoleném LHC se nenachází žádná organizační úroveň nebo došlo k technické chybě"></p-tree>
      </p-panel>
    </div>
    <div class="p-col-7">
      <p-treeTable #LhcTreeTable [value]="lhcTree" [columns]="cols" [loading]="loading" selectionMode="checkbox" [(selection)]="selectedNodes"
                   [scrollable]="true" scrollHeight="flex">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of cols" [ngClass]="{'jprl-col': col==cols[0], 'org-ur-top-header': col==cols[1]}">
              {{col.header}}
            </th>
          </tr>
          <tr>
            <th class="jprl-col">
              <p-treeTableHeaderCheckbox pTooltip="Vybrat vše" tooltipPosition="top"></p-treeTableHeaderCheckbox>
              <span class="p-ml-2">
                <button pButton class="icon-btn hide-label-button layers-panel-button"
                        pTooltip="Rozbalit vše"
                        tooltipPosition="top"
                        (click)="expandAll()">
                  <fa-layers [fixedWidth]="true">
                    <fa-icon [icon]="faChevronDown" transform="up-3"></fa-icon>
                    <fa-icon [icon]="faChevronDown" transform="down-4"></fa-icon>
                  </fa-layers>
                </button>
                <button pButton class="icon-btn hide-label-button layers-panel-button"
                        pTooltip="Sbalit vše"
                        tooltipPosition="top"
                        (click)="collapseAll()">
                  <fa-layers [fixedWidth]="true">
                    <fa-icon [icon]="faChevronUp" transform="up-3"></fa-icon>
                    <fa-icon [icon]="faChevronUp" transform="down-4"></fa-icon>
                  </fa-layers>
                </button>
              </span>
            </th>

            <th class="org-ur-filter" style="font-weight: normal;" pTooltip="Omezit výpis podle zvolené OÚ. Zobrazí se vše po promazání políčka"  tooltipPosition="top">
              <app-org-ur mode="admin" [zakaznikGuid]="zakaznikGuid" (valueChanged)="LhcTreeTable.filter($event, 'orgUrGuid', cols[1].filterMatchMode)"></app-org-ur>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
          <tr>
            <td class="jprl-col">
              <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
              <p-treeTableCheckbox [value]="rowNode"></p-treeTableCheckbox>
              <ng-template [ngIf]="rowNode.node.type=='OddListDto'">
                {{rowData.odd}}
              </ng-template>
              <ng-template [ngIf]="rowNode.node.type=='DilListDto'">
                {{rowData.dil}}
              </ng-template>
              <ng-template [ngIf]="rowNode.node.type=='PorOrgUrListDto'">
                {{rowData.por}}
              </ng-template>
            </td>
            <td>
              <ng-template [ngIf]="rowNode.node.type=='OddListDto'">
                <ng-template ngFor let-item [ngForOf]="getPorOrgUrFromOdd(rowNode.node.children)">
                  <app-org-ur-display mode="admin" [zakaznikGuid]="zakaznikGuid" [guid]="item"></app-org-ur-display><span *ngIf="lastPorOrgUrFromOdd!=item">, </span>
                </ng-template>
              </ng-template>
              <ng-template [ngIf]="rowNode.node.type=='DilListDto'">
                <ng-template ngFor let-item [ngForOf]="getPorOrgUrFromDil(rowNode.node.children)">
                  <app-org-ur-display mode="admin" [zakaznikGuid]="zakaznikGuid" [guid]="item"></app-org-ur-display><span *ngIf="lastPorOrgUrFromDil!=item">, </span>
                </ng-template>
              </ng-template>
              <ng-template [ngIf]="rowNode.node.type=='PorOrgUrListDto'">
                <app-org-ur-display mode="admin" [zakaznikGuid]="zakaznikGuid" [guid]="rowData.orgUrGuid"></app-org-ur-display>
              </ng-template>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td [attr.colspan]="cols.length" *ngIf="!loading">Ve zvoleném LHC se nenachází žádné JPRL nebo došlo k technické chybě</td>
          </tr>
        </ng-template>
      </p-treeTable>
    </div>
  </div>
  <p-footer>
    <button type="button" pButton icon="pi pi-check" label="Uložit" (click)="save()" [disabled]="!selectedNodes || selectedNodes.length==0 || orgUrGuid==null"></button>
    <button type="button" pButton icon="pi pi-times" label="Zavřít" (click)="displayDialog=false" class="p-button-secondary"></button>
  </p-footer>
</p-dialog>
