 <input pInputText [(ngModel)]="datum" (click)="op.show($event)" (ngModelChange)="dateChanged($event)" autocomplete="off" style="width:100%;" />


<p-overlayPanel #op [showCloseIcon]="true" appendTo="body">
  <div class="p-grid">
    <div class="p-col-6" style="font-weight:bold"> Od </div><div class="p-col-6" style="font-weight:bold"> Do </div>
    <div class="p-col-6">
      <p-calendar useUtc [(ngModel)]="value[0]" [maxDate]="value[1]" [firstDayOfWeek]="1" [inline]="true" (onSelect)="onCalChanged()"></p-calendar>
    </div>
    <div class="p-col-6">
      <p-calendar useUtc [(ngModel)]="value[1]" [minDate]="value[0]" [firstDayOfWeek]="1" [inline]="true" (onSelect)="onCalChanged()"></p-calendar>
    </div>
  </div>
  <div class="p-col-12" style="text-align:right;">
    <button pButton label="Zavřít" icon="pi pi-check" (click)="op.hide()" type="button"></button>
  </div>
</p-overlayPanel>
