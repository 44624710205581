<button pButton type="button" class="icon-btn hide-label-button"
        pTooltip="Přehled vybraných JPRL" tooltipPosition="bottom"
        (click)="displayDialog=true" [disabled]="lhpItems == undefined || lhpItems.length == 0">
  <fa-icon [icon]="faTable"></fa-icon>
</button>

<p-dialog header="Přehled vybraných JPRL" [(visible)]="displayDialog" [modal]="true" [style]="{width: '60vw'}" [baseZIndex]="10000"
          [draggable]="false" [resizable]="false" [contentStyle]="{height: '100vw', 'padding-bottom': '0px'}" (onHide)="displayDialog=false;">
  <p-table #dt [columns]="cols" [value]="lhpItems" [scrollable]="true" scrollHeight="flex" styleClass="p-datatable-striped pds-table-compact map-info-lhp-list"
           (sortFunction)="jprlSort($event)" [customSort]="true" sortMode="multiple">
    <ng-template pTemplate="caption">
      <div class="p-d-flex">
        <button type="button" pButton pRipple icon="pi pi-file-o" (click)="dt.exportCSV()" pTooltip="Export do CSV" tooltipPosition="bottom"></button>
        <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success p-ml-2"  pTooltip="XLS" tooltipPosition="bottom"></button>
      </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns" pSortableColumn="{{col.field}}" pTooltip="{{col.title}}" tooltipPosition="bottom">
          {{col.header}}<p-sortIcon field="code"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item let-columns="columns">
      <tr>
        <ng-container *ngFor="let col of cols">
          <td *ngIf="col.field!='plocha'">
            {{rowContent(col.field, item)}}
          </td>
          <td *ngIf="col.field=='plocha'">
            {{rowContent(col.field, item) | number : '.2-2' : 'cs'}}
          </td>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="footer">
      <tr class="suma-row" tooltipPosition="bottom" pTooltip="Sumární řádek">
        <td>∑</td>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
        <td>{{sumaPloch | number : '.2-2' : 'cs'}}</td>
      </tr>
    </ng-template>
  </p-table>

  <ng-template pTemplate="footer">
    <button type="button" pButton icon="pi pi-times" label="Zavřít" (click)="displayDialog=false"></button>
  </ng-template>
</p-dialog>
