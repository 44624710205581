<p-dialog header="Import" [(visible)]="display" [modal]="true" [style]="{width: '900px'}">

  <app-block-ui [block]="block"></app-block-ui>

  <div class="p-grid validated-form-container" [formGroup]="form">
    <div class="p-col-4">
      Zákazník
      <app-zakaznik-dropdown #zakaznikDropdown formControlName="zakaznik" [mode]="accessMode"></app-zakaznik-dropdown>
    </div>
    <div class="p-col-8">
      Poznámka
      <input type="text" pInputText formControlName="poznamka" [ngStyle]="{'width':'100%'}" />
    </div>
  </div>

  <p-fileUpload name="file" url="api/lhp/Import/Upload" accept=".xml" (onUpload)="onXmlUpload($event)" (onBeforeUpload)="onBeforeUpload($event)" auto="true" chooseLabel="Procházet" [disabled]="block || !form.valid">
    <ng-template pTemplate="content">
      <p-messages key="lhc-import-messages"></p-messages>
      <div class="p-grid validated-form-container" [formGroup]="form">
        <div class="p-col-12">
          <p-table [value]="uploadedFiles">
            <ng-template pTemplate="colgroup">
              <colgroup>
                <col [style.width]="'10%'">
                <col [style.width]="'50%'">
                <col [style.width]="'30%'">
                <col [style.width]="'10%'">
              </colgroup>
            </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th>Kód LHC</th>
                <th>Název LHC</th>
                <th>Soubor</th>
                <th>Ročník</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-fileInfo>
              <tr>
                <td>{{fileInfo.response.lhc.lhcKod}}</td>
                <td>{{fileInfo.response.lhc.lhcNaz}}</td>
                <td>{{fileInfo.file.name}}</td>
                <td>{{fileInfo.response.lhc.rocnik}}</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </ng-template>
  </p-fileUpload>


  <p-footer>
    <button type="submit" pButton icon="pi pi-check" label="Spustit import" (click)="runImport()" [disabled]="block || !form.valid"></button>
    <button type="button" pButton icon="pi pi-times" label="Zavřít" (click)="display=false" class="p-button-secondary" [disabled]="block"></button>
  </p-footer>

</p-dialog>

