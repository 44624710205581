<p-tree [value]="layersData" scrollHeight="flex" animate="true" [filter]="true" filterMode="strict" selectionMode="checkbox"
        (onNodeSelect)="nodeSelect($event.node)" (onNodeUnselect)="nodeUnselect($event.node)" [(selection)]="selectedLayersData" emptyMessage="Seznam vrstev je prázdný nebo se načítá.">
  <ng-template let-node pTemplate="{{templateNameOverlay}}">
    <div class="tree-label" pTooltip="{{getTooltip(node)}}" tooltipPosition="bottom">{{node.label}}</div>
    <button *ngIf="node.boudingBox"
            pButton class="icon-btn hide-label-button p-button-rounded p-button-text layer-zoom-btn"
            pTooltip="Přiblížit na vrstvu"
            (click)="zoomTo($event,node.boudingBox)">
      <fa-icon [icon]="faSearch"></fa-icon>
    </button>
  </ng-template>
  <ng-template let-node pTemplate="{{templateNameBase}}">
    <p-radioButton pTooltip="{{getTooltip(node)}}" [label]="node.label" [value]="node.key" [name]="node.data['groupName']" [(ngModel)]="selectedBaseValues[node.data['groupName']]"
                    (onClick)="onBaseLayerClicked(node)"></p-radioButton>
  </ng-template>
  <ng-template let-node pTemplate="{{templateNameGroup}}">
    {{node.label}}
    <p-badge [value]="selectedGroupCounts[node.key]" *ngIf="selectedGroupCounts[node.key] > 0" styleClass="p-ml-2"></p-badge>
  </ng-template>
</p-tree>
