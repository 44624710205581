import { Component, Input, Output, EventEmitter, ViewChild, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { ImportService } from 'src/app/Services/Lhp/import.service';
import { MessagesUtils } from 'src/app/Utils/Shared/messages.utils';
import { RoleUtils } from '../../../Utils/Shared/role.utils';
import { ZakaznikDropdownComponent } from '../../Zakaznik/zakaznik-dropdown/zakaznik-dropdown.component';

@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.css']
})
export class ImportComponent implements OnInit {

  constructor(
    private importService: ImportService,
    private messageUtils: MessagesUtils,
    private messageService: MessageService,
    private formBuilder: FormBuilder,
    private roleUtils: RoleUtils
  ) { }

  @Output() close: EventEmitter<any> = new EventEmitter();

  private readonly MESSAGE_KEY: string = "lhc-list-toast";
  private readonly MESSAGE_ERR_KEY: string = "lhc-import-messages";
  uploadedFiles: any[] = [];
  accessMode: string = 'admin';

  public block: boolean = false;
  public poznamka: string;

  private _display: boolean = false;

  // workaround nefunkčního eventu skrytí dialogu: https://stackoverflow.com/a/44334902
  @Input() set display(_show: boolean) {
    this.uploadedFiles = [];
    let result: boolean = this._display != _show;
    if (result == true) {
      this._display = _show;
      if (_show == false) {
        this.close.emit();
        var lastZakaznikValue = this.form.get('zakaznik').value;
        this.form.reset();
        if (this.accessMode != 'admin') {
          this.form.get('zakaznik').setValue(lastZakaznikValue);
        }
      }   
    }
  }

  get display(): boolean {
    return this._display;
  }


  ngOnInit() {
    if (!this.roleUtils.checkRole(['SuperAdmin'])) {
      this.accessMode = 'base';
    }
  }

  //Formgroup pro validaci zákazníka
  form = this.formBuilder.group({
    zakaznik: [null, Validators.required],
    poznamka: ['']
  });


  /**
   * Handler uploadu xml pro následný import.
   * @param event
   */
  onXmlUpload(event): void {
    if (event.originalEvent?.body?.success) {
      this.messageService.clear(this.MESSAGE_ERR_KEY);
      for (let file of event.files) {
        this.uploadedFiles.push({ file: file, response: event.originalEvent.body.data });
      }
    }
    else {
      this.messageUtils.showResponseMessage(this.MESSAGE_ERR_KEY, event.originalEvent?.body);
    }
  }

  /**
   * K odesilanemu souboru prida identifikaci zakaznika
  **/
  onBeforeUpload(event) {
    event.formData.append('zakaznikGuid', this.form.get('zakaznik').value?.guid);
  }

  /**
   * Spuštění importu již uploadovaného xml.
  **/
  runImport() {
    if (this.uploadedFiles.length > 0) {
      this.block = true;
      var index: number = 0;
      for (var file of this.uploadedFiles) {
        let index = file.file.name.lastIndexOf(".");
        let fileName = file.file.name.substring(0, index);
        let zakaznikGuid = this.form.get('zakaznik').value?.guid;
        let poznamka = this.form.get('poznamka').value;
        this.importService.run(file.response.guid, fileName, zakaznikGuid, poznamka).subscribe(resp => {
          if (resp.success) {
            index++;
            if (this.uploadedFiles.length == index) {
              this.display = false;
            }
            this.messageUtils.showResponseMessage(this.MESSAGE_KEY, resp);
          }
          else {
            this.messageUtils.showResponseMessage(this.MESSAGE_ERR_KEY, resp);
          }
          this.block = false;
        });
      }
    }
  }
}
