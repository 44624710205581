import { Component, ElementRef, Input } from '@angular/core';
import { BlockableUI } from 'primeng/api';

/**
 * Komponenta pro podporu blokování oblasti stránky - div
 * Credits: https://stackoverflow.com/a/55183360, https://github.com/primefaces/primeng/issues/2003#issuecomment-318997784
 */
@Component({
  selector: 'blockable-div',
  template: '<div [ngStyle]="style" [ngClass]="class"><ng-content></ng-content></div>'
})
export class BlockableDivComponent implements BlockableUI {

  @Input() style: any;
  @Input() class: any;

  constructor(private el: ElementRef) {
  }

  getBlockableElement(): HTMLElement {
    return this.el.nativeElement.children[0];
  }

}
