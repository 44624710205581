<p-card styleClass="map-info-panel-item">
	<button *ngIf="!unselectDisabled" pButton type="button" icon="pi pi-times" class="p-button-rounded map-info-close-button" (click)="removeFromSelection()" pTooltip="Odebrat z výběru" tooltipPosition="left"></button>
	<div class="p-grid p-nogutter" style="margin-bottom:0.5rem">
		<div class="p-col nacrt-i-primary">
			<p><b>{{_item.popis}}</b></p>
		</div>
		<div class="p-col-fixed" style="width:2.5rem;">
			<app-nacrt-priloha [data]="_item"></app-nacrt-priloha>
		</div>
	</div>
	<div class="p-grid p-nogutter">
		<div class="p-col-5 nacrt-i-secondary">
			<p *ngIf="_item.plocha || _item.obvod">
				<b>
					<span *ngIf="_item.plocha">
						{{_item.plocha | formatPlocha}}
					</span>
					<span *ngIf="_item.plocha && _item.obvod"> / </span>
					<span *ngIf="_item.obvod">
						{{_item.obvod | formatDelka}}
					</span>
				</b>
			</p>
			<p>
				<b>
					<span *ngIf="_item.cislo" pTooltip="Index holiny" tooltipPosition="bottom">IH {{_item.cislo}}</span>
					<span *ngIf="_item.cislo && _item.jprl"> / </span>
					<span *ngIf="_item.jprl" (click)="goToKniha(i)" style="cursor:pointer"
								pTooltip="Kniha" tooltipPosition="bottom">
						<fa-icon [icon]="faBook" class="kniha-btn"></fa-icon>
						{{_item.jprl}}
					</span>
				</b>
			</p>
		</div>
		<div class="p-col-7 right">
			<span class="p-buttonset">
				<button pButton type="button" class="icon-btn hide-label-button"
								pTooltip="Přiblížit na náčrt" tooltipPosition="bottom"
								(click)="zoomTo()">
					<fa-icon [icon]="faSearch"></fa-icon>
				</button>
				<button pButton type="button" class="icon-btn hide-label-button"
								pTooltip="Kopírovat náčrt" tooltipPosition="bottom" [disabled]="disabledBtn || !canCopy"
								(click)="copy()">
					<fa-icon [icon]="faCopy"></fa-icon>
				</button>
				<button pButton type="button" class="icon-btn hide-label-button"
								pTooltip="Editovat náčrt" tooltipPosition="bottom" [disabled]="disabledBtn || !canEdit"
								(click)="edit()">
					<fa-icon [icon]="faPencilAlt"></fa-icon>
				</button>
				<button pButton type="button" class="icon-btn hide-label-button"
								pTooltip="Ostatní operace" tooltipPosition="left"
								(click)="ooPanel.toggle($event)">
					<fa-icon [icon]="faEllipsisV"></fa-icon>
				</button>
			</span>
		</div>
	</div>
	<div class="nacrt-i-secondary">
		<p><fa-icon [icon]="faFolder"></fa-icon> {{_item.projekt}}</p>
		<p *ngIf="_item.poznamka">{{_item.poznamka}}</p>
	</div>

	<app-map-info-nacrt-edit [nacrtProjects]="_nacrtProjects" [itemId]="_item?.id"></app-map-info-nacrt-edit>
</p-card>

<p-confirmPopup key="{{CONFIRM_KEY}}"></p-confirmPopup>
<p-overlayPanel #ooPanel styleClass="nacrty-oo-panel">
	<ng-template pTemplate>
		<!-- Tlačítko pro zobrazení dialogu dělení multipolygonu není součástí komponenty 'multipolygon-explode' kvůli jeho umístění v overlay panelu.
		Pokud bylo součástí komponenty 'multipolygon-explode' a ta součástí overlay panelu, znamenalo to, že komponenta zmizela se zavřením overlay panelu -->
		<button pButton pRipple type="button" class="icon-btn p-button-text"
						*ngIf="canExplode" [disabled]="disabledBtn || !canEdit"
						label="Rozdělit" pTooltip="Rozdělit multipolygon" tooltipPosition="bottom"
						(click)="explodeDlg.show()">
			<fa-icon [icon]="faObjectUngroup" size="lg"></fa-icon>
		</button>
		<button pButton pRipple type="button" class="icon-btn p-button-text"
						*ngIf="canSplit" [disabled]="disabledBtn || !canEdit"
						label="Rozdělit dle JPRL" pTooltip="Rozdělit hranicemi JPRL" tooltipPosition="bottom"
						(click)="splitDlg.show()">
			<fa-icon [icon]="faCut"></fa-icon>
		</button>
		<div style="display:inline-block;" pTooltip="{{schvalitTooltipText}}" tooltipPosition="bottom" *ngIf="isValidatable">
			<button pButton pRipple type="button" class="icon-btn p-button-text"
							[disabled]="!canSchvalit"	label="Schválit"
							(click)="ooPanel.hide(); schvalit();">
				<fa-icon [icon]="faCheckCircle" size="lg"></fa-icon>
			</button>
		</div>
		<button pButton pRipple type="button" class="icon-btn p-button-text" [disabled]="disabledBtn || !canDelete"
						label="Smazat" pTooltip="Smazat náčrt" tooltipPosition="bottom"
						(click)="delete($event)">
			<fa-icon [icon]="faTrash"></fa-icon>
		</button>
	</ng-template>
</p-overlayPanel>

<app-multipolygon-explode #explodeDlg [item]="_item"
													(ExplodedPolygon)="explodeHandler($event)"></app-multipolygon-explode>
<app-nacrt-split #splitDlg [item]="_item"
								 (SplittedNacrt)="splitFinishedHandler($event)"></app-nacrt-split>
