<button pButton class="icon-btn hide-label-button p-button-info" (click)="displayDialog=true;"
        pTooltip="Vlastnosti sloupců" tooltipPosition="bottom" [disabled]="items==null">
  <fa-icon [icon]="faInfoCircle"></fa-icon>
</button>

<p-dialog header="Vlastnosti sloupců" [(visible)]="displayDialog" [modal]="true" [style]="{width: '900px'}">
  <p-table [columns]="cols" [value]="items" [scrollable]="true" scrollHeight="flex">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns" [ngStyle]="{'flex-basis':col.width}">
          {{col.header}}
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
        <td *ngFor="let col of columns" [ngStyle]="{'flex-basis':col.width}">
          <span *ngIf="col.field=='nazev'||col.field=='popis'">{{rowData[col.field]}}</span>
          <fa-icon *ngIf="col.field=='isPk'&&rowData.isPk" [icon]="faKey"></fa-icon>
          <fa-icon *ngIf="col.field=='canEdit'&&!rowData.canEdit" [icon]="faSquare"></fa-icon>
          <fa-icon *ngIf="col.field=='canEdit'&&rowData.canEdit" [icon]="faCheckSquare"></fa-icon>
        </td>
      </tr>
    </ng-template>
  </p-table>

  <ng-template pTemplate="footer">
    <button type="button" pButton icon="pi pi-times" label="Zavřít" (click)="displayDialog=false" class="p-button-secondary"></button>
  </ng-template>
</p-dialog>
