import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AuthUtils } from '../Auth/auth.utils';
import { TokenDto } from '../Dto/Auth/TokenDto';
import { AuthService } from '../Services/Auth/auth.service';



@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private _refreshTokenInProgress = false;

  constructor(private auth: AuthUtils,
    private authService: AuthService,
    private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let jwt = this.auth.getJwtToken();

    if (jwt != null) {
      var token = 'Bearer ' + jwt.auth_token;

      request = request.clone({
        setHeaders: {
          Authorization: token
        }
      });
    }

    if (this.authService.apiSignout == request.url) this.authService.loggedOut.emit();//odeslat info o odhlášení až po přidání autorizační hlavičky do requestu


    return next.handle(request).pipe(
      catchError(error => {
        //K požadavku by nemělo dojít, protože ho nepustí AuthGuard. Pokud se přecejen odešle a dojde k expiraci tokenu, tak znovu přihlásit uživatelem 
        if ([401, 403].includes(error.status) && error.headers.has('Token-Expired')) {
          this.router.navigate(['/login'], { queryParams: { page: this.router.url } });
        }

        return throwError(error);
      }),
      tap(result => {//ověří čas expirace a provede refresh tokenu
        if (result instanceof HttpResponse && !this._refreshTokenInProgress) {
          this.auth.isLoggedIn().then(isLoggedIn => {
            let refreshToken = this.auth.getRefreshToken();
            if (isLoggedIn && jwt != void 0 && refreshToken != void 0) {//Pokud se jedná o jwt token a existuje refresh token
              const timeout = this._timeoutFormToken(this.auth.getJwtToken());
              if (timeout < this.auth.timeToExpMs) {
                this._refreshTokenInProgress = true;
                this.authService.refreshToken(refreshToken).subscribe((res) => {
                  if (res.success) {
                    let token = JSON.parse(res.data.token.toString());
                    this.auth.setJwtToken(token);
                    this.auth.setRefreshToken(res.data.refreshToken);
                  } else {
                    this.authService.signout().subscribe(res => {
                      this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url } });
                    });
                  }
                  this._refreshTokenInProgress = false;
                })
              }
            } else {//Pro windowsAuthentication se neprovádí refresh tokenu. Pouze se ověří stav na serveru a aktualizuje se čas expirace
              if (!isLoggedIn) {
                this._refreshTokenInProgress = true;
                this.authService.isLoged().subscribe(res => {
                  if (res == true) {
                    let token = new TokenDto();
                    token.valid_until = new Date(new Date().getTime() + this.auth.validIntevalWinMs).toString();
                    this.auth.setWinToken(token);
                    this._refreshTokenInProgress = false;
                  }
                });
              }
            }
          });
        }
      })

    );
  }

  /**
   * Vrátí zbívající ms do času expirace
   * @param token
   */
  private _timeoutFormToken(token: TokenDto): number {
    if (token != void 0 && !isNaN(Date.parse(token.valid_until))) {
      const expires = new Date(token.valid_until);
      return expires.getTime() - Date.now();
    } else {
      return 0;
    }
  }
}
