<app-block-ui [block]="block"></app-block-ui>
<p-toast position="top-center" key="vml-edit-toast"></p-toast>

<app-vyroba-kontrola #kontrolaTree [showBtn]="false"></app-vyroba-kontrola>

<div [formGroup]="formData" class="vml-body" nextOnEnter>
  <p-panel>
    <ng-template pTemplate="header">
      <div class="p-grid p-align-center">
        <div class="p-col"><h2 class="p-panel-title">Výrobně mzdový lístek</h2></div>
        <div class="p-col right">
          <button pButton type="button" label="Náhled" class="icon-btn p-button-secondary" (click)="nahled.show(formData.value)" *ngIf="edit" id="vml-calc-button"><fa-icon [icon]="faCalculator"></fa-icon></button>
          <button pButton type="button" label="Uložit rozpracované" class="icon-btn p-button-success" (click)="save()" *ngIf="edit" id="vml-save-button" [disabled]="!dirty"><fa-icon [icon]="faSave"></fa-icon></button>
          <button pButton type="button" label="Uložit a dokončit" class="icon-btn p-button-warning" (click)="saveAndFinish()" *ngIf="edit" id="vml-finish-button"><fa-icon [icon]="faFlagCheckered"></fa-icon></button>
          <button pButton type="button" label="Docházka" class="icon-btn" (click)="showDochazkaHandler()"><fa-icon [icon]="faWalking"></fa-icon></button>
          <button pButton type="button" class="icon-btn hide-label-button" (click)="showHelp=true" *ngIf="edit"><fa-icon [icon]="faQuestion"></fa-icon></button>
          <button pButton type="button" label="Zpět" class="icon-btn" (click)="cancel()" id="vml-cancel-button"><fa-icon [icon]="faUndo"></fa-icon></button>
        </div>
      </div>
    </ng-template>
    <div class="p-grid">
      <div class="p-col-3">
        Datum dokladu
        <app-calendar formControlName="datum" id="vml-datum-calendar" [edit]="edit"></app-calendar>
      </div>
      <div class="p-col-6">
        Organizační úroveň
        <app-org-ur formControlName="orgUrGuid" id="vml-orgUrGuid" [edit]="edit" variant="full"></app-org-ur>
      </div>
      <div class="p-col-3">
        Číslo dokladu
        <input formControlName="cislo" id="vml-cislo-textbox" type="text" pInputText *ngIf="edit" />
        <div class="p-inputtext ui-widget ui-state-default ui-corner-all ui-div-inputtext" *ngIf="!edit" title="{{formData.get('cislo')?.value}}">{{formData.get('cislo')?.value}} </div>
      </div>
    </div>
  </p-panel>

  <p-panel class="vml-main" (keydown)="onKeyDown($event)">
    <ng-template pTemplate="header">
      <div class="p-grid p-align-center">
        <div class="p-col"><span class="p-panel-title">Data</span></div>
        <div class="p-col right" *ngIf="edit">
          <button pButton type="button" icon="pi pi-plus" id="vml-radek-add-button" (click)="addRadek()"></button>
          <button pButton type="button" icon="pi pi-minus" id="vml-radek-del-button" class="p-button-danger" (click)="delRadek()"></button>
        </div>
      </div>
    </ng-template>
    <div class="vml-data">
      <div class="vml-table-wrapper">
        <p-table [value]="radky.value" [styleClass]="'pds-table-compact'" [scrollable]="true" scrollHeight="flex"
                 selectionMode="single" [(selection)]="aktivniRadek" dataKey="guid"
                 (onRowSelect)="rowSelectHandler($event)" (onRowUnselect)="rowUnSelectHandler($event)">
          <ng-template pTemplate="header">
            <tr>
              <th title="Číslo řádku">Č.</th>
              <th title="Záznam">Z.</th>
              <th>Výkon</th>
              <th>Podvýkon</th>
              <th>LHC</th>
              <th>JPRL</th>
              <th>Plocha</th>
              <th>Množství</th>
              <th>Normočas</th>
              <th>% Plnění</th>
              <th>Sortiment</th>
              <th>Typ sad. mat</th>
              <th>Dod./Prac.</th>
              <th>Druh mzdy</th>
              <th>Tarif/Sazba</th>
              <th>Hodiny</th>
              <th>Dny</th>
              <th>Kč</th>
              <th>Počet VJ</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-row let-rowIndex="rowIndex">
            <tr [pSelectableRow]="row" [pSelectableRowIndex]="rowIndex">
              <td>{{(row.cislo < 0 ? '∑' : row.cislo)}}</td>
              <td>{{row.zaznam}}</td>
              <td><app-ciselnik-kod ciselnik="CVykon" [guid]="row.vykonGuid"></app-ciselnik-kod></td>
              <td><app-ciselnik-kod ciselnik="CPodvykon" [guid]="row.podvykonGuid"></app-ciselnik-kod></td>
              <td>{{row.lhc}}</td>
              <td>{{row.odd}}{{row.dil}}{{row.por}}{{row.etaz}}</td>
              <td class="right">{{row.plocha | number:'1.2-2'}}</td>
              <td class="right">{{row.mnozstvi | number:'1.3-3'}}</td>
              <td class="right">{{row.normohodiny | number:'1.2-2'}}</td>
              <td class="right">{{row.procPlneni | number:'1.2-2'}}</td>
              <td><app-ciselnik-kod ciselnik="CSortiment" [guid]="row.sortimentGuid"></app-ciselnik-kod></td>
              <td><app-ciselnik-kod ciselnik="CSazenice" [guid]="row.sazeniceGuid"></app-ciselnik-kod></td>
              <td><app-ciselnik-kod ciselnik="CDodavatel" [guid]="row.dodavatelGuid"></app-ciselnik-kod></td>
              <td><app-ciselnik-kod ciselnik="CDruhMzdy" [guid]="row.druhMzdyGuid"></app-ciselnik-kod></td>
              <td class="right">{{row.tarif | number:'1.2-2'}}</td>
              <td class="right">{{row.hodiny | number:'1.2-2'}}</td>
              <td class="right">{{row.odpracDny | number:'1.2-2'}}</td>
              <td class="right">{{row.kc | number:'1.1-1'}}</td>
              <td class="right">{{row.pocetVj | number:'1.2-2'}}</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <p-card>
        <div #radekForm [formGroup]="aktivniRadekGroup">
          <div class="p-grid">
            <div class="p-col-1">
              Záznam
              <input type="number" formControlName="zaznam" pInputText *ngIf="edit && !aktivniJeSouhrn" id="vml-zaznam-textbox" (ngModelChange)="updateMzdy()" />
              <div class="p-inputtext ui-widget ui-state-default ui-corner-all ui-div-inputtext" *ngIf="!edit || aktivniJeSouhrn" [ngClass]="{'read-only-box': aktivniJeSouhrn}">{{aktivniRadekGroup.get('zaznam')?.value}} </div>
            </div>
            <div class="p-col-2">
              Výkon
              <app-ciselnik-dropdown #vykonDropDown [ciselnik]="'CVykon'" [nullable]="true" [virtualScroll]="true"
                                     [panelStyle]="{'width':'400px'}"
                                     formControlName="vykonGuid" [edit]="edit" [readonly]="aktivniJeSouhrn" id="vml-vykonGuid-dropdown"></app-ciselnik-dropdown>
            </div>
            <div class="p-col-2">
              Podvýkon
              <app-podvykon-dropdown [ciselnik]="'CPodvykon'" [nullable]="true" [vykonGuid]="aktivniVykonGuid.value" [virtualScroll]="true"
                                     [panelStyle]="{'width':'600px'}" (onChange)="onPodvykonChange($event)"
                                     formControlName="podvykonGuid" [edit]="edit" [readonly]="aktivniJeSouhrn" id="vml-podvykonGuid-dropdown"></app-podvykon-dropdown>
            </div>
            <div class="p-col-3">
              <app-etaz-input formControlName="etazGuid" inputId="vml-etazGuid-comp" [(reset)]="etazReset" [edit]="edit" (onChange)="jprlChanged($event)" [orgUrGuid]="orgUrGuid"></app-etaz-input>
            </div>
            <div class="p-col-1" [ngClass]="{'label-of-disabled-field': aktivniRadekGroup.get('indexHoliny').disabled}">
              <label>Index holiny</label>
              <input pInputText id="vml-indexHoliny-textbox" type="text" formControlName="indexHoliny" *ngIf="edit" />
              <div class="p-inputtext ui-widget ui-state-default ui-corner-all ui-div-inputtext" *ngIf="!edit" title="{{aktivniRadekGroup.get('indexHoliny')?.value}}">{{aktivniRadekGroup.get('indexHoliny')?.value}} </div>
            </div>
            <div class="p-col-2" [ngClass]="{'label-of-disabled-field': aktivniRadekGroup.get('druhTezbyGuid').disabled}">
              <label>Druh těžby</label>
              <app-ciselnik-dropdown [ciselnik]="'CVyrDruhTezby'" [nullable]="true" formControlName="druhTezbyGuid" [edit]="edit" id="vml-druhTezbyGuid-dropdown"></app-ciselnik-dropdown>
            </div>
            <div class="p-col-1" [ngClass]="{'label-of-disabled-field': aktivniRadekGroup.get('lokalitaGuid').disabled}">
              <label>Lokalita</label>
              <app-ciselnik-dropdown [ciselnik]="'CLokalita'" [nullable]="true" formControlName="lokalitaGuid" [edit]="edit" id="vml-lokalitaGuid-dropdown"></app-ciselnik-dropdown>
            </div>
          </div>

          <div class="p-grid">
            <div class="p-col-1" [ngClass]="{'label-of-disabled-field': aktivniRadekGroup.get('hmotnatost').disabled}">
              <label>Hmotnatost</label>
              <decimal-input formControlName="hmotnatost" [edit]="edit" id="vml-hmotnatost-textbox"></decimal-input>
            </div>
            <div class="p-col-1" [ngClass]="{'label-of-disabled-field': aktivniRadekGroup.get('drevinaGuid').disabled}">
              <label>Dřevina</label>
              <app-ciselnik-dropdown [ciselnik]="'CVyrDrevina'" [nullable]="true" [virtualScroll]="true" formControlName="drevinaGuid" [edit]="edit" id="vml-drevinaGuid-dropdown"></app-ciselnik-dropdown>
            </div>
            <div class="p-col-1" [ngClass]="{'label-of-disabled-field': aktivniRadekGroup.get('mzd').disabled}">
              <label>MZD</label>
              <p-dropdown [options]="mzdOptions" label="label" styleClass="pds-ciselnik-dropdown" [style]="{'width':'100%'}"
                          appendTo="body" formControlName="mzd" *ngIf="edit" id="vml-mzd-dropdown"></p-dropdown>
              <div class="p-inputtext ui-widget ui-state-default ui-corner-all ui-div-inputtext" *ngIf="!edit">{{aktivniRadekGroup.get('mzd')?.value?'Ano':'Ne'}} </div>
            </div>
            <div class="p-col-2" [ngClass]="{'label-of-disabled-field': aktivniRadekGroup.get('zpusobProvedeniGuid').disabled}">
              <label>Způsob provedení</label>
              <app-ciselnik-dropdown [ciselnik]="'CZpusobProvedeni'" [nullable]="true"
                                     formControlName="zpusobProvedeniGuid" [edit]="edit" id="vml-zpusobProvedeniGuid-dropdown"></app-ciselnik-dropdown>
            </div>
            <div class="p-col-2" [ngClass]="{'label-of-disabled-field': aktivniRadekGroup.get('skodlivyCinitelGuid').disabled}">
              <label>Škodlivý činitel</label>
              <app-ciselnik-dropdown [ciselnik]="'CSkodlivyCinitel'" [nullable]="true" formControlName="skodlivyCinitelGuid" [edit]="edit" id="vml-skodlivyCinitelGuid-dropdown"></app-ciselnik-dropdown>
            </div>
            <div class="p-col-1" [ngClass]="{'label-of-disabled-field': aktivniRadekGroup.get('plocha').disabled}">
              <label>Plocha</label>
              <decimal-input formControlName="plocha" [edit]="edit" [readonly]="aktivniJeSouhrn" id="vml-plocha-textbox" (onChange)="updateMzdy()"></decimal-input>
            </div>
            <div class="p-col-1" [ngClass]="{'label-of-disabled-field': aktivniRadekGroup.get('mjGuid').disabled}">
              <label for="vml-mjGuid-dropdown">MJ</label>
              <app-ciselnik-dropdown [ciselnik]="'CMj'" [ciselnikData]="povoleneMj" [nullable]="true" formControlName="mjGuid" [edit]="edit" [readonly]="aktivniJeSouhrn" id="vml-mjGuid-dropdown"></app-ciselnik-dropdown>
            </div>
            <div class="p-col-1" [ngClass]="{'label-of-disabled-field': aktivniRadekGroup.get('mnozstvi').disabled}">
              <label>Množství</label>
              <decimal-input formControlName="mnozstvi" [edit]="edit" [readonly]="aktivniJeSouhrn" id="vml-mnozstvi-textbox" (onChange)="updateMzdy()"></decimal-input>
            </div>
            <div class="p-col-1" [ngClass]="{'label-of-disabled-field': aktivniRadekGroup.get('normohodiny').disabled}">
              <label>Normočas</label>
              <decimal-input formControlName="normohodiny" [edit]="edit" id="vml-normohodiny-textbox" (onChange)="updateMzdy()"></decimal-input>
            </div>
            <div class="p-col-1" [ngClass]="{'label-of-disabled-field': aktivniRadekGroup.get('sortimentGuid').disabled}">
              <label>Sortiment</label>
              <app-ciselnik-dropdown [ciselnik]="'CSortiment'" [nullable]="true" [virtualScroll]="true" formControlName="sortimentGuid" [edit]="edit" id="vml-sortimentGuid-dropdown"></app-ciselnik-dropdown>
            </div>
          </div>

          <div class="p-grid">
            <div class="p-col-2" [ngClass]="{'label-of-disabled-field': aktivniRadekGroup.get('sazeniceGuid').disabled}">
              <label>Typ sadebního materiálu</label>
              <app-ciselnik-dropdown ciselnik="CSazenice" [nullable]="true" formControlName="sazeniceGuid" [edit]="edit" id="vml-sazeniceGuid-dropdown"></app-ciselnik-dropdown>
            </div>
            <div class="p-col-3" [ngClass]="{'label-of-disabled-field': aktivniRadekGroup.get('plRadekGuid').disabled}">
              <label>Průvodní list</label>
              <app-ciselnik-dropdown [ciselnik]="'DPruvodniListRadek'" [nullable]="true" formControlName="plRadekGuid" [edit]="edit" id="vml-plRadekGuid-dropdown"></app-ciselnik-dropdown>
            </div>
            <div class="p-col-1" [ngClass]="{'label-of-disabled-field': aktivniRadekGroup.get('pribVzd').disabled}">
              <label>Přib. vzd.</label>
              <decimal-input formControlName="pribVzd" [edit]="edit" id="vml-pribVzd-textbox"></decimal-input>
            </div>
            <div class="p-col-3" [ngClass]="{'label-of-disabled-field': aktivniRadekGroup.get('zakazkaGuid').disabled}">
              <label>Zakázka/Dotace</label>
              <app-ciselnik-dropdown [ciselnik]="'CZakazka'" [nullable]="true" [virtualScroll]="true"
                                     [panelStyle]="{'width':'400px'}" [edit]="edit"
                                     formControlName="zakazkaGuid" id="vml-zakazkaGuid-dropdown"></app-ciselnik-dropdown>
            </div>
            <div class="p-col-3" [ngClass]="{'label-of-disabled-field': aktivniRadekGroup.get('akceGuid').disabled}">
              <label>Akce</label>
              <app-ciselnik-dropdown [ciselnik]="'CAkce'" [nullable]="true" formControlName="akceGuid" [edit]="edit" id="vml-akceGuid-dropdown"></app-ciselnik-dropdown>
            </div>
          </div>

          <div class="p-grid">
            <div class="p-col-3">
              <div class="p-grid">
                <div class="p-col" [ngClass]="{'label-of-disabled-field': aktivniRadekGroup.get('dodavatelGuid').disabled}">
                  <label>Dodavatel/Pracovník</label>
                  <app-ciselnik-dropdown [ciselnik]="'CDodavatel'" [nullable]="true" [virtualScroll]="true"
                                         [panelStyle]="{'width':'400px'}" [edit]="edit" [readonly]="aktivniJeSouhrn" (onChange)="updateMzdy()"
                                         formControlName="dodavatelGuid" id="vml-dodavatelGuid-dropdown"></app-ciselnik-dropdown>
                </div>
              </div>
              <div class="p-grid">
                <div class="p-col-8" [ngClass]="{'label-of-disabled-field': aktivniRadekGroup.get('druhMzdyGuid').disabled}">
                  <label>Druh mzdy</label>
                  <app-ciselnik-dropdown [ciselnikData]="druhMzdyZaklad" [nullable]="true" formControlName="druhMzdyGuid"
                                         [edit]="edit" [readonly]="aktivniJeSouhrn" (onChange)="updateMzdy()" id="vml-druhMzdyGuid-dropdown"></app-ciselnik-dropdown>
                </div>
                <div class="p-col-4">
                  <label>% plnění</label>
                  <div class="p-inputtext ui-widget ui-state-default ui-corner-all ui-div-inputtext read-only-box" id="vml-procPlneni-textbox">{{aktivniRadekGroup.get('procPlneni').value | number:'1.2-2'}} </div>
                </div>
              </div>
              <div class="p-grid">
                <div class="p-col-4" [ngClass]="{'label-of-disabled-field': aktivniRadekGroup.get('tarif').disabled}">
                  <label>Tarif/Sazba</label>
                  <decimal-input formControlName="tarif" [edit]="edit" [readonly]="aktivniJeSouhrn" id="vml-tarif-textbox" (onChange)="updateMzdy()"></decimal-input>
                </div>
                <div class="p-col-2" [ngClass]="{'label-of-disabled-field': aktivniRadekGroup.get('hodiny').disabled}">
                  <label>Hod.</label>
                  <decimal-input formControlName="hodiny" [edit]="edit" [readonly]="aktivniJeSouhrn" id="vml-hodiny-textbox" (onChange)="updateMzdy()"></decimal-input>
                </div>
                <div class="p-col-2" [ngClass]="{'label-of-disabled-field': aktivniRadekGroup.get('odpracDny').disabled}">
                  <label>Dny</label>
                  <decimal-input formControlName="odpracDny" [edit]="edit" [readonly]="aktivniJeSouhrn" id="vml-odpracDny-textbox"></decimal-input>
                </div>
                <div class="p-col-4" [ngClass]="{'label-of-disabled-field': aktivniRadekGroup.get('kc').disabled}">
                  <label>Mzda Kč</label>
                  <decimal-input formControlName="kc" [edit]="edit" [readonly]="isKcReadonly(aktivniRadekGroup.get('druhMzdyGuid').value)" id="vml-kc-textbox"></decimal-input>
                </div>
              </div>
              <div class="p-grid">
                <div class="p-col-8">
                  Poznámka
                  <input type="text" name='poznamka' pInputText formControlName="poznamka" *ngIf="edit" id="vml-poznamka-textbox" />
                  <div class="p-inputtext ui-widget ui-state-default ui-corner-all ui-div-inputtext" *ngIf="!edit" title="{{aktivniRadek?.poznamka}}">{{aktivniRadek?.poznamka}} </div>
                </div>
                <div class="p-col-4" [ngClass]="{'label-of-disabled-field': aktivniRadekGroup.get('datumAplikace').disabled}">
                  <label for="vml-datumAplikace-textbox">Den aplikace</label>
                  <app-datum-den-input formControlName="datumAplikace" [refDate]="formData.get('datum').value" id="vml-datumAplikace-textbox" [edit]="edit"></app-datum-den-input>
                </div>
              </div>
            </div>
            <div class="p-col-3" [ngClass]="{'disabled-panel': !povolPremieNahrady}">
              <p-panel>
                <ng-template pTemplate="header">
                  <div class="p-grid p-align-center">
                    <div class="p-col"><span class="p-panel-title">Prémie, Náhrady</span></div>
                    <div class="p-col right" *ngIf="edit">
                      <button pButton type="button" icon="pi pi-plus" (click)="addPremieNahrada()" [disabled]="!povolPremieNahrady"></button>
                    </div>
                  </div>
                </ng-template>
                <div>
                  <div formArrayName="premieNahrady">
                    <div *ngFor="let premieNahrada of aktivniPremieNahrady.controls; let i = index;" [formGroup]="premieNahrada" [ngClass]="'premieNahrada'">
                      <div class="p-grid">
                        <div class="p-col-4" [ngClass]="{'label-of-disabled-field': premieNahrada.get('druhMzdyGuid').disabled}">
                          <label *ngIf="i == 0">Druh</label>
                          <app-ciselnik-dropdown [ciselnikData]="druhMzdyPremieNahrady" [nullable]="true" [virtualScroll]="true"
                                                 [panelStyle]="{'width':'400px'}" [edit]="edit"
                                                 formControlName="druhMzdyGuid" (onChange)="updateMzdy()"></app-ciselnik-dropdown>
                        </div>
                        <div class="p-col" [ngClass]="{'label-of-disabled-field': premieNahrada.get('sazba').disabled}">
                          <label *ngIf="i == 0">Sazba</label>
                          <decimal-input formControlName="sazba" [edit]="edit" (onChange)="updateMzdy()"></decimal-input>
                        </div>
                        <div class="p-col" [ngClass]="{'label-of-disabled-field': premieNahrada.get('kc').disabled}">
                          <label *ngIf="i == 0">Částka Kč</label>
                          <decimal-input formControlName="kc" [edit]="edit" [readonly]="isKcReadonly(premieNahrada.get('druhMzdyGuid').value)"></decimal-input>
                        </div>
                        <div class="p-col-fixed">
                          <button pButton type="button" icon="pi pi-minus" class="p-button-danger" [ngClass]="{'first-row-button' : i == 0}"
                                  (click)="removePremieNahrada(i)" [disabled]="!povolPremieNahrady" *ngIf="edit"></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </p-panel>
            </div>
            <div class="p-col-6">
              <div class="panel-wrapper" [ngClass]="{'disabled-panel': !povolProstedky}">
                <p-panel>
                  <ng-template pTemplate="header">
                    <div class="p-grid p-align-center">
                      <div class="p-col"><span class="p-panel-title">Prostředky</span></div>
                      <div class="p-col right" *ngIf="edit">
                        <button pButton type="button" icon="pi pi-plus" (click)="addProstredek()" [disabled]="!povolProstedky"></button>
                      </div>
                    </div>
                  </ng-template>
                  <div>
                    <div formArrayName="prostredky">
                      <div *ngFor="let prostredek of aktivniProstredky.controls; let i = index;" [formGroup]="prostredek" [ngClass]="'prostredek'">
                        <div class="p-grid">
                          <div class="p-col">
                            <div class="p-grid">
                              <div class="p-col-2">
                                <label *ngIf="i == 0">Typ vazby</label>
                                <checkbox-s-popisem [nullable]="false" id="vazba-{{i}}"
                                                     [edit]="edit" formControlName="ucelovy" [popisky]="vzabaProstredkuPopis"></checkbox-s-popisem>
                              </div>
                              <div class="p-col-4" [ngClass]="{'label-of-disabled-field': prostredek.get('prostredekGuid').disabled}">
                                <label *ngIf="i == 0">Prostředek</label>
                                <app-ciselnik-dropdown [ciselnik]="'CProstredek'" [nullable]="true" [virtualScroll]="true"
                                                       [panelStyle]="{'width':'400px'}" [edit]="edit"
                                                       formControlName="prostredekGuid"></app-ciselnik-dropdown>
                              </div>
                              <div class="p-col-4">
                                <div class="p-grid">
                                  <div class="p-col-4" [ngClass]="{'label-of-disabled-field': (prostredek.get('vykonGuid').disabled || prostredek.get('podvykonGuid').disabled)}">
                                    <label *ngIf="i == 0">Výkon</label>
                                    <app-vykon-prostredku-dropdown [nullable]="true" [virtualScroll]="true" variant="short"
                                                                   [panelStyle]="{'width':'400px'}" [prostredekGuid]="prostredek.get('prostredekGuid').value"
                                                                   formControlName="vykonGuid" [edit]="edit"></app-vykon-prostredku-dropdown>
                                  </div>
                                  <div class="p-col-4" [ngClass]="{'label-of-disabled-field': (prostredek.get('podvykonGuid').disabled || prostredek.get('vykonGuid').disabled) }">
                                    <label *ngIf="i == 0" title="Podvýkon">Podv.</label>
                                    <app-podvykon-dropdown [ciselnik]="'CPodvykon'" [nullable]="true" [vykonGuid]="prostredek.get('vykonGuid').value" [virtualScroll]="true"
                                                           [panelStyle]="{'width':'600px'}" variant="short"
                                                           formControlName="podvykonGuid" [edit]="edit"></app-podvykon-dropdown>
                                  </div>
                                  <div class="p-col-4" [ngClass]="{'label-of-disabled-field': prostredek.get('domOrgUrGuid').disabled}">
                                    <span *ngIf="i == 0" title="Organizační úroveň">Org. úroveň</span>
                                    <app-org-ur formControlName="domOrgUrGuid" id="vml-domOrgUrGuid" [edit]="edit" variant="short" [onlyLevel2Selectable]="true"></app-org-ur>
                                  </div>
                                </div>
                              </div>
                              <div class="p-col-1" [ngClass]="{'label-of-disabled-field': prostredek.get('hodiny').disabled}">
                                <label *ngIf="i == 0">Hodiny</label>
                                <decimal-input formControlName="hodiny" [edit]="edit"></decimal-input>
                              </div>
                              <div class="p-col-1" [ngClass]="{'label-of-disabled-field': prostredek.get('mnozstvi').disabled}">
                                <label *ngIf="i == 0">Množství</label>
                                <decimal-input formControlName="mnozstvi" [edit]="edit"></decimal-input>
                              </div>
                            </div>
                          </div>
                          <div class="p-col-fixed" *ngIf="edit">
                            <button pButton type="button" icon="pi pi-minus" class="p-button-danger" [ngClass]="{'first-row-button' : i == 0}"
                                    (click)="removeProstredek(i)" [disabled]="!povolProstedky"></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </p-panel>
              </div>
              <div class="panel-wrapper" [ngClass]="{'disabled-panel': !povolMaterialy}">
                <p-panel>
                  <ng-template pTemplate="header">
                    <div class="p-grid p-align-center">
                      <div class="p-col"><span class="p-panel-title">Materiály</span></div>
                      <div class="p-col right" *ngIf="edit">
                        <button pButton type="button" icon="pi pi-plus" (click)="addMaterial()" [disabled]="!povolMaterialy"></button>
                      </div>
                    </div>
                  </ng-template>
                  <div>
                    <div formArrayName="materialy">
                      <div *ngFor="let material of aktivniMaterialy.controls; let i = index;" [formGroup]="material" [ngClass]="'material'">
                        <div class="p-grid">
                          <div class="p-col-6" [ngClass]="{'label-of-disabled-field': material.get('materialGuid').disabled}">
                            <label *ngIf="i == 0">Materiál (chemie)</label>
                            <app-ciselnik-dropdown [ciselnik]="'CMaterial'" [nullable]="true" [virtualScroll]="true"
                                                   [panelStyle]="{'width':'400px'}" [edit]="edit"
                                                   formControlName="materialGuid"></app-ciselnik-dropdown>
                          </div>
                          <div class="p-col" [ngClass]="{'label-of-disabled-field': material.get('aplDavka').disabled}">
                            <label *ngIf="i == 0">Aplikační dávka</label>
                            <decimal-input formControlName="aplDavka" [edit]="edit"></decimal-input>
                          </div>
                          <div class="p-col" [ngClass]="{'label-of-disabled-field': material.get('mnozstvi').disabled}">
                            <label *ngIf="i == 0">Množství</label>
                            <decimal-input formControlName="mnozstvi" [edit]="edit"></decimal-input>
                          </div>
                          <div class="p-col-fixed" *ngIf="edit">
                            <button pButton type="button" icon="pi pi-minus" class="p-button-danger" [ngClass]="{'first-row-button' : i == 0}"
                                    (click)="removeMaterial(i)" [disabled]="!povolMaterialy"></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </p-panel>
              </div>
            </div>
          </div>
        </div>
      </p-card>
    </div>
  </p-panel>
</div>

<p-dialog [(visible)]="showHelp" header="Ovládání" position="topright" [resizable]="false" *ngIf="edit">
  <app-vyroba-help coKopiruje="výkon a podvýkon"></app-vyroba-help>
</p-dialog>

<app-vyroba-vml-nahled #nahled></app-vyroba-vml-nahled>

<p-dialog [(visible)]="showDochazka" header="Docházka" [resizable]="false" [modal]="true" (onShow)="onShowDochazka()">
  <app-vyroba-dochazka #dochazka [dokladGuid]="dokladGuid" [edit]="edit" [vml]="true"></app-vyroba-dochazka>
</p-dialog>

<p-confirmDialog #dochazkacd icon="pi pi-info-circle" key="vml-confirm-dlg">
  <p-footer>
    <button type="button" pButton icon="pi pi-times" label="Ne" (click)="dochazkacd.reject()"></button>
    <button type="button" pButton icon="pi pi-check" label="Ano" (click)="dochazkacd.accept()"></button>
  </p-footer>
</p-confirmDialog>
