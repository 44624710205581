<p-panel header="Náhled" styleClass="overview-mapa pds-panel-compact">
  <ng-template pTemplate="icons">
    <button pButton type="button" class="p-panel-header-icon icon-btn hide-label-button p-mr-2"
            pTooltip="Přiblížit na všechny polygony"
            (click)="zoomToExtent(_extent)">
      <fa-icon [icon]="faSearch"></fa-icon>
    </button>
  </ng-template>
  <app-map #overViewMap id="{{MAP_ID}}"
           [ShowMapControls]="false" [Layers]="layers"
           style="width:100%;height:100%;"></app-map>
</p-panel>
