<div class="kniha-detail-form kniha-detail-top-field">
  <div class="p-grid">
    <div class="p-col">
      <app-label-input-display [value]="etaz?.etaz" label="Etáž" [formatConent]="false"></app-label-input-display>
    </div>
    <div class="p-col">
      <app-label-input-display [value]="etaz?.etazPs" label="Pl. skut." tooltip="Plocha skutečná"></app-label-input-display>
    </div>
    <div class="p-col">
      <app-label-input-display [value]="etaz?.etazPp" label="Pl. parc." tooltip="Plocha parciální"></app-label-input-display>
    </div>
    <div class="p-col">
      <app-label-input-display [value]="etaz?.vek" label="Věk" [numDecPlaces]="0"></app-label-input-display>
    </div>
    <div class="p-col">
      <app-ciselnik-field key="CLhcHs" [guid]="etaz?.hsGuid" label="HS" variant="short" tooltip="Hospodářský soubor"></app-ciselnik-field>
    </div>
    <div class="p-col">
      <app-label-input-display [value]="etaz?.obmyti" label="Obmýtí" [numDecPlaces]="0"></app-label-input-display>
    </div>
    <div class="p-col">
      <app-label-input-display [value]="etaz?.obnDoba" label="Doba obn." tooltip="Doba obnovní" [numDecPlaces]="0"></app-label-input-display>
    </div>
    <div class="p-col">
      <app-label-input-display [value]="etaz?.pocObnovy" label="Poč. obn." tooltip="Počátek obnovy" [numDecPlaces]="0"></app-label-input-display>
    </div>
    <div class="p-col">
      <app-label-input-display [value]="etaz?.mzd" label="% MZD" tooltip="Podíl melioračních a zpevňujících dřevin" [numDecPlaces]="0"></app-label-input-display>
    </div>
    <div class="p-col">
      <app-label-input-display [value]="etaz?.zakm" label="Zakm." tooltip="Zakmenění" [numDecPlaces]="0"></app-label-input-display>
    </div>
    <div class="p-col">
      <app-ciselnik-field key="CHospTv" [guid]="etaz?.hospTvGuid" label="Hosp. tvar" variant="short" tooltip="Hospodářský tvar"></app-ciselnik-field>
    </div>
    <div class="p-col">
      <app-label-input-display [value]="etaz?.mTezProc" label="Těž. %" tooltip="Modelové těžěbní procento" [numDecPlaces]="0"></app-label-input-display>
    </div>
    <div class="p-col">
      <app-ciselnik-field key="COdvozTez" [guid]="etaz?.odvozTezGuid" label="Odv. těžby" variant="short" tooltip="Označení způsobu odvození výše těžby"></app-ciselnik-field>
    </div>
    <div class="p-col">
      <app-ciselnik-field key="CMZZasoby" [guid]="etaz?.mzZasobyGuid" label="MZZ" variant="short" tooltip="Metoda zjištění zásoby"></app-ciselnik-field>
    </div>
  </div>
</div>

<div class="p-grid p-align-stretch kniha-detail-form">
  <div class="p-col-12">
    <div class="p-grid p-align-stretch">
      <div class="p-col">
        <app-label-input-display [value]="etaz?.proP" label="PRO ha" tooltip="Plocha prořezávky"></app-label-input-display>
      </div>
      <div class="p-col">
        <app-ciselnik-field key="CNaleh" [guid]="etaz?.proNalGuid" label="PRO nal." variant="short" tooltip="Naléhavost"></app-ciselnik-field>
      </div>
      <div class="p-col">
        <app-ciselnik-field key="CNalHosp" [guid]="etaz?.proNalGuid" label="PRO nal. h." variant="short" tooltip="Naléhavost hospodářská"></app-ciselnik-field>
      </div>
      <div class="p-col">
        <app-label-input-display [value]="etaz?.proPoc" label="PRO poč" tooltip="Počet prořezávek" [numDecPlaces]="0"></app-label-input-display>
      </div>
      <div class="p-col">
        <app-label-input-display [value]="etaz?.tvP" label="TV ha" tooltip="Plocha těžby výchovní"></app-label-input-display>
      </div>
      <div class="p-col">
        <app-ciselnik-field key="CNaleh" [guid]="etaz?.tvNalGuid" label="TV nal." variant="short" tooltip="Naléhavost těžby výchovní"></app-ciselnik-field>
      </div>
      <div class="p-col">
        <app-ciselnik-field key="CNalHosp" [guid]="etaz?.tvNalHospGuid" label="TV nal. h." variant="short" tooltip="Naléhavost hospodářská těžby výchovní"></app-ciselnik-field>
      </div>
      <div class="p-col">
        <app-label-input-display [value]="etaz?.tvPoc" label="TV poč." tooltip="Počet TV zásahů" [numDecPlaces]="0"></app-label-input-display>
      </div>
      <div class="p-col">
        <app-label-input-display [value]="etaz?.toP" label="TO ha" tooltip="Plocha těžby obnovní"></app-label-input-display>
      </div>
      <div class="p-col">
        <app-ciselnik-field key="CNaleh" [guid]="etaz?.toNalGuid" label="TO nal." variant="short" tooltip="Naléhavost těžby obnovní"></app-ciselnik-field>
      </div>
      <div class="p-col">
        <app-ciselnik-field key="CNalHosp" [guid]="etaz?.toNalGuid" label="TO nal. h." variant="short" tooltip="Naléhavost hospodářská těžby obnovní"></app-ciselnik-field>
      </div>
      <div class="p-col">
        <app-ciselnik-field key="CZpusTezby" [guid]="etaz?.toZpusobGuid" label="TO zpus." variant="short" tooltip="Způsob těžby obnovní"></app-ciselnik-field>
      </div>
      <div class="p-col">
        <app-ciselnik-field key="CDuvTezby" [guid]="etaz?.toDuvodGuid" label="TO dův." variant="short" tooltip="Důvod těžby obnovní"></app-ciselnik-field>
      </div>
    </div>
  </div>

  <!-- divná šířka a p-col-fixed je zde kvůli předchozímu řádku, kde je nestandardních 13 prvků - aby to bylo zarovnané pěkně pod sebe musí být takto -->
  <div class="p-col-fixed p-nogutter" style="width: 54%">
    <p-table [value]="etaz?.zalDrs" styleClass="p-datatable-gridlines p-datatable-striped pds-table-compact p-mt-3">
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 25%">Dřevina zalesnění</th>
          <th style="width: 12.5%">%</th>
          <th>Název</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-zales>
        <tr>
          <td>{{zales.drevina?.drZkr}}</td>
          <td>{{zales.procento}}</td>
          <td>{{zales.drevina?.nazev}}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div class="p-col">
    <div class="p-grid p-align-stretch">
       <div class="p-col">
        <app-ciselnik-field key="COmezTez" [guid]="etaz?.omezTezGuid" label="Omez. těž." variant="short" tooltip="Důvod omezení těžby"></app-ciselnik-field>
      </div>
      <div class="p-col">
        <app-ciselnik-field key="CDruhZal" [guid]="etaz?.druhZalGuid" label="Zal. druh" variant="short" tooltip="Druh zalesnení"></app-ciselnik-field>
      </div>
      <div class="p-col">
        <app-label-input-display [value]="etaz?.zalP" label="Zal. ha" tooltip="Plocha zalesnění"></app-label-input-display>
      </div>
      <div class="p-col">
        <app-label-input-display [value]="etaz?.tvybP" label="TVYB ha" tooltip="Plocha těžby výběrné"></app-label-input-display>
      </div>
      <div class="p-col">
        <app-ciselnik-field key="CNaleh" [guid]="etaz?.tvybNalGuid" label="TVYB nal." variant="short" tooltip="Naléhavost těžby výběrné"></app-ciselnik-field>
      </div>
      <div class="p-col">
        <app-ciselnik-field key="CNalHosp" [guid]="etaz?.tvybNalHospGuid" label="TVYB nal. h." variant="short" tooltip="Naléhavost hospodářská těžby výběrné"></app-ciselnik-field>
      </div>
      <div class="p-col-12" *ngIf="etaz?.zalDrs?.length > 1">
        Poznámka
        <div class="p-inputtext ui-corner-all three-row overflow-scroll">{{etaz?.etazText}}</div>
      </div>
    </div>
  </div>

  <div class="p-col-12" *ngIf="etaz?.zalDrs?.length <= 1">
    Poznámka
    <div class="p-inputtext ui-corner-all three-row overflow-scroll">{{etaz?.etazText}}</div>
  </div>

</div>
