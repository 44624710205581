<p-blockUI [blocked]="blocked">
  <p-progressSpinner></p-progressSpinner>
</p-blockUI>

<div *ngIf="visible" style="margin-top:0.5rem;" class="nacrt-detail-component">
  <p-toolbar styleClass="base-toolbar" [style]="{'margin-bottom': '0.5rem'}">
    <div class="p-toolbar-group-left">
      <h2 class="toolbar-label">Detail náčrtu</h2>
    </div>
  </p-toolbar>

  <div class="flex-container">
    <p-card [ngStyle]="{'flex-grow': 1, 'flex-basis': '15%', 'overflow-y': 'auto' }">
      <div class="p-grid">
        <div class="p-col-12">
          <label for="projekt">Projekt</label>
          <div class="p-inputtext ui-widget ui-state-default ui-corner-all ui-div-inputtext">{{nacrtProjekt}}</div>
        </div>
        <div class="p-col-12">
          <label for="popis">Popis</label>
          <div class="p-inputtext ui-widget ui-state-default ui-corner-all ui-div-inputtext">{{popis}}</div>
        </div>
        <div class="p-col-12">
          <label for="poznamka">Poznámka</label>
          <textarea pInputTextarea id="poznamka" disabled="disabled"
                    [rows]="3" [cols]="30" autoResize="autoResize">{{poznamka}}</textarea>
        </div>
      </div>
    </p-card>
    <p-card [ngStyle]="{'flex-grow': 3, 'flex-basis': '35%', 'margin-left': '.5rem', 'margin-right': '.5rem' }">
      <app-nacrt-priloha-foto></app-nacrt-priloha-foto>
    </p-card>
    <app-nacrt-priloha-audio style="flex-grow: 2; flex-basis: 25%; overflow-y: auto;margin-right: 0.5rem;"></app-nacrt-priloha-audio>
    <app-nacrt-map style="flex-grow: 2; flex-basis: 25%;"></app-nacrt-map>
  </div>
</div>
