import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ZakaznikDetailDto } from '../../../Dto/Zakaznik/ZakaznikDetailDto';
import { CiselnikyService } from '../../../Services/Shared/ciselniky.service';

/**
 * Komponenta seznamu zákazníků
*/
@Component({
  selector: 'app-zakaznik-dropdown',
  templateUrl: './zakaznik-dropdown.component.html',
  styleUrls: ['./zakaznik-dropdown.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ZakaznikDropdownComponent),
    multi: true
  }]
})
export class ZakaznikDropdownComponent implements ControlValueAccessor{

  constructor
  (
    private ciselnikyService: CiselnikyService
  )
  {
  }

  @Input('value') _value: ZakaznikDetailDto = null;

  get value(): ZakaznikDetailDto {
    return this._value;
  }

  set value(val: ZakaznikDetailDto) {
    this._value = val;
    this.onModelChange(val);
    this.onModelTouched();
  }

  writeValue(obj: ZakaznikDetailDto): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
   
  onModelChange: any = () => { };
  onModelTouched: any = () => { };
  disabled: boolean = false;

  /**
   * Mód výchozího výběru dropdownu
   * 'base' - vybere se první z načtených zákazníků 
   * 'admin' - nebude vybrán žádný zákazník
  */
  @Input() set mode(value: string) {
    this._loadDropdown(value);
  }

  block: boolean = false;
  zakaznici: ZakaznikDetailDto[];

  /**
   *  Načte seznam zákazníků pro naplnění nabídky.
  */
  private _loadDropdown(mode: string): void {
    this.ciselnikyService.getZakaznici().subscribe(result => {
      this.zakaznici = result.sort((a, b) => a.nazev.toLowerCase().localeCompare(b.nazev.toLowerCase()));
      if (mode == 'base') {
        this.value = this.zakaznici[0];
      }
    });
  }
}
