import { HttpClient, HttpParams } from "@angular/common/http";
import { EventEmitter, Injectable, Output } from "@angular/core";
import { Observable, of } from "rxjs";
import { NacrtProjektDto } from "../../Dto/Nacrty/NacrtProjektDto";
import { DNacrtPrilohaSyncDto } from "../../Dto/Nacrty/DNacrtPrilohaSyncDto";
import { NacrtMapItemDto } from '../../Dto/Nacrty/NacrtMapItemDto';
import { ResultInfoDto, ResultInfoGenericDto } from "../../Dto/Shared/ResultInfoDto";
import { ISchvalWorkflowDto } from "../../Dto/Nacrty/ISchvalWorkflowDto";
import { MapItemDto } from "../../Dto/Mapa/MapItemDto";

@Injectable({
  providedIn: 'root'
})
export class NacrtyService {

  /**
   * Event vystřelený po uložení dat. Obsahuje dto s odpovědí Sync/Post a pokud je odpověď úspěšná tak, i ukládaná data.
  **/
  @Output() NacrtSaved: EventEmitter<any> = new EventEmitter<any>();

  private apiNacrtPrilohy: string = 'api/nacrty/NacrtPriloha/GetPrilohy/';
  private apiNacrtDelete: string = 'api/nacrty/Nacrt/Delete/';
  private apiNacrtyDeleteSelected: string = 'api/nacrty/Nacrt/DeleteSelected/'
  private apiNacrtAll: string = 'api/nacrty/Nacrt/GetAll/';
  private apiNacrtProjektList: string = 'api/nacrty/NacrtProjekt/ListAll/';
  private apiNacrtSchvalit: string = 'api/nacrty/Nacrt/Schvalit';
  private apiIconLibrary: string = 'api/nacrty/NacrtProjekt/GetIconsLibrary';
  private apiLineTypeLibrary: string = 'api/nacrty/NacrtProjekt/GetLineTypesLibrary';
  private apiNacrtSplitByJprl: string = 'api/nacrty/Nacrt/SplitByJrpl/';

  constructor(
    private http: HttpClient
  )
  {  }

  /**
   * Vrátí přilohy náčrtu.
   * @param guid {string} Guid náčrtu
   */
  getPrilohy(guid: string): Observable<DNacrtPrilohaSyncDto[]> {
    return this.http.get<DNacrtPrilohaSyncDto[]>(this.apiNacrtPrilohy + guid);
  }


  /**
   * Smazání náčrtu.
   * @param guid {string} Guid náčrtu, který má býs smazán.
   */
  delete(guid: string): Observable<ResultInfoDto> {
    return this.http.delete<ResultInfoDto>(this.apiNacrtDelete + guid);
  }

  /**
   * Smazání vybraných náčrtů.
   * @param guids {string[]} seznam guidů náčrtů, které se mají smazat
   */
  deleteSelected(guids: string[]): Observable<ResultInfoDto> {
    return this.http.post<ResultInfoDto>(this.apiNacrtyDeleteSelected, guids);
  }

  /**
   * Vrátí všechny náčrty
  */
  getAll(): Observable<ResultInfoGenericDto<NacrtMapItemDto[]>> {
    return this.http.get<ResultInfoGenericDto<NacrtMapItemDto[]>>(this.apiNacrtAll);
  }

  /**
   * Vratí seznam projektů náčrtů
   **/
  getProjekty(): Observable<ResultInfoGenericDto<NacrtProjektDto[]>> {
    return this.http.get<ResultInfoGenericDto<NacrtProjektDto[]>>(this.apiNacrtProjektList);
  }


  /**
   * Schvaleni náčrtu.
   * @param dto {string} Id náčrtu ke schválení
   */
  schvalitNacrt(guid: string): Observable<ResultInfoGenericDto<ISchvalWorkflowDto[]>> {
    var param = new HttpParams();
    param = param.append('guid', guid);
    return this.http.post<ResultInfoGenericDto<ISchvalWorkflowDto[]>>(this.apiNacrtSchvalit, {}, { params: param });
  }


  /**
   * Klíč, pod kterým je knihovna ikon uložena v sessionStorage.
  **/
  private readonly ICON_LIBRARY_KEY: string = "iconLibrary";

  /**
   * Klíč, pod kterým je knihovna typů čar uložena v sessionStorage.
  **/
  private readonly LINE_TYPE_LIBRARY_KEY: string = "lineTypeLibrary";


  /**
   * Request stažení knihovny ikon.
  **/
  private _iconLibraryRequest: EventEmitter<any> = null;

  /**
   * Request stažení knihovny typů čar.
  **/
  private _lineTypeLibraryRequest: EventEmitter<any> = null;


  /**
   * Vrátí knihovnu ikon.
   * Knihovnu stáhne z web-api a uloží do sessionStorage. Pak již pracuje s knihovnou ze sessionStorage.
  **/
  getIconLibrary(): Observable<any> {
    let library = sessionStorage.getItem(this.ICON_LIBRARY_KEY);
    if (library != void 0 && library != '') {
      return of(JSON.parse(library));
    }
    if (this._iconLibraryRequest == void 0) {
      this._iconLibraryRequest = new EventEmitter<any>();
      this.http.get<any>(this.apiIconLibrary).subscribe(resp => {
        sessionStorage.setItem(this.ICON_LIBRARY_KEY, JSON.stringify(resp));
        this._iconLibraryRequest.emit(resp);
      });
    }
    return this._iconLibraryRequest;
  }


  /**
   * Vrátí knihovnu typů čar (a kouzel).
   * Knihovnu stáhne z web-api a uloží do sessionStorage. Pak již pracuje s knihovnou ze sessionStorage.
  **/
  getLineTypeLibrary(): Observable<any> {
    let library = sessionStorage.getItem(this.LINE_TYPE_LIBRARY_KEY);
    if (library != void 0 && library != '') {
      return of(JSON.parse(library));
    }
    if (this._lineTypeLibraryRequest == void 0) {
      this._lineTypeLibraryRequest = new EventEmitter<any>();
      this.http.get<any>(this.apiLineTypeLibrary).subscribe(resp => {
        sessionStorage.setItem(this.LINE_TYPE_LIBRARY_KEY, JSON.stringify(resp));
        this._lineTypeLibraryRequest.emit(resp);
      });
    }
    return this._lineTypeLibraryRequest;
  }


  /**
   * Vrátí náčrty vrstvy z modulu Náčrty.
   * @param url {string} URL, ze které se stáhnout mapové objekty v modulu Náčrty
   */
  getLayerFeatures(url: string): Observable<ResultInfoGenericDto<MapItemDto[]>> {
    return this.http.get<ResultInfoGenericDto<MapItemDto[]>>(url);
  }


  /**
   * Rozdělení náčrtu podle hranic JPRL.
   * @param id {string} guid děleného náčrtu
   */
  splitByJprl(id: string): Observable<ResultInfoGenericDto<string[]>> {
    return this.http.get<ResultInfoGenericDto<string[]>>(this.apiNacrtSplitByJprl + id);
  }
}
