import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { faSave, faTimes, faUserLock } from '@fortawesome/free-solid-svg-icons';
import { FilterService, MessageService, SelectItem } from 'primeng/api';
import { Table } from 'primeng/table';
import { SuperAdminGuard } from '../../../Auth/super-admin.guard';
import { RoleDto } from '../../../Dto/Auth/RoleDto';
import { CiselnikOpravneniEnum } from '../../../Dto/Core/CiselnikOpravneniEnum';
import { CiselnikRoleOpravneniDto } from '../../../Dto/Core/CiselnikRoleOpravneniDto';
import { CiselnikSeznamDto } from '../../../Dto/Core/CiselnikSeznamDto';
import { CiselnikTypEnum } from '../../../Dto/Core/CiselnikTypEnum';
import { CiselnikUserSeznamDto } from '../../../Dto/Core/CiselnikUserSeznamDto';
import { RoleEnum } from '../../../Dto/Core/RoleEnum';
import { CiselnikyAdminService } from '../../../Services/Core/ciselniky-admin.service';
import { CiselnikyService } from '../../../Services/Shared/ciselniky.service';
import { MessagesUtils } from '../../../Utils/Shared/messages.utils';


@Component({
  selector: 'app-ciselniky-opravneni-edit',
  templateUrl: './ciselniky-opravneni-edit.component.html',
  styleUrls: ['./ciselniky-opravneni-edit.component.css']
})
export class CiselnikyOpravneniEditComponent implements OnInit {

  constructor(private ciselnikyAdminService: CiselnikyAdminService,
    private ciselnikyService: CiselnikyService,
    private messageService: MessageService,
    private filterService: FilterService,
    private messagesUtils: MessagesUtils,
    public superAdminGuard: SuperAdminGuard) {
    this.filterOptions = [{ label: "Vše", value: null }, { label: "Přiřazené", value: this.PRIRAZENO }, { label: "Nepřiřazené", value: this.NEPRIRAZENO }, { label: "Volné", value: this.VOLNE }];
  }

  faUserLock = faUserLock;
  faTimes = faTimes;
  faSave = faSave;

  public displayDialog: boolean = false;
  public role: RoleDto[];
  public selectedRole: RoleDto;
  public ciselnikyAll: CiselnikSeznamDto[];
  public ciselnikyToDisplay: CiselnikUserSeznamDto[];
  public selectedCiselniky: CiselnikUserSeznamDto[];
  public readonly TOAST_KEY: string = "ciselnik-opravneni-edit-toast";
  public readonly typyCiselniku: SelectItem[] = [
    { value: CiselnikTypEnum.Systemovy, label: "Systémový" },
    { value: CiselnikTypEnum.Globalni, label: "Globální" },
    { value: CiselnikTypEnum.Zakaznicky, label: "Zákaznický" }];

  public filterOptions: SelectItem[];
  public filter: SelectItem;
  private _selectedByRoleDict: Map<string, CiselnikUserSeznamDto[]> = new Map<string, CiselnikUserSeznamDto[]>();
  public changed: boolean = false;
  public selectAllEdit: boolean;

  private readonly PRIRAZENO: number = 1;
  private readonly NEPRIRAZENO: number = 0;
  private readonly VOLNE: number = -1;

  @Output() onChange: EventEmitter<any> = new EventEmitter();

  @ViewChild("opravneniTable", { static: true }) opravneniTable: Table;

  ngOnInit(): void {
    this.filterService.register('filterOpravneni', this.filterHandler.bind(this));

    if (this.superAdminGuard.canActivate())
      this.ciselnikyService.getRoles().subscribe(res => {
        if (res != void 0 && res.length > 0) {
          this.role = res;
          this.ciselnikyAdminService.GetCiselnikyAll().subscribe(res => {
            this.ciselnikyAll = res.sort((a, b) => ('' + a.nazev).localeCompare(b.nazev));//v základu seřadit podle názvu
            this.selectedRole = this.role[0];
            this.roleChanged(this.role[0].popis);
          }, error => {
            this._errorMessage();
          });
        }
      });
  }

  /**
   * Vrátí popis typu podle kódu
   * @param kod
   */
  public getTypPopisByKod(typ: number): string {
    return this.typyCiselniku.find(x => x.value == typ)?.label;
  }

  /**
   * Handler výběru role
   * @param role
   */
  public roleChanged(role: string): void {
    this.opravneniTable.reset();
    if (!this._selectedByRoleDict.has(role)) {
      this.ciselnikyAdminService.getSeznamCiselnikuForRole(role).subscribe(res => {
        this._selectedByRoleDict.set(role, res);
        this._selectCiselniky();
      }, error => {
        this._errorMessage();
      });
    } else {
      this._selectCiselniky();
    }
  }

  /**
   * Stáhne číselníky pro zbylé role pro filtrování volných číselníků
   * */
  private _loadRoles(): void {
    this.role.forEach(item => {
      if (!this._selectedByRoleDict.has(item.popis)) {
        this.ciselnikyAdminService.getSeznamCiselnikuForRole(item.popis).subscribe(res => {
          this._selectedByRoleDict.set(item.popis, res);
        }, error => {
          this._errorMessage();
        });
      }
    });
  }


  /**
   * Zvolí vybrané řádky podle role
   **/
  private _selectCiselniky(): void {
    let selectedCis = this._selectedByRoleDict.get(this.selectedRole.popis);
    this.ciselnikyToDisplay = (this.ciselnikyAll.map(cis => selectedCis.find(o => o.guid === cis.guid) || cis) as CiselnikUserSeznamDto[]);
    this.selectedCiselniky = selectedCis;
    this.chekcSelectedAll();
    if (this.filter != this.filterOptions[0]) {
      this.filter = this.filterOptions[0];
      this.filtruj(this.filter);
    }

    this._loadRoles();
  }

  /**
   * Uloží vybraný číselník a pokud uspěje, tak uloží do slovníku a oděšle změnu onChange pro superadmina
   **/
  public save(): void {
    if (this.selectedRole != void 0) {
      let result: CiselnikRoleOpravneniDto[] = this.selectedCiselniky.map(x => {
        return { ciselnikGuid: x.guid, opravneni: this._anyToOpravneni(x.opravneni) };
      });

      this.ciselnikyAdminService.updateOpravneni(this.selectedRole.popis, result).subscribe(res => {
        if (res.success) {
          if (this.selectedRole.popis == RoleEnum.SuperAdmin) this.onChange.emit();
          this._selectedByRoleDict.set(this.selectedRole.popis, this.selectedCiselniky);
          this.changed = false;
        };
        this.messagesUtils.showResponseMessage(this.TOAST_KEY, res);
      });
    }
  }

  /**
   * Filtrování podle výběru oprávnění
   * @param guid
   * @param selected - volba filtrace oprávnění přiřazeno/nepřiřazeno/volné
   */
  public filterHandler(guid: string, selected: number): boolean {
    switch (selected) {
      case this.PRIRAZENO: {
        return this.selectedCiselniky.some(x => x.guid == guid);
        break;
      }
      case this.NEPRIRAZENO: {
        return this.selectedCiselniky.find(x => x.guid == guid) == void 0;
        break;
      }
      case this.VOLNE: {
        return this._checkCisenikForAllRoles(guid);
        break;
      }
      default: {
        return true;
        break;
      }
    }
  }

  /**
   * Ověří, jestli je guid číselníku přiřazen nějaké roli
   * @param guid
   */
  private _checkCisenikForAllRoles(guid: string): boolean {
    let result: boolean = true;
    this._selectedByRoleDict.forEach(ciselniky => {
      if (result) result = !ciselniky.some(x => x.guid == guid);
    }, this);

    return result;
  }

  /**
   * Spustí filtr oprávnění
   * @param value
   */
  public filtruj(value): void {
    this.opravneniTable.reset();
    this.opravneniTable.filter(value, "guid", "filterOpravneni");
  }

  /**
   * Handler změny v treetable
   **/
  public opravneniChangedHandler(): void {
    this.chekcSelectedAll();
    if (this.selectedRole != void 0) this.changed = true;
    if (this.selectedCiselniky == void 0 || this.selectedCiselniky?.length == 0) {
      this.selectAllEdit = false;
    }
  }

  /**
   * Zjistí, jestli číselník je vybraný
   * @param value
   */
  public checkSelected(value: CiselnikUserSeznamDto): boolean {
    return this.selectedCiselniky?.some(x => x.guid == value.guid);
  }

  /**
   * Hanlder checkboxu pro hromadnou editaci
   **/
  public selectAllEditChanged(): void {
    this.selectedCiselniky?.forEach(item => {
      item.opravneni = this._anyToOpravneni(this.selectAllEdit);
    });

    this.changed = true;
  }

  private _anyToOpravneni(value: any): CiselnikOpravneniEnum {
    return value ? CiselnikOpravneniEnum.ZobrazitIEditovat : CiselnikOpravneniEnum.PouzeZobrazit;
  }

  /*
   * Nastaví zatržení hromadné editace, pokud jsou vybrané všechny položky jako editovatelné
   * */
  public chekcSelectedAll(): void {
    this.selectAllEdit = !this.selectedCiselniky?.some(x => !x.opravneni);
  }

  /**
   * Handler změny checkboxu editace
   **/
  public onEditChange(): void {
    this.changed = true;
    this.chekcSelectedAll();
  }

  /*
   * Handler pro zavření dialogu
   */
  close(): void {
    this.displayDialog = false;
    this.changed = false;
    this.selectedRole = this.role[0];
    this._selectedByRoleDict = new Map<string, CiselnikUserSeznamDto[]>()
    this.roleChanged(this.role[0].popis);
  }

  /**
   * Zobrazení hlášky o neočekávané chybě
   **/
  private _errorMessage(): void {
    this.messageService.add({ severity: 'error', summary: 'Chyba', key: this.TOAST_KEY, detail: 'Došlo k neočekávané chybě.', life: MessagesUtils.TOAST_LIFE })
  }
}
