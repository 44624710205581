import { Injectable } from '@angular/core';

/** Service pro tisk jprl */
@Injectable({
  providedIn: 'root'
})
export class TiskService {

  private readonly apiLhpTisk = 'api/lhp/Kniha/PorostTisk?guid=';

  constructor() { }

  /** Provede tisk detailu jprl */
  porostTisk(guid: string): void {
    window.open(this.apiLhpTisk + guid);
  }
}
