import { Injectable } from '@angular/core';
import { CanDeactivate} from '@angular/router';
import { CanDeactivateComponent } from '../Components/Shared/can-deactivate/can-deactivate.component';

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<CanDeactivateComponent> {
  canDeactivate(component: CanDeactivateComponent): boolean {

    if (!component.canDeactivate()) {
      if (confirm("Ve formuláři jsou neuložené změny, přejete si jej opustit bez uložení?")) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }
}
