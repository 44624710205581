import { Component, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService  } from 'primeng/api';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import Feature from 'ol/Feature';
import WKB from 'ol/format/WKB';
import { NacrtProjektSourceDto } from '../../../Dto/Nacrty/NacrtProjectSourceDto';
import { UuidUtils } from '../../../Utils/Shared/uuid.utils';
import { SyncService } from '../../../Services/Sync/sync.service';
import { ByteArrayUtils } from '../../../Utils/Mapa/byte-array.utils';
import { MapServices } from '../../../Services/Mapa/map.service';
import { MapInteractionService } from '../../../Services/Mapa/map-interaction.service';
import { ConstructMapLayerUtils } from '../../../Utils/Mapa/construnct-map-layer.utils';
import { LineString, MultiPolygon } from 'ol/geom';
import { MapItemDto } from '../../../Dto/Mapa/MapItemDto';
import { NacrtyInteractionService } from '../../../Services/Nacrty/nacrty-interaction.service';
import { NacrtEditFormComponent } from '../../Nacrty/nacrt-edit-form/nacrt-edit-form.component';
import { IMultipolygonJoinIntoArgs } from '../../../Dto/Mapa/IMultipolygonJoinIntoArgs';
import { DNacrtSyncDto } from '../../../Dto/Nacrty/DNacrtSyncDto';
import { NacrtSyncDto } from '../../../Dto/Nacrty/NacrtSyncDto';
import { NacrtGeometrySyncDto } from '../../../Dto/Nacrty/NacrtGeometrySyncDto';
import { SyncDto } from '../../../Dto/Sync/syncDto';
import GeometryType from 'ol/geom/GeometryType';


/**
 * Komponenta vytvoření metadat nového náčrtu v mapě.
**/
@Component({
  selector: 'app-map-info-nacrt-new',
  templateUrl: './map-info-nacrt-new.component.html',
  styleUrls: ['./map-info-nacrt-new.component.css'],
  providers: [NacrtyInteractionService]
})
export class MapInfoNacrtNewComponent {

  /**
   * Seznam dostupných projektů náčrtu.
  **/
  _nacrtProjects: NacrtProjektSourceDto[] = [];
  @Input() set nacrtProjects(value: NacrtProjektSourceDto[]) {
    if (value != void 0) {
      this._nacrtProjects = value.filter(p => p.editableGeometries);
    }
  }

  /**
   * Událost předávající stav zobrazení komponenty tvorby nového náčrtu.
  **/
  @Output() MapInfoNacrtNewVisible: EventEmitter<boolean> = new EventEmitter<boolean>();


  @ViewChild('editForm') editForm: NacrtEditFormComponent;

  constructor(
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private syncService: SyncService,
    private mapService: MapServices,
    private uuidUtils: UuidUtils,
    private byteArrayUtils: ByteArrayUtils,
    private mapInteractionService: MapInteractionService,
    private nacrtyInteractionService: NacrtyInteractionService,
    private constructMapLayerUtils: ConstructMapLayerUtils)
  {
    this.mapInteractionService.DrawStart.subscribe(geometryType => {
      this.setVisible = true;
      this.item = {
        id: this.uuidUtils.GenerateUuid(),
        modul: 'Nacrty',
        wkt: ''
      };
      this.featureType = geometryType;

      switch (geometryType) {
        case GeometryType.MULTI_POLYGON:
          this.featLength = 0;
          this.featArea = 0;
          break;
        case GeometryType.LINE_STRING:
          this.featLength = 0; 
          this.featArea = null;
          break;
        case GeometryType.POINT:
          this.featArea = null;
          this.featLength = null;
      }
    });

    this.mapInteractionService.FeatureChanged.subscribe(feature => this._feature = feature);
    this.mapInteractionService.UndoEdit.subscribe(featureState => this._feature = featureState);
    this.mapInteractionService.MultipolygonJoinInto.subscribe(this.multipolygonJoinIntoHandler.bind(this));

    this.mapInteractionService.DrawGeometryChanged.subscribe(feature => {
      if (feature.getGeometry() instanceof MultiPolygon) {
        var multipolygon: MultiPolygon = feature.getGeometry();
        this.featArea = multipolygon.getArea();
        let multiPolyLength = 0;
        multipolygon.getPolygons().forEach((p) => {
          multiPolyLength += new LineString(p.getLinearRing(0).getCoordinates()).getLength();
        })
        this.featLength = multiPolyLength;
      }
      if (feature.getGeometry() instanceof LineString) {
        var line: LineString = feature.getGeometry();
        this.featLength = line.getLength();
      }
    });

    this.mapInteractionService.Copy.subscribe(dataToCopy => {
      this.item = dataToCopy;
      this.item.id = this.uuidUtils.GenerateUuid();
      let f = this.constructMapLayerUtils.convertFromSource([dataToCopy])[0];
      this.featureType = f.getGeometry().getType();
      this.toCopy = dataToCopy.modul == 'Lhp' ? null : {
        nacrtProjektGuid: dataToCopy['projektGuid'],
        popis: dataToCopy['popis'],
        poznamka: dataToCopy['poznamka'],
        cisloHoliny: dataToCopy['cislo']
      };
      this.setVisible = true;
    });
  }


  readonly TOAST_KEY: string = 'mapContainerToastKey';
  readonly CONFIRM_KEY: string = 'nacrtEditConfirm';
  faCheck = faCheck;
  faTimes = faTimes;
  blocked: boolean = false;
  _visible: boolean = false;
  _feature: Feature<any> = null;
  featLength: number = null;
  featArea: number = null;
  toCopy: any = null;
  item: MapItemDto = null;
  featureType: string = null;


  /**
   * Setter viditelnosti komponenty. Emituje událost předávající viditelnost komponenty.
  **/
  private set setVisible(value: boolean) {
    this._visible = value;
    this.MapInfoNacrtNewVisible.emit(this._visible);
  }


  /**
   * Uložení editovaného náčrtu.
   * @param event
   */
  save(): void {
    this.blocked = true;
    this.toCopy = null;
    
    let format = new WKB({ hex: false, ewkb: false });
    let dNacrtSyncDto: DNacrtSyncDto = new DNacrtSyncDto();

    dNacrtSyncDto.nacrt = new NacrtSyncDto();
    dNacrtSyncDto.nacrt.guid = this.item.id;
    dNacrtSyncDto.nacrt.akce = [];
    Object.keys(this.editForm.formValue).forEach(key => {
      dNacrtSyncDto.nacrt[key] = this.editForm.formValue[key];
    });

    dNacrtSyncDto.nacrtGeometry = new NacrtGeometrySyncDto();
    dNacrtSyncDto.nacrtGeometry.guid = this.uuidUtils.GenerateUuid();

    let wkb = format.writeGeometry(this._feature.getGeometry());
    dNacrtSyncDto.nacrtGeometry.wkb = this.byteArrayUtils.ToBase64(wkb as ArrayBuffer);

    dNacrtSyncDto.sync = new SyncDto();
    dNacrtSyncDto.sync.i = this.item.id;
    dNacrtSyncDto.sync.u = 0;
    dNacrtSyncDto.sync.h = [];
    dNacrtSyncDto.sync.d = false;
    
    let dataToSync: any[] = [dNacrtSyncDto];
    if (this.nacrtyInteractionService.prilohy != void 0) {
      let prilohyPendingChanges = this.nacrtyInteractionService.prilohy.filter(priloha => !priloha._dirty || priloha.sync.d);
      dataToSync = dataToSync.concat(prilohyPendingChanges);
    }

    this.syncService.post(dataToSync).subscribe(resp => {
      if (resp.processedSuccessfully.length > 0) {
        this.mapService.getMapItemById(resp.processedSuccessfully[0], 'Nacrty').subscribe(mapResp => {
          this._nacrtSavePostProcess(mapResp, this.editForm.formValue['nacrtProjektGuid']);
        });
      }
      else {
        this._nacrtSavePostProcess(resp, this.editForm.formValue['nacrtProjektGuid']);
      }
    });
  }


  /**
   * Zrušení tvorby nového náčrtu.
  **/
  cancel(event): void {
    this.confirmationService.confirm({
      target: event.currentTarget,
      key: this.CONFIRM_KEY,
      message: 'Opravdu si přejete zahodit provedené změny? Změny nebudou uloženy.',
      icon: 'pi pi-exclamation-triangle',
      accept: this.discardAccepted.bind(this),
      acceptLabel: 'Zahodit změny',
      acceptButtonStyleClass: 'p-button-danger',
      reject: () => { },
      rejectLabel: 'Pokračovat v práci'
    });
  }


  /**
   * Potvrzení smaznání změn editovaného náčrtu.
  **/
  discardAccepted(): void {
    this.editForm.reset();
    this.messageService.add({ severity: 'success', summary: 'Změny náčrtu nebyly uloženy.', key: this.TOAST_KEY });
    this._feature = null;
    this.toCopy = null;
    this.setVisible = false;
    this.mapInteractionService.discardChanges(null);
  }


  /**
   * Postprocess uložení náčrtu.
   * @param resp {any} sync post response
   * @param nacrtProjektGuid {string} guid projektu náčrtu, do kterého náčrt patří
   */
  private _nacrtSavePostProcess(resp: any, nacrtProjektGuid: string): void {
    if (resp.isError) {
      let messages = resp.errors.map(err => { return { severity: 'error', summary: 'Chyba', detail: err.text, key: this.TOAST_KEY }; });
      this.messageService.addAll(messages);
    }
    else if (resp.isConflict) {
      this.messageService.add({
        key: this.TOAST_KEY, summary: 'Konflikt', severity: 'error',
        detail: 'Při ukládání náčrtu došlo ke konfliktu. Prosím obnovte stránku a proveďte změny znovu.'
      });
    }
    else {
      if (resp != void 0) {
        resp['targetLayerName'] = 'nacrty_' + nacrtProjektGuid;
        this.messageService.add({ severity: 'success', summary: 'Ok', detail: 'Náčrt byl uložen.', key: this.TOAST_KEY });
        this.setVisible = false;
        this._feature = null;
        this.mapInteractionService.nacrtFinished(resp);
      }
      else {
        this.editForm.reset();
        this._feature = null;
        this.toCopy = null;
        this.setVisible = false;
        this.mapInteractionService.discardChanges(null);
        this.messageService.add({ severity: 'error', summary: 'Chyba', detail: 'Nepodařilo se získat data uloženého náčrtu, zkuste obnovit stránku.', key: this.TOAST_KEY });
      }
    }
    this.blocked = false;
  }


  /**
   * Handler události sloučení náčrtů do multipolygonu.
   * @param data {IMultipolygonJoinIntoArgs} argumetry události (featrue, zdrojová metadata)
   */
  private multipolygonJoinIntoHandler(data: IMultipolygonJoinIntoArgs): void {
    this.item = {
      id: this.uuidUtils.GenerateUuid(),
      modul: 'Nacrty',
      wkt: ''
    };
    this._feature = data.feature;
    this.featureType = this._feature.getGeometry().getType();
    this.toCopy = {
      nacrtProjektGuid: data.metadataSource.projektGuid,
      popis: data.metadataSource.popis,
      poznamka: data.metadataSource.poznamka,
      cisloHoliny: data.metadataSource.cislo
    }
    this.setVisible = true;
  }
}

