<p-overlayPanel #overlayPanel styleClass="new-nacrt-op">
  <ng-template pTemplate>
    <button pButton type="button"
            class="p-button-rounded p-button-secondary p-button-outlined icon-btn hide-label-button nacrt-create-overlay-btn"
            pTooltip="Kreslit bod" tooltipPosition="left"
            (click)="drawPoint($event)">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
           version="1.1" width="18" height="24" viewBox="0 0 24 18" class="svg-icon" style="margin-top: 0.2rem;">
        <path fill="#607D8B" d="M12,2H16V5H22V7H16V10H8V7H2V5H8V2M10,4V8H14V4H10Z" />
      </svg>
    </button>
    <button pButton type="button"
            class="p-button-rounded p-button-secondary p-button-outlined icon-btn hide-label-button nacrt-create-overlay-btn"
            pTooltip="Kreslit linii" tooltipPosition="left"
            (click)="drawLine($event)">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
           version="1.1" width="24" height="24" viewBox="0 0 24 24" class="svg-icon">
        <path fill="#607D8B" d="M2 3V9H4.95L6.95 15H6V21H12V16.41L17.41 11H22V5H16V9.57L10.59 15H9.06L7.06 9H8V3M4 5H6V7H4M18 7H20V9H18M8 17H10V19H8Z" />
      </svg>
    </button>
    <button pButton type="button"
            class="p-button-rounded p-button-secondary p-button-outlined icon-btn hide-label-button nacrt-create-overlay-btn"
            pTooltip="Kreslit polygon" tooltipPosition="left"
            (click)="drawPolygon($event)">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
           version="1.1" width="24" height="24" viewBox="0 0 24 24" class="svg-icon">
        <path fill="#607D8B" d="M2,2V8H4.28L5.57,16H4V22H10V20.06L15,20.05V22H21V16H19.17L20,9H22V3H16V6.53L14.8,8H9.59L8,5.82V2M4,4H6V6H4M18,5H20V7H18M6.31,8H7.11L9,10.59V14H15V10.91L16.57,9H18L17.16,16H15V18.06H10V16H7.6M11,10H13V12H11M6,18H8V20H6M17,18H19V20H17" />
      </svg>
    </button>
  </ng-template>
</p-overlayPanel>

<button pButton *ngIf="canEdit" type="button" [ngStyle]="{'right':calculatedOffset}" [ngClass]="{'hidden-nacrt-create': !createBtnVisible}"
        class="p-button-rounded p-button-secondary p-button-outlined icon-btn hide-label-button nacrt-create-btn"
        (click)="createBtnClick($event)">
  <fa-icon [icon]="faPlus"></fa-icon>
</button>

