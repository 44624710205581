import { ActivatedRoute } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { KnihaService } from '../../Services/Lhp/kniha.service';
import { OnInit, Directive } from '@angular/core';

/** Bázová třída obashující společnou inicializaci pro komponenty "Knihy" */
@Directive()
export abstract class KnihaBase implements OnInit {

  /**
   * Konstruktor je "protected", protože se neočekává vytváření instancí
   * přímo této třídy. Odvozené třídy už mají konstruktor "public".
   * 
   * @param route Informace o aktivní cestě
   * @param knihaService Služba pro data knihy
   */
  protected constructor(
    protected route: ActivatedRoute,
    protected knihaService: KnihaService
  ) { }

  /** Položky drobečkové navigace */
  public breadcrumbs: MenuItem[];

  /** Domovský odkaz drobečkové navigace */
  public breadcrumbHome: MenuItem;

  protected lhcKod: string;
  protected lhcGuid: string;
  protected oddGuid: string;
  protected dilGuid: string;
  protected porGuid: string;
  protected pskGuid: string;
  protected etzGuid: string;
  protected drevinGuid: string;
  protected bzlGuid: string;
  protected jpGuid: string;
  protected opGuid: string;
  protected guid: string; //guid dané úrovně JPRL
  protected odd: string;
  protected dil: string;
  protected por: string;
  protected psk: string;
  protected etaz: string;
  protected drevin: string;
  protected bzl: string;
  protected jp: string;
  protected op: string;
  protected katuzeKod: number;

  /** Inicializace (předpokládá se volání této metody z ngOnInit) */
  protected init() {
    this.lhcKod = this.route.snapshot.paramMap.get('lhcKod');
    this.guid = this.route.snapshot.paramMap.get('guid');

    if (!this.route.snapshot.paramMap.has('odd')) {
      this.lhcGuid = this.guid;
    }

    if (this.route.snapshot.paramMap.has('odd')) {
      this.odd = this.route.snapshot.paramMap.get('odd');
    }

    if (this.route.snapshot.paramMap.has('dil')) {
      this.dil = this.route.snapshot.paramMap.get('dil');
    }

    if (this.route.snapshot.paramMap.has('por')) {
      this.por = this.route.snapshot.paramMap.get('por');
    }

    if (this.route.snapshot.paramMap.has('psk')) {
      this.psk = this.route.snapshot.paramMap.get('psk');
    }

    if (this.route.snapshot.paramMap.has('bzl')) {
      this.bzl = this.route.snapshot.paramMap.get('bzl');
      this.bzlGuid = this.route.snapshot.paramMap.get('guid');
    }

    if (this.route.snapshot.paramMap.has('op')) {
      this.op = this.route.snapshot.paramMap.get('op');
      this.opGuid = this.route.snapshot.paramMap.get('guid');
    }

    if (this.route.snapshot.paramMap.has('jp')) {
      this.jp = this.route.snapshot.paramMap.get('jp');
      this.jpGuid = this.route.snapshot.paramMap.get('guid');
    }

    if (this.route.snapshot.paramMap.has('etaz')) {
      this.etaz = this.route.snapshot.paramMap.get('etaz');
    }

    if (this.route.snapshot.paramMap.has('drevina')) {
      this.drevin = this.route.snapshot.paramMap.get('drevina');
      this.drevinGuid = this.route.snapshot.paramMap.get('guid');
    }

    if (this.route.snapshot.paramMap.has('katuzeKod')) {
      this.katuzeKod = +this.route.snapshot.paramMap.get('katuzeKod');
    }
  }

  protected constructBreadCrumbs() {
    this.breadcrumbHome = { icon: 'pi pi-home', routerLink: ['/kniha'] };

    this.breadcrumbs = [
      { label: 'LHC ' + this.lhcKod, routerLink: ['/kniha', this.lhcKod, this.lhcGuid] }
    ];

    if (this.route.snapshot.paramMap.has('odd')) {
      this.breadcrumbs.push(
        { label: 'Oddělení ' + this.odd, routerLink: ['/kniha', this.lhcKod, this.odd, this.oddGuid] }
      );
    }

    if (this.route.snapshot.paramMap.has('dil')) {
      this.breadcrumbs.push(
        { label: 'Dílec ' + this.dil, routerLink: ['/kniha', this.lhcKod, this.odd, this.dil, this.dilGuid] }
      );
    }

    if (this.route.snapshot.paramMap.has('por')) {
      this.breadcrumbs.push(
        { label: 'Porost ' + this.por, routerLink: ['/kniha', this.lhcKod, this.odd, this.dil, this.por, this.porGuid] }
      );
    }

    if (this.route.snapshot.paramMap.has('psk')) {
      this.breadcrumbs.push(
        { label: 'Porostní skupina ' + this.psk, routerLink: ['/kniha', this.lhcKod, this.odd, this.dil, this.por, 'psk', this.psk, this.pskGuid] }
      );
    }

    if (this.route.snapshot.paramMap.has('bzl')) {
      this.breadcrumbs.push(
        { label: 'Bezlesí ' + this.bzl, routerLink: ['/kniha', this.lhcKod, this.odd, this.dil, this.por, 'bzl', this.bzl, this.katuzeKod, this.bzlGuid] }
      );
    }

    if (this.route.snapshot.paramMap.has('op')) {
      this.breadcrumbs.push(
        { label: 'Ostatní plocha ' + this.op, routerLink: ['/kniha', this.lhcKod, this.odd, this.dil, this.por, 'op', this.op, this.katuzeKod, this.opGuid] }
      );
    }

    if (this.route.snapshot.paramMap.has('jp')) {
      this.breadcrumbs.push(
        { label: 'Jiná plocha ' + this.jp, routerLink: ['/kniha', this.lhcKod, this.odd, this.dil, this.por, 'jp', this.jp, this.katuzeKod, this.jpGuid] }
      );
    }

    if (this.route.snapshot.paramMap.has('etaz')) {
      this.breadcrumbs.push(
        { label: 'Etáž ' + this.etaz, routerLink: ['/kniha', this.lhcKod, this.odd, this.dil, this.por, this.psk, this.etaz, this.etzGuid] }
      );
    }

    if (this.route.snapshot.paramMap.has('drevina')) {
      this.breadcrumbs.push(
        { label: 'Dřevina ' + this.drevin, routerLink: ['/kniha', this.lhcKod, this.odd, this.dil, this.por, this.psk, this.etaz, this.drevin, this.drevinGuid] }
      );
    }
  }

  ngOnInit() {
    this.route.url.subscribe(() => {
      this.init();
      this.loadModel();
    });
  }

  protected abstract loadModel();
}
