import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DLheListDto } from '../../Dto/Lhe/DLheListDto';

/*
 * Service pro práci s lesní hospodářskou evidencí
*/
@Injectable({
  providedIn: 'root'
})
export class LheService {

  constructor
  (
    private http: HttpClient
  )
  {
  }

  private apiLhe: string = 'api/lhe/LheByPsk';

  /**
   * Vrací seznam Lhe podle pskGuid
   * @param pskGuid
   */
  public lhe(pskGuid: string): Observable<DLheListDto[]> {
    return this.http.get<DLheListDto[]>(this.apiLhe + '/' + pskGuid);
  }
}
