<p-toast [key]="NACRT_PROJEKT_TOAST" position="top-center"></p-toast>

<p-table #projektyNacrtu [value]="projekty" dataKey="guid" selectionMode="single" [(selection)]="selectedProjekt"
         [rows]="10" [rowsPerPageOptions]="[10,25,50]" [loading]="loading" styleClass="p-datatable-gridlines p-datatable-striped pds-table-compact"
         [paginator]="true"
         [globalFilterFields]="['skupina','nazev']">
  <ng-template pTemplate="caption">
    <div class="base-toolbar">
      <div class="p-toolbar-group-left">
        <h2 class="toolbar-label">Projekty náčrtů</h2>
        <i class="p-toolbar-separator"><fa-icon [icon]="faEllipsisV"></fa-icon></i>
        <div style="display: inline-block">
          <div class="p-inputgroup p-input-icon-left p-ml-2">
            <i class="pi pi-search"></i>
            <input #globalFilter pInputText type="text" (input)="projektyNacrtu.filterGlobal($event.target.value, 'contains')"
                   placeholder="Hledat" style="width: 20em" />
            <button pButton type="button" class="icon-btn hide-label-button" (click)="globalFilter.value = '';projektyNacrtu.filterGlobal(undefined, 'contains');" [disabled]="globalFilter.value == ''"><fa-icon [icon]="faBackspace"></fa-icon></button>

          </div>

        </div>
        <button pButton type="button" label="Nový" class="icon-btn"
                [disabled]="selectedProjekt != undefined || !canEdit" 
                (click)="add()"><fa-icon [icon]="faPlus"></fa-icon></button>
      </div>
      <div class="p-toolbar-group-right">
        <button pButton type="button" label="Zpět" class="icon-btn" (click)="back()"><fa-icon [icon]="faUndo"></fa-icon></button>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 12%;" pSortableColumn="skupina">Skupina <p-sortIcon field="skupina"></p-sortIcon></th>
      <th pSortableColumn="nazev">Název <p-sortIcon field="nazev"></p-sortIcon></th>
      <th style="width:13%;">Org. úrověň</th>
      <th style="width:10%;">Povolené typy</th>
      <th style="width:10%;">Náhled</th>
      <th style="width:8%;" pSortableColumn="zIndex">Z-index <p-sortIcon field="zIndex"></p-sortIcon></th>
      <th style="width:10%;" pSortableColumn="editableStyle">Úprava stylu <p-sortIcon field="editableStyle"></p-sortIcon></th>
      <th style="width:10%;" pSortableColumn="editableGeometries">Úprava náčrtů <p-sortIcon field="editableGeometries"></p-sortIcon></th>
    </tr>
    <tr>
      <th style="width:12%;">
        <p-columnFilter field="skupina" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <p-multiSelect [ngModel]="value" [options]="skupinyOptions" placeholder="Vše" (onChange)="filter($event.value)" optionLabel="nazev" optionValue="nazev">
              <ng-template let-option pTemplate="item">
                <span class="p-ml-1">{{option.nazev}}</span>
              </ng-template>
            </p-multiSelect>
          </ng-template>
        </p-columnFilter>
      </th>
      <th>
        <p-columnFilter type="text" field="nazev"></p-columnFilter>
      </th>
      <th style="width:13%;">
        <app-org-ur (valueChanged)="filterOrgUrHandler($event)"></app-org-ur>
      </th>
      <th colspan="5">
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-projekt>
    <tr [pSelectableRow]="projekt">
      <td style="width:12%;">
        <span class="image-text">{{projekt.skupina}}</span>
      </td>
      <td>
        {{projekt.nazev}}
      </td>
      <td style="width:13%;">
        <app-org-ur-display [guid]="projekt.orgUrGuids[0]" *ngIf="projekt.orgUrGuids?.length>0"></app-org-ur-display>
      </td>
      <td style="width:10%;">
        <svg [ngClass]="{'svg-icon': true,'primary-icon':projekt.bod,'surface-icon':!projekt.bod}">
          <use xlink:href="assets/vector-point-no-arrow-colored.svg#point"></use>
        </svg>
        <svg [ngClass]="{'svg-icon': true,'primary-icon':projekt.linie,'surface-icon':!projekt.linie}">
          <use xlink:href="assets/vector-polyline-colored.svg#line"></use>
        </svg>
        <svg [ngClass]="{'svg-icon': true,'primary-icon':projekt.plocha,'surface-icon':!projekt.plocha}">
          <use xlink:href="assets/vector-polygon-colored.svg#polygon"></use>
        </svg>
      </td>
      <td style="width:10%;">
        <div class="preview-grid">
          <div style="min-width:{{ previewSize }}px;"><app-preview-point *ngIf="projekt.bod" [projekt]="projekt" [previewSize]="previewSize"></app-preview-point></div>
          <div style="min-width:{{ previewSize }}px;"><app-preview-line *ngIf="projekt.linie" [projekt]="projekt" [previewSize]="previewSize" class="p-col"></app-preview-line></div>
          <div style="min-width:{{ previewSize }}px;"><app-preview-polygon *ngIf="projekt.plocha" [projekt]="projekt" [previewSize]="previewSize" class="p-col"></app-preview-polygon></div>
        </div>
      </td>
      <td style="width:8%;">
        {{projekt.zIndex}}
      </td>
      <td style="width:10%;">
        {{boolToText(projekt.editableStyle)}}
      </td>
      <td style="width:10%;">
        {{boolToText(projekt.editableGeometries)}}
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="paginatorright" let-state>
    <div class="paginator-right">
      Zobrazeno {{state.totalRecords < 1 ? 0 : state.first + 1}} -
      {{
      state.totalRecords > 0 ? ((state.rows+ state.first) <= state.totalRecords ? (state.rows + state.first) :
      state.totalRecords) : 0
      }} z {{state.totalRecords ? state.totalRecords : 0}} záznamů
    </div>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="8">Nebyl nalezen žádný projekt</td>
    </tr>
  </ng-template>
</p-table>

<app-nacrty-projekt-edit [skupiny]="skupiny" [selectedProjekt]="selectedProjekt" [projekty]="projekty"
  (onCancel)="onFormCanceled()" (onSave)="onFormSaved($event)" (onDelete)="onDelete($event)">
</app-nacrty-projekt-edit>
