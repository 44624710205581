<div class="p-grid p-align-stretch kniha-detail-form p-mb-2">
  <div class="p-col-1">
    <app-label-input-display [value]="jp?.jp" label="JP" tooltip="Jiný pozemek" [formatConent]="false"></app-label-input-display>
  </div>
  <div class="p-col-1">
    <app-ciselnik-field key="CJpDruh" [guid]="jp?.jpDruhGuid" label="Druh JP" variant="short" tooltip="Druh jiného pozemku"></app-ciselnik-field>
  </div>
  <div class="p-col-1">
    <app-ciselnik-field key="CLvs" [guid]="jp?.lvsGuid" label="LVS" variant="short" tooltip="Lesní vegetační stupeň"></app-ciselnik-field>
  </div>

  <div class="p-col">
    <span pTooltip="Způsob využití" class="tooltip-label" tooltipPosition="top">Zp. využití</span>
    <div class="p-inputtext ui-corner-all overflow-scroll">{{jp?.jpVyuz}}</div>
  </div>
</div>
<app-kniha-rozdeleni-lesa [rozdeleniLesa]="jp"></app-kniha-rozdeleni-lesa>
