import { Injectable } from "@angular/core";

/**
 * Pomocná třída pro práci s byte[].
**/
@Injectable({
  providedIn: 'root'
})
export class ByteArrayUtils {
  /**
   * Konverze byte[] do base64 stringu.
   * @param data {ArrayBuffer}
   */
  public ToBase64(data: ArrayBuffer): string {
    var binary = '';
    var bytes = new Uint8Array(data);
    for (var i = 0; i < bytes.byteLength; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }
}
