import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as xlsx from 'xlsx';

/**
 * Utilita pro práci s daty tabulky
 **/
@Injectable({
  providedIn: 'root'
})
export class TableUtils {

  /**
   * Spustí export do XLSX.
   * @param data - data pro export
   * @param fileName - bude uvedeno v názvu exportovaného souboru. V základu je nastaveno "data". 
   */
  public exportExcel(data: any[], fileName: string = "data"): void {
      const worksheet = xlsx.utils.json_to_sheet(data);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this._saveAsExcelFile(excelBuffer, fileName);
  }

  /**
   * Uloží data do XLSX souboru
   * @param buffer
   * @param fileName
   */
  private _saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

}
