import { Component, OnInit, ViewChild } from '@angular/core';
import { faBackspace, faEllipsisV, faPlus, faSave, faTimes, faUndo } from '@fortawesome/free-solid-svg-icons';
import { MessageService, TreeNode } from 'primeng/api';
import { AuthService } from '../../../Services/Auth/auth.service';
import { CiselnikyService } from '../../../Services/Shared/ciselniky.service';
import { TreeTable } from 'primeng/treetable';
import { COrgUrDetailDto } from '../../../Dto/Zakaznik/COrgUrDetailDto';
import { OrgurService } from '../../../Services/Zakaznik/orgur.service';
import { OrgUrComponent } from '../../Shared/org-ur/org-ur.component';
import { FormBuilder, Validators } from '@angular/forms';
import { OrgUrUtils } from '../../../Utils/Shared/org-ur.utils';
import { PodCiselnikListDto } from '../../../Dto/Shared/PodCiselnikListDto';
import { Router } from '@angular/router';
import { ZakaznikDetailDto } from '../../../Dto/Zakaznik/ZakaznikDetailDto';

/**
 *  Komponenta seznamu orgurů
*/
@Component({
  selector: 'app-list-orgur',
  templateUrl: './list-orgur.component.html',
  styleUrls: ['./list-orgur.component.css']
})
export class ListOrgurComponent implements OnInit {

  constructor
  (
    private ciselnikyService: CiselnikyService,
    private messageService: MessageService,
    private orgurService: OrgurService,
    private formBuilder: FormBuilder,
    private orgUrUtils: OrgUrUtils,
    private authService: AuthService,
    private router: Router
  )
  {
  }

  ngOnInit(): void {
    this.block = true;
    this.authService.info().subscribe(res => {
      if (res != undefined) {
        if (!res.roles.includes('SuperAdmin')) {
          this.orgUrMode = 'base';
        }
      }
      else {
        this.messageService.add({ severity: 'error', summary: 'Došlo k neočekávané chybě', key: this.MESSAGE_KEY });
      }
      this.block = false;
    });
  }

  @ViewChild('orgurList') orgurList: TreeTable;
  @ViewChild(OrgUrComponent) nadrazenaUroven: OrgUrComponent;

  block: boolean = false;

  lhcTree: TreeNode[];
  selectedOrgUr: TreeNode;
  selectedZakaznik: ZakaznikDetailDto;
  orgUrMode: string = 'admin';
  showForm: boolean = false;

  faEllipsisV = faEllipsisV;
  faBackspace = faBackspace;
  faUndo = faUndo;
  faPlus = faPlus;
  faTimes = faTimes;
  faSave = faSave;

  //formulář
  form = this.formBuilder.group({
    guid: [''],
    parentGuid: [''],
    zakaznikGuid: [''],
    nazev: ['', Validators.required],
    kod: ['', Validators.required],
    poznamka: [''],
    platnostOd: ['', Validators.required],
    platnostDo: ['']
  });

  private _filter: string;

  get filter(): string {
    return this._filter;
  }

  set filter(newFilter: string) {
    this.orgurList.filter(newFilter, "popis", "contains");
    this._filter = newFilter;
  }

  get data(): COrgUrDetailDto {
    let dto: COrgUrDetailDto = this.form.value;
    dto.zakaznikGuid = this.selectedZakaznik.guid;
    return dto;
  }

  set data(val: COrgUrDetailDto) {
    this.form.reset();
    this.form.patchValue(val);
  }

  private MESSAGE_KEY = 'orgur-list-toast';

  /**
   * Navigace na úvodní stránku
  */
  public back(): void {
    this.router.navigate(["/"]);
  }

 

  /**
   * Převede dto na TreeNode
   * @param value
   */
  private _ciselnikToTreeNode(value: PodCiselnikListDto): TreeNode {
    return {
      label: value.kod + " - " + value.popis,
      data: value
    };
  }

  /**
   * Načte strom podle změny zákazníka
   * @param value {string} guid zákazníka
   */
  private _changeZakaznik(value: string) {
    if (value != void 0 && value != '') {
      this.ciselnikyService.getOrgUr(value, true).subscribe(res => {
        this.lhcTree = this.orgUrUtils.gerTreeFromCiselnik((res as PodCiselnikListDto[]), res.map(x => x.guid), true, false, true);
      });
    }
  }

  /**
   * Zpracuje změnu zákazníka v dropdownu
   * @param zakaznik
   */
  onZakaznikChange(dto: ZakaznikDetailDto): void {
    this._changeZakaznik(dto?.guid);
  }

  /**
  * Zpracuje výběr ve stromu organizačních úrovní
  */
  orgUrSelect(event) {
    this.block = true;
    this.orgurService.get(event.node.data.guid).subscribe(res => {
      if (res.success) {
        this.data = res.data;
        this.showForm = true;
      }
      else {
        this.messageService.add({ severity: 'error', summary: 'Chyba', key: this.MESSAGE_KEY, detail: 'Nepovedlo se nalézt příslušnou organizační úroveň.' });
      }
      this.block = false;
    });
  }

  /**
  * Vytvoří nový ukládací formulář
  */
  add() {
    this.data = new COrgUrDetailDto();
    this.showForm = true;
  }

  /**
   * Zruší ukládací formulář
  */
  cancel() {
    this.data = undefined;
    this.selectedOrgUr = undefined;
    this.showForm = false;
  }

  /**
   * Uloží novou organizační úroveň nebo změnu stávající
  */
  save(): void {
    this.block = true;
    this.orgurService.saveOrUpdate(this.data).subscribe(res => {
      if (res.success) {
        this.messageService.add({ severity: 'success', summary: 'OK', key: this.MESSAGE_KEY, detail: 'Organizační úroveň byla úspěšně uložena.' });
        this._changeZakaznik(this.selectedZakaznik.guid);
      }
      else {
        this.messageService.add({ severity: 'error', summary: 'Chyba', key: this.MESSAGE_KEY, detail: res.messages[0] });
      }
      this.block = false;
      this.data = undefined;
      this.selectedOrgUr = undefined;
      this.showForm = false;
    });
  }
}
