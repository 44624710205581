<p-sidebar [(visible)]="infoPanelVisible" [modal]="false" [dismissible]="false" [showCloseIcon]="false" position="right"
           [styleClass]="'map-side-bar'" [style]="{'width':panelWidth}" [ngClass]="{'map-side-bar-nav':displayNav}"
           (onHide)="onInfoPanelHide()"
           (onShow)="onInfoPanelShow()">

  <p-toolbar>
    <div class="p-toolbar-group-left">
      <fa-icon [icon]="faInfoCircle" style="color: var(--text-color-secondary); margin-right: 8px; font-size: 1.8em;"></fa-icon>
      <app-map-info-lhp-list [items]="infoItems['Lhp']"></app-map-info-lhp-list>
      <app-multipolygon-join-into [items]="infoItems['Nacrty']" class="p-ml-2"></app-multipolygon-join-into>
      <app-seznam-parcel class="p-ml-2"></app-seznam-parcel>
    </div>
    <div class="p-toolbar-group-right">
      <button pButton class="icon-btn hide-label-button info-panel-close-button"
              pTooltip="Zavření panelu" [disabled]="!panelClosable"
              (click)="close()">
        <fa-icon [icon]="faTimes"></fa-icon>
      </button>
    </div>
  </p-toolbar>

  <div #infoPanelContent class="map-side-bar-content info-panel-content">
    <app-map-info-help></app-map-info-help>
    <app-map-info-nacrt-new [nacrtProjects]="_nacrtProjects"
                            (MapInfoNacrtNewVisible)="newVisibleHandler($event)"></app-map-info-nacrt-new>
    <app-schvalit-lhe></app-schvalit-lhe>
    <app-map-info-lhp *ngFor="let item of infoItems['Lhp'];"
                      [item]="item"></app-map-info-lhp>
    <app-map-info-nacrt *ngFor="let item of infoItems['Nacrty'];"
                        [item]="item" [nacrtProjects]="_nacrtProjects"></app-map-info-nacrt>
    <app-map-info-pes *ngFor="let item of infoItems['Pes'];"
                      [item]="item"></app-map-info-pes>
    <app-map-info-katastr></app-map-info-katastr>
  </div>
</p-sidebar>
