import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faFlagCheckered, faSave, faUndo } from '@fortawesome/free-solid-svg-icons';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { KubirovaniDetailDto, VypocetKubirovaniListDto } from 'src/app/Dto/Vyroba/KubirovaniDetailDto';
import { KubirovaniStatistikaListDto } from 'src/app/Dto/Vyroba/KubirovaniStatistikaListDto';
import { KubirovaniTypGuids } from 'src/app/Dto/Vyroba/KubirovaniTypGuids';
import { CiselnikyService } from 'src/app/Services/Shared/ciselniky.service';
import { VyrobaService } from 'src/app/Services/Vyroba/vyroba.service';
import { MessagesUtils } from 'src/app/Utils/Shared/messages.utils';
import { UuidUtils } from 'src/app/Utils/Shared/uuid.utils';
import { VyrobaKontrolaComponent } from '../vyroba-kontrola/vyroba-kontrola.component';
import { CiselnikListDto } from 'src/app/Dto/Shared/CiselnikListDto';
import { NextOnEnterDirective } from 'src/app/Directives/next-on-enter.directive';
import { CiselnikDropdownComponent } from 'src/app/Components/Shared/ciselnik-dropdown/ciselnik-dropdown.component';
import { CalendarComponent } from '../../Shared/calendar/calendar.component';

@Component({
  selector: 'app-vyroba-cd-edit',
  templateUrl: './vyroba-cd-edit.component.html',
  styleUrls: ['./vyroba-cd-edit.component.css']
})
export class VyrobaCdEditComponent implements OnInit, AfterViewInit {

  private readonly MESSAGE_KEY: string = "cd-edit-toast";
  private dataLoaded: boolean = false;

  // Zde se ukládá request subscription pro přepočet množství, aby bylo možné stornovat běžící request při úpravě vstupu
  // a pokračovat s novými vstupy.
  private recalcSubscription: Subscription = null;

  constructor(
    private activatedRoute: ActivatedRoute,
    private vyrobaService: VyrobaService,
    private messageUtils: MessagesUtils,
    private messageService: MessageService,
    private router: Router,
    private ciselnikyService: CiselnikyService,
    private uuidUtils: UuidUtils
  ) { }

  data: KubirovaniDetailDto = new KubirovaniDetailDto();

  // vybraný řádek gridu
  aktivniRadek: VypocetKubirovaniListDto = new VypocetKubirovaniListDto();

  statistika: KubirovaniStatistikaListDto[] = [];

  faSave = faSave;
  faFlagCheckered = faFlagCheckered;
  faUndo = faUndo;

  @ViewChild('dodavatelDropDown') dodavatelDropDown: CiselnikDropdownComponent;
  @ViewChild('datumDokladu') datumDokladu: CalendarComponent;
  @ViewChild('kontrolaTree') kontrolaTree: VyrobaKontrolaComponent;
  @ViewChild(NextOnEnterDirective) nextOnEnter: NextOnEnterDirective;

  enDelka: boolean;
  enPrumer: boolean;
  enTrida: boolean;
  enOddenkyKs: boolean;
  enCelkemKs: boolean;
  enCisloHrane: boolean;
  enDelkaHrane: boolean;
  enVyskaHrane: boolean;
  enSirkaHrane: boolean;
  enKoeficient: boolean;
  enPribVz: boolean;
  enMnozstviRucne: boolean;

  hraneGuid = KubirovaniTypGuids.hrane;

  // TODO: povolená pole možná pak číst z webAPI, protože tam by pak při save mohla (měla) probíhat kontrola,
  // že jsou vyplněné jen ty vstupy, co mají být(nebo ukládat jen ty vstupy, co mají být)
  fieldsMap: {} = {};

  // kolekce kontrolních funkcí (posouzení kompletnosti vstupu pro výpočet)
  kontrola: {} = {};

  edit: boolean = false;
  block: boolean = false;
  tezebniVykony: CiselnikListDto[] = [];

  // inicializace povolených vstupů (musí být takto aby bylo možno využít KubirovaniTypGuids)
  private _setupFieldsMap(): void {
    this.fieldsMap[KubirovaniTypGuids.sKurou] = ["Delka", "Prumer", "CelkemKs", "OddenkyKs", "PribVz"];
    this.fieldsMap[KubirovaniTypGuids.bezKury] = ["Delka", "Prumer", "CelkemKs", "OddenkyKs", "PribVz"];
    this.fieldsMap[KubirovaniTypGuids.rakouska] = ["Delka", "Prumer", "CelkemKs", "PribVz"];
    this.fieldsMap[KubirovaniTypGuids.kulatina] = ["Delka", "Prumer", "CelkemKs", "PribVz"];
    this.fieldsMap[KubirovaniTypGuids.teplicka] = ["Trida", "CelkemKs", "OddenkyKs", "PribVz"];
    this.fieldsMap[KubirovaniTypGuids.hrane] = ["CisloHrane", "DelkaHrane", "VyskaHrane", "SirkaHrane", "Koeficient", "MnozstviRucne", "CelkemKs", "OddenkyKs", "PribVz"];
  }

  // inicializace kontroních mechanismů (musí být takto aby bylo možno využít KubirovaniTypGuids)
  private _setupKontrola(): void {
    this.kontrola[KubirovaniTypGuids.sKurou] =
      (d: VypocetKubirovaniListDto) => (d.drevinaGuid != undefined && d.delka > 0 && d.prumer > 0 && (d.celkemKs > 0 || d.oddenkyKs > 0));
    this.kontrola[KubirovaniTypGuids.bezKury] =
      (d: VypocetKubirovaniListDto) => (d.drevinaGuid != undefined && d.delka > 0 && d.prumer > 0 && d.celkemKs > 0);
    this.kontrola[KubirovaniTypGuids.rakouska] =
      (d: VypocetKubirovaniListDto) => (d.drevinaGuid != undefined && d.delka > 0 && d.prumer > 0 && d.celkemKs > 0);
    this.kontrola[KubirovaniTypGuids.kulatina] =
      (d: VypocetKubirovaniListDto) => (d.delka > 0 && d.prumer > 0 && d.celkemKs > 0);
    this.kontrola[KubirovaniTypGuids.teplicka] =
      (d: VypocetKubirovaniListDto) => (d.trida != undefined && d.celkemKs > 0);
    this.kontrola[KubirovaniTypGuids.hrane] =
      (d: VypocetKubirovaniListDto) => (d.delka > 0 && d.delkaHrane > 0 && d.vyskaHrane > 0 && d.koeficient > 0);
  }

  //TODO: dočasné omezení dostupných metod kubírování - #27824
  dostupneTypyVypoctu: CiselnikListDto[] = [];

  ngOnInit(): void {
    this._setupFieldsMap();
    this._setupKontrola();

    //TODO: dočasné omezení dostupných metod kubírování - #27824
    this.ciselnikyService.getCislenik('CVyrTypVypoctu', null).subscribe(resp => {
      this.dostupneTypyVypoctu = resp.filter(x => ['10', '20', '50', '60'].includes(x.kod));
    });

    this.ciselnikyService.getVykonyTezebni().subscribe(resp => this.tezebniVykony = resp);

    // inicializace teď hlavně jako prevence výpisu chyb v console, do budoucna pro nový doklad
    this.data = new KubirovaniDetailDto();

    this.activatedRoute.params.subscribe(param => {
      var guid = param.guid;
      this.edit = param.state == "edit" ? true : false;

      this.vyrobaService.getCdDetail(guid).subscribe(resp => {
        this.data = resp;
        this.data.vypocty = this.data.vypocty.sort((a, b) => a.cislo > b.cislo ? 1 : -1);

        if (this.data.vypocty.length > 0) {
          this.aktivniRadek = this.data.vypocty[0];
          this.setTypVypoctu(this.aktivniRadek.typVypoctuGuid);
        }
        else {
          this.addRadek();
        }
        this.datumDokladu.toggle();//provede se focus
      });
      this.dataLoaded = true;
      setTimeout(() => { this.block = false; });
    });
  }

  ngAfterViewInit(): void {
    this.block = !this.dataLoaded;
  }

  addRadek(): void {
    if (this.data != undefined) {
      var novy = new VypocetKubirovaniListDto();

      novy.guid = this.uuidUtils.GenerateUuid();
      novy.cislo = Math.max(...this.data.vypocty.map(o => o.cislo), 0) + 1;
      if (this.aktivniRadek != null) {
        novy.dodavatelGuid = this.aktivniRadek.dodavatelGuid;
        novy.podvykonGuid = this.aktivniRadek.podvykonGuid;
        novy.druhTezbyGuid = this.aktivniRadek.druhTezbyGuid;
        novy.typVypoctuGuid = this.aktivniRadek.typVypoctuGuid;
        novy.drevinaGuid = this.aktivniRadek.drevinaGuid;
        novy.sortimentGuid = this.aktivniRadek.sortimentGuid;
      }

      this.data.vypocty = this.data.vypocty.concat([novy]);
      this.aktivniRadek = novy;
      this.focusRadekOnFirst();
    }
  }

  delRadek(): void {
    if (this.data != undefined && this.aktivniRadek != null) {
      var i = this.data.vypocty.indexOf(this.aktivniRadek);

      // úprava pole
      var array = this.data.vypocty;
      array.splice(i, 1); // returns removed elements... ty nepotřebuju...

      //přepíše pořadová čásla
      var cislo = 1;
      array.forEach(function (v) {
        v.cislo = cislo++;
      });

      this.data.vypocty = array; //... naopak potřebuju přiřadit do kolekce, aby to grid pochopil

      if (i >= this.data.vypocty.length) i--; // pokud jsem smazal poslední řádek, chci vybrat poslední co zůstal

      this.aktivniRadek = this.data.vypocty[i];
      this.focusRadekOnFirst();
    }
  }



  /**
   * Stisk klávesy. U "klíčových" kláves provede požadovanou akci, ale nenapíše znak, pokud je tisknutelný (např. "*", "/").
   * @param event
   */
  onKeyDown(event): void {
    switch (event.keyCode) {
      case 33:
        event.preventDefault();
        this.onArrowUp();
        break;
      case 34:
        event.preventDefault();
        this.onArrowDown();
        break;
      case 106:
        event.preventDefault();
        this.addRadek();
        break;
      case 111:
        event.preventDefault();
        this._copyValue(event);
        break;
    }
  }

  /**
   * Zkopíruje hodnotu políčka z předcházejícího řádku. Vyžaduje atribut 'name' u vstupního políčka pro které se má hodnota kopírovat shodný
   * s klíčem odpovídající položky v modelu formuláře.
   * @param event
   */
  private _copyValue(event: KeyboardEvent): void {
    let id: string = (event.target as any).id;
    if (id) {
      // ID má "dohodnutou" strukturu {kde}-{co}-{typ}, zde potřebuji {co}
      let tokens = id.split("-");
      if (tokens.length > 1) {
        let controlName = tokens[1];
        let aktivniRadekIdx = this.data.vypocty.indexOf(this.aktivniRadek);
        let value = this.data.vypocty[aktivniRadekIdx - 1][controlName];
        this.aktivniRadek[controlName] = value;
        this.nextOnEnter.onEnter(event);
      }
    }
  }


  /**
   * Pohyb v řádcích gridu nahoru.
  **/
  onArrowUp(): void {
    var i = this.data.vypocty.indexOf(this.aktivniRadek);

    if (i > 0) {
      this.aktivniRadek = this.data.vypocty[i - 1];
      this.setTypVypoctu(this.aktivniRadek.typVypoctuGuid);
      this.focusRadekOnFirst();
    }
  }

  /**
   * Pohyb v řádcích gridu dolů.
  **/
  onArrowDown(): void {
    var i = 1 + this.data.vypocty.indexOf(this.aktivniRadek);

    if (i < this.data.vypocty.length) {
      this.aktivniRadek = this.data.vypocty[i];
      this.setTypVypoctu(this.aktivniRadek.typVypoctuGuid);
      this.focusRadekOnFirst();
    }
  }

  setTypVypoctu(guid: string): void {
    this.enDelka = this.enPrumer = this.enTrida = this.enOddenkyKs = this.enCelkemKs = this.enCisloHrane = this.enDelkaHrane = this.enVyskaHrane = this.enSirkaHrane = this.enKoeficient = this.enMnozstviRucne = false;

    if (guid) {
      var opts: [] = this.fieldsMap[guid];
      opts.forEach((key) => { this["en" + key] = true; }, this);
    }
  }

  save(): void {
    this.block = true;
    this.vyrobaService.saveCd(this.data).subscribe(res => {
      this.messageUtils.showResponseMessage(this.MESSAGE_KEY, res);
      this.block = false;
    });
  }

  cancel(): void {
    this.router.navigate(['vyroba', 'cd'], { queryParams: { "dokladGuid": this.data.guid } });
  }

  recalc(): void {
    if (this.recalcSubscription != null) {
      this.recalcSubscription.unsubscribe();
    }

    if (this.aktivniRadek.typVypoctuGuid == KubirovaniTypGuids.hrane && this.aktivniRadek.mnozstviRucne != undefined && this.aktivniRadek.mnozstviRucne > 0) {
      this.aktivniRadek.mnozstvi = this.aktivniRadek.mnozstviRucne;
    }
    else {
      if (this.aktivniRadek.typVypoctuGuid != undefined && this.kontrola[this.aktivniRadek.typVypoctuGuid](this.aktivniRadek)) {
        this.ciselnikyService.getCislenik("CVyrDrevina", null).subscribe(cVyrDrevina => {
          this.ciselnikyService.getCislenik("CVyrTypVypoctu", null).subscribe(cVyrTypVypoctu => {

            this.aktivniRadek.drevina = cVyrDrevina.find(x => x.guid == this.aktivniRadek.drevinaGuid).kod;
            var typKod = cVyrTypVypoctu.find(x => x.guid == this.aktivniRadek.typVypoctuGuid).kod;

            this.recalcSubscription = this.vyrobaService.kubiruj(typKod, this.aktivniRadek).subscribe(res => {
              this.aktivniRadek.mnozstvi = res.data;
              this.recalcStat();
            });
          });
        });
      }
    }
  }

  //přepočítá tabulku statistiky
  //občas chodí místo number string. Proto se zde převádí na number.
  recalcStat(): void {
    if (this.data.vypocty != void 0) {
      var result = [];
      var vypocty = this.data.vypocty.sort(function (a, b) {
        return a?.drevinaGuid?.localeCompare(b?.drevinaGuid);
      });
      var uniqueDrevinGuids = [... new Set(vypocty.map(x => x.drevinaGuid))];
      var that = this;

      uniqueDrevinGuids.forEach(function (drevinaGuid) {
        var vypoctyDrevinaList = vypocty.filter(x => x.drevinaGuid == drevinaGuid);
        var sortimentGuids = [... new Set(vypoctyDrevinaList.map(x => x.sortimentGuid))];
        sortimentGuids.forEach(function (sortimentGuid) {
          var vypocetStortimentyList = vypoctyDrevinaList.filter(x => x.sortimentGuid == sortimentGuid);
          var newStatistika = new KubirovaniStatistikaListDto();
          newStatistika.sortimentGuid = sortimentGuid;
          newStatistika.drevinaGuid = drevinaGuid;
          newStatistika.celkemKs = vypocetStortimentyList.reduce((a, b) => a + (isNaN(b.celkemKs) ? 0 : Number(b.celkemKs)), 0);
          newStatistika.oddenkyKs = vypocetStortimentyList.reduce((a, b) => a + (isNaN(b.oddenkyKs) ? 0 : Number(b.oddenkyKs)), 0);
          newStatistika.mnozstvi = vypocetStortimentyList.reduce((a, b) => a + (isNaN(b.mnozstvi) ? 0 : Number(b.mnozstvi)), 0);
          newStatistika.prHmCelk = newStatistika.mnozstvi / Number(newStatistika.celkemKs);
          newStatistika.prHmOdd = newStatistika.mnozstvi / Number(newStatistika.oddenkyKs);

          result.push(newStatistika);
        });
      })

      //suma podle dřevin.
      var sumStatList: KubirovaniStatistikaListDto[] = [];
      uniqueDrevinGuids.forEach(function (drevinaGuid) {
        var drevinaData = result.filter(x => x.drevinaGuid == drevinaGuid);
        var newPartSumStatistika = new KubirovaniStatistikaListDto();
        newPartSumStatistika.drevinaGuid = drevinaGuid;
        newPartSumStatistika = that._recalcSumStat(drevinaData, newPartSumStatistika);
        let lastIndex = result.map(x => x.drevinaGuid).lastIndexOf(drevinaGuid);
        result.splice(lastIndex + 1, 0, newPartSumStatistika);
        sumStatList.push(newPartSumStatistika);
      });
      //celková suma
      var sumStatistika = new KubirovaniStatistikaListDto();
      sumStatistika.drevinaPopis = "-";
      result.push(this._recalcSumStat(sumStatList, sumStatistika));
      this.statistika = result;
    }
  }

  //naplní sumu dto statistiky
  private _recalcSumStat(statistikyList: KubirovaniStatistikaListDto[], statistika: KubirovaniStatistikaListDto): KubirovaniStatistikaListDto {
    statistika.sortimentPopis = "∑";
    statistika.celkemKs = statistikyList.reduce((a, b) => a + (isNaN(b.celkemKs) ? 0 : b.celkemKs), 0);
    statistika.oddenkyKs = statistikyList.reduce((a, b) => a + (isNaN(b.oddenkyKs) ? 0 : b.oddenkyKs), 0);
    statistika.mnozstvi = statistikyList.reduce((a, b) => a + (isNaN(b.mnozstvi) ? 0 : b.mnozstvi), 0);
    statistika.prHmCelk = statistika.mnozstvi / statistika.celkemKs;
    statistika.prHmOdd = statistika.mnozstvi / statistika.oddenkyKs;

    return statistika;
  }


  /**
   * Handler změny výkonu. Zkontroluje, zda na řádcích jsou kódy podvýkonů z nového výkonu nebo podvýkon vynuluje.
   * @param event
   */
  vykonChangeHandler(event): void {
    if (this.data != void 0 && this.data.vypocty != void 0) {
      this.ciselnikyService.getCislenik('CPodvykon', null).subscribe(resp => {
        let showWarn: boolean = false;
        let aktivniPodvykon;

        this.data.vypocty.filter(x => x.podvykonGuid != void 0).forEach((vypocet, index) => {
          let rowPodvykon = resp.find(x => x.guid == vypocet.podvykonGuid);
          let podvykonyVykonu = resp.filter(p => (p['parentGuid'] == event));
          let idx = podvykonyVykonu.map(p => p.kod).indexOf(rowPodvykon.kod);

          if (idx == -1) {
            vypocet.podvykonGuid = null;
            showWarn = true;
          }
          else if (vypocet.podvykonGuid != podvykonyVykonu[idx].guid) {
            vypocet.podvykonGuid = podvykonyVykonu[idx].guid;
            showWarn = true;
          }

          if (this.aktivniRadek == vypocet) {
            aktivniPodvykon = vypocet.podvykonGuid;
          }
        });

        // zde je nutný 'strict' operator. Rozlišuje se zde undefined a null;
        if (aktivniPodvykon !== undefined) {
          //timeout je zde nutný kvůli handlerům dropdownu a nastavení value.
          //Když zde není, tak se hodnota nastaví dříve než handlery zareagují a nastavená hodnota se posléze vymaže.
          setTimeout(() => {
            this.aktivniRadek.podvykonGuid = aktivniPodvykon;
          });
        }

        if (showWarn) {
          this.messageService.add({
            key: this.MESSAGE_KEY, summary: 'Upozornění', severity: 'warn',
            detail: 'Po změně výkonu zkontrolujte a opravte podvýkony na jednotlivých řádcích.',
            life: MessagesUtils.TOAST_LIFE
          })
        }
      });
    }
  }

  /**
  * Focus na první pole detailu řádku
  * */
  focusRadekOnFirst(): void {
    setTimeout(() => { if (this.dodavatelDropDown != void 0) this.dodavatelDropDown.focus() });
  }

  /**
   * Uložení a dokončení dokladu
  **/
  saveAndFinish(): void {
    this.block = true;
    if (this.data != void 0)
      this.vyrobaService.saveAndFinish(this.data,"cd").subscribe(res => {
        if (!res.success && res.data?.length > 0) {
          this.kontrolaTree.resolveResult(res);
        } else {
          this.messageUtils.showResponseMessage(this.MESSAGE_KEY, res);
          if (res.success) {
            this.router.navigate(['vyroba', 'cd', "detail", this.data.guid]);
          }
        }

        this.block = false;
      });
  }
}
