import { Component, Input } from '@angular/core';
import GeometryType from 'ol/geom/GeometryType';
import { faObjectGroup } from '@fortawesome/free-solid-svg-icons';
import { NacrtMapItemDto } from '../../../Dto/Nacrty/NacrtMapItemDto';
import { MultiPolygon, Polygon } from 'ol/geom';
import { ConstructMapLayerUtils } from '../../../Utils/Mapa/construnct-map-layer.utils';
import { Feature } from 'ol';
import { MapInteractionService } from '../../../Services/Mapa/map-interaction.service';
import { RoleUtils } from '../../../Utils/Shared/role.utils';
import { RoleEnum } from '../../../Dto/Core/RoleEnum';

/**
 * Komponenta sloužící ke slučování polygonů a multipolygonů do jednoho multipolygonu.
**/
@Component({
  selector: 'app-multipolygon-join-into',
  templateUrl: './multipolygon-join-into.component.html',
  styleUrls: ['./multipolygon-join-into.component.css']
})
export class MultipolygonJoinIntoComponent {

  /**
   * Seznam vybraných náčrtů.
  **/
  @Input() set items(value: NacrtMapItemDto[]) {
    if (value != void 0) {
      this._joinableItems = value.filter(item => item.geometryType == GeometryType.POLYGON || item.geometryType == GeometryType.MULTI_POLYGON);
      this.disabled = this._joinableItems.length <= 1;
    }
  }

  constructor(
    private constructUtils: ConstructMapLayerUtils,
    private mapInteractionService: MapInteractionService,
    private roleUtils: RoleUtils)
  {
    // deaktivace tlačítka sloučení náčrtů podle stavu kreslení
    this.mapInteractionService.DeactivateButtons.subscribe(deactivate => this.disabled = deactivate);
  }

  faObjectGroup = faObjectGroup;
  //příznak aktivního/neaktivního tlačítka slučování náčrtů
  disabled: boolean = true;
  //kolekce náčrtů, které lze slučovat
  private _joinableItems: NacrtMapItemDto[] = [];


  /**
   * Sloučení vybraných polygonů a multipolygonů.
  **/
  join(): void {
    if (this.canEdit) {
      let features = this.constructUtils.convertFromSource(this._joinableItems);
      let geometries: any[] = features.map(f => f.getGeometry());

      //s polygony by se již nikde menělo pracovat, ale tady to ničemu nevadí...
      let polygons = geometries.filter(geom => geom instanceof Polygon);
      let multipolygons = geometries.filter(geom => geom instanceof MultiPolygon);

      //zploštění pole polí Polygonů z Multipolygonu a jejich připojení k "simple" polygonům
      let polygonsAll: Polygon[] = polygons.concat(...multipolygons.map((m: MultiPolygon) => m.getPolygons()));
      let feature = new Feature(new MultiPolygon(polygonsAll));
      this.mapInteractionService.joinToMultipolygon(feature, this._joinableItems[0]);
    }
  }


  /**
   * Vrací true, pokud má přihlášený uživatel právo editace náčrtů.
  **/
  get canEdit(): boolean {
    return this.roleUtils.checkRole([RoleEnum.NacrtyEditor, RoleEnum.NacrtyAdmin]);
  }
}
