import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavUtils {

  public readonly NAV: string = 'nav';

  /**
   * uloží nastavení navigace
   * @param value
   */
  public save(value: string): void {
    localStorage.setItem(this.NAV, value);
  }

  /**
   * vrátí tastaven navigace
   */
  public get display(): boolean {
    let nav = localStorage.getItem(this.NAV);
    return (nav == void 0 || nav == "1");
  }

}
