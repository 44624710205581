<p-toast position="top-center"></p-toast>
<div class="content">
  <p-card [style]="{width: '360px', margin: 'auto'}">
    <h3>Registrace uživatele</h3>
    <form [formGroup]="registerForm" (ngSubmit)="register()">
      <p>
        <label for="userName">Uživatelské jméno</label><br />
        <input pInputText id="userName" name="userName" type="text" formControlName="userName" />
      </p>
      <p *ngIf="userName.invalid && (userName.dirty || userName.touched) || submitted && userName.errors" class="cross-validation-error-message alert alert-danger">
        Vyplňte prosím uživatelské jméno.
      </p>
      <p>
        <label for="password">Heslo</label><br />
        <input pInputText id="password" name="password" type="password" formControlName="password" />
      </p>
      <p *ngIf="password.invalid && (password.dirty || password.touched) || submitted && password.errors" class="cross-validation-error-message alert alert-danger">
        Vyplňte prosím heslo.
      </p>
      <p>
        <label for="firstName">Křestní jméno</label><br />
        <input pInputText id="firstName" name="firstName" type="text" formControlName="firstName" />
      </p>
      <p *ngIf="firstName.invalid && (firstName.dirty || firstName.touched) || submitted && firstName.errors" class="cross-validation-error-message alert alert-danger">
        Vyplňte prosím křestní jméno.
      </p>
      <p>
        <label for="lastName">Příjmení</label><br />
        <input pInputText id="lastName" name="lastName" type="text" formControlName="lastName" />
      </p>
      <p *ngIf="lastName.invalid && (lastName.dirty || lastName.touched) || submitted && lastName.errors" class="cross-validation-error-message alert alert-danger">
        Vyplňte prosím příjmení.
      </p>
      <p>
        <label for="email">Email</label><br />
        <input pInputText id="email" name="email" type="text" formControlName="email" />
      </p>
      <p *ngIf="email.invalid && (email.dirty || email.touched) || submitted && email.errors" class="cross-validation-error-message alert alert-danger">
        Vyplňte prosím email.
      </p>
      <p class="center">
        <button pButton type="submit" label="Odeslat" [ngClass]="'icon-btn'"><fa-icon [icon]="faCheck"></fa-icon></button>
      </p>
    </form>
  </p-card>
</div>
<app-block-ui [block]="loading"></app-block-ui>
