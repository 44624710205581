import { Component, Input } from '@angular/core';
import { CiselnikyService } from 'src/app/Services/Shared/ciselniky.service';
import { PodCiselnikListDto } from 'src/app/Dto/Shared/PodCiselnikListDto';

/**
 * Zobrazení organizační úrovně podle GUIDu
 */
@Component({
  selector: 'app-org-ur-display',
  templateUrl: './org-ur-display.component.html',
  styleUrls: ['./org-ur-display.component.css']
})
export class OrgUrDisplayComponent {

  private _guid: string;
  private _orgUrList: PodCiselnikListDto[];
  private _mode: string = "base";

  /**
  * Volba způsobu načítání obsahu.
  * - 'admin' - pro role SpuperAdmin a LhpAdmin v editaci LHC
  * - 'base' - pro doklady VLS, org.ur v projektech náčrtů
  **/
  @Input() set mode(value: string) {
    this._mode = value;
    this._orgUrList = undefined;
    this._update();
  }

  private _zakaznikGuid: string;
  /**
   * Načte obsah podle zákazníka
   **/
  @Input() set zakaznikGuid(val: string) {
    this._zakaznikGuid = val;
    this._update();
  }


  @Input() set guid(val: string) {
    if (val != this._guid) {
      this._guid = val;
      this._update();
    }
    else {
      this.loading = false;
    }
  }

  /**
   * Varianta zobrazení podle dostupného prostoru:
   * - 'short' - jen kody oddělené lomítkem
   * - 'medium' (výchozí) - kody oddělené lomítkem + název nejnižší org. úrovně
   * - 'full' - kody oddělené lomítkem + názvy všech úrovní oddělené lomítkem
   */
  @Input() variant: string = "medium";

  // kódy všech úrovní oddělené lomítkem
  orgUr: string;
  // název nejnižší org. úrovně
  orgUrText: string;
  // úplný název org ur - všechny úrovně
  orgUrAllText: string;

  /**
   * Oddělovač úrovní
   */
  separator = "/";

  loading = true;

  constructor(private ciselnikyService: CiselnikyService) { }

  private _update(): void {
    if (this._guid != void 0) {
      this.orgUr = this.orgUrText = this.orgUrAllText = undefined;
      if (this._orgUrList == void 0) {
        if (this._mode == "admin" && this._zakaznikGuid != void 0) {
          this.ciselnikyService.getOrgUr(this._zakaznikGuid).subscribe(res => {
            this._orgUrList = (res as PodCiselnikListDto[]);
            this._fill(this._guid);
            this.loading = false;
          });
          return;
        }

        if (this._mode == "base")
          this.ciselnikyService.getCislenik("COrgUr", null).subscribe(cOrgUr => {
            this._orgUrList = (cOrgUr as PodCiselnikListDto[]);
            this._fill(this._guid);
            this.loading = false;
          });
      }
      else {
        this._fill(this._guid);
      }
    }
  };

  /**
   * sestaví řetězec pro vstupní pole
   */
  private _fill(guid: string) {
   var current = this._orgUrList.find(x => x.guid == guid);

    if (current) {
      this.orgUr = this.orgUr ? current.kod + this.separator + this.orgUr : current.kod;
      this.orgUrAllText = this.orgUrAllText ? current.popis + " " + this.separator + " " + this.orgUrAllText : current.popis;

      if (this.orgUrText == undefined) {
        this.orgUrText = current.popis;
      }

      if (current.parentGuid) {
        this._fill(current.parentGuid);
      }
    }
  }

}
