import { Injectable } from '@angular/core';

/**
 * Utils pro práci s heslem
*/
@Injectable({
  providedIn: 'root'
})
export class PasswordUtils {

  constructor() {
    
  }

  private _allowedChars = {
    uppers: "QWERTYUPASDFGHJKLZXCVBNM",
    lowers: "qwertyuiopasdfghjkzxcvbnm",
    numbers: "23456789",
    symbols: "+-*/?:"
  };

  /**
   * Vygeneruje náhodné heslo zadané délky
   * @param length
   */
  getPassword(length: number): string {
    let password: string = "";
    password += this._getRandomCharFromString(this._allowedChars.uppers); //pwd will have at least one upper
    password += this._getRandomCharFromString(this._allowedChars.lowers); //pwd will have at least one lower
    password += this._getRandomCharFromString(this._allowedChars.numbers); //pwd will have at least one number
    password += this._getRandomCharFromString(this._allowedChars.symbols);//pwd will have at least one symbolo
    for (let i = password.length; i < length; i++)
      password += this._getRandomCharFromString(Object.values(this._allowedChars).join('')); //fill the rest of the pwd with random characters
    return this._shufflePassword(password);
  }

  /**
   * Vrátí náhodný znak stringu
   * @param str
   */
  private _getRandomCharFromString(str: string): string {
    return str.charAt(Math.floor(Math.random() * str.length));
  }

  /**
   * Namíchá pozice vygenerovaných znaků
   * @param password
   */
  private _shufflePassword(password: string): string {
    var chars = password.split("");
    for (let i = chars.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [chars[i], chars[j]] = [chars[j], chars[i]];
    }
    var result = chars.join("");
    if (result != password) return result;
    else this._shufflePassword(password);
  }
}
