<div class="kniha-detail-form kniha-detail-top-field">
  <div class="p-grid p-align-stretch">
    <div class="p-col">
      <app-label-input-display [value]="psk?.psk" label="PSK" tooltip="Porostní skupina" [formatConent]="false"></app-label-input-display>
    </div>
     <div class="p-col">
      <app-label-input-display [value]="psk?.chs" label="CHS" tooltip="Cílový hospodářský soubor" [numDecPlaces]="0"></app-label-input-display>
    </div>
    <div class="p-col">
      <app-ciselnik-field key="CSlt" [guid]="psk?.sltGuid" label="SLT" variant="short" tooltip="Soubor lesních typů"></app-ciselnik-field>
    </div>
    <div class="p-col">
      <app-ciselnik-field key="CLt" [guid]="psk?.ltGuid" label="LT" variant="short" tooltip="Lesní typ"></app-ciselnik-field>
    </div>
    <div class="p-col">
      <app-ciselnik-field key="CTerTyp" [guid]="psk?.terTypGuid" label="Ter. typ" variant="short" tooltip="Terénní typ"></app-ciselnik-field>
    </div>
    <div class="p-col">
      <app-ciselnik-field key="CLvs" [guid]="psk?.lvsGuid" label="LVS" variant="short" tooltip="Lesní vegetační stupeň"></app-ciselnik-field>
    </div>
    <div class="p-col">
      <app-label-input-display [value]="psk?.pribVzd" label="Přibl. vzd." tooltip="Přibližovací vzdálenost" [numDecPlaces]="0"></app-label-input-display>
    </div>
    <div class="p-col">
      <app-ciselnik-field key="CHospZp" [guid]="psk?.hospZpGuid" label="Hosp. zp." variant="short" tooltip="Hospodářský způsob"></app-ciselnik-field>
    </div>
    <div class="p-col">
      <app-label-input-display label="Daň ne" tooltip="Osvobození od daně z nemovitosti"></app-label-input-display> <!--TODO - doplnit obsah-->
    </div>

    <div class="p-col-12">
      Poznámka
      <div class="p-inputtext ui-corner-all two-row overflow-scroll">{{psk?.pskText}}</div>
    </div>
  </div>
</div>
<app-kniha-rozdeleni-lesa [rozdeleniLesa]="psk"></app-kniha-rozdeleni-lesa>
