<p-dialog [(visible)]="displaySql" [style]="{width: '40vw', height: '40vw'}" [modal]="true">
  <ng-template pTemplate="header">
    <span class="p-dialog-title">Nový SQL dotaz</span>
  </ng-template>
  <div class="p-grid validated-form-container" [formGroup]="form">
    <div class="p-col-6">
      Název
      <input type="text" pInputText formControlName="nazev">
    </div>
    <div class="p-col-6">
      Kategorie
      <app-dotaz-kategorie-input formControlName="kategorie" [nazevDotazu]="form.get('nazev')?.value"></app-dotaz-kategorie-input>
    </div>
    <div class="p-col-12 data">
      <textarea pInputTextarea formControlName="sqlData" ></textarea>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <button pButton label="Uložit" class="icon-btn p-button-success" (click)="save()" [disabled]="!form.valid"><fa-icon [icon]="faSave"></fa-icon></button>
    <button pButton (click)="close()" label="Zavřít" class="icon-btn p-button-secondary p-button-outlined"><fa-icon [icon]="faTimes"></fa-icon></button>
  </ng-template>
</p-dialog>

