import { Injectable } from '@angular/core';
import Layer from 'ol/layer/Layer';
import VectorLayer from 'ol/layer/Vector';
import TileLayer from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS';
import VectorSource from 'ol/source/Vector';
import WKT from 'ol/format/WKT';
import WMTS from 'ol/source/WMTS';
import WMTSCapabilities from 'ol/format/WMTSCapabilities';
import Feature from 'ol/Feature';
import { Fill, Style, Icon, Circle, Stroke } from 'ol/style';
import ImageStyle from 'ol/style/Image';
import Text from 'ol/style/Text';
import { Geometry, LineString, MultiLineString, MultiPoint, MultiPolygon, Point, Polygon } from 'ol/geom';
import { LayerDefinitionDto } from '../../Dto/Mapa/LayerDefinitionDto';
import { WmsSourceDto } from '../../Dto/Mapa/WmsSourceDto';
import { VectorSourceDto } from '../../Dto/Mapa/VectorSourceDto';
import { MapItemDto } from '../../Dto/Mapa/MapItemDto';
import { VectorStyleDefinitionDto } from '../../Dto/Mapa/VectorStyleDefinitionDto';
import { ILayerSourceDto } from '../../Dto/Mapa/ILayerSourceDto';
import { LayerSourceType } from '../../Dto/Mapa/LayerSourceType';
import { NacrtyService } from '../../Services/Nacrty/nacrty.service';
import { LayerStyleUtils } from './layer-style.utils';
import { WmtsSourceDto } from '../../Dto/Mapa/WmtsSourceDto';
import { optionsFromCapabilities } from 'ol/source/WMTS';
import { WfsSourceDto } from '../../Dto/Nacrty/WfsSourceDto';
import WFS from 'ol/format/WFS'
import { LocalStorageLayersInteractionService } from '../../Services/Mapa/local-storage-layers-interaction.service';
import * as olLoadingstrategy from 'ol/loadingstrategy';
import { FeatureLoaderInteractionService } from '../../Services/Mapa/feature-loader-interaction.service';

/**
 * Zkonstruuje mapové vrstvy.
**/
@Injectable({
  providedIn: 'root'
})
export class ConstructMapLayerUtils {

  /**
   * Název vrstvy pro zobrazeni lomových bodů.
  **/
  private _verticiesLayerName: string = '';

  constructor(
    private nacrtyService: NacrtyService,
    private layerStyleUtils: LayerStyleUtils,
    private localStorageLayersInteractionService: LocalStorageLayersInteractionService,
    private featureLoaderInteractionService: FeatureLoaderInteractionService)
  { }


  /**
   * Vytvoří vektorovou vrstvu pro zobrazování vybraných mapových objektů.
   * @param name {string} název vrstvy
   */
  public async constructLayerForSelections(name: string): Promise<Layer<any, any>> {
    let sourceDto: VectorSourceDto = {
      type: LayerSourceType.vector,
      coordinateSystem: '',
      featureSources: [],
      featureUrl: null,
      schvalProjektGuid: null,
      style: {
        fillFunction: this.layerStyleUtils.getPattern,
        fillRgba: 'rgba(0,0,255,0.5)',
        strokeRgba: 'rgb(0,0,255)',
        strokeWidth: 3,
        circleFillRgba: 'rgba(0,0,255,0.5)',
        circleStrokeRgba: 'rgb(0,0,255)',
        circleStrokeWidth: 3,
        circleRadius: 8,
        iconName: null,
        lineTypeName: null
      },
      allowedGeometries: { bod: true, linie: true, plocha: true },
      editableGeometries: false
    };

    let l =  await this._constructLayer(name, sourceDto);
    l.setZIndex(100000);
    return l;
  }


  /**
   * Vytvoří vektorovou vrstvu pro zobrazování lomových bodů.
   * @param name {string} název vrstvy
   */
  public async constructVerticiesLayer(name: string): Promise<Layer<any, any>> {
    this._verticiesLayerName = name;
    let sourceDto: VectorSourceDto = {
      type: LayerSourceType.vector,
      coordinateSystem: null,
      featureSources: null,
      featureUrl: null,
      schvalProjektGuid: null,
      style: {
        fillFunction: null,
        fillRgba: null,
        strokeRgba: null,
        strokeWidth: null,
        circleFillRgba: 'rgb(0,0,255)',
        circleStrokeRgba: 'rgb(0,0,255)',
        circleStrokeWidth: 1,
        circleRadius: 6,
        iconName: null,
        lineTypeName: null
      },
      allowedGeometries: null,
      editableGeometries: null
    };

    let l = await this._constructLayer(name, sourceDto);
    l.setZIndex(100005);
    return l;
  }


  /**
   * Vytvoření vrstvy pro kreslení.
   * @param name {string} název vrstvy
   */
  public async constructLayerForDraw(name: string): Promise<Layer<any, any>> {
    let sourceDto: VectorSourceDto = {
      type: LayerSourceType.vector,
      coordinateSystem: '',
      featureSources: [],
      featureUrl: null,
      schvalProjektGuid: null,
      style: {
        fillFunction: null,
        fillRgba: 'rgba(0,0,255,0.5)',
        strokeRgba: 'rgb(0,0,255)',
        strokeWidth: 3,
        circleFillRgba: 'rgba(0,0,255,0.5)',
        circleStrokeRgba: 'rgb(0,0,255)',
        circleStrokeWidth: 3,
        circleRadius: 8,
        iconName: null,
        lineTypeName: null
      },
      allowedGeometries: { bod: true, linie: true, plocha: true },
      editableGeometries: true
    };

    let l = await this._constructLayer(name, sourceDto);
    l.setZIndex(100010);
    return l;
  }


  /**
   * Konstrukce vrstvy pro zobrazování délek úseček.
   * @param name {string} název vrstvy
   */
  public constructLayerForDimensions(name: string): Layer<any, any> {
    let layer = new VectorLayer({
      source: new VectorSource(),
      style: (feature, resolution) => { return this.layerStyleUtils.dimLineStyleFunction(feature); }
    }
    );

    layer.setProperties({ name: name, zIndex: 1000000 });
    return layer;
  }


  /**
   * Vytvoření vrstvy pro přichytávání.
   * @param name {string} název vrstvy
   */
  public async constructLayerForSnap(name: string): Promise<Layer<any, any>> {
    let sourceDto: VectorSourceDto = {
      type: LayerSourceType.vector,
      coordinateSystem: '',
      featureSources: [],
      featureUrl: null,
      schvalProjektGuid: null,
      style: {
        fillFunction: null,
        fillRgba: 'rgba(0,0,0,0)',
        strokeRgba: 'rgba(0,0,0,0)',
        strokeWidth: 1,
        circleFillRgba: 'rgba(0,0,0,0)',
        circleStrokeRgba: 'rgba(0,0,0,0)',
        circleStrokeWidth: 1,
        circleRadius: 1,
        iconName: null,
        lineTypeName: null
      },
      allowedGeometries: { bod: false, linie: false, plocha: false },
      editableGeometries: false
    };

    let l = await this._constructLayer(name, sourceDto);
    l.setZIndex(100020);
    return l;
  }


  /**
   * Vytvoří mapové vrstvy dle definice z WebApi.
   * @param sources {LayerDefinitionDto[]} kolekce podkladů pro vytvoření vrstev.
   */
  public async constructLayers(sources: LayerDefinitionDto[]): Promise<Layer<any, any>[]> {
    let layers: Layer<any, any>[] = [];
    for (var i = 0; i < sources.length; i++) {
      let source: LayerDefinitionDto = sources[i];
      let layer: Layer<any, any> = await this._constructLayer(source.id, source.source);

      if (layer != void 0) {
        if (source.minZoom != void 0)
          layer.setMinZoom(source.minZoom);

        layer.setZIndex(source.zIndex);
        layers.push(layer);
      }

      if (source.inversion && source.inversion.source) {
        let iLayer = await this._constructLayer(source.inversion.id, source.inversion.source);
        if (iLayer != void 0) {
          if (source.minZoom != void 0)
            iLayer.setMinZoom(source.minZoom);

          iLayer.setZIndex(source.zIndex);
          layers.push(iLayer);
        }
      }
    }
    return layers;
  }


  /**
   * Vytvoření mapové vrstvy dle definice.
   * @param name {string} název vrstvy
   * @param source {ILayerSourceDto} source vrstvy dle typu
   */
  private async _constructLayer(name: string, source: ILayerSourceDto): Promise<Layer<any, any>> {

    let constructedSource: any = await this._constructSource(source, name);
    let opt: object = {
      name: name,
      projection: source.coordinateSystem,
      source: constructedSource
    };

    let l: Layer<any, any>;
    if (source.type == LayerSourceType.tile || source.type == LayerSourceType.tile_wmts) {

      if ((source as WmsSourceDto).boudingBox != null) {
        opt['extent'] = (source as WmsSourceDto).boudingBox;
      }
      l = new TileLayer(opt);
    }
    else if (source.type == LayerSourceType.vector || source.type == LayerSourceType.wfs) {
      if (name == this._verticiesLayerName) {
        opt['style'] = this._constructVerticiesLayerStyle.bind(this, (source as VectorSourceDto).style);
      }
      else {
        opt['style'] = this._constructVectorStyle.bind(this, (source as VectorSourceDto).style, name);
      }
      l = new VectorLayer(opt);
      l.set('styleDef', (source as VectorSourceDto).style);
    }
    else {
      console.log('Nepodporovaný typ mapové vrstvy.', source.type);
      l = null;
    }

    return l;
  }


  /**
  * Vytvoří zdroj pro OL vrstvu dle typu.
  * @param source {ILayerSourceDto} přenoska s daty pro vytvoření daného typu zdroje pro OL vrstvu
  * @param name {string} název vrstvy
  */
  private async _constructSource(source: ILayerSourceDto, name: string): Promise<any> {
    // S těmito možnými dvěma typy zdroje se počítá v MapComponent (metod _addLayers),
    // v případě rozšíření o další typ zdroje je tedy potřeba pohlídat i MapComponent._addLayers()
    if (source.type == LayerSourceType.tile) {
      return this._constructTileWms(source as WmsSourceDto);
    }
    else if (source.type == LayerSourceType.tile_wmts) {
      return await this._constructTileWmts(source as WmtsSourceDto);
    }
    else if (source.type == LayerSourceType.vector) {
      return this._constructVectorSource(source as VectorSourceDto, name);
    }
    else if (source.type == LayerSourceType.wfs) {
      return this._constructWfsSource(source as WfsSourceDto, name);
    }
    else {
      console.error('Zdroj pro ol vrstvu je nepodporovaného typu.', source);
      return null;
    }
  }


  /**
   * Vytvoření zdroje TileWMS
   * @param source {WmsSourceDto}
   */
  private _constructTileWms(source: WmsSourceDto): TileWMS {
    return new TileWMS({
      url: source.url,
      params: {
        LAYERS: source.layers.join(','),
        FORMAT: source.format,
        TRANSPARENT: source.transparent
      }
    });
  }

  /**
   * Vytvoření zdroje WMTS
   * @param source
   */
  private async _constructTileWmts(source: WmtsSourceDto) {
    var parser = new WMTSCapabilities();
    var xml = await fetch(source.url);
    var xml_text = await xml.text();
    var result = parser.read(xml_text);
    var options = optionsFromCapabilities(result, {
      layer: source.layers[0],
      matrixSet: source.matrixSet,
      format: source.format
    });
    return new WMTS(options);
  }


  /**
   * Vytvoření zdroje vektorových vrstev
   * @param source {VectorSourceDto} data pro vytvoření zdroje vektorové vrstvy
   * @param layerId {string} id vytvářené vrstvy
   */
  private _constructVectorSource(source: VectorSourceDto, layerId: string): VectorSource<any> {
    let s = new VectorSource<any>();

    if (source.featureSources != void 0 && source.featureSources.length > 0) {
      s.addFeatures(this.convertFromSource(source.featureSources));
    }

    if (source.featureUrl != void 0 && source.featureUrl != '') {
      let that = this;
      s.setLoader(
        function () {
          let visibleIdx = that.localStorageLayersInteractionService.getVisibleList().findIndex(x => x == layerId)
          if (visibleIdx != -1) {
            let url = source.featureUrl + '/' + layerId;
            that.nacrtyService.getLayerFeatures(url).subscribe(resp => {
              if (resp.success) {
                var features = that.convertFromSource(resp.data);
                s.addFeatures(features);
              }
              else {
                console.error(resp.messages[0], 'layerId: ' + layerId);
              }
            });
          }
        }
      );
    }

    return s;
  }


  /**
   * Vytvoření zdroje vektorových vrstev založených na WFS
   * @param source {WfsSourceDto} data pro vytvoření zdroje vektorové vrstvy
   * @param name {string} název vrstvy
   */
  private _constructWfsSource(source: WfsSourceDto, name: string): VectorSource<any> {
    var that = this;
    var vectorSource = new VectorSource<any>({
      format: new WFS(),
      strategy: olLoadingstrategy.bbox,
      loader: function (extent, resolution, projection, success, failure) {
        var proj = projection.getCode();
        var url = source.url + '&bbox=' + extent.join(',') + ',' + proj;
        var xhr = new XMLHttpRequest();
        xhr.open('GET', url);
        var onError = function () {
          vectorSource.removeLoadedExtent(extent);
          that.featureLoaderInteractionService.featuresLoaded(name);
        }
        xhr.onerror = onError;
        xhr.onload = function () {
          if (xhr.status == 200) {
            var features = vectorSource.getFormat().readFeatures(xhr.responseText);
            vectorSource.addFeatures(features as Feature<Geometry>[]);
            that.featureLoaderInteractionService.featuresLoaded(name);
          } else {
            onError();
          }
        }
        xhr.send();
      },
    });

    return vectorSource;
  }


  /**
   * Sestavení stylu vektorové vrstvy.
   * @param styleSource {VectorStyleDefinitionDto} definice stylu vrstvy
   * @param name {string} název vrstvy
   * @param feature {Feautre<any>} mapový objekt
   * @param resolution {number} rozlišení
   */
  private _constructVectorStyle(styleSource: VectorStyleDefinitionDto, name: string, feature: Feature<any>, resolution: number): Style {
    let imageStyle: ImageStyle = this.layerStyleUtils.getPointImageStyle(styleSource, resolution, name);
    let textStyle: Text;
    if (!(feature.getGeometry() instanceof Point && imageStyle instanceof Icon))
    { //TODO: popisek by mohly mít i ikony, ale tam je potřetřeba spočítat offset dle velikosti ikony (ikony se budou možná ještě měnit...)
      textStyle = this.layerStyleUtils.getTextStyle(styleSource, feature, resolution);
    }

    let style = new Style({
      fill: new Fill({
        color: styleSource.fillFunction instanceof Function ? styleSource.fillFunction.call() : styleSource.fillRgba
      }),
      stroke: this.layerStyleUtils.getStoke(styleSource),
      image: imageStyle,
      text: textStyle
    });

    if (textStyle != void 0) {
      style.setText(textStyle);
    }
    return style; 
  }


  /**
   * Sestavení stylu vrstvy s lomovými body.
   * @param styleSource {VectorStyleDefinitionDto}
   * @param feature {Feautre<any>}
   * @param resolution {number}
   */
  private _constructVerticiesLayerStyle(styleSource: VectorStyleDefinitionDto, feature: Feature<any>, resolution: number): Style {
    return new Style({
      image: new Circle({
        fill: new Fill({
          color: styleSource.circleFillRgba
        }),
        radius: styleSource.circleRadius
      }),
      geometry: (f: Feature<any>) => {
        let geom: Geometry = f.getGeometry();
        let coords: number[][] = [];
        if (geom instanceof MultiPolygon) {
          geom.getPolygons().forEach(p => {
            let c = p.getCoordinates();
            c.forEach(x => coords = coords.concat(x))
          });
        }
        else if (geom instanceof Polygon) {
          let c = geom.getCoordinates();
          c.forEach(x => coords = coords.concat(x));
        }
        else if (geom instanceof MultiLineString) {
          geom.getLineStrings().forEach(l => {
            coords = coords.concat(l.getCoordinates());
          });
        }
        else if (geom instanceof LineString) {
          coords = coords.concat(geom.getCoordinates());
        }
        let multiPoint: MultiPoint = new MultiPoint(coords);
        return multiPoint;
      }
    });
  }


  /**
   * Konstrukce stylu pro body ve výběrové vrstvě.
   * @param styleSource {VectorStyleDefinitionDto} definice stylu
   * @param feature {Feature<any>} mapový objek, který se bude stylovat
   * @param resolution {number} rozlišení mapy
   */
  private _constructSelectablePointStyle(styleSource: VectorStyleDefinitionDto, feature: Feature<any>, resolution: number): Style {
    return new Style({
      image: new Circle({
        fill: new Fill({
          color: 'rgba(0,0,255,0.5)'
        }),
        stroke: new Stroke({
          color: 'rgb(0,0,255)',
          width: styleSource.circleStrokeWidth
        }),
        radius: resolution > 1 ? (styleSource.circleRadius / resolution) : styleSource.circleRadius
      })
    });
  }


  /**
   * Konstrukce/konverze vektorových objektů ze zdrojových dat.
   * @param featureData {MapItemDto}
   */
  public convertFromSource(featureData: MapItemDto[]): Feature<any>[] {
    let format = new WKT();
    let features = [];

    featureData.forEach(s => {
      let f = format.readFeature(s.wkt);
      f.setId(s.id);
      f.set("popis", s["popis"]);
      f.set("modul", s.modul);

      let styleDef = s['styleDef'];
      if (f.getGeometry() instanceof Point && styleDef != void 0 && styleDef['iconName'] == void 0)
      {
        f.setStyle(this._constructSelectablePointStyle.bind(this, styleDef));
      }

      features.push(f);
    });

    return features;
  }
}
