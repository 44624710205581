import { Component, forwardRef, HostBinding, HostListener, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

/**
 * Komponenta checkboxu s nastavením label z vnějšku a ovládáním přes klávesy 1, 0 a delete
 * */
@Component({
  selector: 'checkbox-s-popisem',
  templateUrl: './checkbox-s-popisem.component.html',
  styleUrls: ['./checkbox-s-popisem.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CheckBoxSPopisemComponent),
    multi: true
  }]
})
export class CheckBoxSPopisemComponent implements ControlValueAccessor {

  constructor() { }

  // Proměnné pro implementaci rozhraní ControlValueAccessor
  onModelChange: any = () => { };
  onModelTouched: any = () => { };
  disabled: boolean = false;

  protected _nullable: boolean = false;
  private _id = '';

  // finta s ID odkoukána zde: https://coryrylan.com/blog/angular-custom-form-controls-with-reactive-forms-and-ngmodel
  @HostBinding('attr.id')
  externalId = '';

  popis: string;
  private readonly EMPTY_POPIS: string = "Ponechat prázdné";


  /**
   * Nastavení popisků ve formátu asociativního pole { "true" : "zaškrtnuto", "false" : "nezašktnuto" }
   */
  @Input() popisky: any;

  /**
   * Nastavení možnosti prázdného obsahu.
   */
  @Input()
  set nullable(value: boolean) {
    if (value != this._nullable) {
      this._nullable = value;
      this._setPopis();
    }
  }

  @Input('value') _value: boolean = null;

  get value() {
    return this._value;
  }

  @Input() edit: boolean = true;

  @Input()
  set id(value: string) {
    this._id = value;
    this.externalId = null;
  }

  get id() {
    return this._id;
  }

  set value(val) {
    if (val != this._value) {
      this._value = val;
      this.onModelChange(val);
      this.onModelTouched();
      this._setPopis();
    }
  }

  // Implementace rozhraní ControlValueAccessor
  writeValue(obj: any): void {
    this.value = obj;
  }

  // Implementace rozhraní ControlValueAccessor
  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  // Implementace rozhraní ControlValueAccessor
  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }

  // Implementace rozhraní ControlValueAccessor
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }


  /**
  * Promaže hodnoty
  * */
  private _cleanValues(): void {
    this.popis = this.EMPTY_POPIS;
    this.value = null;
  }

  /**
   * Zvolí popis k vybrané hodnotě
   */
  private _setPopis(): void {
    if (this.value == void 0 && this._nullable) {
      this._cleanValues();
    } else {
      this.popis = this.popisky[this.value.toString()];
    }
  }


  /**
   * vypíše aktuální položku pro zobrazení v detailu
   **/
  getDetail(): string {
    return this.popisky[this.value.toString()];
  }

  /**
   * Handler odchycení klávesy 1 pro zaškrtnutí a 0 pro zrušení. Pokud je povoleno nullable, tak umožnit nastavená klávesou delete.
   * @param event
   */
  @HostListener('document:keyup', ['$event'])
  keyEvent(event) {
    //Obsah se provede pouze pokud je focus na checkboxu, nebo na label v komponentě
    if (this._id != void 0 && event.path.find(x => x.id == this._id)) {
      if (event.keyCode === KEY_CODE.ONE_NUM_KEY) this.value = true;
      if (event.keyCode === KEY_CODE.ZERO_NUM_KEY) this.value = false;
      if (event.keyCode === KEY_CODE.DELETE_KEY && this._nullable) this._cleanValues();

      this._setPopis();
    }
  }
}

export enum KEY_CODE {
  ONE_NUM_KEY = 97,
  ZERO_NUM_KEY = 96,
  DELETE_KEY = 46
}
