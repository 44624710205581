<app-block-ui [block]="block"></app-block-ui>

<p-panel *ngIf="visible" [style]="{'margin-top': '1em'}">
  <ng-template pTemplate="header">
    <div class="p-grid p-align-center">
      <div class="p-col"><span class="p-panel-title">Inicializace uzávěrky</span></div>
      <div class="p-col right">
        <button pButton type="button" label="Uložit" class="icon-btn p-button-success" (click)="init()" [disabled]="form.invalid"><fa-icon [icon]="faSave"></fa-icon></button>
        <button pButton type="button" label="Zrušit" class="icon-btn" (click)="cancel()"><fa-icon [icon]="faTimes"></fa-icon></button>
      </div>
    </div>
  </ng-template>
  <div class="p-grid validated-form-container" [formGroup]="form">
    <div class="p-col-2">
      Organizační úroveň
      <app-org-ur formControlName="orgUrGuid" id="uzaverky-init-orgUrGuid" variant="full"
                  [allLevelsSelectable]="true"></app-org-ur>
    </div>

    <div class="p-col-2">
      <label for="uzaverky-init-obdobi-od">Období od</label>
      <app-calendar formControlName="obdobiOd" class="required" id="muzaverky-obdobi-od"></app-calendar>
    </div>

    <div class="p-col-2">
      <label for="uzaverky-init-obdobi-do">Období do</label>
      <app-calendar formControlName="obdobiDo" class="required" id="uzaverky-init-obdobi-do"></app-calendar>
    </div>

    <div class="p-col-2">
      <label for="uzaverky-init-hajny">Datum hajný</label>
      <app-calendar formControlName="datumHajny" id="uzaverky-init-hajny"></app-calendar>
    </div>

    <div class="p-col-2">
      <label for="uzaverky-init-polesny">Datum polesný</label>
      <app-calendar formControlName="datumPolesny" id="uzaverky-init-polesny"></app-calendar>
    </div>

    <div class="p-col-2">
      <label for="uzaverky-init-metodik">Datum metodik</label>
      <app-calendar formControlName="datumMetodik" id="uzaverky-init-metodik"></app-calendar>
    </div>
  </div>
</p-panel>
