<p-panel header="Náhled" styleClass="nacrt-mapa pds-panel-compact">
  <ng-template pTemplate="icons">
    <button pButton type="button" class="p-panel-header-icon icon-btn hide-label-button p-mr-2"
            pTooltip="Přiblížit na vybraný náčrt"
            (click)="zoomTo()">
      <fa-icon [icon]="faSearch"></fa-icon>
    </button>
    <button pButton type="button" class="p-panel-header-icon icon-btn hide-label-button"
            pTooltip="Přechod do mapy na vybraný náčrt"
            (click)="goToMapHandler()">
      <fa-icon [icon]="faExternalLinkAlt"></fa-icon>
    </button>
  </ng-template>
  <app-map #nacrtMap id="{{MAP_ID}}" [Layers]="layers"
           [ShowMapControls]="false"
           style="width:100%;height:100%;"></app-map>
</p-panel>
