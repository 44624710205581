import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faCopy, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { MenuItem } from 'primeng/api/menuitem';
import { ResultInfoGenericDto } from 'src/app/Dto/Shared/ResultInfoDto';
import { VyrobaService } from 'src/app/Services/Vyroba/vyroba.service';
import { MessagesUtils } from 'src/app/Utils/Shared/messages.utils';

@Component({
  selector: 'app-vyroba-kopie-dokladu',
  templateUrl: './vyroba-kopie-dokladu.component.html',
  styleUrls: ['./vyroba-kopie-dokladu.component.css']
})
export class VyrobaKopieDokladuComponent implements OnInit {

  menuCopy: MenuItem[];

  constructor(private vyrobaService: VyrobaService, private router: Router, private messageUtils: MessagesUtils) { }

  faCopy = faCopy;
  faAngleDown = faAngleDown;

  @Input() disabled: boolean = false;
  @Input() guid: string;
  @Input() set kod(value: string) {
    this._kod = value.toLowerCase();
    if (this._kod != "ol")
      this.visibleDodavatel = true;
    else
      this.visibleDodavatel = false;
  };

  private _kod: string;
  displayDodavatele: boolean = false;
  dodavatelGuid: string;
  visibleDodavatel: boolean = false;

  ngOnInit(): void {
    this.menuCopy = [
      { label: "Kopie na nového dodavatele" }
    ];
  }

  //zkopíruje doklad s novým uživatelem
  newDodavatelCopy(): void {
    if (this.guid != void 0 && this.dodavatelGuid != void 0)
      switch (this._kod) {
        case "cd":
          this.vyrobaService.copyCdNovyDodavatel(this.guid, this.dodavatelGuid).subscribe(res => {
            this._resolveResult(res);
          });
          break;
        case "vml":
          this.vyrobaService.copyVmlNovyDodavatel(this.guid, this.dodavatelGuid).subscribe(res => {
            this._resolveResult(res);
          });
          break;
      }
  }


  //zkopíruje doklad
  copy(): void {
    if (this.guid != void 0)
      switch (this._kod) {
        case "cd":
          this.vyrobaService.copyCd(this.guid).subscribe(res => {
            this._resolveResult(res);
          });
          break;
        case "ol":
          this.vyrobaService.copyOl(this.guid).subscribe(res => {
            this._resolveResult(res);
          });
          break;
        case "vml":
          this.vyrobaService.copyVml(this.guid).subscribe(res => {
            this._resolveResult(res);
          });
          break;
      }
  }

  //zpracuje odpověd po kopírování
  private _resolveResult(res: ResultInfoGenericDto<string>): void {
    if (res.success) {
      this.router.navigate(['vyroba', this._kod.toLowerCase(), "edit", res.data]);
    } else {
      this.messageUtils.showResponseMessage("copy-doklad-edit-toast", res);
    }
  }
}

