import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { faCheck, faStream, faTimes } from '@fortawesome/free-solid-svg-icons';

/**
 * Komponenta vstupního pole se stromem pro výběr kategorie dotazu
 **/
@Component({
  selector: 'app-dotaz-kategorie-input',
  templateUrl: './dotaz-kategorie-input.component.html',
  styleUrls: ['./dotaz-kategorie-input.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DotazKategorieInputComponent),
    multi: true
  }]
})
export class DotazKategorieInputComponent implements ControlValueAccessor {

  constructor() { }

  faStream = faStream;
  faTimes = faTimes;
  faCheck = faCheck;

  public display: boolean = false;

  private _selectedKategorie: string;

  @Input() nazevDotazu: string = null;


  // proměnné pro implementaci rozhraní ControlValueAccessor
  onModelChange: any = () => { };
  onModelTouched: any = () => { };
  disabled: boolean = false;

  @Input('value') _value: string = null;

  writeValue(obj: any): void {
    this.value = obj;
  }
  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  get value() {
    return this._value;
  }

  set value(val) {
    if (val == void 0 || val == '') val = null; //pro případ, že uživatel smaže vše v inputu
    this._value = val;
    this.onModelChange(val);
    this.onModelTouched();
  }

  /**
   * Handler výběru kategorie 
   * @param kategorie
   */
  public onKategorieChange(kategorie: string): void {
    this._selectedKategorie = kategorie;
  }

  /**
   * Uloží výběr ze stromu do modelu a zavře dialog
   **/
  public save(): void {
    this.value = this._selectedKategorie;
    this.display = false;
  }
}
