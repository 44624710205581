import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { KnihaBase } from 'src/app/Components/Lhp/kniha-base';
import { EtazDetailDto } from 'src/app/Dto/Lhp/EtazDetailDto';
import { KnihaService } from 'src/app/Services/Lhp/kniha.service';

@Component({
  selector: 'app-kniha-etaz',
  templateUrl: './kniha-etaz.component.html',
  styleUrls: ['./kniha-etaz.component.css']
})
export class KnihaEtazComponent extends KnihaBase {

  constructor(
    route: ActivatedRoute,
    knihaService: KnihaService
  ) {
    super(route, knihaService);
  }

  model: EtazDetailDto;

  loadModel() {
    this.knihaService.getEtaz(this.lhcKod, this.odd, this.dil, this.por, this.psk, this.etaz, this.guid)
      .subscribe(response => {
        this.model = response;
        this.etzGuid = this.model.guid;
        this.pskGuid = this.model.lhpGuids.rozdeleniLesaGuid;
        this.porGuid = this.model.lhpGuids.porGuid;
        this.dilGuid = this.model.lhpGuids.dilGuid;
        this.oddGuid = this.model.lhpGuids.oddGuid;
        this.lhcGuid = this.model.lhpGuids.lhcGuid;

        if (isNaN(Number(this.lhcKod))) {
          this.lhcKod = this.model.lhpGuids.lhcKod.toString();
          this.odd = this.model.lhpGuids.odd.toString();
          this.dil = this.model.lhpGuids.dil;
          this.por = this.model.lhpGuids.por;
          this.psk = this.model.lhpGuids.psk;
          this.etaz = this.model.etaz;
        }
        this.constructBreadCrumbs();
      });
  }
}
