import { Component, Input } from '@angular/core';
import { ARozdeleniLesaDetailDto } from 'src/app/Dto/Lhp/ARozdeleniLesaDetailDto';

@Component({
  selector: 'app-kniha-rozdeleni-lesa',
  templateUrl: './kniha-rozdeleni-lesa.component.html'
})
export class KnihaRozdeleniLesaComponent {

  constructor() {
  }

  @Input() rozdeleniLesa: ARozdeleniLesaDetailDto;
}

