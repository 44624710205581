export class VmlProstredekDetailDto {
  guid: string;
  prostredekGuid: string;
  podvykonGuid: string;
  domOrgUrGuid: string;
  hodiny: number;
  mnozstvi: number;
  vykonGuid: string;
  ucelovy: boolean;
}
