import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { IPriloha } from '../../../Dto/Nacrty/IPriloha';
import { NacrtPrilohyChangedArgs } from '../../../Dto/Nacrty/NacrtPrilohyChangedArgs';
import { NacrtyInteractionService } from '../../../Services/Nacrty/nacrty-interaction.service';

/**
 * Komponenta pro přehrávání audio záznamů v přílohách náčrtu.
**/
@Component({
  selector: 'app-nacrt-priloha-audio',
  templateUrl: './nacrt-priloha-audio.component.html',
  styleUrls: ['./nacrt-priloha-audio.component.css']
})
export class NacrtPrilohaAudioComponent implements OnInit, OnDestroy
{
  constructor(
    private nacrtyInteractionService: NacrtyInteractionService)
  { }


  ngOnInit() {
    this._addAudioSubs = this.nacrtyInteractionService.OnAdd.subscribe(this._procesPrilohy.bind(this));
    this._onInitViewSubs = this.nacrtyInteractionService.OnInitView.subscribe(this._procesPrilohy.bind(this));
    this._onDeleteSubs = this.nacrtyInteractionService.OnDelete.subscribe(this._procesPrilohy.bind(this));
  }

  ngOnDestroy() {
    this._addAudioSubs.unsubscribe();
    this._onInitViewSubs.unsubscribe();
    this._onDeleteSubs.unsubscribe();
  }


  private _addAudioSubs: Subscription;
  private _onInitViewSubs: Subscription;
  private _onDeleteSubs: Subscription;


  /**
   * Kolekce zobrazených příloh.
  **/
  audioSources: IPriloha[] = [];


  /**
   * Handler inicializace příloh
   * @param args {NacrtPrilohyChangedArgs} argumenty příloh náčrtu
   */
  private _procesPrilohy(args: NacrtPrilohyChangedArgs): void {
    this.audioSources = args.prilohy.filter(x => x.typ == 'ZVUK' && !x.sync.d)
      .map(x => { return { nazev: x.souborNazev, src: 'data:audio/mp3;base64,' + x.soubor, id: x.guid }; });
  }
}
