<div id="eqv-main">
  <div id="eqv-content">
    <div class="eqv-header-panel">
      <div class="eqv-entities-block">
        <hr class="eqv-entities-hr eqv-hr" />
        <div class="eqv-entities-title">Dostupné objekty</div>
        <div class="eqv-entities-panel-container">
          <!-- EntitiesPanel widget placeholder -->
          <div id="EntitiesPanel" onselectstart="return false"></div>
        </div>
      </div>

      <div class="eqv-central-block">
        <div class="eqv-columns-block">
          <hr class="eqv-columns-hr eqv-hr" />
          <div class="eqv-columns-title">Sloupce</div>
          <div class="eqv-columns-panel-container">
            <!-- ColumnsPanel widget placeholder -->
            <div id="ColumnsPanel"></div>
          </div>
        </div>
        <div class="eqv-conditions-block">
          <hr class="eqv-conditions-hr eqv-hr" />
          <div class="eqv-conditions-title">Podmínky</div>
          <div class="eqv-query-panel-container">
            <!-- QueryPanel widget placeholder -->
            <div id="QueryPanel"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
