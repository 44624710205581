import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResultInfoGenericDto } from '../../Dto/Shared/ResultInfoDto';
import { COrgUrDetailDto } from '../../Dto/Zakaznik/COrgUrDetailDto';

/** Service pro práci s organizačními úrovněmi */
@Injectable({
  providedIn: 'root'
})
export class OrgurService {

  constructor
  (
    private http: HttpClient
  )
  {
  }

  private apiOrgUrSaveOrUpdate: string = 'api/zakaznik/OrgUr/SaveOrUpdate';
  private apiOrgUrGet: string = 'api/zakaznik/OrgUr/Get';

  /**
 * Uloží nebo updatuje organizační úroveň
 * @param data
 */
  saveOrUpdate(data: COrgUrDetailDto): Observable<ResultInfoGenericDto<COrgUrDetailDto>> {
    return this.http.post<ResultInfoGenericDto<COrgUrDetailDto>>(this.apiOrgUrSaveOrUpdate, data, {});
  }

  /**
   * Vrátí detail organizační úrovně podle guidu
   * @param guid
   */
  get(guid: string): Observable<ResultInfoGenericDto<COrgUrDetailDto>> {
    var param = new HttpParams();
    param = param.append('guid', guid);
    return this.http.get<ResultInfoGenericDto<COrgUrDetailDto>>(this.apiOrgUrGet, { params: param });
  }
}
