<form [formGroup]="data">
  <p-panel header="Základní údaje" [style]="{'margin-bottom': '1em'}">
    <div class="p-grid p-align-center">
      <div class="p-col-1">
        <label for="druhVypoctu">
          Druh škody
        </label>
        <p-dropdown id="druhVypoctu" [options]="druhVypoctuList" (onChange)="druhVypoctuChanged($event)" formControlName="typ"
                    [style]="{ 'display': 'block', 'width': '100%', 'min-width': '0' }"></p-dropdown>
      </div>

      <div class="p-col-2">
        <label>
          Držitel
          <p-autoComplete formControlName="drzitel" [suggestions]="navrhovaniDrzitele" (completeMethod)="hledejDrzitele($event)" field="popis"
                          [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}"></p-autoComplete>
        </label>
      </div>

      <div class="p-col-2">
        <label>
          Uživatel
          <p-autoComplete formControlName="uzivatel" [suggestions]="navrhovaniUzivatele" (completeMethod)="hledejUzivatele($event)" field="popis"
                          [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}"></p-autoComplete>
        </label>
      </div>

      <div class="p-col-2">
        <label>
          LHC
          <p-autoComplete formControlName="lhc" [suggestions]="navrhovaneLhc" (completeMethod)="hledejLhc($event)" (onBlur)="lhcBlurHandler($event)"
                          [forceSelection]="true" field="lhcKod"
                          [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}">
            <ng-template let-lhc pTemplate="item">
              {{lhc.lhcKod}} - {{lhc.lhcNaz}}
            </ng-template>
          </p-autoComplete>
        </label>
      </div>

      <div class="p-col-2">
        <label>
          JRPL
          <p-autoComplete formControlName="jprl" [suggestions]="navrhovaneJprl" (completeMethod)="hledejJprl($event)" field="jprl"
                          (onBlur)="jprlBlurHandler($event)" 
                          [style]="{'width':'100%'}"
                          [ngClass]="{'invalid-input-conditional': jprlAlert, 'neutral-input-conditional': !jprlAlert}"
                          [inputStyle]="{'width':'100%'}">
          </p-autoComplete>
        </label>
      </div>

      <div class="p-col-1">
        <label>
          Etáž
          <p-autoComplete formControlName="etaz" [suggestions]="navrhovaneEtaze" (completeMethod)="hledejEtaze($event)" field="etaz"
                          (onBlur)="etazBlurHandler($event)"
                          [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}">
          </p-autoComplete>
        </label>
      </div>

      <div class="p-col-2">
        <label>
          Dřevina
          <p-autoComplete formControlName="drevina" [suggestions]="navrhovaneDreviny" (completeMethod)="hledejDreviny($event)"
                          (onBlur)="drevinaBlurHandler($event)"
                          [forceSelection]="true" field="kod"
                          [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}">
            <ng-template let-drevina pTemplate="item">
              {{drevina.kod}} - {{drevina.popis}}
            </ng-template>
          </p-autoComplete>
        </label>
      </div>
    </div>
  </p-panel>
</form>
