import { Component, forwardRef, HostBinding, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { LhcListDto } from 'src/app/Dto/Lhp/LhcListDto';
import { LhpoService } from 'src/app/Services/Lhp/lhpo.service';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { AutoComplete } from 'primeng/autocomplete';

@Component({
  selector: 'app-lhc-autocomplete',
  templateUrl: './lhc-autocomplete.component.html',
  styleUrls: ['./lhc-autocomplete.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => LhcAutocompleteComponent),
    multi: true
  }]
})
export class LhcAutocompleteComponent implements ControlValueAccessor, OnInit {

  private _id = '';
  private _allLhc: LhcListDto[] = [];

  lhcDto: LhcListDto = null;

  onModelChange: any = () => { };
  onModelTouched: any = () => { };
  @Input() disabled: boolean = false;
  navrhovaneLhc: LhcListDto[];
  wasSetNull: boolean = false; //došlo k promazání. Nesmí se znovu nastavit lhc v blur.
  private _orgUrGuid: string;//omezení podle org. ur
  @Input() set orgUrGuid(value: string) {
    this._orgUrGuid = value;
    this._loadLhc();
  }

  // finta s ID odkoukána zde: https://coryrylan.com/blog/angular-custom-form-controls-with-reactive-forms-and-ngmodel
  @HostBinding('attr.id')
  externalId = '';

  //focus na input
  @Input() focus: EventEmitter<any> = new EventEmitter();

  @Input()
  set id(value: string) {
    this._id = value;
    this.externalId = null;
  }

  get id() {
    return this._id;
  }

  /**
    * Id inputu v autocomplete
    **/
  @Input() lhcInputId: string;

  @Input('value') _value: string = null;

  get value() {
    return this._value;
  }

  set value(val) {
    if (val == void 0) {
      this.wasSetNull = true;
      if (this._allLhc.length == 1 && this.edit)
        this.lhcDto = this._allLhc[0];
      else
        this.lhcDto = null;
    }
    this._workaround();
    this._value = val;
    this.onModelChange(val);
    this.onModelTouched();
    this.onChange.emit(this.lhcDto);
  }

  @Input() edit: boolean = true;

  @Output() onChange: EventEmitter<LhcListDto> = new EventEmitter();

  // workaround na bug v autocomplete: https://github.com/primefaces/primeng/issues/3641
  @ViewChild("lhcAutocomplete") autocomplete: AutoComplete;

  constructor(private lhpoService: LhpoService) { }

  ngOnInit(): void {
    this.focus.subscribe(() => {
      this.autocomplete.focusInput();
    });
    this._loadLhc();
  }

  /**
   * Načte LHC
   * */
  private _loadLhc(): void {
    this.lhpoService.lhcList(this._orgUrGuid).subscribe(res => {
      this._allLhc = res;
      if (this.value != null) {
        this.lhcDto = this._allLhc.find(x => x.guid == this.value);
        this._workaround();
      } else if (this._allLhc.length == 1 && this.edit) {
        this.lhcDto = this._allLhc[0];
        this._workaround();
      }
    });
  }

  writeValue(obj: any): void {
    if (obj != null && this._allLhc.length > 0) {
      this.lhcDto = this._allLhc.find(x => x.guid == obj);
    }
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * Vrátí seznam lhc pro autocomplete.
   * @param event
   */
  hledejLhc(event): void {
    let toSearch = event.query;

    this.navrhovaneLhc = this._allLhc.filter(x => x.lhcKod.toString().startsWith(toSearch));
  };

  /**
   * Zajistí, že v modelu formuláře bude uložen lhcGuid i v případě, že uživatel zapsal LHC ručně.
   * @param event
   */
  onBlur(event) {
    if (event.target.value == undefined || event.target.value == "" && !this.wasSetNull) {
      this.lhcDto = null;
      this.value = null; // vyvolá onChange
    }
    else if (event.target.value != void 0 && typeof event.target.value == 'string' && !this.wasSetNull) {
      let lhc = this._allLhc.find(x => x.lhcKod == Number(event.target.value));

      if (lhc != undefined && this.value != lhc.guid) {
        this.lhcDto = lhc;
        this.value = lhc.guid; // vyvolá onChange
      }
    }
    this.wasSetNull = false;
  }

  /**
   * Ošetření výběru ze seznamu autocomplete (potřebuji celé DTO, nikoli jen jednu položku)
   * @param value
   */
  onSelect(value: LhcListDto): void {
    this.lhcDto = value;
    this.value = value.guid; // vyvolá OnChange
  }

  /**
   * workaround na bug v autocomplete: https://github.com/primefaces/primeng/issues/3641
   */
  private _workaround(): void {
    if (this.autocomplete != void 0)
      setTimeout(() => this.autocomplete.inputEL.nativeElement.value = (this.lhcDto != undefined ? this.lhcDto.lhcKod : ""));
  }

}
