import { Observable, of } from 'rxjs';

export class ServiceUtils {
  /**
   * Metoda pro použití v service pro transformaci HTTP chyby na ResultInfoDto
   * @param r
   */
  static transformError<T>() {
    return (r: any): Observable<T> => {
      let result: any = { success: false };
      if (r.status == "403") {
        result['messages'] = ['Pro danou operaci nemáte dostatečná oprávnění'];
      } else {
        result['messages'] = ['Došlo k chybě při komunikaci se serverem'];
      }
      return of(result as T);
    }
  }
}
