<p-breadcrumb [model]="breadcrumbs" [home]="breadcrumbHome"></p-breadcrumb>

<div *ngIf="model" class="ui-widget">
  <div class="p-grid kniha-body">
    <div class="p-col-2">
      <app-kniha-tree [treeLhcGuid]="lhcGuid"></app-kniha-tree>
    </div>
    <div class="p-col-10">
      <p-card>
        <app-kniha-detail-drevina [drevina]="model"></app-kniha-detail-drevina>
      </p-card>
    </div>
  </div>
</div>
