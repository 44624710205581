import { Component, Input } from '@angular/core';
import { ZniceniInfoDto } from 'src/app/Dto/Skody/ZniceniInfoDto';
import { VypocetDetailDto } from 'src/app/Dto/Skody/VypocetDetailDto';

/**
 * Komponenta zobrazení kroků výpočtu škody S6.
**/
@Component({
  selector: 'skody-detail-s6-kroky',
  templateUrl: './skody-detail-s6-kroky.component.html',
  styleUrls: ['./skody-detail-s6-kroky.component.css']
})
export class SkodyDetailS6KrokyComponent {

  constructor() { }

  @Input() zniceniDto: ZniceniInfoDto;
  @Input() skoda: VypocetDetailDto;
  @Input() blockedPrepocet: boolean;
  
}
