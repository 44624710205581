import { Component} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { KnihaService } from 'src/app/Services/Lhp/kniha.service';
import { DilDetailDto } from 'src/app/Dto/Lhp/DilDetailDto';
import { KnihaBase } from 'src/app/Components/Lhp/kniha-base';

@Component({
  selector: 'app-kniha-dil',
  templateUrl: './kniha-dil.component.html',
  styleUrls: ['./kniha-dil.component.css']
})
export class KnihaDilComponent extends KnihaBase {

  constructor(
    route: ActivatedRoute,
    knihaService: KnihaService
  ) {
    super(route, knihaService);
  }

  model: DilDetailDto;

  loadModel() {
    this.knihaService.getDil(this.lhcKod, this.odd, this.dil, this.guid)
      .subscribe(response => {
        this.model = response;
        this.dilGuid = this.model.guid;
        this.oddGuid = this.model.lhpGuids.oddGuid;
        this.lhcGuid = this.model.lhpGuids.lhcGuid;
        this.constructBreadCrumbs();
      });
  }
}
