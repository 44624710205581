import { Component, forwardRef, Input, HostBinding } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

/**
 * Komponenta pro zadání dne v měsíci. Na vstupu má referenční datum (z toho se bere den a měsíc) a aktuální hodnotu. 
 */
@Component({
  selector: 'app-datum-den-input',
  styles: [
    '.invalid { color: red; }'
  ],
  template: '<input pInputText [id]="id" type="text" [disabled]="disabled" [(ngModel)]="den" *ngIf="edit" style="width: 100%" [ngClass]="{\'invalid\': invalid}" />'
    + '<div class="p-inputtext ui-widget ui-state-default ui-corner-all ui-div-inputtext" *ngIf="!edit">{{den}} </div>',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DatumDenInputComponent),
    multi: true
  }]
})
export class DatumDenInputComponent implements ControlValueAccessor {

  private _id = '';
  private _value: Date;
  private _den: string;
  private _refDate: Date = new Date();

  constructor() { }

  ngOnInit(): void {
  }

  /**
   * Nastaví se na true, pokud hodnota v poli neobsahuje platný den v měsíci.
   */
  invalid: boolean = false;

  @Input() edit: boolean = true;

  /**
   * Referenční datum definuje rok a měsíc, ve kterém se zadávaná hodnota pohybuje. Výchozí = dnes.
   */
  @Input() set refDate(val: Date) {
    this._refDate = val;
    this.recalc();
  }

  // finta s ID odkoukána zde: https://coryrylan.com/blog/angular-custom-form-controls-with-reactive-forms-and-ngmodel
  @HostBinding('attr.id')
  externalId = '';

  @Input()
  set id(value: string) {
    this._id = value;
    this.externalId = null;
  }

  get id() {
    return this._id;
  }

  // Proměnné pro implementaci rozhraní ControlValueAccessor
  onModelChange: any = () => { };
  onModelTouched: any = () => { };
  disabled: boolean = false;

  get value(): Date {
    return this._value;
  }

  set value(val: Date) {
    this._value = val;

    if (this._value) {
      this._den = "" + this._value.getDate();
    }
    else {
      this._den = "";
    }

    this.onModelChange(this._value);
    this.onModelTouched();
  }

  /**
   * Den v měsíci - hodnota pro zobrazení ve vstupním poli
   */
  get den(): string {
    return this._den;
  }

  /**
   * Den v měsíci - hodnota pro zobrazení ve vstupním poli
   */
  set den(val: string) {
    if (this._den != val) {
      this._den = val;
      this.recalc();
    }
  }

  // Implementace rozhraní ControlValueAccessor
  writeValue(obj: any): void {
    this.value = obj;
  }

  // Implementace rozhraní ControlValueAccessor
  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  // Implementace rozhraní ControlValueAccessor
  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }

  // Implementace rozhraní ControlValueAccessor
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * Provede přepočet data při změně hodnoty v inputu nebo při změně referenčního data
   */
  private recalc() {
    var prevValue = this._value;

    if (this._den) {
      var num = Number(this._den);
      // počet dní v měsíci - inspirace https://stackoverflow.com/a/1184359
      var daysInMonth = new Date(this._refDate.getFullYear(), this._refDate.getMonth() + 1, 0).getDate();

      if (num != NaN && num > 0 && num <= daysInMonth) // vypadá to dobře
      {
        var datum = new Date(this._refDate.getFullYear(), this._refDate.getMonth(), num);
        this._value = datum;
        this.invalid = false;
      }
      else { // rozpracovaný nebo neplatný vstup - nenastavím datum.
        this._value = undefined;
        this.invalid = true;
      }
    }
    else {
      this._value = undefined;
      this.invalid = false;
    }

    if (this._value != prevValue) {
      this.onModelChange(this._value);
      this.onModelTouched();
    }
  }
}
