import { EventEmitter, Injectable, Output } from "@angular/core";
import { Layer } from "ol/layer";

/**
 * Sevice pro správu/obsluhu mapových vrstev.
**/
@Injectable()
export class LayersInteractionService {

  /**
   * Událost předávající informaci o tom, že byly do mapy přidány mapové vrstvy.
  **/
  @Output() LayersLoaded: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Vypuštění události o načtených mapách.
  **/
  layersLoaded(): void {
    this.LayersLoaded.emit();
  }


  /**
   * Událost předávající informaci o tom, že se mají aktualizovat zdroje mapový vrstev.
  **/
  @Output() RefreshSources: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Vypuštění události pro aktualizaci zdrojů mapových vrstev.
  **/
  refreshSources(): void {
    this.RefreshSources.emit();
  }


  /**
   * Událost předávající název mapové vrstvy, která má být zapnuta/zobrazena.
  **/
  @Output() TurnOnLayer: EventEmitter<string> = new EventEmitter<string>();


  /**
   * Vypuštění události pro zapnutí mapové vrstvy.
   * @param layerName {string} Název vrstvy
   */
  turnOnLayer(layerName: string) {
    this.TurnOnLayer.emit(layerName);
  }
}
