import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { ResultInfoDto, ResultInfoGenericDto } from '../../Dto/Shared/ResultInfoDto';
import { DochazkaEditDto } from '../../Dto/Vyroba/DochazkaEditDto';

@Injectable({
  providedIn: 'root'
})
export class DochazkaService {
  constructor(
    private http: HttpClient
  ) { }

  private apiDochazkaEdit: string = 'api/vyroba/Dochazka/Edit';
  private apiDochazkaSave: string = 'api/vyroba/Dochazka/Save';


  /**
   * Uloží docházku
   * @param data
   */
  save(data: DochazkaEditDto): Observable<ResultInfoDto> {
    return this.http.post<ResultInfoDto>(this.apiDochazkaSave, data, {});
  }


  /**
   * Vrátí docházky dokladu 
   * @param rok
   * @param mesic
   * @param pracovnikGuid
   * @param dokladGuid
   */
  edit(rok: string, mesic: string, pracovnikGuid: string, dokladGuid: string): Observable<ResultInfoGenericDto<DochazkaEditDto>> {
    var param = new HttpParams();
    //nepovinné údaje nevkádat, pokud neexistují
    if (rok != void 0)
      param = param.append('rok', rok);
    if (mesic != void 0)
      param = param.append('mesic', mesic);
    if (pracovnikGuid != void 0)
      param = param.append('pracovnikGuid', pracovnikGuid);
    if (dokladGuid != void 0)
      param = param.append('dokladGuid', dokladGuid);

    return this.http.get<ResultInfoGenericDto<DochazkaEditDto>>(this.apiDochazkaEdit, { params: param });
  }

}
