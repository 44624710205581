import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserDetailDto } from '../../Dto/Auth/UserDetailDto';
import { UserListDto } from '../../Dto/Auth/UserListDto';
import { ResultInfoGenericDto } from '../../Dto/Shared/ResultInfoDto';

/**
* Service pro práci s uživateli.
*/
@Injectable({
  providedIn: 'root'
})
export class UzivatelService {

  constructor
  (
      private http: HttpClient
  )
  {
  }

  private apiUzivatelList: string = 'api/zakaznik/Uzivatel/List';
  private apiUzivatelCreate: string = 'api/zakaznik/Uzivatel/Create';
  private apiUzivatelGet: string = 'api/zakaznik/Uzivatel/Get';
  private apiUzivatelUpdate: string = 'api/zakaznik/Uzivatel/Update';

  /**
   * Vrátí seznam uživatelů podle zákazníka
   * @param data
   */
  list(zakaznikGuid: string): Observable<UserListDto[]> {
    var param = new HttpParams();
    param = param.append('zakaznikGuid', zakaznikGuid);
    return this.http.get<UserListDto[]>(this.apiUzivatelList, { params: param });
  }

  /**
   * Vrátí uživatele podle guidu
   * @param uzivatelGuid
   */
  get(uzivatelGuid: string): Observable<ResultInfoGenericDto<UserDetailDto>> {
    return this.http.get<ResultInfoGenericDto<UserDetailDto>>(this.apiUzivatelGet + '/' + uzivatelGuid);
  }

  /**
   * Updatuje záznam uživatele
   * @param userDetailDto
   */
  update(userDetailDto: UserDetailDto): Observable<ResultInfoGenericDto<UserDetailDto>> {
    return this.http.post<ResultInfoGenericDto<UserDetailDto>>(this.apiUzivatelUpdate, userDetailDto, {});
  }

  /**
   * Vytvoří nového uživatele
   * @param userDetailDto
   */
  create(userDetailDto: UserDetailDto): Observable<ResultInfoGenericDto<UserDetailDto>> {
    return this.http.post<ResultInfoGenericDto<UserDetailDto>>(this.apiUzivatelCreate, userDetailDto, {});
  }
}
