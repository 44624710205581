import { Component, Output, EventEmitter, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { faImage } from '@fortawesome/free-regular-svg-icons';
import { faCheck, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { NacrtyService } from '../../../Services/Nacrty/nacrty.service';

/**
 * Komponenta pro výběr ikony z knihovny ikon.
**/
@Component({
  selector: 'app-icon-library',
  templateUrl: './icon-library.component.html',
  styleUrls: ['./icon-library.component.css']
})
export class IconLibraryComponent {

  /**
   * Klíč ikony.
  **/
  private _iconKey: string = null;
  @Input() set iconKey(value: string) {
    this._iconKey = value;
    this._setIconByKey(this._iconKey, this.icons);
  }

  /*
   * Slouží k deaktivaci komponenty
  */
  @Input() disabled: boolean = false;

  /**
   * Událost předávající vybranou ikonu.
  **/
  @Output() SelectedIconName: EventEmitter<string> = new EventEmitter<string>();


  constructor(
    private nacrtyService: NacrtyService,
    private domSanitizer: DomSanitizer)
  {
    this.nacrtyService.getIconLibrary().subscribe(icons => {
      let items = [];
      Object.keys(icons).forEach(key => {
        //svg může obsahovat scripty a jsou tedy považovány za nebezpečné, zřejmně se to týká pouze svg kódovaných v base64...
        let unsafeUrl = 'data:image/svg+xml;base64,' + icons[key].data;
        items.push({
          src: this.domSanitizer.bypassSecurityTrustUrl(unsafeUrl),
          name: icons[key].title,
          value: key
        });
      });
      this.icons = items;
      this._setIconByKey(this._iconKey, this.icons);
    });
  }


  faImage = faImage;
  faCheck = faCheck;
  faTimes = faTimes;
  faTrash = faTrash;
  showDlg: boolean = false;
  icons: any[] = [];
  selectedIcon: any = null;
  usedIcon: any = null;


  /**
   * Výběr ikony z knihovny dle klíče.
   * @param key {string} klíč vybrané ikony
   * @param icons {any[]} seznam dostupných ikon
   */
  private _setIconByKey(key: string, icons: any[]) {
    if (key != void 0 && key != '' && icons.length > 0) {
      this.selectedIcon = icons.find(x => x.value == key);
    }
    else {
      this.selectedIcon = null;
    }
    this.usedIcon = this.selectedIcon;
  }


  /**
   * Výběr ikony.
  **/
  pouzitIkonu(): void {
    this.usedIcon = this.selectedIcon;
    this.SelectedIconName.emit(this.usedIcon.value);
    this.showDlg = false;
  }


  /**
   * Odvybrat ikonu.
  **/
  unselectIcon() {
    this.usedIcon = null;
    this.selectedIcon = null;
    this.SelectedIconName.emit(null);
  }
}
