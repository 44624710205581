import { Component, Input } from '@angular/core';
import { JpDetailDto } from '../../../Dto/Lhp/JpDetailDto';

@Component({
  selector: 'app-kniha-detail-jp',
  templateUrl: './kniha-detail-jp.component.html',
  styleUrls: ['./kniha-detail-jp.component.css']
})
export class KnihaDetailJpComponent {

  constructor() { }

  @Input() jp: JpDetailDto;

}
