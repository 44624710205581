import { Component, OnInit } from '@angular/core';
import { NacrtMapItemDto } from '../../../Dto/Nacrty/NacrtMapItemDto';
import { MapInteractionService } from '../../../Services/Mapa/map-interaction.service';

/**
 * Komponenta pro zobrazení nápovědy v mapě
 **/
@Component({
  selector: 'app-map-info-help',
  templateUrl: './map-info-help.component.html',
  styleUrls: ['./map-info-help.component.css']
})
export class MapInfoHelpComponent implements OnInit {

  /**
   * Přepnutí zobrazení nápovědy jen když je potřeba
   */
  public display: boolean = false;

  public geometryType: string;

  constructor(
    private mapInteractionService: MapInteractionService
  ) { }

  ngOnInit(): void {
    this.mapInteractionService.DrawStart.subscribe(geomType => {
      this.geometryType = geomType;
      this.display = true;
    });
    this.mapInteractionService.EditFeature.subscribe((value: NacrtMapItemDto) => {
      this.geometryType = value.geometryType;
      this.display = true;
    });
    this.mapInteractionService.NacrtFinished.subscribe(() => { this.display = false; });
    this.mapInteractionService.DiscardChanges.subscribe(() => { this.display = false; });
  }

}
