import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  faBook, faMap, faDatabase, faTruckMoving, faSeedling, faFileAlt, faScroll, faTable, faWalking,
  faCog, faCalendarAlt, faCheck, faBullhorn, faClipboard, faWarehouse,
  faThList,
  faHandshake,
  faSitemap,
  faUsersCog,
  faShapes,
  faFolder,
  faClipboardList,
  faListOl,
  faFileInvoiceDollar,
  faDraftingCompass
} from '@fortawesome/free-solid-svg-icons';
import {  MenuItem } from 'primeng/api';
import { AuthUtils } from '../../Auth/auth.utils';
import { DotazovacGuard } from '../../Auth/dotazovac.guard';
import { LhpAdminGuard } from '../../Auth/lhp-admin.guard';
import { NacrtyGuard } from '../../Auth/nacrty.guard';
import { SkodyAdminGuard } from '../../Auth/skody-admin.quard';
import { SuperAdminGuard } from '../../Auth/super-admin.guard';
import { ZakaznikAdminGuard } from '../../Auth/zakaznik-admin.guard';

@Component({
  selector: 'app-plocha',
  templateUrl: './plocha.component.html',
  styleUrls: ['./plocha.component.css']
})
export class PlochaComponent implements OnInit {

  constructor
  (
    public router: Router,
    public authUtils: AuthUtils,
    public superAdminGuard: SuperAdminGuard,
    public zakaznikAdminGuard: ZakaznikAdminGuard,
    public lhpAdminGuard: LhpAdminGuard,
    public nacrtyGuard: NacrtyGuard,
    public skodyAdminGuard: SkodyAdminGuard,
    public dotazovacGuard: DotazovacGuard
  )
  {
  }

  faBook = faBook;
  faThList = faThList;
  faMap = faMap;
  faTruckMoving = faTruckMoving;
  faWalking = faWalking;
  faSeedling = faSeedling;
  faFileAlt = faFileAlt;
  faScroll = faScroll;
  faTable = faTable;
  faDatabase = faDatabase;
  faCog = faCog;
  faCalendarAlt = faCalendarAlt;
  faCheck = faCheck;
  faBullhorn = faBullhorn;
  faClipboard = faClipboard;
  faWarehouse = faWarehouse;
  faHandshake = faHandshake;
  faSitemap = faSitemap;
  faClipboardList = faClipboardList;
  faUsersCog = faUsersCog;
  faShapes = faShapes;
  faFolder = faFolder;
  faListOl = faListOl;
  faFileInvoiceDollar = faFileInvoiceDollar;
  faDraftingCompass = faDraftingCompass;
  
  menuVyrobaSestavy: MenuItem[] =[
    { label: "Pěstební - detail", routerLink: ["..", "pestebni"] },
    { label: "Výkaz skladu dříví", routerLink: ["..", "vyroba", "report", "sklad"] },
  ];

  ngOnInit() {
  }

}
