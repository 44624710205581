import { AfterViewInit, Component, ElementRef } from '@angular/core';
import { PreviewBase } from '../preview-base';

/**
 * Komponenta pro pro vykreslení náhledu linie
 **/
@Component({
  selector: 'app-preview-line',
  template: '<canvas width="{{previewSize}}" height="{{previewSize}}">Chyba zobrazení linie</canvas>'
})
export class PreviewLineComponent extends PreviewBase implements AfterViewInit {

  constructor(public hostElement: ElementRef) {
    super(hostElement);
  }

  ngAfterViewInit(): void {
    this._drawPreview();
  }

  /**
   * Metoda pro vykreslení náhledu linie
   */
  private _drawPreview() {
    super.drawPreview();
    if (this.initialized) {
      this.context.beginPath();
      this.context.lineWidth = this.projekt.strokeWidth;
      this.context.moveTo(0, this.previewSize);
      this.context.lineTo(this.previewSize, 0);
      this.context.strokeStyle = this.projekt.strokeRgba;
      this.context.stroke();
    }
  }

}
