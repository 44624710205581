import { Component, Input, forwardRef, HostBinding, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'decimal-input',
  template: '<input pInputText [id]="id" type="text" [disabled]="disabled" [(ngModel)]="value" *ngIf="edit && !readonly" style="width: 100%" />'
    + '<div class="p-inputtext ui-widget ui-state-default ui-corner-all ui-div-inputtext" *ngIf="!edit || readonly" [ngClass]="{\'read-only-box\': readonly}">'
    + '{{ numericValue | number: \'1.2-2\'}} </div>',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DecimalInputComponent),
    multi: true
  }]
})
export class DecimalInputComponent implements ControlValueAccessor {

  private _id = '';
  private _value: string;
  private _numericValue: any;

  constructor() { }

  /**
   * Příznak, zda je povolena editace (používá se pro zobrazení "detailu")
   */
  @Input() edit: boolean = true;

  /**
   * Příznak, že je hodnota jen pro čtení (používá se k odlišení hodnot načtených "odjinud" při editaci)
   */
  @Input() readonly: boolean = false;

  // finta s ID odkoukána zde: https://coryrylan.com/blog/angular-custom-form-controls-with-reactive-forms-and-ngmodel
  @HostBinding('attr.id')
  externalId = '';

  @Input()
  set id(value: string) {
    this._id = value;
    this.externalId = null;
  }

  get id() {
    return this._id;
  }

  @Output() onChange: EventEmitter<number> = new EventEmitter<number>()

  // Proměnné pro implementaci rozhraní ControlValueAccessor
  onModelChange: any = () => { };
  onModelTouched: any = () => { };
  disabled: boolean = false;

  get value() {
    return this._value;
  }

  set value(val) {
    let candidateValue = val ? val.toString().replace('.', ',') : val;

    if (this._value != candidateValue) {
      this._value = candidateValue;
      this._numericValue = val ? Number(val.toString().replace(',', '.')) : val;
      this.onModelChange(this._numericValue);
      this.onChange.emit(this._numericValue);
      this.onModelTouched();
    }
  }

  /**
   * Pro zobrazení pomocí DecimalPipe
   */
  get numericValue() {
    return this._numericValue;
  }

  // Implementace rozhraní ControlValueAccessor
  writeValue(obj: any): void {
    this.value = obj;
  }

  // Implementace rozhraní ControlValueAccessor
  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  // Implementace rozhraní ControlValueAccessor
  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }

  // Implementace rozhraní ControlValueAccessor
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
