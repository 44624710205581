import { Component, Input, OnInit } from '@angular/core';
import { Feature } from 'ol';
import { Coordinate } from 'ol/coordinate';
import WKT from 'ol/format/WKT';
import Map from 'ol/Map';
import { Layer } from 'ol/layer';
import { PesMapItemDto } from '../../../Dto/Pes/PesMapItemDto';
import { FeatureLoaderInteractionService } from '../../../Services/Mapa/feature-loader-interaction.service';
import { LayersInteractionService } from '../../../Services/Mapa/layers-interaction.service';
import { MapInteractionService } from '../../../Services/Mapa/map-interaction.service';
import { ExternalMessagingService } from '../../../Services/Shared/external-messaging.service';
import { PesService } from '../../../Services/Pes/pes.service';
import { PesOptions } from '../../../Dto/Pes/PesOptions';

/**
 * Komponenta modulu PES pro specifické operace s mapovými objekty tohoto modulu (blíže viz #43560).
**/
@Component({
  selector: 'app-pes',
  template: ''
})
export class PesComponent implements OnInit {

  /**
   * Id referenční mapy.
  **/
  @Input() set mapId(value: string) {
    this._mapId = value;
  }


  constructor(
    private mapInteractionService: MapInteractionService,
    private externalMessagingService: ExternalMessagingService,
    private layersInteractionService: LayersInteractionService,
    private featrueLoaderInteractionService: FeatureLoaderInteractionService,
    private pesService: PesService
  )
  { }

  private readonly _modul: string = 'Pes';
  private readonly _parcelyLayerName: string = 'pes_parcely';
  private _format: WKT = new WKT();
  private _mapId: string;
  private _map: Map;
  private _pesOptions: PesOptions;


  ngOnInit(): void {
    this.externalMessagingService.ExternalMessage.subscribe(this._extMsgHandler.bind(this));
    this.mapInteractionService.GetLocalItems.subscribe(this.selectByCoords.bind(this));

    this.pesService.getPesOptions().subscribe(opt => {
      this._pesOptions = opt;
      this._sendPesPrepared();
    });

    this.mapInteractionService.MapCreated.subscribe(map => {
      if (map != void 0 && map.getTargetElement().id == this._mapId) {
        this._map = map;
        this._sendPesPrepared();
      }
    });
  }


  /**
   * Handler výběru mapových objektů parcel pozemkové evidence na zadaných souřadnicích.
   * @param coords {Coordinate} souřadnice, na kterých se mají parcely hledat
   */
  private selectByCoords(coords: Coordinate): void {
    if (this._map != void 0) {
      var items: PesMapItemDto[] = [];

      let layer: Layer<any, any> = this._map.getLayers().getArray().find(l => l.getProperties().name == this._parcelyLayerName) as Layer<any, any>;
      if (layer != void 0) {

        if (layer != void 0 && coords != void 0) {
          let parcelySource = layer.getSource();
          let featuresAtCoords = parcelySource.getFeaturesAtCoordinate(coords);
          items = this._toMapItems(featuresAtCoords);
        }
      }

      this.mapInteractionService.localItems(items);
    }
  }


  /**
   * Handler zprávy z HTML Messaging.
   * @param message {any} Zpráva messagingu.
   */
  private _extMsgHandler(message: any): void {
    if (message.origin == this._pesOptions?.pesOrigin) {
      if (this._map != void 0) {
        let layer: Layer<any, any> = this._map.getLayers().getArray().find(l => l.getProperties().name == this._parcelyLayerName) as Layer<any, any>;
        if (layer != void 0) {
          //odstraníme případné duplicity
          let toSelect = message.data.selection.filter((value, index) => message.data.selection.indexOf(value) == index);

          var featuresLoadedSubs = this.featrueLoaderInteractionService.FeaturesLoaded.subscribe(name => {
            if (name == this._parcelyLayerName) {
              featuresLoadedSubs.unsubscribe();
              let parcely = this._selectByIds(layer, toSelect);
              this.mapInteractionService.selectedItems(parcely);
              this.mapInteractionService.toggleInfoPanel(true);
            }
          });

          let parcely = this._selectByIds(layer, toSelect);
          if (parcely.length == toSelect.length) {
            featuresLoadedSubs.unsubscribe();
            this.mapInteractionService.selectedItems(parcely);
            this.mapInteractionService.toggleInfoPanel(true);
          }

          this.mapInteractionService.zoomTo(message.data.bbox);
          if (!layer.getVisible()) {
            this.layersInteractionService.turnOnLayer(this._parcelyLayerName);
          }
        }
      }
    }
  }

  /**
   * Výběr parcel pozemkového katastru dle id.
   * @param layer {Layer<any, any>} Vrstva, ze které se provádí výběr.
   * @param ids {number[]} Seznam id parcek k výběru.
   */
  private _selectByIds(layer: Layer<any, any>, ids: number[]): PesMapItemDto[] {
    let source = layer.getSource();
    let parcely = source.getFeatures().filter(f => ids.includes(parseInt(f.getProperties()['PAR_ID'])));
    return this._toMapItems(parcely);
  }


  /**
   * Konverze featur do dto mapového objektu modulu PES.
   * @param features {Feature<any>[]} Kolekce featur z mapové vrstvy
   */
  private _toMapItems(features: Feature<any>[]): PesMapItemDto[] {
    var items: PesMapItemDto[] = [];
    items = features.map(f => {
      let fProps = f.getProperties();

      let mi = new PesMapItemDto();
      mi.id = f.getId().toString();
      mi.modul = this._modul;
      mi.wkt = this._format.writeFeature(f);
      mi.parId = fProps['PAR_ID'];
      mi.katuzeKod = fProps['KATUZE_KOD'];
      mi.katuzeNazev = fProps['KATUZE_NAZEV'];
      mi.cisloPar = fProps['KMENOVE_CISLO_PAR'];
      mi.podlomeni = fProps['PODDELENI_CISLA_PAR'];
      mi.vymera = fProps['VYMERA_PARCELY'];
      mi.druhPozemkuKod = fProps['DRUPOZ_KOD'];
      mi.druhPozemkuNazev = fProps['DRUPOZ_NAZEV'];
      return mi;
    });
    return items;
  }


  /**
   * Předání informace aplikaci PES o tom, že je komponenta připravena.
  **/
  private _sendPesPrepared(): void {
    //tato komponenta pro správné fungování potřebuje mít jak mapu, tak aplikační nastavení modulu PES.
    if (this._map != void 0 && this._pesOptions != void 0) {
      this.externalMessagingService.sendMessage({ event: 'PesModulPrepared', data: null });
    }
  }
}
