import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { faImage } from '@fortawesome/free-regular-svg-icons';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ConfirmationService, MessageService } from 'primeng/api';
import { HostListener } from '@angular/core';
import { ByteArrayUtils } from '../../../Utils/Mapa/byte-array.utils';
import { NacrtyInteractionService } from '../../../Services/Nacrty/nacrty-interaction.service';
import { Subscription } from 'rxjs';
import { OnDestroy } from '@angular/core';
import { IPriloha } from '../../../Dto/Nacrty/IPriloha';
import { NacrtPrilohyChangedArgs } from '../../../Dto/Nacrty/NacrtPrilohyChangedArgs';
import { KeyCode } from '../../../Utils/Shared/key-codes';

/**
 * Komponenta pro správu fotografií náčrtu.
**/
@Component({
  selector: 'app-nacrt-priloha-foto-edit',
  templateUrl: './nacrt-priloha-foto-edit.component.html',
  styleUrls: ['./nacrt-priloha-foto-edit.component.css']
})
export class NacrtPrilohaFotoEditComponent implements OnInit, OnDestroy {

  /**
   * Eventa informující o přečtení vybraného souboru. Předává index pro načtení/zpracování dalšího vybraného soubrou. Pouze pro interní použítí.
  **/
  @Output() _fileReaded: EventEmitter<number> = new EventEmitter<number>();


  /**
   * Komponenta/HTML element input typu 'file'.
  **/
  @ViewChild('addPhotoBtn', { static: true }) fileInput: ElementRef;


  /**
   * Konstruktor.
   * @param confirmationService
   * @param messageService
   * @param uuidUtils
   */
  constructor(
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private byteArrayUtils: ByteArrayUtils,
    private nacrtyInteractionService: NacrtyInteractionService)
  {
    this._fileReaded.subscribe(this._fileReadedHandler.bind(this));
  }


  ngOnInit() {
    this._prilohyInitSubs = this.nacrtyInteractionService.OnInitEdit.subscribe(this._onInitHandler.bind(this));
    this._onAddSubs = this.nacrtyInteractionService.OnAdd.subscribe(this._procesPrilohy.bind(this));
    this._onDeleteSubs = this.nacrtyInteractionService.OnDelete.subscribe(this._onDeleteHandler.bind(this));
  }


  ngOnDestroy() {
    this._prilohyInitSubs.unsubscribe();
    this._onAddSubs.unsubscribe();
    this._onDeleteSubs.unsubscribe();
  }


  readonly TOAST_KEY: string = 'mapContainerToastKey';
  readonly CONFIRM_KEY: string = 'fotoDeleteConfirm';
  faImage = faImage;
  faPlus = faPlus;
  faTrash = faTrash;
  private _prilohyInitSubs: Subscription;
  private _onAddSubs: Subscription;
  private _onDeleteSubs: Subscription;


  /**
   * Kolekce zobrazených příloh.
  **/
  photoSources: IPriloha[] = [];


  /**
   * Index aktuálně zobrazovaného obrázku v galerii.
  **/
  activeIndex: number = 0;


  /**
   * Uživatelem vybrané soubory k uploadu
  **/
  private _selectedFiles: any[] = [];

  
  /**
   * Handler inicializace příloh
   * @param args {NacrtPrilohyChangedArgs} argumenty příloh náčrtu
   */
  private _procesPrilohy(args: NacrtPrilohyChangedArgs): void {
    this.photoSources = args.prilohy.filter(x => x.typ == 'FOTO' && !x.sync.d)
      .map(x => { return { nazev: x.souborNazev, src: 'data:image/' + x.souborNazev.split('.')[1] + ';base64,' + x.soubor, id: x.guid }; });
  }


  /**
   * Handler inicializace edtiace příloh.
   * @param args {NacrtPrilohyChangedArgs} argumenty příloh náčrtu
   */
  private _onInitHandler(args: NacrtPrilohyChangedArgs): void {
    this.activeIndex = 0;
    this._procesPrilohy(args);
  }


  /**
   * Handler události o smazání přílohy.
   * @param args {NacrtPrilohyChangedArgs} argumenty příloh náčrtu
   */
  private _onDeleteHandler(args: NacrtPrilohyChangedArgs): void {
    if (args.deleted != void 0 && args.deleted.typ == 'FOTO') {
      this._procesPrilohy(args);
      this._setActiveIndexAfterDelete();
    }
  }


  /**
   * Handler výběru fotek z lokálního uložiště.
   * @param event
   */
  addPhoto(event): void {
    this._selectedFiles = event.target.files;
    this._readFile(this._selectedFiles[0], 0);
  }


  /**
   * Přečtení vybraného souboru a jeho konverze do Base64.
   * @param file {File} vybraný soubor
   * @param idx {number} index vybraného soubrou
   */
  private _readFile(file: File, idx: number): void {
    let reader = new FileReader();
    reader.onload = () => {
      this._onLoadHandler(reader, file, idx);
    };
    reader.onerror = err => {
      this.messageService.add({ severity: 'error', summary: 'Chyba', detail: 'Při zpracování souboru ' + file.name + ' došlo k chybě.', key: this.TOAST_KEY });
    };

    reader.readAsArrayBuffer(file);
  }


  /**
   * Handler načtení vybraného souboru.
   * @param idx {number} Index/pořadí načteného soubrou v seznamu uživatelem vybraných souborů
   */
  private _fileReadedHandler(idx: number): void {
    this.activeIndex = this.photoSources.length - 1;
    if (this._selectedFiles.length > idx) {
      this._readFile(this._selectedFiles[idx], idx);
    }
    else {
      this._selectedFiles = [];
      //vyčistění vybraných souborů v elementu file inputu - je potřeba kvůli opakovanému zobrazení dialogu edtiace příloh
      this.fileInput.nativeElement.value = null;
    }
  }


  /**
   * Handler úspěšného uploadu vybraného souboru.
   * @param reader {FileReader} File reader, který načítal soubor
   * @param file {File} Načítaný soubor
   * @param idx {number} Index/pořadí načítaného souboru
   */
  private _onLoadHandler(reader: FileReader, file: File, idx: number): void {
    let base64data = this.byteArrayUtils.ToBase64(reader.result as ArrayBuffer);

    //TODO: zvážit, zda nejdříve nenačít všechny soubory a pak do service předat koleci
    this.nacrtyInteractionService.add(file, base64data, 'FOTO');
    this._fileReaded.emit(++idx);
  }


  /**
   * Smazání fotografie.
   * @param event
   */
  removePhoto(event): void {
    if (this.photoSources.length == 0) return;

    this.confirmationService.confirm({
      target: event.currentTarget,
      key: this.CONFIRM_KEY,
      message: 'Opravdu smazat fotografii?',
      icon: 'pi pi-exclamation-triangle',
      accept: this.deleteAccepted.bind(this),
      acceptLabel: 'Smazat',
      reject: () => { },
      rejectLabel: 'Zrušit'
    });
  }


  /**
   * Potvrzení smaznání fotografie.
  **/
  deleteAccepted(): void {
    this.nacrtyInteractionService.delete(this.photoSources[this.activeIndex]);
  }


  /**
   * Nastavení aktivního indexu, tj. zobrazeného obrázku, po smazání obrázku.
  **/
  private _setActiveIndexAfterDelete(): void {
    if (this.photoSources.length > 0) {
      if (this.activeIndex > 0) {
        this.activeIndex -= 1;
      }
      else {
        //workaround - komponenta primeng galleria si neumí poradit s odebráním obrázku na prvním místě,
        //tj. se situací, kdy se activeIndex nezmění, ale změní se obrázek na tomto indexu.
        let tmp = this.photoSources.slice();
        this.photoSources = [];
        this.activeIndex = null;
        setTimeout(() => {
          this.photoSources = tmp.slice();
          this.activeIndex = 0;
        });
      }
    }
    else {
      this.activeIndex = null;
    }
  }


  /**
   * Registrace ovládání pohybu v galerii přes klávesnici.
   * @param event {KeyboardEvent} Událost stisku klávesy
   */
  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key == KeyCode.LEFT_KEY) this.activeIndex = this.activeIndex == 0 ? this.photoSources.length - 1 : this.activeIndex - 1;
    if (event.key == KeyCode.RIGHT_KEY) this.activeIndex = this.activeIndex == this.photoSources.length - 1 ? 0 : this.activeIndex + 1;
  }
}
