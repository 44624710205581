import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faThList } from '@fortawesome/free-solid-svg-icons';
import { MessageService, SelectItem, TreeNode } from 'primeng/api';
import { DilListDto } from '../../../Dto/Lhp/DilListDto';
import { LhcListDto } from '../../../Dto/Lhp/LhcListDto';
import { OddListDto } from '../../../Dto/Lhp/OddListDto';
import { PorDetailDto } from '../../../Dto/Lhp/PorDetailDto';
import { PorListDto } from '../../../Dto/Lhp/PorListDto';
import { KnihaService } from '../../../Services/Lhp/kniha.service';
import { MessagesUtils } from '../../../Utils/Shared/messages.utils';


/**
 * Koponenta pro procházení a výběr JPRL od LHC až po porost
 **/
@Component({
  selector: 'app-kniha-detail-tree',
  templateUrl: './kniha-detail-tree.component.html',
  styleUrls: ['./kniha-detail-tree.component.css']
})
export class KnihaDetailTreeComponent {

  constructor(private knihaService: KnihaService,
    private messageService: MessageService) {
    this.platnostOptions = [
      { value: null, label: "Vše" },
      { value: true, label: "Platné" },
      { value: false, label: "Neplatné" }
    ];
    this.platnost = true;
  }



  faThList = faThList;

  private _allLhcList: LhcListDto[];

  /**
   * Obsah tabulky LHC
   */
  lhcItems: LhcListDto[];

  /**
   * Seznam LHC pro naplnění tabulky
   */
  @Input() set lhcList(value: LhcListDto[]) {
    this._allLhcList = value;
    this.filtrujPlatnost(true);
  }

  /**
   * Seznam načtených stromů LHC
   */
  @Input() lhcTreeDict: Record<string, TreeNode>;

  /**
   * Vybraný porost
   */
  @Input() porDetail: PorDetailDto;

  /**
   * pole pro výpis do gridů
   */
  oddList: OddListDto[];
  dilList: DilListDto[];
  porList: PorListDto[];

  //stromy pro vybrané seznamy, aby se při výběru v gridu nemuselo sahat do celkového stromu. 
  private _oddTree: TreeNode[];
  private _dilTree: TreeNode[];

  //Při načítání zablokovat
  loading: boolean = false;

  public readonly TOAST_KEY: string = "kniha-detail-tree-toast";

  /**
  * Vybrané LHC
  */
  selectedLhc: LhcListDto;

  /**
   * Vybrané oddělení
   */
  selectedOdd: OddListDto;

  /**
   * Vybrané dílec
   */
  selectedDil: DilListDto;

  /**
  * Vybraný porost
  */
  selectedPor: PorListDto;

  /**
   * eventa pro výběr JPRL
   */
  @Output() onSelectPor: EventEmitter<string> = new EventEmitter<string>();


  /**
   * Obsah filtru podle platnosti
   */
  platnostOptions: SelectItem[];

  /**
   * Vybraná hodnota filtru platnosti
   */
  platnost: boolean;

  /**
   * zobrazení/skrytí dialogu
   */
  displayDialog: boolean = false
  @Input() set showDialog(value: boolean) {
    if (value && this.porDetail != void 0) {
      this.onLhcSelect(this.porDetail.lhpGuids?.lhcGuid);
      this.selectedLhc = this._allLhcList.find(x => x.guid == this.porDetail.lhpGuids?.lhcGuid);
    }
    this.displayDialog = value;
  }

  /**
   * Zpracuje stažený uzel LHC
   * @param node
   */
  private _loadLhcTree(node: TreeNode): void {
    this._oddTree = node.children;
    this.oddList = node?.children.map(x => x.data);
    if (this.porDetail != void 0) {//pokud je známý porost, tak naplnit a vybrat všechny tabulky
      this.selectedOdd = this.oddList.find(c => c.guid == this.porDetail.lhpGuids?.oddGuid);
      this._dilTree = this._oddTree.find(x => x.data?.guid == this.porDetail.lhpGuids?.oddGuid).children;
      this.dilList = this._oddTree.find(x => x.data?.guid == this.porDetail.lhpGuids?.oddGuid).children.map(x => x.data);
      this.selectedDil = this.dilList.find(c => c.guid == this.porDetail.lhpGuids?.dilGuid);
      this.porList = this._dilTree.find(x => x.data?.guid == this.porDetail.lhpGuids?.dilGuid).children.map(y => y.data);
      this.selectedPor = this.porList.find(c => c.guid == this.porDetail.guid);
    } else {
      this.selectedOdd = this.oddList[0];//při výběru LHC vybrat i první oddíl
      this.onOddSelect(this.selectedOdd.guid);
    }
    this.loading = false;
  }


  /**
   * Výběr v gridu LHC
   * @param event
   */
  onLhcSelect(value: any): void {
    this.loading = true;
    this.onLhcUnselect();
    let guid = value;
    if (typeof (value) != "string") {//pokud došlo k výběru v tabulce LHC
      guid = value?.data?.guid;
      this.porDetail = null;
    }

    if (this.lhcTreeDict[guid] == void 0) {
      this.knihaService.getFromLhcToPorTree(guid)
        .subscribe(response => {
          if (response?.length > 0 && response[0]?.children?.length > 0) {
            this.lhcTreeDict[guid] = response[0];
            this.porDetail = null;
            this._loadLhcTree(response[0]);
          } else {
            this._oddTree = [];
            this.oddList = [];
            this.messageService.add({ severity: 'error', summary: 'Chyba', detail: "Není dostupné žádné LHC", key: this.TOAST_KEY, life: MessagesUtils.TOAST_LIFE })
          }
        });
    } else {
      this._loadLhcTree(this.lhcTreeDict[guid]);
    }
  }

  /**
   * Zrušením výběru LHC vše promazat
   * */
  onLhcUnselect(): void {
    this._oddTree = [];
    this.oddList = [];
    this.selectedOdd = null;
    this.onOddUnselect();
  }

  /**
   * Výběr oddělení
   * @param event
   */
  onOddSelect(value: any): void {
    let guid = typeof (value) == "string" ? value : value.data?.guid;
    this.onOddUnselect();
    this._dilTree = this._oddTree.find(x => x.data?.guid == guid).children;
    this.dilList = this._dilTree.map(y => y.data);
    this.selectedDil = this.dilList[0];
    this.onDilSelect(this.selectedDil.guid);
  }

  /**
   * Zrušením výběru oddělení promazat podřízené gridy
   * */
  onOddUnselect(): void {
    this._dilTree = [];
    this.dilList = [];
    this.selectedDil = null;
    this.onDilUnselect();
  }

  /**
   * Výběr dílce
   * @param event
   */
  onDilSelect(value: any): void {
    let guid = typeof (value) == "string" ? value : value.data?.guid;
    this.onDilUnselect();
    this.porList = this._dilTree.find(x => x.data?.guid == guid).children.map(y => y.data);
    this.selectedPor = this.porList[0];
  }

  /**
   * Zrušením výběru dílce promazat porost
   * */
  onDilUnselect(): void {
    this.porList = [];
    this.selectedPor = null;
  }

  /**
   * Odeslání vybrané položky
   * */
  save() {
    this.onSelectPor.emit(this.selectedPor?.guid);
    this.displayDialog = false;
  }

  /**
   * Po zavření dialogu je nutné promazat a odeslat eventu
   * */
  onCloseDialog(): void {
    this.selectedLhc = null;
    this.onLhcUnselect();
  }

  /**
   * Testuje platnost LHP/O vůči dnešnímu dni.
   * Smyslem metody je identifikovat plán s již ukončenou platností.
   * @param lhc
   */
  isInvalid(lhc: LhcListDto): boolean {
    return lhc.lhpDo < new Date();
  }

  /**
   * Handler filtrace podle platnosti
   * @param platnost
   */
  filtrujPlatnost(platnost: boolean): void {
    if (platnost == void 0) {
      this.lhcItems = this._allLhcList;
    } else if (platnost) {
      this.lhcItems = this._allLhcList?.filter(x => !this.isInvalid(x));
    } else if (!platnost) {
      this.lhcItems = this._allLhcList?.filter(x => this.isInvalid(x));
    }
  }
}
