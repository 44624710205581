import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DVyrUzaverkyListDto, DVyrUzaverkyInitDto } from '../../Dto/Vyroba/DVyrUzaverkyListDto';
import { ResultInfoDto } from '../../Dto/Shared/ResultInfoDto';

@Injectable({
  providedIn: 'root'
})
export class UzaverkyService {
  constructor(
    private http: HttpClient
  ) { }


  private api_list: string = "api/vyroba/Uzaverka/List";
  private api_init: string = "api/vyroba/Uzaverka/Init";
  private api_change_date: string = "api/vyroba/Uzaverka/ChangeDate";
  private api_tree_of_guids: string = 'api/vyroba/Uzaverka/TreeOfGuis';
  private api_delete: string = 'api/vyroba/Uzaverka/Delete';


  /**
   * Seznam uzávěrek.
  **/
  public list(): Observable<DVyrUzaverkyListDto[]> {
    return this.http.get<DVyrUzaverkyListDto[]>(this.api_list);
  }


  /**
   * Inicializace nových uzávěrek.
   * @param initDto Dto s daty.
   * @param orgUrGuid Guid organizační úrovně.
   */
  public init(initDto: DVyrUzaverkyInitDto, orgUrGuid: string): Observable<ResultInfoDto> {
    var param = new HttpParams();
    param = param.append("orgUrGuid", orgUrGuid);
    return this.http.post<ResultInfoDto>(this.api_init, initDto, { params: param });
  }


  /**
   * Změna data uzávěrky pro vybranou roli.
   * @param role Název role.
   * @param datum Nové datum uzávěrky.
   * @param guids Seznam guidů měněných uzávěrek.
   */
  public changeDate(role: string, datum: Date, guids: string[]): Observable<ResultInfoDto> {
    var param = new HttpParams();
    if (datum != void 0) {
      param = param.append("date", datum.toISOString());
    }
    return this.http.post<ResultInfoDto>(this.api_change_date + '/' + role, guids, { params: param });
  }


  /**
   * Vrátí seznam guidů od rodiče po všechny potomky.
   * @param guid
   */
  public getTreeOfGuids(guid: string): Observable<string[]> {
    return this.http.get<string[]>(this.api_tree_of_guids + '/' + guid);
  }

  /**
   * Smazání uzávěrek
   * @param guids guidy vybraných uzávěrek
   */
  delete(guids: string[]): Observable<ResultInfoDto> {
    var param = new HttpParams();
    guids.forEach(function (guid) {
      param = param.append('guids', guid);
    });    
    return this.http.delete<ResultInfoDto>(this.api_delete, { params: param });
  }
}
