import { Component, Input } from '@angular/core';
import { BzlDetailDto } from '../../../Dto/Lhp/BzlDetailDto';

@Component({
  selector: 'app-kniha-detail-bzl',
  templateUrl: './kniha-detail-bzl.component.html',
  styleUrls: ['./kniha-detail-bzl.component.css']
})
export class KnihaDetailBzlComponent {

  @Input() bzl: BzlDetailDto;

}
