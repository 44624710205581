import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ResultInfoGenericDto } from 'src/app/Dto/Shared/ResultInfoDto';
import { LhcListAdminDto } from '../../Dto/Lhp/LhcListAdminDto';

@Injectable({
  providedIn: 'root'
})
export class ImportService {

  private apiRunImportUrl = 'api/lhp/Import/Run';

  @Output() lhcAdd: EventEmitter<LhcListAdminDto> = new EventEmitter<LhcListAdminDto>();

  constructor(
    private http: HttpClient
  ) { }

  /**
   * 
   * Spustí import
   * @param guid - guid LHC, pod kterým je uložený spoubor v temp
   * @param fileName - název importovaného souboru
   * @param zakaznikGuid - Guid zákazníka
   * @param poznamka - poznámka k importovanému LHC
   */
  run(guid: any, fileName: string, zakaznikGuid: string, poznamka: string): Observable<ResultInfoGenericDto<LhcListAdminDto>> {
    var param = new HttpParams();
    param = param.append("guid", guid);
    param = param.append("fileName", fileName);
    param = param.append("zakaznikGuid", zakaznikGuid);
    param = param.append("poznamka", poznamka == void 0 ? '' : poznamka);
    return this.http.post<ResultInfoGenericDto<LhcListAdminDto>>(this.apiRunImportUrl, null, { params: param })
      .pipe(
        tap((lhc) => {
          if (lhc && lhc.data) {
            this.lhcAdd.emit(lhc.data);
          }
        })
      );
  }

}
