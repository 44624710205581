export class KubirovaniStatistikaListDto {
  drevinaGuid: string;
  drevinaPopis: string;
  sortimentGuid: string;
  sortimentPopis: string;
  mnozstvi: number;
  prHmOdd: number;
  prHmCelk: number;
  oddenkyKs: number;
  celkemKs: number;
}
