import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { KnihaService } from 'src/app/Services/Lhp/kniha.service';
import { OpDetailDto } from 'src/app/Dto/Lhp/OpDetailDto';
import { KnihaBase } from '../kniha-base';


@Component({
  selector: 'app-kniha-op',
  templateUrl: './kniha-op.component.html',
  styleUrls: ['./kniha-op.component.css']
})
export class KnihaOpComponent extends KnihaBase {

  constructor(
    route: ActivatedRoute,
    knihaService: KnihaService
  ) {
    super(route, knihaService);
  }

  model: OpDetailDto;

  loadModel() {
    this.knihaService.getOp(this.lhcKod, this.odd, this.dil, this.por, this.op, this.katuzeKod, this.guid)
      .subscribe(response => {
        this.model = response;
        this.opGuid = this.model.guid;
        this.porGuid = this.model.lhpGuids.porGuid;
        this.dilGuid = this.model.lhpGuids.dilGuid;
        this.oddGuid = this.model.lhpGuids.oddGuid;
        this.lhcGuid = this.model.lhpGuids.lhcGuid;
        this.constructBreadCrumbs();
      });
  }
}
