<div id="etaz" class="p-grid" [ngClass]="{'label-of-disabled-field':disabled}">
  <div class="p-col">
    <label>LHC</label>
    <app-lhc-autocomplete [(ngModel)]="lhcGuid" [disabled]="disabled" (onChange)="lhcChanged($event)" [edit]="edit"
                          [focus]="focusLhc" [lhcInputId]="lhcInputId" [orgUrGuid]="orgUrGuid"></app-lhc-autocomplete>
  </div>
  <div class="p-col">
    <label>JPRL</label>
    <app-jprl-autocomplete [jprl]="jprl" [lhcGuid]="lhcGuid" [disabled]="disabled" [jprlInputId]="jprlInputId"
                            (onChange)="onJprlChange($event)" [edit]="edit"></app-jprl-autocomplete>
</div>
  <div class="p-col">
    <label>Etáž</label>
    <div [ngClass]="visibileJprlBtn?'jprl-btn-container':''" *ngIf="edit">
      <p-autoComplete #etazAutocomplete ([ngModel])="etaz" [suggestions]="etazeNavrhy" (completeMethod)="hledej($event)" field="etaz"
                      (onBlur)="onBlur($event)" (onSelect)="onSelect($event)"
                      appendTo="body" [disabled]="disabled" [inputId]="etazInputId"
                      [styleClass]="visibileJprlBtn?'jprl-input-width-min':'jprl-input-width-max'"
                      [ngClass]="{'invalid-input-conditional': alert, 'neutral-input-conditional': !alert}"
                      [inputStyle]="{'width':'100%'}"></p-autoComplete>
      <button *ngIf="visibileJprlBtn" pButton style="float: right;" title="Otevřít etáž v knize" type="button" (click)="openEtaz()" [disabled]="!value" class="icon-btn hide-label-button">
        <fa-icon [icon]="faBook"></fa-icon>
      </button>
    </div>
    <div class="p-inputtext ui-widget ui-state-default ui-corner-all ui-div-inputtext" *ngIf="!edit" title="{{etaz?.etaz}}">{{etaz?.etaz}} </div>
    </div>
  </div>
<p-confirmDialog #cd header="Otevřít etáž" icon="pi pi-info-circle">
  <p-footer>
    <button type="button" pButton icon="pi pi-times" label="Ne" (click)="cd.reject()"></button>
    <button type="button" pButton icon="pi pi-check" label="Ano" (click)="cd.accept()"></button>
  </p-footer>
</p-confirmDialog>
