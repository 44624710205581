import { Injectable, Pipe, PipeTransform } from "@angular/core";

/**
 * Formát plochy náčrtu. Přidává jednotky a zaokrouhluje na 4 desetinná místa.
**/
@Pipe({
  name: 'formatPlocha'
})
@Injectable({
  providedIn: 'root'
})
export class FormatPlochaPipe implements PipeTransform {
  /**
   * Formátování hodnoty.
   * @param value {number} hodnota, se kterou se pracuje
   * @param args {any[]} prvním (a jediným) parametrem je číslo, kterým se zpracovávaná hodnota vydělí pro přepočet jednotek (např. 10000 při přepočtu "m2" na "ha")
   */
  transform(value: number, ...args: any[]): any {
    //pokud je vstup v jiných jednotkách než "ha", provede se přepočet předaným parametrem.
    if (args[0] != void 0) {
      value = value / args[0];
    }
    //protože se zde pracuje i s plochou počítanou na klientu (např. při editaci náčrtu), je potřeba zaokrouhlovat i zde.
    let rounded = Math.round(value * 10000) / 10000;
    //metodat "toLocaleString" by default zobrazuje pouze 3 desetinná místa
    return rounded.toLocaleString('cs-CZ', { maximumFractionDigits: 4 }) + ' ha';
  }
}
