import { Component, OnInit, OnDestroy } from '@angular/core';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { IPriloha } from '../../../Dto/Nacrty/IPriloha';
import { NacrtPrilohyChangedArgs } from '../../../Dto/Nacrty/NacrtPrilohyChangedArgs';
import { NacrtyInteractionService } from '../../../Services/Nacrty/nacrty-interaction.service';

@Component({
  selector: 'app-nacrt-priloha-audio-edit',
  templateUrl: './nacrt-priloha-audio-edit.component.html',
  styleUrls: ['./nacrt-priloha-audio-edit.component.css']
})
export class NacrtPrilohaAudioEditComponent implements OnInit, OnDestroy {

  constructor(
    private confirmationService: ConfirmationService,
    private nacrtyInteractionService: NacrtyInteractionService)
  { }

  ngOnInit() {
    this._onInitEdit = this.nacrtyInteractionService.OnInitEdit.subscribe(this._procesPrilohy.bind(this));
    this._onAddSubs = this.nacrtyInteractionService.OnAdd.subscribe(this._procesPrilohy.bind(this));
    this._onDeleteSubs = this.nacrtyInteractionService.OnDelete.subscribe(this._onDeleteHandler.bind(this));
  }


  ngOnDestroy() {
    this._onInitEdit.unsubscribe();
    this._onAddSubs.unsubscribe();
    this._onDeleteSubs.unsubscribe();
  }


  readonly CONFIRM_KEY: string = 'audioDeleteConfirm';
  faTrash = faTrash;
  private _onAddSubs: Subscription;
  private _onInitEdit: Subscription;
  private _onDeleteSubs: Subscription;


  /**
   * Kolekce zobrazených příloh.
  **/
  audioSources: IPriloha[] = [];


  /**
   * Handler inicializace příloh
   * @param args {NacrtPrilohyChangedArgs} argumenty příloh náčrtu
   */
  private _procesPrilohy(args: NacrtPrilohyChangedArgs): void {
    this.audioSources = args.prilohy.filter(x => x.typ == 'ZVUK' && !x.sync.d)
      .map(x => { return { nazev: x.souborNazev, src: 'data:audio/mp3;base64,' + x.soubor, id: x.guid }; });
  }


  /**
   * Handler události o smazání přílohy.
   * @param args {NacrtPrilohyChangedArgs} argumenty příloh náčrtu
   */
  private _onDeleteHandler(args: NacrtPrilohyChangedArgs): void {
    if (args.deleted != void 0 && args.deleted.typ == 'ZVUK') {
      this._procesPrilohy(args);
    }
  }


  /**
   * Smazání audio záznamu.
   * @param deletedItem {IPriloha} Mazaný záznam.
   */
  delete(deletedItem: IPriloha): void {
    this.confirmationService.confirm({
      target: event.currentTarget,
      key: this.CONFIRM_KEY,
      message: 'Opravdu smazat zvukový záznam?',
      icon: 'pi pi-exclamation-triangle',
      accept: this._deleteAccepted.bind(this, deletedItem),
      acceptLabel: 'Smazat',
      reject: () => { },
      rejectLabel: 'Zrušit'
    });
  }


  /**
   * Akce pro smazání audio záznamu po potvrzení.
   * @param deletedItem {IPriloha} mazaný záznam
   */
  private _deleteAccepted(deletedItem: IPriloha): void {
    this.nacrtyInteractionService.delete(deletedItem);
  }
}
