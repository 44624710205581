import { ViewChild } from '@angular/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import { Feature } from 'ol';
import WKB from 'ol/format/WKB';
import { MultiPolygon, Polygon } from 'ol/geom';
import { MessageService } from 'primeng/api';
import { FeatureDataDto } from '../../../Dto/Nacrty/FeatureDataDto';
import { NacrtMapItemDto, NacrtMapItemPostSaveDto } from '../../../Dto/Nacrty/NacrtMapItemDto';
import { LayersInteractionService } from '../../../Services/Mapa/layers-interaction.service';
import { MapInteractionService } from '../../../Services/Mapa/map-interaction.service';
import { MapServices } from '../../../Services/Mapa/map.service';
import { NacrtySchvalitInteractionService } from '../../../Services/Nacrty/nacrty-schvalit-interaction.service';
import { SyncService } from '../../../Services/Sync/sync.service';
import { ByteArrayUtils } from '../../../Utils/Mapa/byte-array.utils';
import { ColorPalleteUtils } from '../../../Utils/Mapa/color-pallete.utils';
import { ConstructMapLayerUtils } from '../../../Utils/Mapa/construnct-map-layer.utils';
import { UuidUtils } from '../../../Utils/Shared/uuid.utils';
import { OverViewMapComponent } from '../../Mapa/overview-map/overview-map.component';

/**
 * Komponenta dělení multipolygonů na samostatné polygony.
**/
@Component({
  selector: 'app-multipolygon-explode',
  templateUrl: './multipolygon-explode.component.html',
  styleUrls: ['./multipolygon-explode.component.css'],
  providers: [MapInteractionService, NacrtySchvalitInteractionService, LayersInteractionService] //https://angular.io/guide/providers#limiting-provider-scope-with-components
})
export class MultipolygonExplodeComponent {
  
  /**
   * Data náčrtu.
  **/
  _item: NacrtMapItemDto = null;
  @Input() set item(value: NacrtMapItemDto) {
    if (value != void 0) {
      this._item = value;
    }
  }


  /**
   * Událost předávající nově vytvořený polygon.
  **/
  @Output() ExplodedPolygon: EventEmitter<NacrtMapItemPostSaveDto> = new EventEmitter<NacrtMapItemPostSaveDto>();


  /**
   * Komponenta obsahující přehledovou mapu s rozděleným multipolygonem.
  **/
  @ViewChild('explodeMapOverview') explodeMap: OverViewMapComponent;


  constructor(
    private byteArrayUtils: ByteArrayUtils,
    private uuidUtils: UuidUtils,
    private syncService: SyncService,
    private mapService: MapServices,
    private messageService: MessageService,
    private constructUtils: ConstructMapLayerUtils,
    private colorPalleteUtils: ColorPalleteUtils)
  { }

  readonly TOAST_KEY: string = 'exploadToast';
  faQuestion = faQuestion;
  //viditelnost dialogu
  showDlg: boolean = false;
  //data polygonů vzniklých rozdělením zdrojového multipolygonu
  explodedData: FeatureDataDto[] = [];
  //data featur vzniklých rozdělením zdrojového multipolygonu
  features: Feature<MultiPolygon>[] = [];
  //vybrané řádky v seznamu nově vzniklých polygonů - vybrané budou uloženy
  selectedPolygons: FeatureDataDto[] = [];
  //blocker při ukládání
  blocked: boolean = false;


  /**
   * Rozdělení multipolygonu na části.
  **/
  private _explode(item: NacrtMapItemDto): void {
    if (item == void 0) return;

    let f: Feature<MultiPolygon> = this.constructUtils.convertFromSource([item])[0];
    let polygons: Polygon[] = (f.getGeometry() as MultiPolygon).getPolygons();
    var c = 0;
    let exploded: FeatureDataDto[] = [];
    polygons.forEach(p => {
      c++;
      let pf: Feature<MultiPolygon> = new Feature<MultiPolygon>(new MultiPolygon([p]));
      let id = this.uuidUtils.GenerateUuid();
      pf.setId(id); //id nového náčrtu

      let color = this.colorPalleteUtils.explodeColor(c);
      pf.set('barva', color);
      pf.set('popis', c);

      exploded.push({
        id: id,
        feature: pf,
        poradoveCislo: c,
        plocha: p.getArea(),
        popis: item.popis,
        color: color
      });
    });

    this.explodedData = exploded;
    this.features = this.explodedData.map(x => x.feature);
  }


  /**
   * Zobrazení dialogu rozdělení multipolygonu.
  **/
  public show(): void {
    this.showDlg = true;
  }


  /**
   * Handler zobrazení dialogu. Mapa se musí vytvořit až když je html element, do kterého se mapa generuje, vykreslen - tedy po zobrazení dialogu.
  **/
  onShowHandler(): void {
    this.explodeMap.init();
    this._explode(this._item);
  }


  /**
   * Handler skytí dialogu.
  **/
  onHideHandler(): void {
    this.explodedData = [];
    this.explodeMap.clearLayer();
  }


  /**
   * Handler změny vybraných náčrtů po rozdělění původního multipolygonu.
   * @param event {FeatureDataDto[]} vybraná data
   */
  onSelectionChanged(event: FeatureDataDto[]): void {
    this.selectedPolygons = event;
  }


  /**
   * True, pokud jsou data validní a lze uložit nově vzniklé polygony.
  **/
  get canSave(): boolean {
    return this.selectedPolygons.length > 0 && this.selectedPolygons.filter(x => x.popis == '').length == 0;
  }


  /**
   * Uložení vybraných polygonů.
  **/
  save(): void {
    if (this.selectedPolygons.length > 0) {
      if (this.selectedPolygons.filter(x => x.popis == '').length > 0) {
        this.messageService.add({ summary: 'Chyba', severity: 'error', detail: 'U některého náčrtů k uložení není vyplněný povinný popis.', key: this.TOAST_KEY });
        return;
      }

      this.blocked = true;
      let format = new WKB({ hex: false, ewkb: false });
      let syncData: any[] = [];
      this.selectedPolygons.forEach(x => {
        let wkb = format.writeGeometry(x.feature.getGeometry());

        syncData.push({
          nacrt: {
            guid: x.id,
            akce: [],
            nacrtProjektGuid: this._item.projektGuid,
            popis: x.popis,
            poznamka: ''
          },
          nacrtGeometry: {
            guid: this.uuidUtils.GenerateUuid(),
            wkb: this.byteArrayUtils.ToBase64(wkb as ArrayBuffer)
          },
          type: 'DNacrt',
          sync: {
            i: x.id,
            u: 0,
            h: [],
            d: false
          }
        });
      });

      this.syncService.post(syncData).subscribe(resp => {
        if (resp.processedSuccessfully.length > 0) {
          this._savePostProcess(resp.processedSuccessfully);
        }
        else {
          if (resp.isError) {
            let messages = resp.errors.map(err => { return { severity: 'error', summary: 'Chyba', detail: err.text, key: this.TOAST_KEY }; });
            this.messageService.addAll(messages);
          }
          else if (resp.isConflict) {
            this.messageService.add({
              key: this.TOAST_KEY, summary: 'Konflikt', severity: 'error',
              detail: 'Při ukládání náčrtu došlo ke konfliktu. Prosím obnovte stránku a proveďte změny znovu.'
            });
          }
        }
      });
    }
    else {
      this.showDlg = false;
    }
  }


  /**
   * Post process uložení polygonů vzniklých rozbitím multipolygonu.
   * @param guids {string} Id úspěšně uložených polygonů
   */
  private _savePostProcess(guids: string[]): void {
    let c = 0;
    guids.forEach(guid => {
      this.mapService.getMapItemById(guid, 'Nacrty').subscribe(mapResp => {
        c++;
        mapResp['targetLayerName'] = 'nacrty_' + this._item.projektGuid;
        this.ExplodedPolygon.emit(mapResp as NacrtMapItemPostSaveDto);
        if (c == guids.length) {
          this.blocked = false;
          this.showDlg = false;
        }
      });
    })
  }


  /**
   * Handler ukonžené změny velikosti dialogového okna.
  **/
  dlgResizeEndHandler(): void {
    this.explodeMap.resize();
  }
}
