import { Component, OnInit, ViewChild, TemplateRef, EventEmitter, Output, Input } from '@angular/core';
import { VyrobaService } from 'src/app/Services/Vyroba/vyroba.service';
import { OliListDto } from 'src/app/Dto/Vyroba/OliListDto';
import { IDokladFilter } from '../vyroba/vyroba.component';
import { TableComponent } from '../../Shared/table/table.component';

/**
 * Komponenta výrobního dokladu odvozního lístku.
**/
@Component({
  selector: 'app-vyroba-ol',
  templateUrl: './vyroba-ol.component.html',
  styleUrls: ['./vyroba-ol.component.css']
})
export class VyrobaOlComponent implements OnInit {

  @ViewChild("colDatum", { static: true }) datumTemplate: TemplateRef<any>;
  @ViewChild("colDatumCas", { static: true }) datumCasTemplate: TemplateRef<any>;
  @ViewChild("colCiselnik", { static: true }) ciselnikTemplate: TemplateRef<any>;
  @ViewChild("colOrgUr", { static: true }) orgUrTemplate: TemplateRef<any>;
  @ViewChild("colVykon", { static: true }) vykonTemplate: TemplateRef<any>;
  @ViewChild('olTable', { static: true }) table: TableComponent;

  @Output() dataLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() selectionChange: EventEmitter<OliListDto[]> = new EventEmitter<OliListDto[]>();
  @Output() years: EventEmitter<number[]> = new EventEmitter<number[]>();

  constructor(
    private vyrobaService: VyrobaService
  ) { }

  doklady: OliListDto[];
  dokladyAll: OliListDto[];
  cols: any[] = [];

  @Input() eQFilter: string;

  ngOnInit(): void {
    this.initCols();
    this.loadDoklady();
  }

  /**
   * Inicializace sloupců gridu - je potřeba provést až v ngOnInit aby byly dostupné template reference
   */
  private initCols() {
    this.cols = [
      { field: 'cislo', header: 'Č.', width: '5%' },
      { field: 'datum', header: 'Datum', width: '8%', class: 'datum', template: this.datumTemplate },
      { field: 'orgUrGuid', header: 'Org. ur.', width: '23%', template: this.orgUrTemplate },
      // příprava - komponentu na výkon kod udělám samostatně { field: 'vykon', header: 'V', width: '6%', template: this.vykonTemplate },
      { field: 'podvykonGuid', header: 'PV', width: '6%', template: this.ciselnikTemplate, ciselnik: 'CPodvykon' },
      { field: 'lokalitaGuid', header: 'Lokalita', width: '6%', template: this.ciselnikTemplate, ciselnik: 'CLokalita' },
      { field: 'odberatelGuid', header: 'Odběratel', width: '6%', template: this.ciselnikTemplate, ciselnik: 'COdberatel' },
      { field: 'stavKod', header: 'Stav kód', width: '4%' },
      { field: 'stavPopis', header: 'Stav popis', width: '6%' },
      { field: 'vlozil', header: 'Vložil', width: '10%' },
      { field: 'vlozilKdy', header: 'Datum vložení', width: '5%', class: 'datum', template: this.datumCasTemplate },
      { field: 'opravil', header: 'Opravil', width: '10%' },
      { field: 'opravilKdy', header: 'Datum opravy', width: '5%', class: 'datum', template: this.datumCasTemplate }
    ];
  }


  /**
   * Handler změny výběru dokladů.
   * @param event
   */
  selectionChangeHandler(event): void {
    this.selectionChange.emit(event);
  }

  _filter: IDokladFilter;
  @Input() set filter(value: IDokladFilter) {
    if (value != void 0 && JSON.stringify(value) != JSON.stringify(this._filter)) {
      this._filter = value;
      this.filtruj();
    }
  }

  /**
   * Filtrace dokladů rychlým filtrem dle měsíce a roku.
   */
  private filtruj(): void {
    if (this.dokladyAll == void 0) return;

    if (this._filter != void 0) {
      this.doklady = this.dokladyAll.filter(d =>
        this._filter.roky.indexOf(d.datum.getFullYear()) > -1
        && this._filter.mesice.indexOf(d.datum.getMonth() + 1) > -1
      );
    }
    else {
      this.doklady = this.dokladyAll;
    }
  }


  /**
   * Načtení seznamu dokladů.
   */
  loadDoklady(): void {
    this.vyrobaService.getOliList(this.eQFilter).subscribe(resp => {
      if (resp != void 0 && resp.success) {
        this.dokladyAll = resp.data;

        //TODO: časem asi předělat na WebApi
        let uniqueYears = [...new Set(this.dokladyAll.map(x => x.datum.getFullYear()))];
        this.years.emit(uniqueYears);
        this.filtruj();

        this.dataLoaded.emit(true);
      }
      else {
        this.dokladyAll = [];
        this.filtruj();
        this.dataLoaded.emit(false);
      }
    });
  }


  /**
   * Trochu zneužitý Input pro informování child komponenty,
   * že má provést reload dokladů. Nelze zde použít přímé volání
   * metody z child komponenty, protože je existence child komponenty podmíněna *ngIf.
   */
  @Input() set reload(value: boolean) {
    if (value == true) {
      this.loadDoklady();
    }
  }




  /**
   * Id dokladu, který se má v tabulce vybrat.
  **/
  @Input() set dokladGuid(value: string) {
    if (value != void 0 && this.table != void 0) {
      if (this.table.loading) {
        this.table.dataLoaded.subscribe(_ => {
          this.table.selectRowByGuid(value);
        });
      }
      else {
        this.table.selectRowByGuid(value);
      }
    }
  }

  /**
   * Handler seřazení tabulky
   * */
  onSort(): void {
    this.table.setPage();
  }
}
