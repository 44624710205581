import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LhpDrevinaProVypocetDto } from 'src/app/Dto/Skody/LhpDrevinaProVypocetDto';
import { LhpEtazProVypocetDto } from 'src/app/Dto/Skody/LhpEtazProVypocetDto';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { SkodyDetailSBaseComponent } from '../skody-detail-s-base/skody-detail-s-base.component';


/**
 * Komponenta detailu škody typu S9.1.
**/
@Component({
  selector: 'skody-detail-s91',
  templateUrl: './skody-detail-s91.component.html',
  styleUrls: ['./skody-detail-s91.component.css']
})
export class SkodyDetailS91Component extends SkodyDetailSBaseComponent implements OnInit {

  constructor(
    fb: FormBuilder
  ) {
    super(fb);
  }


  data: FormGroup = this.fb.group({
    guid: [''],
    typ: [''],
    plocha: [''],
    vek: [''],
    bonR: [''],
    imise: [''],
    upravaHlpa: [''],
    np: [''],
    nHa: [''],
    zakmU: [''],
    zast: [''],
    obmyti: ['']
  });


  ngOnInit() {
    this.data.valueChanges
      .pipe(
        debounceTime(600),
        distinctUntilChanged((prev, curr) => prev.guid === curr.guid && prev.plocha === curr.plocha
          && prev.plochaVypocet === curr.plochaVypocet && prev.vek === curr.vek && prev.bonR === curr.bonR && prev.imise === curr.imise
          && prev.upravaHlpa === curr.upravaHlpa && prev.np === curr.np && prev.nHa === curr.nHa
          && prev.zakmU === curr.zakmU && prev.zast === curr.zast && prev.obmyti === curr.obmyti),
        filter(data => this.validateVstupyProPrepocet(data)),
      )
      .subscribe(() => {
        this.dataChanged.emit(this.data.value);
      });
  };


  /**
   * Kontrola dat z S91 pro přepočet škody.
   * @param data
   */
  validateVstupyProPrepocet(data): boolean {
    return data.plocha != void 0
      && data.zast != void 0
      && data.nHa != void 0
      && data.bonR != void 0
      && data.vek != void 0
      && data.obmyti != void 0
      && data.zakmU != void 0
      && data.np != void 0;
  };


  /**
   * Zkopíruje data etáže z LHP do vstupu.
  **/
  kopirujLhpEtazDoVstupu(dto: LhpEtazProVypocetDto): void {
    if (dto != void 0) {
      this.data.get('plocha').setValue(dto.etazPs);
      this.data.get('vek').setValue(dto.vek);
      this.data.get('obmyti').setValue(dto.obmyti);
    }
  };

  /**
   * Zkopíruje data dřeviny z LHP do vstupu.
  **/
  kopirujLhpDrevinuDoVstupu(dto: LhpDrevinaProVypocetDto): void {
    if (dto != void 0) {
      this.data.get('bonR').setValue(dto.bonR);
      this.data.get('imise').setValue(dto.imise);
      this.data.get('zast').setValue(dto.zast);
    }
  };
}
