<p-toast position="top-center" key="user-list-toast"></p-toast>

<app-block-ui [block]="block"></app-block-ui>
<div class="list-user-body">
  <p-toolbar styleClass="base-toolbar" [style]="{'margin-bottom': '1em'}">
    <div class="p-toolbar-group-left">
      <h2 class="toolbar-label">Uživatelé</h2>
      <i class="p-toolbar-separator"><fa-icon [icon]="faEllipsisV"></fa-icon></i>
      <app-zakaznik-dropdown [(ngModel)]="selectedZakaznik" [mode]="accessMode" (ngModelChange)="onZakaznikChange($event)"></app-zakaznik-dropdown>
      <div style="display: inline-block">
        <div class="p-inputgroup">
          <input type="text" pInputText placeholder="Hledat" [(ngModel)]="filter" style="width: 20em" />
          <button pButton type="button" class="icon-btn hide-label-button" (click)="filter = undefined" [disabled]="filter == undefined"><fa-icon [icon]="faBackspace"></fa-icon></button>
        </div>
      </div>

      <button pButton [disabled]="selectedUzivatel != undefined || selectedZakaznik == undefined || showForm" type="button" label="Nový" class="icon-btn" (click)="add()"><fa-icon [icon]="faPlus"></fa-icon></button>
    </div>
    <div class="p-toolbar-group-right">
      <button pButton type="button" label="Zpět" class="icon-btn" (click)="back()"><fa-icon [icon]="faUndo"></fa-icon></button>
    </div>
  </p-toolbar>


  <p-table [columns]="uzivatelCols" [value]="uzivatelItems" [scrollable]="true" [rows]="100" scrollHeight="flex" [ngStyle]="{'height': '100%'}"
           [virtualScroll]="true" [virtualRowHeight]="34" selectionMode="single" [(selection)]="selectedRows"
           (onRowSelect)="onRowSelected($event.data)" (onRowUnselect)="onRowUnselected($event.data)"
           styleClass="p-datatable-gridlines p-datatable-striped pds-table-compact">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns">
          {{col.header}}
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr style="height:34px" [pSelectableRow]="rowData">
        <td *ngFor="let col of columns">
          <div *ngIf="col.template">
            <ng-container *ngTemplateOutlet="col.template; context: {$implicit: rowData, column: col}"></ng-container>
          </div>
          <div *ngIf="!col.template">{{rowData[col.field]}}</div>
        </td>
      </tr>
    </ng-template>
  </p-table>

  <ng-template #orgUr let-rowData let-col="column">
    <app-org-ur-display mode="admin" [zakaznikGuid]="selectedZakaznik?.guid" [guid]="rowData.orgUrGuid"></app-org-ur-display>
  </ng-template>
  <div class="list-user-edit" style="height:calc(50vh - 51px)" *ngIf="showForm">
    <p-panel style="height:100%">
      <ng-template pTemplate="header">
        <div class="p-grid p-align-center">
          <div class="p-col"><span class="p-panel-title">Uživatel — {{data.userName}}</span></div>
          <div class="p-col right">
            <button pButton [disabled]="!form.valid" type="button" label="Uložit" class="icon-btn p-button-success" (click)="save()"><fa-icon [icon]="faSave"></fa-icon></button>
            <button pButton type="button" label="Zrušit" class="icon-btn" (click)="cancel()"><fa-icon [icon]="faTimes"></fa-icon></button>
          </div>
        </div>
      </ng-template>
      <div class="p-grid validated-form-container" [formGroup]="form">
        <div class="edit-left-site p-col-6">
          <p-card header="Přihlašovací údaje" [style]="{'margin-bottom': '1rem'}">
            <div class="p-grid">
              <div class="p-col-4">
                Přihlašovací jméno
                <input type="text" pInputText formControlName="userName" />
              </div>
              <div class="p-col-8">
                <app-validated-password formControlName="password"></app-validated-password>
              </div>
            </div>
          </p-card>
          <p-card header="Kontaktní údaje">
            <div class="p-grid">
              <div class="p-col-3">
                Jméno
                <input type="text" pInputText formControlName="firstName" />
              </div>
              <div class="p-col-3">
                Přijmení
                <input type="text" pInputText formControlName="lastName" />
              </div>
              <div class="p-col-3">
                Email
                <input type="text" pInputText formControlName="email" />
              </div>
              <div class="p-col-3">
                Telefonní číslo
                <input type="text" pInputText formControlName="phoneNumber" />
              </div>
            </div>
          </p-card>
        </div>
        <div class="edit-right-site p-col-3">
          <p-card header="Organizační úrovně">
            Domovská organizační úroveň
            <app-org-ur variant="medium" allLevelsSelectable="true" [mode]="accessMode"
                        [zakaznikGuid]="selectedZakaznik?.guid"
                        [useAll]="true"
                        formControlName="orgUrGuid"></app-org-ur>

            <div class="orgur-multiselect">
              <app-org-ur-multi-select [zakaznikGuid]="selectedZakaznik?.guid" mode="admin" formControlName="orgUrGuids" header="Další organizační úrovně"></app-org-ur-multi-select>
            </div>
          </p-card>
        </div>
        <div class="edit-right-site p-col-3">
          <p-card header="Role">
            <p-listbox [options]="roles" multiple="true" [checkbox]="true" [filter]="true"
                       optionLabel="popis" formControlName="role" listStyleClass="role-list"></p-listbox>
          </p-card>
        </div>
      </div>
    </p-panel>
  </div>
</div>
