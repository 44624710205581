<propla-table #olTable [cols]="cols" [allItems]="doklady" [checkboxes]="true" sortField="opravilKdy" sortOrder="-1"
              (selectionChanged)="selectionChangeHandler($event)" (sorted)="onSort()"></propla-table>

<ng-template #colDatum let-rowData let-col="column">
  {{rowData[col.field] | date:'d.M.yyyy'}}
</ng-template>

<ng-template #colDatumCas let-rowData let-col="column">
  {{rowData[col.field] | date:'d.M.yyyy HH:mm'}}
</ng-template>

<ng-template #colCiselnik let-rowData let-col="column">
  <app-ciselnik-kod [guid]="rowData[col.field]" [ciselnik]="col.ciselnik"></app-ciselnik-kod>
</ng-template>

<ng-template #colVykon let-rowData>
  ?
</ng-template>

<ng-template #colOrgUr let-rowData>
  <app-org-ur-display [guid]="rowData['orgUrGuid']"></app-org-ur-display>
</ng-template>
