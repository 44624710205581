<p-toast position="top-center" key="{{TOAST_KEY}}"></p-toast>
<p-confirmDialog key="{{CONFIRM_KEY}}" [closable]="false"></p-confirmDialog>


<p-table #nacrtyTable [selectionMode]="selectionMode" [value]="nacrty" dataKey="id"
         [paginator]="true" [rows]="10" [rowsPerPageOptions]="[10,20,30]"
         [globalFilterFields]="['jprl','popis', 'projekt', 'poznamka']"
         [(selection)]="selectedNacrty"
         (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)"
         styleClass="p-datatable-gridlines p-datatable-striped pds-table-compact">
  <ng-template pTemplate="caption">
    <div class="base-toolbar">
      <div class="p-toolbar-group-left">
        <h2 class="toolbar-label">Náčrty</h2>
        <i class="p-toolbar-separator"><fa-icon [icon]="faEllipsisV"></fa-icon></i>
        <div style="display: inline-block">
          <div class="p-inputgroup p-input-icon-left p-ml-2">
            <i class="pi pi-search"></i>
            <input #globalFilter pInputText type="text" (input)="nacrtyTable.filterGlobal($event.target.value, 'contains')"
                   placeholder="Hledat" style="width: 20em" />
            <button pButton type="button" class="icon-btn hide-label-button"
                    (click)="globalFilter.value = '';nacrtyTable.filterGlobal(undefined, 'contains');" [disabled]="globalFilter.value == ''">
              <fa-icon [icon]="faBackspace"></fa-icon>
            </button>
          </div>
        </div>
        <button pButton type="button" label="Smazat" class="icon-btn" [disabled]="!canDelete"
                pTooltip="Smazat vybrané náčrty" tooltipPosition="bottom"
                (click)="delete()">
          <fa-icon [icon]="faTrash"></fa-icon>
        </button>
      </div>
      <div class="p-toolbar-group-right">
        <button pButton type="button" label="Zpět" class="icon-btn" (click)="back()"><fa-icon [icon]="faUndo"></fa-icon></button>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th width="2%"><p-tableHeaderCheckbox *ngIf="canEdit"></p-tableHeaderCheckbox></th>
      <th width="5%">Typ</th>
      <th width="18%" pSortableColumn="popis">Popis <p-sortIcon field="popis"></p-sortIcon></th>
      <th width="8%" pSortableColumn="jprl">JPRL <p-sortIcon field="jprl"></p-sortIcon></th>
      <th width="5%" pTooltip="Index holiny" tooltipPosition="top" pSortableColumn="cislo">IH <p-sortIcon field="cislo"></p-sortIcon></th>
      <th width="8%">Plocha/Obvod/Délka</th>
      <th width="13%" pSortableColumn="projekt">Projekt <p-sortIcon field="projekt"></p-sortIcon></th>
      <th width="5%" pTooltip="Počet příloh" tooltipPosition="top"><fa-icon [icon]="faPaperclip" style="font-size: 1.2rem;" pSortableColumn="prilohyCount"></fa-icon> <p-sortIcon field="prilohyCount"></p-sortIcon></th>
      <th width="7%" pSortableColumn="vytvoril">Vytvořil <p-sortIcon field="vytvoril"></p-sortIcon></th>
      <th width="11%" pSortableColumn="vytvorenoKdy">Vytvořeno kdy <p-sortIcon field="vytvorenoKdy"></p-sortIcon></th>
      <th width="7%" pSortableColumn="upravil">Upravil <p-sortIcon field="upravil"></p-sortIcon></th>
      <th width="11%" pSortableColumn="upravenoKdy">Upraveno kdy <p-sortIcon field="upravenoKdy"></p-sortIcon></th>
    </tr>
    <tr>
      <th></th>
      <th>
        <p-columnFilter field="geometryType" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <p-multiSelect [(ngModel)]="selectedTypyGeometrii" [options]="typyGeometrii" (onChange)="filter($event.value)" [filter]="false" optionLabel="typ" optionValue="typ">
              <ng-template let-option pTemplate="item">
                <img *ngIf="option.typ == 'Point'" src="assets/vector-point-no-arrow.svg" class="svg-icon" />
                <img *ngIf="option.typ == 'LineString'" src="assets/vector-polyline.svg" class="svg-icon" />
                <img *ngIf="option.typ == 'Polygon' || option.typ == 'MultiPolygon'" src="assets/vector-polygon.svg" class="svg-icon" />
              </ng-template>
            </p-multiSelect>
          </ng-template>
        </p-columnFilter>
      </th>
      <th>
        <p-columnFilter type="text" field="popis" matchMode="contains"></p-columnFilter>
      </th>
      <th>
        <p-columnFilter type="text" field="jprl"></p-columnFilter>
      </th>
      <th>
        <p-columnFilter type="numeric" field="cislo" [showMenu]="false"></p-columnFilter>
      </th>
      <th>

      </th>
      <th>
        <p-columnFilter field="projekt" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <p-multiSelect [(ngModel)]="selectedProjekty" [options]="projekty" (onChange)="filter($event.value)" optionLabel="projekt" optionValue="projekt">
              <ng-template let-option pTemplate="item">
                <p>{{option.projekt}}</p>
              </ng-template>
            </p-multiSelect>
          </ng-template>
        </p-columnFilter>
      </th>
      <th>
        <p-columnFilter type="numeric" field="prilohyCount" [showMenu]="false"></p-columnFilter>
      </th>
      <th>
        <p-columnFilter type="text" field="vytvoril"></p-columnFilter>
      </th>
      <th>
        <p-columnFilter type="date" field="vytvorenoKdy">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <p-calendar [ngModel]="value" dateFormat="d.m.yy" appendTo="body"
                        #vytvorenoKdy
                        (onSelect)="filter(vytvorenoKdy.value)"
                        (onInput)="filter(vytvorenoKdy.value)"></p-calendar>
          </ng-template>
        </p-columnFilter>
      </th>
      <th>
        <p-columnFilter type="text" field="upravil"></p-columnFilter>
      </th>
      <th>
        <p-columnFilter type="date" field="upravenoKdy">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <p-calendar [ngModel]="value" dateFormat="d.m.yy"  appendTo="body"
                        #upravenoKdy
                        (onSelect)="filter(upravenoKdy.value)"
                        (onInput)="filter(upravenoKdy.value)"></p-calendar>
          </ng-template>
        </p-columnFilter>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-nacrt let-rowIndex="rowIndex">
    <tr [pSelectableRow]="nacrt">
      <td style="overflow:inherit;">
        <p-tableCheckbox [value]="nacrt" [index]="rowIndex" *ngIf="canEdit"></p-tableCheckbox>
      </td>
      <td>
        <img *ngIf="nacrt.geometryType == 'Point'" src="assets/vector-point-no-arrow.svg" class="svg-icon" />
        <img *ngIf="nacrt.geometryType == 'LineString'" src="assets/vector-polyline.svg" class="svg-icon" />
        <img *ngIf="nacrt.geometryType == 'Polygon' || nacrt.geometryType == 'MultiPolygon'" src="assets/vector-polygon.svg" class="svg-icon" />
      </td>
      <td>
        {{nacrt.popis}}
      </td>
      <td>
        {{nacrt.jprl}}
      </td>
      <td>
        {{nacrt.cislo}}
      </td>
      <td>
        <span *ngIf="nacrt.geometryType == 'LineString' && nacrt.obvod != undefined">
          {{nacrt.obvod | formatDelka}}
        </span>
        <span *ngIf="(nacrt.geometryType == 'Polygon' || nacrt.geometryType == 'MultiPolygon') && nacrt.plocha != undefined && nacrt.obvod != undefined">
          {{nacrt.plocha | formatPlocha}}/{{nacrt.obvod | formatDelka}}
        </span>
      </td>
      <td>
        {{nacrt.projekt}}
      </td>
      <td>
        {{nacrt.prilohyCount}}
      </td>
      <td>
        {{nacrt.vytvoril}}
      </td>
      <td>
        {{nacrt.vytvorenoKdy | formatDateTime}}
      </td>
      <td>
        {{nacrt.upravil}}
      </td>
      <td>
        {{nacrt.upravenoKdy | formatDateTime}}
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="paginatorright" let-state>
    <div class="paginator-right">
      Zobrazeno {{state.totalRecords < 1 ? 0 : state.first + 1}} -
      {{
      state.totalRecords > 0 ? ((state.rows+ state.first) <= state.totalRecords ? (state.rows + state.first) :
      state.totalRecords) : 0
      }} z {{state.totalRecords ? state.totalRecords : 0}} záznamů
    </div>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="12">Nepovedlo se najít žádné náčrty.</td>
    </tr>
  </ng-template>
</p-table>

<app-nacrt-edit *ngIf="canEdit" #nacrtEdit [visible]="this.selectedNacrty.length != undefined && this.selectedNacrty.length == 1" [nacrtProjects]="projektyAll"
                (NacrtUpdated)="updateRow($event)"></app-nacrt-edit>
<app-nacrt-detail *ngIf="!canEdit" [visible]="viewVisible" [nacrtProjects]="projektyAll" [item]="itemToView"></app-nacrt-detail>

<app-block-ui [block]="block"></app-block-ui>
