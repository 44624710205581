<form [formGroup]="data">
  <p-panel header="Vstupy pro výpočet" [style]="{'margin-bottom': '1em'}">
    <!-- Tady je použité vlastní grid pro 10 sloupců - je založen na principu FlexGridu Primeng. -->
    <div class="p-grid p-align-center">
      <div class="p-10-col-1 p-10-offset-1">Plocha (ha)</div>
      <div class="p-10-col-1">Druh sazenic(p/k)</div>
      <div class="p-10-col-1">Typ sazenic (h/p)</div>
      <div class="p-10-col-1">Np (ks)</div>
      <div class="p-10-col-1">N (ks/ha)</div>
      <div class="p-10-col-1">K2</div>
      <div class="p-10-col-1">CHS</div>
      <div class="p-10-col-1">Zastoupení</div>
    </div>
    <div class="p-grid p-align-center">
      <div class="p-10-col-1">
        LHP
      </div>
      <div class="p-10-col-1">
        <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{_skoda?.lhpEtaz?.etazPs}}</span>
      </div>
      <div class="p-10-col-1 p-10-offset-5">
        <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{_skoda?.lhpEtaz?.chs}}</span>
      </div>
      <div class="p-10-col-1">
        <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{_skoda?.lhpDrevina?.zast}}</span>
      </div>
    </div>
    <div class="p-grid p-align-center">
      <div class="p-10-col-1">
        Vstup
      </div>
      <div class="p-10-col-1">
        <input pInputText type="number" formControlName="plocha" />
      </div>
      <div class="p-10-col-1">
        <input pInputText type="text" formControlName="sazDruh" [pKeyFilter]="druhKeyFilter" />
      </div>
      <div class="p-10-col-1">
        <input pInputText type="text" formControlName="sazTyp" [pKeyFilter]="typKeyFilter" />
      </div>
      <div class="p-10-col-1">
        <input pInputText type="number" formControlName="np" />
      </div>
      <div class="p-10-col-1">
        <input pInputText type="number" formControlName="nHa" />
      </div>
      <div class="p-10-col-1">
        <input pInputText type="number" formControlName="k2" />
      </div>
      <div class="p-10-col-1">
        <input pInputText type="number" formControlName="chs" />
      </div>
      <div class="p-10-col-1">
        <input pInputText type="number" formControlName="zast" />
      </div>
    </div>
  </p-panel>
</form>
