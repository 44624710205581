import { Component, Input, OnInit } from '@angular/core';
import { DrevinDetailDto } from '../../../Dto/Lhp/DrevinDetailDto';

@Component({
  selector: 'app-kniha-detail-drevina',
  templateUrl: './kniha-detail-drevina.component.html',
  styleUrls: ['./kniha-detail-drevina.component.css']
})
export class KnihaDetailDrevinaComponent {

  constructor() { }

  @Input() drevina: DrevinDetailDto;

}
