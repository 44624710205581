<div class="p-grid" style="padding-top:0.5rem;">
  <div class="p-col-8">
    Ikona
    <img *ngIf="usedIcon != null && usedIcon.src != ''"
         [src]="usedIcon.src" alt="ikona bodu"
         style="width: 100%; border: 1px solid #ced4da; border-radius: 3px; height: 2.5rem;"
         pTooltip="{{usedIcon.name}}" tooltipPosition="top" />
    <p *ngIf="usedIcon == null || usedIcon.src == ''"
       style="font-style: italic; text-align: center; border: 1px solid #ced4da; margin: 0; padding: 0.5rem; border-radius: 3px; ">
      Ikona není vybrána.
    </p>
  </div>
  <div class="p-col-2" style="text-align: right; margin-top: 1.3rem;">
    <button pButton type="button" class="icon-btn hide-label-button"
            pTooltip="Zrušit výběr ikony" tooltipPosition="top"
            (click)="unselectIcon()" [disabled]="disabled">
      <fa-icon [icon]="faTrash"></fa-icon>
    </button>
  </div>
  <div class="p-col-2" style="text-align: right; margin-top: 1.3rem;">
    <button pButton type="button" class="icon-btn hide-label-button"
            pTooltip="Vybrat ikonu" tooltipPosition="top"
            (click)="showDlg=true;" [disabled]="disabled">
      <fa-icon [icon]="faImage"></fa-icon>
    </button>
  </div>
</div>

<p-dialog header="Katalog ikon" [(visible)]="showDlg" modal="true" dismissableMask="true"
          [style]="{width:'33rem', height: '90vh', 'max-height': '90vh'}"
          [contentStyle]="{'max-height':'80vh', 'overflow': 'unset', 'padding-bottom': 0}">
  <p-listbox [options]="icons" [(ngModel)]="selectedIcon"
             [metaKeySelection]="false" [filter]="true" optionLabel="name"
             emptyFilterMessage="Hledanému výrazu neodpovídá žádná ikona" emptyMessage="Nebyla nalezena žádná ikona"
             [style]="{'width':'100%'}" [listStyle]="{'max-height': '71vh'}">
    <ng-template let-item pTemplate="item">
      <div class="p-grid p-nogutter" style="width: 100%; align-items: center;">
        <div class="p-col-2">
          <img [src]="item.src" style="max-width: 50px;"/>
        </div>
        <div class="p-col-10">
          <p style="text-align: left;"><span>{{item.name}}</span></p>
        </div>
      </div>
    </ng-template>
  </p-listbox>

  <p-footer>
    <button pButton type="button" class="icon-btn hide-label-button"
            pTooltip="Použít" tooltipPosition="bottom"
            (click)="pouzitIkonu()">
      <fa-icon [icon]="faCheck"></fa-icon>
    </button>
    <button pButton type="button" class="icon-btn hide-label-button times-btn-icon"
            pTooltip="Zavřít" tooltipPosition="bottom"
            (click)="showDlg = false;">
      <fa-icon [icon]="faTimes"></fa-icon>
    </button>
  </p-footer>
</p-dialog>
