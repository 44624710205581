import { Injectable } from '@angular/core';

export const KN_URL:string = "http://nahlizenidokn.cuzk.cz/MapaIdentifikace.aspx?l=KN&";

/**
 * Služba katastru 
 */
@Injectable({
  providedIn: 'root'
})
export class KatastrService {

  constructor() { }

  /**
   * Přejde na katastr na základě dodaných souřadnic
   * @param coords 
   */
  public goToKatastr(coords):void
  {
    let fullString:string = `${KN_URL}x=${Math.round(coords[0])}&y=${Math.round(coords[1])}`;
    window.open(fullString);
  }
}
