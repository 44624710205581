import { Component, Input, OnInit } from '@angular/core';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { MessageService, TreeNode } from 'primeng/api';
import { LhcListAdminDto } from '../../../Dto/Lhp/LhcListAdminDto';
import { PodCiselnikListDto } from '../../../Dto/Shared/PodCiselnikListDto';
import { KnihaService } from '../../../Services/Lhp/kniha.service';
import { LhpAdminServices } from '../../../Services/Lhp/lhp-admin.service';
import { CiselnikyService } from '../../../Services/Shared/ciselniky.service';
import { MessagesUtils } from '../../../Utils/Shared/messages.utils';

/**
 * Komponentapro nastevní organizační úrovně pro zvolené LHC
 * */
@Component({
  selector: 'app-lhc-org-ur-settings',
  templateUrl: './lhc-org-ur-settings.component.html',
  styleUrls: ['./lhc-org-ur-settings.component.css']
})
export class LhcOrgUrSettingsComponent implements OnInit {

  constructor(private knihaService: KnihaService,
    private messageService: MessageService,
    private lhpAdminServices: LhpAdminServices,
    private ciselnikyService: CiselnikyService) { }

  ngOnInit(): void {
    this.cols = [
      { field: 'label', header: 'Výběr JPRL' },
      { field: 'orgUr', header: 'Stávající organizační úroveň' }
    ];

    if (this.orgUrList == void 0 && !this.loadingOrgUr) {
      this.loadOrgUrList();
    }
  }

  faChevronDown = faChevronDown;
  faChevronUp = faChevronUp;


  displayDialog: boolean = false;//zobrazení/skrytí dialogu
  changed: boolean = false;//zaznamená změnu v nastavení LHC
  lhcTree: TreeNode[];//strom s vybraným LHC
  cols: any[];
  loading: boolean = false;
  loadingOrgUr: boolean = false;
  lhcNazev: string;
  lhcKod: string;
  zakaznikGuid: string;
  orgUrGuid: string;
  selectedNodes: TreeNode[];
  private readonly TOAST_KEY: string = "org-ur-settings-toast";
  refresh: boolean = false;//pro případ znovuotevření vybreného LHC

  selectedOrgUr: TreeNode;
  orgUrTree: TreeNode[];

  userOrgUrGuidList: string[];//org. ur. dostupné uživateli

  orgUrList: PodCiselnikListDto[];

  private _lhc: LhcListAdminDto = null;
  get lhc() {
    return this._lhc;
  }

  @Input() set lhc(val: LhcListAdminDto) {
    let changed = false;
    if (this._lhc != val) changed = true;
    this._lhc = val;
    if (changed) {
      this.lhcNazev = val?.lhcNaz;
      this.lhcKod = val?.lhcKod?.toString();
      this.zakaznikGuid = val?.zakaznikGuid;
      this.refresh = false;
      this.loadLhc();
    }

    if (!this.loadingOrgUr) {
      this.loadOrgUrList();
    }
  }

  @Input() disabled: boolean = false;

  /**
   * Zobrazí dialog a provede znovunačtení stromu, pokud to je potřeba
   * */
  public showDialog(): void {
    if (this.refresh) {
      this.refresh = false;
      this.loadLhc();
    }

    var result = [];
    let parentDtoList = this.orgUrList.filter(x => x.parentGuid == void 0).sort((a, b) => parseInt(a.kod) > parseInt(b.kod) ? 1 : -1);//seřadit orgur1 podle kódu

    parentDtoList.forEach(function (dto) {
      let node = this._cislenikToTreeNode(dto);
      node.selectable = false;
      this._useLevel1Node = false;
      this._useLevel2Guid = null;
      this._fillTree(node);
      if (!this.allLevelsSelectable && (node.children == void 0 || node.children.length == 0)) this._useLevel1Node = false;//první úroveň bez potomků pouze u povolení allLevelsSelectable
      if (this._useLevel1Node)
        result.push(node);
    }, this);
    this.orgUrTree = result;

    this.displayDialog = true;
  }

  /**
   * Pokusí se uložit výběr a vygeneruje hlášku
   * */
  public save(): void {
    if (this.orgUrGuid == void 0)
      return this.messageService.add({ key: this.TOAST_KEY, severity: 'error', summary: 'Chyba', detail: 'Zvolte prosím organizační úroveň pro nastavení porostu' });

    this.loading = true;
    let selectedGuids = this.selectedNodes.filter(x => x.type == "PorOrgUrListDto").map(x => x.data?.guid);
    this.lhpAdminServices.setPorOrgUr(this.orgUrGuid, selectedGuids).subscribe(res => {
      this.loading = false;

      if (!res.success)
        return this.messageService.add({ key: this.TOAST_KEY, severity: 'error', summary: 'Chyba', detail: res.messages[0] });

      this.messageService.add({ key: this.TOAST_KEY, severity: 'success', summary: 'Úspěch', detail: "Došlo k nastavení porostů pro LHC " + this.lhcNazev, life: MessagesUtils.TOAST_LIFE });

      //aktualizovat obsah
      this.orgUrGuid = null;
      this.selectedNodes = null;
      this.refresh = true;
      this.loadLhc();
    })
  }

  /**
   * Po zavření dialogu vše promazat
   * */
  onHideDialog(): void {
    this.orgUrGuid = null;
    this.selectedNodes = null;
    this.refresh = true;
  }

  /**
   * Načte vybrané LHC do stromu
   * */
  public loadLhc(): void {
    this.loading = true;
    this.lhcTree = [];
    if (this.lhc != void 0)
      this.knihaService.getFromLhcToPorTree(this.lhc.guid)
        .subscribe(response => {
          if (response?.length > 0 && response[0]?.children?.length > 0) {
            this.lhcTree = response[0]?.children;
            this.loading = false;
          } else {
            return this.messageService.add({ key: this.TOAST_KEY, severity: 'error', summary: 'Chyba', detail: "Ve zvoleném LHC se nenachází žádné JPRL nebo došlo k technické chybě." });
          }
        });
  }

  /**
  * Načtení seznamu organizačních úrovní. TODO - musí se použít jiná metoda controlleru pro získání org. ur. pro odlišení SuperAdmin a LhpAdmin
  **/
  private loadOrgUrList() {
    if (this.lhc != null) {
      this.loadingOrgUr = true;
      this.ciselnikyService.getOrgUr(this.zakaznikGuid).subscribe(res => {
        this.orgUrList = (res as PodCiselnikListDto[]);
        this.userOrgUrGuidList = this.orgUrList.map(x => x.guid);
        this.loadingOrgUr = false;
      });
    }
  }

  /**
  * Naplní větve stromu pouze těmi uzly, které má uživatel povoleny a seřadí podle kódu
  * @param node
  */
  private _fillTree(node: TreeNode): void {
    this.orgUrList.filter(x => x?.parentGuid == node.data.guid).forEach(function (dto) {
      var useOrgUr2 = true;//pomocná property pro odfiltrování orgur2 bez potomků u klikatelné pouze třetí úrovně
      let nodeLoc = this._cislenikToTreeNode(dto);
      nodeLoc.parent = node;
      if (node.children == void 0) {
        node.children = [];
        node.selectable = true;
      } else {
        node.selectable = this.allLevelsSelectable;
      }
      this._fillTree(nodeLoc);

      var isNotOrgUr3 = true;

      if (node.data.parentGuid == void 0) {//předek je orgur1 tj. dto je orgur2
        if (this.onlyLevel2Selectable)
          nodeLoc.selectable = true;
        if (this._useLevel2Guid != dto.guid) {//pokud se nezměnila druhá úroveň
          this._useLevel2Guid = this.userOrgUrGuidList.includes(dto.guid) ? dto.guid : null;
        }
        if (this._useLevel2Guid != void 0 && ((nodeLoc.children == void 0 || nodeLoc.children.length == 0) && !this.onlyLevel2Selectable) && !this.allLevelsSelectable) {//pokud se vyskytne orgur2 bez potomků a nejde na něj klikat, tak odfiltrovat
          this._useLevel2Guid = null;
          useOrgUr2 = false;
        }
      } else {//orgur3
        isNotOrgUr3 = false;
        if (this.userOrgUrGuidList.includes(dto.guid)) {//pokud je povolena třetí úroveň a zatím nebyla povolena druhá, tak povolit celou druhou nadřazenou
          this._useLevel2Guid = dto.parentGuid;
        }
        else {
          this._useLevel2Guid = null;
        }
      }

      if (this._useLevel2Guid != void 0) {//pokud je povolená některá druhá úroveň, tak povolit i první
        this._useLevel1Node = true;
      };

      if ((!this.onlyLevel2Selectable || isNotOrgUr3) && useOrgUr2 && (this._useBranche || this._useLevel2Guid != void 0 || (nodeLoc.children != void 0 && nodeLoc.children.length > 0))) {//pokud je povolený orgur1, nebo pokud je povolen orgur2 (tzn. povolený přímo orgur2, nebo některý z jeho orgur3 ), tak použít
        node.children.push(nodeLoc);
      }
      node.children.sort((a, b) => parseInt(a.data.kod) > parseInt(b.data.kod) ? 1 : -1);//seřadit podle kódu

    }, this);
  }

  //použít vybrané org ur a zavře dialod
  selectOrgUr(): void {
    if (this.selectedOrgUr != null && this.selectedOrgUr.children == null)
      this.orgUrGuid = this.selectedOrgUr.data.guid;
    else
      this.selectedOrgUr == null;
  }

  //převede dto na TreeNode
  private _cislenikToTreeNode(value: PodCiselnikListDto): TreeNode {
    return {
      label: value.kod + " - " + value.popis,
      data: value
    };
  }


  //sbalí všechny podřazené větve
  private _colapseRecursive(node: TreeNode) {
    node.expanded = false;
    if (node.children) {
      node.children.forEach(childNode => {
        this._colapseRecursive(childNode);
      });
    }
  }

  /**
   * Poslední guid org. ur. porostu pro  oddělení k zamezení vykreslení čárky v seznamu
   */
  public lastPorOrgUrFromOdd: string;

  /**
   * Vrátí všechny guidy org. ur. porostů pro oddělení bez duplicit
   * @param children
   */
  public getPorOrgUrFromOdd(children: TreeNode[]): string[] {
    let orgUrs: string[] = [];
    children.forEach(dil => {
      orgUrs = orgUrs.concat(this._getOrgUrGuids(dil.children));
    });
    orgUrs = this._getUnic(orgUrs);
    if (orgUrs.length > 0) this.lastPorOrgUrFromOdd = orgUrs[orgUrs.length - 1];
    return orgUrs;
  }

  /**
   * Poslední guid org. ur. porostu pro dílec k zamezení vykreslení čárky v seznamu
   */
  public lastPorOrgUrFromDil: string;

  /**
   * Vrátí všechny guidy org. ur. porostů pro dílec bez duplicit
   * @param children
   */
  public getPorOrgUrFromDil(children: TreeNode[]): string[] {
    let orgUrs: string[] = this._getUnic(this._getOrgUrGuids(children));
    if (orgUrs.length > 0) this.lastPorOrgUrFromDil = orgUrs[orgUrs.length - 1];
    return orgUrs;
  }


  /**
   * Vrátí seznam guidů organizačních úrovní.
   * @param arr {TreeNode[]}
   */
  private _getOrgUrGuids(arr: TreeNode[]): string[] {
    return arr.map(node => node.data.orgUrGuid);
  }


  /**
   * Vrátí pole unikátních hodnot.
   * @param arr {string[]}
   */
  private _getUnic(arr: string[]): string[] {
    return arr.filter((val, idx) => arr.indexOf(val) == idx);
  }

  /**
   * Rozbalí všechny uzle
   * */
  public expandAll(): void {
    this._expandNodes(this.lhcTree, true);
    this.lhcTree = [...this.lhcTree];//musí se přiřadit kopie, aby se změny projevily.
  }

  /**
   * Sbalí všechny uzle
   * */
  public collapseAll(): void {
    this._expandNodes(this.lhcTree, false);
    this.lhcTree = [...this.lhcTree];//musí se přiřadit kopie, aby se změny projevily.
  }

  /**
   * Rekurzivní funkce pro sbalení/rozbalení všech uzlů
   * @param children - pole TreeNode, které se má projít
   * @param expand - true - rozbalit, false - zbalit
   */
  private _expandNodes(children: TreeNode[], expand: boolean): void {
    children?.forEach(node => {
      node.expanded = expand;
      if (node?.children?.length > 0) this._expandNodes(node.children, expand);
    });
  }
}
