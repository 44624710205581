import { Component, ElementRef, EventEmitter, forwardRef, HostBinding, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelectItem } from 'primeng/api/selectitem';
import { Listbox } from 'primeng/listbox';
import { CiselnikListDto } from 'src/app/Dto/Shared/CiselnikListDto';
import { CiselnikyService } from 'src/app/Services/Shared/ciselniky.service';

/**
 * Komponenta pro obsluhu výběru z číselníku. Sama obsluhuje CiselnikyService:
 * Získá si data číselníku, zobrazi dropdown, a jako hodnotu poskytuje GUID zvolené položky.
 */
@Component({
  selector: 'app-ciselnik-dropdown',
  templateUrl: './ciselnik-dropdown.component.html',
  styleUrls: ['./ciselnik-dropdown.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CiselnikDropdownComponent),
    multi: true
  }]
})
export class CiselnikDropdownComponent implements ControlValueAccessor {

  private _id = '';
  private _ciselnik: string = null;
  protected _nullable: boolean = false;

  protected _rawData: CiselnikListDto[] = [];

  data: SelectItem[] = [];
  kod: string;
  display: boolean = false;
  text: string;
  private _listBoxSelected: boolean = false;

  @ViewChild("cisListBox") listBox: Listbox;
  @ViewChild("kodInput") kodInput: ElementRef;

  // Proměnné pro implementaci rozhraní ControlValueAccessor
  onModelChange: any = () => { };
  onModelTouched: any = () => { };
  disabled: boolean = false;

  // finta s ID odkoukána zde: https://coryrylan.com/blog/angular-custom-form-controls-with-reactive-forms-and-ngmodel
  @HostBinding('attr.id')
  externalId = '';

  /**
   * Příznak, zda je povolena editace (používá se pro zobrazení "detailu")
   */
  @Input() edit: boolean = true;

  /**
   * Příznak, že je hodnota jen pro čtení (používá se k odlišení hodnot načtených "odjinud" při editaci)
   */
  @Input() readonly: boolean = false;

  @Input()
  set id(value: string) {
    this._id = value;
    this.externalId = null;
  }

  get id() {
    return this._id;
  }

  //filtr číselníku
  @Input() filter: any;

  @Input('value') _value: string = null;

  get value() {
    return this._value;
  }

  set value(val) {
    if (val != this._value) {
      this._value = val;
      this.onModelChange(val);
      this.onModelTouched();
      this.onChange.emit(val);
    }
  }

  @Input()
  set ciselnik(value: string) {
    if (value != this._ciselnik) {
      this._ciselnik = value;
      this._rawData = [];

      this.ciselnikyService.getCislenik(this._ciselnik, null).subscribe(res => {
        this._rawData = res;
        if (this.filter) {
          this._rawData = this._rawData.filter(this.filter);
        }
        this.buildData();
      });
    }
  }

  @Input()
  set nullable(value: boolean) {
    if (value != this._nullable) {
      this._nullable = value;
      this.buildData();
    }
  }

  @Input()
  set ciselnikData(value: CiselnikListDto[]) {
    this._rawData = value;
    this.buildData();
  }

  /**
   * Event vyvolaný při změně hodnoty
   */
  @Output() onChange: EventEmitter<any> = new EventEmitter();

  //TODO nevidím nikde implemetované
  @Input() virtualScroll: boolean = false;
  @Input() panelStyle: string;

  /**
   * Varianta zobrazení podle dostupného prostoru:
   * - 'short' - jen kod
   * - 'full' - kod + název
   */
  @Input() variant: string = "full";

  constructor(
    protected ciselnikyService: CiselnikyService
  ) { }

  // Implementace rozhraní ControlValueAccessor
  writeValue(obj: any): void {
    this.value = obj;
    this.selectByGuid();
  }

  // Implementace rozhraní ControlValueAccessor
  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  // Implementace rozhraní ControlValueAccessor
  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }

  // Implementace rozhraní ControlValueAccessor
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * Pomocná metoda pro sestavení dat pro dropdown ze surových dat číselníku a dalšího nastavení.
   * Volá se při změně nastavení nullable a při stažení číselníku.
   */
  protected buildData(): void {
    var auxData = this._rawData.sort((a, b) => { return Number(a.kod) - Number(b.kod); }).map(x => {
      return {
        label: x.kod + " - " + x.popis, value: x.guid
      };
    });

    if (this._nullable) {
      this.data = [{ label: "Ponechat prázdné", value: null }].concat(auxData);
    }
    else {
      this.data = auxData;
    }
    this.selectByGuid();
  }

  //vypíše aktuální položku pro zobrazení v detailu
  getDetail(): string {
    return this.value ? this.data.find(x => x.value == this.value)?.label : "";
  }

  /**
   * Ošetření výběru ze seznamu autocomplete (potřebuji celé DTO, nikoli jen jednu položku)
   * @param val
   */
  onSelect(val: SelectItem): void {
    this.value = val.value;
  }

  /**
   * Po zobrazení dialogu vybrat pole filtru
   * */
  onShowDialog(): void {
    this.listBox.el.nativeElement.querySelector("input.p-inputtext").focus();
  }


  /**
   * Po výběru hodnoty číselníku naplnit pole s popisem a skrýt dialog
   * @param event
   */
  onChangeListBox(event) {
    this.selectByGuid();
    //pokud je v čiselníku zvolane možnost pro vyprázdnění pole
    if (this.value == void 0)
      this._cleanValues();

    this.display = false;
    this._listBoxSelected = true;
  }

  /**
   * Po opuštění pole zkontrolovat kód a zapsat výběr
   * */
  onBlur(): void {
    let item = this.kod != void 0 ? this.data.find(x => x.label.split("-")[0].trim() == this.kod.trim()) : null;
    if (item == void 0) {
      this._cleanValues();
    } else {
      this.fillItem(this._rawData.find(x => x.guid.toLowerCase() == item.value.toLowerCase()));
    }
  }

  /**
   * Promaže hodnoty
   * */
  private _cleanValues(): void {
    this.kod = "";
    this.text = "";
    this.value = null;
  }

  /**
   * Vyhledání a naplnění položky podle guid
   * */
  selectByGuid(): void {
    if (this.value != void 0) {
      let item = this._rawData.find(x => x.guid.toLowerCase() == this.value.toLowerCase());
      this.fillItem(item);
    }
    else {
      this._cleanValues();
    }
  }

  /**
   *
   * Naplnění položky
   * @param item
   */
  fillItem(item: CiselnikListDto): void {
    if (item != void 0) {
      this.kod = item.kod;
      this.text = item.popis;
      this.value = item.guid;
    }
  }

  /**
   * Focus na input
   * */
  focus(): void {
    if (this.kodInput != void 0) this.kodInput.nativeElement.focus();
  }


  /**
   * Zobrazení dialogu s hodnotami pro výběr.
  **/
  openDialog(): void {
    if (!this.disabled && !this.readonly) {
      //FIXME: timeout jen obchází nějaký problém filtrování - viz #27798
      setTimeout(() => { this.display = true; }, 100);
    }
  }

  /**
   * Handler skrytí dialogu
   * */
  onHideHandler(): void {
    if (this._listBoxSelected) {//pokud došlo k výběru, tak odeslat enter pro přechod na další položku
      this._listBoxSelected = false;
      let ev = new KeyboardEvent('keyup', {
        code: 'Enter',
        key: 'Enter',
        view: window,
        bubbles: true
      });
      this.kodInput.nativeElement.dispatchEvent(ev);
    }
    else {
      this.focus();
    }
  }
}
