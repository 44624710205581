<app-block-ui [block]="block"></app-block-ui>
<p-toast position="top-center" key="cd-edit-toast"></p-toast>
<app-vyroba-kontrola #kontrolaTree [showBtn]="false"></app-vyroba-kontrola>

<div class="cd-body" nextOnEnter>
  <p-panel>
    <ng-template pTemplate="header">
      <div class="p-grid p-align-center">
        <div class="p-col"><h2 class="p-panel-title">Číselník dříví</h2></div>
        <div class="p-col right">
          <button pButton type="button" label="Uložit rozpracované" class="icon-btn p-button-success" (click)="save()" *ngIf="edit" id="cd-save-button"><fa-icon [icon]="faSave"></fa-icon></button>
          <button pButton type="button" label="Uložit a dokončit" class="icon-btn p-button-warning" (click)="saveAndFinish()" *ngIf="edit" id="cd-finish-button"><fa-icon [icon]="faFlagCheckered"></fa-icon></button>
          <button pButton type="button" label="Zpět" class="icon-btn" (click)="cancel()" id="cd-cancel-button"><fa-icon [icon]="faUndo"></fa-icon></button>
        </div>
      </div>
    </ng-template>
    <div class="p-grid">
      <div class="p-col-3">
        <div class="p-grid">
          <div class="p-col">
            <label for="cd-datum-date">Datum dokladu</label>
            <app-calendar #datumDokladu [(ngModel)]="data.datum" id="cd-datum-date" [edit]="edit"></app-calendar>
          </div>
          <div class="p-col">
            <label for="cd-orgUrGuid">Organizační úroveň</label>
            <app-org-ur [(ngModel)]="data.orgUrGuid" id="cd-orgUrGuid" [edit]="edit"></app-org-ur>
          </div>
        </div>
      </div>
      <div class="p-col-1">
        <label for="cd-cislo-textbox">Číslo dokladu</label>
        <input [(ngModel)]="data.cislo" type="text" pInputText id="cd-cislo-textbox" *ngIf="edit" />
        <div class="p-inputtext ui-widget ui-state-default ui-corner-all ui-div-inputtext" *ngIf="!edit">{{data.cislo}} </div>
      </div>
      <div class="p-col-1">
        <label for="cd-vykonGuid-dropdown">Výkon</label>
        <app-ciselnik-dropdown [ciselnikData]="tezebniVykony" [(ngModel)]="data.vykonGuid" nullable="true" id="cd-vykonGuid-dropdown"
                               [virtualScroll]="true" [panelStyle]="{'width':'400px'}"
                               (onChange)="vykonChangeHandler($event)" [edit]="edit"></app-ciselnik-dropdown>
      </div>
      <div class="p-col-1">
        <label for="cd-lokalitaGuid-dropdown">Lokalita</label>
        <app-ciselnik-dropdown ciselnik="CLokalita" [(ngModel)]="data.lokalitaGuid" nullable="true" id="cd-lokalitaGuid-dropdown" [edit]="edit"></app-ciselnik-dropdown>
      </div>
      <div class="p-col-3">
        <app-etaz-input [(ngModel)]="data.etazGuid" [visibileJprlBtn]="true" [edit]="edit" [orgUrGuid]="data.orgUrGuid"></app-etaz-input>
      </div>
      <div class="p-col-2">
        <label for="cd-druhMzdyGuid-dropdown">Druh mzdy</label>
        <app-ciselnik-dropdown ciselnik="CDruhMzdy" [(ngModel)]="data.druhMzdyGuid" nullable="true" id="cd-druhMzdyGuid-dropdown" [edit]="edit"></app-ciselnik-dropdown>
      </div>
      <div class="p-col-1">
        <label for="cd-tarif-textbox">Tarif/Sazba</label>
        <input [(ngModel)]="data.tarif" type="text" pInputText id="cd-tarif-textbox" *ngIf="edit" />
        <div class="p-inputtext ui-widget ui-state-default ui-corner-all ui-div-inputtext" *ngIf="!edit">{{data.tarif}} </div>
      </div>
      <div class="p-col-9">
        <label for="cd-poznamka-textbox">Poznámka</label>
        <input [(ngModel)]="data.poznamka" type="text" pInputText id="cd-poznamka-textbox" *ngIf="edit" />
        <div class="p-inputtext ui-widget ui-state-default ui-corner-all ui-div-inputtext" *ngIf="!edit">{{data.poznamka}} </div>
      </div>
      <div class="p-col-3">
        <label for="cd-zpusob-provedeni-dropdown">Způsob provedení</label>
        <app-ciselnik-dropdown ciselnik="CZpusobProvedeni" [(ngModel)]="data.zpusobProvedeniGuid" nullable="true" id="cd-zpusob-provedeni-dropdown" [edit]="edit"></app-ciselnik-dropdown>
      </div>
    </div>
  </p-panel>

  <div class="p-grid p-align-stretch cd-main" (keydown)="onKeyDown($event)" >
    <div class="p-col-8">
      <p-panel [styleClass]="'data-panel'">
        <ng-template pTemplate="header">
          <div class="p-grid p-align-center">
            <div class="p-col"><span class="p-panel-title">Data</span></div>
            <div class="p-col right">
              <button pButton type="button" icon="pi pi-plus" (click)="addRadek()" id="cd-radek-add-button" *ngIf="edit"></button>
              <button pButton type="button" icon="pi pi-minus" class="p-button-danger" (click)="delRadek()" id="cd-radek-del-button" *ngIf="edit"></button>
            </div>
          </div>
        </ng-template>
        <div class="cd-data">
          <div class="cd-table-wrapper">
            <p-table [value]="data.vypocty" [styleClass]="'pds-table-compact'" selectionMode="single" [(selection)]="aktivniRadek"
                     compareSelectionBy="equals" [scrollable]="true" scrollHeight="flex"
                     (onRowSelect)="setTypVypoctu($event.data?.typVypoctuGuid)">
              <ng-template pTemplate="header">
                <tr>
                  <th rowspan="2" title="Číslo řádku">Č.</th>
                  <th rowspan="2" title="Dodavatel">Dod.</th>
                  <th rowspan="2" title="Podvýkon">PV</th>
                  <th rowspan="2" title="Druh těžby">DT</th>
                  <th rowspan="2">Typ</th>
                  <th rowspan="2" title="Dřevina">Dřev.</th>
                  <th rowspan="2" title="Sortiment">Sort.</th>
                  <th rowspan="2" title="Kusů oddenků">Ks odd.</th>
                  <th rowspan="2" title="Kusů celkem">Ks celk.</th>
                  <th rowspan="2" title="Přibližovací vzdálenos">Přib. vz.</th>
                  <th colspan="3">Dlouhé dříví</th>
                  <th colspan="5">Rovnané dříví (hráně)</th>
                  <th rowspan="2" title="Množství">Množ. (m<sup>3</sup>)</th>
                </tr>
                <tr>
                  <!-- dlouhé dříví -->
                  <th>Délka (m)</th>
                  <th title="Průměr">Ø (cm)</th>
                  <th>Třída</th>
                  <!-- rovnané dříví (hráně) -->
                  <th>Hráň</th>
                  <th>Výška</th>
                  <th>Délka</th>
                  <th>Šířka</th>
                  <th title="Koeficient">Koef.</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-row>
                <tr [pSelectableRow]="row">
                  <td>{{row.cislo}}</td>
                  <td><app-ciselnik-kod [ciselnik]="'CDodavatel'" [guid]="row.dodavatelGuid"></app-ciselnik-kod></td>
                  <td><app-ciselnik-kod [ciselnik]="'CPodvykon'" [guid]="row.podvykonGuid"></app-ciselnik-kod></td>
                  <td><app-ciselnik-kod [ciselnik]="'CVyrDruhTezby'" [guid]="row.druhTezbyGuid"></app-ciselnik-kod></td>
                  <td><app-ciselnik-kod [ciselnik]="'CVyrTypVypoctu'" [guid]="row.typVypoctuGuid"></app-ciselnik-kod></td>
                  <td><app-ciselnik-kod [ciselnik]="'CVyrDrevina'" [guid]="row.drevinaGuid"></app-ciselnik-kod></td>
                  <td><app-ciselnik-kod [ciselnik]="'CSortiment'" [guid]="row.sortimentGuid"></app-ciselnik-kod></td>
                  <td>{{row.oddenkyKs}}</td>
                  <td>{{row.celkemKs}}</td>
                  <td>{{row.pribVzd}}</td>
                  <td><span *ngIf="row.typVypoctuGuid != hraneGuid">{{row.delka | number:'1.2-2'}}</span></td>
                  <td>{{row.prumer}}</td>
                  <td>{{row.trida}}</td>
                  <td>{{row.cisloHrane}}</td>
                  <td>{{row.vyskaHrane | number:'1.2-2'}}</td>
                  <td>{{row.delkaHrane | number:'1.2-2'}}</td>
                  <td><span *ngIf="row.typVypoctuGuid == hraneGuid">{{row.delka | number:'1.2-2'}}</span></td>
                  <td>{{row.koeficient | number:'1.2-2'}}</td>
                  <td class="right">{{row.mnozstvi | number:'1.2-2'}}</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
          <p-card>
            <div class="p-grid">
              <div class="p-col-4">
                <label for="cd-dodavatelGuid-dropdown">Dodavatel/Pracovník</label>
                <app-ciselnik-dropdown #dodavatelDropDown ciselnik="CDodavatel" [(ngModel)]="aktivniRadek.dodavatelGuid"
                                       nullable="true" id="cd-dodavatelGuid-dropdown" [edit]="edit"
                                       [virtualScroll]="true" [panelStyle]="{'width':'400px'}"></app-ciselnik-dropdown>
              </div>
              <div class="p-col-4">
                <label for="cd-podvykonGuid-dropdown">Podvýkon</label>
                <app-podvykon-dropdown [vykonGuid]="data.vykonGuid" [(ngModel)]="aktivniRadek.podvykonGuid"
                                       nullable="true" id="cd-podvykonGuid-dropdown" [edit]="edit"
                                       [virtualScroll]="true" [panelStyle]="{'width':'600px'}"></app-podvykon-dropdown>
              </div>
              <div class="p-col-3">
                <label for="cd-druhTezbyGuid-dropdown">Druh těžby</label>
                <app-ciselnik-dropdown ciselnik="CVyrDruhTezby" [(ngModel)]="aktivniRadek.druhTezbyGuid" nullable="true" id="cd-druhTezbyGuid-dropdown"
                                       [edit]="edit"></app-ciselnik-dropdown>
              </div>
              <div class="p-col-1">
                <label for="cd-cislo-ks-textbox">Číslo ks</label>
                <input [(ngModel)]="aktivniRadek.cisloKs" type="number" pInputText id="cd-cislo-ks-textbox" *ngIf="edit" />
                <div class="p-inputtext ui-widget ui-state-default ui-corner-all ui-div-inputtext" *ngIf="!edit">{{aktivniRadek.cisloKs}} </div>
              </div>
            </div>
            <div class="p-grid">
              <div class="p-col-4">
                <label for="cd-typVypoctuGuid-dropdown">Typ</label>
                <app-ciselnik-dropdown [ciselnikData]="dostupneTypyVypoctu" [(ngModel)]="aktivniRadek.typVypoctuGuid" nullable="true" [edit]="edit"
                                       (onChange)="setTypVypoctu($event)" id="cd-typVypoctuGuid-dropdown"></app-ciselnik-dropdown>
              </div>
              <div class="p-col-4">
                <label for="cd-drevinaGuid-dropdown">Dřevina</label>
                <app-ciselnik-dropdown ciselnik="CVyrDrevina" [(ngModel)]="aktivniRadek.drevinaGuid" nullable="true" [virtualScroll]="true"
                                       [edit]="edit" (onChange)="recalc()" id="cd-drevinaGuid-dropdown"></app-ciselnik-dropdown>
              </div>
              <div class="p-col-4">
                <label for="cd-sortimentGuid-dropdown">Sortiment</label>
                <app-ciselnik-dropdown ciselnik="CSortiment" [(ngModel)]="aktivniRadek.sortimentGuid" [virtualScroll]="true" [edit]="edit"
                                       (onChange)="recalcStat()" nullable="true" id="cd-sortimentGuid-dropdown"></app-ciselnik-dropdown>
              </div>
            </div>

            <div class="p-grid">
              <div class="p-col" *ngIf="enDelka">
                <label for="cd-delka-textbox">Délka</label>
                <decimal-input [(ngModel)]="aktivniRadek.delka" (change)="recalc()" id="cd-delka-textbox" [edit]="edit"></decimal-input>
              </div>
              <div class="p-col" *ngIf="enPrumer">
                <label for="cd-prumer-textbox">Průměr</label>
                <input name="prumer" type="text" pInputText [(ngModel)]="aktivniRadek.prumer" (change)="recalc()" id="cd-prumer-textbox" *ngIf="edit" />
                <div class="p-inputtext ui-widget ui-state-default ui-corner-all ui-div-inputtext" *ngIf="!edit">{{aktivniRadek.prumer}} </div>
              </div>
              <div class="p-col" *ngIf="enTrida">
                <label for="cd-trida-textbox">Třída</label>
                <input name="trida" type="text" pInputText [(ngModel)]="aktivniRadek.trida" (change)="recalc()" id="cd-trida-textbox" *ngIf="edit" />
                <div class="p-inputtext ui-widget ui-state-default ui-corner-all ui-div-inputtext" *ngIf="!edit">{{aktivniRadek.trida}} </div>
              </div>
              <div class="p-col" *ngIf="enCisloHrane">
                <label for="cd-cisloHrane-textbox">Číslo hráně</label>
                <input name="cisloHrane" type="text" pInputText [(ngModel)]="aktivniRadek.cisloHrane" (change)="recalc()" id="cd-cisloHrane-textbox" *ngIf="edit" />
                <div class="p-inputtext ui-widget ui-state-default ui-corner-all ui-div-inputtext" *ngIf="!edit">{{aktivniRadek.cisloHrane}} </div>
              </div>
              <div class="p-col" *ngIf="enVyskaHrane">
                <label for="cd-vyskaHrane-textbox">Výška hráně</label>
                <decimal-input [(ngModel)]="aktivniRadek.vyskaHrane" (change)="recalc()" id="cd-vyskaHrane-textbox" [edit]="edit"></decimal-input>
              </div>
              <div class="p-col" *ngIf="enDelkaHrane">
                <label for="cd-delkaHrane-textbox">Délka hráně</label>
                <decimal-input [(ngModel)]="aktivniRadek.delkaHrane" (change)="recalc()" id="cd-delkaHrane-textbox" [edit]="edit"></decimal-input>
              </div>
              <div class="p-col" *ngIf="enSirkaHrane">
                <label for="cd-delka-textbox">Šířka</label>
                <decimal-input [(ngModel)]="aktivniRadek.delka" (change)="recalc()" id="cd-delka-textbox" [edit]="edit"></decimal-input>
              </div>
              <div class="p-col" *ngIf="enKoeficient">
                <label for="cd-koeficient-textbox">Koeficient</label>
                <decimal-input [(ngModel)]="aktivniRadek.koeficient" (change)="recalc()" id="cd-koeficient-textbox" [edit]="edit"></decimal-input>
              </div>
              <div class="p-col" *ngIf="enOddenkyKs">
                <label for="cd-oddenkyKs-textbox">Kusů oddenků</label>
                <input name="oddenkyKs" type="text" pInputText [(ngModel)]="aktivniRadek.oddenkyKs" (change)="recalc()" id="cd-oddenkyKs-textbox" *ngIf="edit" />
                <div class="p-inputtext ui-widget ui-state-default ui-corner-all ui-div-inputtext" *ngIf="!edit">{{aktivniRadek.oddenkyKs}} </div>
              </div>
              <div class="p-col" *ngIf="enCelkemKs">
                <label for="cd-celkemKs-textbox">Kusů celkem</label>
                <input name="celkemKs" type="text" pInputText [(ngModel)]="aktivniRadek.celkemKs" (change)="recalc()" id="cd-celkemKs-textbox" *ngIf="edit" />
                <div class="p-inputtext ui-widget ui-state-default ui-corner-all ui-div-inputtext" *ngIf="!edit">{{aktivniRadek.celkemKs}} </div>
              </div>
              <div class="p-col" *ngIf="enPribVz">
                <label for="cd-pribVz-textbox">Přibližovací vzdálenost</label>
                <input name="pribVz" type="text" pInputText [(ngModel)]="aktivniRadek.pribVzd" id="cd-pribVz-textbox" *ngIf="edit" />
                <div class="p-inputtext ui-widget ui-state-default ui-corner-all ui-div-inputtext" *ngIf="!edit">{{aktivniRadek.pribVzd}} </div>
              </div>
              <div class="p-col" *ngIf="enMnozstviRucne">
                <label for="cd-mnozstviRucne-textbox">Množství ručně</label>
                <decimal-input [(ngModel)]="aktivniRadek.mnozstviRucne" (change)="recalc()" id="cd-mnozstviRucne-textbox" [edit]="edit"></decimal-input>
              </div>
            </div>
          </p-card>
        </div>

      </p-panel>
    </div>
    <div class="p-col-4">
      <div class="cd-right">
        <div [ngClass]="edit?'cd-stat-edit':'cd-stat'">
          <p-panel header="Statistika" [styleClass]="'statistika-panel'">
            <p-table [value]="statistika" [styleClass]="'pds-table-compact'" [scrollable]="true"
                     scrollHeight="flex">
              <ng-template pTemplate="header">
                <tr>
                  <th>Dř.</th>
                  <th>Sort.</th>
                  <th>Množ. (m<sup>3</sup>)</th>
                  <th>Pr. hm. odd.</th>
                  <th>Pr. hm. celk.</th>
                  <th>Ks odd.</th>
                  <th>Ks celk.</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-row>
                <tr>
                  <td>{{row.drevinaPopis}}<app-ciselnik-kod [ciselnik]="'CVyrDrevina'" [guid]="row.drevinaGuid"></app-ciselnik-kod></td>
                  <td>{{row.sortimentPopis}}<app-ciselnik-kod [ciselnik]="'CSortiment'" [guid]="row.sortimentGuid"></app-ciselnik-kod></td>
                  <td style="text-align:right">{{row.mnozstvi | number:'1.2-2':'cs'}}</td>
                  <td style="text-align:right">{{row.prHmOdd | number:'1.2-2':'cs'}}</td>
                  <td style="text-align:right">{{row.prHmCelk | number:'1.2-2':'cs'}}</td>
                  <td style="text-align:right">{{row.oddenkyKs}}</td>
                  <td style="text-align:right">{{row.celkemKs}}</td>
                </tr>
              </ng-template>
            </p-table>
          </p-panel>
        </div>
        <div class="cd-help" *ngIf="edit">
          <p-panel header="Ovládání" [toggleable]="true">
            <app-vyroba-help coKopiruje="typ, dřevinu, sortiment"></app-vyroba-help>
          </p-panel>
        </div>
      </div>
    </div>
  </div>
</div>
