import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { Feature, Map } from 'ol';
import { MultiPolygon, Polygon } from 'ol/geom';
import Overlay from 'ol/Overlay';
import { FormatPlochaPipe } from '../../../Directives/format-plocha.pipe';
import { AreaOverlayInteractionService } from '../../../Services/Mapa/area-overlay-interaction.service';
import { MapInteractionService } from '../../../Services/Mapa/map-interaction.service';


/**
 * Komponenta zobrazující overlay popisky plošných mapových objektů.
**/
@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-area-overlay',
  template: '',
  styleUrls: ['./area-overlay.component.css']
})
export class AreaOverlayComponent implements OnInit {

  private _mapId: string;
  /**
   * Id mapy, do které se budou vytvářet popisky.
  **/
  @Input() set mapId(value: string) {
    this._mapId = value;
  }


  /**
   * Prefix přidávaný do id overlay popisků ploch.
  **/
  private readonly AREA_OVERLAY_PREFIX: string = 'area_overlay_';


  /**
   * Instance mapy.
  **/
  private _map: Map;
  

  constructor(
    private formatPlochaPipe: FormatPlochaPipe,
    private mapInteractionService: MapInteractionService,
    private overlayInteractionService: AreaOverlayInteractionService)
  { }


  /**
   * Inicializace komponenty.
  **/
  ngOnInit(): void {
    this.mapInteractionService.MapCreated.subscribe(this._mapCreatedHandler.bind(this));
    this.overlayInteractionService.Add.subscribe(this._add.bind(this));
    this.overlayInteractionService.Update.subscribe(this._update.bind(this));
    this.overlayInteractionService.Remove.subscribe(this._removeById.bind(this));
  }


  /**
   * Handler vytvoření mapy.
   * @param map {Map} Instance vytvořené mapy
   */
  private _mapCreatedHandler(map: Map): void {
    if (map.getTargetElement().id == this._mapId) {
      this._map = map;
    }
  }


  /**
   * Vytvoření overlay.
   * @param polygon {Polygon} geometrie, které se vytváří overlay popisek
   * @param id {string} id featury, které se vytváří overlay popisek
   */
  private _createOverlay(polygon: Polygon, id: string): Overlay {
    let div = document.createElement('div');
    div.className = 'ol-tooltip ol-tooltip-measure';
    div.innerHTML = this.formatPlochaPipe.transform(polygon.getArea(), 10000);

    return new Overlay({
      id: this.AREA_OVERLAY_PREFIX + id,
      element: div,
      offset: [0, -17],
      positioning: 'bottom-center',
      position: polygon.getInteriorPoint().getCoordinates()
    });
  }


  /**
   * Handler přidání overlay popisků do mapy.
   * @param features {Feautre<any>[]} mapové objekty, kterým se mají přidat overlay popisky
   */
  private _add(features: Feature<any>[]): void {
    this._removeAll();
    features.forEach(f => this._addOverlay(f));
  }


  /**
   * Přidání overlay popisku s plochou polygonu do mapy.
   * @param feature {Feature<any>} mapový objekt, kterému budeme přidávat overlay popisky
   */
  private _addOverlay(feature: Feature<any>): void {
    let id = feature.getId()?.toString();
    let geometry = feature.getGeometry();
    if (geometry instanceof MultiPolygon) {
      let cnt = 0;
      (geometry as MultiPolygon).getPolygons().forEach(polygon => {
        cnt += 1;
        this._map.addOverlay(this._createOverlay(polygon, id + '_' + cnt));
      });
    }
    else if (geometry instanceof Polygon) {
      this._map.addOverlay(this._createOverlay(geometry, id + "_1"));
    }
  }


  /**
   * Aktualizace plochy v overlay popisku
   * @param feature {Feature<any>} mapový objekt jehož overlay popisku se má akutalizovat
   */
  private _update(feature: Feature<any>): void {
    let ids = {};
    let geometry = feature.getGeometry();

    if (geometry instanceof MultiPolygon) {
      let polygons = (geometry as MultiPolygon).getPolygons();
      let cnt = 0;
      polygons.forEach(p => {
        cnt += 1;
        ids[this.AREA_OVERLAY_PREFIX + feature.getId() + '_' + cnt] = { "area": p.getArea(), "coord": p.getInteriorPoint().getCoordinates() };
      });
    }
    else if (geometry instanceof Polygon) {
      let id = this.AREA_OVERLAY_PREFIX + feature.getId() + '_1';
      ids[id] = { "area": geometry.getArea(), "coord": geometry.getInteriorPoint().getCoordinates() };

      if (geometry.getCoordinates()[0].length == 2) {
        this._removeById(feature);
      }
    }

    Object.keys(ids).forEach(id => {
      let overlay = this._map.getOverlayById(id);
      if (overlay != void 0) {
        overlay.getElement().innerHTML = this.formatPlochaPipe.transform(ids[id]["area"], 10000);
        overlay.setPosition(ids[id]["coord"]);
      }
    });
  }


  /**
   * Odebrání overlay popisku dle id.
   * @param feature {Feature<any>} mapový objekt jehož overlay popisku se má odebrat
   */
  private _removeById(feature: Feature<any>): void {
    let ids = [];
    let geometry = feature.getGeometry();
    if (geometry instanceof MultiPolygon) {
      let cnt = 0;
      (geometry as MultiPolygon).getPolygons().forEach(p => {
        cnt += 1;
        ids.push(this.AREA_OVERLAY_PREFIX + feature.getId() + '_' + cnt);
      });
    }
    else {
      ids.push(this.AREA_OVERLAY_PREFIX + feature.getId() + '_1');
    }

    ids.forEach(id => {
      let toRemove = this._map.getOverlayById(id);
      if (toRemove != void 0) {
        this._map.removeOverlay(toRemove);
      }
    });
  }


  /**
   * Odebrání všech overlay popisků s plochou z mapy.
   */
  private _removeAll(): void {
    let areaOverlays = this._map.getOverlays().getArray().filter(x => x.getId().toString().startsWith(this.AREA_OVERLAY_PREFIX));
    for (var i = areaOverlays.length; i > 0; i--) {
      this._map.removeOverlay(areaOverlays[i - 1]);
    }
  }
}
