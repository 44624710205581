import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthLoginComponent } from './Components/Auth/auth-login/auth-login.component';
import { AuthGuard } from './Auth/auth.guard';
import { PlochaComponent } from './Components/plocha/plocha.component';
import { AuthChangePassComponent } from './Components/Auth/auth-change-pass/auth-change-pass.component';
import { CanDeactivateGuard } from './Directives/can-deactivate-guard';
import { SkodyIndexComponent } from './Components/Skody/skody-index/skody-index.component';
import { ListLhcComponent } from './Components/Lhp/list-lhc/list-lhc.component';
import { KnihaLhcComponent } from './Components/Lhp/kniha-lhc/kniha-lhc.component';
import { KnihaOddComponent } from './Components/Lhp/kniha-odd/kniha-odd.component';
import { KnihaDilComponent } from './Components/Lhp/kniha-dil/kniha-dil.component';
import { KnihaPorComponent } from './Components/Lhp/kniha-por/kniha-por.component';
import { KnihaPskComponent } from './Components/Lhp/kniha-psk/kniha-psk.component';
import { KnihaBzlComponent } from './Components/Lhp/kniha-bzl/kniha-bzl.component';
import { KnihaOpComponent } from './Components/Lhp/kniha-op/kniha-op.component';
import { KnihaJpComponent } from './Components/Lhp/kniha-jp/kniha-jp.component';
import { KnihaEtazComponent } from './Components/Lhp/kniha-etaz/kniha-etaz.component';
import { KnihaDrevinaComponent } from './Components/Lhp/kniha-drevina/kniha-drevina.component';
import { VyrobaComponent } from './Components/Vyroba/vyroba/vyroba.component';
import { VyrobaCdEditComponent } from './Components/Vyroba/vyroba-cd-edit/vyroba-cd-edit.component';
import { VyrobaOlEditComponent } from './Components/Vyroba/vyroba-ol-edit/vyroba-ol-edit.component';
import { VyrobaVmlEditComponent } from './Components/Vyroba/vyroba-vml-edit/vyroba-vml-edit.component';
import { VyrobaDochazkaComponent } from './Components/Vyroba/vyroba-dochazka/vyroba-dochazka.component';
import { PestebniIndexComponent } from './Components/Vyroba/pestebni-index/pestebni-index.component';
import { VyrobaMaticeComponent } from './Components/Vyroba/vyroba-matice/vyroba-matice.component';
import { VyrobaPruvodniListyComponent } from './Components/Vyroba/vyroba-pruvodni-listy/vyroba-pruvodni-listy.component';
import { VyrobaUzaverkyComponent } from './Components/Vyroba/vyroba-uzaverky/vyroba-uzaverky.component';
import { VyrobaKontrolyComponent } from './Components/Vyroba/vyroba-kontroly/vyroba-kontroly.component';
import { VyrobaSkladConfigComponent } from './Components/Vyroba/vyroba-sklad-config/vyroba-sklad-config.component';
import { VyrobaSkladReportComponent } from './Components/Vyroba/vyroba-sklad-report/vyroba-sklad-report.component';
import { ProvozniDenikComponent } from './Components/Vyroba/provozni-denik/provozni-denik.component';
import { KnihaDetailComponent } from './Components/Lhp/kniha-detail/kniha-detail.component';
import { LhpAdminGuard } from './Auth/lhp-admin.guard';
import { ListCustomerComponent } from './Components/Zakaznik/list-customer/list-customer.component';
import { SuperAdminGuard } from './Auth/super-admin.guard';
import { ListOrgurComponent } from './Components/Zakaznik/list-orgur/list-orgur.component';
import { ZakaznikAdminGuard } from './Auth/zakaznik-admin.guard';
import { MapContainerComponent } from './Components/Mapa/map-container/map-container.component';
import { CiselnikyListComponent } from './Components/Core/ciselniky-list/ciselniky-list.component';
import { CiselnikyEditComponent } from './Components/Core/ciselniky-edit/ciselniky-edit.component';
import { ListUserComponent } from './Components/Zakaznik/list-user/list-user.component'
import { NacrtyListComponent } from './Components/Nacrty/nacrty-list/nacrty-list.component';
import { NacrtyProjektListComponent } from './Components/Nacrty/nacrty-projekt-list/nacrty-projekt-list.component';
import { NacrtyGuard } from './Auth/nacrty.guard';
import { SkodyAdminGuard } from './Auth/skody-admin.quard';
import { DotazovacComponent } from './Components/Dotazovac/dotazovac/dotazovac.component';


const routes: Routes = [
  { path: '', redirectTo: '/plocha', pathMatch: 'full' },
  { path: 'plocha', component: PlochaComponent, canActivate: [AuthGuard] },
  { path: 'login', component: AuthLoginComponent },
  { path: 'pass', component: AuthChangePassComponent, canActivate: [AuthGuard] },
  // dočasně deaktivovat viz #26384 { path: 'register', component: AuthRegisterComponent },
  { path: 'skody', component: SkodyIndexComponent, canActivate: [AuthGuard, SkodyAdminGuard] },
  { path: 'lhp/config/lhc', component: ListLhcComponent, canActivate: [AuthGuard, LhpAdminGuard] },
  { path: 'kniha', component: KnihaDetailComponent, canActivate: [AuthGuard] },
  { path: 'kniha/:lhcKod/:guid', component: KnihaLhcComponent },
  { path: 'kniha/:lhcKod/:odd/:guid', component: KnihaOddComponent },
  { path: 'kniha/:lhcKod/:odd/:dil/:guid', component: KnihaDilComponent },
  { path: 'kniha/:lhcKod/:odd/:dil/:por/:guid', component: KnihaPorComponent },
  { path: 'kniha/:lhcKod/:odd/:dil/:por/psk/:psk/:guid', component: KnihaPskComponent },
  { path: 'kniha/:lhcKod/:odd/:dil/:por/bzl/:bzl/:katuzeKod/:guid', component: KnihaBzlComponent },
  { path: 'kniha/:lhcKod/:odd/:dil/:por/op/:op/:katuzeKod/:guid', component: KnihaOpComponent },
  { path: 'kniha/:lhcKod/:odd/:dil/:por/jp/:jp/:katuzeKod/:guid', component: KnihaJpComponent },
  { path: 'kniha/:lhcKod/:odd/:dil/:por/psk/:psk/:etaz/:guid', component: KnihaEtazComponent },
  { path: 'kniha/:lhcKod/:odd/:dil/:por/psk/:psk/:etaz/:drevina/:guid', component: KnihaDrevinaComponent },
  { path: 'mapa', component: MapContainerComponent },
  { path: 'vyroba', redirectTo: '/vyroba/vml', pathMatch: 'full' },
  { path: 'vyroba/:doklad', component: VyrobaComponent },
  { path: 'vyroba/cd/:state/:guid', component: VyrobaCdEditComponent },
  { path: 'vyroba/ol/:state/:guid', component: VyrobaOlEditComponent },
  { path: 'vyroba/vml/:state/:guid', component: VyrobaVmlEditComponent, canDeactivate: [CanDeactivateGuard] },
  { path: 'dochazka', component: VyrobaDochazkaComponent },
  { path: 'pestebni', component: PestebniIndexComponent },
  { path: 'vyroba/config/matice', component: VyrobaMaticeComponent },
  { path: 'vyroba/config/pruvodni', component: VyrobaPruvodniListyComponent },
  { path: 'vyroba/config/uzaverky', component: VyrobaUzaverkyComponent },
  { path: 'dotazovac', component: DotazovacComponent },
  { path: 'vyroba/config/kontroly', component: VyrobaKontrolyComponent },
  { path: 'vyroba/config/sklad', component: VyrobaSkladConfigComponent },
  { path: 'vyroba/report/sklad', component: VyrobaSkladReportComponent },
  { path: 'denik', component: ProvozniDenikComponent },
  { path: 'zakaznik/config', component: ListCustomerComponent, canActivate: [AuthGuard, SuperAdminGuard] },
  { path: 'zakaznik/config/org-ur', component: ListOrgurComponent, canActivate: [AuthGuard, ZakaznikAdminGuard] },
  { path: 'zakaznik/config/uzivatele', component: ListUserComponent, canActivate: [AuthGuard, ZakaznikAdminGuard] },
  { path: 'config/ciselniky', component: CiselnikyListComponent },
  { path: 'config/ciselniky/edit/:nazev/:guid', component: CiselnikyEditComponent },
  { path: 'nacrty', component: NacrtyListComponent, canActivate: [AuthGuard, NacrtyGuard], canDeactivate: [CanDeactivateGuard] },
  { path: 'nacrty/projekty', component: NacrtyProjektListComponent, canActivate: [AuthGuard, NacrtyGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
