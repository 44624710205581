import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LhcListAdminDto } from '../../Dto/Lhp/LhcListAdminDto';
import { ResultInfoDto, ResultInfoGenericDto } from '../../Dto/Shared/ResultInfoDto';


/**
 * Services pro administraci v modulu LHP.
**/
@Injectable({
  providedIn: 'root'
})
export class LhpAdminServices {

  private readonly CODE_FORBIDDEN: number = 403;
  private readonly apiLhcList: string = 'api/LhpAdmin/List';
  private readonly apiLhcDelete: string = 'api/LhpAdmin/DeleteLhc';
  private readonly apiLhcSetPorOrgUr: string = 'api/LhpAdmin/SetPorOrgUr';
  constructor(private http: HttpClient)
  { }

  /**
  * Vrátí seznam Lhc
  */
  lhcList(): Observable<ResultInfoGenericDto<LhcListAdminDto[]>> {
    return this.http.get<ResultInfoGenericDto<LhcListAdminDto[]>>(this.apiLhcList)
      .pipe(
        catchError(this._authErr.bind(this))
      );
  }

  /**
   * Smaže Lhc a vrátí info o úspěchu
   * @param guid
   */
  delete(guid: string): Observable<ResultInfoDto>
  {
    var param = new HttpParams();
    param = param.append('guid', guid);
    return this.http.delete<ResultInfoDto>(this.apiLhcDelete, { params: param });
  }

  /**
   * Handler chybového stavu http clienta. Zpracovává stav 403, kdy server odmítne request kvůli oprávnění.
   * @param err
   * @param caught
   */
  private _authErr(err: any, caught: Observable<ResultInfoGenericDto<LhcListAdminDto[]>>) {
    if (err.status == this.CODE_FORBIDDEN) {
      let res: ResultInfoGenericDto<LhcListAdminDto[]> = new ResultInfoGenericDto<LhcListAdminDto[]>();
      res.success = false;
      res.messages = ["Nemáte dostatečná oprávnění."];
      res.data = null;
      return of(res);
    }
    else {
      return caught;
    }
  }

  setPorOrgUr(orgur: string, porosty: string[]): Observable<ResultInfoDto> {
    var param = new HttpParams();
    param = param.append('orgur', orgur);
    return this.http.post<ResultInfoDto>(this.apiLhcSetPorOrgUr, porosty, { params: param });
  }
}
