import { Component, ElementRef, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import  Map  from 'ol/Map';
import  View  from 'ol/View';
import  Layer from 'ol/layer/Layer';
import { Extent } from 'ol/extent';
import { Coordinate } from 'ol/coordinate';
import proj4 from 'proj4';
import { register } from 'ol/proj/proj4';
import { Control, defaults as defaultControls, ScaleLine } from 'ol/control';
import { Draw, Interaction, Modify, Snap } from 'ol/interaction';
import DrawHole from 'ol-ext/interaction/DrawHole';
import { DrawEvent } from 'ol/interaction/Draw';
import { ModifyEvent } from 'ol/interaction/Modify';
import GeometryType from 'ol/geom/GeometryType';
import Feature from 'ol/Feature';
import { ConstructMapLayerUtils } from '../../../Utils/Mapa/construnct-map-layer.utils';
import { MapItemDto } from '../../../Dto/Mapa/MapItemDto';
import { MessageService } from 'primeng/api';
import { ILayerFeatures } from '../../../Dto/Mapa/IFeatureToRemove';
import VectorSource from 'ol/source/Vector';
import { UuidUtils } from '../../../Utils/Shared/uuid.utils';
import { NacrtMapItemDto, NacrtMapItemPostSaveDto } from '../../../Dto/Nacrty/NacrtMapItemDto';
import { MapInteractionService } from '../../../Services/Mapa/map-interaction.service';
import { OffsetlInfoDto } from '../../../Dto/Mapa/OffsetlInfoDto';
import { ExtentUtils } from '../../../Utils/Mapa/extent.utils';
import { LineString, MultiPolygon, Point, Polygon } from 'ol/geom';
import { IMultipolygonJoinIntoArgs } from '../../../Dto/Mapa/IMultipolygonJoinIntoArgs';
import { ISchvalWorkflowDto } from '../../../Dto/Nacrty/ISchvalWorkflowDto';
import { NacrtSchvalWorkflowDto } from '../../../Dto/Nacrty/NacrtSchvalWorkflowDto';
import { NacrtySchvalitInteractionService } from '../../../Services/Nacrty/nacrty-schvalit-interaction.service';
import { Subscription } from 'rxjs';
import { SnapInteractionService } from '../../../Services/Mapa/snap-interaction.service';
import { SnappingChangedArgs, SnappingType } from '../../../Dto/Mapa/SnappingChangeArgs';
import { LayersInteractionService } from '../../../Services/Mapa/layers-interaction.service';
import { MapServices } from '../../../Services/Mapa/map.service';
import { LayerFilterUtils } from '../../../Utils/Mapa/layer-filter';
import { NacrtyStyleInteractionService } from '../../../Services/Nacrty/nacrty-style-interaction.service';
import { AreaOverlayInteractionService } from '../../../Services/Mapa/area-overlay-interaction.service';
import { LocalStorageLayersInteractionService } from '../../../Services/Mapa/local-storage-layers-interaction.service';
import VectorLayer from 'ol/layer/Vector';


/**
 * Komponenta mapového okna.
**/
@Component({
  encapsulation: ViewEncapsulation.None,  //bez tohoto byl problem s ol.css
  selector: 'app-map',
  //Element, do kterého vykresluje OpenLayer mapa musí být přímým potomkem Flex containeru.
  //POZOR na správné nastavení Id komponenty<app-map />
  template: '',
  styleUrls: ['./map.component.css', '../../../../../node_modules/ol/ol.css']
})
export class MapComponent implements OnInit, OnDestroy {
  /**
   * Kolekce vrstev, se kterými bude mapa pracovat. (Pozor: nemusí obsahovat defaultní mapové vrstvy - výběrovou, lomové body, atp.)
   * Default: [];
   * Required.
  **/
  private _layers: Layer<any, any>[] = [];
  @Input() set Layers(value: Layer<any, any>[]) {
    if (value != void 0) {
      this._layers = value.slice();
      this._addLayers(this._layers);
    }
  }


  /**
   * Seznam názvů vrstev, které mají být zobrazeny.
  **/
  @Input() set VisibleLayers(value: string[]) {
    if (value != void 0) {
      this._setVisibleLayers(value);
    }
  }


  /**
   * Zobrazení ovládacích prvků mapy (tlačítka +/-, měřítko).
  **/
  private _showMapControls: boolean = true;
  @Input() set ShowMapControls(value: boolean) {
    if (value != void 0) {
      this._showMapControls = value;
      this._displayMapControls(this._showMapControls);
    }
  }


  constructor(
    private element: ElementRef, //element componenty
    private constructLayerUtils: ConstructMapLayerUtils,
    private messageService: MessageService,
    private uuidUtils: UuidUtils,
    private extentUtils: ExtentUtils,
    private mapService: MapServices,
    private layerFilterUtils: LayerFilterUtils,
    private mapInteractionService: MapInteractionService,
    private layersInteractionService: LayersInteractionService,
    private localStoragelayersInteractionService: LocalStorageLayersInteractionService,
    private snapInteractionService: SnapInteractionService,
    private nacrtStyleInteractionService: NacrtyStyleInteractionService,
    private nacrtySchvalitInteractionService: NacrtySchvalitInteractionService,
    private areaOverlayInteractionService: AreaOverlayInteractionService)
  {
    this._createMapSubs = this.mapInteractionService.CreateMap.subscribe(this._createMap.bind(this));
    this._refreshSourcesSubs = this.layersInteractionService.RefreshSources.subscribe(this._refreshSources.bind(this));
  }


  /**
   * Instance OpenLayer mapy.
  **/
  private _map: Map;
  readonly TOAST_KEY: string = 'mapContainerToastKey';
  private readonly SELECTABLE_LAYER_NAME: string = 'selectableLayer';
  private readonly VERTICIES_LAYER_NAME: string = 'verticiesLayer';
  private readonly DRAW_LAYER_NAME: string = 'drawLayer';
  private readonly DIM_OVERLAY_NAME: string = 'dimensionsOverlay';
  private readonly SNAP_LAYER_NAME: string = 'snapLayer';
  //limit pro aktivní přichytávání. Pokud zoom < 17 (mapa oddálena) je přichytávání neaktivní
  private readonly SNAP_ZOOM_LIMIT: number = 17;
  //vrstva pro výběry
  private _selectableLayer: Layer<any, any>;
  //vrstva pro zobrazení lomových bodů
  private _verticiesLayer: Layer<any, any>;
  //vrstva pro kreslení/úpravy featur
  private _drawLayer: Layer<any, any>;
  //vrstva pro výpis rozměrů featur
  private _dimensionsOverlay: Layer<any, any>;
  //vrstva pro přichytávání
  private _snapLayer: Layer<any, any>;
  //centroid extentu ČR dle bounding-boxu z WMS Ortofoto. Definuje výchozí pozici mapy.
  private _initCenter: Coordinate = [-667500, -1081000];
  //výchozí úroveň přiblížení mapy
  private _initZoom: number = 9;
  //OL interakce úprav existující featury
  private _modify: Modify;
  //OL interakce kreslení nové featury
  private _draw: Draw;
  //OL-Ext interakce kreslení enkláv
  private _drawHole: DrawHole;
  //OL interakce přichytávání
  private _snap: Snap;
  //poslední stav nastavení přichytávání k lomovým bodům
  private _snapVertex: boolean = false;
  //poslední stav nastavení přichytávání k hranám
  private _snapEdge: boolean = false;
  //právě kreslený mapový objekt. Je potřeba pro zaznamenávání procesu kreslení k případnému undo
  //(interakce kreslení neposkytuje události pro jednotlivé změny stavu featury jako interakce modify...)
  private _drawingFeature: Feature<any> = null;
  //indikátor prvního pohybu mapy (pohyb mapy nastává i po její inicializaci)
  private _firstMapMove: boolean = true;
  //poslední stav upravovaného náčrtu - slouží k tomu, aby se předávaly jen skutečně provedené změny geometrie
  private _lastFeatureState: Feature<any> = null;
  //příznak, zda se zobrazují lomové body vybraných mapových objektů
  private _showVertex: boolean = false;

  private _drawStartSubs: Subscription;
  private _discardChangesSubs: Subscription;
  private _editFeatureSubs: Subscription;
  private _nacrtFinishedSubs: Subscription;
  private _nacrtDeleteSubs: Subscription;
  private _copySubs: Subscription;
  private _zoomToSubs: Subscription;
  private _controlOffsetSubs: Subscription;
  private _selectedItemsSubs: Subscription;
  private _undoEditSubs: Subscription;
  private _undoDrawSubs: Subscription;
  private _multipolygonJoinIntoSubs: Subscription;
  private _createMapSubs: Subscription;
  private _schvaleniPostprocessSubs: Subscription;
  private _drawEnclaveSwitchSubs: Subscription;
  private _snapChangedSubs: Subscription;
  private _snapActivateSubs: Subscription;
  private _renderSyncSubs: Subscription;
  private _toggleVertexSubs: Subscription;
  private _featureChangedSubs: Subscription;
  private _refreshSourcesSubs: Subscription;

  /**
   * Inicializace komponenty.
  **/
  ngOnInit(): void {
    this._layers = [];
    this._map = null;
    this._registerKrovak();
  }


  /**
   * Destroy komponenty.
  **/
  ngOnDestroy(): void {
    this._createMapSubs.unsubscribe();
    this._refreshSourcesSubs.unsubscribe();

    this._drawStartSubs?.unsubscribe();
    this._discardChangesSubs?.unsubscribe();
    this._editFeatureSubs?.unsubscribe();
    this._nacrtFinishedSubs?.unsubscribe();
    this._nacrtDeleteSubs?.unsubscribe();
    this._copySubs?.unsubscribe();
    this._zoomToSubs?.unsubscribe();
    this._controlOffsetSubs?.unsubscribe();
    this._selectedItemsSubs?.unsubscribe();
    this._undoEditSubs?.unsubscribe();
    this._undoDrawSubs?.unsubscribe();
    this._multipolygonJoinIntoSubs?.unsubscribe();
    this._schvaleniPostprocessSubs?.unsubscribe();
    this._drawEnclaveSwitchSubs?.unsubscribe();
    this._snapChangedSubs?.unsubscribe();
    this._snapActivateSubs?.unsubscribe();
    this._renderSyncSubs?.unsubscribe();
    this._toggleVertexSubs?.unsubscribe();
    this._featureChangedSubs?.unsubscribe();
  }

  /**
   * Registrace křovákova zobrazení do OpenLayers
  **/
  private _registerKrovak(): void {
    proj4.defs("EPSG:5514", "+proj=krovak +lat_0=49.5 +lon_0=24.83333333333333 +alpha=30.28813972222222 +k=0.9999 +x_0=0 +y_0=0 +ellps=bessel +towgs84=589,76,480,0,0,0,0 +units=m +no_defs");
    register(proj4);
  }


  /**
   * Registrace handlerů potřebných po vytvoření mapy.
  **/
  private _registerAfterMapCreateHandlers(): void {
    this._drawStartSubs = this.mapInteractionService.DrawStart.subscribe(this._addMapInteractions.bind(this));
    this._discardChangesSubs = this.mapInteractionService.DiscardChanges.subscribe(this._afterDiscardChanges.bind(this));
    this._editFeatureSubs = this.mapInteractionService.EditFeature.subscribe(this._editFeature.bind(this));
    this._nacrtFinishedSubs = this.mapInteractionService.NacrtFinished.subscribe(this._afterEdit.bind(this));
    this._nacrtDeleteSubs = this.mapInteractionService.NacrtDelete.subscribe(this._removeFeatureHandler.bind(this));
    this._copySubs = this.mapInteractionService.Copy.subscribe(this._copyHandler.bind(this));
    this._zoomToSubs = this.mapInteractionService.ZoomTo.subscribe(this._zoomTo.bind(this));
    this._controlOffsetSubs = this.mapInteractionService.ControlOffset.subscribe(this._setControlOffset.bind(this));
    this._selectedItemsSubs = this.mapInteractionService.SelectedItems.subscribe(this._selectFeatures.bind(this));
    this._undoEditSubs = this.mapInteractionService.UndoEdit.subscribe(this._undoEdit.bind(this));
    this._undoDrawSubs = this.mapInteractionService.UndoDraw.subscribe(this._undoDraw.bind(this));
    this._multipolygonJoinIntoSubs = this.mapInteractionService.MultipolygonJoinInto.subscribe(this.multipolygonJoinIntoHandler.bind(this));
    this._drawEnclaveSwitchSubs = this.mapInteractionService.DrawEnclave.subscribe(this._switchEnclaveHandler.bind(this));
    this._toggleVertexSubs = this.mapInteractionService.VertexToggle.subscribe(this._vertexToggleHandler.bind(this));
    this._featureChangedSubs = this.mapInteractionService.FeatureChanged.subscribe(this._featureChangedHandler.bind(this));

    this._schvaleniPostprocessSubs = this.nacrtySchvalitInteractionService.SchvaleniPostprocess.subscribe(this._schvalitPostProcess.bind(this));

    this._snapChangedSubs = this.snapInteractionService.SnapingChanged.subscribe(this._snappingChangedHandler.bind(this));
    this._snapActivateSubs = this.snapInteractionService.Activate.subscribe(this._activateSnapHandler.bind(this));

    this._renderSyncSubs = this.nacrtStyleInteractionService.RenderMapSync.subscribe(_ => { if (this._map != void 0) this._map.renderSync(); });
  }


  /**
   * Vytvoření výchozích mapových vrstev.
  **/
  private async _createDefaultLayers(): Promise<Layer<any, any>[]>  {
    return Promise.all([
      this._selectableLayer = await this.constructLayerUtils.constructLayerForSelections(this.SELECTABLE_LAYER_NAME),
      this._verticiesLayer = await this.constructLayerUtils.constructVerticiesLayer(this.VERTICIES_LAYER_NAME),
      this._drawLayer = await this.constructLayerUtils.constructLayerForDraw(this.DRAW_LAYER_NAME),
      this._dimensionsOverlay = await this.constructLayerUtils.constructLayerForDimensions(this.DIM_OVERLAY_NAME),
      this._snapLayer = await this.constructLayerUtils.constructLayerForSnap(this.SNAP_LAYER_NAME),
    ]);
  }

  
  /**
   * Vytvoření mapy
   * @param idToCreate {string} Id mapy
  **/
  private _createMap(idToCreate: string): void {
    this._registerAfterMapCreateHandlers();
    this._createDefaultLayers().then((defaultLayers) => {
      let mapId = this.element.nativeElement.id;
      if (mapId == void 0 || mapId == '') {
        console.error('Není nastaveno Id elementu pro vykreslení mapy.');
        return;
      }

      if (mapId != idToCreate) {
        return;
      }

      this._layers = this._layers.concat(defaultLayers);

      this._map = new Map({
        target: mapId,
        controls: defaultControls().extend([
          new ScaleLine({
            units: "metric"
          })
        ]),
        layers: this._layers,
        view: new View({
          center: this._initCenter,
          projection: 'EPSG:5514', //souřadnicový systém S-JTSK Křovák East-North,
          zoom: this._initZoom
        })
      });

      this._aferMapConstuct();
      this.mapInteractionService.mapCreated(this._map);
    });
  }


  /**
   * Akce po vytvoření mapy.
  **/
  private _aferMapConstuct(): void {
    this._map.once('postrender', this._onMapPostRender.bind(this));
    this._map.on('singleclick', this._mapClickHandler.bind(this));
    this._map.on('dblclick', this._mapDblClickHandler.bind(this));
    this._map.on('moveend', this._moveEndHandler.bind(this));
    this._displayMapControls(this._showMapControls);
  }


  /**
   * Handler vykreslení okna mapy. Při prvním zobrazení mapové stránky se může stát,
   * že není správně nastavena výška canvasu, ve kterém je mapa vykreslena. Proto se zde aktualizuje.
   * @param event
   */
  private _onMapPostRender(event): void {
    this.onResized(null);
  }


  /**
   * Handler kliknutí do mapy.
   * @param event {MapBrowserEvent} OpenLayer event kliknutí do mapy.
   */
  private _mapClickHandler(event): void {
    if (this._draw == void 0 && this._modify == void 0) { //"prohlížecí" mód
      this.mapInteractionService.mapClick(event.coordinate, this._map.getView().getZoom(), (event.originalEvent.ctrlKey || event.originalEvent.metaKey));
    }
    else { //edit mód
      this.mapInteractionService.showUndo({ feature: this._drawingFeature, visible: this._draw != void 0 || this.mapInteractionService.FeatureStackCnt > 0 });
    }
  }


  /**
   * Handler dvojkliku do mapy.
   * @param event {MapBrowserEvent} OpenLayer event dvoj kliknutí do mapy.
   */
  private _mapDblClickHandler(event): void {
    //zabrání přiblížení mapy při dvojkliku.
    event.preventDefault();
    return;
  }


  /**
   * Handler ukončení pohybu mapy. Aktualizuje extent v URL.
   * @param event
   */
  private _moveEndHandler(event): void {
    console.debug('zoom level:', this._map.getView().getZoom());
    let extent = this.extentUtils.getMapExtent(event.map);
    localStorage.setItem(this.mapInteractionService.LAST_POSITION_KEY, extent.join(','));

    if (this._firstMapMove) {
      this._firstMapMove = false;
      return;
    }

    this.mapInteractionService.moveEnd(extent);
    this.snapInteractionService.activate(this._map.getView().getZoom() > this.SNAP_ZOOM_LIMIT);
  }


  /**
   * Aktualizace zdrojů vrstev.
  **/
  private _refreshSources(): void {
    this.mapInteractionService.clearSelection();
    this._map.getLayers().getArray().forEach((l: Layer<any, any>) => l.getSource().refresh());
  }


  /**
   * Přidání vrstev do mapy. Pokud již mapa danou vrstvu obsahuje, vrstva se nepřidá, ale v případě vektorové vrstvy aktualizuje obsah
   * @param layersToAdd
   */
  private _addLayers(layersToAdd: Layer<any, any>[]): void {
    if (this._map == void 0) return;

    layersToAdd.forEach((layer: Layer<any, any>) => {
      let layerName = layer.getProperties().name;

      let mapLayerIdx = this._map.getLayers().getArray().findIndex(x => x.getProperties().name == layerName);
      if (mapLayerIdx == -1) {
        this._map.addLayer(layer);
      }
    });

    this.layersInteractionService.layersLoaded();
  }


  /**
   * Nastavení viditelnosti vrstev.
   * @param layerNames Seznam názvů vrstev, které mají být zobrazeny.
   */
  private _setVisibleLayers(layerNames: string[]): void {
    if (this._map == void 0) return;

    this._map.getLayers().getArray()
      .filter((layer: Layer<any, any>) =>
        layer.getProperties().name != this.SELECTABLE_LAYER_NAME
        && layer.getProperties().name != this.VERTICIES_LAYER_NAME
        && layer.getProperties().name != this.DRAW_LAYER_NAME
        && layer.getProperties().name != this.DIM_OVERLAY_NAME
        && layer.getProperties().name != this.SNAP_LAYER_NAME
      )
      .forEach((layer: Layer<any, any>) => {
        let layerName = layer.getProperties().name;
        let layerToShowIdx = layerNames.findIndex(x => x == layerName);
        let visible = layerToShowIdx > -1 ? true : false;
        layer.setVisible(visible);
        if (visible && layer instanceof VectorLayer)
          layer.getSource().refresh();
      });

    this._updateSnappedGeometries();
  }


  /**
   * Nastaví posun elementům z OpenLayers (tlačítka pro zoom a měřítko).
   * @param value {OffsetlInfoDto} informace potřebné k odsazení ovládacích prvků mapy
   */
  private _setControlOffset(value: OffsetlInfoDto): void {
    if (value.panel == 'layerPanel' && this._map != void 0) {
      this._map.getControls().forEach((control: Control) => {
        let classList = control['element'].classList;
        if (classList.contains('ol-zoom') || classList.contains('ol-scale-line')) {
          this._setTranslate(control['element'], value.offset);
        }
      });
    }
  }


  /**
   * Nastavení transformace - posun po ose X
   * @param element 
   * @param value
   */
  private _setTranslate(element: any, value: string): void {
    element.style.transition = 'transform .3s';
    element.style.transform = 'translateX(' + value + ')';
  }


  /**
   * Nastaví mapu na zadaný extent.
   * @param bbox {Extent} Bounding-box, na který se má mapa nastavit ([minx, miny, maxx, maxy]).
   */
  private _zoomTo(bbox: Extent): void {
    if (this._map != void 0 && this.extentUtils.getMapExtent(this._map) != bbox) {
      this._map.getView().fit(bbox, { size: this._map.getSize() });
    }
  }


  /**
   * Zobrazí/skryje ovládací prvky mapy.
   * @param visible {boolean} true, pokud mají být ovládací prvky mapy viditelné
  **/
  private _displayMapControls(visible: boolean): void {
    if (this._map == void 0) return;
    this._map.getControls().forEach((control) => {
      control['element'].style.visibility = visible ? 'visible' : 'hidden';
    });
  }


  /**
   * Přidání mapových objektů do výběrové vrstvy.
   * Nahrazuje původní výběr.
   * @param featuresDto {MapItemDto[]} kolekce zdrojových dat pro vytvoření featur
   */
  private _selectFeatures(featuresDto: MapItemDto[]): void {
    if (this._map == void 0) return;

    if (this._selectableLayer != void 0) {
      let source = this._selectableLayer.getSource();
      source.clear();

      featuresDto.filter(dto => dto.wkt.startsWith('POINT')).forEach(dto => {
        if (dto.modul == 'Nacrty') {
          let sourceL = this._map.getLayers().getArray().find(l => l.getProperties().name == 'nacrty_' + dto['projektGuid']);
          if (sourceL != void 0) {
            dto['styleDef'] = sourceL.get('styleDef');
          }
        }
      });

      let features = this.constructLayerUtils.convertFromSource(featuresDto);
      source.addFeatures(features);

      //zobrazení lomových bodů vybraných objektů (zobrazujeme pouze pro plochy a linie)
      if (this._verticiesLayer != void 0) {
        this._addToVertexLayer(features);
      }

      //popisky vybraných mapových objektů se budou zobrazovat pouze mimo kreslení/editaci
      if (this._draw == void 0 && this._modify == void 0 && this._drawHole == void 0)
      {
        this.fillDimOverlay(features);
        this.areaOverlayInteractionService.add(features);
      }

      //zoom se proveden pouze, pokud existují nejaké mapové objekty, na které lze přiblížit
      if (source.getFeatures().length > 0) {
        this.mapInteractionService.zoomToIfNoUrlBbox(this.extentUtils.addBuffer(source.getExtent()));
      }
    }
  }


  /**
   * Handler změny velikosti mapového okna.
   * @param event
   */
  public onResized(event): void {
    if (this._map != void 0) {
      this._map.updateSize();
    }
  }


  /**
   * Kontrola podporovaných typů geometrie.
   * @param geometryType {string} typ geometrie
   */
  private _validateGeometryType(geometryType: string): boolean {
    return geometryType == GeometryType.MULTI_POLYGON || geometryType == GeometryType.LINE_STRING || geometryType == GeometryType.POINT;
  }


  /**
   * Zahájení kreslení náčrtu.
   * @param geometryType {string} typ geometrie, který se bude kreslit
   */
  private _drawStart(geometryType: string): void {
    this._draw = new Draw({
      source: this._drawLayer.getSource(),
      type: geometryType
    });

    // nastaví z-index kreslící vrstvy tak, aby nepřekrývala vrstvu s délkami linií
    this._draw.getOverlay().setZIndex(10000);

    // po začátku kreslení přiradí kreslenému náčrtu event handler pro změnu geometrie náčrtu
    this._draw.on('drawstart', this._drawStartHandler.bind(this));
    this._draw.on('drawend', this._drawEndHandler.bind(this));
    this._map.addInteraction(this._draw);
  }


  /**
   * Handler události zahájeného kreslení mapového objektu.
   * @param event {DrawEvent} událost zahájeného kreslení
   */
  private _drawStartHandler(event: DrawEvent): void {

    let drawFeatures = this._drawLayer.getSource().getFeatures();

    //Všechny plošné objekty v mapě jsou multipolygony, tj. jedna featura, ale několik ploch, které je potřeba popsat/zobrzit overlay popisek s jejich plochou.
    //Jednotlivé plochy v rámci multipolygonu jsou odlišeny suffixem "_" + "pořadové číslo polygonyu".
    //OL DrawEvent obsahuje polygon, nikoliv multipolygon. Proto se zde zjistí, zda jde o první nebo nějakou další plochu v rámci kreslené featury a dle toho se nastaví id kreslenému polygonu.
    //Toto id se pak používá pro aktualizaci zobrazované plochy polygonu v overlay popisku při změně jeho geometrie.
    let id;
    if (drawFeatures.length > 0 && drawFeatures[0].getGeometry() instanceof MultiPolygon) {
      id = drawFeatures[0].getId() + '_' + ((drawFeatures[0].getGeometry() as MultiPolygon).getPolygons().length + 1);
    }
    else {
      id = this.uuidUtils.GenerateUuid() + '_1';
    }

    this._drawingFeature = event.feature;
    this._drawingFeature.setId(id);
    this.areaOverlayInteractionService.add([...drawFeatures, this._drawingFeature]);
    this._drawingFeature.on('change', this._drawChangeHandler.bind(this));
    this._addToVertexLayer([...this._getFeaturesForVertex(), this._drawingFeature]);
  }
  

  /**
   * Handler změny kresleného mapového objektu.
   * @param event {BaseEvent} událost změny upravovaného objektu
   */
  private _drawChangeHandler(event): void {
    let featureToDim = this._cloneFeature(event.target);

    if (featureToDim.getGeometry() instanceof Polygon
      || featureToDim.getGeometry() instanceof MultiPolygon) {
      this.areaOverlayInteractionService.update(featureToDim);
      let features: Feature<any>[] = this._drawLayer.getSource().getFeatures().slice();
      features.push(featureToDim);
      featureToDim = this._getMultipolygon(features);
    }

    this.fillDimOverlay([featureToDim]);
    this.mapInteractionService.drawGeometryChanged(featureToDim);
  }


  /**
   * Hanler ukončení kreslení mapového objektu.
   * @param event {DrawEvent} událost ukončeného kreslení
   */
  private _drawEndHandler(event: DrawEvent): void {
    let clon = this._cloneFeature(event.feature);

    if (this._drawingFeature.getGeometry() instanceof Polygon
      || this._drawingFeature.getGeometry() instanceof MultiPolygon)
    {
      let features: Feature<any>[] = this._drawLayer.getSource().getFeatures().slice();
      features.push(clon);
      let newMpF = this._getMultipolygon(features);
      this.areaOverlayInteractionService.add([newMpF]);
      this.mapInteractionService.featureChanged(newMpF);

      //Dokončilo se kreslení nového polygonu, čímž vznikl nový multipolygon. Tím je potřeba nahradit existující featury ve vrstvě pro kreslení
      //a pověsit na něj handler události 'change', pro sledování jeho následných změn.
      //PDd POZOR: Nově nakreslený polygon z interakce "Draw" se do cílové vrstvy (this._drawLayer) zapíše až po vypuštění události 'drawEnd' a proto musíme aktualizaci
      //featury ve vrstvě pro kreslení provést až po provedené změně vrstvy pro kreslení. Jinak dojde k tomu, že je tam nově nakreslená část 2x.
      this._drawLayer.once('change', this._drawLayerChangeHandler.bind(this, newMpF));
    }
    else {
      this._removeDrawInteraction();
      this.mapInteractionService.featureChanged(clon);
    }

    this._drawingFeature = null;
  }


  /**
   * Handler změny vrstvy pro kreslení (volá se např. po přidání nové featury do vrstvy).
   * @param feature {Feature<any>} nový mapový objekt, kterým nahradíme stávající objekty vrstvy pro kresení
   */
  _drawLayerChangeHandler(feature): void {
    this._drawLayer.getSource().clear();
    this._drawLayer.getSource().addFeature(feature);
    feature.on('change', this._editChangeHandler.bind(this));
  }


  /**
   * Ze vstupních mapových objektů vytvoří Multipolygon.
   * @param features {Feature<any>} kolekce mapových objektů, ze kterých se bude skládat Multipolygon
   */
  private _getMultipolygon(features: Feature<any>[]): Feature<MultiPolygon> {
    let polygons = [];
    let geoms = features.map(f => { return f.getGeometry(); });
    geoms.forEach(g => {
      if (g instanceof MultiPolygon) {
        polygons.push(...g.getPolygons());
      }
      else {
        polygons.push(g);
      }
    });

    let feature = new Feature(new MultiPolygon(polygons));

    let featuresWithId = features.filter(f => f.getId() != void 0);
    if (featuresWithId.length > 0) {
      feature.setId(featuresWithId[0].getId());
    }

    return feature;
  }


  /**
   * Zahájení editace featury
   * @param drawSource {VectorSource<any>} zdroj vektorové vrstvy, ve které bude probíhat editace
   */
  private _modifyStart(drawSource: VectorSource<any>): void {
    this._modify = new Modify({
      source: drawSource
    });

    this._modify.on('modifyend', this._modifyEndHandler.bind(this));
    this._map.addInteraction(this._modify);
  }


  /**
   * Handler ukončení změny mapového objektu.
   * @param event {ModifyEvent} událost ukončení změny mapového objektu
   */
  private _modifyEndHandler(event: ModifyEvent): void {
    let feature: Feature<any> = this._cloneFeature(event.features.getArray()[0] as Feature<any>);

    if (feature.getGeometry() instanceof MultiPolygon) {
      let features: Feature<any>[] = this._drawLayer.getSource().getFeatures().slice();
      feature = this._getMultipolygon(features);
      this.areaOverlayInteractionService.add([feature]);
    }

    //geometrie se reálně proti původnímu stavu nezměnila
    if (this._lastFeatureState != void 0 && this._lastFeatureState.getGeometry().flatCoordinates.join(',') == feature.getGeometry().flatCoordinates.join(',')) {
      return;
    }
    else {
      this._lastFeatureState = feature;
      this.mapInteractionService.featureChanged(feature);
    }
  }


  /**
   * Přidání interakce kreslení enkláv.
  **/
  private _drawHoleStart(): void {
    this._drawHole = new DrawHole({
      layers: [this._drawLayer]
    });
    this._drawHole.setActive(false);

    this._drawHole.on("drawstart", this._drawHoleStartHandler.bind(this));
    this._drawHole.on("drawend", this._drawHoleEndHandler.bind(this));

    this._map.addInteraction(this._drawHole as Interaction);
  }


  /**
   * Handler zahájeného kreslení enklávy.
   * @param event {DrawEvent} událost zahájeného kreslení enklávy
   */
  private _drawHoleStartHandler(event: DrawEvent): void {
    this._drawingFeature = event.feature;
  }


  /**
   * Handler ukončeného kreslení enklávy.
   * @param event {DrawEvent} událost ukončeného kreslení enklávy
   */
  private _drawHoleEndHandler(event: DrawEvent): void {
    let features: Feature<any>[] = this._drawLayer.getSource().getFeatures().slice();
    let newMpF = this._getMultipolygon(features);
    this.areaOverlayInteractionService.add([newMpF]);
    this.mapInteractionService.featureChanged(newMpF);
    this._drawingFeature = null;
  }


  /**
   * Vytvoření interakce přichytávání.
  **/
  private _snapStart(): void {
    this._snap = new Snap({
      source: this._snapLayer.getSource(), //nebo použít pouze kolekci featur, kterou si stejně budu muset sestavit?
      edge: this._snapEdge,
      vertex: this._snapVertex
    });

    this.snapInteractionService.activate(this._map.getView().getZoom() > this.SNAP_ZOOM_LIMIT);
    this._map.addInteraction(this._snap);
    this.snapInteractionService.showSnapSwitch(true);
  }


  /**
   * Přidání interakcí do mapy.
   * @param geometryType {string} Typ geometrie, která se bude kreslit nebo null u editace.
   */
  private _addMapInteractions(geometryType: string = null): void {
    if (geometryType != void 0 && geometryType != '') { //draw
      if (!this._validateGeometryType(geometryType)) {
        this.messageService.add({ severity: 'error', summary: 'Chyba', detail: 'Nepodporovaný typ geometrie "' + geometryType + '"', key: this.TOAST_KEY });
        return;
      }

      this._drawStart(geometryType);
      this._modifyStart(this._drawLayer.getSource());
      if (geometryType == GeometryType.MULTI_POLYGON) {
        this._drawHoleStart();
        this.mapInteractionService.showEnclaveToggler(true);
      }
    }
    else { //edit
      this._modifyStart(this._drawLayer.getSource());

      let features = this._drawLayer.getSource().getFeatures();
      if (features.length == 0) { //sem by se to nemělo nikdy dostat!
        this.messageService.add({ severity: 'error', summary: 'Chyba', detail: 'Nelze editovat neexistující geometrie.', key: this.TOAST_KEY });
        return;
      }
      geometryType = features[0].getGeometry().getType();
      if (geometryType == GeometryType.MULTI_POLYGON) {
        this._drawStart(geometryType);
        this._drawHoleStart();
        this.mapInteractionService.showEnclaveToggler(true);
      }
    }

    this._snapStart();
  }


  /**
   * Odebrání interakcí z mapy.
  **/
  private _removeMapInteractions(): void {
    this._removeDrawInteraction();
    this._removeModifyInteraction();
    this._removeDrawHoleInteraction();
    this._removeSnapInteraction();
  }

  /**
   * Odebrání interakce kreslení z mapy.
  **/
  private _removeDrawInteraction(): void {
    this._map.removeInteraction(this._draw);
    this._draw = null;
  }

  /**
   * Odebrání interakce editace z mapy.
  **/
  private _removeModifyInteraction(): void {
    this._map.removeInteraction(this._modify);
    this._modify = null;
  }

  /**
   * Odebrání interakce kreslení enkláv z mapy.
  **/
  private _removeDrawHoleInteraction(): void {
    this._map.removeInteraction(this._drawHole as Interaction);
    this._drawHole = null;
  }

  /**
   * Odebrání interakce přichytávání.
  **/
  private _removeSnapInteraction(): void {
    this._map.removeInteraction(this._snap);
    this._snapLayer.getSource().clear();
    this._snap = null;
  }

  /**
   * Metoda, co handluje změnu geometrie kresleného náčrtu.
   * Pro kreslený náčrt sebere souřadnice jednotlivých segmentů (částí liní/polygonu) a zobrazí pro něj délky.
   * @param evt draw event
   */
  public fillDimOverlay = (features) => {
    this._dimensionsOverlay.getSource().clear();

    features.forEach((feature) => {
      let geom = feature.getGeometry();
      if (geom instanceof MultiPolygon) {
        let coords: number[][][][] = geom.getCoordinates();
        for (let i = 0; i < coords.length; i++) {
          for (let j = 0; j < coords[i].length; j++) {
            for (let k = 0; k < coords[i][j].length - 1; k++) {
              this._dimensionsOverlay.getSource().addFeature(
                new Feature({ geometry: new LineString([coords[i][j][k], coords[i][j][k + 1]]) }));
            }
          }
        }

      }
      if (geom instanceof LineString) {
        let coords: number[][] = geom.getCoordinates();
        for (let i = 0; i < coords.length - 1; i++) {
          this._dimensionsOverlay.getSource().addFeature(
            new Feature({ geometry: new LineString([coords[i], coords[i + 1]]) }));
        }
      }
    });
  };



  /**
   * Odebrání mapových objektů z vrstev mapy.
   * @param dataToRemove {ILayerFeatures[]} Kolekce dat identifikující mapové objekty k odebrání
   */
  private _removeFeatures(dataToRemove: ILayerFeatures[]): void {
    if (this._map == void 0) return;

    dataToRemove.forEach(toRem => {
      let layer = this._map.getLayers().getArray().filter(l => l.getProperties().name == toRem.nazev)[0];
      if (layer != void 0) {
        let featuresToRemove = (layer as Layer<any, any>).getSource().getFeatures().filter(f => toRem.ids.includes(f.getId()));
        let source = (layer as Layer<any, any>).getSource();
        featuresToRemove.forEach(f => {
          this.areaOverlayInteractionService.remove(f);
          source.removeFeature(f);
        });
        this.fillDimOverlay(this._selectableLayer.getSource().getFeatures());
      }
    });
  }


  /**
   * Handler smazání náčrtu.
   * @param dataToRemove {NacrtMapItemDto} data smazaného náčrtu
   */
  private _removeFeatureHandler(dataToRemove: NacrtMapItemDto): void {
    let tartgetLayer = 'nacrty_' + dataToRemove.projektGuid;
    this._removeFeatures([
      { ids: [dataToRemove.id], nazev: this.SELECTABLE_LAYER_NAME },
      { ids: [dataToRemove.id], nazev: this.VERTICIES_LAYER_NAME },
      { ids: [dataToRemove.id], nazev: tartgetLayer }]);
  }


  /**
   * Vytvoření nové featury z dat jiného mapového objektu
   * @param dto {MapItemDto} data mapového objektu
   */
  private _copyHandler(dto: MapItemDto): void {
    let feature = this.constructLayerUtils.convertFromSource([{ wkt: dto.wkt, id: this.uuidUtils.GenerateUuid(), modul: dto.modul }])[0];
    this._addToDrawLayer(feature);
    this._addMapInteractions();
    this.mapInteractionService.featureChanged(feature);

    // je třeba naplnit předem, poté se reaguje už jen na změnu featury.
    this.fillDimOverlay([feature]);
    this.areaOverlayInteractionService.add([feature]);
    this.mapInteractionService.drawGeometryChanged(feature);
     
    feature.on('change', this._copyChangeHandler.bind(this));
  }


  /**
   * Handler zaměny kopírovaného mapového objektu.
   * @param event {event: BaseEvent} událost změny kopírovaného objektu
   */
  private _copyChangeHandler(event): void {
    let feature = this._cloneFeature(event.target);
    this.fillDimOverlay([feature]);
    this.areaOverlayInteractionService.update(feature);
    this.mapInteractionService.drawGeometryChanged(feature);
  }


  /**
   * Zahájení editace existujícího náčrtu.
   * @param data {NacrtMapItemDto} data editovaného náčrtu
   */
  private _editFeature(data: NacrtMapItemDto): void {
    let feature = this._selectableLayer.getSource().getFeatures().filter(x => x.getId() == data.id)[0];
    if (feature != void 0) {
      let sourceLayer = 'nacrty_' + data.projektGuid;
      this._removeFeatures([
        { ids: [data.id], nazev: this.SELECTABLE_LAYER_NAME },
        { ids: [data.id], nazev: this.VERTICIES_LAYER_NAME },
        { ids: [data.id], nazev: sourceLayer }]);
      this._addToDrawLayer(feature);
      this._addMapInteractions();

      // Naplní vrstvu s délkami úseček. Nutno udělat poprvé ručně, poté se reaguje na změnu geometrie.
      this.fillDimOverlay(this._drawLayer.getSource().getFeatures());
      this.areaOverlayInteractionService.add([feature]);
      this.mapInteractionService.drawGeometryChanged(feature);
      
      feature.on('change', this._editChangeHandler.bind(this));

      this.mapInteractionService.featureChanged(feature);
    }
  }


  /**
   * Handler změny editovaného mapového objektu.
   * @param event {BaseEvent} událost změny mapového objektu
   */
  private _editChangeHandler(event): void {
    let featureToDim = this._cloneFeature(event.target);
    if (featureToDim.getGeometry().getType() == GeometryType.POLYGON) {
      let features: Feature<any>[] = this._drawLayer.getSource().getFeatures().slice();
      featureToDim = this._getMultipolygon(features);
    }
    this.fillDimOverlay([featureToDim]);
    this.areaOverlayInteractionService.update(featureToDim);
    this.mapInteractionService.drawGeometryChanged(featureToDim);
  }


  /**
   * Přidání featury do vrstvy pro kreslení.
   * @param feature {Feature<any>} mapový objekt, který se bude upravovat.
   */
  private _addToDrawLayer(feature: Feature<any>): void {
    let source = this._drawLayer.getSource();
    source.addFeature(feature);
  }


  /**
   * Akce po zrušení změn nového/existujícího náčrtu.
   * @param data {NacrtMapItemPostSaveDto} původní data náčrtu nebo null
   */
  private _afterDiscardChanges(data: NacrtMapItemPostSaveDto): void {
    this._dimensionsOverlay.getSource().clear();
    if (data != void 0) {
      this._addFeature(data.targetLayerName, data);
    }
    else {
      this.areaOverlayInteractionService.add(this._selectableLayer.getSource().getFeatures());
    }

    this._resetToDefaults();
    this._addToVertexLayer(this._getFeaturesForVertex());
  }


  /**
   * Handler unkončení kreslení/editace náčrtu.
   * @param data {NacrtMapItemPostSaveDto} data náčrtu
   */
  private _afterEdit(data: NacrtMapItemPostSaveDto): void {
    this._addFeature(data.targetLayerName, data);
    this._resetToDefaults();
  }


  /**
   * Vrátí nastavení ovládacích prvků, interakcí atd. do výchozícho stavu před editací/kreslením.
  **/
  private _resetToDefaults(): void {
    this._removeMapInteractions();
    this._clearDrawFeatures();
    this.mapInteractionService.showEnclaveToggler(false);
    this.snapInteractionService.showSnapSwitch(false);
  }


  /**
   * Vyčistí vrstvu pro kreslení.
  **/
  private _clearDrawFeatures() {
    this._drawingFeature = null;
    this._drawLayer.getSource().clear();
  }


  /**
   * Přidání náčrtu do vrstvy. Náčrt se přidá i do výběrové vrstvy a spočítají se jeho rozměry.
   * @param targetLayerName {string} název cílové vrstvy
   * @param data {MapItemDto} data náčrtu
   */
  private _addFeature(targetLayerName: string, data: MapItemDto): void {
    let targetLayer = this._map.getLayers().getArray().filter(l => l.getProperties().name == targetLayerName)[0];
    if (targetLayer != void 0) {
      let feature = this.constructLayerUtils.convertFromSource([data])[0];
      (targetLayer as Layer<any, any>).getSource().addFeature(feature);
      this._selectableLayer.getSource().addFeature(feature);
      this.fillDimOverlay(this._selectableLayer.getSource().getFeatures());
      this.areaOverlayInteractionService.add(this._selectableLayer.getSource().getFeatures());
    }
  }


  /**
   * Nastavení geometrie upravovaného náčrtu na předchozí stav.
   * @param featureState {Feature<any>} stav featury
   */
  private _undoEdit(featureState: Feature<any>): void {
    if (this._drawLayer != void 0) {
      let drawSource = this._drawLayer.getSource();
      if (featureState != void 0) {
        if (featureState.getGeometry() instanceof MultiPolygon) {
          //v případě Multipolygonu se musí nahradit původní kolekce polygonů multipolygonem, který je ve stacku stavů
          let id = drawSource.getFeatures()[0].getId();
          featureState.setId(id);
          drawSource.clear();
          drawSource.addFeature(featureState);
          this.areaOverlayInteractionService.add([featureState]);

          this._editChangeHandler({ target: featureState });
          //protože jsme zcela nahradili původní featuru, tak musíme i převázat handler
          featureState.on('change', this._editChangeHandler.bind(this));
          this.mapInteractionService.featureChanged(featureState, false);
        }
        else {
          let f = drawSource.getFeatures()[0];
          f.setGeometry(featureState.getGeometry());
          this.mapInteractionService.featureChanged(f, false);
        }
      }
    }
  }


  /**
   * Odebrání posledního přidaného bodu z kreslené feautury.
  **/
  private _undoDraw(): void {
    if (this._draw && this._draw.getActive()) {
      this._draw.removeLastPoint();
    }
    else if (this._drawHole && this._drawHole.getActive()) {
      this._drawHole.removeLastPoint();
    }

    this.areaOverlayInteractionService.update(this._drawingFeature);
    this._addToVertexLayer([...this._getFeaturesForVertex(), this._drawingFeature]);
  }


  /**
   * Handler události sloučení náčrtů do multipolygonu.
   * @param data {IMultipolygonJoinIntoArgs} argumetry události (featrue, zdrojová metadata)
   */
  private multipolygonJoinIntoHandler(data: IMultipolygonJoinIntoArgs): void {
    this._drawLayer.getSource().addFeature(data.feature);
  }


  /**
   * Postproces schválení náčrtu.
   * @param event {ISchvalWorkflowDto[]} argumentry události
   */
  private _schvalitPostProcess(event: ISchvalWorkflowDto[]): void {
    let nacrtyPostprocess: ISchvalWorkflowDto = event.filter(dto => dto.modul == 'Nacrty')[0];
    if (nacrtyPostprocess != void 0) {
      let dto: NacrtSchvalWorkflowDto = (nacrtyPostprocess as NacrtSchvalWorkflowDto);
      this._removeFeatures([{ nazev: 'nacrty_' + dto.zdrojovyProjekt, ids: [dto.schvalenyNacrt.id] }]);
      this._addFeature('nacrty_' + dto.schvalenyNacrt.projektGuid, dto.schvalenyNacrt);
    }
  }


  /**
   * Handler přepnutí mezi kreslením polygonů a enkláv.
   * @param active {boolean} true, pokud se mají kreslit enklávy
   */
  private _switchEnclaveHandler(active: boolean): void {
    this._drawHole.setActive(active);
    this._draw.setActive(!active);
  }


  /**
   * Handler změny nastavení přichytávání
   * @param args {SnappingChangedArgs} parametry přichytávání
   */
  private _snappingChangedHandler(args: SnappingChangedArgs) {
    if (this._snap != void 0 && this._snap.getActive()) {
      //toto je fuj, ale OL z nějakého důvodu neposkytují prostředky pro změnu nastavení přichytávání. Jiná cesta by byla vždy zcela znova vytvořit novou interakci Snap...
      if (args.type == SnappingType.Vertex) {
        this._snapVertex = args.isOn;
        this._snap['vertex_'] = this._snapVertex;
      }
      if (args.type == SnappingType.Edge) {
        this._snapEdge = args.isOn;
        this._snap['edge_'] = this._snapEdge;
      }
    }
  }


  /**
   * Aktualizace geometrií k přichytávání. Přichytává se k viditelným vrstvám náčrtů a JPRL.
  **/
  private _updateSnappedGeometries(): void {
    //vyčištění vrstvy pro přichytávání
    this._snapLayer.getSource().clear();

    if (this._snap != void 0 && this._snap.getActive())
    {
      let layerNames = this.localStoragelayersInteractionService.getVisibleList();

      //získání hranic JPRL
      let lhcGuids = this.layerFilterUtils.getLhpGuids(this.layerFilterUtils.getIslhLayersNames(layerNames));
      let extent = this.extentUtils.getMapExtent(this._map);
      this.mapService.getSnapGeometries(extent[0], extent[1], extent[2], extent[3], lhcGuids).subscribe(wkt => {
        let data = wkt.map(x => { return { id: this.uuidUtils.GenerateUuid(), wkt: x, popis: '', modul: 'Lhp' }; });
        let jprlF = this.constructLayerUtils.convertFromSource(data);
        this._snapLayer.getSource().addFeatures(jprlF);
      });

      //získání náčrtů z viditelných vrstev
      let nacrtyNames = this.layerFilterUtils.getNacrtyLayerNames(layerNames);
      //vytažení viditelných vrstev náčrtů z mapy
      let nacrtyLayers = this._map.getLayers().getArray().filter(l => nacrtyNames.includes(l.getProperties().name));
      nacrtyLayers.forEach(l => {
        this._snapLayer.getSource().addFeatures((l as Layer<any, any>).getSource().getFeatures());
      });
    }
  }


  /**
   * Handler aktivace přichytávání.
   * @param activate {boolean} true, pokud má být přichytávání aktivní
   */
  private _activateSnapHandler(activate: boolean): void {
    if (this._snap) {
      this._snap.setActive(activate);
      this._updateSnappedGeometries();
    }
  }


  /**
   * Vytvoří klon mapového objektu včetně jeho Id.
   * @param feature {Feature<any>} mapový objekt ke klonování
   */
  private _cloneFeature(feature: Feature<any>): Feature<any> {
    let clone = feature.clone();
    clone.setId(feature.getId());
    return clone;
  }


  /**
   * Handler přepnutí zobrazení lomových bodů vybraných mapových objektů.
   * @param show {boolean} true, pokud se mají lomové body zobrazovat.
   */
  private _vertexToggleHandler(show: boolean): void {
    this._showVertex = show;

    let selected = [];
    if (this._showVertex) {
      selected = this._getFeaturesForVertex();
    }
    this._addToVertexLayer(selected);
  }


  /**
   * Přidání vybraných mapových objektů do vrstvy se zobrazením lomových bodů.
   * @param features {Feature<any>} mapové objekty, kterým chceme zobrazit lomové body
   */
  private _addToVertexLayer(features: Feature<any>[]): void
  {
    this._verticiesLayer.getSource().clear();
    if (this._showVertex) {
      let toAdd = features.filter(f => !(f instanceof Point))
      this._verticiesLayer.getSource().addFeatures(toAdd);
    }
  }


  /**
   * Vrátí mapové objekty z vrstev výběru a kreslení.
  **/
  private _getFeaturesForVertex(): Feature<any>[] {
    return [...this._selectableLayer.getSource().getFeatures(), ...this._drawLayer.getSource().getFeatures()];
  }


  /**
   * Handler změny mapového objektu. Slouží k aktualizaci vykreslených vertexů.
   * @param changedFeature {Feature<any>} aktualizovaný mapový objekt
   */
  private _featureChangedHandler(changedFeature: Feature<any>): void {
    let fs = this._getFeaturesForVertex();
    fs = fs.filter(f => f.getId() != changedFeature.getId());
    this._addToVertexLayer([...fs, changedFeature]);
  }
}
