import { Component, Input, EventEmitter, Output } from '@angular/core';
import { FeatureDataDto } from '../../../Dto/Nacrty/FeatureDataDto';

/**
 * Komponenta zobrazení seznamu mapových objektů.
**/
@Component({
  selector: 'app-feautres-list',
  templateUrl: './feautres-list.component.html',
  styleUrls: ['./feautres-list.component.css']
})
export class FeautresListComponent
{
  _data: FeatureDataDto[] = [];
  /**
   * Data pro zobrazení v komponentě.
  **/
  @Input() set data(value: FeatureDataDto[]) {
    if (value != void 0) {
      this._data = value;
      this._selectedData = [];
      this.selectionChanged.emit(this._selectedData);
    }
  }


  /**
   * Událost informující o změně výběru předávájící vybraná data.
  **/
  @Output() selectionChanged: EventEmitter<FeatureDataDto[]> = new EventEmitter<FeatureDataDto[]>();


  //seznam vybraných dat
  _selectedData: FeatureDataDto[] = [];


  /**
   * Handler změny výběru.
  **/
  onSelectionChanged(): void {
    this.selectionChanged.emit(this._selectedData);
  }
}
