<form>
  <app-block-ui [block]="blockedPrepocet" [target]="krokyU8" [small]="true"></app-block-ui>
  <p-panel #krokyU8 header="Kroky výpočtu" [style]="{'margin-bottom': '1em'}"
           [toggleable]="true" [collapsed]="true" toggler="header">

    <div class="p-grid">
      <div class="p-col-10">
        <div class="p-grid p-align-center">
          <div class="p-10-col-3">
            <label>
              Obvyklá cena dřeva (Kč/m3)
              <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{ponechaniDriviDto?.obvyklaCenaDreva}}</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </p-panel>
</form>
