/**
 * Interface položky řídící matice.
**/
export interface IMaticeItem {
  popis: string;
  formKey: string;
  parent: string;
}

/**
 * Seznam všech položek řídící matice.
**/
export class RidiciMaticeItems {
  public static Items: { [id: string]: IMaticeItem; } = {
    'AkceGuid': { popis: 'Akce', formKey: 'akceGuid', parent: null },
    'CertifikaceGuid': { popis: 'Certifikace', formKey: 'certifikaceGuid', parent: null },
    'DatumAplikace': { popis: 'Datum aplikace', formKey: 'datumAplikace', parent: null },
    'DodavatelGuid': { popis: 'Dodavatel/Pracovník', formKey: 'dodavatelGuid', parent: null },
    'DopravceGuid': { popis: 'Dopravce', formKey: 'dopravceGuid', parent: null },
    'DrevinaGuid': { popis: 'Dřevina', formKey: 'drevinaGuid', parent: null },
    'DruhMzdyGuid': { popis: 'Druh mzdy', formKey: 'druhMzdyGuid', parent: null },
    'DruhTezbyGuid': { popis: 'Druh těžby', formKey: 'druhTezbyGuid', parent: null },
    'EtazGuid': { popis: 'Etáž', formKey: 'etazGuid', parent: null },
    'Hmotnatost': { popis: 'Hmotnatost', formKey: 'hmotnatost', parent: null },
    'Hodiny': { popis: 'Hodiny', formKey: 'hodiny', parent: null },
    'IndexHoliny': { popis: 'Index holiny', formKey: 'indexHoliny', parent: null },
    'Kc': { popis: 'Kč', formKey: 'kc', parent: null },
    'KontraktGuid': { popis: 'Kontrakt', formKey: 'kontraktGuid', parent: null },
    'LokalitaGuid': { popis: 'Lokalita', formKey: 'lokalitaGuid', parent: null },
    'Materialy': { popis: 'Materiály', formKey: 'materialy', parent: null },
    'Materialy_AplDavka': { popis: 'Aplikační dávka', formKey: 'aplDavka', parent: 'materialy' },
    'Materialy_Mnozstvi': { popis: 'Množství materiálu', formKey: 'mnozstvi', parent: 'materialy' },
    'MjGuid': { popis: 'MJ', formKey: 'mjGuid', parent: null },
    'Mnozstvi': { popis: 'Množství', formKey: 'mnozstvi', parent: null },
    'Mzd': { popis: 'MZD', formKey: 'mzd', parent: null },
    'Normohodiny': { popis: 'Normočas', formKey: 'normohodiny', parent: null },
    'OdberatelGuid': { popis: 'Odběratel', formKey: 'odberatelGuid', parent: null },
    'OdpracDny': { popis: 'Dny', formKey: 'odpracDny', parent: null },
    'Plocha': { popis: 'Plocha', formKey: 'plocha', parent: null },
    'PlRadekGuid': { popis: 'Průvodní list', formKey: 'plRadekGuid', parent: null },
    'PribVzd': { popis: 'Přibližovací vzdálenost', formKey: 'pribVzd', parent: null },
    'Prostredky': { popis: 'Prostředky', formKey: 'prostredky', parent: null },
    'Prostredky_Hodiny': { popis: 'Hodiny prostředku', formKey: 'hodiny', parent: 'prostredky' },
    'Prostredky_Mnozstvi': { popis: 'Množství prostředku', formKey: 'mnozstvi', parent: 'prostredky' },
    'Prostredky_PodvykonGuid': { popis: 'Podvýkon prostředku', formKey: 'podvykonGuid', parent: 'prostredky' },
    'Prostredky_VykonGuid': { popis: 'Výkon prostředku', formKey: 'vykonGuid', parent: 'prostredky' },
    'Prostredky_DomOrgUrGuid': { popis: 'Organizační úroveň', formKey: 'domOrgUrGuid', parent: 'prostredky' },
    'SkodlivyCinitelGuid': { popis: 'Škodlivý činitel', formKey: 'skodlivyCinitelGuid', parent: null },
    'SortimentGuid': { popis: 'Sortiment', formKey: 'sortimentGuid', parent: null },
    'Spz': { popis: 'SPZ', formKey: 'spz', parent: null },
    'Tarif': { popis: 'Tarif/Sazba', formKey: 'tarif', parent: null },
    'UjeteKm': { popis: 'Ujeté km', formKey: 'ujeteKm', parent: null },
    'Vagony': { popis: 'Vagóny', formKey: 'vagony', parent: null },
    'ZakazkaGuid': { popis: 'Zakázka/Dotace', formKey: 'zakazkaGuid', parent: null },
    'ZpusobProvedeniGuid': { popis: 'Způsob provedení', formKey: 'zpusobProvedeniGuid', parent: null },
    'SazeniceGuid': { popis: 'Typ sadebního materiálu', formKey: 'sazeniceGuid', parent: null },
    'PremieNahrady': { popis: 'Prémie, Náhrady', formKey: 'premieNahrady', parent: null }
  }
}
