
import { LhpEtazProVypocetDto } from './LhpEtazProVypocetDto';
import { LhpDrevinaProVypocetDto } from './LhpDrevinaProVypocetDto';
import { CDrevinaSkupDetailDto } from '../Vyroba/CDrevinaSkupDetailDto';

export class VypocetDetailDto {
  guid: string;
  typ: string;

  obdobiGuid: string;
  drzitelGuid: string;
  uzivatelGuid: string;
  lhcGuid: string;
  
  jprl: string;
  etaz: string;
  plocha: number;
  plochaVypocet: number;
  vek: number;
  zakm: number;
  bonR: number;
  imise: string;
  upravaHlpa: number;
  sazDruh: string;
  sazTyp: string;
  np: number;
  nHa: number;
  k2: number;
  zakmU: number;
  chs: number;
  zast: number;
  obmyti: number;
  zasoba: number;
  objem: number;
  naklady: number;
  vysledek: number;

  lhpEtaz: LhpEtazProVypocetDto;
  lhpDrevina: LhpDrevinaProVypocetDto;

  drevinaSkupGuid: string;
  drevinaSkup: CDrevinaSkupDetailDto;
}
