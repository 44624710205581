import { Injectable } from "@angular/core";

/**
 * Pomocná třída pro filtrování názvů mapových vrstev.
**/
@Injectable({
  providedIn: 'root'
})
export class LayerFilterUtils {

  /**
   * Vrátí názvy ISLH vrstev.
   * @param layerNames {string[]} seznam názvů mapových vrstev
   */
  public getIslhLayersNames(layerNames: string[]): string[] {
    return layerNames.filter(n => n.startsWith('lhp_'));
  }


  /**
   * Vrátí seznam guidů z názvů ISLH vrstev.
   * @param islhNames {string[]} seznam názvů ISLH vrstev
   */
  public getLhpGuids(islhNames: string[]): string[] {
    return islhNames.map(n => n.split('_')[n.split('_').length - 1]);
  }


  /**
   * Vrátí seznam názvů vrstev náčrtů.
   * @param layerNames {string[]} seznam názvů mapových vrstev.
   */
  public getNacrtyLayerNames(layerNames: string[]): string[] {
    return layerNames.filter(n => n.startsWith('nacrty_'));
  }
}
