import { MapItemDto } from "../Mapa/MapItemDto";

/**
 * Přenoska informací o mapovém objektu z modulu PES.
**/
export class PesMapItemDto extends MapItemDto {
  parId: number;
  katuzeKod: number;
  katuzeNazev: string;
  cisloPar: number;
  podlomeni: number;
  vymera: number;
  druhPozemkuKod: number;
  druhPozemkuNazev: string;
}
