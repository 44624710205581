<p-toast position="top-center" key="vyroba-kontroly-toast"></p-toast>
<app-block-ui [block]="block"></app-block-ui>
<p-toolbar styleClass="base-toolbar">
  <div class="p-toolbar-group-left">
    <h2 class="toolbar-label">Kontroly dokladů</h2>
    <i class="p-toolbar-separator"><fa-icon [icon]="faEllipsisV"></fa-icon></i>
    <button pButton type="button" label="Nová" class="icon-btn" (click)="add()"><fa-icon [icon]="faPlus"></fa-icon></button>
    <div style="display: inline-block">
      <div class="p-inputgroup">
        <input type="text" pInputText placeholder="Hledat" [(ngModel)]="filter" style="width: 20em" />
        <button pButton type="button" class="icon-btn hide-label-button" (click)="filter = undefined" [disabled]="filter == undefined"><fa-icon [icon]="faBackspace"></fa-icon></button>
      </div>
    </div>
    <button pButton type="button" label="Smazat" class="icon-btn p-button-danger" (click)="remove()" [disabled]="selectedItem == undefined || selectedItem.guid == undefined">
      <fa-icon [icon]="faTrash"></fa-icon>
    </button>
  </div>
  <div class="p-toolbar-group-right">
    <button pButton type="button" label="Zpět" class="icon-btn" (click)="back()"><fa-icon [icon]="faUndo"></fa-icon></button>
  </div>
</p-toolbar>

<propla-table #kontrolyTable [cols]="cols" [allItems]="items" [selectionMode]="'single'" [filterGlobal]="filter"
              (rowSelected)="onRowSelected($event)" (rowUnSelected)="onRowUnSelected($event)" (dataLoaded)="dataTableLoaded()"></propla-table>

<ng-template #colDatum let-rowData let-col="column">
  {{rowData[col.field] | date:'d.M.yyyy'}}
</ng-template>

<ng-template #colAnoNe let-rowData let-col="column">
  {{rowData[col.field]?'Ano':'Ne'}}
</ng-template>


<p-panel *ngIf="showDetail" [style]="{'margin-top': '1em'}">
  <ng-template pTemplate="header">
    <div class="p-grid p-align-center">
      <div class="p-col"><span class="p-panel-title">Nastavení kontroly</span></div>
      <div class="p-col right">
        <button pButton type="button" label="Uložit" class="icon-btn p-button-success" (click)="save()" [disabled]="form.invalid"><fa-icon [icon]="faSave"></fa-icon></button>
        <button pButton type="button" label="Uložit kopii" class="icon-btn p-button-success" (click)="saveCopy()" [disabled]="form.invalid"><fa-icon [icon]="faCopy"></fa-icon></button>
        <button pButton type="button" label="Zrušit" class="icon-btn" (click)="cancel()"><fa-icon [icon]="faTimes"></fa-icon></button>
      </div>
    </div>
  </ng-template>
  <div class="p-grid validated-form-container" [formGroup]="form">
    <div class="p-col-1">
      Doklad
      <p-dropdown #dokladTyp [options]="doklady" formControlName="dokladTypKod" styleClass="pds-ciselnik-dropdown" appendTo="body" [style]="{'width': '100%'}" class="required"></p-dropdown>
    </div>
    <div class="p-col-1">
      Chyba
      <p-dropdown [options]="chybaOptions" label="label" styleClass="pds-ciselnik-dropdown" [style]="{'width':'100%'}"
                  appendTo="body" formControlName="chyba" id="vml-chyba-dropdown"></p-dropdown>
    </div>
    <div class="p-col-6">
      <label for="kontroly-popis-textbox">Popis</label>
      <input type="text" pInputText formControlName="popis" id="kontroly-popis-textbox" />
    </div>
    <div class="p-col-2">
      <label for="kontroly-platnost-od">Platnost od</label>
      <app-calendar formControlName="platnostOd" class="required" id="kontroly-platnost-od"></app-calendar>
    </div>
    <div class="p-col-2">
      <label for="kontroly-platnost-do">Platnost do</label>
      <app-calendar formControlName="platnostDo" id="kontroly-platnost-do"></app-calendar>
    </div>

    <div class="p-col-12">
      <label for="kontroly-poznamka-textbox">Poznámka</label>
      <input type="text" pInputText formControlName="poznamka" id="kontroly-poznamka-textbox" />
    </div>

    <div class="p-col-4">
      <p-panel>
        <ng-template pTemplate="header">
          <div class="p-helper-clearfix">
            <span class="p-panel-title" style="font-size:14px;display:inline-block;margin-top:2px">Podmínka cloud</span>

            <button pButton style="float:right;" (click)="editPodminkyHandler('podminkaCloud');" [ngClass]="'icon-btn hide-label-button'"
                    title="Eitace podmínky cloud" [disabled]="data?.podminkaCloud">
              <fa-icon [icon]="faPencilAlt"></fa-icon>
            </button>
          </div>
        </ng-template>
        <textarea pInputTextarea formControlName="podminkaCloud"></textarea>
      </p-panel>
    </div>

    <div class="p-col-4">
      <p-panel>
        <ng-template pTemplate="header">
          <div class="p-helper-clearfix">
            <span class="p-panel-title" style="font-size:14px;display:inline-block;margin-top:2px">Podmínka mobil</span>
            <button pButton style="float:right;" (click)="editPodminkyHandler('podminkaMobil');" [ngClass]="'icon-btn hide-label-button'"
                    title="Eitace podmínky mobil" [disabled]="data?.podminkaMobil">
              <fa-icon [icon]="faPencilAlt"></fa-icon>
            </button>
          </div>
        </ng-template>
        <textarea pInputTextarea formControlName="podminkaMobil"></textarea>
      </p-panel>
    </div>

    <div class="p-col-4">
      <p-panel>
        <ng-template pTemplate="header">
          <div class="p-helper-clearfix">
            <span class="p-panel-title" style="font-size:14px;display:inline-block;margin-top:2px">Podmínka meta</span>
            <button pButton style="float:right;" (click)="editPodminkyHandler('podminkaMeta');" [ngClass]="'icon-btn hide-label-button'"
                    title="Eitace podmínky meta" [disabled]="data?.podminkaMeta">
              <fa-icon [icon]="faPencilAlt"></fa-icon>
            </button>
          </div>
        </ng-template>
        <textarea pInputTextarea formControlName="podminkaMeta"></textarea>
      </p-panel>
    </div>
  </div>
</p-panel>

<p-confirmDialog #confirmDlg header="Smazat" icon="pi pi-exclamation-triangle">
  <p-footer>
    <button type="button" pButton icon="pi pi-times" label="Ne" (click)="confirmDlg.reject()"></button>
    <button type="button" pButton icon="pi pi-check" label="Ano" (click)="confirmDlg.accept()"></button>
  </p-footer>
</p-confirmDialog>

<app-vyroba-eq-conditions-editor [display]="showEQEditor" (close)="closeEQhandler()"
                                 (onChange)="eQChangedHandler($event)" [dokladType]=" data.dokladTypKod"></app-vyroba-eq-conditions-editor>

