import { Component, OnInit, ViewChild, TemplateRef, EventEmitter, AfterViewInit } from '@angular/core';
import { FormBuilder, FormArray, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { VyrobaService } from 'src/app/Services/Vyroba/vyroba.service';
import { MessageService, ConfirmationService } from 'primeng/api';
import { CiselnikyService } from 'src/app/Services/Shared/ciselniky.service';
import { OliDetailDto } from 'src/app/Dto/Vyroba/OliDetailDto';
import { CiselnikListDto } from 'src/app/Dto/Shared/CiselnikListDto';
import { MessagesUtils } from 'src/app/Utils/Shared/messages.utils';
import { OliRadekDetailDto } from 'src/app/Dto/Vyroba/OliRadekDetailDto';
import { VagonDetailDto } from 'src/app/Dto/Vyroba/VagonDetailDto';
import { OliProstredekDetailDto } from 'src/app/Dto/Vyroba/OliProstredekDetailDto';
import { COdberatelKontraktListDto } from 'src/app/Dto/Vyroba/COdberatelKontraktListDto';
import { faSave, faFlagCheckered, faUndo } from '@fortawesome/free-solid-svg-icons';
import { EtazVolbaDto } from 'src/app/Dto/Lhp/EtazVolbaDto';
import { VyrobaKontrolaComponent } from '../vyroba-kontrola/vyroba-kontrola.component';
import { UuidUtils } from 'src/app/Utils/Shared/uuid.utils';
import { NextOnEnterDirective } from 'src/app/Directives/next-on-enter.directive';
import { EtazInputComponent } from '../../Lhp/etaz-input/etaz-input.component';
import { CiselnikDropdownComponent } from 'src/app/Components/Shared/ciselnik-dropdown/ciselnik-dropdown.component';
import { TableComponent } from '../../Shared/table/table.component';
import { CalendarComponent } from '../../Shared/calendar/calendar.component';
import { PodCiselnikListDto } from '../../../Dto/Shared/PodCiselnikListDto';


/**
 * Komponenta pro detail/editaci dokladu OL.
**/
@Component({
  selector: 'app-vyroba-ol-edit',
  templateUrl: './vyroba-ol-edit.component.html',
  styleUrls: ['./vyroba-ol-edit.component.css']
})
export class VyrobaOlEditComponent implements OnInit, AfterViewInit {

  @ViewChild('colSortiment', { static: true }) colSortiment: TemplateRef<any>;
  @ViewChild('rowTable', { static: true }) rowTable: TableComponent;
  @ViewChild('etazInput') etazInput: EtazInputComponent;
  @ViewChild('sortimentDropDown') sortimentDropDown: CiselnikDropdownComponent;
  @ViewChild('datumDokladu') datumDokladu: CalendarComponent;
  @ViewChild('kontrolaTree') kontrolaTree: VyrobaKontrolaComponent;
  @ViewChild(NextOnEnterDirective) nextOnEnter: NextOnEnterDirective;

  private readonly MESSAGE_KEY: string = "ol-edit-toast";
  private readonly SAVE_DLG_KEY: string = 'ol-confirm-dlg';
  private dataLoaded: boolean = false;
  edit: boolean = false;
  block: boolean = false;
  tezebniVykony: CiselnikListDto[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private vyrobaService: VyrobaService,
    private ciselnikyService: CiselnikyService,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private messageUtils: MessagesUtils,
    private uuidUtils: UuidUtils,
    private confirmationService: ConfirmationService
  ) { }


  faSave = faSave;
  faFlagCheckered = faFlagCheckered;
  faUndo = faUndo;

  etazReset: EventEmitter<any> = new EventEmitter();//inicializace komponenty etáže při změně řádku

  formData = this.formBuilder.group({
    guid: [''],
    datum: [''],
    orgUrGuid: [''],
    cislo: [''],
    lokalitaGuid: [''],
    pOrgUrGuid: [''],
    vykonGuid: [''],
    podvykonGuid: [''],
    odberatelGuid: [''],
    cisloCmr: [''],
    cisloZameru: [''],
    zakazkaGuid: [''],
    kontraktGuid: [''],
    ujeteKm: [''],
    certifikaceGuid: [''],
    spz: [''],
    dopravceGuid: [''],
    poznamka: [''],
    vagony: this.formBuilder.array([
      //this.formBuilder.group({
      //  guid: [''],
      //  dokladGuid: [''],
      //  cisloVagonu: ['']
      //})
    ]),
    prostredky: this.formBuilder.array([
      //this.formBuilder.group({
      //  guid: [''],
      //  prostredekGuid: ['']
      //})
    ]),
    radky: this.formBuilder.array([
      //this.formBuilder.group({
      //  guid: [''],
      //  sortimentGuid: [''],
      //  mnozstvi: [''],
      //  etazGuid: [''],
      //  lhc: [''],
      //  odd: [''],
      //  dil: [''],
      //  por: [''],
      //  psk: [''],
      //  etaz: ['']
      //})
    ])
  });

  get vykonGuid() {
    return this.formData.get('vykonGuid');
  }

  get odberatelGuid() {
    return this.formData.get('odberatelGuid');
  }

  get orgUrGuid() {
    return this.formData.get('orgUrGuid').value;
  }

  get radky() {
    return (this.formData.get('radky') as FormArray);
  }

  get vagony() {
    return (this.formData.get('vagony') as FormArray);
  }

  get prostredky() {
    return (this.formData.get('prostredky') as FormArray);
  }

  aktivniRadekGroup: FormGroup = this.formBuilder.group({
    guid: [''],
    sortimentObchodniGuid: [''],
    sortimentGuid: [''],
    mnozstvi: [''],
    etazGuid: [''],
    lhc: [''],
    odd: [''],
    dil: [''],
    por: [''],
    psk: [''],
    etaz: [''],
    cislo: ['']
  });

  aktivniRadek: OliRadekDetailDto = null;
  aktivniRadekIdx: number = 0;
  showJprl: boolean = false;
  kontraktDto: COdberatelKontraktListDto = null;
  sortimentyObchodni: CiselnikListDto[] = [];

  /**
   * Inicializace formuláře.
  **/
  ngOnInit(): void {
    this.ciselnikyService.getVykonyTezebni().subscribe(resp => this.tezebniVykony = resp);

    this.aktivniRadekGroup.valueChanges.subscribe(change => {
      let groupToUpdate = ((this.radky.controls[this.aktivniRadekIdx]) as FormGroup);
      if (groupToUpdate != void 0) {
        groupToUpdate.patchValue(change);
      }
    });

    //dotažení detailu dokladu dle guidu z URL
    this.activatedRoute.params.subscribe(param => {
      var guid = param.guid;
      this.edit = param.state == "edit" ? true : false;
      this.vyrobaService.getOliDetail(guid).subscribe(resp => {
        if (resp.success) {
          resp.data.radky = resp.data.radky.sort((a, b) => a.cislo > b.cislo ? 1 : -1);
          this._setFormModelData(resp.data);
          this._setJprlVisibility(resp.data.lokalitaGuid);

          if (resp.data.radky.length > 0) {
            this.aktivniRadekIdx = 0;
            this.aktivniRadek = this.radky.controls[this.aktivniRadekIdx].value;
            this.aktivniRadekGroup.setValue(this.aktivniRadek);
          }
          else {
            this.addRadek();
          }
          this.datumDokladu.toggle();//provede se focus   
        }
        else if (!resp.success) {
          this.messageUtils.showResponseMessage(this.MESSAGE_KEY, resp);
        }
        else {
          this.messageService.add({ severity: 'error', summary: 'Chyba', key: this.MESSAGE_KEY, detail: 'Došlo k neočekávané chybě.', life: MessagesUtils.TOAST_LIFE })
        }
        this.dataLoaded = true;
        setTimeout(() => { this.block = false; });
      });
    });
  }

  ngAfterViewInit(): void {
    this.block = !this.dataLoaded;
  }


  /**
   * Překlopí dto s daty do fomuláře a dotáhne číselníky.
   * @param dto
   */
  private _setFormModelData(dto: OliDetailDto): void {
    this.formData.patchValue(dto);

    this._setFormArray((this.radky as FormArray), dto.radky);
    this._setFormArray((this.vagony as FormArray), dto.vagony);
    this._setFormArray((this.prostredky as FormArray), dto.prostredky);

    if (dto.podvykonGuid != '' && dto.podvykonGuid != void 0) {
      this.ciselnikyService.getCislenik('CPodvykon', null).subscribe(resp => {
        let podvykon = resp.find(x => x.guid == dto.podvykonGuid);
        if (podvykon != null)
          this.formData.get('vykonGuid').setValue((podvykon as PodCiselnikListDto).parentGuid);
      });
    }
  }


  /**
   * Naplnění formArray v modelu formuláře.
   * @param formArray
   * @param data
   */
  private _setFormArray(formArray: FormArray, data: any[]): void {
    if (data.length > 0) {
      formArray.clear();
      data.forEach(item => formArray.push(this.formBuilder.group(item)));
    }
  }


  /**
   * Hanler změny lokality. Zobrazuje/skrývá sloupce JPRL.
   * @param event
   */
  lokalitaChangeHandler(event) {
    this._setJprlVisibility(event);
  }


  /**
   * Zobrazí/skryje jprl dle guidu lokality.
   * @param lokalitaGuid
   */
  private _setJprlVisibility(lokalitaGuid: string): void {
    if (lokalitaGuid != '' && lokalitaGuid != void 0) {
      this.ciselnikyService.getCislenik('CLokalita', null).subscribe(resp => {
        let lokalita = resp.find(l => l.guid == lokalitaGuid);
        this.showJprl = lokalita.kod == '1'; // Pařez
      });
    }
    else {
      this.showJprl = false;
    }
  }


  /**
   * Pohyb v řádcích gridu nahoru.
  **/
  onArrowUp(): void {
    var i = this.aktivniRadekIdx - 1;
    if (i > -1) {
      this.aktivniRadekIdx = i;
      this.aktivniRadek = this.radky.value[this.aktivniRadekIdx];
      this.aktivniRadekGroup.setValue(this.aktivniRadek);
    }
  }


  /**
   * Pohyb v řádcích gridu dolů.
  **/
  onArrowDown(): void {
    var i = this.aktivniRadekIdx + 1;

    if (i < this.radky.value.length) {
      this.aktivniRadekIdx = i;
      this.aktivniRadek = this.radky.value[this.aktivniRadekIdx];
      this.aktivniRadekGroup.setValue(this.aktivniRadek);
    }
  }


  /**
   * Stisk klávesy. U "klíčových" kláves provede požadovanou akci, ale nenapíše znak, pokud je tisknutelný (např. "*", "/").
   * @param event
   */
  onKeyDown(event): void {
    switch (event.keyCode) {
      case 33:
        event.preventDefault();
        this.onArrowUp();
        break;
      case 34:
        event.preventDefault();
        this.onArrowDown();
        break;
      case 106:
        event.preventDefault();
        this.addRadek();
        break;
      case 111:
        event.preventDefault();
        this._copyValue(event);
        break;
    }
  }


  /**
   * Zkopíruje hodnotu políčka z předcházejícího řádku. Vyžaduje atribut 'name' u vstupního políčka pro které se má hodnota kopírovat shodný
   * s klíčem odpovídající položky v modelu formuláře.
   * @param name
   */
  private _copyValue(event: KeyboardEvent): void {
    let id: string = (event.target as any).id;
    if (id) {
      // ID má "dohodnutou" strukturu {kde}-{co}-{typ}, zde potřebuji {co}
      let tokens = id.split("-");
      if (tokens.length > 1) {
        let controlName = tokens[1];
        let value = this.radky.value[this.aktivniRadekIdx - 1][controlName];
        this.aktivniRadekGroup.get(controlName).setValue(value);
        this.nextOnEnter.onEnter(event);
      }
    }
  }


  /**
   * Handler výběru řádku.
   * @param event
   */
  rowSelectHandler(event) {
    this.etazReset.emit();
    this.aktivniRadekIdx = event.index;
    this.aktivniRadekGroup.setValue(event.data);
    this.focusRadekOnFirst();
  }

  /**
   * Handler odvybrání řádku. Zařídí, že se řádek znova vybere - tj. vždy je řádek vybraný.
   * @param event
   */
  rowUnSelectHandler(event) {
    setTimeout((that) => {
      this.etazReset.emit();
      that.aktivniRadek = event.data;
    }, 0, this, event);
  }


  /**
   * Založní nového řádku. Kopíruje z aktivního řádku sortiment a JPRL (etáž).
  **/
  addRadek(): void {
    let newRow = this._createEmptyRowDto();

    if (this.aktivniRadek != null) {
      newRow.sortimentObchodniGuid = this.aktivniRadek.sortimentObchodniGuid;
      newRow.sortimentGuid = this.aktivniRadek.sortimentGuid;
      newRow.etazGuid = this.aktivniRadek.etazGuid;
      newRow.lhc = this.aktivniRadek.lhc;
      newRow.odd = this.aktivniRadek.odd;
      newRow.dil = this.aktivniRadek.dil;
      newRow.por = this.aktivniRadek.por;
      newRow.psk = this.aktivniRadek.psk;
      newRow.etaz = this.aktivniRadek.etaz;
      newRow.cislo = this.aktivniRadek.cislo;
      let data: OliDetailDto = this.formData.value;
      newRow.cislo = Math.max(...data.radky.map(o => o.cislo), 0) + 1;
    }

    this.etazReset.emit();
    this.aktivniRadekIdx = this.radky.length;
    this.aktivniRadek = newRow;
    this.aktivniRadekGroup.setValue(this.aktivniRadek);
    (this.radky as FormArray).push(this.formBuilder.group(this.aktivniRadek));
    this.focusRadekOnFirst();
  }


  /**
   * Smazání vybraného datového řádku.
  **/
  delRadek(): void {
    if (this.aktivniRadek != null) {
      (this.radky as FormArray).removeAt(this.aktivniRadekIdx);

      //přepíše pořadová čásla
      var cislo = 1;
      let data: OliDetailDto = this.formData.value;
      data.radky.forEach(function (v) {
        v.cislo = cislo++;
      });

      if (this.radky.value.length == this.aktivniRadekIdx) this.aktivniRadekIdx = this.radky.value.length - 1;
      this.aktivniRadek = this.radky.value[this.aktivniRadekIdx];
      if (this.aktivniRadek == undefined) {
        //this.aktivniRadek = this._createEmptyRowDto();
        //(this.radky as FormArray).push(this.formBuilder.group(this.aktivniRadek));
        this.addRadek();
      }
      this.etazReset.emit();
      this.aktivniRadekGroup.setValue(this.aktivniRadek);
    }
    this.focusRadekOnFirst();
  }


  /**
   * Vytvoří prázdné dto řádku se všemi propertami. Guid vygeneruje - je to potřeba pro indetifikaci nových řádků při jejich editaci.
  **/
  private _createEmptyRowDto(): OliRadekDetailDto {
    let emptyRowDto = new OliRadekDetailDto();
    emptyRowDto.guid = this.uuidUtils.GenerateUuid();
    emptyRowDto.etazGuid = null;
    emptyRowDto.sortimentObchodniGuid = null;
    emptyRowDto.sortimentGuid = null;
    emptyRowDto.mnozstvi = null;
    emptyRowDto.lhc = null;
    emptyRowDto.odd = null;
    emptyRowDto.dil = null;
    emptyRowDto.por = null;
    emptyRowDto.psk = null;
    emptyRowDto.etaz = null;
    emptyRowDto.cislo = 1;
    return emptyRowDto;
  }


  /**
   * Přidá novou položku vagónu.
  **/
  addVagon(): void {
    let newVagon = new VagonDetailDto();
    newVagon.guid = null;
    newVagon.dokladGuid = this.formData.get('guid').value;
    newVagon.cisloVagonu = null;

    (this.vagony as FormArray).push(this.formBuilder.group(newVagon));
  }

  /**
   * Odebrání vagónu.
   * @param idx
  **/
  removeVagon(idx): void {
    (this.vagony as FormArray).removeAt(idx);
  }


  /**
   * Přidá novou položku vagónu.
  **/
  addProstredek(): void {
    let newProstredek = new OliProstredekDetailDto();
    newProstredek.guid = null;
    newProstredek.prostredekGuid = null;

    (this.prostredky as FormArray).push(this.formBuilder.group(newProstredek));
  }

  /**
   * Odebrání vagónu.
   * @param idx
  **/
  removeProstredek(idx): void {
    (this.prostredky as FormArray).removeAt(idx);
  }


  /**
   * Uložení dokladu.
  **/
  save(): void {
    this.block = true;
    let data: OliDetailDto = this.formData.value;

    if (data.podvykonGuid == null || data.podvykonGuid == '') {
      this.block = false;
      this.messageService.add({ key: this.MESSAGE_KEY, summary: 'Pozor', severity: 'warn', detail: 'Vyplňte podvýkon.', life: MessagesUtils.TOAST_LIFE });
      return;
    }

    this.vyrobaService.saveOl(data).subscribe(res => {
      this.block = false;
      this.messageUtils.showResponseMessage(this.MESSAGE_KEY, res);
    });
  }


  /**
   * Ukončení editace detailu bez uložení návrat na seznam dokladů OL.
  **/
  cancel(): void {
    this.router.navigate(['vyroba', 'ol'], { queryParams: { "dokladGuid": this.formData.get('guid').value } });
  }

  /**
   * Handler změny jprl
   * @param value
   */
  jprlChanged(value: EtazVolbaDto): void {
    if (value != void 0) {
      Object.keys(value).forEach(key => this.aktivniRadek[key] = value[key]);
      this.aktivniRadekGroup.setValue(this.aktivniRadek);
    }
  }

  kontraktChanged(value: COdberatelKontraktListDto) {
    this.kontraktDto = value;

    if (value && value.sortimenty) {
      this.sortimentyObchodni = value.sortimenty.map(x => {
        return {
          guid: x.guid,
          kod: x.kod,
          popis: x.popis
        };
      });
    }
    else {
      this.sortimentyObchodni = [];
    }

    if (this.sortimentyObchodni.length > 0) {
      this.aktivniRadekGroup.get('sortimentObchodniGuid').enable();
    }
    else {
      this.aktivniRadekGroup.get('sortimentObchodniGuid').disable();
    }
  }

  sortimentObchodniChanged(selectedGuid: string) {
    if (selectedGuid) {
      let dto = this.kontraktDto.sortimenty.find(x => x.guid == selectedGuid);

      if (dto) {
        this.aktivniRadekGroup.get('sortimentGuid').setValue(dto.sortimentGuid);
      }
    }
  }

  /**
   * Focus na první pole detailu řádku
   * */
  focusRadekOnFirst(): void {
    if (this.showJprl) {
      setTimeout(() => { if (this.etazInput != void 0) this.etazInput.focus() });
    } else {
      setTimeout(() => { if (this.sortimentDropDown != void 0) this.sortimentDropDown.focus() });
    }
  }

  /**
   * Uložení a dokončení dokladu
  **/
  saveAndFinish(): void {
    this.confirmationService.confirm({
      key: this.SAVE_DLG_KEY,
      message: 'V případě, že doklad neobsahuje chyby, bude odeslán do ekonomické agendy. Přejete si pokračovat?',
      accept: () => {
        this.block = true;
        let data: OliDetailDto = this.formData.value;
        if (data != void 0)
          this.vyrobaService.saveAndFinish(data, "ol").subscribe(res => {
            if (!res.success && res.data?.length > 0) {
              this.kontrolaTree.resolveResult(res);
            } else {
              this.messageUtils.showResponseMessage(this.MESSAGE_KEY, res);
              if (res.success) {
                this.router.navigate(['vyroba', 'ol', "detail", data.guid]);
              }
            }

            this.block = false;
          });
      }
    });
  }
}
