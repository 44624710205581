import { Component, OnInit } from '@angular/core';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'src/app/Services/Auth/auth.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { RegisterCredentialsDto } from 'src/app/Dto/Auth/RegisterCredentialsDto';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';

@Component({
  selector: 'app-auth-register',
  templateUrl: './auth-register.component.html',
  styleUrls: ['./auth-register.component.css']
})

export class AuthRegisterComponent implements OnInit {
  readonly redirect_delay = 3000;
  constructor(private authService: AuthService,
    private messageService: MessageService,
    private router: Router) {
    this.registerForm = new FormGroup({
      'userName': new FormControl('', { validators: [Validators.required] }),
      'password': new FormControl('', { validators: [Validators.required] }),
      'firstName': new FormControl('', { validators: [Validators.required] }),
      'lastName': new FormControl('', { validators: [Validators.required] }),
      'email': new FormControl('', { validators: [Validators.required, Validators.email] })
    });
  }

  faCheck = faCheck;
  registerForm: FormGroup;
  get userName() { return this.registerForm.get('userName'); }
  get password() { return this.registerForm.get('password'); }
  get firstName() { return this.registerForm.get('firstName'); }
  get lastName() { return this.registerForm.get('lastName'); }
  get email() { return this.registerForm.get('email'); }
  submitted: boolean = false;
  loading: boolean = false;

  ngOnInit() {
  }

  register(): void {
    this.submitted = true;
    if (this.registerForm.valid) {

      var reg = new RegisterCredentialsDto();
      reg.userName = this.userName.value;
      //TODO: hashování
      reg.password = this.password.value;
      reg.firstName = this.firstName.value;
      reg.lastName = this.lastName.value;
      reg.email = this.email.value;

      this.loading = true;
      this.authService.register(reg)
        .subscribe(res => {
          if (res != void 0) {
            if (res.success) {
              for (let msg of res.messages) {
                this.messageService.add({ severity: 'success', summary: 'Registrace', detail: msg });
              }
              setTimeout(this.redirect.bind(this), this.redirect_delay);
            } else {
              for (let msg of res.messages) {
                this.messageService.add({ severity: 'error', summary: 'Chyba', detail: msg });
              }
            }
          }

          this.loading = false;
        });
    }
  }


  redirect():void {
    this.router.navigate(['/login'], { queryParams: { page: "plocha" } });
  }
}
