import { Directive, ElementRef, HostListener, QueryList, ContentChildren } from '@angular/core';
import { CalendarComponent } from 'src/app/Components/Shared/calendar/calendar.component';

/**
 * Obsluha klávesy Enter - posun na další pole formuláře
 */
@Directive({
  selector: '[nextOnEnter]'
})
export class NextOnEnterDirective {

  @ContentChildren(CalendarComponent, { descendants: true }) calendars: QueryList<CalendarComponent>;

  constructor(private hostElement: ElementRef) { }

  @HostListener('keyup', ['$event'])
  onKeyUp(event: any) {
    if (event.key == 'Enter') {
      event.preventDefault();
      this.onEnter(event);
    }
  }

  public onEnter(event: any) {
    const notFound = -1;
    var inputs = this.hostElement.nativeElement.querySelectorAll('input:not([disabled]), textarea:not([disabled])');
    var index = notFound;

    for (var i = 0; i < inputs.length; i++) {
      if (inputs[i] == event.target) {
        index = i + 1;
        break;
      }
    }

    // Bohužel při opuštění pole automaticky nezmizí overlay kalendáře. Proto je nutné je explicitně skrýt.
    // Toto nás, obávám se, může potkat i u dalších složitějších prvků.
    if (event.target.parentNode.parentNode.localName == 'p-calendar') {
      this.calendars.forEach(x => x.toggle());
    }

    if (index > notFound && index < inputs.length) {
      inputs[index].focus();
    }
  }
}
