import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ProvozniDenikListDto } from 'src/app/Dto/Vyroba/ProvozniDenikListDto';

@Injectable({
  providedIn: 'root'
})
export class ProvozniDenikService {

  constructor(
    private http: HttpClient
  ) { }

  private apiList: string = 'api/vyroba/ProvozniDenik/List';


  /**
   * Vrátí seznam záznamů provozního deníku.
  **/
  list(): Observable<ProvozniDenikListDto[]> {
    return this.http.get<ProvozniDenikListDto[]>(this.apiList);
  }
}
