<p-panel styleClass="base-toolbar">
  <ng-template pTemplate="header">
    <div class="p-grid p-align-center">
      <div class="p-col"><h2 class="p-panel-title">Výkaz skladu dříví</h2></div>
      <div class="p-col right">
        <button pButton type="button" label="Zpět" class="icon-btn" (click)="goBack()" id="report-back-button"><fa-icon [icon]="faUndo"></fa-icon></button>
      </div>
    </div>
  </ng-template>
  <app-general-eq id="filter" model="VyrSklad"></app-general-eq>
  <p-toolbar styleClass="base-toolbar" [style]="{ 'margin-top': '1em' }">
    <label>Období: </label>
    <p-calendar [(ngModel)]="obdobi" view="month" dateFormat="yy-mm" [yearNavigator]="true" [yearRange]="yearRange" [maxDate]="maxDate" [readonlyInput]="false"></p-calendar>
    <label style="margin-left: 2em;">Podsestava: </label>
    <p-dropdown [options]="podsestavy" [(ngModel)]="selectedPodsestava" [style]="{'width': '30em'}"></p-dropdown>
    <button pButton title="Tisk" class="icon-btn p-button-success" label="Tisk" (click)="print()" style="margin-left: 2em;"><fa-icon [icon]="faPrint"></fa-icon></button>
  </p-toolbar>
</p-panel>
