import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResultInfoGenericDto, ResultInfoDto } from 'src/app/Dto/Shared/ResultInfoDto';
import { DPruvodniListListDto } from 'src/app/Dto/Vyroba/DPruvodniListListDto';
import { DPruvodniListDetailDto } from 'src/app/Dto/Vyroba/DPruvodniListDetailDto';

@Injectable({
  providedIn: 'root'
})
export class PruvodniListService {

  private apiPlList: string = "api/vyroba/PruvodniList/List";
  private apiPlDetail: string = "api/vyroba/PruvodniList/Detail";
  private apiPlSaveOrUpdate: string = "api/vyroba/PruvodniList/SaveOrUpdate";
  private apiPlDelete: string = "api/vyroba/PruvodniList/Delete";

  constructor(
    private http: HttpClient
  ) { }

  list(): Observable<ResultInfoGenericDto<DPruvodniListListDto[]>> {
    return this.http.get<ResultInfoGenericDto<DPruvodniListListDto[]>>(this.apiPlList);
  }

  get(guid: string): Observable<ResultInfoGenericDto<DPruvodniListDetailDto>> {
    return this.http.get<ResultInfoGenericDto<DPruvodniListDetailDto>>(this.apiPlDetail + '/' + guid);
  }

  saveOrUpdate(data: DPruvodniListDetailDto): Observable<ResultInfoGenericDto<DPruvodniListDetailDto>> {
    return this.http.post<ResultInfoGenericDto<DPruvodniListDetailDto>>(this.apiPlSaveOrUpdate, data, {});
  }

  /**
   * Smazání průvodního listu
   * @param guid guid škodlivého činitele
   */
  delete(guid: string): Observable<ResultInfoDto> {
    var param = new HttpParams();
    param = param.append('guid', guid);
    return this.http.delete<ResultInfoDto>(this.apiPlDelete, { params: param });
  }


}
