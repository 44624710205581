import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { KnihaService } from 'src/app/Services/Lhp/kniha.service';
import { LhcDetailDto } from 'src/app/Dto/Lhp/LhcDetailDto';
import { KnihaBase } from '../kniha-base';


@Component({
  selector: 'app-kniha-lhc',
  templateUrl: './kniha-lhc.component.html',
  styleUrls: ['./kniha-lhc.component.css']
})
export class KnihaLhcComponent extends KnihaBase {

  constructor(
    route: ActivatedRoute,
    knihaService: KnihaService
  ) {
    super(route, knihaService);
  }

  lhc: LhcDetailDto;
  
  loadModel() {
    this.knihaService.getLhc(this.lhcKod, this.guid)
      .subscribe(response => {
        this.lhc = response;
        this.lhcGuid = this.lhc.guid;
        this.constructBreadCrumbs();
      });
  }
}
