<p-card *ngIf="cardVisible" styleClass="map-info-panel-item map-info-panel-kn">
  <div class="p-grid p-nogutter p-ai-center">
    <div class="p-col-3 lhp-i-primary" style="padding-top: .5rem;">
      <p pTooltip="Katastr nemovitostí" tooltipPosition="bottom"><b>Katastr</b></p>
    </div>
    <div class="p-col-7 lhp-i-secondary" style="padding-top: .2rem;">
      <p><b>x:</b> {{x}}</p>
      <p><b>y:</b> {{y}}</p>
    </div>
    <div class="p-col-2 right">
      <button pButton type="button" class="icon-btn hide-label-button"
              pTooltip="Přejít do KN" tooltipPosition="bottom"
              (click)="goToKn()">
        <fa-icon [icon]="faExternalLinkAlt"></fa-icon>
      </button>
    </div>
  </div>
</p-card>
