import { Component, ViewChild } from '@angular/core';
import { faPlus, faDrawPolygon, faWaveSquare, faDotCircle } from '@fortawesome/free-solid-svg-icons';
import { OverlayPanel } from 'primeng/overlaypanel';
import GeometryType from 'ol/geom/GeometryType';
import { MapInteractionService } from '../../../Services/Mapa/map-interaction.service';
import { OffsetlInfoDto } from '../../../Dto/Mapa/OffsetlInfoDto';
import { RoleUtils } from '../../../Utils/Shared/role.utils';
import { RoleEnum } from '../../../Dto/Core/RoleEnum';

/**
 * Komponenta pro výběr typu geometrie pro kreslení nového náčrtu.
**/
@Component({
  selector: 'app-map-nacrt-create',
  templateUrl: './map-nacrt-create.component.html',
  styleUrls: ['./map-nacrt-create.component.css']
})
export class MapNacrtCreateComponent {

  @ViewChild('overlayPanel') overlayPanel: OverlayPanel;


  constructor(
    private mapInteractionService: MapInteractionService,
    private roleUtils: RoleUtils)
  {
    this.mapInteractionService.DeactivateButtons.subscribe(deactivate => this.createBtnVisible = !deactivate);
    this.mapInteractionService.ControlOffset.subscribe(this._offsetHandler.bind(this));
    this.mapInteractionService.DrawFeatureMenuClose.subscribe(_ => this.overlayPanel.hide());
  }

  faPlus = faPlus;
  faDotCircle = faDotCircle;
  faWaveSquare = faWaveSquare;
  faDrawPolygon = faDrawPolygon;
  createBtnVisible: boolean = true;
  calculatedOffset: string = '2rem';


  /**
   * Handler zobrazení/skrytí info panelu mapy. Zajistí odsazení tlačítka.
   * @param value {OffsetlInfoDto} informace k odsazeni tlačítka
   */
  private _offsetHandler(value: OffsetlInfoDto): void {
    if (value.panel == 'infoPanel') {
      this.calculatedOffset = 'calc(2rem + ' + value.offset + ')';
    }
  }

  /**
   * Handler kliknutí na tlačítko vyrvoření nového náčrtu. Zobrazí overlay s výběrem typu náčrtu.
   * @param event
   */
  createBtnClick(event): void {
    if (this.overlayPanel.overlayVisible) {
      this.overlayPanel.hide();
    }
    else {
      this.overlayPanel.show(event);
    }
    this.mapInteractionService.drawFeatureOverlayState(this.overlayPanel.overlayVisible);
  }


  /**
   * Handler kliknutí na tlačítko kreslení bodu.
   * @param event
   */
  drawPoint(event): void {
    this._inner(GeometryType.POINT);
  }


  /**
   * Handler kliknutí na tlačítko kreslení linie.
   * @param event
   */
  drawLine(event): void {
    this._inner(GeometryType.LINE_STRING);
  }


  /**
   * Handler kliknutí na tlačítko kreslení polygonu.
   * @param event
   */
  drawPolygon(event): void {
    this._inner(GeometryType.MULTI_POLYGON);
  }


  /**
   * Operace pro zahájení kreslení náčrtu.
   * @param geometry {string} typ geometrie
   */
  private _inner(geometry: string): void {
    this.overlayPanel.hide();
    this.mapInteractionService.drawFeatureOverlayState(false);
    this.mapInteractionService.drawStarted(geometry);
  }


  /**
   * Vrací true, pokud má přihlášený uživatel právo editace náčrtů.
  **/
  get canEdit(): boolean {
    return this.roleUtils.checkRole([RoleEnum.NacrtyEditor, RoleEnum.NacrtyAdmin]);
  }
}
