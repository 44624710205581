import { Component, Input } from '@angular/core';
import { LhcDetailDto } from '../../../Dto/Lhp/LhcDetailDto';

@Component({
  selector: 'app-kniha-detail-lhc',
  templateUrl: './kniha-detail-lhc.component.html',
  styleUrls: ['./kniha-detail-lhc.component.css']
})
export class KnihaDetailLhcComponent {

  constructor() { }

  @Input() lhc: LhcDetailDto;

}
