<form id="nacrtEditForm" [formGroup]="_form">
  <div class="p-grid validated-form-container">
    <div class="p-col-12">
      <label>Projekt</label>
      <app-nacrt-project-tree [allowSwitch]="allowSwitchVisibleProjects" [layerTreeSources]="_nacrtProjects"
                              formControlName="nacrtProjektGuid"></app-nacrt-project-tree>
    </div>
    <div class="p-col-12">
      <label for="popis">Popis</label>
      <input id="popis" name="popis" type="text" pInputText formControlName="popis" />
      <p *ngIf="popis.invalid && (popis.dirty || popis.touched) && popis.errors"
         class="cross-validation-error-message alert alert-danger"
         style="margin-top: 0.2rem;">
        Vyplňte prosím popis.
      </p>
    </div>
    <div class="p-col-12">
      <label for="poznamka">Poznámka</label>
      <textarea pInputTextarea id="poznamka" formControlName="poznamka"
                [rows]="3" [cols]="30" autoResize="autoResize"></textarea>
    </div>
  </div>
</form>
