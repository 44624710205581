/*
 * Detail dto pro lhe
*/
export class DLheDetailDto
{
  public guid: string;
  public etazGuid: string;
  public drevinaGuid: string;
  public holinaGuid: string;
  public lheCinnostGuid: string;
  public rok: number;
  public mesic: number;
  public mnozstvi: number;
  public plocha: number;
}
