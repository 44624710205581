<p-breadcrumb [model]="breadcrumbs" [home]="breadcrumbHome"></p-breadcrumb>

<div *ngIf="model" class="ui-widget">
  <div class="p-grid kniha-body">
    <div class="p-col-2">
      <app-kniha-tree [treeLhcGuid]="lhcGuid"></app-kniha-tree>
    </div>
    <div class="p-col-10">
      <p-toolbar styleClass="base-toolbar">
        <div class="p-toolbar-group-left">
          <app-lhp-kniha-tisk [guid]="porGuid"></app-lhp-kniha-tisk>
        </div>
      </p-toolbar>
      <p-card>
        <app-kniha-detail-psk [psk]="model"></app-kniha-detail-psk>
      </p-card>
    </div>
  </div>
</div>
