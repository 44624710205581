<p-toast position="top-center" key="{{CISELNIKY_EDIT_TOAST}}"></p-toast>
<app-block-ui [block]="block"></app-block-ui>
<p-confirmPopup key="{{CISELNIKY_EDIT_CONFIRM_POPUP}}"></p-confirmPopup>
<p-confirmDialog key="{{CISELNIKY_EDIT_CONFIRM_DIALOG}}" [closable]="false"></p-confirmDialog>

<div class="ciselnik-edit-body">

  <p-toolbar styleClass="base-toolbar">
    <div class="p-toolbar-group-left" style="height:2.5rem;">
      <h2 class="toolbar-label" *ngIf="edit">Editace číselníku {{nazev}}</h2>
      <h2 class="toolbar-label" *ngIf="!edit">Detail číselníku {{nazev}}</h2>
      <i class="p-toolbar-separator"><fa-icon [icon]="faEllipsisV"></fa-icon></i>
      <button pButton type="button" class="icon-btn" (click)="new()" label="Nový záznam" *ngIf="isAllEdit"><fa-icon [icon]="faPlus"></fa-icon></button>
      <p-selectButton [options]="platnostModOptions" [(ngModel)]="platnostMod" optionLabel="popis" (onChange)="filtruj()" *ngIf="platnostExists"></p-selectButton>
      <p-toggleButton [(ngModel)]="platne" offLabel="Neplatné" onLabel="Platné" (onChange)="filtruj()" *ngIf="platnostExists&&!platnostMod?.volba"></p-toggleButton>
      <div style="display: inline-block" *ngIf="platnostExists&&platnostMod?.volba" class="p-mr-2">
        <div class="p-inputgroup">
          <app-calendar-range [(ngModel)]="platnostFilter" (onChange)="calendarRangeChanged($event)" name="platnostFilter"></app-calendar-range>
          <button pButton type="button" class="p-button-outlined p-button-secondary icon-btn hide-label-button back-sapce-btn" (click)="baskSpaceFilterClicked()" style="margin-right: 0px;"
                  [disabled]="btnFilterDisabled">
            <fa-icon [icon]="faBackspace" title="Smazat obsah filtru" style="padding-left: 5px;margin-right: 4px;">
            </fa-icon>
          </button>
        </div>
      </div>
      <button type="button" pButton pRipple icon="pi pi-file-o" (click)="exportCSV()" pTooltip="Export do CSV" tooltipPosition="bottom" [disabled]="items?.length==0"></button>
      <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success" pTooltip="Export do XSLX" tooltipPosition="bottom" [disabled]="items?.length==0"></button>
      <app-ciselnik-edit-info [sloupce]="dto?.sloupce"></app-ciselnik-edit-info>
    </div>
    <div class="p-toolbar-group-right">
      <button pButton type="button" label="Zpět" class="icon-btn" (click)="back()"><fa-icon [icon]="faUndo"></fa-icon></button>
    </div>
  </p-toolbar>

  <propla-table #ciselnikyTable [cols]="cols" [allItems]="items" selectionMode="single"
                (rowSelected)="onRowSelected($event)" (rowUnSelected)="onRowUnselected()"></propla-table>

  <ng-template #colValue let-rowData let-col="column">
    <span class="ciselnik-edit-value" pTooltip="{{rowData[col.field]?.toString()}}">{{rowData[col.field]?.toString()}}</span>
  </ng-template>

  <ng-template #colCiselnik let-rowData let-col="column">
    <app-ciselnik-kod ciselnik="{{getCiselnikModel(col.field)}}" [guid]="rowData[col.field]"></app-ciselnik-kod>
  </ng-template>

  <ng-template #colDatum let-rowData let-col="column">
    {{rowData[col.field] | date:'d.M.yyyy'}}
  </ng-template>

  <ng-template #colDatumCas let-rowData let-col="column">
    {{rowData[col.field] | date:'d.M.yyyy HH:mm'}}
  </ng-template>

  <ng-template #colNumberDec let-rowData let-col="column">
    <div class="right">{{rowData[col.field] | number:'1.2-2'}}</div>
  </ng-template>

  <div [formGroup]="editForm" (ngSubmit)="save()" *ngIf="edit&&displayEdit">
    <p-panel *ngIf="editArray?.length>0" [style]="{'margin-top': '1rem'}">
      <ng-template pTemplate="header">
        <div class="p-grid p-align-center">
          <div class="p-col p-panel-title">
            Editace řádku
          </div>
          <div class="p-col right">
            <button pButton type="button" class="icon-btn p-button-danger" (click)="delete($event)" label="Smazat" tooltipPosition="top" *ngIf="isAllEdit" [disabled]="newRow"><fa-icon [icon]="faTrash"></fa-icon></button>
            <button pButton type="submit" label="Uložit" class="icon-btn p-button-success" (click)="save()" [disabled]="!editForm.dirty"><fa-icon [icon]="faSave"></fa-icon></button>
            <button pButton type="button" label="Zrušit" class="icon-btn p-button-secondary p-button-outlined" (click)="cancel($event)"><fa-icon [icon]="faTimes"></fa-icon></button>
          </div>
        </div>
      </ng-template>
      <div class="p-grid edit-grid">
        <ng-container *ngFor="let item of editArray.controls; let i=index">
          <div class="p-col" [formGroup]="item">
            <!--Detail číselníku má label v sobě-->
            <label for="{{getItemNameFromControl(item)}}" *ngIf="!isFK(item) || isDateTime(item)!=null || (isFK(item) && isPK(item)) || (isFK(item) && !isPK(item) && getEdit(item))">{{getLabel(item)}}</label>

            <!--Komponenty s možností editace i pouhého zobrazení-->
            <app-calendar formControlName="{{getItemNameFromControl(item)}}" id="{{getItemNameFromControl(item)}}" [edit]="getEdit(item)" *ngIf="isDateTime(item)!=null" [showTime]="isDateTime(item)"></app-calendar>
            <decimal-input formControlName="{{getItemNameFromControl(item)}}" [edit]="getEdit(item)" id="{{getItemNameFromControl(item)}}" *ngIf="getTypeByNazev(item) == SloupecTypEnum.DesetinneCislo"></decimal-input>

            <!--Editace-->
            <div *ngIf="getEdit(item)">
              <p-inputNumber formControlName="{{getItemNameFromControl(item)}}" [inputId]="getItemNameFromControl(item)" *ngIf="getTypeByNazev(item) == SloupecTypEnum.CeleCislo"></p-inputNumber>
              <input type="text" pInputText formControlName="{{getItemNameFromControl(item)}}" *ngIf="getTypeByNazev(item) == SloupecTypEnum.Text || (newRow && isFK(item) && isPK(item))" id="{{getItemNameFromControl(item)}}" />
              <app-ciselnik-dropdown ciselnik="{{getCiselnikModel(item)}}" [virtualScroll]="true" formControlName="{{getItemNameFromControl(item)}}" class="required" *ngIf="isFK(item)" (onChange)="onChangeCiselnikDropdown()"></app-ciselnik-dropdown>
            </div>

            <!--Needitovatelné zobrazení-->
            <app-ciselnik-field key="{{getCiselnikModel(item)}}" [guid]="getValueFromControl(item)" label="{{getLabel(item)}}" variant="short" *ngIf="isFK(item) && !getEdit(item) && !isPK(item)"></app-ciselnik-field>
            <div class="p-inputtext ui-corner-all ui-div-inputtext" *ngIf="isTextOrNumberToDisplay(item)" pTooltip="{{getValueFromControl(item)}}" tooltipPosition="bottom">
              {{getValueFromControl(item)}}
            </div>
          </div>
        </ng-container>
      </div>
    </p-panel>
  </div>
</div>
