import { Injectable } from "@angular/core";

/**
 * Sevice pro přístup k seznamu viditelných mapových vrstev.
**/
@Injectable({
  providedIn: 'root'
})
export class LocalStorageLayersInteractionService {

  /**
   * Klíč, pod kterým je v sessionStorage uložen seznam (neupravených) názvů viditelných vrstev.
  **/
  private readonly UNPROCESSED_LIST_KEY: string = "visibleLayersUnProcessed";


  /**
   * Vrátí seznam názvů viditelných vrstev.
  **/
  getVisibleList(): string[] {
    let list = localStorage.getItem(this.UNPROCESSED_LIST_KEY);
    if (list != void 0 && list != '') {
      return list.split(',');
    }
    return [];
  }


  /**
   * Uloží seznam názvů viditelných vrstev.
   * @param layerNames {string[]} seznam názvů viditelných vrstev
   */
  setVisibleList(layerNames: string[]): void {
    localStorage.setItem(this.UNPROCESSED_LIST_KEY, layerNames.join(','));
  }
}
