<p-breadcrumb [model]="breadcrumbs" [home]="breadcrumbHome"></p-breadcrumb>

<div *ngIf="model" class="ui-widget">
  <div class="p-grid kniha-body">
    <div class="p-col-2">
      <app-kniha-tree [treeLhcGuid]="lhcGuid"></app-kniha-tree>
    </div>
    <div class="p-col-10">
      <p-card>
        <p-toolbar styleClass="base-toolbar">
          <div class="p-toolbar-group-left">
            <app-lhp-kniha-tisk [guid]="porGuid"></app-lhp-kniha-tisk>
          </div>
        </p-toolbar>
        <app-kniha-detail-jp [jp]="model"></app-kniha-detail-jp>
      </p-card>
    </div>
  </div>
</div>
