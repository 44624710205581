import { formatDate } from '@angular/common';
import { Component, EventEmitter, forwardRef, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

/**
 * Komponenta pro volbu data od - do
 **/
@Component({
  selector: 'app-calendar-range',
  templateUrl: './calendar-range.component.html',
  styleUrls: ['./calendar-range.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CalendarRangeComponent),
    multi: true
  }]
})
export class CalendarRangeComponent implements OnInit, ControlValueAccessor {

  constructor() { }

  ngOnInit(): void {
  }

  /**
   * Eventa změny data s parametrem naplmněného inputu
   */
  @Output() onChange: EventEmitter<boolean> = new EventEmitter<boolean>()

  // proměnné pro implementaci rozhraní ControlValueAccessor
  onModelChange: any = () => { };
  onModelTouched: any = () => { };
  disabled: boolean = false;
  public datum: string = '';//hodnota v inputu filtru platnosti

  private _value: Date[] = [null, null];

  get value() {
    return this._value;
  }

  set value(val) {
    this._value = val == void 0 ? [null, null] : val;
    if (val?.length == 0) this.datum = "";
    let result = this._localToUtc(val);
    this.onModelChange(result);
    this.onModelTouched();
    this.onChange.emit(this.datum != void 0 && this.datum != "")
  }

  //převede na utc
  private _localToUtc(dateRange: Date[]): Date[] {
    if (dateRange?.filter(x => x != void 0 && x.toString() != "").length == 2) {
      return dateRange.map(x => new Date((new Date(x.getTime() - (x.getTimezoneOffset() * 60000))).toISOString()));
    }
    else
      return null;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }
  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }


  /**
  * Změna filtru volby data platnosti
  * @param event
  */
  dateChanged(value: string): void {
    var dateRange = value.replace(/ /g, '').split("-");
    try {
      let odFormatDate = formatDate(dateRange[0].split('.').reverse().join('-'), "yyyy-MM-dd", "cs", "locale");
      let doFormatDate = formatDate(dateRange[1].split('.').reverse().join('-'), "yyyy-MM-dd", "cs", "locale");
      if (dateRange.length == 2) {
        this.value = [new Date(Date.parse(odFormatDate)), new Date(Date.parse(doFormatDate))];
      }
    } catch (e) {
      //formatDate vyhodí výjimku, pokud není vhodný formát. Řešit validátorem je asi zbytečné.
    }
    this.onChange.emit(this.datum != void 0 && this.datum != "")
  }

  /**
   * Handler výběru data v kalendáři filtru platnosti
   **/
  onCalChanged(): void {
    this.datum = "";

    if (this.value[0] != null)
      this.datum += this.value[0]?.toLocaleDateString('cs');

    this.datum += " - ";

    if (this.value[1] != null)
      this.datum += this.value[1]?.toLocaleDateString('cs');

    if (this.value[0] == void 0 && this.value[0] == void 0) this.datum = "";
    this.value = this.value;
  }

}
