/**
 * Dto detailu dotazu dotazovače
 **/
export class DotazDetailDto {

  /**
   * Id dotazu
   */
  id: string;

  /**
   * Id relacniho modelu
   */
  modelId: string;

  /**
   * Nazev dotazu
   */
  nazev: string;

  /**
   * Kategorie dotazu
   */
  kategorie: string;

  /**
   * Info zda k dotazu pridat distinct nebo ne
   */
  addDistinct: boolean;

  /**
   * EasyQuery query jako JSON string
   */
  data: string;

  /**
   * SQL select
   */
  sqlData: string;

  /**
   * False, pokud je dotaz odemceny/True zamceny
   */
  zamceno: boolean;

  /**
   * Uzivatelske jmeno uzivatele, ktery dotaz vytvoril
   */
  userId: string;

  /**
   * Priznak rikajici, zda dotaz obsahuje parametrickou podminku nebo ne
   */
  isParametrized: boolean;
}
