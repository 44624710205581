<div *ngIf="show" class="switch-enclave" [ngStyle]="{'right':calculatedOffset}">
  <button pButton type="button" [ngClass]="{'switch-is-on': checked}" pTooltip="Přepnutí kreslení enkláv"
          class="p-button-rounded p-button-secondary p-button-outlined icon-btn hide-label-button"
          (click)="toggle()">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 126.15189 135.62683">
      <g transform="translate(-14.406934,-49.704141)">
        <path d="m 49.400187,132.64855 17.760264,17.9003 c 0,0 -17.926086,17.71176 -31.641947,31.05433 -11.21294,10.90776 -29.8811999,-5.18279 -16.109653,-18.9141 14.510795,-14.4684 29.991336,-30.04053 29.991336,-30.04053 z"></path>
        <path d="m 54.893797,127.36505 29.681066,29.67584 c 0,0 4.274299,-4.62387 33.493907,-33.92652 30.73649,-30.823838 21.44621,-68.309221 19.62196,-71.679408 -1.37924,-2.548042 -5.25058,-1.675818 -7.00652,0.01803 -1.75593,1.693852 -75.790413,75.912058 -75.790413,75.912058 z"></path>
      </g>
    </svg>
  </button>
</div>
