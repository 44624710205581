<span class="attachment-btn attachment-btn-edit"
      pTooltip="Spravovat přílohy náčrtu" tooltipPosition="bottom"
      (click)="onAttachmentBtnClick()">
  <fa-layers>
    <fa-icon [icon]="faPaperclip" size="2x"></fa-icon>
    <fa-layers-counter *ngIf="prilohyCnt > 0" [content]="prilohyCnt"></fa-layers-counter>
  </fa-layers>
</span>

<p-blockUI [blocked]="loading">
  <p-progressSpinner></p-progressSpinner>
</p-blockUI>

<p-dialog header="Přílohy náčrtu" [(visible)]="display" styleClass="nacrt-edit-dialog"
          modal="true" [closeOnEscape]="true" [closable]="true" appendTo="body" [resizable]="false" [blockScroll]="true"
          [style]="{'width': '800px'}">

  <p-tabView>
    <p-tabPanel header="Fotografie">
      <app-nacrt-priloha-foto-edit></app-nacrt-priloha-foto-edit>
    </p-tabPanel>
    <p-tabPanel header="Zvukové nahrávky" class="nacrt-priloha-audio">
      <app-nacrt-priloha-audio-edit></app-nacrt-priloha-audio-edit>
    </p-tabPanel>
  </p-tabView>

  <p-footer>
    <button pButton type="button" class="icon-btn hide-label-button"
            pTooltip="Zavřít" tooltipPosition="bottom"
            (click)="display = false">
      <fa-icon [icon]="faCheck"></fa-icon>
    </button>
  </p-footer>
</p-dialog>

<p-confirmPopup key="{{CONFIRM_KEY}}"></p-confirmPopup>
