import { NacrtAkceSyncDto } from "./NacrtAkceSyncDto";

export class NacrtSyncDto {
  guid: string;
  nacrtProjektGuid: string;
  rozdeleniLesaGuid: string;
  plocha: number;
  obvod: number;
  popis: string;
  poznamka: string;
  cislo: number;
  akce: NacrtAkceSyncDto[];
}
