<p-toast position="top-center" key="zakaznici-list-toast"></p-toast>

<app-block-ui [block]="block"></app-block-ui>

<p-toolbar styleClass="base-toolbar">
  <div class="p-toolbar-group-left">
    <h2 class="toolbar-label">Zákazníci</h2>
    <i class="p-toolbar-separator"><fa-icon [icon]="faEllipsisV"></fa-icon></i>
    <button pButton [disabled]="data != undefined" type="button" label="Nový" class="icon-btn" (click)="add()"><fa-icon [icon]="faPlus"></fa-icon></button>
    <div style="display: inline-block">
      <div class="p-inputgroup">
        <input type="text" pInputText placeholder="Hledat" [(ngModel)]="filter" style="width: 20em" />
        <button pButton type="button" class="icon-btn hide-label-button" (click)="filter = undefined" [disabled]="filter == undefined"><fa-icon [icon]="faBackspace"></fa-icon></button>
      </div>
    </div>
  </div>
  <div class="p-toolbar-group-right">
    <button pButton type="button" label="Zpět" class="icon-btn" (click)="back()"><fa-icon [icon]="faUndo"></fa-icon></button>
  </div>
</p-toolbar>

<propla-table #zakazniciTable [cols]="cols" [allItems]="zakaznici" selectionMode="single" [filterGlobal]="filter"
(rowSelected)="onRowSelected($event)"
(rowUnSelected)="onRowUnselected($event)"
(dataLoaded)="dataLoaded()">
</propla-table>

<p-panel *ngIf="data != undefined" [style]="{'margin-top': '1em'}">
  <ng-template pTemplate="header">
    <div class="p-grid p-align-center">
      <div class="p-col"><span class="p-panel-title">Zákazník — {{data.nazev}}</span></div>
      <div class="p-col right">
        <button pButton type="button" label="Uložit" class="icon-btn p-button-success" (click)="save()"><fa-icon [icon]="faSave"></fa-icon></button>
        <button pButton type="button" label="Zrušit" class="icon-btn" (click)="cancel()"><fa-icon [icon]="faTimes"></fa-icon></button>
      </div>
    </div>
  </ng-template>
  <div class="p-grid">
    <div class="p-col-2">
      Název zákazníka
      <input #nazevZakaznika type="text" pInputText [(ngModel)]="data.nazev" />
    </div>
  </div>
</p-panel>

