import { EtazVolbaDto } from "../Lhp/EtazVolbaDto";


export class OliRadekDetailDto extends EtazVolbaDto {
  guid: string;
  sortimentObchodniGuid: string;
  sortimentGuid: string;
  mnozstvi: number;
  cislo: number;
}
