<div class="p-grid" style="padding-top:0.5rem;">
  <div class="p-col-10">
    Typ čáry linií
    <div class="show-box" [ngStyle]="{'padding-top': paddingTop}">
      <div [style]="usedLineType?.cssStyle"></div>
    </div>
  </div>
  <div class="p-col-2" style="text-align: right; margin-top: 1.3rem;">
    <button pButton type="button" class="icon-btn hide-label-button"
            pTooltip="Vybrat typ čáty" tooltipPosition="top"
            (click)="showDlg=true;">
      <fa-icon [icon]="faAlignCenter"></fa-icon>
    </button>
  </div>
</div>

<p-dialog header="Katalog typů čar" [(visible)]="showDlg" modal="true" dismissableMask="true"
          [style]="{width:'33rem', height: '90vh', 'max-height': '90vh'}"
          [contentStyle]="{'max-height':'80vh', 'overflow': 'unset', 'padding-bottom': 0}">
  <p-listbox [options]="lineTypes" [(ngModel)]="selectedLineType"
             [metaKeySelection]="false" [filter]="true" optionLabel="title"
             emptyFilterMessage="Hledanému výrazu neodpovídá žádný typ čáry" emptyMessage="Nebyl nalezen žádný typ čáry"
             [style]="{'width':'100%'}" [listStyle]="{'max-height': '71vh'}">
    <ng-template let-item pTemplate="item">
      <div class="p-grid" style="width: 100%; align-items: center;">
        <div class="p-col-4">
          <p style="text-align: left;"><span>{{item.title}}</span></p>
        </div>
        <div class="p-col-8">
          <div [style]="item.cssStyle"></div>
        </div>
      </div>
    </ng-template>
  </p-listbox>

  <p-footer>
    <button pButton type="button" class="icon-btn hide-label-button"
            pTooltip="Použít" tooltipPosition="bottom"
            (click)="pouzitTypCary()">
      <fa-icon [icon]="faCheck"></fa-icon>
    </button>
    <button pButton type="button" class="icon-btn hide-label-button times-btn-icon"
            pTooltip="Zavřít" tooltipPosition="bottom"
            (click)="showDlg = false;">
      <fa-icon [icon]="faTimes"></fa-icon>
    </button>
  </p-footer>
</p-dialog>
