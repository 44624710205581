<p-dialog header="Zadejte parametry dotazu '{{dotaz?.nazev}}'" [(visible)]="display" [style]="{width: '45vw'}" [modal]="true"
          (onShow)="onShowDialog()">
  <app-easy-query-params #eq [modelId]="eqModel" (onChange)="onChangeQuery($event)" [data]="eqData"></app-easy-query-params>

  <ng-template pTemplate="footer">
    <button pButton label="Spustit dotaz" class="icon-btn p-button-success" (click)="run()" [disabled]="!data"><fa-icon [icon]="faCheck"></fa-icon></button>
    <button pButton (click)="close()" label="Zavřít" class="icon-btn p-button-secondary p-button-outlined"><fa-icon [icon]="faTimes"></fa-icon></button>
  </ng-template>
</p-dialog>

