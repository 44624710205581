import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { VypocetDetailDto } from '../../Dto/Skody/VypocetDetailDto';

@Injectable({
  providedIn: 'root'
})
export class SkodyServices {
  constructor(
    private http: HttpClient
  ) { }

  private apiIndex: string = "api/Skody/Index";
  private apiDetail: string = "api/Skody/Detail";
  private apiSave: string = "api/Skody/Save";
  private apiPrepocti: string = "api/Skody/Prepocti";

  private apiObdobi: string = "api/Obdobi/List";

  index(obdobi: string): Observable<any> {
    return this.http.get(this.apiIndex + '/' + obdobi);
  };

  detail(id: string): Observable<any> {
    return this.http.get(this.apiDetail + '/' + id);
  };

  save(skoda: VypocetDetailDto): Observable<any> {
    return this.http.post(this.apiSave, skoda, {});
  };

  prepocti(data: VypocetDetailDto): Observable<any> {
    return this.http.post(this.apiPrepocti, data, {});
  };

  obdobi(): Observable<any> {
    return this.http.get(this.apiObdobi);
  };
}
