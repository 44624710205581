import { Component, Input, ViewChild } from '@angular/core';
import { Sidebar } from 'primeng/sidebar';
import { faInfoCircle, faLock, faUnlock, faTimes } from '@fortawesome/free-solid-svg-icons';
import { MapItemDto } from '../../../Dto/Mapa/MapItemDto';
import { NacrtProjektSourceDto } from '../../../Dto/Nacrty/NacrtProjectSourceDto';
import { NavUtils } from '../../../Utils/Shared/nav.utils';
import { MapInteractionService } from '../../../Services/Mapa/map-interaction.service';

@Component({
  selector: 'app-map-info-panel',
  templateUrl: './map-info-panel.component.html',
  styleUrls: ['./map-info-panel.component.css']
})
export class MapInfoPanelComponent {

  @ViewChild('infoSideBar') infoSideBar: Sidebar;

  _nacrtProjects: NacrtProjektSourceDto[];
  @Input() set nacrtProjects(value: NacrtProjektSourceDto[]) {
    if (value != void 0) {
      this._nacrtProjects = value;
    }
  }

  faInfoCircle = faInfoCircle;
  faLock = faLock;
  faUnlock = faUnlock;
  faTimes = faTimes;
  infoItems = {};
  panelClosable: boolean = true;
  panelWidth: string = '24rem';
  infoPanelVisible: boolean = false;
  private _mapInfoNacrtNewVisible: boolean = false;

  constructor(
    private navUtils: NavUtils,
    private mapInteractionService: MapInteractionService)
  {
    this.mapInteractionService.DrawStart.subscribe(_ => this.panelClosable = false);
    this.mapInteractionService.DiscardChanges.subscribe(_ => this.panelClosable = true);
    this.mapInteractionService.EditFeature.subscribe(_ => this.panelClosable = false);
    this.mapInteractionService.FeatureChanged.subscribe(_ => this.panelClosable = false);
    this.mapInteractionService.NacrtFinished.subscribe(_ => this.panelClosable = true);
    this.mapInteractionService.ToggleInfoPanel.subscribe(visible => { if (visible || !this._mapInfoNacrtNewVisible) this.infoPanelVisible = visible; });
    this.mapInteractionService.SelectedItems.subscribe(this._showInInfoPanel.bind(this));
  }


  /**
   * Handler zavření info panelu.
  **/
  close(): void {
    this.mapInteractionService.closeInfo();
  }

  /**
   * Handler skrytí info panelu.
  **/
  onInfoPanelHide(): void {
    this.mapInteractionService.contolOffset({ panel: 'infoPanel', offset: '0px' });
  }


  /**
   * Handler zobrazení info panelu.
  **/
  onInfoPanelShow(): void {
    this.mapInteractionService.contolOffset({ panel: 'infoPanel', offset: this.panelWidth });
  }


  /**
   * Roztřízení mapových položek dle modulů.
   * @param data {MapItemDto[]} data mapových objektů, která se mají zobrazit v infopanelu
   */
  private _showInInfoPanel(data: MapItemDto[]): void {
    this.infoItems = {};
    if (data != void 0) {
      let modules = data.map(d => d.modul);
      if (modules != void 0) {
        modules
          .filter((v, i, s) => { return s.indexOf(v) === i; })
          .forEach(modul => { this.infoItems[modul] = data.filter(i => i.modul == modul); });
      }
    }
  }


  /**
   * True, pokud je zobrazena navigace v horní části
   */
  get displayNav(): boolean {
    return this.navUtils.display;
  }


  /**
   * Handler změny viditelnosti komponenty pro tvorbu nového náčrtu.
   * @param visible {boolean} true, pokud je komponenta "MapInfoNacrtNew" viditelná
   */
  newVisibleHandler(visible: boolean): void {
    this._mapInfoNacrtNewVisible = visible;
  }
}
