<div *ngIf="lhc" class="ui-widget">
  <p-breadcrumb [model]="breadcrumbs" [home]="breadcrumbHome"></p-breadcrumb>

  <div class="p-grid kniha-body">
    <div class="p-col-2">
      <app-kniha-tree [treeLhcGuid]="lhcGuid"></app-kniha-tree>
    </div>
    <div class="p-col-10">
      <p-card>
        <app-kniha-detail-lhc [lhc]="lhc"></app-kniha-detail-lhc>
      </p-card>
    </div>
  </div>
</div>
