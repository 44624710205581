import { Component, OnInit, forwardRef, Input } from '@angular/core';
import { CiselnikDropdownComponent } from 'src/app/Components/Shared/ciselnik-dropdown/ciselnik-dropdown.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { CiselnikyService } from 'src/app/Services/Shared/ciselniky.service';

/**
 * Komponenta pro zobrazení nabídky podvýkonů na základě zvoleného výkonu.
 */
@Component({
  selector: 'app-podvykon-dropdown',
  templateUrl: '../../Shared/ciselnik-dropdown/ciselnik-dropdown.component.html',
  styleUrls: ['../../Shared/ciselnik-dropdown/ciselnik-dropdown.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PodvykonDropdownComponent),
    multi: true
  }]
})
export class PodvykonDropdownComponent extends CiselnikDropdownComponent implements OnInit {

  private _vykonGuid: string;

  @Input()
  set vykonGuid(value: string) {
    if (value != this._vykonGuid) {
      this._vykonGuid = value;
      let currentValue = this._rawData.find(x => x.guid == this.value);
      if (currentValue != void 0 && currentValue['parentGuid'] != value) {
        this.value = null;
      }
      this.buildData();
    }
  }

  constructor(
    ciselnikyService: CiselnikyService
  ) {
    super(ciselnikyService);
  }

  ngOnInit(): void {
    this.ciselnik = "CPodvykon";
  }

  // override
  protected buildData(): void {
    var auxData = this._rawData
      .filter(x => {
        return (x['parentGuid'] == this._vykonGuid);
      }, this)
      .sort((a, b) => { return Number(a.kod) - Number(b.kod); })
      .map(x => {
        return {
          label: x.kod + " - " + x.popis, value: x.guid
        };
      });

    if (this._nullable) {
      this.data = [{ label: "Ponechat prázdné", value: null }].concat(auxData);
    }
    else {
      this.data = auxData;
    }
    this.selectByGuid(); 
  }
}
