import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { faBackspace, faSitemap } from '@fortawesome/free-solid-svg-icons';
import { MessageService } from 'primeng/api';
import { TreeNode } from 'primeng/api/treenode';
import { PodCiselnikListDto } from 'src/app/Dto/Shared/PodCiselnikListDto';
import { AuthService } from 'src/app/Services/Auth/auth.service';
import { CiselnikyService } from 'src/app/Services/Shared/ciselniky.service';
import { MessagesUtils } from '../../../Utils/Shared/messages.utils';
import { OrgUrUtils } from '../../../Utils/Shared/org-ur.utils';

@Component({
  selector: 'app-org-ur',
  templateUrl: './org-ur.component.html',
  styleUrls: ['./org-ur.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => OrgUrComponent),
    multi: true
  }]
})
export class OrgUrComponent implements ControlValueAccessor, OnInit {

  constructor(private ciselnikyService: CiselnikyService,
    private authService: AuthService,
    private messageService: MessageService,
    private orgUrUtils: OrgUrUtils) {
    authService.loggedOut.subscribe(() => {
      OrgUrComponent.orgUrTree = null;
    });
  }

  ngOnInit() {
    if (this.orgUrList == void 0 && !this.loading) {
      this.loadOrgUrList();
    }
  }

  /**
 * Volba způsobu načítání obsahu.
 * - 'admin' - pro role SpuperAdmin a LhpAdmin v editaci LHC, kde je nutné zadat zakaznikGuid
 * - 'base' - základní filtrování podle přihlášeného zákazníka na straně serveru
 **/
  _mode: string = "base";
  @Input() set mode(value: string) {
    if (value != void 0 && value != '') {
      this._mode = value;
    }
  }

  @Input() backSpace: boolean = false;
  @Input() sitemapIcon: boolean = false;

  private _zakaznikGuid: string;
  /**
   * Načte obsah podle zákazníka
   **/
  @Input() set zakaznikGuid(val: string) {
    this._zakaznikGuid = val;
    OrgUrComponent.orgUrTree = null;
    this.loadOrgUrList();
  }


  //pomocná proměnná, aby se seznam orgUr nenačítal 2x.
  private loading: boolean = false;

  /**
   * org. ur. dostupné uživateli
   */
  userOrgUrGuidList: string[];

  faBackspace = faBackspace;
  faSitemap = faSitemap;

  private readonly MESSAGE_KEY: string = "org-ur-toast";

  /**
   * Načtení seznamu organizačních úrovní.
  **/
  private loadOrgUrList() {
    this.loading = true;
    if (this._mode == "admin" && this._zakaznikGuid != void 0) {
      this.ciselnikyService.getOrgUr(this._zakaznikGuid).subscribe(res => {
        this.orgUrList = (res as PodCiselnikListDto[]);
        this.userOrgUrGuidList = res.map(x => x.guid);
        this.loading = false;
      });
      return;
    }

    if (this._mode == "base")
      this.ciselnikyService.getCislenik("COrgUr", null).subscribe(cOrgUr => {
        this.orgUrList = (cOrgUr as PodCiselnikListDto[]);
        this.authService.userOrgUrs().subscribe(res => {
          if (res.success) {
            this.userOrgUrGuidList = res.data;
          } else {
            this.messageService.add({ severity: 'error', summary: 'Chyba', key: this.MESSAGE_KEY, detail: res.messages[0] })
          }
        });
        this.orgUrsLoaded.emit();
        this.loading = false;
      });
  }

  /**
   * Varianta zobrazení podle dostupného prostoru:
   * - 'short' - jen kody oddělené lomítkem
   * - 'medium' (výchozí) - kody oddělené lomítkem + název nejnižší org. úrovně
   * - 'full' - kody oddělené lomítkem + názvy všech úrovní oddělené lomítkem
   */
  @Input() variant: string = "medium";

  // proměnné pro implementaci rozhraní ControlValueAccessor
  onModelChange: any = () => { };
  onModelTouched: any = () => { };
  disabled: boolean = false;

  @Input('value') _value: string = null;
  @Input() edit: boolean = true;
  @Input() allLevelsSelectable: boolean = false;//Všechyn povolené uzly jsou vyditelné a lze je vybrat
  @Input() onlyLevel2Selectable: boolean = false;//Jsou zobrazené povolené orgur1 a orgur2. Vybrat jde pouze orgur2
  @Input() useInFilter: boolean = false;//použití ve fitru bez tlačítka
  @Input() showInput: boolean = true;//má být zobrazeno vstupní pole s popisem a tlačitkem
  @Input() useAll: boolean = false;//zobrazit všechny uzly. tzn. i první bez potomků

  static orgUrNotFoundInFilter: string = "$";//ve filtru je potřeba odlišit prázdné pole od nenalezeného guidu
  private _orgUrNotFoundInFilter: boolean = false;


  //event předávající na výstup sestavený string orgUr.
  @Output() valueChanged: EventEmitter<string> = new EventEmitter<string>();
  //event informující o dokončeném načtení orgUr.
  @Output() orgUrsLoaded: EventEmitter<any> = new EventEmitter<any>();

  get value() {
    return this._value;
  }

  set value(val) {
    if (val == void 0 || val == '') val = null; //pro případ, že uživatel smaže vše v inputu
    this._value = val;
    this.onModelChange(val);
    this.onModelTouched();
    if (this.orgUrList == void 0) {
      this.orgUrsLoaded.subscribe(_ => {
        this._setValue(val);
      });

      if (!this.loading) {
        this.loadOrgUrList();
      }
    } else {
      this._setValue(val);
    }
  }

  writeValue(obj: any): void {
    this.value = obj;
  }
  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  static orgUrTree: TreeNode[];

  orgUrRef = OrgUrComponent;

  //obsah vstupního pole
  orgUr: string;
  orgUrList: PodCiselnikListDto[];
  displayDialog: boolean = false;
  selectedOrgUr: TreeNode;

  // název nejnižší org. úrovně
  orgUrText: string;
  // úplný název org ur - všechny úrovně
  orgUrAllText: string;

  //nastaví obsah vstupního pole
  private _setValue(guid: string): void {
    //pro případ filtru pouze odeslat eventu o nenalezeném guidu
    if (this._orgUrNotFoundInFilter) {
      this._orgUrNotFoundInFilter = false;
      this.orgUrAllText = "";
      this.valueChanged.emit(OrgUrComponent.orgUrNotFoundInFilter);
    } else {
      let orgUr3 = this.orgUrList.find(x => x.guid == guid);
      this.orgUr = orgUr3?.kod;
      this.orgUrText = orgUr3?.popis;
      this.orgUrAllText = orgUr3?.popis;
      this._fillValue(orgUr3);
      this.valueChanged.emit(this.value);
    }
  };

  //sestaví řetězec pro vstupní pole
  private _fillValue(value: PodCiselnikListDto) {
    this.orgUrList.forEach(function (orgUrLoc) {
      if (orgUrLoc.guid == value?.parentGuid) {
        this.orgUr = orgUrLoc.kod + "/" + this.orgUr;
        this.orgUrAllText = orgUrLoc.popis + " / " + this.orgUrAllText;
        if (value.parentGuid != null) {
          this._fillValue(orgUrLoc);
        }
      }
    }, this);
  }

  /**
   * Zobrazí dialog se stromem org ur
   * */
  showDialog(): void {
    this.displayDialog = true;
    //pokud strom ještě neexistuje, tak vytvořit
    if (OrgUrComponent.orgUrTree == void 0) {
      OrgUrComponent.orgUrTree = this.orgUrUtils.gerTreeFromCiselnik(this.orgUrList, this.userOrgUrGuidList, this.allLevelsSelectable, this.onlyLevel2Selectable, this.useAll);
    }
  }


  //použít vybrané org ur a zavře dialod
  selectOrgUr(): void {
    this.value = this.selectedOrgUr.data.guid;
    this.displayDialog = false;
  }

  //handler změny v inputu po upuštění pole
  inputChange(data): void {
    let valueField = data.target.value.split("/").filter(x => x != "");
    var result: string = void 0;
    valueField.forEach(function (kod) {
      result = this.orgUrList.find(x => x.kod == kod && x.parentGuid == result)?.guid;
    }, this);

    //u filtru odlišit prázdné pole od nenalezeného guidu
    if (this.useInFilter && data.target.value != void 0 && data.target.value != "" && result == void 0)
      this._orgUrNotFoundInFilter = true;

    //nenalezený guid by měla řešit validace. Bez správného výsledku nelze uložit.
    this.value = result;


  }

  //sbalí všechny podřazené větve
  private _colapseRecursive(node: TreeNode) {
    node.expanded = false;
    if (node.children) {
      node.children.forEach(childNode => {
        this._colapseRecursive(childNode);
      });
    }
  }

  //najde základní uzel a vrátí jeho guid
  private _findRootGuid(guid: string): string {
    var result;
    this.orgUrList.forEach(function (orgUrItem) {
      if (orgUrItem.guid == guid && orgUrItem.parentGuid != void 0) {
        result = orgUrItem.parentGuid;
        let parentResult = this._findRootGuid(orgUrItem.parentGuid);
        if (parentResult != void 0)
          result = parentResult;
      }
    }, this);
    return result;
  }

  //handler rozbalení větve
  onNodeExpanded(event): void {
    var guid = this._findRootGuid(event.node.data.guid);
    OrgUrComponent.orgUrTree.forEach(node => {
      if ((event.node.data.parentGuid == void 0 && event.node.data.guid != node.data.guid) || (event.node.data.parentGuid != void 0 && node.data.guid != guid))
        this._colapseRecursive(node);
    });
  }

  /**
   * Oveří domovskou or. ur. uživatele a pokud není org. ur. 3, tak doporučit výběr
   * @param userOrgUrGuid domovská org. ur.
   */
  checkUserOrgUr(userOrgUrGuid: string): boolean {
    var showMsgDialog: boolean = false;
    if (userOrgUrGuid != void 0 && this.orgUrList != void 0) {
      var showMsgDialog: boolean = false;
      var userOrgUr = this.orgUrList.find(x => x.guid == userOrgUrGuid);
      if (userOrgUr.parentGuid != void 0) {
        var parentUserOrgUr = this.orgUrList.find(x => x.guid == userOrgUr.parentGuid);
        if (parentUserOrgUr.parentGuid == void 0) {
          showMsgDialog = true;
        }
      } else {
        showMsgDialog = true;
      }

      if (showMsgDialog && !this.displayDialog) {
        this.showDialog();
        this.messageService.clear(this.MESSAGE_KEY);
        this.messageService.add({ severity: 'warn', summary: 'Upozornění', key: this.MESSAGE_KEY, detail: 'Je zvolena nevhodá organizační úroveň. Vyberte prosím jinou.', life: MessagesUtils.TOAST_LIFE })
      }
    }
    return showMsgDialog;
  }

  public baskSpaceClicked(): void {
    this.value = null;
  }
}
