import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LhpDrevinaProVypocetDto } from 'src/app/Dto/Skody/LhpDrevinaProVypocetDto';
import { LhpEtazProVypocetDto } from 'src/app/Dto/Skody/LhpEtazProVypocetDto';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { SkodyDetailSBaseComponent } from '../skody-detail-s-base/skody-detail-s-base.component';


/**
 * Komponenta detailu škody typu S7.2.
**/
@Component({
  selector: 'skody-detail-s72',
  templateUrl: './skody-detail-s72.component.html',
  styleUrls: ['./skody-detail-s72.component.css']
})
export class SkodyDetailS72Component extends SkodyDetailSBaseComponent implements OnInit {

  constructor(
    fb: FormBuilder
  ) {
    super(fb);
  }


  druhKeyFilter: RegExp = /^[pPkK]?$/;
  typKeyFilter: RegExp = /^[hHpP]?$/;


  data: FormGroup = this.fb.group({
    guid: [''],
    typ: [''],    
    plocha: [''],
    sazDruh: [''],
    sazTyp: [''],
    np: [''],
    nHa: [''],
    k2: [''],
    chs: [''],
    zast: [''],
  });


  ngOnInit() {
    this.data.valueChanges
      .pipe(
        debounceTime(600),
        distinctUntilChanged((prev, curr) => prev.guid === curr.guid && prev.plocha === curr.plocha
          && prev.plochaVypocet === curr.plochaVypocet && prev.sazDruh === curr.sazDruh && prev.sazTyp === curr.sazTyp && prev.np === curr.np && prev.nHa === curr.nHa
          && prev.k2 === curr.k2 && prev.chs === curr.chs && prev.zast === curr.zast),
        filter(data => this.validateVstupyProPrepocet(data)),
      )
      .subscribe(() => {
        this.dataChanged.emit(this.data.value);
      });
  };


  /**
   * Kontrola dat z S72 pro přepočet škody.
   * @param data
   */
  validateVstupyProPrepocet(data): boolean {
    return data.plocha != void 0
      && data.zast != void 0
      && data.chs != void 0
      && data.sazTyp != void 0 && data.sazTyp != ''
      && data.sazDruh != void 0 && data.sazDruh != ''
      && data.np != void 0
      && data.k2 != void 0;
  };

  
  /**
   * Zkopíruje data etáže z LHP do vstupu.
  **/
  kopirujLhpEtazDoVstupu(dto: LhpEtazProVypocetDto): void {
    if (dto != void 0) {
      this.data.get('plocha').setValue(dto.etazPs);
      this.data.get('chs').setValue(dto.chs);
    }
  };

  /**
   * Zkopíruje data dřeviny z LHP do vstupu.
  **/
  kopirujLhpDrevinuDoVstupu(dto: LhpDrevinaProVypocetDto): void {
    if (dto != void 0) {
      this.data.get('zast').setValue(dto.zast);
    }
  };
}
