import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { EqViewOptions, AdvancedSearchView } from '@easyquery/ui';
import { EqContext, i18n } from '@easyquery/core';
import { EqLocaleInfo } from 'src/app/Utils/Shared/eq-locale-info.utils';

@Component({
  selector: 'app-vyroba-eq-conditions-editor',
  templateUrl: './vyroba-eq-conditions-editor.component.html',
  styleUrls: ['./vyroba-eq-conditions-editor.component.css']
})
export class VyrobaEqConditionsEditorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  view: AdvancedSearchView;
  context: EqContext;

  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

  @Input() useJson: boolean = false;//odpověd v json nebo sql
  @Input() display: boolean = false;
  _model: string = "VyrDokladVML";
  //zvolí model podle typu dokladu
  @Input() set dokladType(value: string) {
    switch (value.toLowerCase()) {
      case "vml":
        this._model = "VyrDokladVML";
        break;
      case "ol":
        this._model = "VyrDokladOL";
        break;
      case "cd":
        this._model = "VyrDokladCD";
        break;
      default:
        break;
    }
  }

  //inicializace easy query
  private _loadEasyQuery(): void {
    i18n.updateLocaleInfo('cs', EqLocaleInfo.Czech);
    i18n.setCurrentLocale('cs');

    const options: EqViewOptions = {
      loadModelOnStart: true,
      defaultModelId: this._model,
      endpoint: "/api/easyquery",
      handlers: {
        onError: (context, status) => {
          console.error(status.action + " error:\n" + status.text);
        }
      },
      widgets: {
        entitiesPanel: {
        },
        columnsPanel: {
        },
        queryPanel: {
        },
      },
      result: {
        showChart: false
      }
    };

    this.view = new AdvancedSearchView();
    this.context = this.view.getContext();
    this.view.init(options);
  }

  //odeslání výsledku a zavření
  save(): void {
    if (this.useJson) {
      this.onChange.emit(this.context.getQuery().toJSON());
    } else {
      this.onChange.emit(this.context.resultStatement);
    }
    
    this.cancel();
  }

  //zavření dialogu
  cancel(): void {
    this.close.emit();
  }

  //na událost zobrazení dialogu zinicializovat easy query, nebo provést reload podle typu modelu.
  onShowDialog(): void {
    if (this.view == void 0)
      this._loadEasyQuery();
    else {
      this.context.clearQuery();
      this.context.defaultModelId = this._model;
      this.context.loadDefaultModel();
    }
  }
}
