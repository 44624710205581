import { EventEmitter, Injectable, Output } from "@angular/core";
import { ISchvalWorkflowDto } from "../../Dto/Nacrty/ISchvalWorkflowDto";
import { LoggingEventEmitter } from "../../Extensions/LoggingEventEmitter";

/**
 * Service sloužící k distribuci modulových požadavků na provedení akcí po schválení náčrtu.
**/
@Injectable()
export class NacrtySchvalitInteractionService {

  constructor() {}

  /**
   * Událost předávající požadavky modulů na postproces schválení náčrtu.
  **/
  @Output() SchvaleniPostprocess: EventEmitter<ISchvalWorkflowDto[]> = new LoggingEventEmitter<ISchvalWorkflowDto[]>('SchvaleniPostprocess');

  /**
   * Vypuštění události pro distribuci požadavků jednotlivých modulů na postprocesní akce po schválení náčrtu.
   * @param moduleData {ISchvalWorkflowDto[]} parametry události postprocesních akcí schválení náčrtů
   */
  schvaleniPostprocess(moduleData: ISchvalWorkflowDto[]): void {
    this.SchvaleniPostprocess.emit(moduleData);
  }
}
