import { OnDestroy } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { faPaperclip, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { NacrtMapItemDto } from '../../../Dto/Nacrty/NacrtMapItemDto';
import { NacrtPrilohyChangedArgs } from '../../../Dto/Nacrty/NacrtPrilohyChangedArgs';
import { NacrtyInteractionService } from '../../../Services/Nacrty/nacrty-interaction.service';

/**
 * Komponenta editace příloh náčrtu.
**/
@Component({
  selector: 'app-nacrt-priloha-edit',
  templateUrl: './nacrt-priloha-edit.component.html',
  styleUrls: ['./nacrt-priloha-edit.component.css']
})
export class NacrtPrilohaEditComponent implements OnInit, OnDestroy
{
  /**
    * Guid náčrtu, jehož přílohami se bude pracovat.
   **/
  _nacrtData: NacrtMapItemDto = null;
  @Input() set data(value: NacrtMapItemDto) {
    if (value != void 0) {
      this._nacrtData = value;
      this.prilohyCnt = value.prilohyCount;
    }
  }

  _newNacrt: boolean = false;
  @Input() set newNacrt(value: boolean) {
    if (value != void 0) {
      this._newNacrt = value;
    }
  }


  /**
   * Konstruktor.
   * @param confirmationService {ConfirmationService}
   * @param nacrtyService
   */
  constructor(
    private nacrtyInteractionService: NacrtyInteractionService)
  { }


  readonly CONFIRM_KEY: string = 'dlgCloseConfirm';
  display: boolean = false;
  loading: boolean = false;
  faPaperclip = faPaperclip;
  faCheck = faCheck;
  faTimes = faTimes;
  prilohyCnt: number = 0;
  private _onInitEditSubs: Subscription;
  private _onAddSubs: Subscription;
  private _onDeleteSubs: Subscription;


  ngOnInit() {
    this._onInitEditSubs = this.nacrtyInteractionService.OnInitEdit.subscribe(_ => {
      this.display = true;
      this.loading = false;
    });
    this._onAddSubs = this.nacrtyInteractionService.OnAdd.subscribe(this._updateCount.bind(this));
    this._onDeleteSubs = this.nacrtyInteractionService.OnDelete.subscribe(this._updateCount.bind(this));
  }


  ngOnDestroy() {
    this._onInitEditSubs.unsubscribe();
    this._onAddSubs.unsubscribe();
    this._onDeleteSubs.unsubscribe();
  }


  /**
   * Handler kliknutí na tlačítko editace příloh.
  **/
  onAttachmentBtnClick(): void {
    this.loading = true;
    this.nacrtyInteractionService.initEdit(this._nacrtData, this._newNacrt);
  }


  /**
   * Aktualizace počtu příloh.
   * @param args {NacrtPrilohyChangedArgs} argumenty příloh náčrtu
   */
  private _updateCount(args: NacrtPrilohyChangedArgs): void {
    this.prilohyCnt = args.prilohy.filter(p => !p.sync.d).length
  }
}
