<p-tree #tr [value]="knihaRef.jprlTree" [loading]="loading" (onNodeExpand)="nodeExpanded($event)">
  <ng-template let-node pTemplate="LhcListDto">
    {{node.data.lhcKod}}
  </ng-template>
  <ng-template let-node pTemplate="OddListDto">
    <a [routerLink]="['/kniha', node.parent.data.lhcKod, node.data.odd, node.data.guid]">{{node.data.odd}}</a>
  </ng-template>
  <ng-template let-node pTemplate="DilListDto">
    <a [routerLink]="['/kniha', node.parent.parent.data.lhcKod, node.parent.data.odd, node.data.dil, node.data.guid]">{{node.data.dil}}</a>
  </ng-template>
  <ng-template let-node pTemplate="PorListDto">
    <a [routerLink]="['/kniha', node.parent.parent.parent.data.lhcKod, node.parent.parent.data.odd, node.parent.data.dil, node.data.por, node.data.guid]">{{node.data.por}}</a>
  </ng-template>
  <ng-template let-node pTemplate="PskListDto">
    <a [routerLink]="['/kniha', node.parent.parent.parent.parent.data.lhcKod, node.parent.parent.parent.data.odd, node.parent.parent.data.dil, node.parent.data.por, 'psk', node.data.psk, node.data.guid]">{{node.data.psk}}</a>
  </ng-template>
  <ng-template let-node pTemplate="EtazListDto">
    <a [routerLink]="['/kniha', node.parent.parent.parent.parent.parent.data.lhcKod, node.parent.parent.parent.parent.data.odd, node.parent.parent.parent.data.dil, node.parent.parent.data.por, 'psk', node.parent.data.psk, node.data.etaz, node.data.guid]">{{node.data.etaz}} (zakm. {{node.data.zakm}})</a>
  </ng-template>
  <ng-template let-node pTemplate="DrevinListDto">
    <a [routerLink]="['/kniha', node.parent.parent.parent.parent.parent.parent.data.lhcKod, node.parent.parent.parent.parent.parent.data.odd, node.parent.parent.parent.parent.data.dil, node.parent.parent.parent.data.por, 'psk', node.parent.parent.data.psk, node.parent.data.etaz, node.data.drZkr, node.data.guid]">{{node.data.drZkr}} ({{node.data.zast}} %)</a>
  </ng-template>
  <ng-template let-node pTemplate="BzlListDto">
    <a [routerLink]="['/kniha', node.parent.parent.parent.parent.data.lhcKod, node.parent.parent.parent.data.odd, node.parent.parent.data.dil, node.parent.data.por, 'bzl', node.data.bzl, node.data.katuzeKod, node.data.guid]">BZL {{node.data.bzl}}</a>
  </ng-template>
  <ng-template let-node pTemplate="JpListDto">
    <a [routerLink]="['/kniha', node.parent.parent.parent.parent.data.lhcKod, node.parent.parent.parent.data.odd, node.parent.parent.data.dil, node.parent.data.por, 'jp', node.data.jp, node.data.katuzeKod, node.data.guid]">JP {{node.data.jp}}</a>
  </ng-template>
  <ng-template let-node pTemplate="OpListDto">
    <a [routerLink]="['/kniha', node.parent.parent.parent.parent.data.lhcKod, node.parent.parent.parent.data.odd, node.parent.parent.data.dil, node.parent.data.por, 'op', node.data.op, node.data.katuzeKod, node.data.guid]">OP {{node.data.op}}</a>
  </ng-template>
</p-tree>
