import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { OptionsService } from './Services/Shared/options.service';
import { NavUtils } from './Utils/Shared/nav.utils';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  apiVersion: string = "(načítám)";

  constructor(
    private activeRoute: ActivatedRoute,
    private optionsService: OptionsService,
    private navUtils: NavUtils,
    private config: PrimeNGConfig
  ) {
  }

  title = 'ClientApp';
  displayNav = true;

  async ngOnInit() {

    this.config.setTranslation({
      startsWith: 'Začíná',
      contains: 'Obsahuje',
      notContains: 'Neobsahuje',
      endsWith: 'Končí',
      equals: 'Je rovno',
      notEquals: 'Není rovno',
      noFilter: 'Zrušit filtr',
      dayNames: ["neděle", "pondělí", "úterý", "středa", "čtvrtek", "pátek", "sobota"],
      dayNamesShort: ["ne", "po", "út", "st", "čt", "pá", "so"],
      dayNamesMin: ["NE", "PO", "ÚT", "ST", "ČT", "PÁ", "SO"],
      monthNames: ["leden", "únor", "březen", "duben", "květen", "červen", "červenec", "srpen", "září", "říjen", "listopad", "prosinec"],
      monthNamesShort: ["led", "úno", "bře", "dub", "kvě", "čer", "čec", "srp", "zář", "říj", "lis", "pro"],
      today: 'Dnes',
      clear: 'Vymazat',
      dateIs: 'Je rovno',
      dateIsNot: 'Není rovno',
      dateBefore: 'Datum je před',
      dateAfter: 'Datum není před'
    });

    this.optionsService.getApiVersion().subscribe(result => {
      this.apiVersion = result;
    });

    this.activeRoute.queryParamMap.subscribe(params => {
      //záznam zobrazení navigace z url
      let nav = params.get(this.navUtils.NAV);
      if (nav != void 0) this.navUtils.save(nav);

      //získání nastavení navigace z localstorage
      let savedDisplayNav = this.navUtils.display;
      this.displayNav = savedDisplayNav || savedDisplayNav == null;

    });
  }
}
