<p-blockUI [blocked]="blocked">
  <p-progressSpinner></p-progressSpinner>
</p-blockUI>
<p-confirmPopup key="{{CONFIRM_KEY}}"></p-confirmPopup>

<div class="p-grid p-nogutter edit-nacrt-form" *ngIf="_visible">
  <div class="p-col-12 nacrt-i-primary">
    <p><b>Úprava náčrtu</b></p>
  </div>
  <div class="p-col-12">
    <p *ngIf="featLength != null || featArea != null">
      <b>
        <span *ngIf="featArea != null">
          S = {{featArea | formatPlocha: 10000}}
        </span>
        <span *ngIf="featArea != null && featLength != null"> / o = {{featLength | formatDelka}}</span>
        <span *ngIf="featLength != null && featArea == null">
          l = {{featLength | formatDelka}}
        </span>
      </b>
    </p>
  </div>
  <div class="p-col-12">
    <app-nacrt-edit-form #editForm [nacrtProjects]="_nacrtProjects" [featureType]="featureGeometry" [inputNacrtData]="formData"></app-nacrt-edit-form>
  </div>
  <div class="p-col-6">
    <app-nacrt-priloha-edit [data]="_item" [newNacrt]="false"></app-nacrt-priloha-edit>
  </div>
  <div class="p-col-6 right">
    <span class="p-buttonset">
      <button pButton type="submit" label="Uložit" class="icon-btn"
              pTooltip="Uložit změny" tooltipPosition="bottom"
              [disabled]="!editForm.isValid"
              (click)="save()">
        <fa-icon [icon]="faCheck"></fa-icon>
      </button>
      <button pButton type="button" class="icon-btn hide-label-button"
              pTooltip="Zrušit" tooltipPosition="bottom"
              (click)="cancel($event)">
        <fa-icon [icon]="faTimes"></fa-icon>
      </button>
    </span>
  </div>
</div>
