<p-table [value]="_data" styleClass="pds-table-compact" [(selection)]="_selectedData" dataKey="id"
         (selectionChange)="onSelectionChanged()">
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 2.5rem; text-align: center;">
        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
      </th>
      <th style="width: 3.5rem; text-align: center;">Číslo</th>
      <th style="width: 4rem;"></th>
      <th style="">Popis</th>
      <th style="width:5.5rem;">Plocha</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-data>
    <tr>
      <td style="text-align: center;">
        <p-tableCheckbox [value]="data"></p-tableCheckbox>
      </td>
      <td style="text-align: center;">{{data.poradoveCislo}}</td>
      <td style="{{'background-clip: content-box;background-color:'+data.color}}"></td>
      <td>
        <input pInputText type="text" [(ngModel)]="data.popis" required>
      </td>
      <td style="text-align: right;">{{data.plocha | formatPlocha}}</td>
    </tr>
  </ng-template>
</p-table>
