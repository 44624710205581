import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { DotazDetailDto } from '../../../Dto/Dotazovac/DotazDetailDto';
import { DotazovacService } from '../../../Services/Dotazovac/dotazovac.service';
import { MessagesUtils } from '../../../Utils/Shared/messages.utils';

/**
 * Komponenta pro výběr kategorie dotazu ze stromu kategorií
 **/
@Component({
  selector: 'app-dotazovac-kategorie',
  templateUrl: './dotazovac-kategorie.component.html',
  styleUrls: ['./dotazovac-kategorie.component.css']
})
export class DotazovacKategorieComponent implements OnInit {

  constructor(private dotazovacService: DotazovacService,
    private messagesUtils: MessagesUtils) { }

  ngOnInit(): void {
    this._loadKategorieTree();
    this.dotazovacService.savedOrDeleted.subscribe(res => {
      if (res) this._loadKategorieTree();
    });
  }

  public kategorieTree: TreeNode<DotazDetailDto>[];
  public selectedKategorie: TreeNode;

  public readonly KATEGORIE_TOAST = "kategorie_toast";

  @Output() onChange: EventEmitter<string> = new EventEmitter<string>();

  // proměnné pro implementaci rozhraní ControlValueAccessor
  onModelChange: any = () => { };
  onModelTouched: any = () => { };
  disabled: boolean = false;

  /**
   * Název dotazu pro jeho zobrazení v hlavičce dialogu
   */
  @Input() nazevDotazu: string = null;

  /**
   * Přidá a vybere základní uzel jako možnost výběru všech uzlů pro filtr
   */
  @Input() addRootNode: boolean = true;

  @Input() set kategorie(value: string) {
    this.value = value;
  }

  private _value: string;

  get value() {
    return this._value;
  }

  set value(val) {
    if (val == void 0 || this._value != val) {
      if (val == void 0 || val == '') val = null; //pro případ, že uživatel smaže vše v inputu
      this._value = val;
      this._setKategorie();
    }
  }

  /**
  * Handler výběru kategorie
  * @param event
  **/
  public nodeKategorieSelect(event): void {
    this._value = event.node.key;
    this.onChange.emit(this.value);
  }

  /**
   * Načte strom kategorií
   **/
  private _loadKategorieTree(): void {
    this.dotazovacService.getDotazyCategoryTree().subscribe(res => {
      if (res.success) {
        if (this.addRootNode) {
          let root: TreeNode[] = [{ expanded: true, label: "Všechny kategorie", children: res.data }];
          this.kategorieTree = root;
        } else {
          this.kategorieTree = res.data;
        }
        this._setKategorie();
      } else {
        this.messagesUtils.showResponseMessage(this.KATEGORIE_TOAST, res);
      }
    });
  }

  /**
   * Vybere základní uzel v případě neexistence vabraného řetězce.
   * Pokud je vyraný řetezec kategorie, tak spustí vyhledávání a rozbalení cesty k vybranému uzlu
   **/
  private _setKategorie(): void {
    this.selectedKategorie = null;
    if (this.kategorieTree?.length > 0) {
      if (this.addRootNode) {
        let rootNode = this.kategorieTree[0];
        if (this.value == void 0) {//pokud není vybraná žádná kategorie, tak vyrat základní uzel
          this.selectedKategorie = rootNode;
        } else {
          this._colapseAll();
          this._setKategorieNode(rootNode);
          this._expandSelected();
        }
      } else {
        this._colapseAll();
        if (this.value != void 0) {
          this.kategorieTree.forEach(node => {
            this._setKategorieNode(node);
          });
          this._expandSelected();
        }
      }
    }
  }

  /**
   * Provede výběr uzlu podle řetezce kategorie
   * @param node
   */
  private _setKategorieNode(node: TreeNode): void {
    if (node.key == this.value) {
      this.selectedKategorie = node;
    } else {
      node?.children.forEach(childNode => {
        this._setKategorieNode(childNode);
      });
    }
  };

  /**
   * Sbalí všechny uzly
   * @param node
   */
  private _expandSelected(): void {
    let kategorieFiled = this.value.split("/");
    if (this.addRootNode) {
      this._expandNode(this.kategorieTree[0], kategorieFiled);
    } else {
      this._expandNode(null, kategorieFiled);
    }
  }

  /**
   * Rozbalí cestu k vybranému uzlu
   * @param node
   * @param kategorieFiled
   */
  private _expandNode(node: TreeNode, kategorieFiled: string[]): void {
    let nodeToExpand: TreeNode = node != void 0 ?
      node.children.find(x => x.label == kategorieFiled[0]) :
      this.kategorieTree.find(x => x.label == kategorieFiled[0]);

    if (nodeToExpand != void 0 && nodeToExpand.key != this.value) {//vybraný uzel nerozbalovat
      nodeToExpand.expanded = true;
      kategorieFiled.shift();
      this._expandNode(nodeToExpand, kategorieFiled);
    }
  }

  /**
   * Sbalí vše v základním uzlu
   **/
  private _colapseAll(): void {
    let kategorieToColapse: TreeNode<DotazDetailDto>[] = this.addRootNode ? this.kategorieTree[0]?.children : this.kategorieTree;
    kategorieToColapse?.forEach(node => {
      this._collapseRecursive(node);
    });
  }

  /**
   * Sbalí všechny uzly
   * @param node
   */
  private _collapseRecursive(node: TreeNode) {
    node.expanded = false;
    if (node.children) {
      node.children.forEach(childNode => {
        this._collapseRecursive(childNode);
      });
    }
  }
}
