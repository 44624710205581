import { VmlProstredekDetailDto } from '../Vyroba/VmlProstredekDetailDto';
import { VmlMaterialDetailDto } from '../Vyroba/VmlMaterialDetailDto';
import { VmlPremieNahradaDetailDto } from './VmlPremieNahradaDetailDto';
import { EtazVolbaDto } from '../Lhp/EtazVolbaDto';

export class VmlRadekDetailDto extends EtazVolbaDto {
  guid: string;
  cislo: number;
  zaznam: number;
  podvykonGuid: string;
  indexHoliny: number;
  druhTezbyGuid: string;
  lokalitaGuid: string;
  zpusobProvedeniGuid: string;
  plocha: number;
  sortimentGuid: string;
  sazeniceGuid: string;
  drevinaGuid: string;
  plRadekGuid: string;
  mjGuid: string;
  mnozstvi: number;
  dodavatelGuid: string;
  druhMzdyGuid: string;
  tarif: number;
  kc: number;
  poznamka: string;
  hmotnatost: number;
  zakazkaGuid: string;
  odpracDny: number;
  hodiny: number;
  normohodiny: number;
  pribVzd: number;
  skodlivyCinitel: string;
  akceGuid: string;
  datumAplikace: Date;
  mzd: boolean;
  vykonGuid: string;
  procPlneni: number;
  prostredky: VmlProstredekDetailDto[];
  materialy: VmlMaterialDetailDto[];
  premieNahrady: VmlPremieNahradaDetailDto[];

  // následící vlastnosti nejsou na serveru
  // výpočet množství * normohodiny
  pocetVj: number;
  // výpočet plocha * normohodiny
  pocetVjPlocha: number;
}
