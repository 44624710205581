import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faDatabase, faEllipsisV, faFile, faLock, faLockOpen, faPlayCircle, faTrash, faUndo } from '@fortawesome/free-solid-svg-icons';
import { saveAs } from "file-saver";
import { ConfirmationService, LazyLoadEvent, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { RoleEnum } from '../../../Dto/Core/RoleEnum';
import { DotazDataDto } from '../../../Dto/Dotazovac/DotazDataDto';
import { DotazDetailDto } from '../../../Dto/Dotazovac/DotazDetailDto';
import { DotazInfoDto } from '../../../Dto/Dotazovac/DotazInfoDto';
import { DotazListDto } from '../../../Dto/Dotazovac/DotazListDto';
import { ModelListDto } from '../../../Dto/Dotazovac/ModelListDto';
import { AuthService } from '../../../Services/Auth/auth.service';
import { DotazovacService } from '../../../Services/Dotazovac/dotazovac.service';
import { MessagesUtils } from '../../../Utils/Shared/messages.utils';
import { RoleUtils } from '../../../Utils/Shared/role.utils';

export interface IDatovyModel {
  nazev: string;
}

/**
 * Interface slovníku modelů filtrů
 **/
export class IFilterModels {
  datovyModel: string[];
  nazev: string;
  insLogin: string;
}

/**
 * Komponenta pro interakci s dotazova4em 
*/
@Component({
  selector: 'app-dotazovac',
  templateUrl: './dotazovac.component.html',
  styleUrls: ['./dotazovac.component.css']
})
export class DotazovacComponent implements OnInit {

  constructor
    (
      private dotazovacService: DotazovacService,
      private messageService: MessageService,
      private confirmationService: ConfirmationService,
      private roleUtils: RoleUtils,
      private authService: AuthService,
      private router: Router,
      private activatedRoute: ActivatedRoute,
      private messagesUtils: MessagesUtils
    ) {
  }

  @ViewChild('dotazyTable') dotazyTable: Table;

  public readonly DOTAZOVAC_TOAST = "dotazovac_toast";
  public readonly DOTAZOVAC_DELETE_CONFIRM = "dotazovac_delete_confirm";
  private readonly _MATCH_MODE_SUFIX = "MM";

  /**
   * Pole s počtem řádků tabulky na stránku
   */
  public readonly ROWS_PER_PAGE = [10, 25, 50];

  faLock = faLock;
  faLockOpen = faLockOpen;
  faUndo = faUndo;
  faTrash = faTrash;
  faEllipsisV = faEllipsisV;
  faDatabase = faDatabase;
  faFile = faFile;
  faPlayCircle = faPlayCircle;

  /*
   * Indikuje, zda je uživatel administrátor
  */
  get administrator(): boolean {
    return this._administrator;
  }

  /*
   * Indikuje, zda může uživatel editovat vybraný dotaz
  */
  get canEdit(): boolean {
    return this._canChange();
  }

  /*
   * Indikuje, zda může uživatel smazat vybraný dotaz
  */
  get canDelete(): boolean {
    return this._canChange();
  }

  /*
   * Indikuje, zda může uživatel odemknout vybraný dotaz
  */
  get canUnlock(): boolean {
    return this._canChange() && this.selectedDotaz.zamceno;
  }

  /*
   * Indikuje, zda může uživatel zamknout vybraný dotaz
  */
  get canLock(): boolean {
    return this._canChange() && !this.selectedDotaz.zamceno;
  }

  /**
   * Vybraná položka stromu kategorií. Aplikuje se globálně na filtr tabulky dotazů.
   */
  set kategorie(value: string) {
    this._kategorie = value;
    this.dotazyTable.filterGlobal(value, "equals");
  }

  get kategorie(): string {
    return this._kategorie;
  }

  /**
   * Slovník modelů filtrů
   */
  filterModels: IFilterModels = { datovyModel: [], nazev: null, insLogin: null };


  dotazy: DotazListDto[] = [];
  selectedDotaz: DotazListDto;
  selectedDotazDetail: DotazDetailDto;
  modelyOptions: ModelListDto[] = [];
  selectedDotazInfo: DotazInfoDto;
  dotazDataCols: any[];
  dotazDataRows: any[];
  dataTotalRecords: number;
  /**
   * EasyQuery JSOn pro editaci parametrů
   */
  paramsEQData: string;

  loadingData: boolean;
  block: boolean = false;
  displaySql: boolean = false;
  editParams: boolean = false;

  private _administrator: boolean = false;
  private _editor: boolean = false;
  private _author: boolean = false;
  private _kategorie: string;
  private _login: string;

  private _queryParamsActions: { [param: string]: Function } = {
    "insLogin": (value: string, matchMode: string) => { this.filterModels.insLogin = value; this.dotazyTable.filter(value, 'insLogin', matchMode); },
    "nazev": (value: string, matchMode: string) => { this.filterModels.nazev = value; this.dotazyTable.filter(value, 'nazev', matchMode); },
    "global": (value: string, matchMode: string) => {//globálně se filtrují kategorie
      if (this.kategorie == void 0) this.kategorie = value;
    },
    "datovyModel": (value: any, matchMode: string) => {//filtruje se pole
      if (this.filterModels?.datovyModel?.length == 0) {//zavádí se pouze při inicializaci, kdy nedošlo k žádnému výběru
        if (typeof (value) == "string") value = [value];//ošetří situaci, kdy je filtrována pouze jedna položka a v url je string. Potřebujeme pole.
        this.filterModels.datovyModel = value;
        this.dotazyTable.filter(this.filterModels?.datovyModel, 'datovyModel', 'in');
      }
    }
  };

  ngOnInit(): void {
    this._editor = this.roleUtils.checkRole([RoleEnum.DotazovacEditor]);
    this._administrator = this.roleUtils.checkRole([RoleEnum.DotazovacAdmin, RoleEnum.SuperAdmin]);
    this._loadModely();
    this._loadDotazy();
  }

  /**
  * Navigace na úvodní stránku
  */
  public back(): void {
    this.router.navigate(["/"]);
  }

  /**
   * Handler vybrání řádku
   * @param dto {DotazListDto}
   */
  onRowSelect(dto: DotazListDto): void {
    this._cleanData();
    if (this._login == void 0) {
      this.authService.info().subscribe(res => {
        this._login = res.login;
        this._author = dto.insLogin == res.login;
      });
    } else {
      this._author = dto.insLogin == this._login;
    }
  }

  /**
   * Handler odvybrání řádku
   * @param dto {DotazListDto} 
   */
  onRowUnselect(dto: DotazListDto): void {
    this._author = false;
    this._cleanData();
  }

  /**
   * Zobrazí sql vybraného dotazu
  */
  sql() {
    this.dotazovacService.getDotaz(this.selectedDotaz?.id).subscribe(res => {
      this._apiPostprocess(res.success, undefined, `Sql dotazu se nepovedlo zobrazit kvůli chybě:${res.messages[0]}`, () => {
        this.selectedDotazDetail = res.data;
        this.displaySql = true;
      });
    });
  }

  /**
   * Handler zavření dialogu s sql dotazem
  */
  hideSql() {
    this.selectedDotazDetail = undefined;
  }

  /**
   * Handler odemčení dotazu
   * @param event
   */
  unlock(event): void {
    this.block = true;
    this.dotazovacService.unlockDotaz(this.selectedDotaz.id).subscribe(res => {
      this._apiPostprocess(res, "Dotaz byl úspěšně odemčen.", "Dotaz se nepovedlo odemknout kvůli neznámé chybě.", () => {
        this.selectedDotaz.zamceno = !this.selectedDotaz.zamceno;
      });
    });
  }

  /**
   * Handler zamčení dotazu
   * @param event
   */
  lock(event): void {
    this.block = true;
    this.dotazovacService.lockDotaz(this.selectedDotaz.id).subscribe(res => {
      this._apiPostprocess(res, "Dotaz byl úspěšně zamčen.", "Dotaz se nepovedlo zamknout kvůli neznámé chybě.", () => {
        this.selectedDotaz.zamceno = !this.selectedDotaz.zamceno;
      });
    });
  }

  /**
   * Zobrazí popup s dotazem, jestli chce uživatel dotaz smazat
   * @param event
   */
  delete(event): void {
    this.confirmationService.confirm({
      target: event.currentTarget,
      key: this.DOTAZOVAC_DELETE_CONFIRM,
      message: 'Skutečně si přejete smazat vybraný dotaz?',
      icon: 'pi pi-exclamation-triangle',
      accept: this._deleteAccept.bind(this),
      acceptLabel: 'Smazat',
      acceptButtonStyleClass: 'p-button-danger',
      reject: () => { },
      rejectLabel: 'Zavřít'
    });
  }

  /**
   * Smaže dotaz, pokud to předtím uživatel potvrdil
  */
  private _deleteAccept(): void {
    this.block = true;
    this.dotazovacService.deleteDotaz(this.selectedDotaz.id).subscribe(res => {
      this._apiPostprocess(res, "Dotaz byl úspěšně smazán.", "Dotaz se nepovedlo smazat kvůli neznámé chybě.", () => {
        this.dotazy = this.dotazy.filter(x => x.id != this.selectedDotaz.id);
        this.selectedDotaz = undefined;
      });
    });
  }

  /**
   * Postprocess operací nad api
   * @param result {boolean} Výsledek vrácený api 
   * @param successMessage {string} Message toastu v případě úspěchu
   * @param errorMessage {string} Message toastu v případě neúspěchu
   * @param successAction {Function} Co se má provést v případě úspěchu
   */
  private _apiPostprocess(result: boolean, successMessage: string, errorMessage: string, successAction: Function): void {
    if (result) {
      if (successMessage != void 0) {
        this.messageService.add({
          key: this.DOTAZOVAC_TOAST,
          severity: 'success',
          summary: 'Úspěch',
          detail: successMessage,
          life: MessagesUtils.TOAST_LIFE
        });
      }
      successAction();
    }
    else {
      this.messageService.add({
        key: this.DOTAZOVAC_TOAST,
        severity: 'error',
        summary: 'Chyba',
        detail: errorMessage,
        life: MessagesUtils.TOAST_LIFE
      });
    }
    this.block = false;
  }

  /**
   * Načte dostupné dotazy dotazovače
  */
  private _loadDotazy(newDotazId: string = null): void {
    this.block = true;
    this.dotazovacService.dotazyList().subscribe(res => {
      if (res.success) {
        this.dotazy = res.data;
        if (newDotazId != void 0) this.selectedDotaz = this.dotazy.find(x => x.id == newDotazId);

        this.activatedRoute.queryParams.subscribe(res => { this._processUrlQueryParams(res) });
      }
      else {
        res.messages[0] = `Při načítání dotazů došlo k chybě: ${res.messages[0]}.`;
        this.messagesUtils.showResponseMessage(this.DOTAZOVAC_TOAST, res);
      }
    });
  }

  /**
   * Načte dostupné modely dotazů 
  */
  private _loadModely(): void {
    this.dotazovacService.listModels().subscribe(res => {
      if (res.success) {
        this.modelyOptions = res.data;
      }
      else {
        this.messagesUtils.showResponseMessage(this.DOTAZOVAC_TOAST, res);
      }
    });
  }

  /**
   * Indikace, že přihlášený uživatel má právo zasahovat do vybraného dotazu
  */
  private _canChange(): boolean {
    return this._administrator || (this._editor && this._author);
  }

  /**
   * Zpracuje query parametry 
   * @param params Parametry z url
   */
  private _processUrlQueryParams(params: { [name: string]: string }): void {
    if (params?.filters) {
      let filters = JSON.parse(params.filters);

      for (const [key, value] of Object.entries(filters)) {
        let action: Function = this._queryParamsActions[key];
        if (action != void 0) action(value, filters[key + this._MATCH_MODE_SUFIX]);
      }
    }

    this.block = false;
  }

  /**
   * Handler filtrování tabulky dotazů. Zapíše query paramatry do URL
   * @param event
   */
  public onDotazyTableFilter(event): void {
    let queryParamsResult: any = {};

    if (event?.filters != void 0) {
      Object.keys(event.filters)?.forEach(filter => {
        if (Array.isArray(this.filterModels[filter])) {
          if (this.filterModels[filter]?.length > 0) queryParamsResult[filter] = this.filterModels[filter];
        } else {
          this.filterModels[filter] = event.filters[filter]?.value ? event.filters[filter].value : null; //při null, undefined, NAN, "", 0 a false použít null
          if (this.filterModels[filter] != void 0) {
            queryParamsResult[filter] = this.filterModels[filter];
            queryParamsResult[filter + this._MATCH_MODE_SUFIX] = event?.filters[filter]?.matchMode;
          }
        }
      });
      if (Object.keys(queryParamsResult).length !== 0) queryParamsResult = { filters: JSON.stringify(queryParamsResult) };
    }

    this.router.navigate(["dotazovac"], { queryParams: queryParamsResult });
  }

  /**
   * Handler výběru kategorie
   * @param event
   **/
  public onKategorieChange(value: string): void {
    this.kategorie = value;
  }

  /**
   * Handler uložení nového dotazu
   **/
  public onSaveDotaz(dotaz: DotazDetailDto): void {
    if (this.selectedDotaz.id == dotaz.id) {
      this.dotazDataRows = [];
      this.selectedDotazInfo = null;
    }
    this._loadDotazy(dotaz?.id);
  }

  /**
  * Přejde na stránku s vybraným řádkem
  * */
  setPage(): void {
    var items = (this.dotazyTable?.filteredValue != void 0) ? this.dotazyTable.filteredValue : this.dotazy;

    if (items != void 0 && this.selectedDotaz != void 0) {
      let index = items.indexOf(this.selectedDotaz);

      if (index != -1) {
        this.dotazyTable.first = (Math.floor(index / this.dotazyTable.rows) * this.dotazyTable.rows);
        this.dotazyTable.firstChange.emit(this.dotazyTable.first);
      }
    }
  }

  /**
   * Handler seřazení tabulky
   * */
  onSort(): void {
    this.setPage();
  }

  /**
   * Spustí dotaz. Pokud obsahuje parametry, tak vyvolá dialog pro zadání parametrů
   **/
  public runDotaz(): void {
    this._cleanData();
    this.loadingData = true;
    if (this.selectedDotaz.isParametrized) {
      this.dotazovacService.getParametrizedQuery(this.selectedDotaz.id).subscribe(res => {
        if (res.success) {
          this.paramsEQData = res.data;
          this.editParams = true;
        } else {
          this.messagesUtils.showResponseMessage(this.DOTAZOVAC_TOAST, res);
          this.loadingData = false;
        }
      });
    } else {
      this._loadInfo();
    }
  }

  /**
   * Získá ze serveru metadata vybraného dotazu a provede jeho spuštění.
   **/
  private _loadInfo(): void {
    this.dotazovacService.getDotazInfo(this.selectedDotaz.id, this.paramsEQData).subscribe(res => {
      if (res.success) {
        this.selectedDotazInfo = res.data;
        if (this.dotazDataCols == void 0) this.dotazDataCols = res.data.columns.map(column => { return { field: column, header: column } });
        this.dotazovacService.getDotazData(this.selectedDotaz.id, res.data.cacheKey, 10, 1).subscribe(dataRes => {
          if (dataRes.success) {
            this._setData(dataRes.data);
          } else {
            this.messagesUtils.showResponseMessage(this.DOTAZOVAC_TOAST, dataRes);
            this.loadingData = false;
          }
        });
      } else {
        this.messagesUtils.showResponseMessage(this.DOTAZOVAC_TOAST, res);
        this.loadingData = false;
      }
    });
  }

  /**
   * Načte výsledek dotazu do tabulky 
   * @param dotazData
   */
  private _setData(dotazData: DotazDataDto): void {
    if (dotazData.data) {
      this.dotazDataRows = JSON.parse(dotazData.data);
      this.dataTotalRecords = dotazData.zaznamuCelkem;
    } else {
      this.messagesUtils.showResponseMessage(this.DOTAZOVAC_TOAST, { success: false, messages: ["Dotaz nevrátil žádný výsledek"] });
    }
    this.loadingData = false;
  }

  /**
   * Načte lazy data  
   * @param event
   */
  public loadDataRows(event: LazyLoadEvent): void {
    if (this.dotazDataRows != void 0) {
      this.loadingData = true;
      let page = event.first != 0 ? (event.first / event.rows) + 1 : 1;
      this.dotazovacService.getDotazData(this.selectedDotaz.id, this.selectedDotazInfo.cacheKey, event.rows, page).subscribe(dataRes => {
        if (dataRes.success) {
          this._setData(dataRes.data);
        } else {
          this.messagesUtils.showResponseMessage(this.DOTAZOVAC_TOAST, dataRes);
        }
        this.loadingData = false;
      });
    }
  }

  private _cleanData(): void {
    this.selectedDotazDetail = null;
    this.selectedDotazInfo = null;
    this.dotazDataCols = null;
    this.dotazDataRows = null;
    this.paramsEQData = null;
    this.editParams = false;
  }

  /**
   * Spuštění dotazu s parametry
   * @param data podmínky dotazu s parametry v json
   */
  public onSaveDotazParams(data: string): void {
    this.editParams = false;
    this.paramsEQData = data;
    this._loadInfo();
  }

  /**
   * Handler tlačítka exportu dotazu do excelu
   **/
  public async exportExcel(): Promise<void> {
    if (this.exportExcelEnabled)
      await this.dotazovacService.exportDotazAsXlsx(this.selectedDotazInfo.cacheKey).then(res => {
        let blob = new Blob([res], { type: 'pplication/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, this.selectedDotaz.nazev + ".xlsx");
      });
  }

  /**
   * True, pokud je export do excelu možný/povolen.
   */
  public get exportExcelEnabled(): boolean {
    return this.selectedDotazInfo?.cacheKey != void 0 && this.dotazDataRows != void 0 && this.dotazDataRows.length > 0;
  }
}
