import { Component, Input } from '@angular/core';
import { CiselnikyService } from 'src/app/Services/Shared/ciselniky.service';
import { CiselnikListDto } from 'src/app/Dto/Shared/CiselnikListDto';
import { PodCiselnikListDto } from '../../../Dto/Shared/PodCiselnikListDto';

/**
 * Komponenta pro zobrazení člověkem čitelného kódu podle GUIDu z číselníku.
 * Sama si obsluhuje CiselnikService, na vstupu tedy stačí zadat GUID a název číselníku.
 */
@Component({
  selector: 'app-ciselnik-kod',
  template: '<span pTooltip=\"{{popis}}\">{{content}}</span>',
  styles: []
})
export class CiselnikKodComponent {

  private _ciselnik: string = null;
  private _parentCiselnik: string = null;
  private _guid: string = null;

  protected _rawData: CiselnikListDto[] = [];

  content: string = "";
  popis: string = "";

  @Input()
  set ciselnik(value: string) {
    if (value != this._ciselnik) {
      this._ciselnik = value;
      this._rawData = [];

      this.ciselnikyService.getCislenik(this._ciselnik, null).subscribe(res => {
        this._rawData = res;
        this.updateKod();
      });
    }
  }

  //Pokud je vyplněný parentCiselnik, tak se bude hledat obsah jako předek pro ciselnik
  @Input()
  set parentCiselnik(value: string) {
    if (value != this._parentCiselnik) {
      this._parentCiselnik = value;
    }
  }

  @Input()
  set ciselnikData(value: CiselnikListDto[]) {
    this._rawData = value;
    this.updateKod();
  }

  @Input()
  set guid(value: string) {
    if (value?.toLowerCase() != this._guid?.toLowerCase()) {
      this._guid = value;
      this.updateKod();
    }
  }

  //Pokud je true, tak se popis zobrazí v obsahu za kódem
  private _showPopisIn: boolean = false;
  @Input()
  set showPopisIn(value: boolean) {
    if (value != this._showPopisIn) {
      this._showPopisIn = value;
      this.updateKod();
    }
  }

  constructor(
    private ciselnikyService: CiselnikyService
  ) { }

  private updateKod(): void {
    var match: CiselnikListDto;
    if (this._guid && this._rawData.length > 0 && (match = this._rawData.find(x => { return x.guid?.toLowerCase() == this._guid?.toLowerCase() }, this)) != undefined) {
      if (this._parentCiselnik != void 0) {
        this.ciselnikyService.getCislenik(this._parentCiselnik, null).subscribe(res => {
          var matchParent: CiselnikListDto;
          if ((match as PodCiselnikListDto).parentGuid && res.length > 0 && (matchParent = res.find(x => { return x.guid?.toLowerCase() == (match as PodCiselnikListDto).parentGuid?.toLowerCase() }, this)) != undefined) {
            if (this._showPopisIn)
              this.content = matchParent.kod + " - " + matchParent.popis
            else
              this.content = matchParent.kod;

            this.popis = matchParent.popis;
          }
        });
      } else {
        if (this._showPopisIn)
          this.content = match.kod + " - " + match.popis
        else
          this.content = match.kod;

        this.popis = match.popis;
      }
    }
    else {
      this.content = "";
      this.popis = "";
    }
  }
}
