<div *ngIf="visible" [ngStyle]="{'right':calculatedOffset}" class="snap-btn-container">
  <button pButton type="button" [ngClass]="{'switch-is-on': vertex}" style="margin-bottom: 0.5rem;"
          class="p-button-rounded p-button-secondary p-button-outlined icon-btn hide-label-button"
          [disabled]="!active" pTooltip="Přepnutí přichytávání k&nbsp;vrcholům"
          (click)="snapVertex()">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 24 24">
      <path d="M2,11H9.17C9.58,9.83 10.69,9 12,9C13.31,9 14.42,9.83 14.83,11H22V13H14.83C14.42,14.17 13.31,15 12,15C10.69,15 9.58,14.17 9.17,13H2V11Z" />
    </svg>
  </button>
  <button pButton type="button" [ngClass]="{'switch-is-on': edge}"
          class="p-button-rounded p-button-secondary p-button-outlined icon-btn hide-label-button"
          [disabled]="!active"
          (click)="snapEdge()" pTooltip="Přepnutí přichytávání k&nbsp;hranám">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 24 24">
      <path d="M4,9C5.31,9 6.42,9.83 6.83,11H17.17C17.58,9.83 18.69,9 20,9A3,3 0 0,1 23,12A3,3 0 0,1 20,15C18.69,15 17.58,14.17 17.17,13H6.83C6.42,14.17 5.31,15 4,15A3,3 0 0,1 1,12A3,3 0 0,1 4,9Z" />
    </svg>
  </button>
</div>
