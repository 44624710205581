import { Output, EventEmitter } from "@angular/core";
import { Injectable } from "@angular/core";
import { PostMessageData } from "../../Dto/Shared/PostMessageDataDto";

/**
 * Service pro předávání externích zpráv HTML messagingu.
**/
@Injectable({
  providedIn: 'root'
})
export class ExternalMessagingService {

  /**
   * Událost předávájící zprávu messagingu.
  **/
  @Output() ExternalMessage: EventEmitter<any> = new EventEmitter<any>();


  /**
   * Příznak, zda je již nastavený hadler příchozích zpráv.
  **/
  private _init: boolean = false;


  /**
   * Inicializace HTML messagingu.
  **/
  public init(): void {
    if (!this._init) {
      window.addEventListener('message', this._messageHandler.bind(this));
    }
  }


  /**
   * Handler přijatých HTML zpráv.
   * @param event {any} přijatá událost
   */
  private _messageHandler(event: any): void {
    this.externalMessage(event);
  }



  /**
   * Vypuštění události předávající zprávu HMLT messagingu.
   * @param message {any} Zpráva HMTL messagingu
   */
  externalMessage(message: any): void {
    this.ExternalMessage.emit(message);
  }


  /**
   * Odeslání zprávy pro parent window.
   * @param data {IPostMessageData} obsah zprávy
   */
  public sendMessage(data: PostMessageData): void {
    //TargetOrigin zde musí být '*' nebo URL cílové aplikace. Pokud je uvedena jiná URL, framework messagingu zprávu vůbec nevyšle.
    //Příjímající aplikace pak musí dělat kontrolu URL v obdržené zprávě.
    parent.postMessage(data, '*');
  }
}
