import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ReleaseNotesDto } from '../../Dto/Core/ReleaseNotesDto';

@Injectable({
  providedIn: 'root'
})
export class ReleaseNotesService {

  constructor(
    private http: HttpClient
  ) { }

  private apiReleaseNotes: string = "api/Options/ReleaseNotes";

  /**
   * Získá seznam číselníků pro editaci
   **/
  public getReleaseNotes(): Observable<ReleaseNotesDto[]> {
    return this.http.get<ReleaseNotesDto[]>(this.apiReleaseNotes);
  }
}
