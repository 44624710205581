import { Injectable, EventEmitter } from "@angular/core";

/**
 * Přetížení event emitteru s logovánm parametrů/názvu události.
**/
@Injectable({
  providedIn: 'root'
})
export class LoggingEventEmitter<T> extends EventEmitter<T> {
  constructor(private _name: string) {
    super();
  }

  /**
   * Vypuštění události.
   * @param value {T} hodnota předávaná událostí
   */
  public override emit(value?: T): void {
    console.debug('emit: ', this._name, value);
    super.emit(value);
  }
}
