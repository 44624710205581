import { Component, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { DruhVypoctu } from 'src/app/Utils/Skody/druh-vypoctu.utils';
import { LhpoService } from 'src/app/Services/Lhp/lhpo.service';
import { FormBuilder } from '@angular/forms';
import { VypocetDetailDto } from 'src/app/Dto/Skody/VypocetDetailDto';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { EtazInfoDto } from 'src/app/Dto/Skody/EtazInfoDto';
import { DetailZuComponent } from '../detail-zu/detail-zu.component';
import { CiselnikyService } from '../../../Services/Shared/ciselniky.service';



/**
 * Komponenta pro zadávání základních údajů škody.
**/
@Component({
  selector: 'ujmy-detail-zu',
  templateUrl: './ujmy-detail-zu.component.html',
  styleUrls: ['./ujmy-detail-zu.component.css']
})
export class UjmyDetailZuComponent extends DetailZuComponent implements OnInit {

  constructor(
    fb: FormBuilder,
    ciselnikService: CiselnikyService,
    lhpoService: LhpoService
  ) {
    super(fb, ciselnikService, lhpoService);
  }

  druhVypoctuList: SelectItem[] = [
    { label: DruhVypoctu.U7, value: DruhVypoctu.U7 },
    { label: DruhVypoctu.U8, value: DruhVypoctu.U8 },
    { label: DruhVypoctu.U10, value: DruhVypoctu.U10 }
  ];

  ngOnInit() {
    super.ngOnInit();

    this.data.valueChanges
      .pipe(
        debounceTime(600),
        distinctUntilChanged((prev, curr) => prev.guid === curr.guid && prev.typ === curr.typ
          && prev.lhc == curr.lhc
          && prev.jprl == curr.jprl && prev.etaz == curr.etaz && prev.drevina == curr.drevina),
        filter(data => this.validateVstupyProPrepocet(data))
      )
      .subscribe(() => {
        this.dataChanged.emit(this.data.value);
      });
  };


  /**
   * Naplnění modelu formuláře daty škody.
   * @param skoda
   */
  populateForm(skoda: VypocetDetailDto): void {
    super.populateForm(skoda);
  };


  /**
   * Vrátí seznam dřevin pro autocomplete. Pro U10 mohou do výpočtu vstupovat pouze dřeviny z LHP.
   * @param event
   */
  hledejDreviny(event): void {
    super.hledejDreviny(event);

    if (this.data.value.typ == DruhVypoctu.U10) {
      let drevinyEtaze = (this.data.get('etaz').value as EtazInfoDto).dreviny.map(x => x.zkratka);
      this.navrhovaneDreviny = this.navrhovaneDreviny.filter(x => drevinyEtaze.includes(x.kod));
    }
  };
}
