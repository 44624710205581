import { Injectable, Pipe, PipeTransform } from "@angular/core";

/**
 * Formát délkových rozměrů náčrtu. Přidá jednotky. Dle potřeby převede 'm' na 'km'. Hodnota je zaokrouhlena na 2 des. místa.
**/
@Pipe({
  name: 'formatDelka'
})
@Injectable({
  providedIn: 'root'
})
export class FormatDelkaPipe implements PipeTransform {
  transform(value: number, ...args: any[]): any {
    let units = "m";
    if (value > 1000) {
      value = value / 1000;
      units = "km";
    }

    let rounded = Math.round(value * 100) / 100;
    return rounded.toLocaleString('cs-CZ', { maximumFractionDigits: 2 }) + ' ' + units;
  }
}
