<app-block-ui [block]="block"></app-block-ui>

<p-toolbar styleClass="base-toolbar">
  <div class="p-toolbar-group-left">
    <h2 class="toolbar-label">Služební deník</h2>
    <i class="p-toolbar-separator"><fa-icon [icon]="faEllipsisV"></fa-icon></i>
    <div style="display: inline-block">
      <div class="p-inputgroup">
        <input type="text" pInputText placeholder="Hledat" [(ngModel)]="filter" style="width: 20em"/>
        <button pButton type="button" class="icon-btn hide-label-button" (click)="filter = undefined" [disabled]="filter == undefined"><fa-icon [icon]="faBackspace"></fa-icon></button>
      </div>
    </div>
  </div>
  <div class="p-toolbar-group-right">
    <button pButton type="button" label="Zpět" class="icon-btn" (click)="back()"><fa-icon [icon]="faUndo"></fa-icon></button>
  </div>
</p-toolbar>

<propla-table #pdTable [cols]="cols" [allItems]="items" [selectionMode]="'single'" [filterGlobal]="filter"
              (rowSelected)="onRowSelected($event)" (rowUnSelected)="onRowUnSelected($event)"></propla-table>

<ng-template #colDatum let-rowData let-col="column">
  {{rowData[col.field] | date:'d.M.yyyy'}}
</ng-template>

<app-provozni-denik-detail [itemGuid]="selectedGuid"></app-provozni-denik-detail>
