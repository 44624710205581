<p-card styleClass="map-info-panel-item">
  <button pButton type="button" icon="pi pi-times" class="p-button-rounded map-info-close-button" (click)="removeFromSelection()" pTooltip="Odebrat z výběru" tooltipPosition="left"></button>
  <div class="p-grid p-nogutter" style="margin-bottom:0.5rem">
    <div class="p-col">
      <div class="pes-i-primary">
        <b>{{_item.katuzeNazev}}</b>
      </div>
    </div>
  </div>
  <div class="p-grid p-nogutter">
    <div class="p-col-7 pes-i-secondary">
      <div class="pes-i-secondary">
        Číslo k.ú.: <b>{{_item.katuzeKod}}</b>
      </div>
      <div class="pes-i-secondary">
        <b><span pTooltip="Číslo parcely" tooltipPosition="bottom">{{_item.cisloPar}}<span *ngIf="_item.podlomeni != null">/{{_item.podlomeni}}</span></span></b>,
        <span pTooltip="Výměra parcely" tooltipPosition="bottom">{{_item.vymera | formatPlocha}}</span>
      </div>
      <div class="pes-i-secondary">
        <span pTooltip="Druh pozemku" tooltipPosition="bottom">{{_item.druhPozemkuKod}} - {{_item.druhPozemkuNazev}}</span>
      </div>
    </div>
    <div class="p-col-5 right">
      <span class="p-buttonset">
        <button pButton type="button" class="icon-btn hide-label-button"
                pTooltip="Přiblížit na parcelu" tooltipPosition="bottom"
                (click)="zoomTo()">
          <fa-icon [icon]="faSearch"></fa-icon>
        </button>
        <button pButton type="button" class="icon-btn hide-label-button"
                pTooltip="Kopírovat jako náčrt" tooltipPosition="bottom" [disabled]="disabledBtn"
                (click)="copy()">
          <fa-icon [icon]="faCopy"></fa-icon>
        </button>
        <button pButton type="button" class="icon-btn hide-label-button"
                pTooltip="Zobrazit pasport" tooltipPosition="bottom"
                (click)="goToPasport()">
          <fa-icon [icon]="faBookOpen"></fa-icon>
        </button>
      </span>
    </div>
  </div>
</p-card>
