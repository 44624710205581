import { Component, Input } from '@angular/core';
import { SyncService } from 'src/app/Services/Sync/sync.service';


/**
 * Komponenta detailu provozního deníku.
**/
@Component({
  selector: 'app-provozni-denik-detail',
  templateUrl: './provozni-denik-detail.component.html',
  styleUrls: ['./provozni-denik-detail.component.css']
})
export class ProvozniDenikDetailComponent {

  constructor(
    private syncService: SyncService
  ) { }

  _itemGuid: string;
  @Input() set itemGuid(value: string) {
    this._itemGuid = value;
    if (value != void 0) {
      this._loadData(value);
    }
    else {
      this.items = [];
    }
  }

  loading: boolean = false;
  items: any[];


  /**
   * Načtení detailu.
   * @param guid
   */
  _loadData(guid: string) {
    this.loading = true;
    this.syncService.get(guid).subscribe(resp => {
      this.items = resp.nacrt.akce;
      this.loading = false;
    });
  }
}
