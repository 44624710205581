<p-toast position="top-center" key="{{CISELNIKY_LIST_TOAST}}"></p-toast>
<app-block-ui [block]="block"></app-block-ui>

<p-toolbar styleClass="base-toolbar">
  <div class="p-toolbar-group-left">
    <h2 class="toolbar-label">Číselníky</h2>
    <i class="p-toolbar-separator"><fa-icon [icon]="faEllipsisV"></fa-icon></i>
    <app-ciselniky-opravneni-edit (onChange)="loadCiselniky()"></app-ciselniky-opravneni-edit>
    <span class="p-input-icon-left p-mr-2 p-inputgroup">
      <i class="pi pi-search"></i>
      <input pInputText type="text" [(ngModel)]="globalFilter" (input)="ciselnikyTable.filterGlobal($event.target.value, 'contains')" placeholder="Hledat" />
      <button pButton type="button" class="icon-btn hide-label-button" (click)="globalFilter = null; ciselnikyTable.filterGlobal('', 'contains');"
              [disabled]="globalFilter==null">
        <fa-icon [icon]="faBackspace"></fa-icon>
      </button>
    </span>
  </div>
  <div class="p-toolbar-group-right">
    <button pButton type="button" label="Zpět" class="icon-btn" (click)="back()"><fa-icon [icon]="faUndo"></fa-icon></button>
  </div>
</p-toolbar>

<p-table #ciselnikyTable [value]="items" selectionMode="single" (onRowSelect)="onRowSelected($event)" [paginator]="true" [rows]="10"
         [rowsPerPageOptions]="[10,20,30]" [globalFilterFields]="['nazev','popis']">
  <ng-template pTemplate="header">
    <tr>
      <th style="flex-basis: 30%" pSortableColumn="nazev">
        Název
        <p-sortIcon field="nazev"></p-sortIcon>
      </th>
      <th style="flex-basis: 30%" pSortableColumn="popis">
        Popis
        <p-sortIcon field="popis"></p-sortIcon>
      </th>
      <th style="flex-basis: 20%" pSortableColumn="schema">
        Schéma
        <p-sortIcon field="typ"></p-sortIcon>
      </th>
      <th style="flex-basis: 20%" pSortableColumn="typ">
        Typ číselníku
        <p-sortIcon field="typ"></p-sortIcon>
      </th>
    </tr>
    <tr>
      <th style="flex-basis: 30%"></th>
      <th style="flex-basis: 30%"></th>
      <th style="flex-basis: 20%"></th>
      <th style="flex-basis: 20%; padding: 0.4rem;">
        <p-columnFilter field="typ" matchMode="contains" [showMenu]="false">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <p-dropdown [options]="typyCiselniku" [ngModel]="value" (onChange)="filter($event.value)" placeholder="{{typyCiselniku[0].label}}"
                        [showClear]="true"></p-dropdown>
          </ng-template>
        </p-columnFilter>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-ciselnik>
    <tr [pSelectableRow]="ciselnik">
      <td style="flex-basis: 30%">{{ciselnik.nazev}}</td>
      <td style="flex-basis: 30%">{{ciselnik.popis}}</td>
      <td style="flex-basis: 20%">{{ciselnik.schema}}</td>
      <td style="flex-basis: 20%">{{getTypPopisByKod(ciselnik.typ)}}</td>
    </tr>
  </ng-template>
  <ng-template pTemplate="paginatorright" let-state>
    <div class="paginator-right">
      Zobrazeno {{state.totalRecords < 1 ? 0 : state.first + 1}} -
      {{
      state.totalRecords > 0 ? ((state.rows+ state.first) <= state.totalRecords ? (state.rows + state.first) :
      state.totalRecords) : 0
      }} z {{state.totalRecords ? state.totalRecords : 0}} záznamů
    </div>
  </ng-template>
</p-table>
