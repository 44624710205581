import { Component } from '@angular/core';
import { OffsetlInfoDto } from '../../../Dto/Mapa/OffsetlInfoDto';
import { MapInteractionService } from '../../../Services/Mapa/map-interaction.service';

/**
 * Komponenta přepínání kreslení polygonů a enkláv.
**/
@Component({
  selector: 'app-map-nacrt-switch-enclave',
  templateUrl: './map-nacrt-switch-enclave.component.html',
  styleUrls: ['./map-nacrt-switch-enclave.component.css']
})
export class MapNacrtSwitchEnclaveComponent {

  constructor(private mapInteractionService: MapInteractionService)
  {
    this.mapInteractionService.ControlOffset.subscribe(this._offsetHandler.bind(this));
    this.mapInteractionService.ShowEnclaveToggler.subscribe(this._toggleHandler.bind(this));
  }

  //zobrazení komponenty
  show: boolean = false;
  //hodnota přepínače
  checked: boolean = false;
  //offset tlačítka přepínače od pravého okraje obrazovky
  calculatedOffset: string = '2rem';

  /**
   * Handler zobrazení/skrytí info panelu mapy. Zajistí odsazení tlačítka.
   * @param value {OffsetlInfoDto} informace k odsazeni tlačítka
   */
  private _offsetHandler(value: OffsetlInfoDto): void {
    if (value.panel == 'infoPanel') {
      this.calculatedOffset = 'calc(2rem + ' + value.offset + ')';
    }
  }


  /**
   * Handler přepnutí viditelnosti komponenty.
   * @param value {boolean} true, pokud má být zobrazena
   */
  private _toggleHandler(value: boolean): void {
    this.show = value;
    if (!value) this.checked = false;
  }


  /**
   * Implementace přepnutí.
  **/
  toggle(): void {
    this.checked = !this.checked;
    this.mapInteractionService.drawEnclave(this.checked);
  }
}
