import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { faExternalLinkAlt, faHighlighter } from '@fortawesome/free-solid-svg-icons';
import Layer from 'ol/layer/Layer';
import { MessageService, TreeNode } from 'primeng/api';
import { ARozdeleniLesaDetailDto } from '../../../Dto/Lhp/ARozdeleniLesaDetailDto';
import { BzlDetailDto } from '../../../Dto/Lhp/BzlDetailDto';
import { DrevinDetailDto } from '../../../Dto/Lhp/DrevinDetailDto';
import { EtazDetailDto } from '../../../Dto/Lhp/EtazDetailDto';
import { JpDetailDto } from '../../../Dto/Lhp/JpDetailDto';
import { KnihaNavigationParams } from '../../../Dto/Lhp/KnihaNavigationParams';
import { LhcDetailDto } from '../../../Dto/Lhp/LhcDetailDto';
import { LhcListDto } from '../../../Dto/Lhp/LhcListDto';
import { OpDetailDto } from '../../../Dto/Lhp/OpDetailDto';
import { PorDetailDto } from '../../../Dto/Lhp/PorDetailDto';
import { PorTreeBzlJpOpDto } from '../../../Dto/Lhp/PorTreeBzlJpOpDto';
import { PorTreeDrevinaDto } from '../../../Dto/Lhp/PorTreeDrevinaDto';
import { PorTreeDto } from '../../../Dto/Lhp/PorTreeDto';
import { PorTreeEtazDto } from '../../../Dto/Lhp/PorTreeEtazDto';
import { PorTreePskDto } from '../../../Dto/Lhp/PorTreePskDto';
import { PskDetailDto } from '../../../Dto/Lhp/PskDetailDto';
import { MapItemDto } from '../../../Dto/Mapa/MapItemDto';
import { MapNavigationParams } from '../../../Dto/Mapa/MapNavigationParams';
import { AuthService } from '../../../Services/Auth/auth.service';
import { KnihaService } from '../../../Services/Lhp/kniha.service';
import { LhpoService } from '../../../Services/Lhp/lhpo.service';
import { LayersInteractionService } from '../../../Services/Mapa/layers-interaction.service';
import { MapInteractionService } from '../../../Services/Mapa/map-interaction.service';
import { NacrtySchvalitInteractionService } from '../../../Services/Nacrty/nacrty-schvalit-interaction.service';
import { ConstructMapLayerUtils } from '../../../Utils/Mapa/construnct-map-layer.utils';
import { ExtentUtils } from '../../../Utils/Mapa/extent.utils';
import { MessagesUtils } from '../../../Utils/Shared/messages.utils';
import { NavUtils } from '../../../Utils/Shared/nav.utils';

@Component({
  selector: 'app-kniha-detail',
  templateUrl: './kniha-detail.component.html',
  styleUrls: ['./kniha-detail.component.css'],
  providers: [MapInteractionService, NacrtySchvalitInteractionService, LayersInteractionService] //https://angular.io/guide/providers#limiting-provider-scope-with-components
})
export class KnihaDetailComponent implements AfterViewInit, OnInit {

  // ikony
  faExternalLinkAlt = faExternalLinkAlt;
  faHighlighter = faHighlighter;

  /**
   * GUID org. úrovně vybrané ve filtrovacím políčku v toolbaru
   */
  orgUrFilter: string;

  /**
   * Vybrané LHC
   */
  selectedLhc: LhcListDto;

  /**
   * Detail vybraného LHC
   */
  selectedLhcDetail: LhcDetailDto;

  /**
   * Seznam všech LHC dostupných pro uživatele nebo pro vybraný org. ur.
   */
  lhcList: LhcListDto[] = [];

  /**
   * Zobrazí dialog pro výběr porostu
   */
  selectPorDialog: boolean = false;

  /**
   * Vybrané hodnoty JPRL.
   * TODO je potřeba dořešit výběr v inputu
   */
  lhcKod: string;
  odd: string;
  oddPlocha: number;
  dil: string;
  dilPlocha: number;
  porost: string;
  porostPlocha: number;

  /**
   * Aktivní detail porostu
   */
  porDetail: PorDetailDto;

  /**
   * Aktivní detail porostní skupiny
   */
  pskDetail: PskDetailDto;

  /**
   * Aktivní detail etáže
   */
  etazDetail: EtazDetailDto;

  /**
   * Aktivní detail dřeviny
   */
  drevinaDetail: DrevinDetailDto;

  /**
   * Aktivní detail bezlesí
   */
  bzlDetail: BzlDetailDto;

  /**
  * Aktivní detail jiné plochy
  */
  jpDetail: JpDetailDto;

  /**
  * Aktivní detail ostatní plochy pozemku
  */
  opDetail: OpDetailDto;

  /**
   * Strom aktivního porostu
   */
  porTree: PorTreeDto;

  /**
   * Aktivní řádek v tabulce porostů
   */
  selectedPskTree: PorTreePskDto;

  /**
   * Aktivní řádek v tabulce etáží
   */
  selectedEtazTree: PorTreeEtazDto;

  /**
   * Aktivní řádek v tabulce dřevin
   */
  selectedDrevinaTree: PorTreeDrevinaDto;

  /**
  * Aktivní řádek v tabulce bezlesí
  */
  selectedBzlTree: PorTreeBzlJpOpDto;

  /**
  * Aktivní řádek v tabulce jiné plochy
  */
  selectedJpTree: PorTreeBzlJpOpDto;

  /**
  * Aktivní řádek v tabulce ostatní plochy
  */
  selectedOpTree: PorTreeBzlJpOpDto;

  /**
   * True, pokud došlo k nastavení z query parametru
   */
  private _initialized: boolean = false;

  /**
   * Seznam načtených stromu LHC
   */
  lhcTreeDict: Record<string, TreeNode> = {};

  /**
   * Blokování stránky
   */
  loading: boolean = false;

  /**
   * Blokování detailu knihy, pokud nejsou dostupná žádná data (nezobrazuje loader a neblokuje hlavičku stránky);.
   */
  noDataLoaded: boolean = false;

  /**
   * Index aktivní záložky v TabView střední části stránky
   */
  activeMiddleIndex: number;

  /**
   * Index aktivní záložky v TabView spodní části stránky
   */
  activeBottomIndex: number;

  /**
   * Popis hlavičky společného tabu pro psk, bzl, jp a op
   */
  rlTabHeader: string;

  /**
   * Popis tooltipu hlavičky společného tabu pro psk, bzl, jp a op
   */
  rlTabTooltip: string;

  /**
   * Zamčení společného tabu pro psk, bzl, jp a op
   */
  rlTabDisabled: boolean = false;

  /**
   * vybraný typ rozdělení lesa (PSK, BZL, JP nebo OP)
   */
  selectedRlType: string;

  /**
   * Vybraný tab ve spodní části z URL podle názvu typu JPRL
   */
  private _selectedBottomTab: string;

  /**
   * Slovník inicializačních funkcí pro zpracování typu z URL
   */
  private _initType = {
    'op': function (guid: string, self: KnihaDetailComponent) {
      if (self.opDetail == void 0 || self.opDetail?.guid != guid) {
        self.loading = true;
        self.knihaService.getOp(guid).subscribe(res => {
          self.opDetail = res;
          self._rlChanged(res);
          self._selectRows();
          if (self.porDetail == void 0 || self.porDetail?.guid == guid) self._loadPor(res.lhpGuids.porGuid); else self.loading = false;
        });
      } else {
        self._rlChanged(self.opDetail);
        self._selectRows();
      }
    },
    'jp': function (guid: string, self: KnihaDetailComponent) {
      if (self.jpDetail == void 0 || self.jpDetail?.guid != guid) {
        self.loading = true;
        self.knihaService.getJp(guid).subscribe(res => {
          self.jpDetail = res;
          self._rlChanged(res);
          self._selectRows();
          if (self.porDetail == void 0 || self.porDetail?.guid == guid) self._loadPor(res.lhpGuids.porGuid); else self.loading = false;
        });
      } else {
        self._rlChanged(self.jpDetail);
        self._selectRows();
      }
    },
    'bzl': function (guid: string, self: KnihaDetailComponent) {
      if (self.bzlDetail == void 0 || self.bzlDetail?.guid != guid) {
        self.loading = true;
        self.knihaService.getBzl(guid).subscribe(res => {
          self.bzlDetail = res;
          self._rlChanged(res);
          self._selectRows();
          if (self.porDetail == void 0 || self.porDetail?.guid == guid) self._loadPor(res.lhpGuids.porGuid); else self.loading = false;
        });
      } else {
        self._rlChanged(self.bzlDetail);
        self._selectRows();
      }
    },
    'psk': function (guid: string, self: KnihaDetailComponent) {
      self._loadPsk(guid);
    },
    'drevina': function (guid: string, self: KnihaDetailComponent) {
      let isDrevinaInEtaz = self.selectedEtazTree?.dreviny.some(x => x.guid == guid);
      if (self.drevinaDetail == void 0 || self.drevinaDetail?.guid != guid) {
        self.loading = true;
        self.knihaService.getDrevina(guid).subscribe(res => {
          self.drevinaDetail = res;

          if ((self.etazDetail == void 0 || self.etazDetail?.guid != res.etazGuid) && res.etazGuid != void 0)
            self._loadEtaz(res.etazGuid)
          else {
            self._selectRows();
            self.loading = false;
          }

        });
      } else if (isDrevinaInEtaz == false) {
        let etazGuid = self.selectedPskTree?.etaze?.find(x => x.dreviny?.some(y => y.guid == guid))?.guid
        if (etazGuid != void 0) self._loadEtaz(etazGuid);
      } else self._selectRows();
    },
    'etaz': function (guid: string, self: KnihaDetailComponent) {
      self._loadEtaz(guid);
    },
    'por': function (guid: string, self: KnihaDetailComponent) {
      if (self.porDetail?.guid != guid) self._loadPor(guid);
    },
    'lhc': function (guid: string, self: KnihaDetailComponent) {
      self._selectLhc(guid);
      self._initialized = true;
      self.loading = true;
      self.knihaService.getFromLhcToPorTree(guid)
        .subscribe(response => {
          if (self.lhcTreeDict[guid] == void 0) self.lhcTreeDict[guid] = response[0];

          let porGuid = self.lhcTreeDict[guid]?.children[0]?.children[0]?.children[0]?.data?.guid;
          if (porGuid != void 0) self._loadPor(porGuid, true);
        });
    }
  };

  /**
  * Slovník funkcí nastavení prvního, nebo posledního zvoleného řádku tabulek RL
  */
  private _setFirstOrLastRlRow = {};

  /**
   * Slovník funkcí pro inicializaci tabu TabView RL
   */
  private _initRlTab = {
    'op': function (self: KnihaDetailComponent) {
      self.selectedRlType = self.RL_TAB_OP;
      self._setBottomIndex();
      self.activeMiddleIndex = self.OP_TAB_INDEX;
      self._cleanPskDetails();
      self._setRlTab(self.RL_TAB_OP, self.RL_TAB_OP_TOOLTIP);
    },
    'jp': function (self: KnihaDetailComponent) {
      self.selectedRlType = self.RL_TAB_JP;
      self._setBottomIndex();
      self.activeMiddleIndex = self.JP_TAB_INDEX;
      self._cleanPskDetails();
      self._setRlTab(self.RL_TAB_JP, self.RL_TAB_JP_TOOLTIP);
    },
    'bzl': function (self: KnihaDetailComponent) {
      self.selectedRlType = self.RL_TAB_BZL;
      self._setBottomIndex();
      self.activeMiddleIndex = self.BZL_TAB_INDEX;
      self._cleanPskDetails();
      self._setRlTab(self.RL_TAB_BZL, self.RL_TAB_BZL_TOOLTIP);
    },
    'psk': function (self: KnihaDetailComponent) {
      self.selectedRlType = self.RL_TAB_PSK;
      self._setBottomIndex();
      self.activeMiddleIndex = self.PSK_TAB_INDEX;
    },
    'drevina': function (self: KnihaDetailComponent) {
      self.selectedRlType = self.RL_TAB_PSK;
      self._setBottomIndex();
      self.activeMiddleIndex = self.PSK_TAB_INDEX;
    },
    'etaz': function (self: KnihaDetailComponent) {
      self.selectedRlType = self.RL_TAB_PSK;
      self._setBottomIndex();
      self.activeMiddleIndex = self.PSK_TAB_INDEX;
    }
  };

  //konstanty indexu záložek ve střední části
  private readonly PSK_TAB_INDEX: number = 0;
  private readonly BZL_TAB_INDEX: number = 1;
  private readonly JP_TAB_INDEX: number = 2;
  private readonly OP_TAB_INDEX: number = 3;

  //asiciativní pole s konstantami indexu záložek ve spodní části
  private readonly BOTTOM_TAB_INDEXES: object = { lhc: 0, por: 1, psk: 2, bzl: 2, jp: 2, op: 2, etaz: 3, drevina: 4, lhe: 5 };

  //Popisy a tooltipy tabu RL ve spodní části TabView
  private readonly RL_TAB_PSK: string = "PSK";
  private readonly RL_TAB_PSK_TOOLTIP: string = "Porostní skupina";
  private readonly RL_TAB_BZL: string = "BZL";
  private readonly RL_TAB_BZL_TOOLTIP: string = "Bezlesí";
  private readonly RL_TAB_JP: string = "JP";
  private readonly RL_TAB_JP_TOOLTIP: string = "Jiná plocha";
  private readonly RL_TAB_OP: string = "OP";
  private readonly RL_TAB_OP_TOOLTIP: string = "Ostatní plocha";

  /**
   * Id elementu mapy.
  **/
  mapElementId: string = 'knihaMap';

  /**
   * Mapový objekt vybraného rozdělení lesa.
   */
  selectedMapItem: MapItemDto[] = [];

  /**
   * Poslední vybraný mapový objekt rozdělení lesa - slouží pro zapínání/vypínání zvýraznění v mapě.
   */
  _actualMapItem: MapItemDto[] = [];

  /**
   * Vrstvy, které mají být zobrazeny v mapě.
   */
  layers: Layer<any, any>[] = [];
  visibleLayers: string[] = [];

  /**
   * Uchovává aktuálně vybraný guid rozdělení lesa a jeho typ.
   */
  private _actualRlInfo: object = {};

  lheEnabled: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private knihaService: KnihaService,
    private lhpoService: LhpoService,
    private messageService: MessageService,
    private construnctLayerUtils: ConstructMapLayerUtils,
    private extentUtils: ExtentUtils,
    private navUtils: NavUtils,
    private mapInteractionService: MapInteractionService
  ) {

    this._setFirstOrLastRlRow[this.PSK_TAB_INDEX] = function (self: KnihaDetailComponent) {
      if (self.selectedPskTree == void 0) self.selectedPskTree = self.porTree?.psk[0];
      self._setRlTab(self.RL_TAB_PSK, self.RL_TAB_PSK_TOOLTIP);
      self.onPskSelect();
    }

    this._setFirstOrLastRlRow[this.BZL_TAB_INDEX] = function (self: KnihaDetailComponent) {
      if (self.selectedBzlTree == void 0) self.selectedBzlTree = self.porTree?.bzl[0];
      self.onBzlSelect();
    }

    this._setFirstOrLastRlRow[this.JP_TAB_INDEX] = function (self: KnihaDetailComponent) {
      if (self.selectedJpTree == void 0) self.selectedJpTree = self.porTree?.jp[0];
      self.onJpSelect();
    }

    this._setFirstOrLastRlRow[this.OP_TAB_INDEX] = function (self: KnihaDetailComponent) {
      if (self.selectedOpTree == void 0) self.selectedOpTree = self.porTree?.op[0];
      self.onOpSelect();
    }
  }

  public readonly TOAST_KEY: string = "kniha-detail-toast";

  ngOnInit(): void {
    this.authService.info().subscribe(res => {
      if (res != void 0) {
        this.lheEnabled = (res.roles.includes('LheAdmin') || res.roles.includes('LheEditor') || res.roles.includes('LheProhlizec'));
      }
      else {
        this.messageService.add({ severity: 'error', summary: 'Došlo k chybě při kontrole oprávnění LHE', key: this.TOAST_KEY });
      }
    });
  }

  ngAfterViewInit(): void {
    this.mapInteractionService.createMap(this.mapElementId);
    this._loadLhc();

    if (!this.selectPorDialog && this.lhcList != void 0 && this.lhcList.length > 0) this.loading = true;
  }

  /**
   * Načte LHC. Po načtení zpracuje výsledek.
   * */
  private _loadLhc(reload: boolean = false): void {
    this.lhpoService.lhcList(this.orgUrFilter).subscribe(res => {
      this.lhcList = res;

      if (!this._initialized && !reload)//zavádí se pouze jednou po inicializaci
        this.route.queryParamMap.subscribe(params => {
          let type = this._getTypeFromParam(params);
          if (type != void 0) {
            this._initialized = true;
            if (!params.has("tab")) this._navigateBottomTab(type);
            else {
              this._selectedBottomTab = params.get("tab");
              this._init(type, params.get(type))
            }
          }
        });

      //pokud nedošlo k nastavení z url, nebo pokud je vyžadovaný reload
      if (!this._initialized || reload) {
        //pokud je jedno LHC, tak nastavit
        if (this.lhcList.length == 1) return this.router.navigate(['kniha'], { queryParams: { lhc: this.lhcList[0].guid } });
        //pokud je víc LHC a nedošlo k nastavení z URL, tak otevřít dialog pro výběr porostu
        if (this.selectedRlType == void 0 && this.lhcList.length > 1) {
          this.loading = false;
          this.selectPorDialog = true;
        }
      }

      if (res == void 0 || res.length == 0) {
        this.loading = false;
        this.noDataLoaded = true;
        this.messageService.add({ severity: 'error', summary: 'Chyba', detail: "Není dostupné žádné LHC", key: this.TOAST_KEY, sticky: true });
      }
    });
  }

  /**
   * Handler výběru porostu. Promaže tabulky a přesměruje pro načtení porostu s výběrem prvního PSK.
   * @param value
   */
  onPorSelect(guid: string): void {
    this.selectedRlType = null;
    this._cleanNotPskSelections();
    this._cleanPskSelections();
    this.pskDetail = null;
    this.drevinaDetail = null;
    this.etazDetail = null;
    this.bzlDetail = null;
    this.jpDetail = null;
    this.opDetail = null;
    this.router.navigate(['kniha'], { queryParams: { "por": guid } });
  }

  /**
   * Načte obsah na základě vstupních parametrů. 
   * @param type
   * @param guid
   */
  private _init(type: string, guid: string): void {

    if (type != void 0) this._initialized = true;

    if (this._initRlTab[type] != void 0) this._initRlTab[type](this);

    if (this._initType[type] != void 0) this._initType[type](guid, this); else this.loading = false;

    this._setBottomIndex();
  }

  /**
   * Načte detail porostu a přesměruje na por, pokud je povoleno
   * @param guid
   * @param redirectToPor
   */
  private _loadPor(guid: string, redirectToPor: boolean = false): void {
    this.loading = true;
    this.knihaService.getPor(guid).subscribe(res => {
      this._setPorDetail(res);
      this._selectLhc(res.lhpGuids.lhcGuid);
      if (redirectToPor) return this.router.navigate(['kniha'], { queryParams: { por: res.guid } });
    })
  }


  /**
   * Naplnění detailu knihy po zvolení porostu
   * @param por
   */
  private _setPorDetail(por: PorDetailDto): void {
    this.porDetail = por;
    this.lhcKod = por.lhpGuids.lhcKod?.toString();
    this.odd = por.lhpGuids.odd?.toString();
    this.dil = por.lhpGuids.dil;
    this.porost = por.por;
    this.loading = false;

    this.loading = true;

    this.knihaService.getPorTree(por.guid).subscribe(res => {
      this.porTree = res;

      this._selectRows();

      //pokud LHC není načteno, tak načíst
      if (this.lhcTreeDict[this.porDetail.lhpGuids.lhcGuid] == void 0) {
        this.knihaService.getFromLhcToPorTree(this.porDetail.lhpGuids.lhcGuid, this.orgUrFilter)
          .subscribe(response => {
            this.lhcTreeDict[this.porDetail.lhpGuids.lhcGuid] = response[0];
            this._loadPlochy();
          });
      } else {
        this._loadPlochy();
      }
    }, (error) => {
      this.messageService.add({ severity: 'error', summary: 'Chyba', detail: "Chyba při získání porostu", key: this.TOAST_KEY, life: MessagesUtils.TOAST_LIFE })
      this.oddPlocha = null;
      this.dilPlocha = null;
      this.porostPlocha = null;
      this.porTree = null;
      this.loading = false;
    });
  }

  /**
   * Načte detail PSK  i nadřezených JPRL, pokud nejsou načteny
   * @param guid
   */
  private _loadPsk(guid: string): void {
    this._setRlTab(this.RL_TAB_PSK, this.RL_TAB_PSK_TOOLTIP);
    if (this.pskDetail == void 0 || this.pskDetail?.guid != guid) {
      this.loading = true;
      this.knihaService.getPsk(guid).subscribe(res => {
        this.pskDetail = res;
        this._rlChanged(res);
        //načte aktivní porost, pokud ještě není načtený
        if ((this.porDetail == void 0 || this.porDetail?.guid != res.porGuid) && res.porGuid != void 0) {
          this._loadPor(res.porGuid);
        }
        else
          this.loading = false;

        this._selectRows();

        this._selectLhc(res.lhpGuids.lhcGuid);

      });
    } else {
      this._rlChanged(this.pskDetail);
    }
  }

  /**
   * Načte detail etáže i nadřezených JPRL, pokud nejsou načteny
   **/
  private _loadEtaz(guid: string): void {
    if (this.etazDetail == void 0 || this.etazDetail?.guid != guid) {
      this.loading = true;
      this.knihaService.getEtaz(guid).subscribe(res => {
        this.etazDetail = res;

        if ((this.pskDetail == void 0 || this.pskDetail?.guid != res.pskGuid) && res.pskGuid != void 0) {
          this._loadPsk(res.pskGuid);
        }
        else {
          this._selectRows();
          this.loading = false;
        }
      });
    }
    else {
      if (this.etazDetail != void 0) {
        this.selectedEtazTree = this.selectedPskTree?.etaze.find(x => x.guid == this.etazDetail?.guid);
        this._rlChanged(this.pskDetail);
      }
    }
  }

  //reakce na výběr v tabulce
  onPskSelect(): void {
    this.selectedRlType = this.RL_TAB_PSK;
    this._navigateToEtazWithFirstDrevina(this.selectedPskTree.etaze[0]);
  }

  onPskUnselect(): void {
    this._cleanPskSelections();
    this.rlTabDisabled = true;
    this.selectedRlType = null;
    this.router.navigate(['kniha'], { queryParams: { por: this.porDetail?.guid } });
  }

  onEtazSelect(): void {
    this._navigateToEtazWithFirstDrevina(this.selectedEtazTree);
  }

  onEtazUnselect(): void {
    this.selectedDrevinaTree = null;
    this.router.navigate(['kniha'], { queryParams: { psk: this.selectedPskTree.guid } });
  }

  onDrevinaSelect(): void {
    this.router.navigate(['kniha'], { queryParams: { drevina: this.selectedDrevinaTree.guid } });
  }

  onDrevinaUnselect(): void {
    this.router.navigate(['kniha'], { queryParams: { etaz: this.selectedEtazTree.guid } });
  }

  onBzlSelect(): void {
    this.selectedRlType = this.RL_TAB_BZL;
    this.router.navigate(['kniha'], { queryParams: { bzl: this.selectedBzlTree.guid } });
  }

  onBzlUnselect(): void {
    this.selectedRlType = null;
    this.rlTabDisabled = true;
    this.router.navigate(['kniha'], { queryParams: { por: this.porDetail.guid } });
  }

  onJpSelect(): void {
    this.selectedRlType = this.RL_TAB_JP;
    this.router.navigate(['kniha'], { queryParams: { jp: this.selectedJpTree.guid } });
  }

  onJpUnselect(): void {
    this.selectedRlType = null;
    this.rlTabDisabled = true;
    this.router.navigate(['kniha'], { queryParams: { por: this.porDetail.guid } });
  }

  onOpSelect(): void {
    this.selectedRlType = this.RL_TAB_OP;
    this.router.navigate(['kniha'], { queryParams: { op: this.selectedOpTree.guid } });
  }

  onOpUnselect(): void {
    this.selectedRlType = null;
    this.rlTabDisabled = true;
    this.router.navigate(['kniha'], { queryParams: { por: this.porDetail.guid } });
  }



  /**
   * Vybere lhc, pokud už není vybraný a načte detail
   * @param guid
   */
  private _selectLhc(guid: string): void {
    if (this.selectedLhc != void 0 || this.selectedLhc?.guid != guid) {
      this.selectedLhc = this.lhcList.find(x => x.guid == guid);

      if (this.selectedLhcDetail?.guid != guid)
        this.knihaService.getLhc(guid).subscribe(res => {
          this.selectedLhcDetail = res;
        });
    }
  }

  /**
   * Načte plochy JPRL vybraného porostu a přesměruje na první psk, pokud je nastaveno 
   * @param porDetail
   */
  private _loadPlochy(): void {
    let oddNode = this.lhcTreeDict[this.porDetail.lhpGuids.lhcGuid].children.find(x => x.data?.guid == this.porDetail.lhpGuids.oddGuid);
    this.oddPlocha = oddNode.data?.plocha;
    let dilNode = oddNode.children.find(x => x.data?.guid == this.porDetail.lhpGuids.dilGuid);
    this.dilPlocha = dilNode.data?.plocha;
    this.porostPlocha = dilNode.children.find(x => x.data?.guid == this.porDetail.guid).data?.plocha;

    if (this.selectedRlType == void 0) this._navigateToEtazWithFirstDrevina(this.porTree.psk[0]?.etaze[0], true)
    else if (this.selectedEtazTree == void 0 && this.selectedPskTree != void 0) this._navigateToEtazWithFirstDrevina(this.selectedPskTree.etaze[0], true)

    this.loading = false;
  }

  /**
   * Hanlder změny myší v TabView
   * @param tabViewEvent
   */
  public selectJprlTab(tabViewEvent: any): void {
    this._navigateBottomTab(null);
    this._navigateBottomTab(Object.keys(this.BOTTOM_TAB_INDEXES).find(key => this.BOTTOM_TAB_INDEXES[key] === tabViewEvent.index));
  }

  /**
   * Přidá do URL nastavení spodního tabu
   * @param type
   */
  private _navigateBottomTab(type: string): void {
    this.router.navigate([], { relativeTo: this.route, queryParams: { tab: type }, queryParamsHandling: "merge" });
  }

  /**
   * Promaže všechny property RL, které souvisí s výběrem PSK
   * */
  private _cleanPskSelections(): void {
    this.selectedPskTree = null;
    this.selectedEtazTree = null;
    this.selectedDrevinaTree = null;
  }

  /**
   * Promaže všechny property RL, které souvisí s výběrem PSK i s detaily
   * */
  private _cleanPskDetails(): void {
    this._cleanPskSelections();
    this.pskDetail = null;
    this.etazDetail = null;
    this.drevinaDetail = null;
  }

  /**
   *  Promaže všechny property RL, které nesouvisí s výběrem PSK
   */
  private _cleanNotPskSelections(): void {
    this.selectedBzlTree = null;
    this.selectedJpTree = null;
    this.selectedOpTree = null;
  }


  /**
   * Nastaví hlavičku TabView pro psk, bzl, jp nebo op
   */
  private _setRlTab(header: string, tooltip: string): void {
    this.rlTabDisabled = false;
    this.rlTabHeader = header;
    this.rlTabTooltip = tooltip;
  }

  /**
   * Změna v org. ur. filtru
   * @param guid
   */
  public onOrgUrChanged(guid: string): void {
    if (this.orgUrFilter != guid) {
      if (guid != void 0) {
        this.selectedRlType = null;
        this._cleanNotPskSelections();
        this._cleanPskSelections();
      }

      this.orgUrFilter = guid;
      this._loadLhc(true);
    }
  }

  /**
   * Nastaví vybrané řádky
   **/
  private _selectRows(): void {
    switch (this.selectedRlType) {
      case this.RL_TAB_PSK:
        this.selectedPskTree = this.porTree?.psk?.find(x => x.guid == this.pskDetail?.guid);
        if (this.etazDetail != void 0) this.selectedEtazTree = this.selectedPskTree?.etaze.find(x => x.guid == this.etazDetail?.guid);
        if (this.drevinaDetail != void 0) this.selectedDrevinaTree = this.selectedEtazTree?.dreviny.find(x => x.guid == this.drevinaDetail?.guid);
        break;
      case this.RL_TAB_BZL:
        this.selectedBzlTree = this.porTree?.bzl?.find(x => x.guid == this.bzlDetail?.guid);
        break;
      case this.RL_TAB_JP:
        this.selectedJpTree = this.porTree?.jp?.find(x => x.guid == this.jpDetail?.guid);
        break;
      case this.RL_TAB_OP:
        this.selectedOpTree = this.porTree?.op?.find(x => x.guid == this.opDetail?.guid);
        break;
      default:
    }
  }

  /**
   * Handler změny viewtab prostřední části
   * @param event
   */
  public middleTabViewChange(event): void {
    this._setFirstOrLastRlRow[event.index](this);
  }


  /**
   * Handler změny výběru rozdělení lesa.
   * @param data {ARozdeleniLesaDetailDto} data nově vybraného rozdělení lesa
   */
  private _rlChanged(data: ARozdeleniLesaDetailDto): void {
    this._actualRlInfo[MapNavigationParams.id] = data?.guid;
    this._actualRlInfo[MapNavigationParams.typ] = 'Lhp';
    this._zoomToRl(data);
    this._showLayer(data?.lhcGuid);
  }


  /**
   * Nastavení mapy na výřez vybraného rozdělení lesa.
   * @param data {ARozdeleniLesaDetailDto} detail rozdeleni lesa
   */
  private _zoomToRl(data: ARozdeleniLesaDetailDto): void {
    this.mapInteractionService.zoomTo(this.extentUtils.addBuffer(this.extentUtils.getExtent(data?.wkt)));
    this._actualMapItem = [{
      modul: 'Lhp',
      id: data.guid,
      wkt: data.wkt
    }];
    this.selectedMapItem = this._actualMapItem;
    this.mapInteractionService.selectedItems(this.selectedMapItem);
  }


  /**
   * Handler změny velikosti elementu, ve kterém je vykreslena mapa.
  **/
  zoomToActualMapItem(): void {
    if (this._actualMapItem != void 0 && this._actualMapItem.length > 0) {
      this.mapInteractionService.zoomTo(this.extentUtils.addBuffer(this.extentUtils.getExtent(this._actualMapItem[0].wkt)));
    }
  }


  /**
   * Získání/zobrazení porostní mapy vybraného LHC.
   * @param lhcGuid {string} guid LHC
   */
  private _showLayer(lhcGuid: string): void {
    if (lhcGuid != void 0 && lhcGuid != '' && this.layers.length == 0) {
      this.lhpoService.getMapPorostni().subscribe(source => {
        this.construnctLayerUtils.constructLayers(source).then((layers) => {
          this.layers = layers;
          this.visibleLayers = source.filter(s => s.id.endsWith(lhcGuid)).map(s => s.id);
        });
      });
    }
    else {
      let mapLayerNames = this.layers.map((x: Layer<any, any>) => { return x.getProperties().name; });
      this.visibleLayers = [mapLayerNames.find(x => x.endsWith(lhcGuid))];
    }
  }


  /**
   * Přechod do mapy na vybrané rozdělení lesa.
  **/
  goToMapHandler(): void {
    this.router.navigate(['mapa'], { queryParams: this._actualRlInfo });
  }


  /**
   * Přepíná zvýraznění vybraného rozdělení lesa v mapě.
  **/
  highlight(): void {
    if (this.selectedMapItem.length > 0) {
      this.selectedMapItem = [];
    }
    else {
      this.selectedMapItem = this._actualMapItem;
    }
    this.mapInteractionService.selectedItems(this.selectedMapItem);
  }

  /**
   * funkce pro sumární řádek dřevin
   * @param key - název propety, pro výpočet sumy
   */
  public sumByKey(key: string): number {
    return this.selectedEtazTree?.dreviny.reduce((a: any, b: PorTreeDrevinaDto) => a + b[key], 0)
  }

  /**
   * Suma zásoby
   **/
  public get sumDrZas(): number {
    let sum = this.sumByKey('drZasCel');
    return this.selectedEtazTree?.etazPp != void 0 && this.selectedEtazTree?.etazPp != 0 ? sum / this.selectedEtazTree.etazPp : null;
  }

  /**
   * True, pokud je zobrazena navigace v horní části
   */
  get displayNav(): boolean {
    return this.navUtils.display;
  }

  /**
   * Nastaví spodní tab podle hodnoty z URL
   * */
  private _setBottomIndex(): void {
    this.activeBottomIndex = this.BOTTOM_TAB_INDEXES[this._selectedBottomTab];
  }

  /**
   * Naviguje na první dřevinu, pokud existuje.
   * Pokud neexistuje, (jedná se o holinu) přesměrovat na etáž s vybraným tabem,
   * nebo na tab etáže, pokud je vybraný tab na dřevině.
   * @param etaz - Vybraná etáž
   * @param porInit - True, pokud je voláno při inicializaci porostu.
   */
  private _navigateToEtazWithFirstDrevina(etaz: PorTreeEtazDto, porInit: boolean = false): void {
    if (etaz?.dreviny != void 0 && etaz.dreviny.length > 0) { //pokud existují dřeviny, přejít na první dřevinu a vybraný tab
      this.router.navigate(['kniha'], {
        queryParams: {
          drevina: etaz.dreviny[0].guid,
          tab: this._selectedBottomTab != void 0 && !porInit? this._selectedBottomTab : "etaz"
        }
      });
    } else {//pokud neexistují dřeviny, přejít na etáž a zvolený tab, nebo na tab etáže, pokud je tab prázdný, nebo obsahuje dřevinu
      this.router.navigate(['kniha'], {
        queryParams: {
          etaz: etaz.guid,
          tab: this._selectedBottomTab != void 0 && this._selectedBottomTab != "drevina" && !porInit ? this._selectedBottomTab : "etaz"
        }
      });
    }
  }

  /**
   * Vrátí vybraný typ JPRL z URL parametrů
   * @param params
   */
  private _getTypeFromParam(params: ParamMap): string {
    let knihaKeys = Object.keys(KnihaNavigationParams);
    return params.keys.filter(x => knihaKeys.includes(x))[0];
  }

  /**
   * Testuje platnost LHP/O vůči dnešnímu dni.
   * Smyslem metody je identifikovat plán s již ukončenou platností.
   * @param lhc
   */
  isInvalid(lhc: LhcListDto): boolean {
    return lhc != null && lhc.lhpDo < new Date();
  }

}
