import { Component, Input, ViewChild } from '@angular/core';
import { DLheListDto, LheListFilterDto } from '../../../Dto/Lhe/DLheListDto';
import { PorTreeEtazDto } from '../../../Dto/Lhp/PorTreeEtazDto';
import { PorTreePskDto } from '../../../Dto/Lhp/PorTreePskDto';
import { LheService } from '../../../Services/Lhe/lhe.service';
import { CiselnikKodComponent } from '../../Shared/ciselnik-kod/ciselnik-kod.component';

/*
 * Komponenta pro práci s Lesní hospodářskou evidencí v knize
*/
@Component({
  selector: 'app-kniha-detail-lhe',
  templateUrl: './kniha-detail-lhe.component.html',
  styleUrls: ['./kniha-detail-lhe.component.css']
})
export class KnihaDetailLheComponent {

  constructor
    (
      private lheService: LheService
    ) {
  }

  @ViewChild('ciselnikCinnosti') ciselnikCinnosti: CiselnikKodComponent;

  public pskGuid: string;

  @Input() set pskTree(value: PorTreePskDto) {
    this.selectedLheList = null;
    this.selectedLheListFilter = null;
    this.pskGuid = value?.guid;
    if (value != void 0) this._selectedPskTree = value;
    if (value != void 0 && !this._loadingLhe) this._loadLhe(value.guid);
  }

  get pskTree(): PorTreePskDto {
    return this._selectedPskTree;
  }

  private _selectedPskTree: PorTreePskDto;

  /* Seznam všech dostupných Lhe */
  lheList: DLheListDto[];
  /* Seznam současně dostupných Lhe podle filtru z tabulky filtrů*/
  lheCurrentList: DLheListDto[];
  /* Vybrané Lhe v tabulce Lhe */
  selectedLheList: DLheListDto;

  /* Seznam všech filtrů z tabulky filtrů */
  lheListFilter: LheListFilterDto[] = [];
  /* Současně vybraný filtr v tabulce filtrů */
  selectedLheListFilter: LheListFilterDto;

  private readonly _ALL_SELECTION_NAME: string = 'VŠE';
  private readonly _ETAZ_EXTENSION: string = ' (Et)';
  private readonly _INDEX_HOLIN_EXTENSION: string = ' (IH)';

  private _loadingLhe = false;

  /*
   * Vyfiltruje tabulku Lhe podle filtrační tabulky
  */
  onLheFilterSelect(): void {
    this.selectedLheList = null;
    if (this.selectedLheListFilter.value == this._ALL_SELECTION_NAME) {
      this.lheCurrentList = this.lheList;
    }
    else if (this.selectedLheListFilter.holinaGuid != void 0) {
      this.lheCurrentList = this.lheList.filter(x => x.holinaGuid == this.selectedLheListFilter.holinaGuid);
    }
    else if (this.selectedLheListFilter.etazGuid != void 0) {
      this.lheCurrentList = this.lheList.filter(x => x.etazGuid == this.selectedLheListFilter.etazGuid);
    }
  }

  /*
    * Odstraní filtrování tabulky
  */
  onLheFilterUnselect(): void {
    this.selectedLheList = null;
    this.lheCurrentList = this.lheList;
  }

  /**
 * Vypočítá sumu daného sloupce lhe tabulky
 * @param column
 */
  sumLheColumn(column: string): number {
    return this.lheCurrentList?.reduce((a: any, b: DLheListDto) => a + b[column], 0)
  }

  /**
   * Načte tabulku Lhe a tabulku filtrů
   * @param pskGuid
   * @param lheGuid
   */
  private _loadLhe(pskGuid: string, lheGuid: string = null): void {
    this._loadingLhe = true;
    this.lheService.lhe(pskGuid).subscribe(res => {
      if (res != void 0) {
        this.lheList = res;
        this.lheCurrentList = this.lheList;

        this.lheListFilter = [{ value: this._ALL_SELECTION_NAME, etazGuid: null, holinaGuid: null, indexHoliny: null }];
        let suitableHoliny: DLheListDto[] = this.lheList.filter(x => (x as DLheListDto).indexHoliny != void 0);
        let etaze: PorTreeEtazDto[] = this.pskTree?.etaze;

        this.lheListFilter = this.lheListFilter.concat(suitableHoliny.map(x => (
          {
            value: x.indexHoliny.toString() + this._INDEX_HOLIN_EXTENSION,
            indexHoliny: x.indexHoliny,
            holinaGuid: x.holinaGuid,
            etazGuid: null
          })));

        if (etaze != void 0)
          this.lheListFilter = this.lheListFilter.concat(etaze.map(x => (
            {
              value: x.etaz + this._ETAZ_EXTENSION,
              etazGuid: x.guid,
              holinaGuid: null,
              indexHoliny: null
            })));

        this.lheListFilter = [...new Map(this.lheListFilter.map(item => [item.value, item])).values()];

        if (this.selectedLheListFilter != void 0) {
          if (this.selectedLheListFilter.holinaGuid != void 0) {
            this.lheCurrentList = this.lheList.filter(x => x.holinaGuid == this.selectedLheListFilter.holinaGuid);
          }
          if (this.selectedLheListFilter.etazGuid != void 0) {
            this.lheCurrentList = this.lheList.filter(x => x.etazGuid == this.selectedLheListFilter.etazGuid);
          }
        }

        if (lheGuid != void 0) {
          this.selectedLheList = res.find(x => x.guid == lheGuid);
        }

        this._loadingLhe = false;
      }
    });
  }

  /**
   * Handler uložení editace LHE
   * @param guid {DLheListDto} data záznamu LHE.
   */
  public onLhcEditChanged(value: DLheListDto): void {
    if (value != void 0) {
      this.selectedLheList = this.lheList.find(x => x.guid == value.guid);
      if (this.selectedLheList == void 0) {//jedná se o nový záznam.
        this._loadLhe(this.pskTree.guid, value.guid);
      } else {
        Object.keys(this.selectedLheList).forEach(key => this.selectedLheList[key] = value[key]);
      }
    } else {
      this.selectedLheList = null;
      this._loadLhe(this.pskGuid);
    }
  }
}
