<p-messages [key]="AuthLoginMsg"></p-messages>
<div class="content">
  <p-card [style]="{width: '360px', margin: 'auto'}">
    <form class="validated-form-container" [formGroup]="loginForm" (ngSubmit)="login()">
        <p>
          <label for="userName">Uživatelské jméno</label><br />
          <input pInputText id="userName" name="userName" type="text" formControlName="userName" />
        </p>
        <p *ngIf="userName.invalid && (userName.dirty || userName.touched) || submitted && userName.errors" class="cross-validation-error-message alert alert-danger">
          Vyplňte prosím uživatelské jméno.
        </p>
        <p>
          <label for="password">Heslo</label><br />
          <input pInputText id="password" name="password" type="password" formControlName="password" />
        </p>
        <p *ngIf="password.invalid && (password.dirty || password.touched) || submitted && password.errors" class="cross-validation-error-message alert alert-danger">
          Vyplňte prosím heslo.
        </p>
        <p class="center">
          <button pButton type="submit" label="Přihlásit se" [ngClass]="'icon-btn'"><fa-icon [icon]="faCheck"></fa-icon></button>
        </p>

      <!-- dočasně schováno viz #26384
        <p class="center">
          <a href="/register">Registrace uživatele</a>
        </p>
      -->
    </form>
  </p-card>


  <!--<button pButton type="button" label="Go to policy resctected" (click)="goToPolicyRestected()"></button>
  <button pButton type="button" label="Go to role resctected" (click)="goToRoleRestected()"></button>
  <button pButton type="button" label="Go to public" (click)="goToPublic()"></button>-->
</div>
<app-block-ui [block]="loading"></app-block-ui>
