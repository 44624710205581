<form>
  <app-block-ui [block]="blockedPrepocet" [target]="krokyU10" [small]="true"></app-block-ui>
  <p-panel #krokyU10 header="Kroky výpočtu" [style]="{'margin-bottom': '1em'}"
           [toggleable]="true" [collapsed]="true" toggler="header">
    <!-- Tady je použité vlastní grid pro 9 sloupců - je založen na principu FlexGridu Primeng. -->
    <div class="p-grid" style="font-size:12px;">
      <div class="p-col-10">
        <div class="p-grid p-align-center">
          <div class="p-9-col-1">
            <label>
              Plocha výpočtu (m<sup>2</sup>)
              <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{omezeniTezbyDto?.plochaProVypocet}}</span>
            </label>
          </div>
          <div class="p-9-col-1">
            <label>
              P0
              <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{omezeniTezbyDto?.koeficienty?.p0}}</span>
            </label>
          </div>
          <div class="p-9-col-1">
            <label>
              P1
              <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{omezeniTezbyDto?.koeficienty?.p1}}</span>
            </label>
          </div>
          <div class="p-9-col-1">
            <label>
              P2
              <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{omezeniTezbyDto?.koeficienty?.p2}}</span>
            </label>
          </div>
          <div class="p-9-col-1">
            <label>
              P3
              <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{omezeniTezbyDto?.koeficienty?.p3}}</span>
            </label>
          </div>
          <div class="p-9-col-1">
            <label>
              P4
              <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{omezeniTezbyDto?.koeficienty?.p4}}</span>
            </label>
          </div>
          <div class="p-9-col-1">
            <label>
              THLPa
              <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{omezeniTezbyDto?.thlpa}}</span>
            </label>
          </div>
          <div class="p-9-col-1">
            <label>
              HLPa
              <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{omezeniTezbyDto?.hlpa}}</span>
            </label>
          </div>
        </div>

        <div class="p-grid p-align-center">
          <div class="p-9-col-1">
            <label>
              Celk. zásoba (m<sup>3</sup>)
              <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{omezeniTezbyDto?.drZasCel}}</span>
            </label>
          </div>
          <div class="p-9-col-1">
            <label>
              Etát TO (LHP m<sup>3</sup>)
              <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{omezeniTezbyDto?.etatToLhp}}</span>
            </label>
          </div>
          <div class="p-9-col-1">
            <label>
              Těžební procento
              <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{omezeniTezbyDto?.tezProc}}</span>
            </label>
          </div>
          <div class="p-9-col-1">
            <label>
              TO těž. proc. (m<sup>3</sup>)
              <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{omezeniTezbyDto?.etatToTezProc}}</span>
            </label>
          </div>
          <div class="p-9-col-1">
            <label>
              Etát TV (LHP m<sup>3</sup>)
              <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{omezeniTezbyDto?.etatTvLhp}}</span>
            </label>
          </div>
          <div class="p-9-col-1">
            <label>
              Probírkové procento
              <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{omezeniTezbyDto?.probProc}}</span>
            </label>
          </div>
          <div class="p-9-col-1">
            <label>
              TV prob. proc (m<sup>3</sup>)
              <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{omezeniTezbyDto?.etatTvProbProc}}</span>
            </label>
          </div>
          <div class="p-9-col-1">
            <label>
              Rozdíl (m<sup>3</sup>)
              <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{omezeniTezbyDto?.rozdilZaok}}</span>
            </label>
          </div>
          <div class="p-9-col-1">
            <label>
              PRIBOR (%)
              <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{omezeniTezbyDto?.pribor}}</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </p-panel>
</form>
