import { Component, Input, OnInit } from '@angular/core';
import { faTable } from '@fortawesome/free-solid-svg-icons';
import { SortEvent, SortMeta } from 'primeng/api';
import { ObjectUtils } from 'primeng/utils';
import { LhpMapItemDto } from '../../../Dto/Lhp/LhpMapItemDto';
import { TableUtils } from '../../../Utils/Shared/table.utils';

/**
 * Komponenta pro zobrazení vybraných JPRL v gridu
 **/
@Component({
  selector: 'app-map-info-lhp-list',
  templateUrl: './map-info-lhp-list.component.html',
  styleUrls: ['./map-info-lhp-list.component.css']
})
export class MapInfoLhpListComponent implements OnInit {

  displayDialog: boolean = false;

  faTable = faTable;

  lhpItems: LhpMapItemDto[];
  @Input() set items(value: LhpMapItemDto[]) {
    this.lhpItems = value != void 0 ? [...value] : [];
  };

  cols: any[];

  constructor(private tableUtils: TableUtils) { }

  ngOnInit(): void {
    this.cols = [
      { field: 'lhcKod', header: 'LHC' },
      { field: 'odd', header: 'Oddělení' },
      { field: 'dil', header: 'Dílec' },
      { field: 'por', header: 'Porost' },
      { field: 'pskBzlJpOp', header: 'PSK/ BZL/ JP/ OP', title: "Porostní skupina/ Bezlesí/ Jiný pozemek/ Ostatní pozemek" },
      { field: 'plocha', header: 'Plocha' }
    ];
  }

  /**
   * Vrátí obsah řádku
   * @param field
   * @param row
   */
  public rowContent(field: string, row: LhpMapItemDto): any {
    return field != "pskBzlJpOp" ? row[field] : row.psk != void 0 ? row.psk : row.bzlJpOp;
  }

  get sumaPloch(): number {
    let result = this.lhpItems?.reduce(function (accumulator, item) {
      return accumulator + item.plocha;
    }, 0);

    return Math.round(result * 100) / 100;
  }

  /**
   * Metoda pro multi řazení tabulky včetně sloupce PSK/BZL/JP/OP
   * @param event
   */
  public jprlSort(event: SortEvent) {
    event.data.sort((data1: LhpMapItemDto, data2: LhpMapItemDto) => {
      return this._multisortField(data1, data2, event.multiSortMeta);
    });
  }

  /**
   * Metoda pro multi řazení dvou řádků
   * @param data1 - data prvního řádku
   * @param data2 - data druhého rádku
   * @param multiSortMeta - meta data o řazených sloupcích
   * @param index - index sloupců začínající nulou
   */
  private _multisortField(data1: LhpMapItemDto, data2: LhpMapItemDto, multiSortMeta: SortMeta[], index: number = 0) {
    let value1 = ObjectUtils.resolveFieldData(data1, multiSortMeta[index].field);
    let value2 = ObjectUtils.resolveFieldData(data2, multiSortMeta[index].field);
    let result = null;

    if (multiSortMeta[index].field == "pskBzlJpOp") {
      value1 = data1.pskNorm != void 0 ? data1.pskNorm : data1.bzlJpOp.toString();
      value2 = data2.pskNorm != void 0 ? data2.pskNorm : data2.bzlJpOp.toString();
    }

    if (value1 == null && value2 != null)
      result = -1;
    else if (value1 != null && value2 == null)
      result = 1;
    else if (value1 == null && value2 == null)
      result = 0;
    else if (typeof value1 == 'string' || value1 instanceof String) {
      if (value1.localeCompare && (value1 != value2)) {
        return (multiSortMeta[index].order * value1.localeCompare(value2));
      }
    }
    else {
      result = (value1 < value2) ? -1 : 1;
    }
    if (value1 == value2) {
      return (multiSortMeta.length - 1) > (index) ? (this._multisortField(data1, data2, multiSortMeta, index + 1)) : 0;
    }
    return (multiSortMeta[index].order * result);
  }

  /**
   * Spustí export do XLSX
   **/
  public exportExcel(): void {
    let data = this.lhpItems.map(item => {
      let result = {};
      this.cols.forEach(col => {
        result[col.header] = this.rowContent(col.field, item);
      }, this);
      return result;
    });

    this.tableUtils.exportExcel(data, "jprl");
  }
}
