import { AfterViewInit, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { faBackspace, faEllipsisV, faPaperclip, faUndo, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { RoleEnum } from '../../../Dto/Core/RoleEnum';
import { NacrtMapItemDto } from '../../../Dto/Nacrty/NacrtMapItemDto';
import { NacrtProjektDto } from '../../../Dto/Nacrty/NacrtProjektDto';
import { MapInteractionService } from '../../../Services/Mapa/map-interaction.service';
import { NacrtySchvalitInteractionService } from '../../../Services/Nacrty/nacrty-schvalit-interaction.service';
import { NacrtyService } from '../../../Services/Nacrty/nacrty.service';
import { MessagesUtils } from '../../../Utils/Shared/messages.utils';
import { RoleUtils } from '../../../Utils/Shared/role.utils';
import { CanDeactivateComponent } from '../../Shared/can-deactivate/can-deactivate.component';
import { NacrtEditComponent } from '../nacrt-edit/nacrt-edit.component';

export interface IProjekt { projekt: string }
export interface ITypGeometrie { typ: string }

@Component({
  selector: 'app-nacrty-list',
  templateUrl: './nacrty-list.component.html',
  styleUrls: ['./nacrty-list.component.css'],
  providers: [MapInteractionService, NacrtySchvalitInteractionService]
})
export class NacrtyListComponent implements OnInit, AfterViewInit, CanDeactivateComponent {

  @ViewChild('nacrtyTable') nacrtyTable: Table;
  @ViewChild('nacrtEdit') nacrtEdit: NacrtEditComponent;

  constructor(
    private nacrtyService: NacrtyService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private mapInteractionService: MapInteractionService,
    private messageUtils: MessagesUtils,
    private roleUtils: RoleUtils)
  { }

  ngAfterViewInit(): void {
    this.block = !this.dataLoaded;
  }

  ngOnInit(): void {
    this._fillNacrtyTable();
    this.nacrtyService.getProjekty().subscribe(resp => this.projektyAll = resp.data);
  }


  readonly CONFIRM_KEY: string = 'nacrty-list-confirm';
  readonly TOAST_KEY: string = 'nacrty-list-toast';
  nacrty: NacrtMapItemDto[] = [];
  selectedNacrty: NacrtMapItemDto[] = [];
  projektyAll: NacrtProjektDto[] = [];

  projekty: IProjekt[] = [];
  selectedProjekty: IProjekt[] = [];

  typyGeometrii: ITypGeometrie[] = [];
  selectedTypyGeometrii: ITypGeometrie[] = [];


  block: boolean = false;
  dataLoaded: boolean = false;
  viewVisible: boolean = false;
  selectionMode: string = this.canEdit ? 'multi' : 'single';
  itemToView: NacrtMapItemDto = null;

  faPaperclip = faPaperclip;
  faEllipsisV = faEllipsisV;
  faUndo = faUndo;
  faBackspace = faBackspace;
  faTrash = faTrash;

  private _lastSelectedRow: NacrtMapItemDto = null;

  /**
   * Výběr řádku
   * @param event
   */
  onRowSelect(event) {
    if (this.canEdit) {
      if (!this.nacrtEdit.canDeactivate()) {
        this._confirmDiscardNacrtChanges(this._discardEditNacrt);
      }
      else {
        this._discardEditNacrt();
        this._edit();
      }
    }
    else {
      this.itemToView = event.data;
      this.viewVisible = true;
      this.selectedNacrty = [this.itemToView];
    }
  }


  /**
   * Odvybrání řádku
   * @param event
   */
  onRowUnselect(event) {
    if (this.canEdit) {
      if (!this.nacrtEdit.canDeactivate()) {
        this._confirmDiscardNacrtChanges(this._discardEditNacrt);
      }
      else {
        this._discardEditNacrt();
        this._edit();
      }
    }
    else {
      this.itemToView = null;
      this.viewVisible = false;
    }
  }


  /**
   * Zrušení provedených změn vybraného náčrtu.
  **/
  private _discardEditNacrt(): void {
    this._lastSelectedRow = null;
    this.mapInteractionService.discardChanges(null);
  }
    

  /**
   * Vynutí si potvrzení pro smazání změn.
   * @param acceptClb {Function} callback funkce po potvrzení zrušení provedených změn.
   */
  private _confirmDiscardNacrtChanges(acceptClb: Function): void {
    this.confirmationService.confirm({
      key: this.CONFIRM_KEY,
      message: 'Opravdu si přejete zahodit provedené změny? Změny nebudou uloženy.',
      icon: 'pi pi-exclamation-triangle',
      accept: acceptClb.bind(this),
      acceptLabel: 'Zahodit změny',
      acceptButtonStyleClass: 'p-button-danger',
      reject: () => {
        //wokraround programové změny výběru řádků - jinak to prostě (u multiselectu) nefunguje
        this.selectedNacrty = [];
        window.setTimeout(function (that) {
          that.selectedNacrty = [that._lastSelectedRow]
        }, 0, this);
      },
      rejectLabel: 'Pokračovat v práci'
    });
  }


  /**
   * Naplní tabulku náčrtů
  */
  private _fillNacrtyTable(): void {

    this.block = true;
    this.nacrtyService.getAll().subscribe(res => {
      if (res.success) {
        this.nacrty = res.data;
        this.projekty = this._getProjects(this.nacrty);
        this.typyGeometrii = this._getGeometryTypes(this.nacrty);
      }
      else {
        this.messageService.add({ severity: 'error', summary: 'Chyba', key: this.TOAST_KEY, detail: res.messages[0] });
      }

      this.dataLoaded = true;
      this.block = false;
    });
  }


  /**
   * Převezme projekty z dat náčrtů
   * @param nacrty
   */
  private _getProjects(nacrty: NacrtMapItemDto[]): IProjekt[] {
    var distinctProjectsIds = Array.from(new Set(nacrty.map((item: NacrtMapItemDto) => item.projektGuid)));
    return distinctProjectsIds.map(id => { return { projekt: nacrty.find(x => x.projektGuid == id).projekt } });
  }

  /**
   * Převezme geometrie z dat náčrtů
   * @param nacrty
   */
  private _getGeometryTypes(nacrty: NacrtMapItemDto[]): ITypGeometrie[] {
    var distinctGeometries = Array.from(new Set(nacrty.map((item: NacrtMapItemDto) => item.geometryType)));
    return distinctGeometries.map(geometry => { return { typ: geometry } });
  }

  /**
   * Navigace na úvodní stránku
   */
  public back(): void {
    this.router.navigate(["/"]);
  }


  /**
   * Aktualizace řádku vybraného náčrtu.
   * @param dto {NacrtMapItemDto} aktualizovaná data vybraného náčrtu
   */
  updateRow(dto: NacrtMapItemDto): void {
    this.selectedNacrty[0] = dto;
    let i = this.nacrty.findIndex(n => n.id == dto.id);
    this.nacrty.splice(i, 1, dto);
  }


  /**
   * True, pokud editovaný náčrt nemá provedené změny (formulář/příloha).
  **/
  canDeactivate(): boolean {
    return this.nacrtEdit == void 0 || this.nacrtEdit.canDeactivate();
  }


  /**
   * Zobrazení upozornění o neuložených změnách v případě navigace na jinou URL.
   * @param $event
   */
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (!this.canDeactivate()) {
      $event.returnValue = true;
    }
  }


  /**
   * Zobrazení editačního formuláře vybraného náčrtu.
  **/
  private _edit(): void {
    if (this.selectedNacrty.length != undefined && this.selectedNacrty.length == 1) {
      this._lastSelectedRow = this.selectedNacrty.slice()[0];
      this.mapInteractionService.editFeature(this.selectedNacrty[0]);
    }
  }


  /**
   * Smazání vybraných náčrtů.
  **/
  delete(): void {
    if (this.canDelete) {
      this.confirmationService.confirm({
        key: this.CONFIRM_KEY,
        message: 'Opravdu si přejete smazat vybrané náčrty?',
        icon: 'pi pi-exclamation-triangle',
        accept: this._deleteAcceptClb.bind(this),
        acceptLabel: 'Smazat',
        acceptButtonStyleClass: 'p-button-danger',
        reject: () => { },
        rejectLabel: 'Zrušit'
      });
    }
  }


  /**
   * Accept callback potvrzení smazání náčrtů.
  **/
  private _deleteAcceptClb(): void {
    let idToDel = this.selectedNacrty.map(x => x.id);
    this.nacrtyService.deleteSelected(idToDel).subscribe(resp => {
      if (resp.success) {
        this._postDelete(idToDel);
      }
      this.messageUtils.showResponseMessage(this.TOAST_KEY, resp);
    });
  }


  /**
   * Odebrání smazaných náčrtů z gridu a vyčistění vybraných záznamů.
   * @param deleted {string[]} seznam id smazaných náčrtů
   */
  private _postDelete(deleted: string[]): void {
    this.nacrty = this.nacrty.filter(x => !deleted.includes(x.id)).slice();
    this.selectedNacrty = [];
  }


  /**
   * Vrací true, pokud má přihlášený uživatel právo editace náčrtů.
  **/
  get canEdit(): boolean {
    return this.roleUtils.checkRole([RoleEnum.NacrtyEditor, RoleEnum.NacrtyAdmin]);
  }


  /**
   * Vrací true, pokud může přihlášený uživatel smazat náčrty.
  **/
  get canDelete(): boolean {
    if (this.selectedNacrty?.length == 0) {
      return false;
    }
    else if(this.selectedNacrty?.filter(n => !n.editableGeometries).length > 0) {
      return this.roleUtils.checkRole([RoleEnum.NacrtyAdmin]);
    }
    else {
      return this.canEdit;
    }
  }
}
