import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { DotazDetailDto } from '../../../Dto/Dotazovac/DotazDetailDto';

@Component({
  selector: 'app-new-sql-dotaz',
  templateUrl: './new-sql-dotaz.component.html',
  styleUrls: ['./new-sql-dotaz.component.css']
})
export class NewSqlDotazComponent {

  constructor(protected formBuilder: FormBuilder) {
  }

  @Input() displaySql: boolean = false;
  @Output() displaySqlChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() set dotaz(value: DotazDetailDto) {
    this._dotaz = value;
    if (value != void 0) {
      this.form.patchValue(value);
    }
  }

  private _dotaz: DotazDetailDto;


  faTimes = faTimes;
  faSave = faSave;

  /**
   * Událost o vložení dotazu
   */
  @Output() onSave: EventEmitter<DotazDetailDto> = new EventEmitter<DotazDetailDto>();

  form: FormGroup = this.formBuilder.group({
    nazev: ['', [Validators.required, Validators.maxLength(100)]],
    kategorie: ['', [Validators.required, Validators.maxLength(500)]],
    sqlData: ['', [Validators.required, Validators.maxLength(500)]]
  });

  /**
    * Přečte data z formuláře a uloží
    **/
  public save(): void {
    this.onSave.emit(this._formToDto());
    this.displaySql = false;
    this.displaySqlChange.emit(this.displaySql);
    this.form.reset();
  }

  /**
   * Formulář i data převede na dto detailu
   **/
  private _formToDto(): DotazDetailDto {
    let result: DotazDetailDto = new DotazDetailDto();
    if (this._dotaz != void 0) result = this._dotaz;

    Object.keys(this.form.value).forEach(key => {
      result[key] = this.form.value[key];
    });

    result.modelId = "PDo_Dotazovac_Test";//TODO - odkud brát model?
    result.zamceno = true;

    return result;
  }

  /**
   * Zavře dialog a udělá reset formuláře
   **/
  public close(): void {
    this._dotaz = null;
    this.displaySql = false;
    this.displaySqlChange.emit(this.displaySql);
    this.form.reset();
  }
}
