/**
 * Enum názvů query parametrů používaných při navigaci do mapy.
**/
export enum MapNavigationParams {
  //Extent na kterém se inicializuje mapa.
  bbox = 'bbox',

  //Souřadnice pro "výběr" mapových objektů. Pokud je zadáno, zobrazí se info panel s vybranými objekty a v mapě budou objekty zvýrazněny.
  coords = 'coords',

  //úroveň přiblížení
  zoom = 'zoom',

  //parametry idenfifikující mapové objekty
  id = 'id',
  typ = 'typ', //ve spojení s "id" jde o modul, ve spojení s "jprl" jde o typ rozdělení lesa
  lhc = 'lhc',
  odd = 'odd',
  dil = 'dil',
  por = 'por',
  jprl = 'jprl',

  //guid dotazu, který má uloženy výsledky selectu
  dotaz = 'dotaz'
}
