import { Component, OnInit, forwardRef, Input } from '@angular/core';
import { CiselnikDropdownComponent } from 'src/app/Components/Shared/ciselnik-dropdown/ciselnik-dropdown.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { CiselnikyService } from 'src/app/Services/Shared/ciselniky.service';

@Component({
  selector: 'app-vykon-prostredku-dropdown',
  templateUrl: '../../Shared/ciselnik-dropdown/ciselnik-dropdown.component.html',
  styleUrls: ['../../Shared/ciselnik-dropdown/ciselnik-dropdown.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => VykonProstredkuDropdownComponent),
    multi: true
  }]
})
export class VykonProstredkuDropdownComponent extends CiselnikDropdownComponent implements OnInit {

  constructor(
    ciselnikyService: CiselnikyService
  ) {
    super(ciselnikyService);
  }

  ngOnInit(): void {
    this.ciselnik = "CVykon";
  }

  private _prostredekGuid: string;
  private _vykonGuids: string[];//vykony prostředku
  private _podvykonGuid: string;//podvykon prostředku

  //odfiltruje výkony, které nepatří k prostředku
  @Input()
  set prostredekGuid(value: string) {
    if (value != this._prostredekGuid) {
      this._prostredekGuid = value;
      this.ciselnikyService.findVykonByProstredek(value).subscribe(res => {
        this._vykonGuids = res;
        if (res == void 0 || res.length == 0 || value.includes(this.value)) {
          this.value = null;
        } else if (res != void 0 && res.length == 1) {
          this.value = res[0];
        }

        this.buildData();
      });
    }
  }

  // override
  protected buildData(): void {
    var auxData = this._rawData.sort((a, b) => { return Number(a.kod) - Number(b.kod); })
      .map(x => {
        return {
          label: x.kod + " - " + x.popis, value: x.guid
        };
      });;

    if (this._vykonGuids) { // je nutno zkontrolovat naplnění, protože nemusí být...
      auxData = auxData.filter(x => {
        return this._vykonGuids.includes(x.value);
      }, this);
    }

    if (this._nullable) {
      this.data = [{ label: "Ponechat prázdné", value: null }].concat(auxData);
    }
    else {
      this.data = auxData;
    }
    this.selectByGuid();
  }

}
