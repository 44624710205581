import { Injectable } from "@angular/core";
import { TreeNode } from "primeng/api";

/**
 * Pomocná třída pro vyhledávání uzlů ve stromové stuktuře.
**/
@Injectable({
  providedIn: 'root'
})
export class TreeSearchUtils {

  /**
   * Vyhledání uzlu dle jeho Id ve stromové struktuře. Vrací první nalezený záznam.
   * @param id {string} id hledaného uzlu
   * @param tree {TreeNode[]} strom, ve kterém hledáme uzel
   */
  public findNode(id: string, tree: TreeNode[]): TreeNode {
    let res: TreeNode = null;
    for (var i = 0; res == null && i < tree.length; i++) {
      res = this._findNode(id, tree[i]);
    }
    return res;
  }


  /**
   * Vyhledání uzlu.
   * @param id {string} id hledaného uzlu
   * @param node {TreeNode} uzel stromu, ve kterém se hledá
   */
  private _findNode(id: string, node: TreeNode): TreeNode {
    if (node.key == id) {
      return node;
    }

    let res: TreeNode = null;
    if (node.children) {
      for (var i = 0; res == null && i < node.children.length; i++) {
        res = this._findNode(id, node.children[i]);
      }
    }

    return res;
  }
}
