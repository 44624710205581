<p-toast position="top-center" key="org-ur-toast"></p-toast>
<div [ngClass]="{'org-ur-short': variant=='short', 'org-ur-medium': variant=='medium', 'org-ur-full': variant=='full'
     , 'org-ur-second-short': variant=='second-short'}" *ngIf="showInput">
  <div class="p-inputgroup" *ngIf="edit&&!useInFilter" [title]="orgUrAllText">
    <span class="p-inputgroup-addon" *ngIf="sitemapIcon"><fa-icon [icon]="faSitemap"></fa-icon></span>
    <input [(ngModel)]="orgUr" type="text" pInputText (change)="inputChange($event)" [disabled]="disabled" />
    <span class="p-inputgroup-addon" *ngIf="variant == 'medium'">{{orgUrText}}</span>
    <span class="p-inputgroup-addon" *ngIf="variant == 'full'">{{orgUrAllText}}</span>
    <button pButton type="button" class="p-button-outlined p-button-secondary icon-btn hide-label-button back-sapce-btn" (click)="baskSpaceClicked()" [disabled]="_value == undefined"
            *ngIf="backSpace"><fa-icon [icon]="faBackspace" title="Smazat obsah filtru" style="padding-left: 5px !important;margin-right: 4px;">
              </fa-icon></button>
    <button pButton type="button" icon="pi pi-search" title="Vyhledat organizační úroveň" (click)="showDialog()"
            [disabled]="disabled"></button>
  </div>
  <div *ngIf="edit&&useInFilter" [title]="orgUrAllText">
    <input [(ngModel)]="orgUr" type="text" pInputText (input)="inputChange($event)" [disabled]="disabled" style="width:100%" />
  </div>
  <div class="p-inputgroup" *ngIf="!edit">
    <div class="p-inputtext ui-state-default ui-corner-all ui-div-inputtext">
      {{orgUr}}
    </div>
    <span class="p-inputgroup-addon" *ngIf="variant == 'medium'">{{orgUrText}}</span>
    <span class="p-inputgroup-addon" *ngIf="variant == 'full'">{{orgUrAllText}}</span>
  </div>
</div>

<p-dialog header="Organizační úrovně" [(visible)]="displayDialog" appendTo="body">
  <p-tree [value]="orgUrRef.orgUrTree" selectionMode="single" [(selection)]="selectedOrgUr" (onNodeExpand)="onNodeExpanded($event)"></p-tree>
  <p-footer>
    <button type="button" pButton icon="pi pi-check" label="Použít" (click)="selectOrgUr()" [disabled]="!selectedOrgUr"></button>
    <button type="button" pButton icon="pi pi-times" label="Zavřít" (click)="displayDialog=false" class="p-button-secondary"></button>
  </p-footer>
</p-dialog>
