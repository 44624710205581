<div class="p-grid plocha-grid">
  <div class="p-col-8">
    <div class="p-grid">
      <div class="p-col-4">
        <p-panel header="LHP / LHO" [style]="{'margin-bottom': '1em'}">
          <button pButton (click)="router.navigate(['/kniha'])" class="p-button-secondary" label="Kniha">
            <fa-icon [icon]="faBook" size="3x" [classes]="['plocha-icon-class']"></fa-icon>
          </button>
          <button pButton (click)="router.navigate(['/mapa'])" class="p-button-secondary" label="Mapa">
            <fa-icon [icon]="faMap" size="3x" [classes]="['plocha-icon-class']"></fa-icon>
          </button>
        </p-panel>
      </div>
      <div class="p-col-4">
        <p-panel header="Náčrty" [style]="{'margin-bottom': '1em'}">
          <button pButton [disabled]="!nacrtyGuard.canActivate()" (click)="router.navigate(['/nacrty'])" class="p-button-secondary" label="Náčrty">
            <fa-icon [icon]="faShapes" size="3x" [classes]="['plocha-icon-class']"></fa-icon>
          </button>
          <button pButton [disabled]="!nacrtyGuard.canActivate()" (click)="router.navigate(['/nacrty/projekty'])" class="p-button-secondary" label="Projekty náčrtů">
            <fa-icon [icon]="faFolder" size="3x" [classes]="['plocha-icon-class']"></fa-icon>
          </button>
        </p-panel>
      </div>
      <div class="p-col-4">
        <p-panel header="Služební deník" [style]="{'margin-bottom': '1em'}">
          <button pButton (click)="router.navigate(['/denik'])" class="p-button-secondary" label="Služební deník" disabled="disabled">
            <fa-icon [icon]="faClipboard" size="3x" [classes]="['plocha-icon-class']"></fa-icon>
          </button>
        </p-panel>
      </div>
    </div>
    <div class="p-grid">
      <div class="p-col-4">
        <p-panel header="Lesní hospodářská evidence" [style]="{'margin-bottom': '1em'}">
          <button pButton (click)="router.navigate(['/kniha'])" class="p-button-secondary" label="LHE" [disabled]="true">
            <fa-icon [icon]="faClipboardList" size="3x" [classes]="['plocha-icon-class']"></fa-icon>
          </button>
        </p-panel>
      </div>
      <div class="p-col-4">
        <p-panel header="Dotace" [style]="{'margin-bottom': '1em'}">
          <button pButton (click)="router.navigate(['/nacrty'])" class="p-button-secondary" label="Žádosti o dotaci" [disabled]="true">
            <fa-icon [icon]="faFileInvoiceDollar" size="3x" [classes]="['plocha-icon-class']"></fa-icon>
          </button>
        </p-panel>
      </div>
      <div class="p-col-4">
        <p-panel header="Projekty" [style]="{'margin-bottom': '1em'}">
          <button pButton (click)="router.navigate(['/projekty'])" class="p-button-secondary" label="Projekty" disabled="disabled">
            <fa-icon [icon]="faDraftingCompass" size="3x" [classes]="['plocha-icon-class']"></fa-icon>
          </button>
        </p-panel>
      </div>
    </div>
    <div class="p-grid">
      <div class="p-col-8">
        <p-panel header="Servis">
          <button pButton (click)="router.navigate(['/dotazovac'])" class="p-button-secondary" label="Uživatelské sestavy" [disabled]="!dotazovacGuard.canActivate()">
            <fa-icon [icon]="faDatabase" size="3x" [classes]="['plocha-icon-class']"></fa-icon>
          </button>
          <button pButton [disabled]="!zakaznikAdminGuard.canActivate()" (click)="router.navigate(['/zakaznik/config/org-ur'])" class="p-button-secondary" label="Organizační struktura">
            <fa-icon [icon]="faSitemap" size="3x" [classes]="['plocha-icon-class']"></fa-icon>
          </button>
          <button pButton [disabled]="!lhpAdminGuard.canActivate()" (click)="router.navigate(['/lhp/config/lhc'])" class="p-button-secondary" label="Správa LHC">
            <fa-icon [icon]="faThList" size="3x" [classes]="['plocha-icon-class']"></fa-icon>
          </button>
          <!--<button pButton (click)="router.navigate(['/vyroba/config/matice'])" class="p-button-secondary" label="Řídicí matice" disabled="disabled">
            <fa-icon [icon]="faCog" size="3x" [classes]="['plocha-icon-class']"></fa-icon>
          </button>
          <button pButton (click)="router.navigate(['/vyroba/config/kontroly'])" class="p-button-secondary" label="Kontroly dokladů" disabled="disabled">
            <fa-icon [icon]="faCheck" size="3x" [classes]="['plocha-icon-class']"></fa-icon>
          </button>
          <button pButton (click)="router.navigate(['/vyroba/config/uzaverky'])" class="p-button-secondary" label="Období, uzávěrky" disabled="disabled">
            <fa-icon [icon]="faCalendarAlt" size="3x" [classes]="['plocha-icon-class']"></fa-icon>
          </button>
          <button pButton (click)="router.navigate(['/vyroba/config/pruvodni'])" class="p-button-secondary" label="Průvodní listy" disabled="disabled">
            <fa-icon [icon]="faFileAlt" size="3x" [classes]="['plocha-icon-class']"></fa-icon>
          </button>
          <button pButton (click)="router.navigate(['/vyroba/config/sklad'])" class="p-button-secondary" label="Nastavení skladu" disabled="disabled">
            <fa-icon [icon]="faWarehouse" size="3x" [classes]="['plocha-icon-class']"></fa-icon>
          </button>-->
          <button pButton (click)="router.navigate(['config/ciselniky'])" class="p-button-secondary" label="Číselníky">
            <fa-icon [icon]="faListOl" size="3x" [classes]="['plocha-icon-class']"></fa-icon>
          </button>
          <button pButton [disabled]="!zakaznikAdminGuard.canActivate()" (click)="router.navigate(['/zakaznik/config/uzivatele'])" class="p-button-secondary" label="Správa uživatelů">
            <fa-icon [icon]="faUsersCog" size="3x" [classes]="['plocha-icon-class']"></fa-icon>
          </button>
          <button pButton *ngIf="superAdminGuard.canActivate()" (click)="router.navigate(['/zakaznik/config'])" class="p-button-secondary" label="Správa zákazníků">
            <fa-icon [icon]="faHandshake" size="3x" [classes]="['plocha-icon-class']"></fa-icon>
          </button>
        </p-panel>
      </div>
      <div class="p-col-4">
        <p-panel header="Škody zvěří">
          <button pButton (click)="router.navigate(['/skody'])" class="p-button-secondary plocha-icon-btn" label="Škody zvěří"
                  [disabled]="!skodyAdminGuard.canActivate()">
            <svg x="0px" y="0px" class="plocha-icon-class"
                 viewBox="0 0 495.98 495.98" style="enable-background:new 0 0 495.98 495.98;" xml:space="preserve">
            <path d="M446.208,36.108h14.485c4.646,0,8.409-3.761,8.409-8.409s-3.764-8.41-8.409-8.41h-14.485c-8.641,0-16.672,4.27-21.52,11.424
		l-18.377,27.183l-13.963-24.883c-2.246-4.064-7.357-5.501-11.447-3.22c-4.04,2.275-5.482,7.4-3.203,11.448l18.084,32.224
		l-10.726,15.884c-3.249-0.707-6.649-0.757-9.968,0.009c-1.266,0.286-2.433,0.795-3.615,1.288l-34.455-47.203l14.52-22.214
		c2.526-3.885,1.442-9.1-2.449-11.637c-3.858-2.522-9.064-1.47-11.646,2.438L325.62,30.114c-7.969-6.266-18.596-8.648-28.613-5.765
		l-15.059,4.343L264.75,3.647c-2.609-3.828-7.833-4.797-11.695-2.169c-3.825,2.629-4.794,7.859-2.169,11.693l14.029,20.424
		l-25.031,7.219c-4.469,1.29-7.028,5.946-5.746,10.413c1.277,4.449,5.861,6.989,10.412,5.748l57.107-16.464
		c6.158-1.75,12.775,0.509,16.506,5.649l8.752,11.989l-28.922,12.227c-4.288,1.808-6.291,6.742-4.486,11.021
		c1.365,3.203,4.486,5.133,7.755,5.133c1.101,0,2.201-0.215,3.269-0.666l32.57-13.771l22.188,30.392l-6.555,14.668l-62.232,93.332
		H115.455c-19.61,0-37.056,8.688-49.192,22.246c-20.039-9.585-26.936-20.802-29.104-28.892c-0.313-1.156-1.332-1.987-2.527-2.053
		c-1.199-0.066-2.3,0.641-2.745,1.758c-15.01,38.351,7.392,53.387,19.087,58.42c-1.085,4.771-1.789,9.692-1.789,14.791
		c0,19.783,8.834,37.341,22.582,49.479l-25.064,38.301l-0.952,1.462c-1.645,2.496-2.548,5.512-2.433,8.728l3.908,110.636
		c0.165,4.753,3.665,8.892,8.542,9.689c5.553,0.903,10.792-2.858,11.694-8.409l13.436-82.408l22.306,83.41
		c1.229,4.598,5.565,7.851,10.495,7.531c5.615-0.363,9.869-5.207,9.507-10.817l-6.517-101.024l27.821-40.307h103.047l18.807,37.597
		l-17.723,102.569c-0.887,5.108,1.971,10.273,7.012,12.12c5.664,2.069,11.939-0.837,14.008-6.503l34.871-95.27l17.244,93.759
		c0.936,5.157,5.619,8.894,10.974,8.531c5.763-0.386,10.116-5.369,9.721-11.126l-11.218-165.688l63.646-144.807l37.397,16.712
		c5.537,2.472,11.828,2.645,17.492,0.477c5.668-2.168,10.23-6.495,12.696-12.031l2.251-5.025c4.155-9.32,1.641-20.275-6.176-26.827
		l-59.049-49.528l21.783-32.216l9.082,6.956c1.525,1.165,3.318,1.731,5.107,1.731c2.527,0,5.025-1.134,6.682-3.3
		c2.813-3.688,2.119-8.968-1.574-11.786l-9.873-7.555l7.902-11.687C440.325,37.611,443.165,36.108,446.208,36.108z" /></svg>
          </button>
        </p-panel>
      </div>
    </div>
  </div>

  <!--<p-panel header="Výroba" [style]="{'margin-bottom': '1em'}">
        <button pButton (click)="router.navigate(['/vyroba/vml'])" class="p-button-secondary" label="Výrobně mzdové lístky" disabled="disabled">
          <img src="assets/wood.svg" />
        </button>
        <button pButton (click)="router.navigate(['/vyroba/ol'])" class="p-button-secondary" label="Odvozní lístky" disabled="disabled">
          <img src="assets/transport.svg" />
        </button>
        <button pButton (click)="router.navigate(['/vyroba/cd'])" class="p-button-secondary" label="Číselník dříví" disabled="disabled">
          <img src="assets/skurou.svg" />
        </button>
        <button pButton (click)="router.navigate(['/dochazka'])" class="p-button-secondary" label="Docházka" disabled="disabled">
          <fa-icon [icon]="faWalking" size="3x" [classes]="['plocha-icon-class']"></fa-icon>
        </button>
        <button pButton class="p-button-secondary" label="Výrobní sestavy" (click)="mVyrobaSestavy.toggle($event)" disabled="disabled">
          <img src="assets/marketing.svg" />
        </button>
        <p-menu #mVyrobaSestavy [popup]="true" [model]="menuVyrobaSestavy"></p-menu>
      </p-panel>
      <p-panel header="Školkařská evidence" [style]="{'margin-bottom': '1em'}">
        <button pButton class="p-button-secondary" label="Oddíly" disabled="disabled">
          <fa-icon [icon]="faSeedling" size="3x" [classes]="['plocha-icon-class']"></fa-icon>
        </button>
        <button pButton class="p-button-secondary" label="Doklady" disabled="disabled">
          <fa-icon [icon]="faFileAlt" size="3x" [classes]="['plocha-icon-class']"></fa-icon>
        </button>
        <button pButton class="p-button-secondary" label="Grafická evidence" disabled="disabled">
          <fa-icon [icon]="faTable" size="3x" [classes]="['plocha-icon-class']"></fa-icon>
        </button>
        <button pButton class="p-button-secondary" label="Hlášení" disabled="disabled">
          <fa-icon [icon]="faScroll" size="3x" [classes]="['plocha-icon-class']"></fa-icon>
        </button>
        <button pButton class="p-button-secondary" label="Sestavy" disabled="disabled">
          <img src="assets/marketing.svg" />
        </button>
      </p-panel>-->

  <div class="p-col-4">
    <div class="letokruhy"></div>
  </div>

</div>

<div class="ui-widget footer p-component">
  <!-- stará attribution z ikony jelena - škody -->
  <!--<div>Icons made by <a href="https://www.freepik.com/?__hstc=57440181.0b6a51189cf143685636f4d817fa900b.1561721250503.1562230760815.1562556012960.7&__hssc=57440181.1.1562556012960&__hsfp=3167949697" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a> is licensed by <a href="http://creativecommons.org/licenses/by/3.0/" title="Creative Commons BY 3.0" target="_blank">CC 3.0 BY</a></div>-->
  <div>Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
  <div>Icons made by <a href="https://www.flaticon.com/authors/xnimrodx" title="xnimrodx">xnimrodx</a> from <a href="https://www.flaticon.com/" title="Flaticon"> www.flaticon.com</a></div>
  <div><a href="/swagger">WebAPI</a></div>
</div>
