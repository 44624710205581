<div class="p-grid p-align-stretch kniha-detail-form rozdeleni-lesa">
  <div class="p-col-12">
    <div class="p-grid p-align-stretch">
      <div class="p-col">
        <app-label-input-display [value]="rozdeleniLesa?.plochaP0" label="Plocha" [numDecPlaces]="4"></app-label-input-display>
      </div>
      <div class="p-col">
        <app-label-input-display [value]="rozdeleniLesa?.vymera" label="Výměra" [numDecPlaces]="4"></app-label-input-display>
      </div>
      <div class="p-col">
        <app-label-input-display [value]="rozdeleniLesa?.plocha" label="Pl. HK" tooltip="Plocha použitá v HK"></app-label-input-display>
      </div>
      <div class="p-col">
        <app-label-input-display [value]="rozdeleniLesa?.cisloTel" label="LV" tooltip="Číslo vlastnictví (listu vlastnického)" [formatConent]="false"></app-label-input-display>
      </div>
      <div class="p-col">
        <app-ciselnik-field key="CKvalP" [guid]="rozdeleniLesa?.kvalPGuid" label="Kvalita" variant="short"></app-ciselnik-field>
      </div>
      <div class="p-col">
        <app-label-input-display [value]="rozdeleniLesa?.katuzeKod" label="k. ú." tooltip="Katastrální území" [ciselnikTooltip]="rozdeleniLesa?.katuzeNazev" [formatConent]="false"></app-label-input-display>
      </div>
      <div class="p-col">
        <app-ciselnik-field key="COrp" [guid]="rozdeleniLesa?.orpGuid" label="ORP" variant="short"></app-ciselnik-field>
      </div>
      <div class="p-col">
        <app-ciselnik-field key="CKraj" [guid]="rozdeleniLesa?.krajGuid" label="Kraj" variant="short"></app-ciselnik-field>
      </div>
    </div>
  </div>

  <div class="p-col-12">
    <div class="p-grid p-align-stretch">
      <div class="p-col">
        <app-ciselnik-field key="CChko" [guid]="rozdeleniLesa?.chkoGuid" label="CHKO" variant="short" tooltip="Chráněná krajinná oblast"></app-ciselnik-field>
      </div>
      <div class="p-col">
        <app-ciselnik-field key="CChkoZona" [guid]="rozdeleniLesa?.chkoZGuid" label="Zóna CHKO" variant="short" tooltip="Chráněná krajinná oblast - zóna"></app-ciselnik-field>
      </div>
      <div class="p-col">
        <app-ciselnik-field key="CNp" [guid]="rozdeleniLesa?.npGuid" label="NP" variant="short" tooltip="Národní park"></app-ciselnik-field>
      </div>
      <div class="p-col">
        <app-ciselnik-field key="CNpZona" [guid]="rozdeleniLesa?.npZGuid" label="Zóna NP" variant="short" tooltip="Zóna národního parku"></app-ciselnik-field>
      </div>
      <div class="p-col">
        <app-ciselnik-field key="CPo" [guid]="rozdeleniLesa?.poGuid" label="PO" variant="short" tooltip="Ptačí oblast"></app-ciselnik-field>
      </div>
      <div class="p-col">
        <app-ciselnik-field key="CPp" [guid]="rozdeleniLesa?.ppGuid" label="PP" variant="short" tooltip="Přírodní památka"></app-ciselnik-field>
      </div>
      <div class="p-col">
        <app-ciselnik-field key="CPr" [guid]="rozdeleniLesa?.prGuid" label="PR" variant="short" tooltip="Přírodní rezervace"></app-ciselnik-field>
      </div>
      <div class="p-col">
        <app-ciselnik-field key="CLhcSchu" [guid]="rozdeleniLesa?.schuGuid" label="SCHU" variant="short" tooltip="Smluvně chráněná území"></app-ciselnik-field>
      </div>
    </div>
  </div>

  <div class="p-col-4">
    <p-table [value]="rozdeleniLesa?.evls" styleClass="p-datatable-gridlines p-datatable-striped pds-table-compact p-mt-2">
      <ng-template pTemplate="header">
        <tr>
          <th pTooltip="Evropsky významná lokalita" class="tooltip-label" tooltipPosition="top">EVL</th>
          <th>Popis</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-evl>
        <tr>
          <td>{{evl.kod}}</td>
          <td>{{evl.popis}}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <div class="p-col-4">
    <p-table [value]="rozdeleniLesa?.npps" styleClass="p-datatable-gridlines p-datatable-striped pds-table-compact p-mt-2">
      <ng-template pTemplate="header">
        <tr>
          <th pTooltip="Národní přírodní památka" class="tooltip-label" tooltipPosition="top">NPP</th>
          <th>Popis</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-npp>
        <tr>
          <td>{{npp.kod}}</td>
          <td>{{npp.popis}}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <div class="p-col-4">
    <p-table [value]="rozdeleniLesa?.nprs" styleClass="p-datatable-gridlines p-datatable-striped pds-table-compact p-mt-2">
      <ng-template pTemplate="header">
        <tr>
          <th pTooltip="Národní přírodní rezervace" class="tooltip-label" tooltipPosition="top">NPR</th>
          <th>Popis</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-npr>
        <tr>
          <td>{{npr.kod}}</td>
          <td>{{npr.popis}}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<p-table [value]="rozdeleniLesa?.pars" styleClass="p-datatable-gridlines p-datatable-striped pds-table-compact p-mt-3">
  <ng-template pTemplate="caption">
    Parcely
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th>Skupina dle KN</th>
      <th>Číslo</th>
      <th>Podlomení</th>
      <th>Díl</th>
      <th>Plocha části parcely zjištěná</th>
      <th>Celá parcela</th>
      <th>Plocha parcely zjištěná</th>
      <th>Výměra parcely</th>
      <th>Druh pozemnku</th>
      <th>Druh pozemku - popis</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-parcela>
    <tr>
      <td>{{parcela.parSkuKn}}</td>
      <td>{{parcela.parCis}}</td>
      <td>{{parcela.parPod}}</td>
      <td>{{parcela.parDil}}</td>
      <td>{{parcela.cpP0}}</td>
      <td>{{parcela.cpCas}}</td>
      <td>{{parcela.parP0}}</td>
      <td>{{parcela.parV}}</td>
      <td>{{parcela?.parDruhPoz?.kod}}</td>
      <td>{{parcela?.parDruhPoz?.popis}}</td>
    </tr>
  </ng-template>
</p-table>

