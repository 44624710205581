<div class="p-grid p-align-stretch kniha-detail-form">
  <div class="p-col-2">
    <app-label-input-display [value]="lhc?.lhcKod" label="Kód" [formatConent]="false"></app-label-input-display>
  </div>
  <div class="p-col-10">
    <app-label-input-display [value]="lhc?.lhcNaz" label="Název"></app-label-input-display>
  </div>

  <div class="p-col-1">
    <app-label-input-display [value]="lhc?.kuDatum" label="KU datum" tooltip="Datum vydání číselníku k.u."></app-label-input-display>
  </div>
  <div class="p-col-1">
    <app-label-input-display [value]="lhc?.lhpOd" label="Platnost OD" ></app-label-input-display>
  </div>
  <div class="p-col-1">
    <app-label-input-display [value]="lhc?.lhpDo" label="Platnost DO" ></app-label-input-display>
  </div>
  <div class="p-col-2">
    <app-label-input-display [value]="lhc?.lhpLic" label="Licence taxační kanceláře" [numDecPlaces]="0"></app-label-input-display>
  </div>
  <div class="p-col-7">
    <app-label-input-display [value]="lhc?.lhpTax" label="Taxační kancelář"></app-label-input-display>
  </div>

  <div class="p-col-2">
    <app-label-input-display [value]="lhc?.lhpNezdar" label="LHP nezdar"></app-label-input-display>
  </div>
  <div class="p-col-2">
    <app-label-input-display [value]="lhc?.tezProc" label="Těžební %" tooltip="Ukazatel těžební procento"></app-label-input-display>
  </div>
  <div class="p-col-2">
    <app-label-input-display [value]="lhc?.norPas" label="Normální paseka" [numDecPlaces]="0" tooltip="Ukazatel normální paseka"></app-label-input-display>
  </div>
  <div class="p-col-2">
    <app-label-input-display [value]="lhc?.lhcPnPro" label="LHC PN PRO" [numDecPlaces]="0" tooltip="Očekávaný podíl těžby předmýtní nahodilé"></app-label-input-display>
  </div>
  <div class="p-col-2">
    <app-label-input-display [value]="lhc?.lhcInd" label="LHC IND" [numDecPlaces]="0" tooltip="Výše těžeb v LHP odvozených induktivně"></app-label-input-display>
  </div>
  <div class="p-col-2">
    <app-label-input-display [value]="lhc?.lhcMax" label="LHC MAX" [numDecPlaces]="0" tooltip="Celková maximální výše těžeb v lesích obhospodařovaných jinak než výběrným způsobem"></app-label-input-display>
  </div>

  <div class="p-col-2">
    <app-label-input-display [value]="lhc?.etatTo" label="Etát TO" [numDecPlaces]="0" tooltip="Mýtní těžba navržená v LHP"></app-label-input-display>
  </div>
  <div class="p-col-2">
    <app-label-input-display [value]="lhc?.etatTv" label="Etát TV" [numDecPlaces]="0" tooltip="Výše předmýtní těžby navržená v LHP"></app-label-input-display>
  </div>
  <div class="p-col-2">
    <app-label-input-display [value]="lhc?.etatTvyb" label="Etát TVYB" [numDecPlaces]="0" tooltip="Celková výše těžeb pro lesy obhospodařované hospodářským způsobem výběrným"></app-label-input-display>
  </div>
  <div class="p-col-2">
    <app-label-input-display [value]="lhc?.etat" label="Etát" [numDecPlaces]="0"></app-label-input-display>
  </div>
  <div class="p-col-2">
    <app-label-input-display [value]="lhc?.mvychDo40" label="MVych do 40" tooltip="Minimální plošný rozsah výchovných zásahů do 40 let"></app-label-input-display>
  </div>
</div>
