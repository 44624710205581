import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SkodyDetailSBaseComponent } from 'src/app/Components/Skody/skody-detail-s-base/skody-detail-s-base.component';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { LhpEtazProVypocetDto } from 'src/app/Dto/Skody/LhpEtazProVypocetDto';
import { LhpDrevinaProVypocetDto } from 'src/app/Dto/Skody/LhpDrevinaProVypocetDto';

@Component({
  selector: 'ujmy-detail-u7',
  templateUrl: './ujmy-detail-u7.component.html',
  styleUrls: ['./ujmy-detail-u7.component.css']
})
export class UjmyDetailU7Component extends SkodyDetailSBaseComponent implements OnInit {

  constructor(
    fb: FormBuilder
  ) {
    super(fb);
  }

  ngOnInit(): void {
    this.data.valueChanges
      .pipe(
        debounceTime(600),
        distinctUntilChanged((prev, curr) => prev.plocha === curr.plocha && prev.vek === curr.vek && prev.zakm === curr.zakm && prev.zast == curr.zast
          && prev.bonR === curr.bonR && prev.upravaHlpa === curr.upravaHlpa && curr.zasoba == prev.zasoba && curr.objem == prev.objem),
        filter(data => this.validateVstupyProPrepocet(data))
      )
      .subscribe(() => {
        this.dataChanged.emit(this.data.value);
      });
  }


  data: FormGroup = this.fb.group({
    plocha: [''],
    vek: [''],
    zakm: [''],
    zast: [''],
    bonR: [''],
    upravaHlpa: [''],
    zasoba: [''],
    objem: ['']
  });

  /**
   * Kontrola dat z U7 pro přepočet škody.
   * @param data
   */
  validateVstupyProPrepocet(data): boolean {
    return data.plocha != void 0
      && data.vek != void 0
      && data.zakm != void 0;
  }


  /**
   * Zkopíruje data etáže z LHP do vstupu.
  **/
  kopirujLhpEtazDoVstupu(dto: LhpEtazProVypocetDto): void {
    if (dto != void 0) {
      this.data.get('plocha').setValue(dto.etazPs);
      this.data.get('vek').setValue(dto.vek);
      this.data.get('zakm').setValue(dto.zakm);
    }
  }


  /**
   * Zkopíruje data dřeviny z LHP do vstupu.
  **/
  kopirujLhpDrevinuDoVstupu(dto: LhpDrevinaProVypocetDto): void {
    if (dto != void 0) {
      this.data.get('bonR').setValue(dto.bonR);
      this.data.get('zast').setValue(dto.zast);
    }
  }
}
