<p-autoComplete #lhcAutocomplete ([ngModel])="lhcDto" [suggestions]="navrhovaneLhc" (completeMethod)="hledejLhc($event)"
                (onBlur)="onBlur($event);opLhcInfo?.hide()" (onSelect)="onSelect($event)" (onFocus)="opLhcInfo?.show($event)"
                field="lhcKod" appendTo="body" [disabled]="disabled" *ngIf="edit" [inputId]="lhcInputId"
                [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}">
  <ng-template let-lhc pTemplate="item">
    {{lhc.lhcKod}} - {{lhc.lhcNaz}}
  </ng-template>
</p-autoComplete>
<p-overlayPanel #opLhcInfo [dismissable]="false" appendTo="body" [style]="{width: '300px'}" *ngIf="edit">
  <dl *ngIf="lhcDto != null; else nezadano">
    <dt>Název LHC:</dt>
    <dd>{{lhcDto?.lhcNaz}}</dd>
    <dt>Platnost LHP:</dt>
    <dd>{{lhcDto?.lhpOd | date:'yyyy'}} - {{lhcDto?.lhpDo | date:'yyyy'}}</dd>
    <!--<dt>Guid:</dt>
    <dd>{{lhcDto?.guid}}</dd>-->
  </dl>
  <ng-template #nezadano><p>LHC nezadáno</p></ng-template>
</p-overlayPanel>
<div class="p-inputtext ui-widget ui-state-default ui-corner-all ui-div-inputtext" *ngIf="!edit" title="{{lhcDto?.lhcKod}}">{{lhcDto?.lhcKod}} </div>
