import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { CVyrSkladListDto } from 'src/app/Dto/Vyroba/CVyrSkladListDto';
import { SelectItem } from 'primeng/api/selectitem';
import { faPlus, faTrash, faSave, faUndo, faCopy, faEllipsisV, faBackspace, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Validators, FormBuilder, FormArray, ValidatorFn, FormGroup, ValidationErrors } from '@angular/forms';
import { CVyrSkladDetailDto } from 'src/app/Dto/Vyroba/CVyrSkladDetailDto';
import { Router } from '@angular/router';
import { MessageService, ConfirmationService } from 'primeng/api';
import { ResultInfoGenericDto } from 'src/app/Dto/Shared/ResultInfoDto';
import { CVyrSkladPohybDetailDto } from 'src/app/Dto/Vyroba/CVyrSkladPohybDetailDto';
import { MessagesUtils } from 'src/app/Utils/Shared/messages.utils';
import { CiselnikyService } from 'src/app/Services/Shared/ciselniky.service';
import { VyrobaSkladService } from 'src/app/Services/Vyroba/vyroba-sklad.service';
import { CiselnikDropdownComponent } from 'src/app/Components/Shared/ciselnik-dropdown/ciselnik-dropdown.component';
import { TableComponent } from '../../Shared/table/table.component';

@Component({
  selector: 'app-vyroba-sklad-config',
  templateUrl: './vyroba-sklad-config.component.html',
  styleUrls: ['./vyroba-sklad-config.component.css']
})
export class VyrobaSkladConfigComponent implements OnInit {

  private readonly MESSAGE_KEY: string = "vyroba-sklad-config-toast";

  /**
   * crossfield validator pro kontrolu, že pokud je zaškrtnut pohyb, je vybrán druh pohybu
   * @param control
   */
  private readonly druhPohybuValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
    const enabled = control.get('enabled');
    const druhKod = control.get('druhKod');

    return enabled.value && !druhKod.value ? { druhKodMissing: true } : null;
  };

  @ViewChild("colDatum", { static: true }) datumTemplate: TemplateRef<any>;
  @ViewChild("colLokalita", { static: true }) lokalitaTemplate: TemplateRef<any>;
  @ViewChild("skladTable", { static: true }) table: TableComponent;
  @ViewChild("skladVykon") vykonInput: CiselnikDropdownComponent;

  items: CVyrSkladListDto[];
  selectedItem: CVyrSkladListDto;
  cols: any[] = [];
  block: boolean = false;
  filter: string;
  showDetail: boolean = false;

  druhPohybu: SelectItem[] = [
    { value: '', label: '' },
    { value: 's', label: 'Standardní' },
    { value: 'm', label: 'Manipulace' },
    { value: 'o', label: 'Ostatní' }
  ];

  faPlus = faPlus;
  faTrash = faTrash;
  faSave = faSave;
  faUndo = faUndo;
  faCopy = faCopy;
  faEllipsisV = faEllipsisV;
  faBackspace = faBackspace;
  faTimes = faTimes;

  form = this.formBuilder.group({
    guid: [''],
    podvykonGuid: ['', Validators.required],
    lokalitaGuid: [''],
    platnostOd: ['', Validators.required],
    platnostDo: [''],
    vlozil: [''],
    vlozilKdy: [''],
    opravil: [''],
    opravilKdy: [''],
    lokality: this.formBuilder.array([]),
    vykonGuid: ['', Validators.required] // dávám nakonec, protože není v DTO, ale musí zde být aby formulář držel pohromadě
  });

  get vykonGuid() {
    return this.form.get('vykonGuid');
  }

  get data(): CVyrSkladDetailDto {
    return this.form.value;
  }

  set data(val: CVyrSkladDetailDto) {
    this.form.reset();
    this.form.patchValue(val);
  }

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private messageUtils: MessagesUtils,
    private vyrobaSkladService: VyrobaSkladService,
    private ciselnikyService: CiselnikyService,
    private confirmationService: ConfirmationService
  ) { }

  ngOnInit(): void {
    this._initCols();
    this.ciselnikyService.getCislenik("CLokalita", null).subscribe(cis => {
      cis.sort((a, b) => { return Number(a.kod) - Number(b.kod); }).forEach(item => {
        (this.form.get('lokality') as FormArray).push(
          this.formBuilder.group({
            lokalitaGuid: [ item.guid ],
            prijem: this.formBuilder.group({
              guid: [''],
              smer: [ 1.0 ],
              druhKod: [''],
              enabled: [''],
            }, { validators: this.druhPohybuValidator }),
            vydej: this.formBuilder.group({
              guid: [''],
              smer: [ -1.0 ],
              druhKod: [''],
              enabled: [''],
            }, { validators: this.druhPohybuValidator })
          })
        );
      });
    });

    this._loadList();
  }

  /**
   * Inicializace sloupců pro grid
   */
  private _initCols() {
    this.cols = [
      { field: 'vykon', header: 'V', class: 'col-5' },
      { field: 'podvykon', header: 'PV', class: 'col-5' },
      { field: 'podvykonPopis', header: 'Podvýkon', class: 'col-25' },
      { field: 'lokalitaGuid', header: 'Lokalita', class: 'col-25', template: this.lokalitaTemplate },
      { field: 'platnostOd', header: 'Platnost od', class: 'col-5', template: this.datumTemplate },
      { field: 'platnostDo', header: 'Platnost do', class: 'col-5', template: this.datumTemplate },
      { field: 'vlozil', header: 'Vložil', class: 'col-10 col-hide' },
      { field: 'vlozilKdy', header: 'Vloženo kdy', class: 'col-5 col-hide', template: this.datumTemplate },
      { field: 'opravil', header: 'Opravil', class: 'col-10 col-hide' },
      { field: 'opravilKdy', header: 'Opraveno kdy', class: 'col-5 col-hide', template: this.datumTemplate }
    ];
  }

  add(): void {
    var newData = new CVyrSkladDetailDto();
    this.data = newData;
    this._loadPohybyFromDto([]);
    this.vykonGuid.setValue(null);
    this.showDetail = true;
    this.focusFirst();
  }

  back(): void {
    this.router.navigate(["/vyroba"]);
  }

  /**
   * Handler vybrání řádku gridu
   * @param dto
   */
  onRowSelected(dto: CVyrSkladListDto): void {
    this.block = true;
    this.vykonGuid.setValue(null);
    this.vyrobaSkladService.getByGuid(dto.guid).subscribe(resp => { this._processDetailResponse(resp); });
    this.selectedItem = dto;
  }

  /**
   * Handler zrušení výběru řádku
   * @param dto
   */
  onRowUnSelected(dto: CVyrSkladListDto): void {
    this.showDetail = false;
    this.selectedItem = null;
  }

  /**
   * Zpracování odpovědi s detailem (načtení detailu do formuláře, případně zobrazení hlášky)
   * @param resp
   * @param successMessage
   */
  private _processDetailResponse(resp: ResultInfoGenericDto<CVyrSkladDetailDto>, successMessage?: string) {
    if (resp.success) {
      this.data = resp.data;

      this._loadPohybyFromDto(resp.data.pohyby);

      this.ciselnikyService.getCislenik("CPodvykon", null).subscribe(cis => {
        var pv = cis.find(x => x.guid == this.data.podvykonGuid);
        this.vykonGuid.setValue(pv["parentGuid"]);
        this.block = false;
        this.showDetail = true;
        this.focusFirst();
      });

      if (successMessage != undefined) {
        this._showMessage(resp, successMessage);
      }
    }
    else {
      this._showMessage(resp);
      this.block = false;
    }
  }

  /**
   * Převod pole skladových pohybů z jednoduché kolekce v DTO na strukturu pro formulář
   * @param pohyby
   */
  private _loadPohybyFromDto(pohyby: CVyrSkladPohybDetailDto[]): void {
    this.ciselnikyService.getCislenik("CLokalita", null).subscribe(cis => {
      let lokality = [];

      cis.sort((a, b) => { return Number(a.kod) - Number(b.kod); }).forEach(item => {
        let prijem = pohyby.find(x => x.lokalitaGuid == item.guid && x.smer == +1);
        let vydej = pohyby.find(x => x.lokalitaGuid == item.guid && x.smer == -1);
        lokality.push({
          lokalitaGuid: item.guid,
          prijem: {
            guid: prijem?.guid,
            smer: 1,
            druhKod: prijem?.druhKod,
            enabled: prijem != null
          },
          vydej: {
            guid: vydej?.guid,
            smer: -1,
            druhKod: vydej?.druhKod,
            enabled: vydej != null
          },
        });
      });

      this.form.get('lokality').patchValue(lokality);
    });
  }

  /**
   * Převede data skladových pohybů ze struktury formuláře na DTO pro uložení na serveru.
   */
  private _savePohybyToDto(): CVyrSkladPohybDetailDto[] {
    let formData = this.form.get('lokality').value;
    let result: CVyrSkladPohybDetailDto[] = [];

    formData.forEach(x => {
      if (x.prijem.enabled) {
        result.push({
          druhKod: x.prijem.druhKod,
          guid: x.prijem.guid,
          lokalitaGuid: x.lokalitaGuid,
          smer: x.prijem.smer
        });
      }
      if (x.vydej.enabled) {
        result.push({
          druhKod: x.vydej.druhKod,
          guid: x.vydej.guid,
          lokalitaGuid: x.lokalitaGuid,
          smer: x.vydej.smer
        });
      }
    });

    return result;
  }

  /**
   * Podle obsahu response zobrazi prislusnou zpravu
   * @param resp response
   * @param successMessage text message zobrazeny v pripade, ze resp.success je true
   */
  private _showMessage(resp: ResultInfoGenericDto<CVyrSkladDetailDto>, successMessage?: string) {
    if (resp.success) {
      if (successMessage != undefined) {
        this.messageService.add({ severity: 'success', summary: 'OK', key: this.MESSAGE_KEY, detail: successMessage, life: MessagesUtils.TOAST_LIFE })
      }
    }
    else if (!resp.success) {
      this.messageUtils.showResponseMessage(this.MESSAGE_KEY, resp);
    }
    else {
      this.messageService.add({ severity: 'error', summary: 'Chyba', key: this.MESSAGE_KEY, detail: 'Došlo k neočekávané chybě.', life: MessagesUtils.TOAST_LIFE })
    }
  }


  /**
   * Aktualizace dat v gridu po uložení
   * @param resp
   */
  private _updateInGrid(resp: ResultInfoGenericDto<CVyrSkladDetailDto>) {
    if (resp.success) {
      var listDto = this.items.find(x => x.guid == resp.data.guid);

      if (listDto == undefined) {
        listDto = new CVyrSkladListDto();
        listDto.guid = resp.data.guid;
        this.items.push(listDto);
        this.items = [...this.items];
        this.selectedItem = listDto;
      }

      Object.keys(resp.data).forEach(key => {
        listDto[key] = resp.data[key];
      });

      this.ciselnikyService.getCislenik("CPodvykon", null).subscribe(cis => {
        var pv = cis.find(x => x.guid == resp.data.podvykonGuid);
        listDto.podvykon = Number(pv.kod);
        listDto.podvykonPopis = pv.popis;
        this.ciselnikyService.getCislenik("CVykon", null).subscribe(cvykon => {
          var v = cvykon.find(x => x.guid == pv["parentGuid"]);
          listDto.vykon = Number(v.kod);
        });
      });
    }
  }

  save(): void {
    this.block = true;
    this.vyrobaSkladService.save(this._getDataFormSave()).subscribe(resp => {
      this._processDetailResponse(resp, 'Nastavení bylo uloženo.');
      this._updateInGrid(resp);
    });
  }

  saveCopy(): void {
    this.block = true;
    this.form.patchValue({ guid: undefined });
    this.vyrobaSkladService.save(this._getDataFormSave()).subscribe(resp => {
      this._processDetailResponse(resp, 'Nastavení bylo uloženo.');
      this._updateInGrid(resp);
    });
  }

  /**
   * Pomůcka pro sestavení dat pro uložení
   */
  private _getDataFormSave(): CVyrSkladDetailDto {
    let data = this.data;
    data.pohyby = this._savePohybyToDto();
    return data;
  }

  cancel(): void {
    this.add(); // reset form
    this.showDetail = false;
    this.table.unselectAll();
    this.selectedItem = undefined;
  }

  /**
   * Po zobrazení panelu focus na první položku
   * */
  focusFirst(): void {
    setTimeout(() => { if (this.vykonInput != void 0) this.vykonInput.focus() });
  }

  /**
   *  Handler načtení dat tabulky
   * */
  dataTableLoaded(): void {
    if (this.selectedItem != void 0)
      this.table.selectRowByGuid(this.selectedItem.guid);
  }

  /**
  * Smaže vybraný řádek
  * */
  remove(): void {
    this.confirmationService.confirm({
      message: 'Opravdu si přejete smazat vybraný řádek?',
      accept: () => {
        this.block = true;
        this.vyrobaSkladService.delete(this.selectedItem.guid)
          .subscribe(resp => {
            this.cancel();
            this._showMessage(resp as ResultInfoGenericDto<CVyrSkladDetailDto>, "Záznam matice byl smazán.");
            if (resp.success) {
              this._loadList();
            }
            this.block = false;
          });
      }
    });
  }


  /**
   * Načte seznam zaznamu matice
   * */
  private _loadList(): void {
    this.vyrobaSkladService.list().subscribe(resp => {
      if (resp.success) {
        this.items = resp.data;
      }
      else if (!resp.success) {
        this.messageUtils.showResponseMessage(this.MESSAGE_KEY, resp);
      }
      else {
        this.messageService.add({ severity: 'error', summary: 'Chyba', key: this.MESSAGE_KEY, detail: 'Došlo k neočekávané chybě.', life: MessagesUtils.TOAST_LIFE })
      }
    });
  }

  /**
   * zruší výběr a druh u všech lokalit
   */
  nic(): void {
    this.ciselnikyService.getCislenik("CLokalita", null).subscribe(cis => {
      let lokality = [];

      cis.sort((a, b) => { return Number(a.kod) - Number(b.kod); }).forEach(item => {
        lokality.push({
          prijem: {
            druhKod: '',
            enabled: false
          },
          vydej: {
            druhKod: '',
            enabled: false
          },
        });
      });

      this.form.get('lokality').patchValue(lokality);
    });
  }

}
