import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserInfoDto } from '../../Dto/Auth/UserInfoDto';
import { SyncDto } from '../../Dto/Sync/syncDto';
import { SyncHistoryDto } from '../../Dto/Sync/SyncHistoryDto';
import { SyncResult } from '../../Dto/Sync/SyncResult';
import { AuthService } from '../../Services/Auth/auth.service';
import { DateUtils } from '../../Utils/Shared/date.utils';

@Injectable({
  providedIn: 'root'
})
export class SyncService {

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) { }

  api_sync_get: string = "api/Sync/Get";
  api_sync_post: string = "api/Sync/Post";


  get(guid: string): Observable<any> {
    return this.http.get<any>(this.api_sync_get + '/' + guid);
  }


  /**
   * Provede synchronizační post request včetně doplnění sync/sync_history.
   * Obsahuje buď odpověď Sync/Post nebo string/chybu.
   * @param dtos {any[]} kolekce synchronizačních dto
   */
  post(dtos: any[]): Observable<SyncResult> {
    //EventEmitter je zde kvůli tomu, že se nejdříve volá webApi pro získání informací o přihlášeném uživateli až potom sync.post, jehož výsledek potřebujeme.
    let emitter = new EventEmitter<any>();
    this.authService.info().subscribe(infoResp => {
      if (infoResp != void 0) {
        dtos.forEach(dto => {
          let updatedSync = this.addHistoryEntry(dto.sync, infoResp);
          dto.sync = updatedSync;
        });

        this.http.post<SyncResult>(this.api_sync_post, dtos).subscribe(postResp => {
          emitter.emit(postResp);
        }, (err) => emitter.emit(err));
      }
      else {
        emitter.emit("Chyba získání info o přihlášením uživateli.");
      }
    });
    return emitter;
  }


  /**
   * Přidá nový záznam do sync_history.
   * @param syncDto
   * @param userInfo
   */
  private addHistoryEntry(syncDto: SyncDto, userInfo: UserInfoDto): SyncDto {
    if (userInfo == void 0) {
      return null;
    }
    syncDto.u++;
    let newHistory = this._createHistoryEntry(syncDto.u, userInfo.login);
    syncDto.h.push(newHistory);
    return syncDto;
  }


  /**
   * Vytovří nový záznam sync historie.
   * @param seqNumber
   * @param by
   */
  private _createHistoryEntry(seqNumber: number, by: string): SyncHistoryDto {
    let historyDto = new SyncHistoryDto();
    historyDto.s = seqNumber;
    historyDto.w = DateUtils.formatDateTime(new Date());
    historyDto.b = by;

    return historyDto;
  }
}
