import { Component, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { faPlay, faPrint } from '@fortawesome/free-solid-svg-icons';
import { PestebniService } from 'src/app/Services/Vyroba/pestebni.service';

@Component({
  selector: 'app-pestebni-index',
  templateUrl: './pestebni-index.component.html',
  styleUrls: ['./pestebni-index.component.css']
})
export class PestebniIndexComponent implements OnInit {

  faPlay = faPlay;
  faPrint = faPrint;

  podsestavy: SelectItem[];
  selectedPodsestava: string;

  typEasyQuery: string = "VyrTisk";
  typSestavy: string = "pěstební";

  service: PestebniService;

  constructor(service: PestebniService) {
    this.service = service;
  }

  ngOnInit(): void {
    //Podsestavy
    this.podsestavy = [
      { value: "01", label: "Detail" },
      { value: "02", label: "Detail bez dodavatele" },
      { value: "03", label: "Detail bez Kč" },
      { value: "04", label: "Detail bez prostředku s náklady na MJ" },
      { value: "05", label: "Detail bez prostředku s materiálem" },
      { value: "06", label: "Sumář za organizační úroveň 3" },
      { value: "07", label: "Sumář za organizační úroveň 3 bez Kč" },
      { value: "08", label: "Sumář za organizační úroveň 3 s náklady na MJ" },
      { value: "09", label: "Sumář za organizační úroveň 2" },
      { value: "10", label: "Sumář za organizační úroveň 2 bez Kč" },
      { value: "11", label: "Sumář za organizační úroveň 2 s náklady na MJ" },
      { value: "12", label: "Sumář za organizační úroveň 1" },
      { value: "13", label: "Sumář za organizační úroveň 1 bez Kč" },
      { value: "14", label: "Sumář za organizační úroveň 1 s náklady na MJ" },
      { value: "15", label: "Sumář celkem" },
      { value: "16", label: "Sumář celkem bez Kč" },
      { value: "17", label: "Sumář celkem s náklady na MJ" },
    ];
    this.selectedPodsestava = this.podsestavy[0].value;
  }

  //run(): void {
  //  console.log(this.service.test((window as any).EQView.getContext().getQuery().toJSON(), this.selectedPodsestava.value).subscribe(x => console.log(x)));
  //}

  print(): void {
    this.service.exportToken().subscribe(x => {
      window.open("api/Pestebni/Export/?parameters=" + (window as any).EQView.getContext().getQuery().toJSON() + "&frx=" + this.selectedPodsestava + "&token=" + x.data);
    });
  }
}
