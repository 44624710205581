import { Component, OnInit } from '@angular/core';
import { faPrint, faUndo } from '@fortawesome/free-solid-svg-icons';
import { SelectItem } from 'primeng/api';
import { PestebniService } from 'src/app/Services/Vyroba/pestebni.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-vyroba-sklad-report',
  templateUrl: './vyroba-sklad-report.component.html',
  styleUrls: ['./vyroba-sklad-report.component.css']
})
export class VyrobaSkladReportComponent implements OnInit {

  faPrint = faPrint;
  faUndo = faUndo;

  maxDate = new Date(); // zatím max = dnes... potom bude max z dokladů VML
  yearRange = '' + (new Date().getFullYear() - 1) + ':' + (new Date().getFullYear()); // zatím loni a letos, pak by mělo být z dokladů 

  podsestavy: SelectItem[] = [];
  selectedPodsestava: string;
  obdobi: Date = new Date();

  get mesic() {
    return this.obdobi.getMonth() + 1;
  }

  get rok() {
    return this.obdobi.getFullYear();
  }

  constructor(
    private location: Location,
    private service: PestebniService
  ) { }

  ngOnInit(): void {
    this.podsestavy = [
      { value: 'VYROBA_VYKAZ_SKLADU_DETAILjprl', label: 'Celkový přehled (P-VM-OM-ES) dle JPRL a sortimentů' },
      { value: 'VYROBA_VYKAZ_SKLADU_DETAILjprlpvm', label: 'Celkový přehled (P+VM-OM-ES) dle JPRL a sortimentů' },
      { value: 'VYROBA_VYKAZ_SKLADU_DETAIL_P_VMjprl', label: 'Detail P-VM dle JPRL a sortimentů' },
      { value: 'VYROBA_VYKAZ_SKLADU_DETAIL_P_OMjprl', label: 'Detail P-OM dle JPRL a sortimentů' },
      { value: 'VYROBA_VYKAZ_SKLADU_DETAIL_P_OM2jprl', label: 'Detail P-OM (OM bez porostu) dle JPRL a sortimentů' },
      { value: 'VYROBA_VYKAZ_SKLADU_DETAIL_P_OM_KUMULOVANE', label: 'Sumář P-OM kumulovaně od počátku roku' },
      { value: 'VYROBA_VYKAZ_SKLADU_DETAIL', label: 'Celkový přehled (P-VM-OM-ES)' },
      { value: 'VYROBA_VYKAZ_SKLADU_DETAILpvm', label: 'Celkový přehled (P+VM-OM-ES)' },
      { value: 'VYROBA_VYKAZ_SKLADU_DETAIL_P_VM', label: 'Detail P-VM' },
      { value: 'VYROBA_VYKAZ_SKLADU_DETAIL_P_OM', label: 'Detail P-OM' },
      { value: 'VYROBA_VYKAZ_SKLADU_DETAIL_OM_ES', label: 'Detail OM-ES' },
      { value: 'VYROBA_VYKAZ_SKLADU_DETAIL_P_OM2', label: 'Detail P-OM (OM bez porostu)' },
      { value: 'VYROBA_VYKAZ_SKLADU_DETAIL_P_VMs', label: 'Sumář P-VM' },
      { value: 'VYROBA_VYKAZ_SKLADU_DETAIL_P_OMs', label: 'Sumář P-OM' },
      { value: 'VYROBA_VYKAZ_SKLADU_DETAIL_PVM_OMs', label: 'Sumář P+VM-OM' },
      { value: 'VYROBA_VYKAZ_SKLADU_DETAIL_OM_ESs', label: 'Sumář OM-ES' },
      { value: 'VYROBA_VYKAZ_SKLADU_DETAIL_ORG_UR3', label: 'Sumář dle sortimentů za organizační úroveň 3' },
      { value: 'VYROBA_VYKAZ_SKLADU_DETAIL_ORG_UR2', label: 'Sumář dle sortimentů za organizační úroveň 2' },
      { value: 'VYROBA_VYKAZ_SKLADU_DETAIL_ORG_UR1', label: 'Sumář dle sortimentů za organizační úroveň 1' },
      { value: 'VYROBA_VYKAZ_SKLADU_DETAIL_ORG_UR0', label: 'Sumář dle sortimentů za vše' },
      { value: 'VYROBA_VYKAZ_SKLADU_DETAIL_ORG_UR0_standard', label: 'Sumář za vše jen standard' },
      { value: 'VYROBA_VYKAZ_SKLADU_DETAIL_ORG_UR0_JL', label: 'Sumář J/L za vše' },
      { value: 'VYROBA_VYKAZ_SKLADU_DETAIL_ORG_UR0_', label: 'Sumář za vše' }
    ];
    this.selectedPodsestava = this.podsestavy[0].value;
  }

  print(): void {
    this.service.exportToken().subscribe(x => {
      window.open("api/Pestebni/ExportSklad/?parameters=" + (window as any).EQView.getContext().getQuery().toJSON() + "&frx=" + this.selectedPodsestava + "&rok=" + this.rok + "&mesic=" + this.mesic + "&token=" + x.data);
    });
  }

  goBack() {
    this.location.back();
  }
}
