import { Component, TemplateRef, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { SortEvent } from 'primeng/api';
import { formatDate } from '@angular/common';

export class ProPlaTableColumn {
  field: string;
  header: string;
  sortable: boolean = false;
  comparer: ((v1: any, v2: any) => number);
  template: TemplateRef<any>;
}

@Component({
  selector: 'propla-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})
export class TableComponent {
  @ViewChild('dt') dt: Table;
  myBodyTemplate: TemplateRef<any>;

  @Input() sortField: string = null;
  @Input() sortOrder: number = 1;
  @Input() rows: number = 10;
  @Input() disabledFilterFields: any[] = [];

  @Input() styleClass: string = null;

  constructor() { }

  private _filterGlobal: string = undefined;
  @Input() set filterGlobal(value: string) {
    if (value != this._filterGlobal) {
      this._filterGlobal = value;
      this.dt.filterGlobal(value, "contains");
    }
  }

  //aktuálně vybrané řádky
  selectedRows: any = [];

  @Input() cols: any[];
  @Input() sortFunction: Function;

  //Mód výběru
  _selectionMode: string = null;//multiple //single
  @Input() set selectionMode(value: string) {
    this._selectionMode = value;
  }

  //Zobrazení checkboxů pro výběr řádků
  _checkboxes: boolean = false;
  @Input() set checkboxes(value: boolean) {
    if (value != void 0) {
      this._checkboxes = value;
    }
  }

  rowClicked: boolean = false;
  loading: boolean = true;
  totalRecords: number;

  //seznam záznamů pro zobrazení
  _itemsToShow: any[];
  set itemsToShow(value: any[]) {
    this._itemsToShow = value;
    if (value != null)
      this.totalRecords = value.length;
  }

  @Output() dataLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() sorted: EventEmitter<any> = new EventEmitter();
  //seznam všech záznamů
  _allItems: any[];
  @Input() set allItems(value: any[]) {
    this._allItems = value;
    this.itemsToShow = this._allItems;
    this.unselectAll();
    if (this._allItems != void 0) {
      this.loading = false;
      this.dataLoaded.emit(true);
    }
  }

  @Output() rowSelected: EventEmitter<any> = new EventEmitter();
  onRowSelect(event): void {
    if (this._selectionMode == 'multiple') {
      this.rowSelected.emit(this.selectedRows);
    }
    else {
      this.rowSelected.emit(event.data);
    }
  }

  @Output() rowUnSelected: EventEmitter<any> = new EventEmitter();
  onRowUnselect(event): void {
    if (this._selectionMode == 'multiple') {
      this.rowUnSelected.emit(this.selectedRows);
    }
    else {
      this.rowUnSelected.emit(event.data);
    }
  }

  setRowStyle(rowData: any): string {
    let result = "";
    if (rowData['disabled'] != null && rowData['disabled'])
      result += "disabledRow";

    if (rowData['rowClass'] != null)
      result += rowData['rowClass'];
    return result;
  }

  unselectAll(): void {
    this.selectedRows = [];
  }

  selectRowByGuid(id: string, idName: string = 'guid'): void {
    let selected = this._allItems.filter(item => item[idName] == id);
    if (selected != void 0) {
      this.selectedRows = selected;
      this.selectionChanged.emit(this.selectedRows);
      this.setPage();
    }
  }

  /**
   * Obsah metody sortFunction komponenty tabulky primeng
   * @param event
   */
  sortData(event: SortEvent) {
    if (this.sortFunction) {
      return this.sortFunction.call(this.sortFunction, event);
    } else {
      event.data.sort((data1, data2) => {
        let value1 = data1[event.field];
        let value2 = data2[event.field];
        return (event.order * this.SortValues(value1, value2));
      });
    }
  }

  private SortValues(val1: any, val2: any): number {
    let result = null;
    if (val1 == null && val2 != null)
      result = -1;
    else if (val1 != null && val2 == null)
      result = 1;
    else if (val1 == null && val2 == null)
      result = 0;
    else if (typeof val1 !== 'number' && typeof val2 !== 'number' && !isNaN(Date.parse(val1.split('.').reverse().join('-')))
      && !isNaN(Date.parse(val2.split('.').reverse().join('-')))) {
      let d1 = new Date(formatDate(val1.split('.').reverse().join('-'), "yyyy-MM-dd", "cs", "locale"));
      let d2 = new Date(formatDate(val2.split('.').reverse().join('-'), "yyyy-MM-dd", "cs", "locale"));
      result = (d1 < d2) ? -1 : (d1 > d2) ? 1 : 0;
    }
    else if (typeof val1 === 'string' && typeof val2 === 'string')
      result = val1.localeCompare(val2);
    else
      result = (val1 < val2) ? -1 : (val1 > val2) ? 1 : 0;

    return result;
  }


  @Output() selectionChanged: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Handler změny vybraných řádků.
   * @param event
   */
  onSelectionChange(event) {
    this.selectionChanged.emit(this.selectedRows);
  }

  /**
   * Přejde na stránku s vybraným řádkem
   * */
  setPage(): void {
    var items = (this.dt != void 0 && this.dt.filteredValue != void 0)? this.dt.filteredValue : this._allItems;

    if (items != void 0 && this.selectedRows != void 0) {
      let index;
      if (Array.isArray(this.selectedRows))//u pole použít první položku
        index = items.indexOf(this.selectedRows[0]);
      else
        index = items.indexOf(this.selectedRows);

      if (index != -1) {
        this.dt.first = (Math.floor(index / this.dt.rows) * this.dt.rows);
        this.dt.firstChange.emit(this.dt.first);
      }      
    }
  }

  /**
   * Volá se pří seřazení tabulky
   * */
  onSort(): void {
    this.sorted.emit()
  }

  /**
   * Vrátí seřaditelnou vlastnost sloupečku
   * @param col
   */
  sortableColumn(col) {
    if (this.disabledFilterFields.includes(col.field)) return null;
    else return col.field;
  }
}
