import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { TreeNode } from 'primeng/api';
import { LhcDetailDto } from '../../Dto/Lhp/LhcDetailDto';
import { OddDetailDto } from '../../Dto/Lhp/OddDetailDto';
import { DilDetailDto } from '../../Dto/Lhp/DilDetailDto';
import { PorDetailDto } from '../../Dto/Lhp/PorDetailDto';
import { PskDetailDto } from '../../Dto/Lhp/PskDetailDto';
import { BzlDetailDto } from '../../Dto/Lhp/BzlDetailDto';
import { OpDetailDto } from '../../Dto/Lhp/OpDetailDto';
import { JpDetailDto } from '../../Dto/Lhp/JpDetailDto';
import { EtazDetailDto } from '../../Dto/Lhp/EtazDetailDto';
import { DrevinDetailDto } from '../../Dto/Lhp/DrevinDetailDto';
import { ARozdeleniLesaDetailDto } from '../../Dto/Lhp/ARozdeleniLesaDetailDto';
import { PorTreeDto } from '../../Dto/Lhp/PorTreeDto';

@Injectable({
  providedIn: 'root'
})
export class KnihaService {
  constructor(
    private http: HttpClient,
  ) { }

  private apiKnihaUrl = 'api/lhp/Kniha/';

  getLhcTree(kod: string, guid: string): Observable<TreeNode[]> {
    return this.http.get<TreeNode[]>(this.apiKnihaUrl + kod + "/" + guid + "/LhcTree");
  }

  getJprlTree(node: TreeNode): Observable<TreeNode[]> {
    return this.http.get<TreeNode[]>(this.apiKnihaUrl + node.data.guid + "/" + node.type + "/LoadJprl");
  }

  /**
   * Vrátí detail LHC
   * @param guid - LHC guid
   */
  getLhc(guid: string): Observable<LhcDetailDto>;
  /**
   * Vrátí detail LHC
   * @param lhcKod
   * @param guid - LHC guid
   */
  getLhc(lhcKod: string, guid: string): Observable<LhcDetailDto>;

  getLhc(lhcKodOrLhcGuid: string, guid?: string): Observable<LhcDetailDto> {
    if (guid == void 0) return this.http.get<LhcDetailDto>(this.apiKnihaUrl + "lhc/" + lhcKodOrLhcGuid);
    return this.http.get<LhcDetailDto>(this.apiKnihaUrl + lhcKodOrLhcGuid + "/" + guid);
  }

  /**
   * Vrátí detail oddělení
   * @param guid - guid oddělení
   */
  getOdd(guid: string): Observable<OddDetailDto>;
  /**
   * Vrátí detail oddělení
   * @param lhcKod
   * @param odd
   * @param guid - guid oddělení
   */
  getOdd(lhcKod: string, odd: string, guid: string): Observable<OddDetailDto>

  getOdd(lhcKodOrOddGuid: string, odd?: string, guid?: string): Observable<OddDetailDto> {
    if (guid == void 0) return this.http.get<OddDetailDto>(this.apiKnihaUrl + "odd/" + lhcKodOrOddGuid);
    return this.http.get<OddDetailDto>(this.apiKnihaUrl + lhcKodOrOddGuid + "/" + odd + "/" + guid);
  }

  /**
   * Vrátí detail dílce
   * @param guid
   */
  getDil(guid: string): Observable<DilDetailDto>;
  /**
   * Vrátí detail dílce
   * @param lhcKod
   * @param odd
   * @param dil
   * @param guid - guid dílce
   */
  getDil(lhcKod: string, odd: string, dil: string, guid: string): Observable<DilDetailDto>;

  getDil(lhcKodOrDilGuid: string, odd?: string, dil?: string, guid?: string): Observable<DilDetailDto> {
    if (guid == void 0) return this.http.get<DilDetailDto>(this.apiKnihaUrl + "dil/" + lhcKodOrDilGuid);
    return this.http.get<DilDetailDto>(this.apiKnihaUrl + lhcKodOrDilGuid + "/" + odd + "/" + dil + "/" + guid);
  }

  /**
   * Vrátí detail porostu
   * @param guid
   */
  getPor(guid: string): Observable<PorDetailDto>;
  /**
   * Vrátí detail porostu
   * @param lhcKod
   * @param odd
   * @param dil
   * @param por
   * @param guid
   */
  getPor(lhcKod: string, odd: string, dil: string, por: string, guid: string): Observable<PorDetailDto>;
 
  getPor(lhcKodOrPorGuid: string, odd?: string, dil?: string, por?: string, guid?: string): Observable<PorDetailDto> {
    if (guid == void 0) return this.http.get<PorDetailDto>(this.apiKnihaUrl + "por/" + lhcKodOrPorGuid);
    return this.http.get<PorDetailDto>(this.apiKnihaUrl + lhcKodOrPorGuid + "/" + odd + "/" + dil + "/" + por + "/" + guid);
  }

  /**
   * Vrátí detail porostní skupiny
   * @param guid
   */
  getPsk(guid: string): Observable<PskDetailDto>;
  /**
   * Vrátí detail porostní skupiny
   * @param lhcKod
   * @param odd
   * @param dil
   * @param por
   * @param psk
   * @param guid
   */
  getPsk(lhcKod: string, odd: string, dil: string, por: string, psk: string, guid: string): Observable<PskDetailDto>;

  getPsk(lhcKodOrPskGuid: string, odd?: string, dil?: string, por?: string, psk?: string, guid?: string): Observable<PskDetailDto> {
    if (guid == void 0) return this.http.get<PskDetailDto>(this.apiKnihaUrl + "psk/" + lhcKodOrPskGuid);
    return this.http.get<PskDetailDto>(this.apiKnihaUrl + lhcKodOrPskGuid + "/" + odd + "/" + dil + "/" + por + "/Psk/" + encodeURIComponent(psk) + "/" + guid);
  }

  /**
   * Vrátí detail bezlesí
   * @param guid
   */
  getBzl(guid: string): Observable<BzlDetailDto>;
  /**
   * Vrátí detail bezlesí
   * @param lhcKod
   * @param odd
   * @param dil
   * @param por
   * @param bzl
   * @param katuzeKod
   * @param guid
   */
  getBzl(lhcKod: string, odd: string, dil: string, por: string, bzl: string, katuzeKod: number, guid: string): Observable<BzlDetailDto>;

  getBzl(lhcKodOrBzlGuid: string, odd?: string, dil?: string, por?: string, bzl?: string, katuzeKod?: number, guid?: string): Observable<BzlDetailDto> {
    if (guid == void 0) return this.http.get<BzlDetailDto>(this.apiKnihaUrl + "bzl/" + lhcKodOrBzlGuid);
    return this.http.get<BzlDetailDto>(this.apiKnihaUrl + lhcKodOrBzlGuid + "/" + odd + "/" + dil + "/" + por + "/Bzl/" + bzl + "/" + katuzeKod + "/" + guid);
  }

  /**
   * Vrátí detail ostatního pozemku
   * @param guid
   */
  getOp(guid: string): Observable<OpDetailDto>;
  /**
   * Vrátí detail ostatního pozemku
   * @param lhcKod
   * @param odd
   * @param dil
   * @param por
   * @param op
   * @param katuzeKod
   * @param guid
   */
  getOp(lhcKod: string, odd: string, dil: string, por: string, op: string, katuzeKod: number, guid: string): Observable<OpDetailDto>;

  getOp(lhcKodOrOpGuid: string, odd?: string, dil?: string, por?: string, op?: string, katuzeKod?: number, guid?: string): Observable<OpDetailDto> {
    if (guid == void 0) return this.http.get<OpDetailDto>(this.apiKnihaUrl + "op/" + lhcKodOrOpGuid);
    return this.http.get<OpDetailDto>(this.apiKnihaUrl + lhcKodOrOpGuid + "/" + odd + "/" + dil + "/" + por + "/Op/" + op + "/" + katuzeKod + "/" + guid);
  }

  /**
   * Vrátí detail jiného pozemku
   * @param guid
   */
  getJp(guid: string): Observable<JpDetailDto>;
  /**
   * Vrátí detail jiného pozemku
   * @param lhcKod
   * @param odd
   * @param dil
   * @param por
   * @param jp
   * @param katuzeKod
   * @param guid
   */
  getJp(lhcKod: string, odd: string, dil: string, por: string, jp: string, katuzeKod: number, guid: string): Observable<JpDetailDto>;

  getJp(lhcKodOrJpGuid: string, odd?: string, dil?: string, por?: string, jp?: string, katuzeKod?: number, guid?: string): Observable<JpDetailDto> {
    if (guid == void 0) return this.http.get<JpDetailDto>(this.apiKnihaUrl + "jp/" + lhcKodOrJpGuid);
    return this.http.get<JpDetailDto>(this.apiKnihaUrl + lhcKodOrJpGuid + "/" + odd + "/" + dil + "/" + por + "/Jp/" + jp + "/" + katuzeKod + "/" + guid);
  }

  /**
   * Vrátí detail etáže
   * @param guid
   */
  getEtaz(guid: string): Observable<EtazDetailDto>;
  /**
   * Vrátí detail etáže
   * @param lhcKod
   * @param odd
   * @param dil
   * @param por
   * @param psk
   * @param etaz
   * @param guid
   */
  getEtaz(lhcKod: string, odd: string, dil: string, por: string, psk: string, etaz: string, guid: string): Observable<EtazDetailDto>;

  getEtaz(lhcKodOrEtazGuid: string, odd?: string, dil?: string, por?: string, psk?: string, etaz?: string, guid?: string): Observable<EtazDetailDto> {
    if (guid == void 0) return this.http.get<EtazDetailDto>(this.apiKnihaUrl + "etaz/" + lhcKodOrEtazGuid);
    return this.http.get<EtazDetailDto>(this.apiKnihaUrl + lhcKodOrEtazGuid + "/" + odd + "/" + dil + "/" + por + "/Psk/" + encodeURIComponent(psk) + "/" + etaz + "/" + guid);
  }

  /**
   * Vrátí detail dřeviny
   * @param guid
   */
  getDrevina(guid: string): Observable<DrevinDetailDto>;
  /**
   * Vrátí detail dřeviny
   * @param lhcKod
   * @param odd
   * @param dil
   * @param por
   * @param psk
   * @param etaz
   * @param drevina
   * @param guid
   */
  getDrevina(lhcKod: string, odd: string, dil: string, por: string, psk: string, etaz: string, drevina: string, guid: string): Observable<DrevinDetailDto>;

  getDrevina(lhcKodOrDrevinaGuid: string, odd?: string, dil?: string, por?: string, psk?: string, etaz?: string, drevina?: string, guid?: string): Observable<DrevinDetailDto> {
    if (guid == void 0) return this.http.get<DrevinDetailDto>(this.apiKnihaUrl + "drevina/" + lhcKodOrDrevinaGuid);
    return this.http.get<DrevinDetailDto>(this.apiKnihaUrl + lhcKodOrDrevinaGuid + "/" + odd + "/" + dil + "/" + por + "/Psk/" + encodeURIComponent(psk) + "/" + etaz + "/" + drevina + "/" + guid);
  }

  /**
   * Vrátí strom LHC až po porost, který obsahuje org. ur. guid
   * @param guid
   * @param orgUrGuid - 
   */
  getFromLhcToPorTree(guid: string, orgUrGuid?: string): Observable<TreeNode[]> {
    var param = new HttpParams();
    if (orgUrGuid != void 0) param = param.append("orgUrGuid", orgUrGuid);
    return this.http.get<TreeNode[]>(this.apiKnihaUrl + "FromLhcToPorTree/" + guid, { params: param });
  }

  /**
   * Strom porostu detailu knihy
   * @param guid
   */
  getPorTree(guid: string): Observable<PorTreeDto> {
    return this.http.get<PorTreeDto>(this.apiKnihaUrl + "PorTree/" + guid);
  }
}
