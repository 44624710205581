<span class="attachment-btn" [ngClass]="{'attachment-btn-disabled': prilohyCnt == 0 || disabled}"
      pTooltip="{{prilohyCnt == 0 ? 'Žádné přílohy' : 'Zobrazit přílohy náčrtu'}}" tooltipPosition="bottom"
      (click)="onAttachmentBtnClick()">
  <fa-layers>
    <fa-icon [icon]="faPaperclip" style="font-size: 1.5rem;"></fa-icon>
    <fa-layers-counter *ngIf="prilohyCnt > 0" [content]="prilohyCnt"></fa-layers-counter>
  </fa-layers>
</span>

<p-blockUI [blocked]="loading">
  <p-progressSpinner></p-progressSpinner>
</p-blockUI>

<p-dialog header="Přílohy náčrtu" [(visible)]="display" styleClass="nacrt-dialog"
          modal="true" [closeOnEscape]="true" [closable]="true" appendTo="body" [resizable]="false" [blockScroll]="true"
          [style]="{'width': '75rem'}">

  <p-tabView>
    <p-tabPanel header="Fotografie">
      <app-nacrt-priloha-foto></app-nacrt-priloha-foto>
    </p-tabPanel>
    <p-tabPanel header="Zvukové nahrávky" class="nacrt-priloha-audio">
      <app-nacrt-priloha-audio></app-nacrt-priloha-audio>
    </p-tabPanel>
  </p-tabView>

  <p-footer>
    <button pButton type="button" class="icon-btn hide-label-button"
            pTooltip="Zavřít" tooltipPosition="bottom"
            (click)="display = false">
      <fa-icon [icon]="faCheck"></fa-icon>
    </button>
  </p-footer>
</p-dialog>
