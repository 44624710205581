<p-panel styleClass="base-toolbar">
  <ng-template pTemplate="header">
    <div class="p-grid p-align-center">
      <div class="p-col"><h2 class="p-panel-title">Nastavení sestavy &ndash; <span [innerHTML]="typSestavy"></span></h2></div>
      <div class="p-col right">
        <button pButton title="Tisk" class="icon-btn p-button-success" label="Tisk" (click)="print()"><fa-icon [icon]="faPrint"></fa-icon></button>
        <!--<button pButton type="button" label="Spustit" class="icon-btn p-button-success" (click)="run()"><fa-icon [icon]="faPlay"></fa-icon></button>-->
      </div>
    </div>
  </ng-template>
  <div class="p-grid">
    <!--<div class="p-col-6 left">
    <b class="toolbar-label">Rok</b>
    <p-dropdown [options]="roky" name="rokyDropdown" [(ngModel)]="selectedRok" (onChange)="changeRok();"></p-dropdown>
  </div>-->
    <div class="p-col-12 left">
      <b class="toolbar-label">Podsestava: </b>
      <p-dropdown [options]="podsestavy" appendTo="body" name="podsestavyDropdown" [(ngModel)]="selectedPodsestava"></p-dropdown>
    </div>
    <div class="p-col-12 left">
      <app-general-eq id="filter" [model]="typEasyQuery"></app-general-eq>
    </div>
  </div>
</p-panel>
