import { DVyrKontrolaListDto } from './DVyrKontrolaListDto';

export class DVyrKontrolaDetailDto {
  guid: string;
  dokladTypKod: string;
  chyba: boolean;
  popis: string;
  poznamka: string;
  platnostOd: Date;
  platnostDo: Date;
  podminkaMeta: string;
  podminkaCloud: string;
  podminkaMobil: string;
}
