import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { faCopy, faDice } from '@fortawesome/free-solid-svg-icons';
import { PasswordUtils } from '../../../Utils/Uzivatel/password.utils';

export const REQUIRED_PASSWORD_LENGTH: number = 8;

/**
 * Komponenta pro validaci souhlasícího hesla 
*/
@Component({
  selector: 'app-validated-password',
  templateUrl: './validated-password.component.html',
  styleUrls: ['./validated-password.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ValidatedPasswordComponent),
    multi: true
  }]
})
export class ValidatedPasswordComponent implements OnInit, ControlValueAccessor {

  constructor
  (
    private passwordUtils: PasswordUtils
  )
  {
  }

  ngOnInit(): void {
  }

  @Input('value') _value: string = '';

  get value() {
    return this._value;
  }

  set value(val) {
    if (val != this._value) {
        this._value = val;
        this.onModelChange(val);
        this.onModelTouched();
    }
  }

  initialPasswordValue: string = undefined;
  checkPasswordValue: string = undefined;
  clipboardContainer: any;

  faDice = faDice;
  faCopy = faCopy;

  writeValue(obj: any): void {
    this._value = obj;
  }

  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onModelChange: any = () => { };
  onModelTouched: any = () => { };
  disabled: boolean = false;

  /**
   * Zpracuje generování hesla
  */
  onGeneratePassword(): void {
    this.initialPasswordValue = this.passwordUtils.getPassword(REQUIRED_PASSWORD_LENGTH);
    this.checkPasswordValue = this.initialPasswordValue;
    this.onPasswordInput(undefined);
  }

  /**
   * Zpracuje vložení hesla do jednoho z polí
   * @param event
   */
  onPasswordInput(event) {
    if (this.initialPasswordValue == this.checkPasswordValue)
      this.value = this.initialPasswordValue;
    else this.value = '';
  }
}
