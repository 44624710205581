<p-table [value]="audioSources" styleClass="p-datatable-sm">
  <ng-template pTemplate="header">
    <tr>
      <th class="dat-time-col">Datum a čas</th>
      <th>Přehrávač</th>
      <th style="width:5rem;">Smazat</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-item>
    <tr>
      <td>{{item.nazev}}</td>
      <td>
        <audio controls style="margin-top:0.3rem;">
          <source [src]="item.src" />
        </audio>
      </td>
      <td style="text-align: right; padding-right: 1rem;">
        <button pButton type="button" class="icon-btn hide-label-button"
                pTooltip="Smazat audio záznam" tooltipPosition="bottom"
                (click)="delete(item)">
          <fa-icon [icon]="faTrash"></fa-icon>
        </button>
      </td>
    </tr>
  </ng-template>
</p-table>

<p-confirmPopup key="{{CONFIRM_KEY}}"></p-confirmPopup>
