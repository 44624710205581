<div [ngClass]="'ciselnik-' + variant" *ngIf="edit && !readonly">
  <div class="p-inputgroup ciselnik-dropdown" style="width:100%">
    <input #kodInput [ngClass]="{'invalid-input-conditional': !(value || disabled)}"  type="text" pInputText [(ngModel)]="kod" (blur)="onBlur()" (keydown.space)="openDialog()" [disabled]="disabled" [id]="id" />
    <div class="p-inputgroup-addon text-addon" *ngIf="variant == 'full'" title="{{text}}">{{text}}</div>
    <span class="p-inputgroup-addon pi pi-angle-down" (click)="openDialog()" style="cursor:pointer" *ngIf="!disabled"></span>
  </div>
</div>
<p-dialog header="Výběr z číselníku" [(visible)]="display" [modal]="true" [baseZIndex]="10000" [style]="{width: '640px'}" (onShow)="onShowDialog()"
          (onHide)="onHideHandler()">
  <p-listbox #cisListBox [options]="data" [(ngModel)]="value" [filter]="true" [style]="{'width':'600px'}"
             (onChange)="onChangeListBox($event)" [listStyle]="{'max-height':'400px'}"></p-listbox>
</p-dialog>


<div class="p-inputtext ui-widget ui-state-default ui-corner-all ui-div-inputtext" *ngIf="!edit || readonly" [ngClass]="{'read-only-box': readonly}" title="{{getDetail()}}">{{getDetail()}} </div>
