import { EventEmitter, Injectable, Output } from "@angular/core";


/**
 * Service sloužící k aktualizaci stylu náčrtu v pinech ikon.
**/
@Injectable({
  providedIn: 'root'
})
export class NacrtyStyleInteractionService
{
  /**
   * Událost předávající název vrstvy, ze které se má vymazat vyrendrovaný pin pro ikony.
  **/
  @Output() RemovePinCanvas: EventEmitter<string> = new EventEmitter<string>();


  /**
   * Událost pro synchronní překreslení mapy.
  **/
  @Output() RenderMapSync: EventEmitter<any> = new EventEmitter<any>();


  /**
   * Vypuštění události pro odstranění vyrendrovaného canvasu pinu ikony.
   * @param name {string} název vrstvy
   */
  public removePinCanvas(name: string): void {
    this.RemovePinCanvas.emit(name);
  }


  /**
   * Synchronní překreslení mapy.
  **/
  renderMapSync(): void {
    this.RenderMapSync.emit();
  }
}
