import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Router } from '@angular/router';
import { faBook } from '@fortawesome/free-solid-svg-icons';
import { ConfirmationService } from 'primeng/api';
import { AutoComplete } from 'primeng/autocomplete';
import { EtazListDto } from 'src/app/Dto/Lhp/EtazListDto';
import { EtazVolbaDto } from 'src/app/Dto/Lhp/EtazVolbaDto';
import { LhcListDto } from 'src/app/Dto/Lhp/LhcListDto';
import { PskInfoDto } from 'src/app/Dto/Lhp/PskInfoDto';
import { LhpoService } from 'src/app/Services/Lhp/lhpo.service';

@Component({
  selector: 'app-etaz-input',
  templateUrl: './etaz-input.component.html',
  styleUrls: ['./etaz-input.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => EtazInputComponent),
    multi: true
  }]
})
export class EtazInputComponent implements ControlValueAccessor, OnInit {

  private _lhcGuid: string = null;
  private _jprlChoice: EtazVolbaDto;
  private _etazOrJprlDeleted: boolean = false;//Pokud došlo ke smazání etáže nebo JPRL, tak je potřeba odlišit od inicializace.
  private _newInit: boolean = true; //Pro změnu obsahu z venčí na už vytvořené komponentě
  private _lhcNewInitialized: boolean = false; //Odlišeni změny lhc od změny obsahu z venčí
  private _jprlNewInitialized: boolean = false; //Odlišeni změny jprl od změny obsahu z venčí
  private _valueWasSet: boolean = false;//Při nastavení formu dojde k naplnění value a tím k zacyklení. Proměnná zajistrí pouze jedno naplnění value.
  faBook = faBook;

  set lhcGuid(val: string) {
    if (val != this._lhcGuid) {
      this._lhcGuid = val;
    }
  }
  get lhcGuid(): string {
    return this._lhcGuid;
  }

  @Input() edit: boolean = true;

  // Úplný seznam etáží v rámci PSK
  etazeAutocomplete: EtazListDto[] = [];

  // Profiltrované návrhy pro autocomplete
  etazeNavrhy: EtazListDto[] = [];

  jprl: string = null;
  etaz: EtazListDto = null;
  alert: boolean = false;
  lhcInputId: string;
  jprlInputId: string;
  etazInputId: string;

  // proměnné pro implementaci rozhraní ControlValueAccessor
  onModelChange: any = () => { };
  onModelTouched: any = () => { };
  disabled: boolean = false;

  @Input('value') _value: string = null;
  @Input() visibileJprlBtn: boolean = false;
  @Input() orgUrGuid: string;//Omezení LHC podle orgur

  //Nastavý komponentu do výchozího stavu
  @Input() reset: EventEmitter<any> = new EventEmitter();

  /**
   * Id inputu v autocomplete
   **/
  @Input()
  set inputId(value: string) {
    this.etazInputId = value + "-etaz";
    this.lhcInputId = value + "-lhc";
    this.jprlInputId = value + "-jprl";
  }


  //Focus na input LHC
  focusLhc: EventEmitter<any> = new EventEmitter();

  get value() {
    return this._value;
  }

  set value(val) {
    if (!this._valueWasSet) {
      this._valueWasSet = true;
      this._workaround();
      let valueChanged: boolean = false;
      if (this._value != val || this._newInit) {
        if (val != void 0) {
          valueChanged = true;
        }
        else {
          this.etaz = null;
          this._jprlChoice.etaz = null;
          this._jprlChoice.etazGuid = null;
          if (!this._newInit && this._lhcNewInitialized)
            this.onChange.emit(this._jprlChoice);
          else if (!this._etazOrJprlDeleted) {
            this._etazOrJprlDeleted = false;
            this.lhcGuid = null;
          }

          if (!this._etazOrJprlDeleted) {
            this._etazOrJprlDeleted = false;
            this.jprl = null;
          }
        }
      } else if (this._etazOrJprlDeleted && this._lhcNewInitialized) {
        this._etazOrJprlDeleted = false;
        this.onChange.emit(this._jprlChoice);
      } else {
        this.onChange.emit(this._jprlChoice);
      }
      this._value = val;

      this.onModelChange(val);
      this.onModelTouched();
      if (valueChanged) {
        this._initData();
        if (this.etaz != void 0) {
          this._jprlChoice.etaz = this.etaz.etaz;
          this._jprlChoice.etazGuid = this.etaz.guid;
        }

        this.onChange.emit(this._jprlChoice);
      }
      if (this._newInit) {
        this._newInit = false;
      }
    } else {
      this._valueWasSet = false;
    }
  }

  @Output() onChange: EventEmitter<EtazVolbaDto> = new EventEmitter();

  // workaround na bug v autocomplete: https://github.com/primefaces/primeng/issues/3641
  @ViewChild('etazAutocomplete') autocomplete: AutoComplete;

  constructor(private lhpoService: LhpoService, private router: Router, private confirmationService: ConfirmationService) { }

  ngOnInit(): void {
    this.reset.subscribe(() => {
      this._newInit = true;
      this._lhcNewInitialized = false;
      this._jprlNewInitialized = false;
      this._reset();
      this.lhcGuid = null;
    });
    this._jprlChoice = new EtazVolbaDto();
    this._initData();
  }

  // Implementace rozhraní ControlValueAccessor
  writeValue(obj: any): void {
    this.value = obj;
  }

  // Implementace rozhraní ControlValueAccessor
  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  // Implementace rozhraní ControlValueAccessor
  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }

  // Implementace rozhraní ControlValueAccessor
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * Handler změny JPRL - nastavuje etáže pro autocomplete
   * @param psk
   */
  onJprlChange(psk: PskInfoDto): void {
    if (psk != undefined) {
      this._jprlChoice.odd = psk.odd;
      this._jprlChoice.dil = psk.dil;
      this._jprlChoice.por = psk.por;
      this._jprlChoice.psk = psk.psk;

      this.etazeAutocomplete = psk.etaze;
      if (this.etazeAutocomplete.length == 1) {
        this.etaz = this.etazeAutocomplete[0];
        this._jprlChoice.etaz = this.etaz.etaz;
        this._jprlChoice.etazGuid = this.etaz.guid;
        this.value = this.etazeAutocomplete[0].guid;
      } else if (this._jprlNewInitialized) {
        this.etaz = null;
        this.value = null;
      }
    } else {
      this.etazeAutocomplete = [];
      this.etaz = null;
      this.alert = false;
      this._etazOrJprlDeleted = true;
      this._reset();
      this.value = null;
    }
    this._jprlNewInitialized = true;
  }

  /**
   * Prohledání etáži pro nabídku autocomplete
   * @param event
   */
  hledej(event): void {
    this.etazeNavrhy = this.etazeAutocomplete.filter(x => x.etaz.startsWith(event.query));
  }

  /**
   * Zajistí dotažení informací i v případě, že uživatel zapsal etáž ručně.
   * @param event
   */
  onBlur(event) {
    if (event.target.value != void 0 && typeof event.target.value == 'string') {
      let toSearch = event.target.value;

      let etz = this.etazeAutocomplete.find(x => x.etaz == toSearch);

      if (etz != undefined) {
        this.etaz = etz;
        this.value = etz.guid;
        this.alert = false;
      }
      else {
        this.etaz = null;
        this._etazOrJprlDeleted = true;
        this.value = null;
        this.alert = true;
      }
    }
  }

  /**
   * Ošetření výběru ze seznamu autocomplete (potřebuji celé DTO, nikoli jen jednu položku)
   * @param value
   */
  onSelect(value: EtazListDto): void {
    this.value = value.guid;
    this.alert = false;
  }

  /**
   * Inicializace dat po změně vstupního GUIDu
   */
  private _initData(): void {
    if (this.value) {
      this.lhpoService.etazDetail(this.value).subscribe(res => {
        if (res != undefined) {
          this.etaz = {
            etaz: res.etaz, guid: res.guid, zakm: res.zakm
          };
          this._workaround();
          this.alert = false;

          this.jprl = "" + res.lhpGuids?.odd + res.lhpGuids?.dil + res.lhpGuids?.por + res.lhpGuids?.psk;
          if (!this._lhcNewInitialized)
            this.lhcGuid = res.lhcGuid;
        }
        else {
          this.etaz = null;
          this.alert = true;
        }
      });
    }
  }

  /**
   * workaround na bug v autocomplete: https://github.com/primefaces/primeng/issues/3641
   */
  private _workaround(): void {
    if (this.autocomplete != void 0)
      setTimeout(() => this.autocomplete.inputEL.nativeElement.value = (this.etaz != null ? this.etaz.etaz : ""));
  }

  /**
   *  Přesměruje na knihu
   * */
  openEtaz(): void {
    this.confirmationService.confirm({
      message: 'Přejete si otevřít detail etáže? Pokud zvolíte Ano, otevře se detail v novém okně, aby nedošlo ke ztrátě dat v rozpracovaném formuláři.',
      accept: () => {
        window.open('kniha/lhcKod/odd/dil/por/psk/psk/etaz/' + this.value, '_blank');
      }
    });
  }

  /**
   * Handler výběru lhc pro získání kódu
   **/
  lhcChanged(lhc: LhcListDto): void {
    if (lhc != void 0) {
      this._jprlChoice = new EtazVolbaDto();
      if (!this._newInit) {
        this._jprlChoice.lhc = lhc.lhcKod;
        if (this._lhcNewInitialized) {
          this._reset();
          this.value = null;
        }
      }
      this.onChange.emit(this._jprlChoice);
      this._lhcNewInitialized = true;
    } else if (this._lhcNewInitialized) {//pokud došlo k vymazání
      this._jprlChoice.lhc = null;
      this._reset();
      this.value = null;
    }
  }

  //vymaže ceý obsah kromě lhc
  private _reset(): void {
    this._jprlChoice.odd = null;
    this._jprlChoice.dil = null;
    this._jprlChoice.por = null;
    this._jprlChoice.psk = null;
    this._jprlChoice.etaz = null;
    this._jprlChoice.etazGuid = null;
    this.etaz = null;
    this.jprl = null;
    this._valueWasSet = false;
  }

  /**
   * Focus na input LHC
   * */
  focus(): void {
    this.focusLhc.emit();
  }
}
