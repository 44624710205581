<p-panel>
  <ng-template pTemplate="header">
    <div class="p-grid p-align-center">
      <div class="p-col"><span class="p-panel-title">Seznam viditelných položek</span></div>
      <div class="p-col right">
        <button pButton type="button" label="Vše" (click)="vse()"></button>
        <button pButton type="button" label="Nic" (click)="nic()"></button>
      </div>
    </div>
  </ng-template>
  <div class="p-grid">
    <div class="p-col-fixed" style="width: 15em;" *ngFor="let i of [7, 14, 21, 28, 35]; last as isLast">
      <div class="polozka" *ngFor="let pol of polozky.slice(i - 7, isLast ? undefined : i)">
        <p-checkbox name="pol-check-group" [value]="pol.value" [label]="pol.label" [(ngModel)]="value" [inputId]="'chb-' + pol.value"></p-checkbox>
      </div>
    </div>
    <div class="p-col-fixed" style="width: 18em; margin-top: -0.5em;">
      <p-fieldset [style]="{'padding-top': '0'}">
        <ng-template pTemplate="header">
          <p-checkbox name="pol-check-group" [value]="polozkyMaterialy[0].value" [label]="polozkyMaterialy[0].label"
                      [(ngModel)]="value" [inputId]="'chb-' + polozkyMaterialy[0].value" (onChange)="onGroupChange('Materialy', $event.checked)"></p-checkbox>
        </ng-template>
        <div class="polozka" *ngFor="let pol of polozkyMaterialy.slice(1)">
          <p-checkbox name="pol-check-group" [value]="pol.value" [label]="pol.label" [(ngModel)]="value" [inputId]="'chb-' + pol.value" [disabled]="!enableMaterialy"></p-checkbox>
        </div>
      </p-fieldset>
      <p-fieldset [style]="{'padding-top': '0'}">
        <ng-template pTemplate="header">
          <p-checkbox name="pol-check-group" [value]="polozkyProstredky[0].value" [label]="polozkyProstredky[0].label"
                      [(ngModel)]="value" [inputId]="'chb-' + polozkyProstredky[0].value" (onChange)="onGroupChange('Prostredky', $event.checked)"></p-checkbox>
        </ng-template>
        <div class="polozka" *ngFor="let pol of polozkyProstredky.slice(1)">
          <p-checkbox name="pol-check-group" [value]="pol.value" [label]="pol.label" [(ngModel)]="value" [inputId]="'chb-' + pol.value" [disabled]="!enableProstredky"></p-checkbox>
        </div>
      </p-fieldset>
    </div>
  </div>
</p-panel>
