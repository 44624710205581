import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { ConfirmationService, MessageService } from 'primeng/api';
import { NacrtMapItemDto, NacrtMapItemPostSaveDto } from '../../../Dto/Nacrty/NacrtMapItemDto';
import { NacrtProjektSourceDto } from '../../../Dto/Nacrty/NacrtProjectSourceDto';
import { NacrtProjektDto } from '../../../Dto/Nacrty/NacrtProjektDto';
import { LayersInteractionService } from '../../../Services/Mapa/layers-interaction.service';
import { MapInteractionService } from '../../../Services/Mapa/map-interaction.service';
import { MapServices } from '../../../Services/Mapa/map.service';
import { NacrtyInteractionService } from '../../../Services/Nacrty/nacrty-interaction.service';
import { SyncService } from '../../../Services/Sync/sync.service';
import { ConstructMapLayerUtils } from '../../../Utils/Mapa/construnct-map-layer.utils';
import { MapToTreeDtoUtils } from '../../../Utils/Mapa/map-to-tree-dto.utils';
import { NacrtEditFormComponent } from '../nacrt-edit-form/nacrt-edit-form.component';


/**
 * Komponenta editace náčrtů v seznamu náčrtů (nikoli v mapě! - viz MapInfoNacrtEditComponent).
**/
@Component({
  selector: 'app-nacrt-edit',
  templateUrl: './nacrt-edit.component.html',
  styleUrls: ['./nacrt-edit.component.css'],
  providers: [NacrtyInteractionService, LayersInteractionService]
})
export class NacrtEditComponent {

  /**
   * Seznam dostupných projektů náčrtů.
  **/
  _nacrtProjects: NacrtProjektSourceDto[] = [];
  @Input() set nacrtProjects(value: NacrtProjektDto[]) {
    if (value != void 0) {
      this._nacrtProjects = this.mapToTreeDtoUtils.fromProjectDetailToProjSourceDto(value);
    }
  }


  /**
   * Viditelnost editace.
  **/
  @Input() visible: boolean = false;


  /**
   * Aktualizovaná data náčrtu.
  **/
  @Output() NacrtUpdated: EventEmitter<NacrtMapItemDto> = new EventEmitter<NacrtMapItemDto>();


  /**
   * Komponeta editačního formuláře metadat náčrtů.
  **/
  @ViewChild('editForm') editForm: NacrtEditFormComponent;


  constructor(
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private mapInteractionService: MapInteractionService,
    private nacrtyInteractionService: NacrtyInteractionService,
    private syncService: SyncService,
    private mapServices: MapServices,
    private constructUtils: ConstructMapLayerUtils,
    private mapToTreeDtoUtils: MapToTreeDtoUtils)
  {
    this.mapInteractionService.EditFeature.subscribe(itemToEdit => {
      this._init(itemToEdit);
    });

    this.mapInteractionService.DiscardChanges.subscribe(originalItem => {
      if (originalItem == void 0) {
        this.editForm?.reset();
        this.formData = null;
        this._item = null;
      }
      else {
        this._init(originalItem);
      }
    });
  }

  readonly TOAST_KEY: string = 'nacrtEditToast';
  readonly CONFIRM_KEY: string = 'nacrtEditConfirm';
  private _item: NacrtMapItemDto = null;
  private _originalItem = null;
  private _syncData: any = null;
  blocked: boolean = false;
  formData: any = null;
  faCheck = faCheck;
  faTimes = faTimes;
  featureType: string = null;


  /**
   * Zahájení editace náčrtu.
   * @param itemToEdit {NacrtMapItemDto} data náčrtu k editaci
   */
  private _init(itemToEdit: NacrtMapItemDto): void {
    this.nacrtyInteractionService.clearPrilohy();
    this.nacrtyInteractionService.initEdit(itemToEdit, false);
    this._getNacrtData(itemToEdit);
  }


  /**
   * Získá data editovaného náčrtu přes sync api.
   * @param value {NacrtMapItemDto} editovaný náčrt
   */
  private _getNacrtData(value: NacrtMapItemDto): void {
    if (value != void 0) {
      this._item = value;
      
      this.featureType = this.constructUtils.convertFromSource([{ id: '', modul: '', wkt: this._item.wkt }])[0].getGeometry().getType();

      //kopie původního stavu náčrtu - použije se v případě rušení provedení změn
      this._originalItem = {};
      Object.keys(this._item).forEach(key => this._originalItem[key] = this._item[key]);

      this.syncService.get(value.id).subscribe(resp => {
        this._syncData = resp;
        this._fillForm(this._syncData);
      });
    }
  }


  /**
   * Uložení změn náčrtu.
  **/
  save(): void {
    this.blocked = true;

    Object.keys(this.editForm.formValue).forEach(key => {
      this._syncData['nacrt'][key] = this.editForm.formValue[key];
    });

    let dataToSync: any[] = [this._syncData];
    if (this.nacrtyInteractionService.prilohy != void 0) {
      let prilohyPendingChanges = this.nacrtyInteractionService.prilohy.filter(priloha => !priloha._dirty || priloha.sync.d);
      dataToSync = dataToSync.concat(prilohyPendingChanges);
    }

    this.syncService.post(dataToSync).subscribe(this._nacrtSavePostProcess.bind(this));
  }


  /**
   * Postprocess uložení změn náčrtu.
   * @param resp {any} sync post response
   */
  private _nacrtSavePostProcess(resp: any): void {
    this.blocked = false;

    if (resp.isError) {
      let messages = resp.errors.map(err => { return { severity: 'error', summary: 'Chyba', detail: err.text, key: this.TOAST_KEY }; });
      this.messageService.addAll(messages);
    }
    else if (resp.isConflict) {
      this.messageService.add({
        key: this.TOAST_KEY, summary: 'Konflikt', severity: 'error',
        detail: 'Při ukládání náčrtu došlo ke konfliktu. Prosím obnovte stránku a proveďte změny znovu.'
      });
    }
    else {
      this.mapServices.getMapItemById(this._item.id, 'Nacrty').subscribe(resp => {
        this.NacrtUpdated.emit(resp as NacrtMapItemDto);
        this._init(resp as NacrtMapItemDto);
        this.editForm.markAsNotDirty();
        this.messageService.add({ severity: 'success', summary: 'Ok', detail: 'Náčrt byl aktualizován.', key: this.TOAST_KEY });
      });
    }
  }


  /**
   * Handler zrušení editace náčrtu.
  **/
  cancel(event): void {
    this.confirmationService.confirm({
      target: event.currentTarget,
      key: this.CONFIRM_KEY,
      message: 'Opravdu si přejete zahodit provedené změny? Změny nebudou uloženy.',
      icon: 'pi pi-exclamation-triangle',
      accept: this.discardAccepted.bind(this),
      acceptLabel: 'Zahodit změny',
      acceptButtonStyleClass: 'p-button-danger',
      reject: () => { },
      rejectLabel: 'Pokračovat v práci'
    });
  }


  /**
   * Potvrzení smaznání změn editovaného náčrtu.
  **/
  discardAccepted(): void {
    this.messageService.add({ severity: 'success', summary: 'Změny náčrtu nebyly uloženy.', key: this.TOAST_KEY });
    this.mapInteractionService.discardChanges((this._originalItem as NacrtMapItemPostSaveDto));
  }


  /**
   * Sestavení dat pro formulář metadat.
   * @param data {any} sync data náčrtu
   */
  private _fillForm(data: any): void {
    this.formData = {
      nacrtProjektGuid: data.nacrt.nacrtProjektGuid,
      popis: data.nacrt.popis,
      poznamka: data.nacrt.poznamka,
      cisloHoliny: data.nacrt.cislo
    }
    this.editForm.markAsNotDirty();
  }


  /**
   * True, pokud nejsou u náčrtu provedeny změny (formulář, přílohy).
  **/
  canDeactivate(): boolean {
    if (this.editForm == void 0 || this.nacrtyInteractionService.prilohy == void 0) {
      return true;
    }
    return !this.editForm.isDirty && this.nacrtyInteractionService.prilohy.filter(priloha => !priloha._dirty || priloha.sync.d).length == 0;
  }
}
