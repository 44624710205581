/**
 * Enum názvu parametrů při navigaci do knihy
 * */
export enum KnihaNavigationParams {
  lhc = 'lhc',
  por = 'por',
  psk = 'psk',
  etaz = 'eraz',
  drevina = 'drevina',
  bzl = 'bzl',
  jp = 'jp',
  op = 'op'
}
