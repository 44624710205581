import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { faBuilding } from '@fortawesome/free-regular-svg-icons';
import { faCog, faQuestion, faSignOutAlt, faUser, faUserCog } from '@fortawesome/free-solid-svg-icons';
import { MenuItem, MessageService } from 'primeng/api';
import { UserInfoDto } from 'src/app/Dto/Auth/UserInfoDto';
import { AuthService } from 'src/app/Services/Auth/auth.service';
import { AuthUtils } from '../../../Auth/auth.utils';
import { ReleaseNotesDto } from '../../../Dto/Core/ReleaseNotesDto';
import { MessagesUtils } from '../../../Utils/Shared/messages.utils';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.css']
})
export class UserInfoComponent implements OnInit {

  private _Key: string = "user-info-toast";

  public readonly USER_INFO: string = "userInfo"; //konstanta pro locastorage

  /**
   * Klíč pro uložení poslední verze, jejíž release notes si uživatel zobrazil, v localstorage
   */
  public readonly VISITED_RELEASE_KEY: string = "visitedRelease";

  constructor(private authService: AuthService, private router: Router, private messageService: MessageService, private authUtils: AuthUtils, private eRef: ElementRef) { }

  info: UserInfoDto;

  faSignOutAlt = faSignOutAlt;
  faUserCog = faUserCog;
  faUser = faUser;
  faBuilding = faBuilding;

  faCog = faCog;
  faQuestion = faQuestion;

  /**
   * Položky nabídky nápovědy
   */
  items: MenuItem[];

  /**
   * Viditelný informační panel (releaseNotes, permissions, help, about)
   */
  visibleInfoPanel: string = null;

  /**
   * Příznak, že máme nějakou novinku (ovlivňuje vzhled tlačítka)
   */
  releaseNotesAlert: boolean = false;

  /**
   * Poslední verze release notes, kterou uživatel viděl (načtená z localStorege)
   */
  visitedReleaseVersion: string = null;

  /**
   * Poslední verze dostupná v panelu s novinkami (načtená z webAPI)
   */
  lastReleaseVersion: string = null;

  /**
   * Slouží pro zobrazení/skrytí komponenty
   */
  loaded = false;

  ngOnInit() {
    this.loadInfo();

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.authUtils.isLoggedIn().then(res => {
          if (res) {
            let userInfo: any = JSON.parse(localStorage.getItem(this.USER_INFO));
            if (userInfo != void 0) {
              this.info = userInfo;
              this.loaded = true;
            } else {
              this.loadInfo();
            }
          } else {
            this._clearStorage();
          }
        });
      }

    });

    this.authService.loggedOut.subscribe(() => {
      this._clearStorage();
    });

    this.items = [
      { label: 'Co je nového?', command: () => { this.releaseNotesOpen(); } },
      { label: 'Přístupová práva', command: () => { this.visibleInfoPanel = 'permissions'; } },
      { label: 'Nápověda', command: () => { this.visibleInfoPanel = 'help'; } },
      { label: 'O aplikaci', command: () => { this.visibleInfoPanel = 'about'; } }
    ];

    this.visitedReleaseVersion = localStorage.getItem(this.VISITED_RELEASE_KEY);
  }


  /**
   * Handler kliknutí do dokumentu. 
   * Rozpozná kliknutí mimo tuto komponentu a zavře panel.
   * @param event
   */
  @HostListener('document:click', ['$event'])
  public clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.visibleInfoPanel = null;
    }
  }

  private _clearStorage(): void {
    localStorage.removeItem(this.authUtils.JWT_TOKEN);
    localStorage.removeItem(this.authUtils.WIN_TOKEN);
    localStorage.removeItem(this.authUtils.REFRESH_TOKEN);
    localStorage.removeItem(this.USER_INFO);
    sessionStorage.clear();
    this.loaded = false;
  }

  loadInfo(): any {
    this.authService.info()
      .subscribe(res => {
        if (res != void 0) {
          this.info = res;
          this.loaded = true;
          localStorage.setItem(this.USER_INFO, JSON.stringify(res));
        }
      })
  }

  signOut(): void {
    this.authService.signout()
      .subscribe(res => {
        if (res != void 0) {
          if (res.success) {
            this.router.navigate(['/login'], { queryParams: { page: "plocha" } });
          } else {
            for (let msg of res.messages) {
              this.messageService.add({ key: this._Key, severity: 'error', summary: 'Chyba', detail: msg, life: MessagesUtils.TOAST_LIFE });
            }
          }
        }
      });
  }

  changePass(): void {
    this.router.navigate(['/pass']);
  }

  /**
   * Reakce na načtení historie verzí (ošetří zvýraznění tlačítka, pokud je něco nového).
   * @param data
   */
  releaseNotesLoaded(data: ReleaseNotesDto[]) {
    if (data != undefined && data.length > 0) {
      this.lastReleaseVersion = data[0].cisloVerze;
      this.releaseNotesAlert = (this.lastReleaseVersion != this.visitedReleaseVersion);
    }
  }

  /**
   * Obsluha otevření panelu s historií verzí
   */
  releaseNotesOpen() {
    this.visibleInfoPanel = 'releaseNotes';
    this.releaseNotesAlert = false;
    localStorage.setItem(this.VISITED_RELEASE_KEY, this.lastReleaseVersion);
    // tady by se teď nabízelo zvýšit i this.visitedReleaseVersion, ale neudělám to, kvůli odlišení "nových" verzí v panelu
  }
}
