<p-table #dt [value]="_itemsToShow" [columns]="cols" [loading]="loading" 
         [paginator]="true" [rows]="rows"
         [selectionMode]="_selectionMode" [(selection)]="selectedRows"
         (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)" (selectionChange)="onSelectionChange($event)"
         [rowsPerPageOptions]="[10,20,30]"
         (sortFunction)="sortData($event)" [sortField]="sortField" [sortOrder]="sortOrder"
         [totalRecords]="totalRecords"
         (onSort)="onSort()" [styleClass]="styleClass">
  <ng-template pTemplate="colgroup" let-columns>
    <colgroup>
      <col *ngIf="_checkboxes" />
      <col *ngFor="let col of columns" [style.width]="col.width" [class]="col.class">
    </colgroup>
  </ng-template>

  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngIf="_checkboxes" style="width: 3.6em; text-align:center;">
        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
      </th>
      <th *ngFor="let col of columns" [pSortableColumn]="sortableColumn(col)" [ngClass]="'th-' + col.field">
        <div *ngIf="!disabledFilterFields.includes(col.field)" class="sortable-header">
          {{col.header}}
          <p-sortIcon [field]="col.field" ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
        </div>
        <div *ngIf="disabledFilterFields.includes(col.field)">
          {{col.header}}
        </div>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr [pSelectableRow]="rowData" [ngClass]="setRowStyle(rowData)">
      <td *ngIf="_checkboxes" style="text-align:center;">
        <p-tableCheckbox [value]="rowData" [index]="rowIndex"></p-tableCheckbox>
      </td>
      <td *ngFor="let col of columns" [ngClass]="'td-' + col.field">
        <div *ngIf="col.template">
          <ng-container *ngTemplateOutlet="col.template; context: {$implicit: rowData, column: col}"></ng-container>
        </div>
        <div *ngIf="!col.template">{{rowData[col.field]}}</div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="paginatorright" let-state>
    <div class="paginator-right">
      Zobrazeno {{state.totalRecords < 1 ? 0 : state.first + 1}} -
      {{
      state.totalRecords > 0 ? ((state.rows+ state.first) <= state.totalRecords ? (state.rows + state.first) :
      state.totalRecords) : 0
      }} z {{state.totalRecords ? state.totalRecords : 0}} záznamů
    </div>
  </ng-template>
</p-table>
