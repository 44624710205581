<p-messages severity="success" *ngIf="display">
  <ng-template pTemplate>
    <div class="p-grid">
      <div class="p-col-10">
        <b>Ovládání při kreslení</b>
      </div>
      <div class="p-col-2" style="position: relative;">
        <button pButton type="button" icon="pi pi-times" class="p-button-rounded p-button-plain p-button-text" (click)="display = false"></button>
      </div>
      <div class="p-col-12" *ngIf="geometryType == 'Point'">
        Bod vložíte kliknutím do mapy.
      </div>
      <div *ngIf="geometryType != 'Point'" class="p-col-5" pTooltip="Dvojklik levým tlačítkem myši" tooltipPosition="left">
        <span class="x2">2x</span>
        <img src="assets/mouse-left-button.svg" alt="Levé tlačítko myši" />
      </div>
      <div *ngIf="geometryType != 'Point'" class="p-col-7">
        Dokončení plochy nebo linie
      </div>
      <div *ngIf="geometryType != 'Point'" class="p-col-5" pTooltip="Klávesa Alt plus levé tlačítko myši" tooltipPosition="left">
        <span class="p-button-raised p-button p-button-secondary p-mr-1"><span class="p-button-label">Alt</span></span>
        <span class="x2">+</span>
        <img src="assets/mouse-left-button.svg" alt="Levé tlačítko myši" />
      </div>
      <div *ngIf="geometryType != 'Point'" class="p-col-7">
        Odstranění lomového bodu dokončené plochy nebo linie
      </div>
    </div>
  </ng-template>
</p-messages>
