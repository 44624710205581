<app-block-ui [block]="blockedPrepocet" [target]="skodaVysledek"></app-block-ui>
<p-panel #skodaVysledek [header]="skodaHeader" [style]="{'margin-bottom': '1em'}">
  <div class="p-grid p-align-center"><div class="p-col-12">&nbsp;</div></div> <!--tfuj tajbl, ale ono to funguje-->
  <div class="p-grid p-align-center">
    <div class="p-col-4">
      Celkem
    </div>
    <div class="p-col-8">
      <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{vypocet?.vysledek}}</span>
    </div>
  </div>
  <div class="p-grid p-align-center">
    <div class="p-col-4">
      Na 1 ks
    </div>
    <div class="p-col-8">
      <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{vypocet?.vysledekNaKus}}</span>
    </div>
  </div>
</p-panel>
