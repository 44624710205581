<div>
  <button pButton type="button" label="Smazat" class="icon-btn p-button-danger" (click)="displayDeleteLhc = true" [disabled]="!data"><fa-icon [icon]="faTrash"></fa-icon></button>

  <p-dialog header="Smazat LHC" [(visible)]="displayDeleteLhc" [modal]="true" [style]="{width: '900px'}">

    <p>Skutečně si přejete smazat LHC {{data?.lhcKod}}?</p>

    <p-footer>
      <button pButton type="button" class="icon-btn p-button-danger" label="Smazat" (click)="deleteLhcCheck()"><fa-icon [icon]="faTrash"></fa-icon></button>
      <button type="button" pButton icon="pi pi-times" label="Zavřít" (click)="displayDeleteLhc=false" class="p-button-secondary"></button>

    </p-footer>

  </p-dialog>

  <p-dialog header="Kontrola smazání LHC" (onHide)=" onDeleteLhcCheckHide()" [(visible)]="displayDeleteLhcCheck" [modal]="true" [style]="{width: '900px'}">
    <p>
      Kliknutím na "Smazat" dojde ke smazání LHC {{data?.lhcKod}}. Tuto akci nelze vrátit zpět.
    </p>
    <p-footer>
      <button type="button" pButton icon="pi pi-times" label="Zavřít" (click)="displayDeleteLhcCheck=false; displayDeleteLhc=false" class="p-button-secondary"></button>
      <button pButton type="button" class="icon-btn p-button-danger" label="Smazat" (click)="deleteLhc()"><fa-icon [icon]="faTrash"></fa-icon></button>
    </p-footer>
  </p-dialog>

  <p-toast position="top-center" key="lhc-delete-toast"></p-toast>

  <app-block-ui [block]="deleteBlock"></app-block-ui>
</div>
