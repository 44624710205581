import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { faBackspace, faEllipsisV, faFileImport, faSearch, faUndo } from '@fortawesome/free-solid-svg-icons';
import { LhpAdminServices } from '../../../Services/Lhp/lhp-admin.service';
import { ImportService } from '../../../Services/Lhp/import.service';
import { LhcListAdminDto } from '../../../Dto/Lhp/LhcListAdminDto';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { MessagesUtils } from '../../../Utils/Shared/messages.utils';

/** Komponenta seznamu Lhc */
@Component({
  selector: 'app-list-lhc',
  templateUrl: './list-lhc.component.html',
  styleUrls: ['./list-lhc.component.css']
})
export class ListLhcComponent implements OnInit {

  @ViewChild("colDatum", { static: true }) datumTemplate: TemplateRef<any>;
  @ViewChild("colDatumCas", { static: true }) datumCasTemplate: TemplateRef<any>;

  private readonly MESSAGE_KEY: string = "lhc-list-toast";
  selectedLhc: LhcListAdminDto;
  lhcItems: LhcListAdminDto[];
  displayImport: boolean = false;
  faFileImport = faFileImport;
  faBackspace = faBackspace;
  faEllipsisV = faEllipsisV;
  faUndo = faUndo;

  filter: string;
  faSearch = faSearch;

  lhcCols: any[] = [];

  constructor(
    private adminService: LhpAdminServices,
    private importService: ImportService,
    private messageService: MessageService,
    private router: Router)
  {
    this.importService.lhcAdd.subscribe(lhc => { this._addFromList(lhc); });
  }

  ngOnInit() {

    // přesunuto do OnInit kvůli referenci na datumTemplate
    this.lhcCols = [
      { field: 'lhcKod', header: 'Kód LHC', width: '8%' },
      { field: 'lhcNaz', header: 'Název LHC', width: '20%' },
      { field: 'rocnik', header: 'Ročník', width: '5%' },
      { field: 'lhpOd', header: 'Platnost LHP od', width: '9%', template: this.datumTemplate },
      { field: 'lhpDo', header: 'Platnost LHP do', width: '9%', template: this.datumTemplate },
      { field: 'insLogin', header: 'Kdo naimportoval', width: '13%' },
      { field: 'insDate', header: 'Kdy bylo naimportováno', width: '11%', template: this.datumCasTemplate },
      { field: 'zakaznikNazev', header: 'Zákazník', width: '13%' },
      { field: 'poznamka', header: 'Poznámka', width: '12%' }
    ];

    this._loadLhcTable();
    
  }

  /**
   * Navigace na úvodní stránku
  */
  public back(): void {
    this.router.navigate(["/"]);
  }

  /**
   * Uloží vybraný řádek do proměnné
   */
  onRowSelected(dto: LhcListAdminDto): void {
    this.selectedLhc = dto;
  }

  /**
  * Odstraní vybraný řádek z proměnné
  */
  onRowUnselected(dto: LhcListAdminDto): void {
    this.selectedLhc = undefined;
  }

  /**
  * Přejde na detail Lhc
  */
  goToDetail(): void {
    this.router.navigate(['/kniha/' + this.selectedLhc.lhcKod + '/' + this.selectedLhc.guid]);
  }

  /**
   * Handluje úspěch smazání Lhc
   */
  onDeleteSuccess() {
    this.selectedLhc = undefined;
    this._loadLhcTable();
  }

  private _addFromList(lhc: LhcListAdminDto) {
    if (lhc != void 0) {
      this.lhcItems.unshift(lhc);
    }
  }

  private _loadLhcTable() {
    this.adminService.lhcList().subscribe(res => {
      if (res != void 0 && res.success) {
        this.lhcItems = res.data;
      }
      else {
        this.messageService.add({ severity: 'error', summary: 'Chyba', detail: res.messages[0], key: this.MESSAGE_KEY, life: MessagesUtils.TOAST_LIFE });
      }
    });
  }
}
