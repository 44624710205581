<p-panel *ngIf="_itemGuid != undefined" header="Akce" [style]="{'margin-top':'2em'}">
  <p-table [value]="items" [loading]="loading">
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="datum" style="width: 25%">
          <div class="sortable-header">
            Datum
            <p-sortIcon field="datum"></p-sortIcon>
          </div>
        </th>
        <th pSortableColumn="login" style="width: 25%">
          <div class="sortable-header">
            Login
            <p-sortIcon field="login"></p-sortIcon>
          </div>
        </th>
        <th pSortableColumn="poznamka" style="width: 50%">
          <div class="sortable-header">
            Poznámka
            <p-sortIcon field="poznamka"></p-sortIcon>
          </div>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
      <tr>
        <td>{{item.datum | date:'d.M.yyyy'}}</td>
        <td>{{item.login}}</td>
        <td>{{item.poznamka}}</td>
      </tr>
    </ng-template>
  </p-table>
</p-panel>
