<p-breadcrumb [model]="breadcrumbs" [home]="breadcrumbHome"></p-breadcrumb>

<div *ngIf="model" class="ui-widget">
  <div class="p-grid kniha-body">
    <div class="p-col-2">
      <app-kniha-tree [treeLhcGuid]="lhcGuid"></app-kniha-tree>
    </div>
    <div class="p-col-10">
      <p-card>
        <div class="p-grid p-align-stretch kniha-detail-form">
          <div class="p-col-2">
            Kód
            <div class="p-inputtext ui-corner-all">{{model.odd}}</div>
          </div>
          <div class="p-col-10">
            Poznámka
            <div class="p-inputtext ui-corner-all">{{model.oddText}}</div>
          </div>
        </div>
      </p-card>
    </div>
  </div>
</div>
