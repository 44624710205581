import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { KnihaBase } from 'src/app/Components/Lhp/kniha-base';
import { BzlDetailDto } from 'src/app/Dto/Lhp/BzlDetailDto';
import { KnihaService } from 'src/app/Services/Lhp/kniha.service';

@Component({
  selector: 'app-kniha-bzl',
  templateUrl: './kniha-bzl.component.html',
  styleUrls: ['./kniha-bzl.component.css']
})
export class KnihaBzlComponent extends KnihaBase {

  constructor(
    route: ActivatedRoute,
    knihaService: KnihaService
  ) {
    super(route, knihaService);
  }

  model: BzlDetailDto;

  loadModel() {
    this.knihaService.getBzl(this.lhcKod, this.odd, this.dil, this.por, this.bzl, this.katuzeKod, this.guid)
      .subscribe(response => {
        this.model = response;
        this.bzlGuid = this.model.guid;
        this.porGuid = this.model.lhpGuids.porGuid;
        this.dilGuid = this.model.lhpGuids.dilGuid;
        this.oddGuid = this.model.lhpGuids.oddGuid;
        this.lhcGuid = this.model.lhpGuids.lhcGuid;
      });
  }
}
