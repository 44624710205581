import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { ResultInfoGenericDto } from '../../Dto/Shared/ResultInfoDto';
import { ZakaznikDetailDto } from '../../Dto/Zakaznik/ZakaznikDetailDto';

/** Service pro práci se zákazníky */
@Injectable({
  providedIn: 'root'
})
export class ZakaznikService {

  constructor
  (
    private http: HttpClient
  )
  {
  }

  private apiZakaznikSaveOrUpdate: string = 'api/zakaznik/Zakaznik/SaveOrUpdate';

  /**
   * Uloží nebo updatuje zákazníka
   * @param data
   */
  saveOrUpdate(data: ZakaznikDetailDto): Observable<ResultInfoGenericDto<ZakaznikDetailDto>> {
    return this.http.post<ResultInfoGenericDto<ZakaznikDetailDto>>(this.apiZakaznikSaveOrUpdate, data, {});
  }


}
