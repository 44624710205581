import { getLocaleNumberFormat, NumberFormatStyle } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

/**
 * Komponenta pro zbrazení needitovatelného textového pole se vzhledem inputu s popiskem
 **/
@Component({
  selector: 'app-label-input-display',
  templateUrl: './label-input-display.component.html',
  styleUrls: ['./label-input-display.component.css']
})
export class LabelInputDisplayComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  isDate: boolean = false;

  isBoolean: boolean = false;

  isNumber: boolean = false;

  /**
   * Možnost automatického nastavní typu obsahu. Pokud je False tak se nerozlišujde typ a vypíše se property bez formátování.
   */
  @Input() formatConent: boolean = true;

  _value: any;

  @Input() set value(val: any) {
    if (this.formatConent) {
      if (val instanceof Date) this.isDate = true;
      if (typeof val == "number") this.isNumber = true;
      if (typeof val == "boolean") this.isBoolean = true;
    }
    this._value = val;
  };

  /**
   * Lopisek pole
   */
  @Input() label: string;

  /**
  * Bublínková nápověda pro popisek
  */
  @Input() tooltip: string;

  /**
   * Počet desetinných míst u čísla
   */
  @Input() numDecPlaces: number = 2;

  /**
   * Pokud je naplněno, tak se zobrazí obsah jako tooltip se žutým pozadím, jako v číselnících
   */
  @Input() ciselnikTooltip: string;

  get numFormat(): string {
    return "." + this.numDecPlaces + "-" + this.numDecPlaces;
  }
}
