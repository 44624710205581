<div class="p-inputgroup">
  <input type="text" pInputText [disabled]="disabled" [(ngModel)]="value">
  <button type="button" pButton class="p-button-success icon-btn hide-label-button" (click)="display=true"><fa-icon [icon]="faStream"></fa-icon></button>
</div>

<p-dialog [(visible)]="display"  [style]="{width:'33rem', 'max-height': '90vh'}"
          [modal]="true">
  <ng-template pTemplate="header">
    <span class="p-dialog-title">Kategorie dotazu {{nazevDotazu}}</span>
  </ng-template>

  <app-dotazovac-kategorie [kategorie]="value" (onChange)="onKategorieChange($event)" [addRootNode]="false"></app-dotazovac-kategorie>

  <ng-template pTemplate="footer">
    <button pButton (click)="save()" class="icon-btn hide-label-button" pTooltip="Použít" tooltipPosition="bottom"><fa-icon [icon]="faCheck"></fa-icon></button>
    <button pButton (click)="display=false" class="icon-btn hide-label-button times-btn-icon" pTooltip="Zavřít" tooltipPosition="bottom"><fa-icon [icon]="faTimes"></fa-icon></button>
  </ng-template>
</p-dialog>
