import { Component, Input } from '@angular/core';
import { faPaperclip, faCheck } from '@fortawesome/free-solid-svg-icons';
import { NacrtMapItemDto } from '../../../Dto/Nacrty/NacrtMapItemDto';
import { NacrtPrilohyChangedArgs } from '../../../Dto/Nacrty/NacrtPrilohyChangedArgs';
import { MapInteractionService } from '../../../Services/Mapa/map-interaction.service';
import { NacrtyInteractionService } from '../../../Services/Nacrty/nacrty-interaction.service';

/**
 * Komponenta zobrazení příloh náčrtu.
**/
@Component({
  selector: 'app-nacrt-priloha',
  templateUrl: './nacrt-priloha.component.html',
  styleUrls: ['./nacrt-priloha.component.css']
})
export class NacrtPrilohaComponent
{
  /**
    * Guid náčrtu, jehož přílohami se bude pracovat.
   **/
  _nacrtData: NacrtMapItemDto = null;
  @Input() set data(value: NacrtMapItemDto) {
    if (value != void 0) {
      this._nacrtData = value;
      this.prilohyCnt = value.prilohyCount;
    }
  }


  constructor(
    private nacrtyInteractionService: NacrtyInteractionService,
    private mapInteractionService: MapInteractionService)
  {
    this.mapInteractionService.EditFeature.subscribe(dto => { if (dto.id == this._nacrtData.id) this.disabled = true; });
    this.mapInteractionService.NacrtFinished.subscribe(dto => { if (dto.id == this._nacrtData.id) this.disabled = false; });
    this.mapInteractionService.DiscardChanges.subscribe(dto => {
      if (dto != void 0 && dto.id == this._nacrtData.id) {
        this.disabled = false;
        this.prilohyCnt = this._nacrtData.prilohyCount;
      }
    });

    this.nacrtyInteractionService.OnInitView.subscribe(_ => {
      this.display = true;
      this.loading = false;
    });

    this.nacrtyInteractionService.OnAdd.subscribe(this._updateCount.bind(this));
    this.nacrtyInteractionService.OnDelete.subscribe(this._updateCount.bind(this));
  }

  display: boolean = false;
  loading: boolean = false;
  faPaperclip = faPaperclip;
  faCheck = faCheck;
  prilohyCnt: number = 0;
  disabled: boolean = false;


  /**
   * Handler kliknutí na tlačítko zobrazení dialogu příloh náčrtu.
  **/
  onAttachmentBtnClick(): void {
    if (this.prilohyCnt > 0 && !this.disabled) {
      this.loading = true;
      this.nacrtyInteractionService.initView(this._nacrtData);
    }
  }


  /**
   * Aktualizace počtu příloh.
   * @param args {NacrtPrilohyChangedArgs} argumenty příloh náčrtu
   */
  private _updateCount(args: NacrtPrilohyChangedArgs): void {
    this.prilohyCnt = args.prilohy.filter(p => !p.sync.d).length
  }
}
