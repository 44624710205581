import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TreeNode } from 'primeng/api';
import { KnihaService } from 'src/app/Services/Lhp/kniha.service';
import { Tree } from 'primeng/tree/tree';
import { KnihaBase } from '../kniha-base';


@Component({
  selector: 'app-kniha-tree',
  templateUrl: './kniha-tree.component.html',
  styleUrls: ['./kniha-tree.component.css']
})
export class KnihaTreeComponent extends KnihaBase {

  constructor(
    route: ActivatedRoute,
    knihaService: KnihaService
  ) {
    super(route, knihaService);
  }

  static jprlTree: TreeNode[];
  knihaRef = KnihaTreeComponent;
  loading: boolean = false;

  @Input() set treeLhcGuid(value: string) {
    this.lhcGuid = value;
  }

  loadModel() {
    if (KnihaTreeComponent.jprlTree == null || KnihaTreeComponent.jprlTree[0].data.guid != this.lhcGuid) {
      KnihaTreeComponent.jprlTree = null;
      this.loading = true;
      this.knihaService.getLhcTree(this.lhcKod, this.lhcGuid)
        .subscribe(response => {
          //přiřadit práznému dílci parent
          response[0].children = response[0].children.map(x => { x.children = [{ parent: x }]; return x; });
          KnihaTreeComponent.jprlTree = response;
          console.log(KnihaTreeComponent.jprlTree);
          KnihaTreeComponent.jprlTree[0].expanded = true; // LHC je vždy rozbaleno
          this.loading = false;
        });
    }
  }

  //Při rozbalení načte obsah
  nodeExpanded(event) {
    var node: TreeNode = event.node;
    this.loading = true;
    this.knihaService.getJprlTree(node)
      .subscribe(response => {
        if (response[0] != void 0) {
          //pokud se nejedná o koncové uzly bez potomků, tak přiřadit prázdným potomkům parent
          response.map(x => { if (x.children != void 0 && x.children[0] == void 0) x.children = [{ parent: x }]; return x; });
          KnihaTreeComponent.jprlTree.forEach(function (lhc) {
            if (response[0].type == "DilListDto" && lhc.children[0] != void 0) {
              let odd = lhc.children.find(x => x.data?.guid == node.data.guid);
              if (odd != void 0)
                odd.children = response;
            } else {
              lhc.children.forEach(function (dil) {
                if (response[0].type == "PorListDto" && dil.children[0] != void 0) {
                  let por = dil.children.find(x => x.data?.guid == node.data.guid);
                  if (por != void 0)
                    por.children = response;
                } else if (dil.children[0] != void 0) {
                  dil.children.forEach(function (por) {
                    if ((response[0].type == "PskListDto" || response[0].type == "BzlListDto"
                      || response[0].type == "JpListDto" || response[0].type == "OpListDto")
                      && por.children != void 0 && por.children[0] != void 0) {
                      let rl = por.children.find(x => x.data?.guid == node.data.guid);
                      if (rl != void 0)
                        rl.children = response;
                    } else if (por.children != void 0 && por.children[0] != void 0) {
                      por.children.forEach(function (psk) {
                        if (response[0].type == "EtazListDto" && psk.children != void 0 && psk.children[0] != void 0) {
                          let etaz = psk.children.find(x => x.data?.guid == node.data.guid);
                          if (etaz != void 0)
                            etaz.children = response;
                        } else if (psk.children != void 0 && psk.children[0] != void 0) {
                          psk.children.forEach(function (etaz) {
                            if (response[0].type == "DrevinListDto" && etaz.children != void 0 && etaz.children[0] != void 0) {
                              let drevina = etaz.children.find(x => x.data?.guid == node.data.guid);
                              if (drevina != void 0)
                                drevina.children = response;
                            }
                          });
                        }
                      });
                    }
                  });
                }
              });
            }
          })
        }
        this.loading = false;
      });
  }
}
