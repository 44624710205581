import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CiselnikDataDto } from '../../Dto/Core/CiselnikDataDto';
import { CiselnikRoleOpravneniDto } from '../../Dto/Core/CiselnikRoleOpravneniDto';
import { CiselnikSeznamDto } from '../../Dto/Core/CiselnikSeznamDto';
import { CiselnikUserSeznamDto } from '../../Dto/Core/CiselnikUserSeznamDto';
import { ResultInfoDto } from '../../Dto/Shared/ResultInfoDto';

@Injectable({
  providedIn: "root"
})
export class CiselnikyAdminService {
  constructor(
    private http: HttpClient
  ) { }

  private apiGetSeznamCiselniku: string = "api/CiselnikyAdmin/GetSeznamCiselniku";
  private apiGetCiselnikData: string = "api/CiselnikyAdmin/GetCiselnikData";
  private apiSave: string = "api/CiselnikyAdmin/Update";
  private apiInsert: string = "api/CiselnikyAdmin/Insert";
  private apiGetCiselnikyAll: string = "api/CiselnikyAdmin/GetCiselnikyAll";
  private apiGetSeznamCiselnikuForRole: string = "api/CiselnikyAdmin/GetSeznamCiselnikuForRole";
  private apiUpdateOpravneni: string = "api/CiselnikyAdmin/UpdateOpravneni";
  private apiDelete: string = "api/CiselnikyAdmin/Delete";


  /**
   * Získá seznam číselníků pro editaci
   **/
  public getSeznamCiselniku(): Observable<CiselnikUserSeznamDto[]> {
    return this.http.get<CiselnikUserSeznamDto[]>(this.apiGetSeznamCiselniku);
  }

  /**
   * Záská data vybraného číselníku pro zobrazení/editaci
   * @param guid
   */
  public getCiselnikData(guid: string): Observable<CiselnikDataDto> {
    return this.http.get<CiselnikDataDto>(this.apiGetCiselnikData + "?guid=" + guid);
  }

  /**
   * Uloží editovaný řádek číselníku
   * @param data
   */
  public save(ciselnikGuid: string, data: any): Observable<ResultInfoDto> {
    var param = new HttpParams();
    param = param.append('ciselnikGuid', ciselnikGuid);
    param = param.append('updatedRowJson', JSON.stringify(data));
    return this.http.post<ResultInfoDto>(this.apiSave, null, { params: param });
  }

  /**
   * Vloží nový řádek do tabulky
   * @param ciselnikGuid
   * @param data
   */
  public insert(ciselnikGuid: string, data: any): Observable<ResultInfoDto> {
    var param = new HttpParams();
    param = param.append('ciselnikGuid', ciselnikGuid);
    param = param.append('rowJson', JSON.stringify(data));
    return this.http.post<ResultInfoDto>(this.apiInsert, null, { params: param });
  }

  /**
   * Vrátí seznam všech číselníku pro roli SuperAdmin
   * */
  public GetCiselnikyAll(): Observable<CiselnikSeznamDto[]> {
    return this.http.get<CiselnikSeznamDto[]>(this.apiGetCiselnikyAll);
  }

  /**
   * Získá seznam číselníků podle role
   * @param role
   */
  public getSeznamCiselnikuForRole(role: string): Observable<CiselnikUserSeznamDto[]> {
    return this.http.get<CiselnikUserSeznamDto[]>(this.apiGetSeznamCiselnikuForRole + "?role=" + role);
  }

  /**
   * Nastaví zvolené oprávnění
   * @param role
   * @param ciselniky
   */
  public updateOpravneni(role: string, ciselniky: CiselnikRoleOpravneniDto[]): Observable<ResultInfoDto> {
    var param = new HttpParams();
    param = param.append('role', role);
    return this.http.post<ResultInfoDto>(this.apiUpdateOpravneni, ciselniky, { params: param });
  }

  /**
   * Smaže záznam
   * @param ciselnikGuid - guid číselníku
   * @param id - guid záznamu, který se má smazat
   */
  public delete(ciselnikGuid: string, id: string): Observable<ResultInfoDto> {
    var param = new HttpParams();
    param = param.append('ciselnikGuid', ciselnikGuid);
    return this.http.delete<ResultInfoDto>(this.apiDelete, { params: param, body: '"' + id + '"', headers: new HttpHeaders({ 'content-type': 'text/json' }) });
  }
}
