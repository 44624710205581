import { Component, Input } from '@angular/core';
import { VypocetDetailDto } from 'src/app/Dto/Skody/VypocetDetailDto';
import { UjmaOdst7VystavkyInfoDto } from 'src/app/Dto/Skody/UjmaOdst7VystavkyInfoDto';

@Component({
  selector: 'ujmy-detail-u7-kroky',
  templateUrl: './ujmy-detail-u7-kroky.component.html',
  styleUrls: ['./ujmy-detail-u7-kroky.component.css']
})
export class UjmyDetailU7KrokyComponent {

  constructor() { }

  @Input() skoda: VypocetDetailDto;
  @Input() blockedPrepocet: boolean;
  @Input() vystavkyDto: UjmaOdst7VystavkyInfoDto;
}
