export class CVyrMaticeDetailDto {
  guid: string;
  dokladTypKod: string;
  podvykonGuid: string;
  mjFakturaceGuid: string;
  poznamka: string;
  platnostOd: Date;
  platnostDo: Date;
  vlozil: string;
  vlozilKdy: Date;
  opravil: string;
  opravilKdy: Date;
  povolMj: string[];
  polozky: string[];
}
