export class EqLocaleInfo {
  public static Czech = {
    englishName: "Czech",
    displayName: "Čeština",
    settings: {
      dateFormat: 'dd.MM.yyyy',
      decimalSeparator: ','
    },
    texts: {
      AltMenuAttribute: "Atribut",
      AltMenuConstantExpression: "Konstanta",
      ButtonApply: "Použít",
      ButtonCancel: "Zrušit",
      ButtonOK: "OK",
      ButtonClear: "Vyčistit",
      MenuEnable: "Zapnout/vypnout",
      MenuParameterization: "Parametrická podmínka",
      MenuJoinCond: "Použív v 'JOIN'",
      ButtonDelete: "Odstranit",
      ButtonAddCondition: "Přidat podmínku",
      ButtonAddPredicate: "Přidat skupinu podmínek",
      ButtonSelectAll: "Vybrat vše",
      ButtonDeselectAll: "Zrušit výběr",
      ButtonAddColumns: "Přidat do sloupců",
      ButtonAddConditions: "Přidat do podmínek",

      CmdAddConditionAfter: "Přidat podmínku pod aktuální",
      CmdAddConditionInto: "Přidat podmínku",
      CmdAddPredicateAfter: "Otevřít závorku pod aktuální",
      CmdAddPredicateInto: "Otevřít závorku",
      CmdClickToAddCondition: "[Přidat podmínku]",
      CmdDeleteRow: "Odstranit řádek",
      ErrIncorrectPredicateTitleFormat: "Špatný formát popisu predikátu",
      ErrNotNumber: " není číslo",
      ErrIncorrectInteger: "Špatná celočíselná hodnota",
      ErrIncorrectNumberList: "Špatný formát seznamu",
      False: "Ne",
      LinkTypeAll: "všechny",
      LinkTypeAny: "některé",
      LinkTypeNone: "žádné",
      LinkTypeNotAll: "ne všechny",
      ConjAll: "a",
      ConjAny: "nebo",
      ConjNotAll: "a",
      ConjNone: "nebo",
      MsgApplySelection: "[Použít výběr]",
      MsgAs: "jako",
      MsgEmptyList: "(prázdný seznam)",
      MsgEmptyListValue: "[vyberte hodnotu]",
      MsgEmptyScalarValue: "[zadejte hodnotu]",
      MsgSubQueryValue: "[upravit vnořený dotaz]",
      MsgEmptyAttrValue: "[vyberte atribut]",
      MsgEmptyCustomSql: "[zadejte SQL výraz]",
      MsgCustomSql: "[vlastní SQL]",
      MsgNoResult: "nenalazen žádný záznam",
      MsgResultCount: "nalezeno {0} záznamů",

      MsgOf: "z",
      PredicateTitle: "{lt} následující",
      RootPredicateTitle: "Vybrat záznamy, kde platí {lt} následující",
      StrAddConditions: "Přidat podmínky",
      SubQueryDialogTitle: "Upravit vnořený dotaz",
      SubQueryColumnTitle: "Sloupec:",
      SubQueryEmptyColumn: "[vybrat sloupec]",
      SubQueryQueryPanelCaption: "Podmínky",

      ButtonSorting: "Řazení",
      ButtonToAggr: "Agregovat sloupec",
      ButtonToSimple: "Neagregovat sloupec",
      CmdAscending: "Vzestupně",
      CmdClickToAddColumn: "[Přidat sloupec]",
      CmdDeleteColumn: "Odebrat sloupec",
      CmdDeleteSorting: "Zrušit řazení",
      CmdDescending: "Sestupně",
      CmdGroupSort: "Řazení",
      CmdNotSorted: "Neřadit",
      ColTypeAggrFunc: "Agregační funkce",
      ColTypeCompound: "Počítaný",
      ColTypeGroup: "Typ sloupce",
      ColTypeSimple: "Základní",
      HeaderExpression: "Položka",
      HeaderSorting: "Řazení",
      HeaderTitle: "Popis",
      SortHeaderColumn: "Sloupec",
      SortHeaderSorting: "Řazení",
      StrAddColumns: "Přidat sloupce",
      CustomExpression: "Vlastní výraz",

      CmdMoveToStart: "Přesunout na začátek",
      CmdMoveRight: "Přesunout doprava",
      CmdMoveLeft: "Přesunout doleva",
      CmdMoveToEnd: "Přesunout na konec",
      ButtonMenu: "Zobrazit nabídku",
      CmdToSimple: "Neagregovat",

      CmdMoveToFirst: "Přesunout na začátek",
      CmdMoveToPrev: "Přesunout nahoru",
      CmdMoveToNext: "Přesunout dolů",
      CmdMoveToLast: "Přesunout na konec",

      CmdToggleEnable: "Aktivovat/Deaktivovat",
      ButtonFormat: "Zobrazit formát",

      //FilterBar
      StrNoFilterDefined: "Filtr nenastaven",
      StrNoFilterClickToAdd: "Filtr nenastevn. Klikněte pro přidání podmínky",

      //DateTime macroses
      Today: "Dnes",
      Yesterday: "Včera",
      Tomorrow: "Zítra",
      FirstDayOfMonth: "První den v měsíci",
      LastDayOfMonth: "Poslední den v měsíci",
      FirstDayOfWeek: "První den týdne",
      FirstDayOfYear: "Začátek roku",
      FirstDayOfNextWeek: "První den příštího týdne",
      FirstDayOfNextMonth: "První den příštího měsíce",
      FirstDayOfNextYear: "První den příštího roku",
      Now: "Nyní",
      HourStart: "Začátek hodiny",
      Midnight: "Půlnoc",
      Noon: "Poledne",

      Entities: {},
      Attributes: {},
      Operators: {
        Equal: {
          caption: "je rovno",
          displayFormat: "{expr1} [[je rovno]] {expr2}",
        },
        NotEqual: {
          caption: "není rovno",
          displayFormat: "{expr1} [[není rovno]] {expr2}",
        },
        LessThan: {
          caption: "je menší",
          displayFormat: "{expr1} [[je menší než]] {expr2}",
        },
        LessOrEqual: {
          caption: "je menší nebo rovno",
          displayFormat: "{expr1} [[je menší nebo rovno]] {expr2}",
        },
        GreaterThan: {
          caption: "je větší",
          displayFormat: "{expr1} [[je větší než]] {expr2}",
        },
        GreaterOrEqual: {
          caption: "je větší nebo rovno",
          displayFormat: "{expr1} [[je větší nebo rovno]] {expr2}",
        },
        IsNull: {
          caption: "je NULL",
          displayFormat: "{expr1} [[je NULL]]",
        },
        IsNotNull: {
          caption: "není NULL",
          displayFormat: "{expr1} [[není NULL]]",
        },
        IsTrue: {
          caption: "je pravdivé",
          displayFormat: "{expr1} [[je pravdivé]]",
        },
        NotTrue: {
          caption: "není pravdivé",
          displayFormat: "{expr1} [[není pravdivé]]",
        },
        InList: {
          caption: "je v seznamu",
          displayFormat: "{expr1} [[je v seznamu]] {expr2}",
        },
        NotInList: {
          caption: "není v seznamu",
          displayFormat: "{expr1} [[není v seznamu]] {expr2}",
        },
        Between: {
          caption: "je mezi",
          displayFormat: "{expr1} [[je mezi]] {expr2} and {expr3}",
        },
        NotBetween: {
          caption: "není mezi",
          displayFormat: "{expr1} [[není mezi]] {expr2} and {expr3}",
        },
        StartsWith: {
          caption: "začíná na",
          displayFormat: "{expr1} [[začíná na]] {expr2}",
        },
        NotStartsWith: {
          caption: "nezačíná na",
          displayFormat: "{expr1} [[nezačíná na]] {expr2}",
        },
        Contains: {
          caption: "obsahuje",
          displayFormat: "{expr1} [[obsahuje]] {expr2}",
        },
        NotContains: {
          caption: "neobsahuje",
          displayFormat: "{expr1} [[neobsahuje]] {expr2}",
        },
        DateWithinToday: {
          caption: "je dnes",
          displayFormat: "{expr1} [[je dnes]]",
        },
        DateWithinThisWeek: {
          caption: "v aktuálním týdnu",
          displayFormat: "{expr1} [[jev aktuálním týdnu]]",
        },
        DateWithinPrevWeek: {
          caption: "v minulém týdnu",
          displayFormat: "{expr1} [[je v minulém týdnu]]",
        },
        DateWithinThisMonth: {
          caption: "v aktuálním měsíci",
          displayFormat: "{expr1} [[je v aktuálním měsíci]]",
        },
        DateWithinPrevMonth: {
          caption: "v minulém měsíci",
          displayFormat: "{expr1} [[je v minulém měsíci]]",
        },
        DateWithinThisYear: {
          caption: "v aktuálním roce",
          displayFormat: "{expr1} [[je v aktuálním roce]]",
        },
        DateWithinPrevYear: {
          caption: "v minulém roce",
          displayFormat: "{expr1} [[je v minulém roce]]",
        },
        DateBeforePrecise: {
          caption: "je před datem",
          displayFormat: "{expr1} [[je před]] {expr2}",
        },
        DateAfterPrecise: {
          caption: "je po datu",
          displayFormat: "{expr1} [[je po]] {expr2}",
        },
        DatePeriodPrecise: {
          caption: "je mezi daty",
          displayFormat: "{expr1} [[je mezi]] {expr2} and {expr3}",
        },
        TimeBeforePrecise: {
          caption: "je před (čas)",
          displayFormat: "{expr1} [[je před (čas)]] {expr2}",
        },
        TimePeriodPrecise: {
          caption: "je po (čas)",
          displayFormat: "{expr1} [[je po (čas)]] {expr2}",
        },
        MaximumOfAttr: {
          caption: "rovná se maximu z",
          displayFormat: "{expr1} [[je rovné maximu z]] {expr2}",
        },
        InSubQuery: {
          caption: "v poddotazu",
          displayFormat: "{expr1} [[je v poddotazu]] {expr2}",
        },
        NotInSubQuery: {
          caption: "není v poddotazu",
          displayFormat: "{expr1} [[není v poddotazu]] {expr2}",
				}
      },
      AggregateFunctions: {
        SUM: {
          caption: "Součet",
          displayFormat: "[[Součet] {attr1}",
        },
        COUNT: {
          caption: "Počet",
          displayFormat: "[[Počet]] {attr1}",
        },
        CNTDST: {
          caption: "Počet jedinečných hodnot",
          displayFormat: "[[Počet jedinečných hodnot]] z {attr1}",
        },
        AVG: {
          caption: "Průměr",
          displayFormat: "[[Průmer]] z {attr1}",
        },
        MIN: {
          caption: "Minimum",
          displayFormat: "[[Minimum]] z {attr1}",
        },
        MAX: {
          caption: "Maximum",
          displayFormat: "[[Maximum]] z {attr1}",
        },
      }
    }
  };
}
