import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { UzaverkyService } from 'src/app/Services/Vyroba/uzaverky.service';
import { MessageService } from 'primeng/api';
import { MessagesUtils } from 'src/app/Utils/Shared/messages.utils';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { DVyrUzaverkyListDto } from 'src/app/Dto/Vyroba/DVyrUzaverkyListDto';

/**
 * Interface pro nabídku rolí.
**/
export interface IRole {
  value: string,
  name: string
}

/**
 * Komponenta aktualizace data uzávěrky pro roli.
**/
@Component({
  selector: 'app-vyroba-uzaverky-update',
  templateUrl: './vyroba-uzaverky-update.component.html',
  styleUrls: ['./vyroba-uzaverky-update.component.css']
})
export class VyrobaUzaverkyUpdateComponent implements OnInit {

  private TOAST_KEY: string = "vyroba-uzaverky-toast";
  @Input() visible: boolean;
  @Input() selectedItems: DVyrUzaverkyListDto[] = [];
  @Output() reloadUzaverky: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private formBuilder: FormBuilder,
    private uzaverkyService: UzaverkyService,
    private messageService: MessageService,
    private messagesUtils: MessagesUtils
  ) { }

  faSave = faSave;
  faTimes = faTimes;
  block: boolean = false;
  role: IRole[] = [];

  form = this.formBuilder.group({
    role: [''],
    datum: ['']
  });


  get datumValue() {
    var datVal = this.form.get('datum').value;
    if (datVal == '') return null;
    else return datVal;
  }

  get roleValue() {
    return this.form.get('role').value.value;
  }

  ngOnInit() {
    this.role = [
      { value: 'Hajny', name: 'Hajný' },
      { value: 'Polesny', name: 'Polesný' },
      { value: 'Metodik', name: 'Metodik' },
    ];

    this.form.get('role').setValue(this.role[0]);
  }


  /**
   * Aktualizace data uzávěrky pro roli.
  **/
  update(): void {
    if (this.selectedItems.length <= 0) {
      this.messageService.add({ key: this.TOAST_KEY, severity: 'error', summary: 'Chyba', detail: 'Nejsou vybrány uzávěrky k aktualizaci.' });
      return;
    }

    this.block = true;
    this.uzaverkyService.changeDate(this.roleValue, this.datumValue, this.selectedItems.map(x => x.guid)).subscribe(resp => {
      if (resp.success) {
        this.clear();
        this.reloadUzaverky.emit();
      }
      this.block = false;
      this.messagesUtils.showResponseMessage(this.TOAST_KEY, resp);
    });
  }


  /**
   * Zrušení aktualizace/vymaže formulář.
  **/
  cancel(): void {
    this.clear();
  }


  /**
   * Vymaže formulář.
  **/
  clear(): void {
    this.form.reset();
    this.form.get('role').setValue(this.role[0]);
  }
}
