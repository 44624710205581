import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResultInfoGenericDto } from 'src/app/Dto/Shared/ResultInfoDto';

@Injectable({
  providedIn: 'root'
})
export class PestebniService {
  constructor(
    private http: HttpClient
  ) { }

  private apiTest: string = "api/Pestebni/List";
  private apiOrgUrs: string = "api/Pestebni/OrgUrs";

  test(parameters: string, subquery: string): Observable<ResultInfoGenericDto<string>> {
    return this.http.get<ResultInfoGenericDto<string>>(this.apiTest + "?parameters=" + parameters + "&subquery=" + subquery);
  };

  exportToken(): Observable<ResultInfoGenericDto<string>> {
    return this.http.get<ResultInfoGenericDto<string>>("api/Pestebni/ExportToken");
  }
}
