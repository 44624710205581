import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SkodyDetailSBaseComponent } from 'src/app/Components/Skody/skody-detail-s-base/skody-detail-s-base.component';
import { LhpDrevinaProVypocetDto } from 'src/app/Dto/Skody/LhpDrevinaProVypocetDto';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { LhpEtazProVypocetDto } from 'src/app/Dto/Skody/LhpEtazProVypocetDto';
import { LhcListDto } from 'src/app/Dto/Lhp/LhcListDto';


/**
 * Komponenta s formulářem detailu výpočtu újmy dle odstavce 10 - omezení povolené výše těžby.
**/
@Component({
  selector: 'ujmy-detail-u10',
  templateUrl: './ujmy-detail-u10.component.html',
  styleUrls: ['./ujmy-detail-u10.component.css']
})
export class UjmyDetailU10Component extends SkodyDetailSBaseComponent implements OnInit {

  constructor(
    fb: FormBuilder
  ) {
    super(fb);
  }

  @Input() lhcListDto: LhcListDto;

  ngOnInit(): void {
    this.data.valueChanges
      .pipe(
        debounceTime(600),
        distinctUntilChanged((prev, curr) => prev.plocha === curr.plocha
          && prev.vek === curr.vek && prev.bonR === curr.bonR && prev.zakm === curr.zakm && prev.upravaHlpa == curr.upravaHlpa
          && prev.zast === curr.zast && prev.obmyti === curr.obmyti && prev.obnDoba == curr.obnDoba),
        filter(data => this.validateVstupyProPrepocet(data)),
      )
      .subscribe(() => {
        this.dataChanged.emit(this.data.value);
      });
  }


  data: FormGroup = this.fb.group({
    plocha: [''],
    vek: [''],
    bonR: [''],
    zakm: [''],
    upravaHlpa: [''],
    zast: [''],
    obmyti: [''],
    obnDoba: ['']
  });

  /**
   * Kontrola dat z U10 pro přepočet škody.
   * @param data
   */
  validateVstupyProPrepocet(data): boolean {
    return data.plocha != void 0
      && data.vek != void 0
      && data.bonR != void 0
      && data.zakm != void 0
      && data.zast != void 0
      && data.obmyti != void 0
      && data.obnDoba != void 0;
      
  }


  /**
   * Zkopíruje data etáže z LHP do vstupu.
  **/
  kopirujLhpEtazDoVstupu(dto: LhpEtazProVypocetDto): void {
    if (dto != void 0) {
      this.data.get('plocha').setValue(dto.etazPs);

      let skutVek = dto.vek;
      if (this.lhcListDto != void 0 && this.lhcListDto.lhpOd != undefined) {
        skutVek += new Date().getFullYear() - this.lhcListDto.lhpOd.getFullYear() - 1;
      }
      this.data.get('vek').setValue(skutVek);
      
      this.data.get('zakm').setValue(dto.zakm)
      this.data.get('obmyti').setValue(dto.obmyti);
      this.data.get('obnDoba').setValue(dto.obnDoba)
    }
  }


  /**
   * Zkopíruje data dřeviny z LHP do vstupu.
  **/
  kopirujLhpDrevinuDoVstupu(dto: LhpDrevinaProVypocetDto): void {
    if (dto != void 0) {
      this.data.get('bonR').setValue(dto.bonR);
      this.data.get('zast').setValue(dto.zast);
    }
  }
}
