import { Component, Input } from '@angular/core';
import { Coordinate } from 'ol/coordinate';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { KatastrService } from '../../../Services/Mapa/katastr.service';
import { MapInteractionService } from '../../../Services/Mapa/map-interaction.service';


/**
 * Komponenta pro zobrazení informací o katastru, resp. pro zobrazení LV v KN.
**/
@Component({
  selector: 'app-map-info-katastr',
  templateUrl: './map-info-katastr.component.html',
  styleUrls: ['./map-info-katastr.component.css']
})
export class MapInfoKatastrComponent {

  constructor(
    private _katastrService: KatastrService,
    private mapInteractionService: MapInteractionService)
  {
    this.mapInteractionService.Coordinates.subscribe((coords: Coordinate) => {
      this.x = coords[0];
      this.y = coords[1];
    });
  }

  faExternalLinkAlt = faExternalLinkAlt;
  x: number;
  y: number;


  /**
   * Pokud nejsou předány souřadnice, tak panel nebude zobrazen.
  **/
  get cardVisible(): boolean {
    return this.x != void 0 && this.y != void 0;
  }

  /**
   * Otevření LV v KN.
  **/
  goToKn(): void {
    this._katastrService.goToKatastr([this.x,this.y]);
  }
}
