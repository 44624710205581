<app-block-ui [block]="blocked"></app-block-ui>

<p-card styleClass="map-info-panel-item lhe-schvalit-from" *ngIf="visible">
  <div class="p-grid validated-form-container" [formGroup]="form">
    <div class="p-col-12 nacrt-i-primary">
      <p><b>Holina byla schválena</b></p>
    </div>
    <div style="p-col-12">
      <p-message severity="success" text="Holina byla uložena s následujícími paremetry. Pokud si přejete hodnoty změnit, upravte je ve formuláři níže a kliknětě 'Uložit'." styleClass="p-mr-2"></p-message>
    </div>
    <div class="p-col-6">
      Datum
      <p-calendar [style]="{'width':'100%'}" formControlName="datum" view="month" dateFormat="mm.yy"
                  [yearNavigator]="true" [yearRange]="yearRangeLhe" [minDate]="minDate" [maxDate]="maxDate"></p-calendar>
    </div>
    <div class="p-col-6">
      Index holiny
      <input type="number" pInputText formControlName="indexHoliny" />
    </div>
    <div class="p-col-12" style="padding: 0 0.5rem;">
      <p *ngIf="datum.invalid && (datum.dirty || datum.touched) && datum.errors"
         class="cross-validation-error-message alert alert-danger">
        Vyplňte prosím datum.
      </p>
      <p *ngIf="indexHoliny.invalid && (indexHoliny.dirty || indexHoliny.touched) && indexHoliny.errors"
         class="cross-validation-error-message alert alert-danger">
        Vyplňte prosím index holiny.
      </p>
    </div>
  </div>

  <div class="p-col-12 right" style="padding-right: 0;">
    <span class="p-buttonset">
      <button pButton type="submit" label="Uložit" class="icon-btn"
              pTooltip="Uložit změny" tooltipPosition="bottom"
              [disabled]="!form.valid || !form.dirty"
              (click)="save()">
        <fa-icon [icon]="faCheck"></fa-icon>
      </button>
      <button pButton type="button" class="icon-btn hide-label-button"
              pTooltip="Zavřít" tooltipPosition="bottom"
              (click)="close()">
        <fa-icon [icon]="faTimes"></fa-icon>
      </button>
    </span>
  </div>
</p-card>
