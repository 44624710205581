import { HttpClient, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { DotazDataDto } from '../../Dto/Dotazovac/DotazDataDto';
import { DotazDetailDto } from '../../Dto/Dotazovac/DotazDetailDto';
import { DotazInfoDto } from '../../Dto/Dotazovac/DotazInfoDto';
import { DotazListDto } from '../../Dto/Dotazovac/DotazListDto';
import { ModelListDto } from '../../Dto/Dotazovac/ModelListDto';
import { ResultInfoGenericDto } from '../../Dto/Shared/ResultInfoDto';
import { AuthService } from '../Auth/auth.service';

/**
 * Service pro práci s dotazovačem
*/
@Injectable({
  providedIn: 'root'
})
export class DotazovacService {

  constructor
    (
      private http: HttpClient,
      private authService: AuthService
    ) {
    authService.loggedOut.subscribe(() => {
      this._models = null;
      this._modelsRequest = null;
    });
  }

  private _apiDotazyList: string = "api/Dotazovac/DotazyList";
  private _apiGetDotaz: string = "api/Dotazovac/GetDotaz";
  private _apiSaveDotaz: string = "api/Dotazovac/SaveDotaz";
  private _apiDelete: string = "api/Dotazovac/DeleteDotaz";
  private _apiUnlockDotaz: string = "api/Dotazovac/UnlockDotaz";
  private _apiLockDotaz: string = "api/Dotazovac/LockDotaz";
  private _apiListModels: string = "api/Dotazovac/ListModels";
  private _apiGetDotazyCategoryTree: string = "api/Dotazovac/GetDotazyCategoryTree";
  private _apiGetDotazInfo: string = "api/Dotazovac/GetDotazInfo";
  private _apiGetDotazData: string = "api/Dotazovac/GetDotazData";
  private _apiExportAsXlsx: string = "api/Dotazovac/ExportAsXlsx";
  private _apiGetParametrizedQuery: string = "api/Dotazovac/GetParametrizedQuery";

  /**
   * BehaviorSubject pro předání události o uložení nebo smazání dotazu
   **/
  public savedOrDeleted: BehaviorSubject<boolean> = new BehaviorSubject(false);


  /**
   * Vrátí data dotazu podle daného guidu
   * @param guid {string} - guid hledaného dotazu
   */
  public getDotaz(guid: string): Observable<ResultInfoGenericDto<DotazDetailDto>> {
    return this.http.get<ResultInfoGenericDto<DotazDetailDto>>(this._apiGetDotaz + "/" + guid);
  }

  /**
   * Seznam modelů dotazovače pro přihlášeného uživatele
   */
  private _models: ModelListDto[];

  /**
   * Eventa pro zajištění pouze jednoho požadavku na získání modelů dotazovače ze serveru pro právě přihlášeného uživatele.
   */
  private _modelsRequest: EventEmitter<ResultInfoGenericDto<ModelListDto[]>> = null;

  /**
   * Vrátí seznam dostupných dotazů pro dotazovač
  */
  public dotazyList(): Observable<ResultInfoGenericDto<DotazListDto[]>> {
    return this.http.get<ResultInfoGenericDto<DotazListDto[]>>(this._apiDotazyList);
  }

  /**
   * Uloží nový dotaz
   * @param dotaz - dto detailu dotazu
   */
  public saveDotaz(dotaz: DotazDetailDto): Observable<ResultInfoGenericDto<DotazDetailDto>> {
    return this.http.post<ResultInfoGenericDto<DotazDetailDto>>(this._apiSaveDotaz, dotaz).pipe(
      tap(res => { if (res.success) this.savedOrDeleted.next(true) })
    );
  }

  /**
   * Smaže vybraný dotaz ze seznamu dotazů 
   * @param guid
   */
  public deleteDotaz(guid: string): Observable<boolean> {
    var param = new HttpParams();
    param = param.append('guid', guid);
    return this.http.delete<boolean>(this._apiDelete, { params: param }).pipe(
      tap(res => { this.savedOrDeleted.next(true) })
    );;
  }

  /**
   * Odemkne vzbraný dotaz ze seznamu dotazů
   * @param guid
   */
  public unlockDotaz(guid: string): Observable<boolean> {
    var param = new HttpParams();
    param = param.append('guid', guid);
    return this.http.post<boolean>(this._apiUnlockDotaz, {}, { params: param });
  }

  /**
 * Zamkne vybraný dotaz ze seznamu dotazů
 * @param guid
 */
  public lockDotaz(guid: string): Observable<boolean> {
    var param = new HttpParams();
    param = param.append('guid', guid);
    return this.http.post<boolean>(this._apiLockDotaz, {}, { params: param });
  }

  /**
   * Vrátí seznam modelů dotazovače.
   **/
  public listModels(): Observable<ResultInfoGenericDto<ModelListDto[]>> {
    let result: ResultInfoGenericDto<ModelListDto[]>;
    if (this._models != void 0) {
      result = { success: true, data: this._models, messages: null };
      return of(result);
    } else if (this._modelsRequest == void 0) {
      this._modelsRequest = new EventEmitter<ResultInfoGenericDto<ModelListDto[]>>();
      this.http.get<ResultInfoGenericDto<ModelListDto[]>>(this._apiListModels).subscribe(res => {
        if (res.success) this._models = res.data;
        if (!res.success) console.error(res.messages[0]);
        return this._modelsRequest.emit(res);
      });
    }

    return this._modelsRequest;
  }

  /**
   * Vrátí strom kategorií dotazů
   **/
  public getDotazyCategoryTree(): Observable<ResultInfoGenericDto<TreeNode<DotazDetailDto>[]>> {
    return this.http.get<ResultInfoGenericDto<TreeNode<DotazDetailDto>[]>>(this._apiGetDotazyCategoryTree);
  }

  /**
   * Získá metadata výsledku dotazu (Use Case Spustit dotaz)
   * @param id ID dotazu
   * @param eqQuery EQ dotaz jako JSON string, použít v případě dotazů, kterým je možné nastavit hodnoty parametrů (bude zde EQ dotaz s vyplněnými parametry)
   * @return Vrací informace o ne/úspěchu operace, a v případě úspěchu také metadata výsledku dotazu
   */
  public getDotazInfo(id: string, eqQuery: string = null): Observable<ResultInfoGenericDto<DotazInfoDto>> {
    var param = new HttpParams();
    param = param.append('id', id);
    if (eqQuery != void 0) param = param.append('eqQuery', eqQuery);
    return this.http.get<ResultInfoGenericDto<DotazInfoDto>>(this._apiGetDotazInfo, { params: param });
  }

  /**
   * Vrátí výsledek dotazu podle zadaných parametrů pro lazy loading
   * @param id ID dotazu
   * @param cacheKey klíč, pod kterým je dotaz uložen v cache (klíč vrací API spuštění dotazu, zde metoda getDotazInfo)
   * @param rows počet záznamů na stránku
   * @param page stránka
   */
  public getDotazData(id: string, cacheKey: string, rows: number, page: number): Observable<ResultInfoGenericDto<DotazDataDto>> {
    var param = new HttpParams();
    param = param.append('id', id);
    param = param.append('cacheKey', cacheKey);
    param = param.append('rows', rows);
    param = param.append('page', page);
    return this.http.get<ResultInfoGenericDto<DotazDataDto>>(this._apiGetDotazData, { params: param });
  }

  /**
   * Vrátí ArrayBuffer exportu tabulky s výsledkem dotazu
   * @param cacheKey
   */
  public async exportDotazAsXlsx(cacheKey: string): Promise<ArrayBuffer> {
    return await this.http.get(this._apiExportAsXlsx + "/" + cacheKey, { responseType: "arraybuffer" }).pipe(
      map((file: ArrayBuffer) => {
        return file;
      })
    ).toPromise<ArrayBuffer>();
  }

  /**
   * Vrátí EQ query jako JSON string obsahující pouze parametry,
	 * jejichž hodnotu může uživatel nastavit před spuštěním dotazu
   * @param id ID dotazu
   */
  public getParametrizedQuery(id: string): Observable<ResultInfoGenericDto<string>> {
    return this.http.get<ResultInfoGenericDto<string>>(this._apiGetParametrizedQuery + "/" + id);
  }
}
