import { CVyrSkladPohybDetailDto } from './CVyrSkladPohybDetailDto';

export class CVyrSkladDetailDto {
  guid: string;
  podvykonGuid: string;
  lokalitaGuid: string;
  platnostOd: Date;
  platnostDo: Date;
  vlozil: string;
  vlozilKdy: Date;
  opravil: string;
  opravilKdy: Date;
  pohyby: CVyrSkladPohybDetailDto[];
}
