<app-block-ui [block]="loading"></app-block-ui>
<p-toast position="top-center" key="{{TOAST_KEY}}"></p-toast>
<button pButton type="button" class="icon-btn hide-label-button" (click)="showDialog=true"><fa-icon [icon]="faThList"></fa-icon></button>
<p-dialog [(visible)]="displayDialog" [style]="{width: '70vw'}"
          [contentStyle]="{height: '70vw', 'padding-bottom': '0px'}" (onHide)="onCloseDialog()">
  <ng-template pTemplate="header">
    <span class="p-dialog-title">Volba JPRL</span>
    <button type="button" pButton icon="pi pi-check" (click)="save()" [disabled]="!selectedPor" style="margin-left: auto;" class="p-mr-2"></button>
  </ng-template>
  <div class="p-grid kniha-detail-tree" style="height:100%">
    <div class="p-col-9" style="height:100%">
      <p-table [value]="lhcItems" [scrollable]="true" scrollHeight="flex" selectionMode="single" (onRowSelect)="onLhcSelect($event)"
               (onRowUnselect)="onLhcUnselect()" [(selection)]="selectedLhc" dataKey="guid" sortField="lhcKod" styleClass="p-datatable-striped">
        <ng-template pTemplate="header">
          <tr>
            <th style="flex-basis: 20%" pSortableColumn="lhcKod">
              Kód
              <p-sortIcon field="lhcKod"></p-sortIcon>
            </th>
            <th style="flex-basis: 55%" pSortableColumn="lhcNaz">
              Název
              <p-sortIcon field="lhcNaz"></p-sortIcon>
            </th>
            <th style="flex-basis: 25%">Platnost LHP</th>
          </tr>
          <tr>
            <th style="flex-basis: 20%">
              <p-columnFilter type="text" field="lhcKod" [showMenu]="false" matchMode="contains"></p-columnFilter>
            </th>
            <th style="flex-basis: 55%">
              <p-columnFilter type="text" field="lhcNaz" style="width: 100%;"></p-columnFilter>
            </th>
            <th style="flex-basis: 25%">
              <p-dropdown [options]="platnostOptions"  [(ngModel)]="platnost" (onChange)="filtrujPlatnost($event.value)"  [style]="{'width':'8rem'}"></p-dropdown>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-lhc>
          <tr [pSelectableRow]="lhc" [ngClass]="{'pds-data-neplatne': isInvalid(lhc)}">
            <td style="flex-basis: 20%">{{lhc.lhcKod}}</td>
            <td style="flex-basis: 55%">{{lhc.lhcNaz}}</td>
            <td style="flex-basis: 25%">{{lhc.lhpOd?.getFullYear()}} - {{lhc.lhpDo?.getFullYear()}}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <div class="p-col-1" style="height:100%">
      <p-table [value]="oddList" [scrollable]="true" scrollHeight="flex" selectionMode="single" (onRowSelect)="onOddSelect($event)"
               (onRowUnselect)="onOddUnselect()" [(selection)]="selectedOdd" dataKey="guid" styleClass="p-datatable-striped">
        <ng-template pTemplate="header">
          <tr>
            <th>
              Oddělení
            </th>
          </tr>
          <tr>
            <th>
              <p-columnFilter type="text" field="odd" matchMode="equals" [showMenu]="false"></p-columnFilter>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-odd>
          <tr [pSelectableRow]="odd">
            <td>{{odd.odd}}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <div class="p-col-1" style="height:100%">
      <p-table [value]="dilList" [scrollable]="true" scrollHeight="flex" selectionMode="single" (onRowSelect)="onDilSelect($event)"
               (onRowUnselect)="onDilUnselect()" [(selection)]="selectedDil" dataKey="guid" styleClass="p-datatable-striped">
        <ng-template pTemplate="header">
          <tr>
            <th>
              Dílec
            </th>
          </tr>
          <tr><th style="height: 4.571rem;"></th></tr>
        </ng-template>
        <ng-template pTemplate="body" let-dil>
          <tr [pSelectableRow]="dil">
            <td>{{dil.dil}}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <div class="p-col-1" style="height:100%">
      <p-table [value]="porList" [scrollable]="true" scrollHeight="flex" selectionMode="single"
               [(selection)]="selectedPor" dataKey="guid" styleClass="p-datatable-striped">
        <ng-template pTemplate="header">
          <tr>
            <th>Porost</th>
          </tr>
          <tr><th style="height: 4.571rem;"></th></tr>
        </ng-template>
        <ng-template pTemplate="body" let-por>
          <tr [pSelectableRow]="por">
            <td>{{por.por}}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
  <p-footer>
    <button type="button" pButton icon="pi pi-check" label="Použít" (click)="save()" [disabled]="!selectedPor"></button>
    <button type="button" pButton icon="pi pi-times" label="Zavřít" (click)="displayDialog=false" class="p-button-secondary"></button>
  </p-footer>
</p-dialog>

