import { Component, AfterViewInit } from '@angular/core';
import Layer from 'ol/layer/Layer';
import { ConstructMapLayerUtils } from '../../../Utils/Mapa/construnct-map-layer.utils';
import { MapServices } from '../../../Services/Mapa/map.service';
import { LayerDefinitionDto } from '../../../Dto/Mapa/LayerDefinitionDto';
import { NacrtProjektSourceDto } from '../../../Dto/Nacrty/NacrtProjectSourceDto';
import { VectorSourceDto } from '../../../Dto/Mapa/VectorSourceDto';
import { MapInteractionService } from '../../../Services/Mapa/map-interaction.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MapNavigationParams } from '../../../Dto/Mapa/MapNavigationParams';
import { Extent } from 'ol/extent';
import { ExtentUtils } from '../../../Utils/Mapa/extent.utils';
import { MessageService } from 'primeng/api';
import { MapItemDto } from '../../../Dto/Mapa/MapItemDto';
import { NacrtySchvalitInteractionService } from '../../../Services/Nacrty/nacrty-schvalit-interaction.service';
import { MapToTreeDtoUtils } from '../../../Utils/Mapa/map-to-tree-dto.utils';
import { LayersInteractionService } from '../../../Services/Mapa/layers-interaction.service';
import { ExternalMessagingService } from '../../../Services/Shared/external-messaging.service';

/**
 * Komponeta omalující mapové okno a související věci.
**/
@Component({
  selector: 'app-map-container',
  templateUrl: './map-container.component.html',
  styleUrls: ['./map-container.component.css'],
  providers: [MapInteractionService, NacrtySchvalitInteractionService, LayersInteractionService] //https://angular.io/guide/providers#limiting-provider-scope-with-components
})
export class MapContainerComponent implements AfterViewInit {

  constructor(
    private construnctLayerUtils: ConstructMapLayerUtils,
    private mapService: MapServices,
    private mapInteractionService: MapInteractionService,
    private layersInteractionService: LayersInteractionService,
    private router: Router,
    private route: ActivatedRoute,
    private extentUtils: ExtentUtils,
    private messageService: MessageService,
    private mapToTreeDtoUtils: MapToTreeDtoUtils,
    private externalMessagingService: ExternalMessagingService)
  {
    this.externalMessagingService.init();
    this._loadLayers();

    this.mapInteractionService.MoveEnd.subscribe(this._moveEndHandler.bind(this));
    this.mapInteractionService.MapClick.subscribe(this._mapClickHandler.bind(this));
    this.mapInteractionService.CloseInfo.subscribe(this._closeInfoHandler.bind(this));
    this.mapInteractionService.RemoveFromSelection.subscribe(this._removeFromSelectionHandler.bind(this));
    this.mapInteractionService.ZoomToIfNoUrlBbox.subscribe(this._zoomToIfNoUrlBboxHandler.bind(this));
    this.mapInteractionService.RequestReload.subscribe(this._reloadLayesHandler.bind(this));
  }

  /**
   * příznak probíhajícího načítání vrstev z WebAPI
   */
  loadingLayers: boolean = false;

  layerSources: LayerDefinitionDto[] = [];
  nacrtProjects: NacrtProjektSourceDto[] = [];
  layers: Layer<any, any>[] = [];
  visibleLayers: string[] = [];
  showMapControls: boolean = true;
  mapElementId: string = 'map';
  readonly TOAST_KEY: string = 'mapContainerToastKey';
  //příznak, zda se má po aktualizaci parametrů URL provádět nějaká akce (true - neprovádět nic).
  private _urlDoNothing: boolean = false;
  private _doBboxChange: boolean = true;
  //příznak, zda se má při zpracování změny URL provádět akce navázaná na souřadnice.
  private _coordsDoNothing: boolean = false;
  private _doMapClick: boolean = false;
  private _doZoomTo: boolean = false;


  ngAfterViewInit() {
    this.layersInteractionService.LayersLoaded.subscribe(_ => {
      this.route.queryParams.subscribe(params => {
        this._processParams(params);
      });
    });

    this.mapInteractionService.MapCreated.subscribe(map => {
      if (map.getTargetElement().id == this.mapElementId) {
        let lastPosition = localStorage.getItem(this.mapInteractionService.LAST_POSITION_KEY);
        if (lastPosition != void 0) {
          this.mapInteractionService.zoomTo(lastPosition.split(',').map(Number));
        }
        else {
          this.mapInteractionService.zoomTo([-905000, -1228000, -430000, -934000]); //extent ČR dle ortofoto mapy.
        }
      }
    });

    this.mapInteractionService.createMap(this.mapElementId);
  }


  /**
   * Handler změny viditelnosti vrstev.
   * @param event {object} Key: value páry, kde key == id vrstvy a value = true/false.
   */
  onVisibleLayersChanged(event: any): void {
    let visible: string[] = [];
    Object.keys(event).forEach(key => {
      if (event[key]) {
        visible.push(key);
      }
    });

    this.visibleLayers = visible;
  }


  /**
   * Přenačtení mapových vrstev, včetně jejich zdrojů.
  **/
  private _reloadLayesHandler(): void {
    this._loadLayers();
    this.layersInteractionService.refreshSources();
  }


  /**
   * Načtení zdrojů pro vytvoření mapových vrstev a vytvoření mapových vrstev.
  **/
  private _loadLayers(): void {
    this.loadingLayers = true;
    this.layers = [];
    this.layerSources = [];
    this.nacrtProjects = [];

    this.mapService.getLayerSources().subscribe(sources => {
      //vrstvy seřadíme podle názvu vrstvy a pak sestupně dle z-indexu
      //řazení provádíme již zde, aby se nemuselo dělat na více místech opakovaně.
      var sortedSources = sources
        .sort((a: LayerDefinitionDto, b: LayerDefinitionDto) => { return a.label.localeCompare(b.label, 'cs'); })
        .sort((a: LayerDefinitionDto, b: LayerDefinitionDto) => { return b.zIndex - a.zIndex; });

      this.construnctLayerUtils.constructLayers(sortedSources).then((result) => {
        this.layers = result;
        this.layerSources = sortedSources;
        this.loadingLayers = false;

        this.nacrtProjects = this.mapToTreeDtoUtils.fromLayerDefinitionProjSourceDto(
          this.layerSources.filter(s => s.id.startsWith('nacrty_')), 'nacrty_'.length);
      });
    });
  }


  /**
   * Upraví query parametry v aktuální URL.
   * @param params {Params} parametry
   */
  private _handleUrlParams(params: Params): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: params,
      queryParamsHandling: 'merge'
    });
  }


  /**
   * Vrátí všechny query parametry nastavené na prázdno.
   * @param preserve: string[] seznam názvů query parametrů, které se mají přeskočit
  **/
  private _getEmptyParams(preserve: string[] = []): Params {
    let params: Params = {};
    Object.keys(MapNavigationParams).forEach(key => {
      if (!preserve.includes(key))
        params[key] = null;
    });
    return params;
  }


  /**
   * Zpracování parametrů URL.
   * @param params {Params} query parametry URL
   */
  private _processParams(params: Params): void {
    if (this._urlDoNothing) {
      this._urlDoNothing = false;
      return;
    }

    let keys = Object.keys(params);
    //parametr 'typ' je v URL pouze pro externí navigaci ve spojení s 'id' nebo identifikací jprl
    this._doZoomTo = !this._doMapClick && !keys.includes(MapNavigationParams.bbox) && !keys.includes(MapNavigationParams.typ);

    if (keys.includes(MapNavigationParams.coords)) {
      if (!this._coordsDoNothing) {
        this.mapInteractionService.coordinates(params[MapNavigationParams.coords].map(x => parseFloat(x)));
        this.mapInteractionService.toggleInfoPanel(true);
      }
      this._coordsDoNothing = false;
    }

    if (keys.includes(MapNavigationParams.bbox)) {
      this.mapInteractionService.zoomTo(params[MapNavigationParams.bbox].map(x => parseFloat(x)));
    }

    if (keys.includes(MapNavigationParams.dotaz)) {
      if (this._doMapClick) {
        this._doMapClick = false;
        this.mapInteractionService.processDotazMapClick(params[MapNavigationParams.dotaz]);
      }
      else {
        this.mapInteractionService.processDotazExternalNavigation(params[MapNavigationParams.dotaz]);
      }
    }
    else if (keys.includes(MapNavigationParams.id) && keys.includes(MapNavigationParams.typ)) {
      this._processGetById(params);
    }
    else if (keys.includes(MapNavigationParams.lhc) && keys.includes(MapNavigationParams.odd)
      && keys.includes(MapNavigationParams.dil) && keys.includes(MapNavigationParams.por)
      && keys.includes(MapNavigationParams.typ) && keys.includes(MapNavigationParams.jprl)) {
      this._processGetByJprl(params);
    }
  }


  /**
   * Zpracování parametrů URL pro získání mapového objektu dle id a typu.
   * @param params {Params} URL query parametry
   */
  private _processGetById(params: Params) {
    this.mapService
      .getMapItemById(
        params[MapNavigationParams.id],
        params[MapNavigationParams.typ])
      .subscribe(mapItem => {
        this._getMapItemPostProcess(mapItem, params[MapNavigationParams.bbox] == void 0);
      });
  }


  /**
   * Zpracování parametrů URL pro získání mapového objektu dle identifikace JPRL.
   * @param params {Params} URL query parametry
   */
  private _processGetByJprl(params: Params) {
    this.mapService
      .getMapItemByJprl(
        params[MapNavigationParams.lhc],
        params[MapNavigationParams.odd],
        params[MapNavigationParams.dil],
        params[MapNavigationParams.por],
        params[MapNavigationParams.jprl],
        params[MapNavigationParams.typ],
      )
      .subscribe(mapItem => {
        this._getMapItemPostProcess(mapItem, params[MapNavigationParams.bbox] == void 0);
      });
  }


  /**
   * Post process získání mapového objektu z web api.
   * @param mapItem {MapItemDto} mapový objekt
   */
  private _getMapItemPostProcess(mapItem: MapItemDto, doZoomTo: boolean): void {
    if (mapItem != void 0) {
      if (doZoomTo) {
        this._doBboxChange = false;
        this.mapInteractionService.zoomTo(this.extentUtils.addBuffer(this.extentUtils.getExtent(mapItem.wkt)));
      }
      this.mapInteractionService.selectedItems([mapItem]);
      this.mapInteractionService.toggleInfoPanel(true);
    }
    else {
      this.messageService.add({ severity: 'warn', summary: 'Upozornění', detail: 'Hledaná JPRL nebyla nalezena.', key: this.TOAST_KEY });
    }
  }


  /**
   * Handler dokončeného pohybu mapy.
   * @param extent {Extent} Extent mapy
   */
  private _moveEndHandler(extent: Extent): void {
    if (this._doBboxChange) {
      this._urlDoNothing = true;
      let params: Params = {};
      params[MapNavigationParams.bbox] = extent;
      this._handleUrlParams(params);
    }
    this._doBboxChange = true;
  }


  /**
   * Handler kliknutí do mapy.
   * @param event {any} objekt se souřadnimcemi a guidem uloženého dotazu
   */
  private _mapClickHandler(event: any): void {
    this._doMapClick = true;
    this._coordsDoNothing = true;
    let params = this._getEmptyParams([MapNavigationParams.bbox]);
    params[MapNavigationParams.coords] = event.coords;
    params[MapNavigationParams.dotaz] = event.dotazGuid;
    this._handleUrlParams(params);
  }


  /**
   * Handler zavření info-panelu.
  **/
  private _closeInfoHandler(): void {
    let params = this._getEmptyParams([MapNavigationParams.bbox]);
    this._handleUrlParams(params);
  }


  /**
   * Handler odebrání mapového objektu z výběru.
   * @param dotazGuid {string} guid uloženého dotazu.
   */
  private _removeFromSelectionHandler(dotazGuid: string): void {
    let params = this._getEmptyParams(['bbox', 'coords']);
    params[MapNavigationParams.dotaz] = dotazGuid;
    this._urlDoNothing = true;
    this._handleUrlParams(params);
  }


  /**
   * Navigace na předaný extent, pokud není specifikován bbox v URL.
   * @param extent {Extent} extent
   */
  private _zoomToIfNoUrlBboxHandler(extent: Extent): void {
    if (this._doZoomTo) {
      this.mapInteractionService.zoomTo(extent);
    }
    this._doZoomTo = false;
  }
}
