import { Component, Input } from '@angular/core';
import { VypocetDetailDto } from 'src/app/Dto/Skody/VypocetDetailDto';
import { LoupaniInfoDto } from 'src/app/Dto/Skody/LoupaniInfoDto';


/**
 * Komponenta zobrazení kroků výpočtu škody S9.1.
**/
@Component({
  selector: 'skody-detail-s91-kroky',
  templateUrl: './skody-detail-s91-kroky.component.html',
  styleUrls: ['./skody-detail-s91-kroky.component.css']
})
export class SkodyDetailS91KrokyComponent {

  constructor() { }

  @Input() skoda: VypocetDetailDto;
  @Input() loupaniDto: LoupaniInfoDto;
  @Input() blockedPrepocet: boolean;
}
