import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { KnihaBase } from 'src/app/Components/Lhp/kniha-base';
import { CiselnikListDto } from 'src/app/Dto/Shared/CiselnikListDto';
import { DrevinDetailDto } from 'src/app/Dto/Lhp/DrevinDetailDto';
import { KnihaService } from 'src/app/Services/Lhp/kniha.service';
import { CiselnikyService } from 'src/app/Services/Shared/ciselniky.service';

@Component({
  selector: 'app-kniha-drevina',
  templateUrl: './kniha-drevina.component.html',
  styleUrls: ['./kniha-drevina.component.css']
})
export class KnihaDrevinaComponent extends KnihaBase {
  constructor(
    route: ActivatedRoute,
    knihaService: KnihaService,
    private _ciselnikService: CiselnikyService
  ) {
    super(route, knihaService);
  }

  model: DrevinDetailDto;
  cpuvdr: CiselnikListDto;

  loadModel() {

    this.knihaService.getDrevina(this.lhcKod, this.odd, this.dil, this.por, this.psk, this.etaz, this.drevin, this.guid)
      .subscribe(response => {
        this.model = response;
        this.drevinGuid = this.model.guid;
        this.etzGuid = this.model.lhpGuids.rozdeleniLesaGuid;
        this.pskGuid = this.model.lhpGuids.rozdeleniLesaGuid;
        this.porGuid = this.model.lhpGuids.porGuid;
        this.dilGuid = this.model.lhpGuids.dilGuid;
        this.oddGuid = this.model.lhpGuids.oddGuid;
        this.lhcGuid = this.model.lhpGuids.lhcGuid;
        this.constructBreadCrumbs();

        this._ciselnikService.getCislenik("CPuvDr", null).subscribe(res => {
          this.cpuvdr = res.find(puvdr => puvdr.guid == this.model.drPuvodGuid);
        });
      });
  }
}
