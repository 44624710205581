import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { faBook, faCopy, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { LhpMapItemDto } from '../../../Dto/Lhp/LhpMapItemDto';
import { MapInteractionService } from '../../../Services/Mapa/map-interaction.service';
import { ExtentUtils } from '../../../Utils/Mapa/extent.utils';
import { NavUtils } from '../../../Utils/Shared/nav.utils';

/**
 * Komponenta pro zobrazení vybraných mapových položek z modulu LHP.
**/
@Component({
  selector: 'app-map-info-lhp',
  templateUrl: './map-info-lhp.component.html',
  styleUrls: ['./map-info-lhp.component.css']
})
export class MapInfoLhpComponent {

  @Input() item: LhpMapItemDto;


  constructor(
    private router: Router,
    private extentUtils: ExtentUtils,
    private navUtils: NavUtils,
    private mapInteractionService: MapInteractionService)
  {
    this.mapInteractionService.DeactivateButtons.subscribe(deactivate => this.disabledBtn = deactivate);
  }

  faBook = faBook;
  faSearch = faSearch;
  faCopy = faCopy;
  faTimes = faTimes;
  disabledBtn: boolean = false;


  /**
   * Navigace do detailu knihy na vybrané PSK
   */
  goToKniha(): void {
    if (this.navUtils.display) {
      let params = {};
      params[this.item.typ] = this.item.id;
      this.router.navigate(['kniha'], { queryParams: params });
    }
    else {
      console.log('goToKniha' + JSON.stringify(this.item));
    }
  }


  /**
   * Přiblížení na extent vybrané PSK
   */
  zoomTo(): void {
    this.mapInteractionService.zoomTo(this.extentUtils.addBuffer(this.extentUtils.getExtent(this.item.wkt)));
  }


  /**
   * Handler kliknutí na tlačítko zkopírování JPRL do náčrtů.
   */
  copy(): void {
    this.mapInteractionService.copy(this.item);
  }


  /**
   * Odebrání JPRL z výběru.
   */
  removeFromSelection(): void {
    this.mapInteractionService.removeFromSelection(this.item.id);
  }
}
