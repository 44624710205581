import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { LayerBehaviourType } from '../../../Dto/Mapa/LayerBehaviourType';
import { LayerDefinitionDto } from '../../../Dto/Mapa/LayerDefinitionDto';
import { MapInteractionService } from '../../../Services/Mapa/map-interaction.service';
import { LayerTreeGenerator } from '../../../Utils/Mapa/layer-tree-generator';
import { Extent } from 'ol/extent';
import { MapToTreeDtoUtils } from '../../../Utils/Mapa/map-to-tree-dto.utils';
import { TreeSearchUtils } from '../../../Utils/Shared/tree-search.utils';
import { LocalStorageLayersInteractionService } from '../../../Services/Mapa/local-storage-layers-interaction.service';
import { LayersInteractionService } from '../../../Services/Mapa/layers-interaction.service';

/**
 * Komponenta pro zobrazení seznamu vrstev.
**/
@Component({
  selector: 'app-map-layer-list',
  templateUrl: './map-layer-list.component.html',
  styleUrls: ['./map-layer-list.component.css']
})
export class MapLayerListComponent {

  /**
   * Eventa indikující změnu viditelnosti vrstev. Předává seznam názvů viditelných vrstev.
  **/
  @Output() visibleLayersChanged: EventEmitter<string[]> = new EventEmitter<string[]>();

  private _layerSources: LayerDefinitionDto[] = [];

  /**
   * Zdrojová data mapových vrstev.
  **/
  @Input() set layerSources(value: LayerDefinitionDto[]) {
    if (value != void 0 && value.length > 0) {
      this._layerSources = value;
      this.layersData = this.layerTreeGenerator.getTree(this.mapToTreeDtoUtils.fromLayerDefinitionToTreeDto(this._layerSources));

      //skupina neplatných LHP/O nesmí být selectable, ale její podskupiny již ano
      let neplatneLhpoGroup = this.layersData.find(x => x.label == 'Neplatné LHPO');
      if (neplatneLhpoGroup != void 0)
        neplatneLhpoGroup.selectable = false;

      this.selectedLayersData = this._getSelectedNodes(this.layersData, this.localStoragelayersInteractionServices.getVisibleList());

      this._initSelectedBaseLayers(this.selectedLayersData);
      this.visibleLayersChanged.emit(this._getStoredVisibility(this.PROCESSED_LIST_KEY));
      this._updateBadges();
    }
  }

  

  constructor(
    private mapInteractionService: MapInteractionService,
    private layerTreeGenerator: LayerTreeGenerator,
    private mapToTreeDtoUtils: MapToTreeDtoUtils,
    private treeSearchUtils: TreeSearchUtils,
    private localStoragelayersInteractionServices: LocalStorageLayersInteractionService,
    private layersInteractionService: LayersInteractionService)
  {
    this.templateNameBase = LayerBehaviourType.baseLayer;
    this.templateNameOverlay = LayerBehaviourType.overlayLayer;
    this.layersInteractionService.TurnOnLayer.subscribe(this._turnOnLayerHandler.bind(this));
  }


  faSearch = faSearch;
  //uzly stromu
  layersData: TreeNode[] = [];
  //vybrané uzly stromu
  selectedLayersData: TreeNode[] = [];
  //Slouží pro obsluhu radiobutonů ve stromu. Tree by default nepodporuje uzly jako skupiny radiobuttonů.
  //Klíčem je id skupiny, do které radiobutton patří, hodnotou pak id uzlu
  selectedBaseValues: object = {};
  //počet zapnutých vrstev v rámci skupin stromu
  selectedGroupCounts: object = {};

  templateNameBase;
  templateNameOverlay;
  templateNameGroup: string = "layerGroup";
  //klíč pro uložení seznamu viditelných vrstev (upraveného pro komunikaci s okolím (mapou)) do localStorage
  private readonly PROCESSED_LIST_KEY: string = "visibleLayersProcessed";
  //klíč pro uložení seznamu viditelných base vrstev do localStorage
  private readonly SELECTED_BASE_LAYERS: string = "selectedBaseLayers";

  /** --------- handlery --------- **/

  /**
   * Handler výběru uzlu stromu (uzlel musí být selectable).
   * @param node {TreeNode} vybraný uzel stromu
   */
  public nodeSelect(node: TreeNode): void {
    this._switchDependentLayers(node);
    this._saveLayersLists(this.selectedLayersData);
    this.visibleLayersChanged.emit(this._getProcessedList());
    this._updateBadges();
  }

  /**
   * Handler odvybrání uzlu stromu (uzlel musí být selectable).
   * @param node {TreeNode} odvybraný uzel stromu
   */
  public nodeUnselect(node: TreeNode): void {
    this._saveLayersLists(this.selectedLayersData);
    this.visibleLayersChanged.emit(this._getProcessedList());
    this._updateBadges();
  }


  /**
   * Handler přepnutí base vrstvy.
   * Base vrstvy (skupiny radiobuttonů) nejsou v tree podporovány, proto mají vlastní obsluhu.
   * @param node {TreeNode} node na který bylo kliknuto
  **/
  onBaseLayerClicked(node: TreeNode): void {
    let selectedId = this.selectedBaseValues[node.data['groupName']];

    //workaround odvybrání radiobuttonu, které prineng API neimplementuje
    let storedSel = JSON.parse(localStorage.getItem(this.SELECTED_BASE_LAYERS));
    if (storedSel != void 0 && storedSel[node.data['groupName']] == node.key) {
      delete this.selectedBaseValues[node.data['groupName']];
      selectedId = null;
    }

    //aktualizace vybraných tree nodes našimi base vrstvami
    let idx = this.selectedLayersData.findIndex(s => s.parent?.key == node.data['groupName'] && s.type == LayerBehaviourType.baseLayer.toString());
    if (idx != -1) {
      this.selectedLayersData.splice(idx, 1);
    }
    if (selectedId != void 0) {
      this.selectedLayersData.push(this.treeSearchUtils.findNode(selectedId, this.layersData));
    }

    this._saveLayersLists(this.selectedLayersData);
    this.visibleLayersChanged.emit(this._getProcessedList());

    this._updateBadges();
  }

  /** --------- handlery konec --------- **/


  /** --------- metody volané přímo z html --------- **/
  
  /**
   * Vrátí tooltip, který patří k vrstvě podle treeNode
   * @param node  {TreeNode} 
   */
  getTooltip(node: TreeNode): string {
    let tooltip: string = "";
    var description = node.data['description'];
    tooltip = description == void 0 ? node.label : node.label + "\n" + description;
    return tooltip;
  }


  /**
   * Handler přiblížení na extent vrstvy.
   * @param event {PointerEvent} eventa kliku na tlačitko. Strom ji nesmí odchytit, jinak dodje k výběru.
   * @param bbox {Extent} bouding-box vrstvy
   */
  zoomTo(event: PointerEvent, bbox: Extent): void {
    event.preventDefault();
    event.stopPropagation();
    this.mapInteractionService.zoomTo(bbox);
  }

  /** --------- metody volané přímo z html konec--------- **/


  /**
   * Vrátí zpracovaný seznam viditelných vrstev z localStorage.
  **/
  private _getProcessedList(): string[] {
    return localStorage.getItem(this.PROCESSED_LIST_KEY).split(',');
  }


  /**
   * Uloží/aktualizuje v localStorage seznamy názvů vrstev v nezpracovaném i zpracovaném stavu, včetně seznamu base vrstev.
  **/
  private _saveLayersLists(data: TreeNode[]): void {
    localStorage.setItem(this.SELECTED_BASE_LAYERS, JSON.stringify(this.selectedBaseValues));

    let unprocessedList: string[] = [];
    unprocessedList = data.filter(node => node.leaf).map(node => node.key);
    this.localStoragelayersInteractionServices.setVisibleList(unprocessedList);

    let processedList = this._processVisibleList(unprocessedList);
    localStorage.setItem(this.PROCESSED_LIST_KEY, processedList.join(','));
  }


  /**
   * Upraví seznam názvů viditelných vrstev tak, aby zohlednil inverzní varianty vrstev.
   * @param list {string[]} seznam názvů viditelných vrstev
   */
  private _processVisibleList(list: string[]): string[] {
    let processedList: string[] = list.slice();

    processedList.forEach(name => {
      //vyhledáme všechny zdroje, které jsou závislé na zpracovávané vrstvě a dle potřeby je přepneme na jejich inverzní variantu
      let dependentSources = this._layerSources.filter(s => s.inversion?.conditionalLayer == name);
      if (dependentSources.length > 0) {
        dependentSources.forEach(ds => {
          let idx = processedList.findIndex(pl => pl == ds.id);
          //závislá vrstva je zapnutá, tj. nahradíme ji za její inverzní variantu
          if (idx > -1) {
            processedList.splice(idx, 1, ds.inversion.id);
          }
        });
      }
    });

    return processedList;
  }


  /**
   * Vrátí seznam názvů viditelných vrstev uložený v localStorage.
   * @param key {string} klíč, pod kterým je seznam uložený
  **/
  private _getStoredVisibility(key: string): string[] {
    let storedVisibility = localStorage.getItem(key);
    if (storedVisibility != void 0 && storedVisibility != '') {
      return storedVisibility.split(',');
    }
    return [];
  }


  /**
   *  Aktualizace počtu zapnutých vrstev.
  **/
  private _updateBadges(): void {
    this.selectedGroupCounts = {};
    this.selectedLayersData.filter(node => node.leaf).forEach(node => {
      this.__updateBadgesInner(node);
    });
  }


  /**
   * Spočítá počet zapnutých vrstev uzlu stromu a jeho rodičů.
   * @param node {TreeNode} uzel stromu
   */
  private __updateBadgesInner(node: TreeNode): void {
      if (node.parent) {
        let cnt = this.selectedGroupCounts[node.parent.key];
        this.selectedGroupCounts[node.parent.key] = cnt == void 0 ? 1 : cnt + 1;
        this.__updateBadgesInner(node.parent);
      }
  }


  /**
   * Vypnutí závislých vrstev uzlu (např. u ISLH map).
   * @param node {TreeNode} uzel stromu
   */
  private _switchDependentLayers(node: TreeNode): void {
    let leafIds = this._findLeafNodes(node).map(n => n.key);
    let toOffs = this._layerSources.filter(s => leafIds.includes(s.id) && s.toOffOnOn != void 0 && s.toOffOnOn.length > 0).map(s => s.toOffOnOn);

    if (toOffs.length > 0) {
      let toOffOnOn = toOffs.reduce((prev, cur) => prev.concat(cur), []);

      let unprocessed = this.localStoragelayersInteractionServices.getVisibleList();
      unprocessed = unprocessed.filter(name => !toOffOnOn.includes(name));
      leafIds.forEach(id => {
        if (!unprocessed.includes(id)) {
          unprocessed.push(id);
        }
      });

      //vybrané vrstvy sestavujeme "znova", proto potřebuji resetovat nastavení "partialSelected", které se při sestavování stromu nastavuje
      this.layersData.forEach(node => this._partialSelectedReset(node));
      this.selectedLayersData = this._getSelectedNodes(this.layersData, unprocessed);
    }
  }


  /**
   * Rekurzivně nastaví "partialSelected" na false v uzlu a všech jeho rodičích.
   * @param node {TreeNode} uzel stromu
   */
  private _partialSelectedReset(node: TreeNode): void {
    node.partialSelected = false;
    if (node.parent) {
      this._partialSelectedReset(node.parent);
    }
  }


  /**
   * Vrátí vybrané uzly stromu dle id. Pokud je vybrána celá skupina vrstev, je v tomto výběru také.
   * @param tree {TreeNode[]} strom, ve kterém hledáme
   * @param visibleIds {string[]} seznam id vrstev, které jsou zapnuté
   */
  private _getSelectedNodes(tree: TreeNode[], visibleIds: string[]): TreeNode[] {
    let resultNodes: TreeNode[] = [];

    visibleIds.forEach(id => {
      let node: TreeNode = this.treeSearchUtils.findNode(id, tree);
      if (node != void 0) {
        node.partialSelected = false;
        resultNodes.push(node);
        resultNodes = this._setPartialSelected(node, resultNodes);
      }
    });

    return resultNodes;
  }


  /**
   * Nastavení 'partitalSelected' u rodičovských uzlů.
   * Zároveň vrací seznam rodičovských uzlů, jejichž potomci jsou ve výběru všichni.
   * @param node {TreeNode} uzel, jehož rodič se nastavuje
   * @param resultNodes {TreeNode[]} seznam rodičovských uzlů, jejichž potomci jsou ve výběru všichni
   */
  private _setPartialSelected(node: TreeNode, resultNodes: TreeNode[]): TreeNode[] {
    if (node.parent == void 0) {
      if (node.children.filter(ch => ch.partialSelected == false).length == node.children.length) {
        node.partialSelected = false;
        if (!resultNodes.includes(node)) {
          resultNodes.push(node);
        }
      }
      else {
        node.partialSelected = true;
      }

      return resultNodes;
    }

    if (node.parent.children.filter(ch => ch.partialSelected == false).length == node.parent.children.length) {
      node.parent.partialSelected = false;
      if (!resultNodes.includes(node.parent)) {
        resultNodes.push(node.parent);
      }
    }
    else {
      node.parent.partialSelected = true;
    }

    return this._setPartialSelected(node.parent, resultNodes);
  }


  /**
   * Rozbalení všech úrovní stromu.
  **/
  expandAll(): void {
    this._expandRecursive(this.layersData, true);
  }


  /**
   * Sbalení všech úrovní stromu.
  **/
  collapseAll(): void {
    this._expandRecursive(this.layersData, false);
  }


  /**
   * Rozbalení/sbalení všech úrovní stromu.
   * @param nodes {TreeNode[]} seznam uzlů
   * @param expand {boolean} true - rozbalit, false - sbalit
   */
  private _expandRecursive(nodes: TreeNode[], expand: boolean): void {
    nodes.forEach(node => {
      node.expanded = expand;
      if (node.children) {
        this._expandRecursive(node.children, expand);
      }
    });
  }



  /**
   * Vrátí všechny terminální uzly, které jsou potomkem předaného uzlu nebo předaný uzel, pokud je sám terminálním uzlem.
   * @param node {TreeNode} uzel stromu
   */
  private _findLeafNodes(node: TreeNode): TreeNode[] {
    if (node.leaf) {
      return [node];
    }
    else {
      let res: TreeNode[] = [];
      if (node.children) {
        node.children.forEach(child => {
          res = res.concat(this._findLeafNodes(child));
        });
      }
      return res;
    }
  }


  /**
   * Úvodní nastavení vybraných base vrstev podle vybraných uzlů.
  **/
  private _initSelectedBaseLayers(nodes: TreeNode[]): void {
    let selectedBaseLayers = nodes.filter(node => node.type == LayerBehaviourType.baseLayer.toString());
    if (selectedBaseLayers.length > 0) {
      //v rámci jedné skupiny vrstev nepředpokládám vice různých skupin radiobuttonů
      selectedBaseLayers.forEach(l => {
        this.selectedBaseValues[l.data['groupName']] = l.key;
      });
    }
    localStorage.setItem(this.SELECTED_BASE_LAYERS, JSON.stringify(this.selectedBaseValues));
  }


  /**
   * Handler zapnutí vrstvy.
   * @param layerName {string} Id vrstvy k zapnutí
   */
  private _turnOnLayerHandler(layerName: string): void {
    let visibleLayers = this.localStoragelayersInteractionServices.getVisibleList();
    if (!visibleLayers.includes(layerName)) {
      visibleLayers.push(layerName);
      this.selectedLayersData = this._getSelectedNodes(this.layersData, visibleLayers);
      this._saveLayersLists(this.selectedLayersData);
      this.visibleLayersChanged.emit(this._getProcessedList());
      this._updateBadges();
    }
  }
}
