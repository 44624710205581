import { Component, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { DruhVypoctu } from 'src/app/Utils/Skody/druh-vypoctu.utils';
import { LhpoService } from 'src/app/Services/Lhp/lhpo.service';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { DrzitelListDto } from 'src/app/Dto/Skody/DrzitelListDto';
import { UzivatelListDto } from 'src/app/Dto/Skody/UzivatelListDto';
import { VypocetDetailDto } from 'src/app/Dto/Skody/VypocetDetailDto';
import { DetailZuComponent } from '../detail-zu/detail-zu.component';
import { CiselnikyService } from '../../../Services/Shared/ciselniky.service';
import { CiselnikListDto } from '../../../Dto/Shared/CiselnikListDto';
import { PodCiselnikListDto } from '../../../Dto/Shared/PodCiselnikListDto';

/**
 * Komponenta pro zadávání základních údajů škody.
**/
@Component({
  selector: 'skody-detail-zu',
  templateUrl: './skody-detail-zu.component.html',
  styleUrls: ['./skody-detail-zu.component.css']
})
export class SkodyDetailZuComponent extends DetailZuComponent implements OnInit {

  constructor(
    fb: FormBuilder,
    ciselnikService: CiselnikyService,
    lhpoService: LhpoService
  ) {
    super(fb, ciselnikService, lhpoService);

    this.data.addControl('drzitel', new FormControl());
    this.data.addControl('uzivatel', new FormControl());
  }

  druhVypoctuList: SelectItem[] = [
    { label: DruhVypoctu.S6, value: DruhVypoctu.S6 },
    { label: DruhVypoctu.S7_2, value: DruhVypoctu.S7_2 },
    { label: DruhVypoctu.S9_1, value: DruhVypoctu.S9_1 }
  ];
  
  ngOnInit() {
    super.ngOnInit();
    this.data.valueChanges
      .pipe(
        debounceTime(600),
        distinctUntilChanged((prev, curr) => prev.guid === curr.guid && prev.typ === curr.typ
          && prev.drzitel == curr.drzitel && prev.uzivatel == curr.uzivatel && prev.lhc == curr.lhc
          && prev.jprl == curr.jprl && prev.etaz == curr.etaz && prev.drevina == curr.drevina),
        filter(data => this.validateVstupyProPrepocet(data))
      )
      .subscribe(() => {
        this.dataChanged.emit(this.data.value);
      });

    this.ciselnikService.getCislenik("CDrzitel", null).subscribe(res => {
      this.drziteleAll = (res as PodCiselnikListDto[]);
    });

    this.ciselnikService.getCislenik("CUzivatel", null).subscribe(res => {
      this.uzivateleAll = res;
    });
  };

  /**
   * Naplnění modelu formuláře daty škody.
   * @param skoda
   */
  populateForm(skoda: VypocetDetailDto): void {
    super.populateForm(skoda);

    this.ciselnikService.getCislenik("CDrzitel", null).subscribe(res => {
      this.data.get('drzitel').setValue(res.find(drzitel => drzitel.guid == skoda.drzitelGuid));
    });

    this.ciselnikService.getCislenik("CUzivatel", null).subscribe(res => {
      this.data.get('uzivatel').setValue(res.find(uzivatel => uzivatel.guid == skoda.uzivatelGuid));
    });
  };


  /** ---------- autocomplete ---------- **/
  navrhovaniDrzitele: PodCiselnikListDto[];
  navrhovaniUzivatele: CiselnikListDto[];

  drziteleAll: PodCiselnikListDto[];
  uzivateleAll: CiselnikListDto[];

  /**
   * Vrátí seznam držitelů pro autocomplete.
   * @param event
   */
  hledejDrzitele(event): void {
    let toSearch = event.query.toLowerCase();
    this.navrhovaniDrzitele = this.drziteleAll.filter(x => x.kod.toLowerCase().startsWith(toSearch));
  };


  /**
   * Vrátí seznam uživatelů pro autocomplete.
   * @param event
   */
  hledejUzivatele(event): void {
    let toSearch = event.query.toLowerCase();
    this.navrhovaniUzivatele = this.uzivateleAll.filter(x => x.popis.toLowerCase().startsWith(toSearch));
  };
}
