import { Directive, ElementRef, Input } from "@angular/core";
import { NacrtProjektDto } from "../../Dto/Nacrty/NacrtProjektDto";

/**
 * Bázová třída pro komponenty náhledu projektu náčrtu
 **/
@Directive()
export abstract class PreviewBase {

  constructor(public hostElement: ElementRef) { }

  /**
  * Projekt s hodnotami náhledu
  */
  @Input() projekt: NacrtProjektDto;

  /**
   * Velikost plátna
   */
  @Input() previewSize: number = 20;

  protected canvas: HTMLCanvasElement;
  protected context: CanvasRenderingContext2D;
  protected initialized: boolean = false;

  /**
   * Vykreslí náhledu
   * @param projekt
   */
  protected drawPreview() {
    this.canvas = this.hostElement.nativeElement.querySelectorAll('canvas')[0];
    if (this.canvas != void 0) {
      this.context = this.canvas.getContext("2d");
      this.context.clearRect(0, 0, this.previewSize, this.previewSize);
      this.initialized = true;
    }
  }

}
