import { Injectable } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { PodCiselnikListDto } from '../../Dto/Shared/PodCiselnikListDto';

/**
 *  Utilita pro konverzi číselníku organizačních úrovní na strom.
 *  FIXME - optimalizovat
 * */
@Injectable({
  providedIn: 'root'
})
export class OrgUrUtils {

  /**
   * zachování celé větve při filtrování. To když je povolené org. ur. 1
   */
  private _useBranche: boolean = false;

  //property pro odfiltrování nepotřebných větví
  private _useLevel1Node: boolean = false;
  private _useLevel2Guid: string = null;
  private _allLevelsSelectable: boolean;
  private _onlyLevel2Selectable: boolean;

  /**
   * Senam org. ur., kterým se má naplnit strom
   */
  private _orgUrList: PodCiselnikListDto[];

  /**
   * Guidy org.ur., ke kterým má uživatel přístup
   */
  private _userOrgUrGuidList: string[];

  /**
   * Vrátí strom s org.ur.
   * @param orgUrList - číselník org. ur.
   * @param userOrgUrGuidList  - seznam org. ur., ke kterým má uživatel přístup
   * @param allLevelsSelectable - true, pokud je povoleno klikat na všechny uzly
   * @param onlyLevel2Selectable - při true jsou zobrazené povolené orgur1 a orgur2. Vybrat jde pouze orgur2.
   * @param useAll - zobrazit bezpodmínečně všechny uzly, pokud mají kompletní data až k prvnímu uzlu. Např. orgur2 bez předka se nepřevede.
   */
  public gerTreeFromCiselnik(orgUrList: PodCiselnikListDto[], userOrgUrGuidList: string[], allLevelsSelectable: boolean = false, onlyLevel2Selectable: boolean = false, useAll: boolean = false): TreeNode<any>[] {
    this._allLevelsSelectable = allLevelsSelectable;
    this._onlyLevel2Selectable = onlyLevel2Selectable;
    this._userOrgUrGuidList = userOrgUrGuidList;
    this._orgUrList = orgUrList;

    var result = [];
    let parentDtoList = orgUrList.filter(x => x.parentGuid == void 0).sort((a, b) => parseInt(a.kod) > parseInt(b.kod) ? 1 : -1);//seřadit orgur1 podle kódu

    parentDtoList.forEach(function (dto) {
      let node = this._cislenikToTreeNode(dto);
      node.selectable = useAll;
      this._useBranche = this._userOrgUrGuidList.includes(dto.guid);
      this._useLevel1Node = useAll;
      this._useLevel2Guid = null;
      this._fillTree(node);
      if (!this._allLevelsSelectable && (node.children == void 0 || node.children.length == 0)) this._useLevel1Node = false;//první úroveň bez potomků pouze u povolení allLevelsSelectable
      if (this._useLevel1Node)
        result.push(node);
    }, this);

    return result;
  }

  /**
   * Naplní větve stromu pouze těmi uzly, které má uživatel povoleny a seřadí podle kódu
   * @param node
   */
  private _fillTree(node: TreeNode): void {
    this._orgUrList.filter(x => x?.parentGuid == node.data.guid).forEach(function (dto) {
      var useOrgUr2 = true;//pomocná property pro odfiltrování orgur2 bez potomků u klikatelné pouze třetí úrovně
      let nodeLoc = this._cislenikToTreeNode(dto);
      nodeLoc.parent = node;
      if (node.children == void 0) {
        node.children = [];
        node.selectable = true;
      } else {
        node.selectable = this._allLevelsSelectable;
      }
      this._fillTree(nodeLoc);

      var isOrgUr3 = true;

      if (node.data.parentGuid == void 0) {//předek je orgur1 tj. dto je orgur2
        if (this._onlyLevel2Selectable)
          nodeLoc.selectable = true;
        if (this._useLevel2Guid != dto.guid) {//pokud se nezměnila druhá úroveň
          this._useLevel2Guid = this._userOrgUrGuidList.includes(dto.guid) ? dto.guid : null;
        }
        if (this._useLevel2Guid != void 0 && ((nodeLoc.children == void 0 || nodeLoc.children.length == 0) && !this._onlyLevel2Selectable) && !this._allLevelsSelectable) {//pokud se vyskytne orgur2 bez potomků a nejde na něj klikat, tak odfiltrovat
          this._useLevel2Guid = null;
          useOrgUr2 = false;
        }
      } else {//orgur3
        isOrgUr3 = false;
        if (this._userOrgUrGuidList.includes(dto.guid)) {//pokud je povolena třetí úroveň a zatím nebyla povolena druhá, tak povolit celou druhou nadřazenou
          this._useLevel2Guid = dto.parentGuid;
        }
        else {
          this._useLevel2Guid = null;
        }
      }

      if (this._useLevel2Guid != void 0) {//pokud je povolená některá druhá úroveň, tak povolit i první
        this._useLevel1Node = true;
      };

      if ((!this._onlyLevel2Selectable || isOrgUr3) && useOrgUr2 && (this._useBranche || this._useLevel2Guid != void 0 || (nodeLoc.children != void 0 && nodeLoc.children.length > 0))) {//pokud je povolený orgur1, nebo pokud je povolen orgur2 (tzn. povolený přímo orgur2, nebo některý z jeho orgur3 ), tak použít
        node.children.push(nodeLoc);
      }
      node.children.sort((a, b) => parseInt(a.data.kod) > parseInt(b.data.kod) ? 1 : -1);//seřadit podle kódu

    }, this);
  }

  /**
   * převede dto na TreeNode
   * @param value
   */
  private _cislenikToTreeNode(value: PodCiselnikListDto): TreeNode {
    return {
      label: value.kod + " - " + value.popis,
      data: value
    };
  }
}
