<form>
  <app-block-ui [block]="blockedPrepocet" [target]="krokyS91" [small]="true"></app-block-ui>
  <p-panel #krokyS91 header="Kroky výpočtu" [style]="{'margin-bottom': '1em'}"
           [toggleable]="true" [collapsed]="true" toggler="header">
    <div class="p-grid" style="font-size:12px;">
      <div class="p-col-10">
        <div class="p-grid p-align-center">
          <div class="p-11-col-1">
            <label>
              Plocha dřev. (m2)
              <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{skoda?.plochaVypocet}}</span>
            </label>
          </div>
          <div class="p-11-col-1">
            <label>
              N (ks)
              <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{loupaniDto?.n}}</span>
            </label>
          </div>
          <div class="p-11-col-1">
            <label>
              Skupina drev. (3)
              <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{loupaniDto?.skupinaDrevin3Nazev}}</span>
            </label>
          </div>
          <div class="p-11-col-1">
            <label>
              Max. obmýtí
              <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{loupaniDto?.skupinaDrevin3MaxObmyti}}</span>
            </label>
          </div>
          <div class="p-11-col-1">
            <label>
              P0
              <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{loupaniDto?.koeficienty?.p0}}</span>
            </label>
          </div>
          <div class="p-11-col-1">
            <label>
              P1
              <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{loupaniDto?.koeficienty?.p1}}</span>
            </label>
          </div>
          <div class="p-11-col-1">
            <label>
              P2
              <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{loupaniDto?.koeficienty?.p2}}</span>
            </label>
          </div>
          <div class="p-11-col-1">
            <label>
              P3
              <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{loupaniDto?.koeficienty?.p3}}</span>
            </label>
          </div>
          <div class="p-11-col-1">
            <label>
              P4
              <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{loupaniDto?.koeficienty?.p4}}</span>
            </label>
          </div>
          <div class="p-11-col-1">
            <label>
              K3
              <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{loupaniDto?.k3}}</span>
            </label>
          </div>
          <div class="p-11-col-1">
            <label>
              Obm. pro výpočet
              <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{loupaniDto?.obmytiVypocet}}</span>
            </label>
          </div>
        </div>

        <div class="p-grid p-align-center">
          <div class="p-11-col-1">
            <label>
              THLPu
              <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{loupaniDto?.thlpu}}</span>
            </label>
          </div>
          <div class="p-11-col-1">
            <label>
              HLPu
              <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{loupaniDto?.hlpu}}</span>
            </label>
          </div>
          <div class="p-11-col-1">
            <label>
              n = u - a
              <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{loupaniDto?.uMinusA}}</span>
            </label>
          </div>
          <div class="p-11-col-1">
            <label>
              1,02^n
              <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{loupaniDto?.powN}}</span>
            </label>
          </div>
          <div class="p-11-col-1">
            <label>
              Np/N
              <span class="p-inputtext ui-corner-all ui-state-disabled disabled-input-like">{{loupaniDto?.npDivN}}</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </p-panel>
</form>
