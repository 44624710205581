import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { faUndo, faSave, faPlus, faTrash, faEllipsisV, faCopy, faBackspace, faTimes } from '@fortawesome/free-solid-svg-icons';
import { CVyrMaticeListDto } from 'src/app/Dto/Vyroba/CVyrMaticeListDto';
import { MaticeService } from 'src/app/Services/Vyroba/matice.service';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { MessagesUtils } from 'src/app/Utils/Shared/messages.utils';
import { CVyrMaticeDetailDto } from 'src/app/Dto/Vyroba/CVyrMaticeDetailDto';
import { CiselnikyService } from 'src/app/Services/Shared/ciselniky.service';
import { ResultInfoGenericDto } from 'src/app/Dto/Shared/ResultInfoDto';
import { FormBuilder, Validators } from '@angular/forms';
import { Dropdown } from 'primeng/dropdown';
import { TableComponent } from '../../Shared/table/table.component';

@Component({
  selector: 'app-vyroba-matice',
  templateUrl: './vyroba-matice.component.html',
  styleUrls: ['./vyroba-matice.component.css']
})
export class VyrobaMaticeComponent implements OnInit {

  private readonly MESSAGE_KEY: string = "vyroba-matice-toast";

  @ViewChild("colDatum", { static: true }) datumTemplate: TemplateRef<any>;
  @ViewChild("maticeTable", { static: true }) table: TableComponent;
  @ViewChild("dokladTyp") dokladTyp: Dropdown;

  items: CVyrMaticeListDto[];
  selectedItem: CVyrMaticeListDto;
  cols: any[] = [];
  block: boolean = false;
  filter: string;
  showDetail: boolean = false;

  doklady: SelectItem[] = [
    { value: 'VML', label: 'VML' },
    { value: 'OL', label: 'OL' }
  ];

  mjList: SelectItem[] = [];

  faPlus = faPlus;
  faTrash = faTrash;
  faSave = faSave;
  faUndo = faUndo;
  faCopy = faCopy;
  faEllipsisV = faEllipsisV;
  faBackspace = faBackspace;
  faTimes = faTimes;

  form = this.formBuilder.group({
    guid: [''],
    dokladTypKod: ['', Validators.required],
    podvykonGuid: ['', Validators.required],
    mjFakturaceGuid: [''],
    poznamka: [''],
    platnostOd: ['', Validators.required],
    platnostDo: [''],
    vlozil: [''],
    vlozilKdy: [''],
    opravil: [''],
    opravilKdy: [''],
    povolMj: [''],
    polozky: [''],
    vykonGuid: ['', Validators.required] // dávám nakonec, protože není v DTO, ale musí zde být aby formulář držel pohromadě
  });

  get vykonGuid() {
    return this.form.get('vykonGuid');
  }

  get data(): CVyrMaticeDetailDto {
    return this.form.value;
  }

  set data(val: CVyrMaticeDetailDto) {
    this.form.reset();
    this.form.patchValue(val);
  }

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private messageUtils: MessagesUtils,
    private maticeService: MaticeService,
    private ciselnikyService: CiselnikyService,
    private confirmationService: ConfirmationService
  ) { }

  ngOnInit(): void {
    this._initCols();
    this.ciselnikyService.getCislenik("CMj", null).subscribe(cis => {
      this.mjList = cis
        .sort((a, b) => { return Number(a.kod) - Number(b.kod); })
        .map(item => { return { label: item.kod + " - " + item.popis, value: item.guid }; });
    });

    this._loadList();
  }

  /**
   * Inicializace sloupců pro grid
   */
  private _initCols() {
    this.cols = [
      { field: 'dokladTypKod', header: 'Doklad', class: 'col-4' },
      { field: 'vykon', header: 'V', class: 'col-3' },
      { field: 'podvykon', header: 'PV', class: 'col-3' },
      { field: 'podvykonPopis', header: 'Podvýkon', class: 'col-25' },
      { field: 'poznamka', header: 'Poznámka', class: 'col-25' },
      { field: 'platnostOd', header: 'Platnost od', class: 'col-5', template: this.datumTemplate },
      { field: 'platnostDo', header: 'Platnost do', class: 'col-5', template: this.datumTemplate },
      { field: 'vlozil', header: 'Vložil', class: 'col-10 col-hide' },
      { field: 'vlozilKdy', header: 'Vloženo kdy', class: 'col-5 col-hide', template: this.datumTemplate },
      { field: 'opravil', header: 'Opravil', class: 'col-10 col-hide' },
      { field: 'opravilKdy', header: 'Opraveno kdy', class: 'col-5 col-hide', template: this.datumTemplate }
    ];
  }

  add(): void {
    var newData = new CVyrMaticeDetailDto();
    newData.dokladTypKod = this.doklady[0].value;
    this.data = newData;
    this.vykonGuid.setValue(null);
    this.showDetail = true;
    this.focusFirst();
  }

  back(): void {
    this.router.navigate(["/vyroba"]);
  }

  /**
   * Handler vybrání řádku gridu
   * @param dto
   */
  onRowSelected(dto: CVyrMaticeListDto): void {
    this.block = true;
    this.vykonGuid.setValue(null);
    this.maticeService.getByGuid(dto.guid).subscribe(resp => { this._processDetailResponse(resp); });
    this.selectedItem = dto;
  }

  /**
   * Handler zrušení výběru řádku
   * @param dto
   */
  onRowUnSelected(dto: CVyrMaticeListDto): void {
    this.showDetail = false;
    this.selectedItem = null;
  }

  /**
   * Zpracování odpovědi s detailem (načtení detailu do formuláře, případně zobrazení hlášky)
   * @param resp
   * @param successMessage
   */
  private _processDetailResponse(resp: ResultInfoGenericDto<CVyrMaticeDetailDto>, successMessage?: string) {
    if (resp.success) {
      this.data = resp.data;
      this.ciselnikyService.getCislenik("CPodvykon", null).subscribe(cis => {
        var pv = cis.find(x => x.guid == this.data.podvykonGuid);
        this.vykonGuid.setValue(pv["parentGuid"]);
        this.block = false;
        this.showDetail = true;
        this.focusFirst();
      });
      if (successMessage != undefined) {
        this._showMessage(resp, successMessage);
      }
    }
    else {
      this._showMessage(resp);
      this.block = false;
    }
  }

  /**
   * Podle obsahu response zobrazi prislusnou zpravu
   * @param resp response
   * @param successMessage text message zobrazeny v pripade, ze resp.success je true
   */
  private _showMessage(resp: ResultInfoGenericDto<CVyrMaticeDetailDto>, successMessage?: string) {
    if (resp.success) {
      if (successMessage != undefined) {
        this.messageService.add({ severity: 'success', summary: 'OK', key: this.MESSAGE_KEY, detail: successMessage, life: MessagesUtils.TOAST_LIFE })
      }
    }
    else if (!resp.success) {
      this.messageUtils.showResponseMessage(this.MESSAGE_KEY, resp);
    }
    else {
      this.messageService.add({ severity: 'error', summary: 'Chyba', key: this.MESSAGE_KEY, detail: 'Došlo k neočekávané chybě.', life: MessagesUtils.TOAST_LIFE })
    }
  }


  /**
   * Aktualizace dat v gridu po uložení
   * @param resp
   */
  private _updateInGrid(resp: ResultInfoGenericDto<CVyrMaticeDetailDto>) {
    if (resp.success) {
      var listDto = this.items.find(x => x.guid == resp.data.guid);

      if (listDto == undefined) {
        listDto = new CVyrMaticeListDto();
        listDto.guid = resp.data.guid;
        this.items.push(listDto);
        this.items = [...this.items];
        this.selectedItem = listDto;
      }

      Object.keys(resp.data).forEach(key => {
        listDto[key] = resp.data[key];
      });

      this.ciselnikyService.getCislenik("CPodvykon", null).subscribe(cis => {
        var pv = cis.find(x => x.guid == resp.data.podvykonGuid);
        listDto.podvykon = Number(pv.kod);
        listDto.podvykonPopis = pv.popis;
        this.ciselnikyService.getCislenik("CVykon", null).subscribe(cvykon => {
          var v = cvykon.find(x => x.guid == pv["parentGuid"]);
          listDto.vykon = Number(v.kod);
        });
      });
    }
  }

  save(): void {
    this.block = true;
    this.maticeService.save(this.data).subscribe(resp => {
      this._processDetailResponse(resp, 'Nastavení bylo uloženo.');
      this._updateInGrid(resp);
    });
  }

  saveCopy(): void {
    this.block = true;
    this.form.patchValue({ guid: undefined });
    this.maticeService.save(this.data).subscribe(resp => {
      this._processDetailResponse(resp, 'Nastavení bylo uloženo.');
      this._updateInGrid(resp);
    });
  }

  cancel(): void {
    this.add(); // reset form
    this.showDetail = false;
    this.table.unselectAll();
    this.selectedItem = undefined;
  }

  /**
   * Po zobrazení panelu focus na první položku
   * */
  focusFirst(): void {
    setTimeout(() => { if (this.dokladTyp != void 0) this.dokladTyp.applyFocus() });
  }

  /**
   *  Handler načtení dat tabulky
   * */
  dataTableLoaded(): void {
    if (this.selectedItem != void 0)
      this.table.selectRowByGuid(this.selectedItem.guid);
  }

  /**
  * Smaže vybraný řádek
  * */
  remove(): void {
    this.confirmationService.confirm({
      message: 'Opravdu si přejete smazat vybraný řádek?',
      accept: () => {
        this.block = true;
        this.maticeService.delete(this.selectedItem.guid)
          .subscribe(resp => {
            this.cancel();
            this._showMessage(resp as ResultInfoGenericDto<CVyrMaticeDetailDto>, "Záznam matice byl smazán.");
            if (resp.success) {
              this._loadList();
            }
            this.block = false;
          });
      }
    });
  }


  /**
   * Načte seznam zaznamu matice
   * */
  private _loadList(): void {
    this.maticeService.list().subscribe(resp => {
      if (resp.success) {
        this.items = resp.data;
      }
      else if (!resp.success) {
        this.messageUtils.showResponseMessage(this.MESSAGE_KEY, resp);
      }
      else {
        this.messageService.add({ severity: 'error', summary: 'Chyba', key: this.MESSAGE_KEY, detail: 'Došlo k neočekávané chybě.', life: MessagesUtils.TOAST_LIFE })
      }
    });
  }

}
