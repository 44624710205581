import { Component, Input } from '@angular/core';
import { VypocetDetailDto } from 'src/app/Dto/Skody/VypocetDetailDto';
import { OkusInfoDto } from 'src/app/Dto/Skody/OkusInfoDto';


/**
 * Komponenta zobrazení kroků výpočtu škody S7.2.
**/
@Component({
  selector: 'skody-detail-s72-kroky',
  templateUrl: './skody-detail-s72-kroky.component.html',
  styleUrls: ['./skody-detail-s72-kroky.component.css']
})
export class SkodyDetailS72KrokyComponent {

  constructor() { }

  @Input() skoda: VypocetDetailDto;
  @Input() okusDto: OkusInfoDto;
  @Input() blockedPrepocet: boolean;

}
