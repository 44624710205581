export class COrgUrDetailDto {
  guid: string;
  parentGuid: string;
  zakaznikGuid: string;
  kod: number;
  nazev: string;
  poznamka: string;
  platnostOd: Date;
  platnostDo: Date;
}
