import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faFilter } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-filtr',
  templateUrl: './filtr.component.html',
  styleUrls: ['./filtr.component.css']
})
export class FiltrComponent {
  constructor() {
  }

  displayFilter: boolean = false;
  faFilter = faFilter;
  @Input() dokladType: string;
  @Output() onChange: EventEmitter<string> = new EventEmitter<string>();


  closeHandler(): void {
    this.displayFilter = false;
  }

  eQChangedHandler(value: any): void {
    this.onChange.emit(value);
  }  
}
