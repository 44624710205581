/**
 * Typ přichytávání
**/
export const enum SnappingType {
  //přichytávání k lomovým bodům
  Vertex,
  //přichytávání k hranám
  Edge
}


/**
 * Přenoska pro data události změny stavu přepínače přichytávání.
**/
export class SnappingChangedArgs {

  constructor(type: SnappingType, isOn: boolean)
  {
    this.type = type;
    this.isOn = isOn;
  }

  //typ přichytávání
  type: SnappingType;

  //indiace stavu. True - zapnuto.
  isOn: boolean;
}
