import { EventEmitter, Injectable, Output } from "@angular/core";
import { Feature } from "ol";
import { Coordinate } from "ol/coordinate";
import { Extent } from 'ol/extent';
import { IMultipolygonJoinIntoArgs } from "../../Dto/Mapa/IMultipolygonJoinIntoArgs";
import { IShowUndoArgs } from "../../Dto/Mapa/IShowUndoArgs";
import { MapItemDto } from "../../Dto/Mapa/MapItemDto";
import { OffsetlInfoDto } from "../../Dto/Mapa/OffsetlInfoDto";
import { NacrtMapItemDto, NacrtMapItemPostSaveDto } from "../../Dto/Nacrty/NacrtMapItemDto";
import { LoggingEventEmitter } from "../../Extensions/LoggingEventEmitter";
import { UuidUtils } from "../../Utils/Shared/uuid.utils";
import { MapServices } from "./map.service";
import Map from 'ol/Map';


/**
 * Service sloužící ke komunikaci mezi komponentami mapy.
**/
@Injectable()
export class MapInteractionService {

  /**
   * Zásobník stavů mapového objektu.
  **/
  private _featureStack: Feature<any>[] = [];


  /**
   * Vrátí počet stavů mapového objeku v zásobníku stavů.
  **/
  public get FeatureStackCnt(): number {
    return this._featureStack.length;
  }


  /**
   * Zásobník stavů mapového objektu, které byly vráceny z _featureStacku.
  **/
  private _redoStack: Feature<any>[] = [];


  /**
   * Seznam vybraných mapových objektů.
  **/
  private _selectedItems: MapItemDto[] = [];


  /**
   * Stav zobrazení overlay panelu typů geometrií kreslených náčrtů.
  **/
  private _drawFeatureMenuOpened: boolean = false;


  /**
   * Událost informující o zahájeném kreslení.
   * Predává typ geometrie kresleného objektu.
  **/
  @Output() DrawStart: EventEmitter<string> = new LoggingEventEmitter<string>('DrawStart');


  /**
   * Událost informující o zahájené editaci existujícího náčrtu.
   * Předává data editovaného náčrtu.
  **/
  @Output() EditFeature: EventEmitter<NacrtMapItemDto> = new LoggingEventEmitter<NacrtMapItemDto>('EditFeature');


  /**
   * Událost informující o kopírování mapového objektu.
   * Předává data zdrojového mapového objektu.
  **/
  @Output() Copy: EventEmitter<MapItemDto> = new LoggingEventEmitter<MapItemDto>('Copy');


  /**
   * Událost informující o změně mapového objektu.
   * Předává mapový objekt.
  **/
  @Output() FeatureChanged: EventEmitter<Feature<any>> = new LoggingEventEmitter<Feature<any>>('FeatureChanged');

  /**
   * Událost informující o změně geometrie mapového objektu pohybem myši (volá se při jakékoliv, i neukončené změně geometrie)
   * Předává mapový objekt.
  **/
  @Output() DrawGeometryChanged: EventEmitter<Feature<any>> = new LoggingEventEmitter<Feature<any>>('DrawGeometryChanged');

  /**
   * Událost informující o dokončení kreslení/změn náčrtu.
   * Předává data náčrtu.
  **/
  @Output() NacrtFinished: EventEmitter<NacrtMapItemPostSaveDto> = new LoggingEventEmitter<NacrtMapItemPostSaveDto>('NacrtFinished');


  /**
   * Událost pro zrušení provedených změn při kreslení/editaci náčrtu.
   * Při zrušení změn editace předává dto s původními (nezměněnými) daty náčrtu, při rušení změn nově kresleného náčrtu předává null.
  **/
  @Output() DiscardChanges: EventEmitter<NacrtMapItemPostSaveDto> = new LoggingEventEmitter<NacrtMapItemPostSaveDto>('DiscardChanges');


  /**
   * Událost informující o smazání náčrtu.
   * Předává data smazaného náčrtu.
  **/
  @Output() NacrtDelete: EventEmitter<NacrtMapItemDto> = new LoggingEventEmitter<NacrtMapItemDto>('NacrtDelete');


  /**
   * Událost informující o změně výběru mapových objektů.
   * Předává seznam mapových objektů, které mají být aktuálně zobrazeny.
  **/
  @Output() SelectedItems: EventEmitter<MapItemDto[]> = new LoggingEventEmitter<MapItemDto[]>('SelectedItems');


  /**
   * Událost předávající souřadnice bouding-boxu.
  **/
  @Output() ZoomTo: EventEmitter<Extent> = new LoggingEventEmitter<Extent>('ZoomTo');


  /**
   * Událost informující o změně šířky mapových panelů, resp. o nutnosti odsadit ovládací prvky mapy, aby nebyly skryty pod zobrazenými panely.
   * Předává hodnotu odsazení a id panelu
  **/
  @Output() ControlOffset: EventEmitter<OffsetlInfoDto> = new LoggingEventEmitter<OffsetlInfoDto>('ControlOffset');


  /**
   * Událost předávající souřadnice.
  **/
  @Output() Coordinates: EventEmitter<Coordinate> = new LoggingEventEmitter<Coordinate>('Coordinates');


  /**
   * Událost informující o přepnutí zobrazení info panelu.
   * Předává true, pokud má být info panel zobrazen.
  **/
  @Output() ToggleInfoPanel: EventEmitter<boolean> = new LoggingEventEmitter<boolean>('ToggleInfoPanel');


  /**
   * Událost říkající, že se má nabídka typů geometrie skrýt.
  **/
  @Output() DrawFeatureMenuClose: EventEmitter<any> = new LoggingEventEmitter<any>('DrawFeatureMenuClose');


  /**
   * Událost informující o ukončení pohybu mapy.
   * Předává nový extent mapy.
  **/
  @Output() MoveEnd: EventEmitter<Extent> = new LoggingEventEmitter<Extent>('MoveEnd');


  /**
   * Událost informující o kliknutí do mapy.
   * Předává objekt se souřadnicemi místa kliknutí a guidem uloženého dotazu.
  **/
  @Output() MapClick: EventEmitter<any> = new LoggingEventEmitter<any>('MapClick');


  /**
   * Událost pro přiblížení na extent, pokud v URL není specifikovaný bbox.
   * Předává extent.
  **/
  @Output() ZoomToIfNoUrlBbox: EventEmitter<Extent> = new LoggingEventEmitter<Extent>('ZoomToIfNoUrlBbox');


  /**
   * Událost informující o odebrání mapového objektu z výběru.
   * Předává guid uloženého dotazu.
  **/
  @Output() RemoveFromSelection: EventEmitter<string> = new LoggingEventEmitter<string>('RemoveFromSelection');


  /**
   * Událost informující o zavření info-panelu.
  **/
  @Output() CloseInfo: EventEmitter<any> = new LoggingEventEmitter<any>('CloseInfo');


  /**
   * Událost pro zobrazení tlačítka "Undo".
  **/
  @Output() ShowUndo: EventEmitter<IShowUndoArgs> = new LoggingEventEmitter<IShowUndoArgs>('ShowUndo');


  /**
   * Událost "zpět" při editaci geometrie náčrtu.
   * Předává stav featury.
  **/
  @Output() UndoEdit: EventEmitter<Feature<any>> = new LoggingEventEmitter<Feature<any>>('UndoEdit');


  /**
   * Událost "zpět" při kreslení nové geometrie náčrtu.
  **/
  @Output() UndoDraw: EventEmitter<any> = new LoggingEventEmitter<any>('UndoDraw');

  /**
   * Událost požadavku na obnovení mapy.
   */
  @Output() RequestReload: EventEmitter<any> = new LoggingEventEmitter<any>('RequestReload');


  /**
   * Událost sloučení náčrtů do multipolygonu.
   * Předává featuru multipolygonu a zdrojová metadata.
  **/
  @Output() MultipolygonJoinInto: EventEmitter<IMultipolygonJoinIntoArgs> = new LoggingEventEmitter<IMultipolygonJoinIntoArgs>('MultipolygonJoinInto');


  /**
   * Událost informující o vytvoření mapy.
   * Předává instanci vytvořené mapy.
  **/ 
  @Output() MapCreated: EventEmitter<Map> = new LoggingEventEmitter<Map>('MapCreated');


  /**
   * Událost pro vytvoření mapy.
   * Předává Id mapy.
  **/
  @Output() CreateMap: EventEmitter<string> = new LoggingEventEmitter<string>('CreateMap');

  /**
   * Událost zobrazení přepínače kreslení enkláv.
   * True, pokud má být přepínač zobrazen.
  **/
  @Output() ShowEnclaveToggler: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Událost předávající informaci o tom, zda se má kreslit enkláva.
  **/
  @Output() DrawEnclave: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Událost předávající informaci o tom, zda (ne)mají být dostupné editační funkce mapy.
  **/
  @Output() DeactivateButtons: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Událost předávající informaci o tom, zda (ne)mají být zobrazeny lomové body vybraných mapových objektů.
  **/
  @Output() VertexToggle: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Událost předávající souřadnice pro získání mapových objektů přimo z vrstev mapy.
  **/
  @Output() GetLocalItems: EventEmitter<Coordinate> = new EventEmitter<Coordinate>();


  /**
   * Událost předávající lokálně získané mapové objekty.
  **/
  @Output() LocalItems: EventEmitter<MapItemDto[]> = new EventEmitter<MapItemDto[]>();
  
  public readonly LAST_POSITION_KEY: string = "lastMapPosition";
  
  constructor(
    private mapService: MapServices,
    private uuidUtils: UuidUtils)
  { }


  /**
   * Vypuštění události zahájení kreslení náčrtu.
   * @param geometryType {string} typ kreslené geometrie
   */
  drawStarted(geometryType: string): void {
    this.DrawStart.emit(geometryType);
    this.DeactivateButtons.emit(true);
    this.toggleInfoPanel(true);
  }


  /**
   * Vypuštění události zahájení editace náčrtu.
   * @param editedNacrt {NacrtMapItemDto} data editovaného náčrtu
   */
  editFeature(editedNacrt: NacrtMapItemDto): void {
    this.EditFeature.emit(editedNacrt);
    this.DeactivateButtons.emit(true);
  }


  /**
   * Vypuštění události informující o kopírování mapového objektu.
   * @param dataToCopy {MapItemDto} data zdrojového mapového objektu
   */
  copy(dataToCopy: MapItemDto): void {
    //prosím pozor - tento typ deep kopie nepodporuje např. typ Date a jiné.
    let deepCopy: MapItemDto = JSON.parse(JSON.stringify(dataToCopy));
    this.Copy.emit(deepCopy);
    this.DeactivateButtons.emit(true);
  }


  /**
   * Vypuštění události o změně mapového objektu.
   * Stav mapového objektu je přidán do zásobníku pro hitorizaci.
   * @param feature {Feature<any>} mapový objekt
   * @param addToStack {boolean} true, pokud se stav featury má přidat do stacku stavů
   */
  featureChanged(feature: Feature<any>, addToStack: boolean = true): void {
    if (addToStack) this._addToFeaturestack(feature);
    this.FeatureChanged.emit(feature);
  }

  /**
   * Vypuštění události o změně geometrie kresleného objektu.
   * @param feature {Feature<any>} mapový objekt
   */
  drawGeometryChanged(feature: Feature<any>): void {
    this.DrawGeometryChanged.emit(feature);
  }


  /**
   * Vypuštění události informující o ukončení kreslení/editaci náčrtu.
   * @param data {NacrtMapItemPostSaveDto} data náčrtu
   */
  nacrtFinished(data: NacrtMapItemPostSaveDto): void {
    this._clearFeatureStack();
    let added = this._addToSelected(data);
    if (added) {
      this.SelectedItems.emit(this._selectedItems);
    }
    this.NacrtFinished.emit(data);
    this.DeactivateButtons.emit(false);
  }


  /**
   * Vypuštění události zrušení změn kreslení/editace náčrtu
   * @param originalData {NacrtMapItemPostSaveDto} původní (nezměněná) data editovaného náčrtu, v případě nového náčrtu null
   */
  discardChanges(originalData: NacrtMapItemPostSaveDto): void {
    this._clearFeatureStack();
    this.DiscardChanges.emit(originalData);
    this.DeactivateButtons.emit(false);
  }


  /**
   * Vypuštění události informující o smazání náčrtu.
   * @param deletedData {NacrtMapItemDto} Data smazaného náčrtu
   */
  nacrtDelete(deletedData: NacrtMapItemDto): void {
    this._removeFromSelected(deletedData);
    this.SelectedItems.emit(this._selectedItems);
    this.NacrtDelete.emit(deletedData);
  }


  /**
   * Vypuštění události informující o změně vybraných mapových objektů.
   * @param selectedItems {MapItemDto[]} seznam vybraných mapových objektů
   */
  selectedItems(selectedItems: MapItemDto[]): void {
    this._selectedItems = selectedItems.slice();
    this.SelectedItems.emit(selectedItems);
  }


  /**
   * Vypuštění události předávající bouding-box.
   * @param bbox {Extent} bouding box
   */
  zoomTo(bbox: Extent): void {
    this.ZoomTo.emit(bbox);
  }

  
  /**
   * Nastavení mapy na vybrané mapové objekty, pokud není v URL extent.
   * @param extent {Extent} bouding box výběrové vrstvy
   */
  zoomToIfNoUrlBbox(extent: Extent): void {
    this.ZoomToIfNoUrlBbox.emit(extent);
  }


  /**
   * Vypuštění události informující o potřebě odsahit ovládací prvky.
   * @param offset {OffsetlInfoDto} info pro odsazení.
   */
  contolOffset(offset: OffsetlInfoDto): void {
    this.ControlOffset.emit(offset);
  }


  /**
   * Akce po ukončení pohybu mapy.
   * @param extent {Extent} aktuální extent mapy
   */
  moveEnd(extent: Extent): void {
    this.MoveEnd.emit(extent);
  }

  
  /**
   * Metoda zjistí, jaké objekty se nalézají na předaných souřadnicích.
   * @param coords {Coordinate} souřadnice místa kliknutí
   * @param zoom {number} zoom level v okamžiku kliknutí - používá se pro stanovení okolí při hledání objektů na {coords}
   * @param ctrlKey {boolean} true, pokud je při kliknutí stisknuta klávesa Ctrl
   */
  mapClick(coords: Coordinate, zoom: number, ctrlKey: boolean): void {
    if (this._drawFeatureMenuOpened) {
      this._drawFeatureMenuOpened = false;
      this.DrawFeatureMenuClose.emit();
      return;
    }

    //toto se může provést hned - není třeba čekat na webApi/změnu URL
    this.coordinates(coords);
    this.toggleInfoPanel(true);

    let dotazGuid = this.uuidUtils.GenerateUuid();
    sessionStorage.setItem(dotazGuid, JSON.stringify({
      params: {
        coords: coords,
        zoom: zoom
      },
      ctrl: ctrlKey
    }));

    this.MapClick.emit({ coords: coords, dotazGuid: dotazGuid });
  }


  /**
   * Zavření info panelu.
  **/
  closeInfo(): void {
    this.toggleInfoPanel(false);
    this.selectedItems([]);
    this.CloseInfo.emit();
  }


  /**
   * Nastavení stavu overlay panelu typů geometrií kresleného náčrtu.
   * Pokud je panel otevřený, tak kliknutí do mapy nevyvolá změnu URL a následné akce, ale zavře overlay panel.
   * @param opened {boolean} aktuální stav zobrazení overlay panelu typů geometrií kresleného náčrtu
   */
  drawFeatureOverlayState(opened: boolean): void {
    this._drawFeatureMenuOpened = opened;
  }


  /**
   * Odebere mapový objekt z výběru.
   * @param id {string} id mapového objektu k odebrání z výběru.
   */
  removeFromSelection(id: string): void {
    let idx = this._selectedItems.findIndex(item => item.id == id);
    if (idx > -1) {
      this._selectedItems.splice(idx, 1);
      this.SelectedItems.emit(this._selectedItems);

      let dotazGuid: string = null;
      if (this._selectedItems.length == 0) {
        this.toggleInfoPanel(false);
      }
      else {
        dotazGuid = this.uuidUtils.GenerateUuid();
        sessionStorage.setItem(dotazGuid, JSON.stringify({
          queryResults: this._selectedItems
        }));
      }

      this.RemoveFromSelection.emit(dotazGuid);
    }
  }


  /**
   * Odebrání všech vybraných objektů z výběru.
  **/
  clearSelection(): void {
    this.RemoveFromSelection.emit(null);
    this._selectedItems = [];
    this.SelectedItems.emit(this._selectedItems);
  }


  /**
   * Vypuštění události s předposledním zaznamenaným stavem upravované featury.
  **/
  undoEdit(): void {
    if (this._featureStack.length > 1) {
      this._redoStack.push(this._featureStack.pop());
      let _lastFeatureState = this._featureStack[this._featureStack.length - 1].clone();
      _lastFeatureState.setId(this._featureStack[this._featureStack.length - 1].getId());
      this.UndoEdit.emit(_lastFeatureState);
      if (this._featureStack.length <= 1) {
        this.showUndo({ feature: null, visible: false });
      }
    }
  }


  /**
   * Vypuštění události pro navrácení do předposledního stavu kreslené featury.
  **/
  undoDraw(): void {
    this.UndoDraw.emit();
  }


  /**
   * Vypuštění události pro zobrazení/skrytí tlačítka "Undo".
   * @param args {IShowUndoArgs} objekt s kreslenou/upravovanou featurou a viditelností tlačítka undo.
   */
  showUndo(args: IShowUndoArgs): void {
    this.ShowUndo.emit(args);
  }


  /**
   * Přidání záznamu do stacku stavů featury.
   * @param feature {Feature<any>} stav mapového objektu
  **/
  private _addToFeaturestack(feature: Feature<any>): void {
    let clon = feature.clone();
    clon.setId(feature.getId());
    this._featureStack.push(clon);
    this.showUndo({ feature: null, visible: this._featureStack.length > 1 });
  }


  /**
   * Vypuštění události se souřadnicemi.
   * @param coords {Coordinate} souřadnice
   */
  coordinates(coords: Coordinate): void {
    this.Coordinates.emit(coords);
  }


  /**
   * Vypuštění události pro zobrazení/skrytí info-panelu.
   * @param show {boolean} true, pokud má být info-panel zobrazen.
   */
  toggleInfoPanel(show: boolean): void {
    this.ToggleInfoPanel.emit(show);
  }


  /**
   * Odešle požadavek na atkualizaci mapy.
   * Všechny komponenty, které si udržují nějaká data, by si je měly obnovit ze serveru
   */
  reload(): void {
    this.RequestReload.emit();
  }


  /**
   * Vypuštění události o sloučení náčrtů do multipolygonu.
  **/
  joinToMultipolygon(feature: Feature<any>, metadataSource: NacrtMapItemDto): void {
    this.MultipolygonJoinInto.emit({ feature: feature, metadataSource: metadataSource });
  }

  /**
   * Vypuštění události informující o vytvoření mapy.
   * @param map {Map} Vytvoření instance mapy
   */
  mapCreated(map: Map): void {
    this.MapCreated.emit(map);
  }


  /**
   * Vypuštění události pro vytvoření mapy.
   * @param mapId {string} Id mapy
   */
  createMap(mapId: string): void {
    this.CreateMap.emit(mapId);
  }


  /**
   * Vyčištění zásobníku/fronty změn mapových objektů.
  **/
  private _clearFeatureStack(): void {
    this._featureStack = [];
    this._redoStack = [];
    this.showUndo({ feature: null, visible: false });
  }


  /**
   * Odebere mapový objekt ze zásobníku.
   * @param toRemove {MapItemDto} objekt k odebrání ze zásobníku
   */
  private _removeFromSelected(toRemove: MapItemDto): void {
    let idx = this._selectedItems.findIndex(x => x.id == toRemove.id);
    this._selectedItems.splice(idx, 1);
  }


  /**
   * Přidá mapový objekt do zásobníku, pokud v něm již není.
   * @param toAdd {MapItemDto} objekt k přidání do zásobníku
   * @returns {boolean} true, pokud byl objekt do kolekce přidán
   */
  private _addToSelected(toAdd: MapItemDto): boolean {
    let idx = this._selectedItems.findIndex(x => x.id == toAdd.id);
    if (idx == -1) {
      this._selectedItems.push(toAdd);
      return true;
    }
    //pokud náčrt již v seznamu vybraných náčrtů existuje, porovnáme jeho geometrii. Pokud došlo k aktualizaci, tak jej v kolekci nahradíme.
    else if (this._selectedItems[idx].wkt != toAdd.wkt) {
      this._selectedItems.splice(idx, 1, toAdd);
    }
    return false;
  }


  
  /**
   * Zpracování URL parametru 'dotaz' při kliknutí do mapy.
   * @param dotazGuid {string} guid uloženého dotazu
   */
  processDotazMapClick(dotazGuid: string) {
    let storedDotaz = sessionStorage.getItem(dotazGuid);
    if (storedDotaz != void 0) {
      let dotazData: any = JSON.parse(storedDotaz);
      let coords: Coordinate = dotazData.params.coords.map(x => parseFloat(x));
      this.mapService.whatIsHere(
        coords,
        parseFloat(dotazData.params.zoom)
      ).subscribe(resp => {
        //k mapovým objektům z webapi připojíme mapové objekty vyhledané na klientovi
        //FIXME: teď tu je pouze PES, ale pokud by bylo více takových use-case, tak toto nebude fungovat!
        var localItemsSubs = this.LocalItems.subscribe((localFeatures: MapItemDto[]) => {
          localItemsSubs.unsubscribe();
          resp = resp.concat(localFeatures);
          if (dotazData.ctrl) {
            let toAdd = resp.filter(ri => this._selectedItems.findIndex(si => ri.id == si.id) == -1);

            resp.forEach(ri => {
              let idx = this._selectedItems.findIndex(item => item.id == ri.id);
              if (idx > -1) {
                this._selectedItems.splice(idx, 1);
              }
            });

            this._selectedItems = this._selectedItems.concat(toAdd);
          }
          else {
            this._selectedItems = resp != void 0 ? resp : [];
          }
          dotazData['queryResults'] = this._selectedItems;
          sessionStorage.setItem(dotazGuid, JSON.stringify(dotazData));
          this.SelectedItems.emit(this._selectedItems);
          this.toggleInfoPanel(true);
        });

        this.getLocalItems(coords);
      });
    }
    else {
      console.error('V sessionStorage nebyl nalezen uložený dotaz.');
    }
  }


  /**
   * Vypuštění události pro získání mapových objektů přímo z mapy.
   * @param coords {Coordinate} souřadnice, na kterých se mají hledat mapové objekty
   */
  getLocalItems(coords: Coordinate): void {
    this.GetLocalItems.emit(coords);
  }


  /**
   * Vypuštění události s mapovými objekty získanými lokálně z existujících featur.
   * @param mapItems {MapItemDto[]} seznam lokálně získaných mapových objektů
   */
  localItems(mapItems: MapItemDto[]): void {
    this.LocalItems.emit(mapItems);
  }


  /**
   * Zpracování URL parametru 'dotaz' při navigaci z domény mimo mapu.
   * @param dotazGuid {string} guid uloženého dotazu
   */
  processDotazExternalNavigation(dotazGuid: string) {
    let storedDotaz = sessionStorage.getItem(dotazGuid);
    if (storedDotaz != void 0) {
      let dotazData: any = JSON.parse(storedDotaz);
      this.selectedItems(dotazData['queryResults'] != void 0 ? dotazData['queryResults'] : []);
      this.toggleInfoPanel(true);
    }
    else {
      console.error('V sessionStorage nebyl nalezen uložený dotaz.');
    }
  }


  /**
   * Vypuštění události pro zobrazení/skyrtí přepínače kreslení enkláv.
   * @param value {boolean} true, pokud má být přepínač zobrazený
   */
  showEnclaveToggler(value: boolean): void {
    this.ShowEnclaveToggler.emit(value);
  }


  /**
   * Vypuštění události o kreslení enklávy.
   * @param value {boolean} true, pokud se má enkláva kreslit
   */
  drawEnclave(value: boolean): void {
    this.DrawEnclave.emit(value);
  }


  /**
   * Vypuštění události pro zobrazení/skrytí lomových bodů vybraných mapových objektů.
   * @param value {boolean} true, pokud mají být lomové body zobrazeny
   */
  vertexToggle(value: boolean): void {
    this.VertexToggle.emit(value);
  }
}
