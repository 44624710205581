import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import Feature from 'ol/Feature';
import WKB from 'ol/format/WKB';
import { NacrtProjektSourceDto } from '../../../Dto/Nacrty/NacrtProjectSourceDto';
import { SyncService } from '../../../Services/Sync/sync.service';
import { ByteArrayUtils } from '../../../Utils/Mapa/byte-array.utils';
import { MapServices } from '../../../Services/Mapa/map.service';
import { NacrtMapItemDto, NacrtMapItemPostSaveDto } from '../../../Dto/Nacrty/NacrtMapItemDto';
import { MapInteractionService } from '../../../Services/Mapa/map-interaction.service';
import { LineString, MultiPolygon } from 'ol/geom';
import { NacrtyInteractionService } from '../../../Services/Nacrty/nacrty-interaction.service';
import { Subscription } from 'rxjs';
import { NacrtEditFormComponent } from '../../Nacrty/nacrt-edit-form/nacrt-edit-form.component';
import { DNacrtSyncDto } from '../../../Dto/Nacrty/DNacrtSyncDto';
import { RoleUtils } from '../../../Utils/Shared/role.utils';
import { RoleEnum } from '../../../Dto/Core/RoleEnum';

/**
 * Komponenta pro editaci atributů náčrtů v mapě (nikoli seznamu - viz NacrtEditComponent).
 * Ukládá i geometrii náčrtu.
**/
@Component({
  selector: 'app-map-info-nacrt-edit',
  templateUrl: './map-info-nacrt-edit.component.html',
  styleUrls: ['./map-info-nacrt-edit.component.css']
})
export class MapInfoNacrtEditComponent implements OnInit, OnDestroy {

  /**
   * Seznam dostupných projektů náčrtu.
  **/
  _nacrtProjects: NacrtProjektSourceDto[] = [];
  @Input() set nacrtProjects(value: NacrtProjektSourceDto[]) {
    if (value != void 0) {
      this._nacrtProjects = value;

      if (!this.roleUtils.checkRole([RoleEnum.NacrtyAdmin])) {
        this._nacrtProjects = value.filter(p => p.editableGeometries);
      }
    }
  }


  /**
   * Id editovaného náčrtu.
  **/
  _itemId: string = null;
  @Input() set itemId(value: string) {
    if (value != void 0) {
      this._itemId = value;
    }
  }


  @ViewChild('editForm') editForm: NacrtEditFormComponent;


  constructor(
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private mapService: MapServices,
    private syncService: SyncService,
    private byteArrayUtils: ByteArrayUtils,
    private mapInteractionService: MapInteractionService,
    private nacrtyInteractionService: NacrtyInteractionService,
    private roleUtils: RoleUtils)
  {
    this.mapInteractionService.EditFeature.subscribe(itemToEdit => {
      if (this._itemId == itemToEdit.id) {
        this._visible = true;
        this._getNacrtData(itemToEdit);
      }
    });
  }


  readonly TOAST_KEY: string = 'mapContainerToastKey';
  readonly CONFIRM_KEY: string = 'nacrtEditConfirm';
  faCheck = faCheck;
  faTimes = faTimes;
  syncData: DNacrtSyncDto = null;
  formData: any = null;
  blocked: boolean = false;
  _item: NacrtMapItemDto = null;
  _originalItem = null;
  _feature: Feature<any> = null;
  _visible: boolean = false;
  private _featureChangedSubs: Subscription;
  private _undoSubs: Subscription;
  private _geometryChangedSubs: Subscription;
  featArea: number = null;
  featLength: number = null;
  

  ngOnInit() {
    this._geometryChangedSubs = this.mapInteractionService.DrawGeometryChanged.subscribe(feature => {
      if (feature.getGeometry() instanceof MultiPolygon) {
        var multipolygon: MultiPolygon = feature.getGeometry();
        this.featArea = multipolygon.getArea();
        let multiPolyLength = 0;
        multipolygon.getPolygons().forEach((p) => {
          multiPolyLength += new LineString(p.getLinearRing(0).getCoordinates()).getLength();
        })
        this.featLength = multiPolyLength;
      }
      else if (feature.getGeometry() instanceof LineString) {
        var line: LineString = feature.getGeometry();
        this.featLength = line.getLength();
        this.featArea = null;
      }
      else {
        this.featArea = null;
        this.featLength = null;
      }
    });

    this._featureChangedSubs = this.mapInteractionService.FeatureChanged.subscribe(this._updateFeature.bind(this));
    this._undoSubs = this.mapInteractionService.UndoEdit.subscribe(this._updateFeature.bind(this));
  }

  ngOnDestroy() {
    this._featureChangedSubs.unsubscribe();
    this._undoSubs.unsubscribe();
    this._geometryChangedSubs.unsubscribe();
  }


  /**
   * Získá data editovaného náčrtu přes sync api.
   * @param value {NacrtMapItemDto} editovaný náčrt
   */
  private _getNacrtData(value: NacrtMapItemDto): void {
    if (value != void 0) {
      this._item = value;

      //kopie původního stavu náčrtu - použije se v případě rušení provedení změn
      this._originalItem = {};
      Object.keys(this._item).forEach(key => this._originalItem[key] = this._item[key]);

      this.syncService.get(value.id).subscribe(resp => {
        this.syncData = resp;
        //plocha i obvod náčrtu je počítán na cloudu a zde jej potřebuji vymazat, aby se na cloud neposílal! (#44152)
        this.syncData.nacrt.plocha = null;
        this.syncData.nacrt.obvod = null;

        this.formData = {
          nacrtProjektGuid: this.syncData.nacrt.nacrtProjektGuid,
          popis: this.syncData.nacrt.popis,
          poznamka: this.syncData.nacrt.poznamka,
          cisloHoliny: this.syncData.nacrt.cislo
        }
      });
    }
  }

  /**
   * Uložení editovaného náčrtu.
  **/
  save(): void {
    this.blocked = true;

    Object.keys(this.editForm.formValue).forEach(key => {
      this.syncData.nacrt[key] = this.editForm.formValue[key];
    });

    let format = new WKB({ hex: false, ewkb: false });
    let wkb = format.writeGeometry(this._feature.getGeometry());
    this.syncData.nacrtGeometry.wkb = this.byteArrayUtils.ToBase64(wkb as ArrayBuffer);
    this.syncData.nacrtGeometry.guid = this.syncData.nacrtGeometry.guid;


    let dataToSync: any[] = [this.syncData];
    if (this.nacrtyInteractionService.prilohy != void 0) {
      let prilohyPendingChanges = this.nacrtyInteractionService.prilohy.filter(priloha => !priloha._dirty || priloha.sync.d);
      dataToSync = dataToSync.concat(prilohyPendingChanges);
    }

    this.syncService.post(dataToSync).subscribe(resp => {
      this._nacrtSavePostProcess(resp, this.editForm.formValue['nacrtProjektGuid']);
    });
  }


  /**
   * Handler zrušení editace náčrtu.
  **/
  cancel(event): void {
    this.confirmationService.confirm({
      target: event.currentTarget,
      key: this.CONFIRM_KEY,
      message: 'Opravdu si přejete zahodit provedené změny? Změny nebudou uloženy.',
      icon: 'pi pi-exclamation-triangle',
      accept: this.discardAccepted.bind(this),
      acceptLabel: 'Zahodit změny',
      acceptButtonStyleClass: 'p-button-danger',
      reject: () => { },
      rejectLabel: 'Pokračovat v práci'
    });
  }


  /**
   * Potvrzení smaznání změn editovaného náčrtu.
  **/
  discardAccepted(): void {
    this.editForm.reset();
    this.messageService.add({ severity: 'success', summary: 'Změny náčrtu nebyly uloženy.', key: this.TOAST_KEY });
    this._visible = false;
    this._originalItem['targetLayerName'] = 'nacrty_' + this._originalItem.projektGuid;
    this.nacrtyInteractionService.clearPrilohy();
    this.mapInteractionService.discardChanges((this._originalItem as NacrtMapItemPostSaveDto));
  }


  /**
   * Postprocess uložení změn náčrtu.
   * @param resp {any} sync post response
   * @param nacrtProjektGuid {string} guid projektu náčrtu, do kterého náčrt patří
   */
  private _nacrtSavePostProcess(resp: any, nacrtProjektGuid: string): void {
    this.blocked = false;

    if (resp.isError) {
      let messages = resp.errors.map(err => { return { severity: 'error', summary: 'Chyba', detail: err.text, key: this.TOAST_KEY }; });
      this.messageService.addAll(messages);
    }
    else if (resp.isConflict) {
      this.messageService.add({
        key: this.TOAST_KEY, summary: 'Konflikt', severity: 'error',
        detail: 'Při ukládání náčrtu došlo ke konfliktu. Prosím obnovte stránku a proveďte změny znovu.'
      });
    }
    else
    {
      this.mapService.getMapItemById(this._item.id, 'Nacrty').subscribe(mapResp => {
        mapResp['targetLayerName'] = 'nacrty_' + nacrtProjektGuid;
        this._visible = false;
        this.nacrtyInteractionService.clearPrilohy();
        this.mapInteractionService.nacrtFinished(mapResp as NacrtMapItemPostSaveDto);
        this.messageService.add({ severity: 'success', summary: 'Ok', detail: 'Náčrt byl aktualizován.', key: this.TOAST_KEY });
      });
    }
  }


  /**
   * Aktualizace featury.
   * @param feature {Feature<any>} featura
   */
  private _updateFeature(feature: Feature<any>): void {
    if (feature.getId() == this._itemId) {
      this._feature = feature;
    }
  }


  /**
   * Vrátí typ geometrie náčrtu.
  **/
  get featureGeometry(): string {
    if (this._feature == void 0) {
      return null;
    }
    return this._feature.getGeometry().getType();
  }
}
