<p-messages></p-messages>
<div class="content">
  <p-card [style]="{width: '360px', margin: 'auto'}">
    <h3>Změna hesla</h3>
    <form [formGroup]="passForm" (ngSubmit)="changePass()">
      <p>
        <label for="oldPass">Staré heslo</label><br />
        <input pInputText id="oldPass" name="oldPass" type="password" formControlName="oldPass" />
      </p>
      <p *ngIf="oldPass.invalid && (oldPass.dirty || oldPass.touched)  || submitted && oldPass.errors" class="cross-validation-error-message alert alert-danger">
        Vyplňte prosím staré heslo.
      </p>
      <p>
        <label for="newPass">Nové heslo</label><br />
        <input pInputText id="newPass" name="newPass" type="password" formControlName="newPass" />
      </p>
      <p *ngIf="newPass.invalid && (newPass.dirty || newPass.touched) || submitted && newPass.errors" class="cross-validation-error-message alert alert-danger">
        Vyplňte prosím nové heslo.
      </p>
      <p>
        <label for="agNewPass">Znovu nové heslo</label><br />
        <input pInputText id="agNewPass" name="agNewPass" type="password" formControlName="agNewPass" />
      </p>
      <p *ngIf="newPass.value != agNewPass.value" class="cross-validation-error-message alert alert-danger">
        Vyplňte prosím znovu nové heslo.
      </p>
      <p class="center">
        <button pButton type="submit" label="Odeslat" [ngClass]="'icon-btn'"><fa-icon [icon]="faCheck"></fa-icon></button>
      </p>
    </form>
  </p-card>
</div>
<app-block-ui [block]="loading"></app-block-ui>
