import { AfterViewInit } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { faExternalLinkAlt, faSearch } from '@fortawesome/free-solid-svg-icons';
import Layer from 'ol/layer/Layer';
import { Subscription } from 'rxjs';
import { Extent } from 'ol/extent';
import { NacrtMapItemDto } from '../../../Dto/Nacrty/NacrtMapItemDto';
import { NacrtPrilohyChangedArgs } from '../../../Dto/Nacrty/NacrtPrilohyChangedArgs';
import { MapInteractionService } from '../../../Services/Mapa/map-interaction.service';
import { NacrtyInteractionService } from '../../../Services/Nacrty/nacrty-interaction.service';
import { MapServices } from '../../../Services/Mapa/map.service';
import { ConstructMapLayerUtils } from '../../../Utils/Mapa/construnct-map-layer.utils';
import { MapNavigationParams } from '../../../Dto/Mapa/MapNavigationParams';
import { Router } from '@angular/router';
import { LayersInteractionService } from '../../../Services/Mapa/layers-interaction.service';


/**
 * Komponenta s přehledovou mapou zobrazující náčrt.
**/
@Component({
  selector: 'app-nacrt-map',
  templateUrl: './nacrt-map.component.html',
  styleUrls: ['./nacrt-map.component.css']
})
export class NacrtMapComponent implements OnInit, AfterViewInit {

  constructor(
    private mapInteractionService: MapInteractionService,
    private nacrtyInteractionService: NacrtyInteractionService,
    private layersInteractionService: LayersInteractionService,
    private mapService: MapServices,
    private layerUtils: ConstructMapLayerUtils,
    private router: Router)
  { }


  ngAfterViewInit() {
    this._mapCreatedSubs = this.mapInteractionService.CreateMap.subscribe(this._onMapCreated.bind(this));
    this.mapInteractionService.createMap(this.MAP_ID);
  }


  ngOnInit() {
    this._editInitSubs = this.nacrtyInteractionService.OnInitEdit.subscribe(this._onInitHandler.bind(this));
    this._viewInitSubs = this.nacrtyInteractionService.OnInitView.subscribe(this._onInitHandler.bind(this));
    this._zoomToSubs = this.mapInteractionService.ZoomToIfNoUrlBbox.subscribe(this._zoomToIfNoUrlBboxHandler.bind(this));
  }


  ngOnDestroy() {
    this._editInitSubs.unsubscribe();
    this._viewInitSubs.unsubscribe();
    this._zoomToSubs.unsubscribe();
    this._mapCreatedSubs.unsubscribe();
    this._layersLoadedSubs.unsubscribe();
    this._mapCreated = false;
  }

  //id mapy
  MAP_ID: string = 'nacrtMap';
  //vrstvy komponenty
  layers: Layer<any, any>[] = [];
  faExternalLinkAlt = faExternalLinkAlt;
  faSearch = faSearch;

  private _editInitSubs: Subscription;
  private _viewInitSubs: Subscription;
  private _zoomToSubs: Subscription;
  private _mapCreatedSubs: Subscription;
  private _layersLoadedSubs: Subscription;
  private _nacrtData: NacrtMapItemDto = null;
  private _mapCreated: boolean = false;
  //bbox náčrtu, v tomto případě již včetně bufferu pro zoomTo (přidává jej již mapa)
  private _extent: Extent = null;


  /**
   * Handler inicializace editace náčrtu.
   * @param args {NacrtPrilohyChangedArgs} argumenty příloh náčrtu
   */
  private _onInitHandler(args: NacrtPrilohyChangedArgs): void {
    this._nacrtData = args.nacrtData;
    this._selectNacrt(this._nacrtData);
  }


  /**
   * Handler dokončené inicializace mapy.
   * @param mapId {string} Id mapy, která se inicializovala
   */
  private _onMapCreated(mapId: string): void {
    if (this.MAP_ID == mapId) {
      this._mapCreated = true;
      this._layersLoadedSubs = this.layersInteractionService.LayersLoaded.subscribe(this._onLayersLoaded.bind(this));
      this.mapService.getCachedLayerSource().subscribe(sources => {
        let zakladni = sources.find(s => s.id == 'cuzk_zakladni');
        if (zakladni != void 0) {
          this.layerUtils.constructLayers([zakladni]).then((layers) => {
            let zakladniLayer: Layer<any, any> = layers[0];
            this.layers.push(zakladniLayer);
            this.layers = this.layers.slice();
          });
        }
      });
    }
  }


  /**
   * Handler načtení/vytvoření mapových vrstev.
  **/
  private _onLayersLoaded(): void {
    this._selectNacrt(this._nacrtData);
  }


  /**
   * Výběr náčrtu v mapě.
   * @param nacrt {NacrtMapItemDto} data náčrtu
   */
  private _selectNacrt(nacrt: NacrtMapItemDto): void {
    if (this._mapCreated && nacrt != void 0) {
      this.mapInteractionService.selectedItems([nacrt]);
    }
  }


  /**
   * Přiblížení na extent mapového objektu
   * @param extent {Extent} extent mapového objektu
   */
  private _zoomToIfNoUrlBboxHandler(extent: Extent): void {
    this._extent = extent;
    this.mapInteractionService.zoomTo(this._extent);
  }


  /**
   * Přiblížení na vybraný náčrt;
  **/
  zoomTo(): void {
    if (this._extent != void 0) {
      this.mapInteractionService.zoomTo(this._extent);
    }
  }


  /**
   * Zajistí přechod do mapy s přiblížením na vybraný náčrt.
  **/
  goToMapHandler(): void {
    let params = {};
    params[MapNavigationParams.id] = this._nacrtData.id;
    params[MapNavigationParams.typ] = 'Nacrty';
    this.router.navigate(['mapa'], { queryParams: params });
  }
}
