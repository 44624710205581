<div *ngIf="showBtn"> <!--tlačítko musí být v divu pro správné zrovnání s ostatními tlačítky v toolbaru-->
  <button pButton label="Kontrola" class="icon-btn" [disabled]="disabled" (click)="check()">
    <fa-icon [icon]="faCheck"></fa-icon>
  </button>
</div>
<app-block-ui [block]="loading"></app-block-ui>
<p-toast position="top-center" key="kontroly-toast"></p-toast>


<p-dialog header="Výsledky kontrol" [(visible)]="display" [modal]="true" [style]="{width: '600px'}">
  <div class="p-grid" *ngIf="messagesTree">
    <div class="p-col-12">
      <p-tree [value]="messagesTree" [style]="{width: '100%'}"></p-tree>
    </div>
  </div>

  <p-footer>
    <button type="button" pButton icon="pi pi-times" label="Zavřít" (click)="display=false" class="p-button-secondary"></button>
  </p-footer>
</p-dialog>
