<p-toast position="top-center" key="{{TOAST_KEY}}"></p-toast>

<div class="map-container">
  <app-map id="{{mapElementId}}"
           [Layers]="layers" [VisibleLayers]="visibleLayers" [ShowMapControls]="showMapControls"></app-map>
  <app-area-overlay [mapId]="mapElementId"></app-area-overlay>
  <app-map-layer-panel [layerSources]="layerSources" [loadingLayers]="loadingLayers"
                       (visibleLayersChanged)="visibleLayers = $event"></app-map-layer-panel>
  <app-map-info-panel [nacrtProjects]="nacrtProjects"></app-map-info-panel>
  <app-map-nacrt-create></app-map-nacrt-create>
  <app-map-nacrt-undo></app-map-nacrt-undo>
  <app-map-nacrt-switch-enclave></app-map-nacrt-switch-enclave>
  <app-map-snap-switch></app-map-snap-switch>
  <app-vertex-switch></app-vertex-switch>
  <app-pes [mapId]="mapElementId"></app-pes>
</div>
