import { SyncDto } from "../Sync/syncDto";
import { NacrtGeometrySyncDto } from "./NacrtGeometrySyncDto";
import { NacrtSyncDto } from "./NacrtSyncDto";

export class DNacrtSyncDto {
  nacrt: NacrtSyncDto;
  nacrtGeometry: NacrtGeometrySyncDto;
  type: string = 'DNacrt';
  sync: SyncDto;
}
