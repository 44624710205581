import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

// už mě to s tím datem v JS nebaví... viz #26124
Date.prototype.toJSON = function () {
  var dateParts = [
    this.getFullYear(),
    ('0' + (this.getMonth() + 1)).slice(-2),
    ('0' + this.getDate()).slice(-2)
  ];
  var timeParts = [
    this.getHours(),
    this.getMinutes(),
    this.getSeconds()
  ];
  return dateParts.join('-') + 'T' + timeParts.map(p => ('0' + p).slice(-2)).join(':');
};

// interceptor převzat: https://stackoverflow.com/a/49279951

@Injectable()
export class DateResponseInterceptor implements HttpInterceptor {
  private dateRegex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})$/;
  private sectionFragmentRegex = /(?:\.\d*)?$/;

  //private utcDateRegex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)Z$/;

  constructor() { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.convertDates(event.body);
        }
      }));
  }

  private convertDates(object: Object) {
    if (!object || !(object instanceof Object)) {
      return;
    }

    if (object instanceof Array) {
      for (const item of object) {
        this.convertDates(item);
      }
    }

    for (const key of Object.keys(object)) {
      const value = object[key];

      if (value instanceof Array) {
        for (const item of value) {
          this.convertDates(item);
        }
      }

      if (value instanceof Object) {
        this.convertDates(value);
      }

      if (typeof value === 'string') {
        if (this.dateRegex.test(value)) {
          object[key] = new Date(value);
        }
        else if (this.sectionFragmentRegex.test(value)) {
          object[key] = value;
        }
      }
    }
  }
}
