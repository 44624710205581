import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PesOptions } from '../../Dto/Pes/PesOptions';

/**
 * Service pro komunikace s web-api pro potřeby modulu PES.
**/
@Injectable({
  providedIn: 'root'
})
export class PesService {

  private apiPesOptions: string = "api/Options/GetPesOptions";

  constructor(
    private http: HttpClient
  ) { }


  /**
   * Vrátí konfiguraci modulu PES.
  **/
  getPesOptions(): Observable<PesOptions> {
    return this.http.get<PesOptions>(this.apiPesOptions);
  }
}
