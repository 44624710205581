import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faBackspace, faEllipsisV, faFilter, faPlus, faSave, faTimes, faTrash, faUndo } from '@fortawesome/free-solid-svg-icons';
import { ConfirmationService, FilterService, MessageService } from 'primeng/api';
import { CiselnikDataDto } from '../../../Dto/Core/CiselnikDataDto';
import { CiselnikOpravneniEnum } from '../../../Dto/Core/CiselnikOpravneniEnum';
import { CiselnikSloupecTypEnum } from '../../../Dto/Core/CiselnikSloupecTypEnum';
import { ResultInfoDto } from '../../../Dto/Shared/ResultInfoDto';
import { CiselnikyAdminService } from '../../../Services/Core/ciselniky-admin.service';
import { CiselnikyService } from '../../../Services/Shared/ciselniky.service';
import { MessagesUtils } from '../../../Utils/Shared/messages.utils';
import { TableUtils } from '../../../Utils/Shared/table.utils';
import { UuidUtils } from '../../../Utils/Shared/uuid.utils';
import { TableComponent } from '../../Shared/table/table.component';

/**
 * Komponenta pro editaci/zobrazení obsahu číselníku
 * */
@Component({
  selector: 'app-ciselniky-edit',
  templateUrl: './ciselniky-edit.component.html',
  styleUrls: ['./ciselniky-edit.component.css']
})
export class CiselnikyEditComponent implements OnInit {

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private messageService: MessageService,
    private messageUtils: MessagesUtils,
    private ciselnikyAdminService: CiselnikyAdminService,
    private filterService: FilterService,
    private uuidUtils: UuidUtils,
    private formBuilder: FormBuilder,
    private tableUtils: TableUtils,
    private confirmationService: ConfirmationService,
    private ciselnikyService: CiselnikyService) {
    this.platnostModOptions = [{ popis: "Platnost volbou", volba: true }, { popis: "Platné/neplatné", volba: false }];
    this.platnostMod = this.platnostModOptions[0];
    this.editForm = this.formBuilder.group({
      editArray: new FormArray([])
    });

    this.ciselnikyService.loaded.subscribe(() => {
      if (!this.editForm.dirty) this._pristineOnLoaded = true;
    });
  }

  faFilter = faFilter;
  faEllipsisV = faEllipsisV;
  faUndo = faUndo;
  faSave = faSave;
  faTimes = faTimes;
  faPlus = faPlus;
  faBackspace = faBackspace;
  faTrash = faTrash;

  public edit: boolean = false;
  public displayEdit: boolean = false;
  public dto: CiselnikDataDto;
  public block: boolean = false;
  public cols: any[] = [];
  public items: any[];
  public nazev: string;
  public dateCols: string[] = []; //názvy sloupců s typem date
  public platnostFilter: Date[];//hodnota kalendáře filtru platnosti od do
  public platne: boolean = true;
  public guid: string;//guid číselníku
  public isAllEdit: boolean = false;//True, pokud jsou všechny sloupce editovatelné. Insert le provést pouze v tomto případě
  public platnostExists: boolean = false;//True, pokud existují sloupce platnost_od a platbost_do
  public newRow: boolean = false;//True, pokud je aktuální editovaný řádek nový
  public editForm: FormGroup;
  public btnFilterDisabled: boolean = false;

  get editArray() { return this.editForm.controls.editArray as FormArray; }

  public readonly SloupecPlatnostOd: string = "platnost_od";
  public readonly SloupecPlatnostDo: string = "platnost_do";

  public readonly CISELNIKY_EDIT_TOAST = "ciselniky-edit-toast";
  public readonly CISELNIKY_EDIT_CONFIRM_POPUP: string = 'ciselnik-edit-confirm';
  public readonly CISELNIKY_EDIT_CONFIRM_DIALOG: string = 'ciselnik-edit-confirm-dialog';

  @ViewChild("colValue", { static: true }) colsTemplate: TemplateRef<any>;
  @ViewChild("colCiselnik", { static: true }) ciselnikTemplate: TemplateRef<any>;
  @ViewChild("colDatum", { static: true }) datumTemplate: TemplateRef<any>;
  @ViewChild("colDatumCas", { static: true }) datumCasTemplate: TemplateRef<any>;
  @ViewChild("colNumberDec", { static: true }) numberTemplate: TemplateRef<any>;
  @ViewChild("ciselnikyTable", { static: true }) ciselnikyTable: TableComponent;

  public platnostModOptions: any[];
  public platnostMod: any;

  /**
   * True, pokud došlo k načtení číselníků ze serveru pro dropdown a nedošlo ke změně ve formuláři
   */
  private _pristineOnLoaded: boolean = false;

  /**
   * Vybraný řádek
   */
  private _selectedRow: any;

  /**
   * Předchozí vybraný řádek. Pokud dojde k prvnímu výběru, tak obsahuje aktuální řádek z důvodu zrušení prvního unselectu. 
   */
  private _selectedPrevRow: any;

  ngOnInit(): void {
    this.filterService.register('filterPlatnostOd', this.filterPlatnostOd.bind(this));
    this.filterService.register('filterPlatnostDo', this.filterPlatnostDo.bind(this));

    this.filterService.register('filterNeplatnostOd', this.filterNeplatnostOd.bind(this));
    this.filterService.register('filterNeplatnostDo', this.filterNeplatnostDo.bind(this));

    this.activatedRoute.params.subscribe(param => {
      this.nazev = param.nazev;
      if (param?.guid != void 0) {
        this._loadData(param?.guid);
      } else {
        this.cols = [];
        this.items = [];
      }
    });
  }

  /**
 * Navigace zpět na seznam číselníků
 */
  public back(): void {
    this.router.navigate(["config/ciselniky"]);
  }

  /**
   * Inicializuje sloupce a jejich obsah
   * @param sloupce
   */
  private _initTable(): void {
    if (this.cols == void 0 || this.cols.length == 0) this._initTemplate();
    this.dateCols = this.dto?.sloupce.filter(x => x.typ == CiselnikSloupecTypEnum.Datum || x.typ == CiselnikSloupecTypEnum.DatumCas).map(col => col.nazev);
    this._setTableContent();
  }

  /**
   * Nastaví template podle typu sloupce
   * */
  private _initTemplate(): void {
    this.cols = this.dto.sloupce.map(sloupec => {
      let template: TemplateRef<any>;
      switch (sloupec.typ) {
        case CiselnikSloupecTypEnum.Datum:
          template = this.datumTemplate;
          break;
        case CiselnikSloupecTypEnum.DatumCas:
          template = this.datumCasTemplate;
          break;
        case CiselnikSloupecTypEnum.CeleCislo:
          template = this.colsTemplate;
          break;
        case CiselnikSloupecTypEnum.DesetinneCislo:
          template = this.numberTemplate;
          break;
        case CiselnikSloupecTypEnum.FK:
          //Pokud je FK sloupec zároveň PK, tak pouze vypsat guid. Stejně tak, pokud název sloupce není v seznamu dostupných číselníků
          template = sloupec.isPk || this.getCiselnikModel(sloupec.nazev) == void 0 ? this.colsTemplate : this.ciselnikTemplate;
          break;
        default:
          template = this.colsTemplate;
          break;
      }
      return {
        field: sloupec.nazev,
        header: sloupec.popis == void 0 ? sloupec.nazev : sloupec.popis,
        template: template
      }
    });
  }

  /**
   * Načte data podle guid číselníku
   * @param guid
   */
  private _loadData(guid: string): void {
    this.ciselnikyAdminService.getCiselnikData(guid).subscribe(res => {
      if (res != void 0) {
        this.dto = res;
        this.guid = guid;
        this.edit = this.dto?.opravneni == CiselnikOpravneniEnum.ZobrazitIEditovat ? true : false;
        this.platnostExists = this.dto?.sloupce.filter(x => x.nazev == this.SloupecPlatnostOd || x.nazev == this.SloupecPlatnostDo)?.length == 2;
        if (this.edit) {
          this.edit = res.sloupce.find(x => x.canEdit) != void 0;
          if (!this.edit)
            this.messageService.add({
              key: this.CISELNIKY_EDIT_TOAST, summary: 'Upozornění', severity: 'warn',
              detail: 'Žádný sloupec není editovatelný.',
              life: MessagesUtils.TOAST_LIFE
            });
        }
        this.isAllEdit = this.dto?.sloupce != void 0
          && this.dto.sloupce.filter(x => !x.canEdit && !x.isPk).length == 0
          && this.edit;

        this._initTable();
      } else {
        this._errorMessage();
      }
    }, error => {
      this._errorMessage();
    });
  }

  private _errorMessage(): void {
    this.items = [];
    this.messageService.add({
      key: this.CISELNIKY_EDIT_TOAST, summary: 'Chyba', severity: 'error',
      detail: 'Při načítání číselníku došlo k chybě.',
      life: MessagesUtils.TOAST_LIFE
    });
  }

  /**
   * Handler výběru řádku. Pokud jsou provedené změny a nejsou uloženy, tak se zobrazí confirm dialog.
   * @param row
   */
  public onRowSelected(row: any): void {
    this._selectedPrevRow = this._selectedRow;
    this._selectedRow = row;
    if (this.editForm.dirty) {
      this._displayConfirmDialog(DialogActions.rowClicked);
    } else {
      this.newRow = false;
      this._onRowSelected();
    }
  }

  /**
   * Zobrazení detailu vybraného řádku
   **/
  private _onRowSelected(): void {
    this.editArray.clear();
    this.editForm.reset();
    this.displayEdit = true;
    this.newRow = false;
    if (this.edit)
      this.dto.sloupce.forEach(sloupec => {
        let result = {};
        if (this.dto.sloupce.find(x => x.nazev == sloupec.nazev).isPk) {
          result[sloupec.nazev] = [this._selectedRow[sloupec.nazev], Validators.required];
        } else {
          result[sloupec.nazev] = [this._selectedRow[sloupec.nazev]];
        }
        this.editArray.push(this.formBuilder.group(result));
      }, this);
  }

  /**
  * Handler odvybrání řádk v tabulce
  **/
  public onRowUnselected(): void {
    this._selectedPrevRow = this._selectedRow;
    this.cancel();
  }

  /**
   * Vrátí label editovaného pole. Nazev sloupce nebo popis, pokud existuje
   * @param item - FormGroup editovaného pole
   */
  public getLabel(item: FormGroup): string {
    let nazev = this.getItemNameFromControl(item)
    let popis = this.dto.sloupce.find(x => x.nazev == nazev)?.popis;
    return popis != void 0 ? popis : nazev;
  }

  /**
   * Vrátí typ sloupce podle názvu
   * @param nazev
   */
  public getTypeByNazev(item: FormGroup): CiselnikSloupecTypEnum {
    let nazev = this.getItemNameFromControl(item);
    return this.dto.sloupce.find(x => x.nazev == nazev)?.typ;
  }

  /**
   * Vrátí možnost editace sloupce
   * @param item - FormGroup
   */
  public getEdit(item: FormGroup): boolean {
    let nazev = this.getItemNameFromControl(item);
    let sloupec = this.dto.sloupce.find(x => x.nazev == nazev);
    return this.edit && (sloupec?.canEdit || (sloupec.isPk && this.newRow && sloupec.nazev.indexOf("guid") == -1));
  }

  /**
   * Enum typu sloupce pro použití v html angularu
   */
  public get SloupecTypEnum() {
    return CiselnikSloupecTypEnum;
  }

  /**
   * Uložení upraveného záznamu
   * */
  public save(): void {
    let dtoToSave = this._editArrayToObject();
    if (dtoToSave != void 0) {
      if (this.newRow) {
        this.ciselnikyAdminService.insert(this.guid, dtoToSave).subscribe(res => {
          this._setResult(res);
        });
      } else {
        this.ciselnikyAdminService.save(this.guid, dtoToSave).subscribe(res => {
          this._setResult(res);
        });
      }
    }
  }

  /**
   * Zpracování odpovědi po insertu/updatu
   * @param result
   */
  private _setResult(result: ResultInfoDto): void {
    if (result.success) {
      result.messages = ["Záznam byl uložen."];
      this._loadData(this.guid);
      this.editForm.markAsPristine();//zruší změny ve formuláři, aby nedošlo k vyvolání dialogu při dalším výběru řádku
      this._resetRow();
      this.displayEdit = false;
    }
    this.messageUtils.showResponseMessage(this.CISELNIKY_EDIT_TOAST, result);
  }

  /**
   * Zruší provedené změny  a zavře editaci
   * @param event
   */
  public cancel(event: any = null): void {
    if (this.editForm.dirty) {
      this._displayConfirmDialog(DialogActions.cancel, event?.currentTarget);
    } else {
      this._acceptCancel();
    }
  }

  private _acceptCancel(): void {
    this._resetRow();//zrušit výběr řádku až po potvrzení uživatelem
    this.editArray.clear();
    this.editForm.reset();
  }

  private _editArrayToObject(): object {
    let result: object = {};
    this.editArray.controls.forEach(item => {
      let key = Object.keys((item as FormGroup).controls)[0];
      result[key] = item.get(key).value;
    });
    return result;
  }

  /**
   * Vrátí true, pokud se jedná o text nebo celé číslo a nejedná se o editaci
   * @param nazev
   */
  public isTextOrNumberToDisplay(item: FormGroup): boolean {
    let nazev = this.getItemNameFromControl(item);
    let type = this.dto.sloupce.find(x => x.nazev == nazev)?.typ;
    return (type == CiselnikSloupecTypEnum.CeleCislo || type == CiselnikSloupecTypEnum.Text) && !this.getEdit(item) || this.isPK(item) && this.isFK(item);
  }

  /**
   * Vrátí název modelu číselníku podle názvu vlastnosti. Ta v tomto případě obsahuje vždy guid.
   * @param item - FormGroup editovaného pole, nebo přímo název vlastnosti
   */
  public getCiselnikModel(item: FormGroup | string): string {
    let value = item instanceof FormGroup ? this.getItemNameFromControl(item) : item;
    let result: string;
    if (value.indexOf("_") > -1 && value.indexOf("guid") > -1) {
      let ciselnikPart: string = value.split("_")[0];
      result = CiselnikyService.ModelsTouse.find(x => x.toLowerCase().indexOf(ciselnikPart?.toLowerCase()) > -1)
    }
    return result;
  }

  /**
   * Převede JSON, převede na typ date, kde je potřeba a naplní tabulku
   **/
  private _setTableContent(): void {
    let jsonData: any[] = this.dto?.data != void 0 && this.dto?.data != "" ? JSON.parse(this.dto.data) : null;
    if (jsonData != void 0) {
      jsonData.forEach(row => {
        this.dateCols.forEach(col => {
          let date = Date.parse(row[col]);
          if (!isNaN(date)) row[col] = new Date(date);
        });
      });
      this.items = jsonData;
    } else {
      this.items = [];
    }
  }

  //metody pro filtrování
  public filterPlatnostOd(value, filter): boolean {
    if (filter === undefined || filter === null || value === undefined || value === null) {
      return true;
    }

    return value <= filter;
  }

  public filterPlatnostDo(value, filter): boolean {
    if (filter === undefined || filter === null || value === undefined || value === null) {
      return true;
    }
    return value >= filter;
  }

  public filterNeplatnostOd(value, filter): boolean {
    if (filter === undefined || filter === null || value === undefined || value === null) {
      return true;
    }

    return value > filter;
  }

  public filterNeplatnostDo(value, filter): boolean {
    if (filter === undefined || filter === null || value === undefined || value === null) {
      return true;
    }
    return value < filter;
  }

  public filtruj(): void {
    if (this.platnostFilter != void 0 && this.platnostFilter[0] != void 0 && this.platnostFilter[1] != void 0 && this.platnostMod?.volba) {
      this.ciselnikyTable.dt.filter(this.platnostFilter[0], this.SloupecPlatnostOd, "filterPlatnostOd");
      if (this.platnostFilter[1] != void 0) this.ciselnikyTable.dt.filter(this.platnostFilter[1], this.SloupecPlatnostDo, "filterPlatnostDo");
    } else if (!this.platnostMod?.volba && this.platne) {
      this.ciselnikyTable.dt.filter(new Date(new Date().getFullYear(), 0, 1), this.SloupecPlatnostOd, "filterPlatnostOd");
      this.ciselnikyTable.dt.filter(new Date(new Date().getFullYear(), 12, 0), this.SloupecPlatnostDo, "filterPlatnostDo");
    } else if (!this.platnostMod?.volba && !this.platne) {
      this.ciselnikyTable.dt.filter(new Date(new Date().getFullYear(), 0, 1), this.SloupecPlatnostOd, "filterNeplatnostOd");
      this.ciselnikyTable.dt.filter(new Date(new Date().getFullYear(), 12, 0), this.SloupecPlatnostDo, "filterNeplatnostDo");
    } else {
      this.ciselnikyTable.dt.reset();
    }
  }

  /**
   * Handler přidání nového řádku s confirm dialogem v případě neuložené editace
   * */
  public new(): void {
    this.newRow = true;
    if (this.editForm.dirty) {
      this._displayConfirmDialog(DialogActions.new);
    } else {
      this._new();
    }
  }

  /**
   * Přidání nového řádku
   * */
  private _new(): void {
    this.editArray.clear();
    this.ciselnikyTable.unselectAll();
    this._selectedRow = null;
    this.dto.sloupce.forEach(sloupec => {
      let result = {};
      if (sloupec.isPk && sloupec.nazev.indexOf("guid") > -1) {
        result[sloupec.nazev] = [this.uuidUtils.GenerateUuid(), Validators.required];
      } else if (sloupec.isPk) {
        result[sloupec.nazev] = [null, Validators.required];
      } else {
        result[sloupec.nazev] = [null];
      }
      this.editArray.push(this.formBuilder.group(result));
    }, this);
    this.displayEdit = true;
    this.editForm.markAsPristine();
  }

  /**
   * získání jména vlastnosti pro právě zpracovávaný řádek
   * @param value
   */
  public getItemNameFromControl(value: FormGroup): string {
    return Object.keys(value.controls)[0];
  }

  /**
   * získání hodnoty vlastnosti pro právě zpracovávaný řádek
   * @param value
   */
  public getValueFromControl(value: FormGroup): string {
    let key = Object.keys(value.controls)[0];
    return value.get(key).value;
  }

  /**
   * Promazání filteru tlačítkem
   * */
  public baskSpaceFilterClicked(): void {
    this.platnostFilter = [];
    this.filtruj();
  }

  /**
   *  Vrátí true, pokud zpracovávaný sloupec je FK
   * @param item - FormGroup editovaného pole
   */
  public isFK(item: FormGroup): boolean {
    return this.getTypeByNazev(item) == CiselnikSloupecTypEnum.FK;
  }

  /**
   * Vrátí true, pokud zpracovávaný sloupec je PK
   * @param item - FormGroup editovaného pole
   */
  public isPK(item: FormGroup): boolean {
    let nazev = this.getItemNameFromControl(item);
    return this.dto?.sloupce.find(x => x.nazev == nazev)?.isPk;
  }

  /**
   * Vrátí true, pokud jde do DateTime, false pokud se jedná o Date a null pokud nejde o datový typ
   * @param item - FormGroup editovaného pole
   */
  public isDateTime(item: FormGroup): boolean {
    switch (this.getTypeByNazev(item)) {
      case CiselnikSloupecTypEnum.Datum:
        return false;
        break;
      case CiselnikSloupecTypEnum.DatumCas:
        return true;
        break;
      default:
        return null;
    }
  }

  /**
   * Handler změny filtru období
   * @param e
   */
  public calendarRangeChanged(value: boolean): void {
    this.btnFilterDisabled = !value;
    this.filtruj();
  }

  /**
   * Export do CSV
   **/
  public exportCSV(): void {
    this.ciselnikyTable.dt.exportCSV();
  }

  /**
   * Spustí export do XLSX
   **/
  public exportExcel(): void {
    let data = this.items.map(item => {
      let result = {};
      this.cols.forEach(col => {
        result[col.header] = item[col.field];
      }, this);
      return result;
    });

    this.tableUtils.exportExcel(data, "ciselniky");
  }

  /**
   * Smazání vybraného záznamu
   **/
  public delete(event): void {
    this.confirmationService.confirm({
      target: event.currentTarget,
      key: this.CISELNIKY_EDIT_CONFIRM_POPUP,
      message: 'Skutečně si přejete smazat vybraný záznam?',
      icon: 'pi pi-exclamation-triangle',
      accept: this._deleteAccept.bind(this),
      acceptLabel: 'Smazat',
      acceptButtonStyleClass: 'p-button-danger',
      reject: () => { },
      rejectLabel: 'Zrušit'
    });
  }

  private _deleteAccept(): void {
    let idKey: string;
    let zaznamId = this.editArray.value.find(x => {
      let result = false;
      let key = Object.keys(x)[0];
      if (this.dto?.sloupce.find(x => x.nazev == key)?.isPk) {
        idKey = key;
        result = true;
      }
      return result;
    })[idKey];

    this.ciselnikyAdminService.delete(this.guid, zaznamId).subscribe(res => {
      if (res.success) {
        this.editArray.clear();
        this._resetRow();
        this.editForm.markAsPristine();
        let i = this.items.findIndex(n => n[idKey] == zaznamId);
        this.items.splice(i, 1);
      }

      this.messageUtils.showResponseMessage(this.CISELNIKY_EDIT_TOAST, res);
    });
  }

  /**
   * Handler změny dropdown. Nastaví dirty na false, pokud došlo ke změně vlivem naplnění dropdown číselníku ze serveru bez zásahu uživatelem.
   **/
  public onChangeCiselnikDropdown(): void {
    if (this._pristineOnLoaded) {
      this._pristineOnLoaded = false;
      this.editForm.markAsPristine();
    }
  }
  /**
   * Zobrazí confirm dialog po potvrzení zrušení změn v editaci při zrušení výběru/výběru nového řádku/novém záznamu
   * @param action - Enum akce, ze které je dialog zobrazen
   * @param target - Pokud je null, tak se použije confirm dialog. Pokud ne, tak se použije confirm popup.
   */
  private _displayConfirmDialog(action: DialogActions, target: any = null): void {
    this.confirmationService.confirm({
      target: target,
      key: target != null ? this.CISELNIKY_EDIT_CONFIRM_POPUP : this.CISELNIKY_EDIT_CONFIRM_DIALOG,
      message: 'Opravdu si přejete zahodit provedené změny? Změny nebudou uloženy.',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        if (action == DialogActions.cancel) {
          this._acceptCancel();
        } else if (action == DialogActions.rowClicked) {
          this._onRowSelected();
        } else {
          this._new();
        }
      },
      acceptLabel: 'Zahodit změny',
      acceptButtonStyleClass: 'p-button-danger',
      reject: () => {
        if (this.newRow && this._selectedRow != void 0) this.newRow = false; //Pokud je vybraný řádek, tak se nejedná o nový řádek v případe pokračovat v práci
        if (!this.newRow && this._selectedPrevRow != void 0) {//Vrátit výběr v tabulce pouze pokud se nejedná o nový řádek a zároveň je definovaný předchozí stav
          this.ciselnikyTable.selectedRows = this._selectedPrevRow;
          this._selectedRow = this._selectedPrevRow;
        }
      },
      rejectLabel: 'Pokračovat v práci'
    });
  }

  /**
   * Nastaví výběr v tabulce do výchozího stavu
   **/
  private _resetRow(): void {
    this.newRow = false;
    this._selectedRow = null;
    this._selectedPrevRow = null;
    this.ciselnikyTable.selectedRows = null;
  }
}

/**
 * enum akcí, pro které je použit dialog změny
 **/
enum DialogActions {
  cancel,
  rowClicked,
  new
}
