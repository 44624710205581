import { Input } from '@angular/core';
import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { faLayerGroup, faTimes, faEllipsisV, faThumbtack, faChevronDown, faChevronUp, faSync, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Sidebar } from 'primeng/sidebar';
import { LayerDefinitionDto } from '../../../Dto/Mapa/LayerDefinitionDto';
import { MapInteractionService } from '../../../Services/Mapa/map-interaction.service';
import { NavUtils } from '../../../Utils/Shared/nav.utils';

/**
 * Komponeta panelu vrstev.
**/
@Component({
  selector: 'app-map-layer-panel',
  templateUrl: './map-layer-panel.component.html',
  styleUrls: ['./map-layer-panel.component.css']
})
export class MapLayerPanelComponent {

  @ViewChild('layersSideBar') layersSideBar: Sidebar;
  @Output() visibleLayersChanged: EventEmitter<string[]> = new EventEmitter<string[]>();

  @Input() layerSources: LayerDefinitionDto[];

  /**
   * Příznak deaktivace tlačítka pro obnovení mapy
   */
  reloadDisabled: boolean = false;

  /**
   * Příznak probíhajícího načítání vrstev
   */
  @Input() set loadingLayers(value: boolean) {
    this._loadingLayers = value;
    this.showPanelIcon = value ? this._faSpinner : this.faLayerGroup;
  }

  _loadingLayers: boolean = false;

  /**
   * Aktivní ikona tlačítka (přepíná se podle stavu načítání)
   */
  showPanelIcon = faLayerGroup;

  layersVisible: boolean = false;
  docked: boolean = false;
  faLayerGroup = faLayerGroup;
  faTimes = faTimes;
  faEllipsisV = faEllipsisV;
  faThumbtack = faThumbtack;
  faChevronDown = faChevronDown;
  faChevronUp = faChevronUp;
  faSync = faSync;
  private _faSpinner = faSpinner;
  panelWidth: string = '24rem';
  checked: boolean;

  constructor(
    private navUtils: NavUtils,
    private mapInteractionService: MapInteractionService)
  {
    // deaktivace tlačítka obnovení mapy podle stavu kreslení
    this.mapInteractionService.DeactivateButtons.subscribe(deactivate => this.reloadDisabled = deactivate);
  }

  /**
   * Handler kliknutí na tlačítka zadokováni/uvolnění panelu vrstev.
  **/
  dock(): void {
    this.docked = !this.docked;
    if (this.docked) {
      this.layersSideBar.disableModality();
    }
    else {
      this.layersSideBar.enableModality();
      this._setMaskStyle();
    }
  }


  /**
   * Handler zavřední panulu vrstev.
  **/
  close(): void {
    this.layersVisible = false;
    if (this.docked) this.dock();
  }


  /**
   * Handler zobrazení panelu vrstev.
  **/
  onLayersPanelShow(): void {
    this.mapInteractionService.contolOffset({ panel: 'layerPanel', offset: this.panelWidth });
    this._setMaskStyle();
  }


  /**
   * Handler skrytí panelu vrstev.
  **/
  onLayersPanelHide(): void {
    this.mapInteractionService.contolOffset({ panel: 'layerPanel', offset: '0px' });
  }


  /**
   * Nastaveni stylu masky (modální pozadí sidebaru).
  **/
  private _setMaskStyle(): void {
    if (this.layersSideBar.mask) {
      this.layersSideBar.mask.style.background = 'rgba(0, 0, 0, 0)';
      //výška hlavičky aplikace
      if (this.displayNav) this.layersSideBar.mask.style.marginTop = '4.857rem';
    }
  }


  /**
   * Eventa přeposílající seznam viditelných vrstev ze seznamu vrstev do mapy.
   * @param event {string[]} seznam názvů viditelných vrstev
   */
  onVisibleLayersChanged(event): void {
    this.visibleLayersChanged.emit(event);
  }

  /**
   * True, pokud je zobrazena navigace v horní části
   */
  get displayNav(): boolean {
    return this.navUtils.display;
  }

  /**
   * Požadavek na aktualizaci (obnovení) map ze serveru
   */
  reload(): void {
    this.mapInteractionService.reload();
  }
}
