<p-toast position="top-center" key="copy-doklad-edit-toast"></p-toast>
<p-menu #mCopy [popup]="true" [model]="menuCopy" (click)="displayDodavatele=true"></p-menu>
<div [ngClass]="visibleDodavatel?'button-group':''" >
  <button pButton label="Kopie" class="icon-btn" [disabled]="disabled" (click)="copy()">
    <fa-icon [icon]="faCopy"></fa-icon>
  </button>
  <button pButton (click)="mCopy.toggle($event)" [disabled]="disabled" *ngIf="visibleDodavatel" [ngClass]="'icon-btn hide-label-button'">
    <fa-icon [icon]="faAngleDown"></fa-icon>
  </button>
</div>

<p-dialog header="Výber dodavatele" [(visible)]="displayDodavatele" [modal]="true" [style]="{width: '700px'}">
  <div class="p-grid">
    <div class="p-col-2">
      Dodavatel
    </div>
    <div class="p-col-10">
      <app-ciselnik-dropdown ciselnik="CDodavatel" [(ngModel)]="dodavatelGuid"
                             nullable="true" [virtualScroll]="true" [panelStyle]="{'width':'400px'}">
      </app-ciselnik-dropdown>
    </div>
  </div>

    <p-footer>
      <button type="button" pButton icon="pi pi-check" label="Vybrat" (click)="newDodavatelCopy()" [disabled]="dodavatelGuid==null"></button>
      <button type="button" pButton icon="pi pi-times" label="Zavřít" (click)="displayDodavatele=false" class="p-button-secondary"></button>
    </p-footer>
</p-dialog>
