import { AfterViewInit, Component, ElementRef } from '@angular/core';
import { PreviewBase } from '../preview-base';

/**
 * Komponenta pro pro vykreslení náhledu bodu
 **/
@Component({
  selector: 'app-preview-point',
  template: '<canvas width="{{previewSize}}" height="{{previewSize}}">Chyba zobrazení bodu</canvas>'
})
export class PreviewPointComponent extends PreviewBase implements AfterViewInit {

  constructor(public hostElement: ElementRef) {
    super(hostElement);
  }

  ngAfterViewInit(): void {
    this._drawPreview();
  }

  /**
   * Metoda pro vykreslení náhledu bodu
   */
  private _drawPreview() {
    super.drawPreview();
    if (this.initialized) {
      let size = 1;
      //pokud je bod vetší, než je velikost plátna, tak dát na maximum.
      if (((this.projekt.strokeWidth + this.projekt.circleRadius) * 2) > this.previewSize) size = this.previewSize / ((this.projekt.strokeWidth + this.projekt.circleRadius) * 2);
      this.context.fillStyle = this.projekt.circleFillRgba;
      this.context.beginPath();
      this.context.lineWidth = this.projekt.circleStrokeWidth;
      this.context.strokeStyle = this.projekt.circleStrokeRgba;
      this.context.arc(this.previewSize / 2, this.previewSize / 2, this.projekt.circleRadius * size, 0, 2 * Math.PI);
      this.context.stroke();
      this.context.fill();
    }
  }

}
