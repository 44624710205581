import { Component } from '@angular/core';
import { OffsetlInfoDto } from '../../../Dto/Mapa/OffsetlInfoDto';
import { SnappingType } from '../../../Dto/Mapa/SnappingChangeArgs';
import { MapInteractionService } from '../../../Services/Mapa/map-interaction.service';
import { SnapInteractionService } from '../../../Services/Mapa/snap-interaction.service';


/**
 * Komponenta přepínače nastavujího přichytávání.
**/
@Component({
  selector: 'app-map-snap-switch',
  templateUrl: './map-snap-switch.component.html',
  styleUrls: ['./map-snap-switch.component.css']
})
export class MapSnapSwitchComponent
{
  constructor(
    private mapInteractionService: MapInteractionService,
    private snapInteractionService: SnapInteractionService)
  {
    this.mapInteractionService.ControlOffset.subscribe(this._offsetHandler.bind(this));
    this.snapInteractionService.ShowSnapSwitch.subscribe(show => this.visible = show);
    this.snapInteractionService.Activate.subscribe(activate => this.active = activate);
  }

  //viditelnost přepínače
  visible: boolean = false;
  //true, pokud je interakce přichytávání aktivní
  active: boolean = false;
  //true, pokud je přichytávání k lomovým bodům zapnuto
  vertex: boolean = false;
  //true, pokud je přichytávání k hranám zapnuto
  edge: boolean = false;
  //offset tlačítka přepínače od pravého okraje obrazovky
  calculatedOffset: string = '2rem';

  /**
   * Handler zobrazení/skrytí info panelu mapy. Zajistí odsazení tlačítka.
   * @param value {OffsetlInfoDto} informace k odsazeni tlačítka
   */
  private _offsetHandler(value: OffsetlInfoDto): void {
    if (value.panel == 'infoPanel') {
      this.calculatedOffset = 'calc(2rem + ' + value.offset + ')';
    }
  }


  /**
   * Handler kliknutí na tlačítko přepínače přichytávání k lomovým bodům.
  **/
  snapVertex(): void {
    if (this.active) {
      this.vertex = !this.vertex;
      this.snapInteractionService.snapingChanged(SnappingType.Vertex, this.vertex);
    }
  }


  /**
   * Handler kliknutí na tlačítko přepínače přichytávání k hranám.
  **/
  snapEdge(): void {
    if (this.active) {
      this.edge = !this.edge;
      this.snapInteractionService.snapingChanged(SnappingType.Edge, this.edge);
    }
  }
}
