export class DateUtils {
  /**
   * Pomůcka pro naformátování data pro vložení do URL
   * @param datum
   */
  static formatDate(datum: Date): string {
    let parts = [
      datum.getFullYear(),
      ('0' + (datum.getMonth() + 1)).slice(-2),
      ('0' + (datum.getDate())).slice(-2)
    ];
    return parts.join('-');
  }


  /**
   * Pomůcka pro naformátování data a času.
   * @param dateTime
   */
  static formatDateTime(dateTime: Date): string {
    let date = DateUtils.formatDate(dateTime);
    let timeParts = [
      ('0' + (dateTime.getHours())).slice(-2),
      ('0' + (dateTime.getMinutes())).slice(-2),
      ('0' + (dateTime.getSeconds())).slice(-2),
    ]
    let time = timeParts.join(':');
    return date + "T" + time + '.' + dateTime.getMilliseconds();
  }
}
