<p-toast [key]="NEW_DOTAZ_TOAST" position="top-center"></p-toast>
<button *ngIf="edit" pButton type="button" label="Editovat" [disabled]="guid==null" (click)="onEditClick($event)" [ngClass]="'icon-btn'" [queryId]="queryId"><fa-icon [icon]="faPencilAlt"></fa-icon></button>
<button *ngIf="copy" pButton type="button" label="Kopírovat" [disabled]="guid==null" (click)="onCopyClick($event)" [ngClass]="'icon-btn'" ><fa-icon [icon]="faCopy"></fa-icon></button>
<button *ngIf="!edit && !copy" pButton type="button" label="Nový dotaz" (click)="menu.toggle($event)" [ngClass]="'icon-btn'"><fa-icon [icon]="faPlus"></fa-icon></button>
<p-menu #menu [popup]="true" [model]="modelsMenu"></p-menu>

<p-dialog [(visible)]="display" [style]="{'width': '60vw', 'min-width':'900px'}" [modal]="true" (onShow)="onShowDialog()">
  <ng-template pTemplate="header">
    <span class="p-dialog-title">{{popisDialogu}} <app-dotazovac-model-display [modelId]="eqModel"></app-dotazovac-model-display></span>
  </ng-template>
  <div class="p-grid validated-form-container" [formGroup]="form">
    <div [ngClass]="copy ? 'p-col-6' : 'p-col-5'">
      Název
      <input type="text" pInputText formControlName="nazev">
    </div>
    <div [ngClass]="copy ? 'p-col-6' : 'p-col-5'">
      Kategorie
      <app-dotaz-kategorie-input formControlName="kategorie" [nazevDotazu]="form.get('nazev')?.value"></app-dotaz-kategorie-input>
    </div>
    <div *ngIf="!copy" class="p-col-2 distinct">
      Přidat distinct
      <p-checkbox name="addDistinct" formControlName="addDistinct" [binary]="true"></p-checkbox>
    </div>
    <div *ngIf="!copy" class="p-col-12">
      <app-easy-query #eq [modelId]="eqModel" (onChange)="onChangeQuery($event)" [data]="data"></app-easy-query>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <button pButton label="Uložit" class="icon-btn p-button-success" (click)="save()" [disabled]="!form.valid || !changed || !data"><fa-icon [icon]="faSave"></fa-icon></button>
    <button pButton (click)="close()" label="Zavřít" class="icon-btn p-button-secondary p-button-outlined"><fa-icon [icon]="faTimes"></fa-icon></button>
  </ng-template>
</p-dialog>

<app-new-sql-dotaz [(displaySql)]="displaySql" [dotaz]="dotaz" (onSave)="onSaveSqlDotaz($event)"></app-new-sql-dotaz>
