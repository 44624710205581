import { Component, OnInit, ViewChild } from '@angular/core';
import { SkodyServices } from 'src/app/Services/Skody/skody.services';
import { VypocetListDto } from 'src/app/Dto/Skody/VypocetListDto';
import { ObdobiListDto } from 'src/app/Dto/Skody/ObdobiListDto';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { SkodyDetailComponent } from '../skody-detail/skody-detail.component';
import { TableComponent } from '../../Shared/table/table.component';


/**
 * Základní komponenta škod.
**/
@Component({
  selector: 'skody-index',
  templateUrl: './skody-index.component.html',
  styleUrls: ['./skody-index.component.css']
})
export class SkodyIndexComponent implements OnInit {
  @ViewChild('skodyTable') skodyTable: TableComponent;
  @ViewChild('detail') detail: SkodyDetailComponent;
  
  vypocetId: string;

  constructor(
    private skodyServices: SkodyServices
  ) {
  }

  skodyItems: VypocetListDto[];
  skodyCols: any[] = [
    { field: 'druhVypoctu', header: 'Druh', class: 'druh-vypoctu', width: '8%' },
    { field: 'lhc', header: 'LHC', class: 'lhc', width: '12%' },
    { field: 'jprl', header: 'JPRL', class: 'jprl', width: '12%' },
    { field: 'etaz', header: 'Etáž', class: 'etaz', width: '8%' },
    { field: 'drevina', header: 'Dřevina', class: 'drevina', width: '8%' },
    { field: 'plocha', header: 'Plocha (ha)', class: 'plocha', width: '8%' },
    { field: 'vyseSkody', header: 'Výše škody', class: 'vyse-skody', width: '12%' }
  ];
  skodaColDrzitel: any = { field: 'drzitel', header: 'Držitel', class: 'drzitel', width: '16%' };
  skodaColUzivatel: any = { field: 'uzivatel', header: 'Uživatel', class: 'uzivatel', width: '16%' };


  obdobi: ObdobiListDto[];
  selectedObdobi: ObdobiListDto;  
  faPlus = faPlus;
  isNewRow: boolean = false;
  isRowSelected: boolean = false;

  ngOnInit() {
    this.skodyServices.obdobi().subscribe(res => {
      if (res != void 0) {
        this.obdobi = res;
        this.selectedObdobi = this.obdobi[0];
        this.manageTableColumns();
        this.getSkodyDleObdobi(this.selectedObdobi);
      }
    });
  };


  /**
   * Handler zmeny období.
  **/
  obdobiChanged(): void {
    this.manageTableColumns();
    this.getSkodyDleObdobi(this.selectedObdobi);
    this.isNewRow = false;
    this.isRowSelected = false;
    this.detail.clearForm(false);
    this.skodyTable.unselectAll();
  };


  /**
   * Dynamicky přidává/odebírá sloupce držitel a uživatel ze seznamu škod/újem dle typu výpočtu.
  **/
  manageTableColumns(): void {
    if (this.selectedObdobi.typ.startsWith('s')) {
      if (this.skodyCols.indexOf(this.skodaColDrzitel) == -1)
        this.skodyCols[this.skodyCols.length - 1].header = 'Výše škody';
        this.skodyCols.splice(1, 0, this.skodaColDrzitel, this.skodaColUzivatel);
    }
    else {
      if (this.skodyCols.indexOf(this.skodaColDrzitel) != -1) {
        this.skodyCols[this.skodyCols.length - 1].header = 'Výše újmy';
        this.skodyCols.splice(1, 2);
      }
    }
    this.skodyCols = this.skodyCols.slice();
  }


  /**
   * Handler výběru řádku.
  **/
  onRowSelected(event): void {
    this.vypocetId = event.id;
    this.isNewRow = false;
    this.isRowSelected = true;
  };


  /**
   * Handler odvybrání řádku.
  **/
  onRowUnSelected(event): void {
    this.isRowSelected = false;
    this.detail.clearForm(false);
  };


  /**
   * Vrátí seznam řádků škod pro vybrané období.
  **/
  getSkodyDleObdobi(obdobi: ObdobiListDto): void {
    this.skodyServices.index(obdobi.guid).subscribe(res => {
      if (res != void 0 && res.data != void 0) {
        this.skodyItems = res.data;
      }
    });
  };


  /**
   * Vymaže formulář a odvybere vybrané řádky. Uložením formuláře vznikne nový záznam škody.
  **/
  newRow(): void {
    this.isNewRow = true;
    this.isRowSelected = true;
    this.detail.clearForm(true);
    this.skodyTable.unselectAll();
  };


  /**
   * Reload záznamů po uložení změn.
  **/
  skodaSavedHandler() {
    this.getSkodyDleObdobi(this.selectedObdobi);
  }
}
