import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

/**
 * Komponenta pro vrstvení fa ikon. Tj. iconBack na pozadí a iconFront na popředí
 **/
@Component({
  selector: 'app-button-icon-stack',
  templateUrl: './button-icon-stack.component.html',
  styleUrls: ['./button-icon-stack.component.css']
})
export class ButtonIconStackComponent {

  constructor() { }

  /**
   * Ikona na pozadí
   */
  @Input() iconBack: IconDefinition;

  /**
   * Inverzní ikona na popředí
   */
  @Input() iconFront: IconDefinition;

  /**
   * Událost kliknutí na tlačítko
   */
  @Output() click: EventEmitter<any> = new EventEmitter();

  /**
   * False/True pro aktivaci/deaktivaci tlačítka
   */
  @Input() disabled: boolean = true;

  /**
   * Popis tlačítka
   */
  @Input() label: string;

  /**
   * Styl tlačítka
   */
  @Input() class: string;

  /**
   * Handler kliknutí na tlačítko
   **/
  public onClick(): void {
    this.click.emit();
  }
}
