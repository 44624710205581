import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DVyrKontrolaDetailDto } from '../../Dto/Vyroba/DVyrKontrolaDetailDto';
import { ResultInfoGenericDto, ResultInfoDto } from '../../Dto/Shared/ResultInfoDto';
import { DVyrKontrolaListDto } from '../../Dto/vyroba/DVyrKontrolaListDto';

@Injectable({
  providedIn: 'root'
})
export class KontrolyService {
  constructor(
    private http: HttpClient
  ) { }

  private apiKontrolyList: string = 'api/vyroba/KontrolaDokladu/List';
  private apiKontrolyGet: string = 'api/vyroba/KontrolaDokladu/Get';
  private apiKontrolySaveOrUpdate: string = 'api/vyroba/KontrolaDokladu/SaveOrUpdate';
  private apiKontrolyDelete: string = 'api/vyroba/KontrolaDokladu/Delete';

  list(): Observable<ResultInfoGenericDto<DVyrKontrolaListDto[]>> {
    return this.http.get<ResultInfoGenericDto<DVyrKontrolaListDto[]>>(this.apiKontrolyList);
  }

  save(data: DVyrKontrolaDetailDto): Observable<ResultInfoGenericDto<DVyrKontrolaDetailDto>> {
    return this.http.post<ResultInfoGenericDto<DVyrKontrolaDetailDto>>(this.apiKontrolySaveOrUpdate, data, {});
  }

  getByGuid(guid: string): Observable<ResultInfoGenericDto<DVyrKontrolaDetailDto>> {
    return this.http.get<ResultInfoGenericDto<DVyrKontrolaDetailDto>>(this.apiKontrolyGet + '/' + guid);
  }

  /**
   * Smazani kontroly
   * @param guid guid kontroly
   */
  delete(guid: string): Observable<ResultInfoDto> {
    var param = new HttpParams();
    param = param.append('guid', guid);
    return this.http.delete<ResultInfoDto>(this.apiKontrolyDelete, { params: param });
  }
}
