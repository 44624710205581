import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResultInfoDto, ResultInfoGenericDto } from 'src/app/Dto/Shared/ResultInfoDto';
import { DVyrKontrolaListDto } from 'src/app/Dto/vyroba/DVyrKontrolaListDto';
import { KubirovaniDetailDto, KubirovaniVstupDto } from 'src/app/Dto/Vyroba/KubirovaniDetailDto';
import { KubirovaniListDto } from 'src/app/Dto/Vyroba/KubirovaniListDto';
import { OliDetailDto } from 'src/app/Dto/Vyroba/OliDetailDto';
import { OliListDto } from 'src/app/Dto/Vyroba/OliListDto';
import { VmlDetailDto } from 'src/app/Dto/Vyroba/VmlDetailDto';
import { VmlListDto } from 'src/app/Dto/Vyroba/VmlListDto';
import { SkladZdrojDto } from 'src/app/Dto/Vyroba/SkladZdrojDto';
import { KonverzeCdDoVmlTyp } from 'src/app/Components/Vyroba/vyroba/vyroba.component';

@Injectable({
  providedIn: 'root'
})
export class VyrobaService {

  private apiCdSave: string = "api/Kubirovani/Save";
  private apiCdDetail: string = "api/Kubirovani/Detail";
  private apiCdList: string = "api/Kubirovani/List";
  private apiCdNew: string = "api/Kubirovani/New";
  private apiKubiruj: string = "api/Kubirovani/Kubiruj";
  private apiCdCopy: string = "api/Kubirovani/Copy";

  private apiOliDetail: string = "api/Oli/Detail";
  private apiOliList: string = "api/Oli/List";
  private apiOliNew: string = "api/Oli/New";
  private apiOliSave: string = "api/Oli/Save";
  private apiOliCopy: string = "api/Oli/Copy";

  private apiVmlList: string = "api/Vml/List";
  private apiVmlDetail: string = "api/Vml/Detail";
  private apiVmlSave: string = "api/Vml/Save";
  private apiVmlNew: string = "api/Vml/New";
  private apiVmlCopy: string = "api/Vml/Copy";
  private apiVmlSklad: string = "api/Vml/Sklad";

  private apiVmlFromCd: string = "api/Vml/NewFormCd";

  private apiCheck = {
    'VML': 'api/Vml/Check',
    'OL': 'api/Oli/Check',
    'CD': 'api/Kubirovani/Check'
  };

  private apiLock = {
    'VML': 'api/Vml/Lock',
    'OL': 'api/Oli/Lock',
    'CD': 'api/Kubirovani/Lock'
  }

  private apiUnLock = {
    'VML': 'api/Vml/UnLock',
    'OL': 'api/Oli/UnLock',
    'CD': 'api/Kubirovani/UnLock'
  }

  private apiDelete = {
    'VML': 'api/Vml/Delete',
    'OL': 'api/Oli/Delete',
    'CD': 'api/Kubirovani/Delete'
  }


  private apiSaveAndFinish = {
    'VML': 'api/Vml/SaveAndFinish',
    'OL': 'api/Oli/SaveAndFinish',
    'CD': 'api/Kubirovani/SaveAndFinish'
  };

  constructor(
    private http: HttpClient
  ) { }


  /** CD **/
  getCdDetail(guid: string): Observable<KubirovaniDetailDto> {
    return this.http.get<KubirovaniDetailDto>(this.apiCdDetail + "/" + guid);
  }

  getCdList(filter: string): Observable<KubirovaniListDto[]> {
    return this.http.get<KubirovaniListDto[]>(this.apiCdList + "?filter=" + filter);
  }

  saveCd(data: KubirovaniDetailDto): Observable<ResultInfoDto> {
    return this.http.post<ResultInfoDto>(this.apiCdSave, data, {});
  }

  newCd(orgUrguid: string): Observable<ResultInfoGenericDto<string>> {
    let path = orgUrguid != void 0 ? "?orgUrguid=" + orgUrguid : "";
    return this.http.get<ResultInfoGenericDto<string>>(this.apiCdNew + path);
  }

  kubiruj(typ: string, data: KubirovaniVstupDto): Observable<ResultInfoGenericDto<number>> {
    return this.http.post<ResultInfoGenericDto<number>>(this.apiKubiruj + "/" + typ, data, {});
  }

  copyCd(guid: string): Observable<ResultInfoGenericDto<string>> {
    return this.http.get<ResultInfoGenericDto<string>>(this.apiCdCopy + "/" + guid);
  }

  copyCdNovyDodavatel(guid: string, guidDodavatel: string): Observable<ResultInfoGenericDto<string>> {
    return this.http.get<ResultInfoGenericDto<string>>(this.apiCdCopy + "/" + guid + "/" + guidDodavatel);
  }

  /** OLi **/
  getOliDetail(guid: string): Observable<ResultInfoGenericDto<OliDetailDto>> {
    return this.http.get<ResultInfoGenericDto<OliDetailDto>>(this.apiOliDetail + "/" + guid);
  }

  getOliList(filter: string): Observable<ResultInfoGenericDto<OliListDto[]>> {
    return this.http.get<ResultInfoGenericDto<OliListDto[]>>(this.apiOliList + "?filter=" + filter);
  }

  saveOl(data: OliDetailDto): Observable<ResultInfoDto> {
    return this.http.post<ResultInfoDto>(this.apiOliSave, data, {});
  }

  newOl(orgUrguid: string): Observable<ResultInfoGenericDto<string>> {
    let path = orgUrguid != void 0 ? "?orgUrguid=" + orgUrguid : "";
    return this.http.get<ResultInfoGenericDto<string>>(this.apiOliNew + path);
  }

  copyOl(guid: string): Observable<ResultInfoGenericDto<string>> {
    return this.http.get<ResultInfoGenericDto<string>>(this.apiOliCopy + "/" + guid);
  }


  /** VML **/
  getVmlList(filter: string): Observable<ResultInfoGenericDto<VmlListDto[]>> {
    return this.http.get<ResultInfoGenericDto<VmlListDto[]>>(this.apiVmlList + "?filter=" + filter);
  }

  getVmlDetail(guid: string): Observable<ResultInfoGenericDto<VmlDetailDto>> {
    return this.http.get<ResultInfoGenericDto<VmlDetailDto>>(this.apiVmlDetail + '/' + guid);
  }

  saveVml(data: VmlDetailDto): Observable<ResultInfoDto> {
    return this.http.post<ResultInfoDto>(this.apiVmlSave, data, {});
  }

  newVml(orgUrguid: string): Observable<ResultInfoGenericDto<string>> {
    let path = orgUrguid != void 0 ? "?orgUrguid=" + orgUrguid : "";
    return this.http.get<ResultInfoGenericDto<string>>(this.apiVmlNew + path);
  }

  copyVml(guid: string): Observable<ResultInfoGenericDto<string>> {
    return this.http.get<ResultInfoGenericDto<string>>(this.apiVmlCopy + "/" + guid);
  }

  copyVmlNovyDodavatel(guid: string, guidDodavatel: string): Observable<ResultInfoGenericDto<string>> {
    return this.http.get<ResultInfoGenericDto<string>>(this.apiVmlCopy + "/" + guid + "/" + guidDodavatel);
  }

  vmlGetSklad(data: VmlDetailDto): Observable<ResultInfoGenericDto<SkladZdrojDto[]>> {
    return this.http.post<ResultInfoGenericDto<SkladZdrojDto[]>>(this.apiVmlSklad, data, {});
  }

  vmlNewFromCd(metoda: KonverzeCdDoVmlTyp, data: string[]): Observable<ResultInfoGenericDto<string[]>> {
    return this.http.post<ResultInfoGenericDto<string[]>>(this.apiVmlFromCd + "?metoda=" + metoda.toString(), data);
  }

  /**
   * Provede kontrolu dokladů
   * @param guids
   * @param type
   */
  check(guids: string[], type: string): Observable<ResultInfoGenericDto<Array<ResultInfoGenericDto<Array<ResultInfoGenericDto<DVyrKontrolaListDto>>>>>> {
    let path = this.apiCheck[type.toLocaleUpperCase()];
    if (path == void 0) {
      let reuslt = new ResultInfoDto();
      reuslt.success = false;
      reuslt.messages = ["U zvoleného typu dokladu není nadefinována kontrola."];
      return Observable.create(reuslt);
    }
    return this.http.post<ResultInfoGenericDto<Array<ResultInfoGenericDto<Array<ResultInfoGenericDto<DVyrKontrolaListDto>>>>>>(path, guids, {});
  }

  /**
   * Uzamčení dokladů.
   * @param guids seznam guidů dokladů
   * @param type typ dokladu
   */
  lock(guids: string[], type: string): Observable<ResultInfoDto> {
    let api = this.apiLock[type];
    return this.http.post<ResultInfoDto>(api, guids, {});
  }


  /**
   * Odemčení dokladů
   * @param guids seznam guidů dokladů
   * @param type typ dokladu
   */
  unLock(guids: string[], type: string): Observable<ResultInfoDto> {
    let api = this.apiUnLock[type];
    return this.http.post<ResultInfoDto>(api, guids, {});
  }

  /**
   * Smazání dokladů
   * @param guids seznam guidů dokladů
   * @param type typ dokladu
   */
  delete(guids: string[], type: string): Observable<ResultInfoDto> {
    var param = new HttpParams();
    guids.forEach((guid) => {
      param = param.append('guids', guid);
    });
    return this.http.delete<ResultInfoDto>(this.apiDelete[type], { params: param });
  }

  /**
   * Provede uložení a dokončení dokladu
   * @param guids
   * @param type
   */
  saveAndFinish(dto: any, type: string): Observable<ResultInfoGenericDto<Array<ResultInfoGenericDto<Array<ResultInfoGenericDto<DVyrKontrolaListDto>>>>>> {
    let path = this.apiSaveAndFinish[type.toLocaleUpperCase()];
    if (path == void 0) {
      let reuslt = new ResultInfoDto();
      reuslt.success = false;
      reuslt.messages = ["U zvoleného typu dokladu není nadefinováno uložení a dokončení."];
      return Observable.create(reuslt);
    }
    return this.http.post<ResultInfoGenericDto<Array<ResultInfoGenericDto<Array<ResultInfoGenericDto<DVyrKontrolaListDto>>>>>>(path, dto, {});
  }
}
