<div class="p-grid p-align-stretch kniha-detail-form">
  <div class="p-col-1">
    <app-ciselnik-field key="CDrevin" label="Dřevina" [guid]="drevina?.drevinaGuid" variant="short"></app-ciselnik-field>
  </div>
  <div class="p-col-1">
    <app-ciselnik-field key="CPuvDr" [guid]="drevina?.drPuvodGuid" label="Původ" variant="short"></app-ciselnik-field>
  </div>
  <div class="p-col-1">
    <app-ciselnik-field key="CZdrRepM" [guid]="drevina?.zdrRepGuid" label="Zdroj RM" variant="short" tooltip="Zdroj reprodukčního materiálu"></app-ciselnik-field>
  </div>
  <div class="p-col-1">
    <app-label-input-display [value]="drevina?.zast" label="Zast. %" tooltip="Zastoupení %" [numDecPlaces]="0"></app-label-input-display>
  </div>
  <div class="p-col-1">
    <app-label-input-display [value]="drevina?.tloustka" label="Tloušťka" [numDecPlaces]="0"></app-label-input-display>
  </div>
  <div class="p-col-1">
    <app-label-input-display [value]="drevina?.vyska" label="Výška" [numDecPlaces]="0"></app-label-input-display>
  </div>
  <div class="p-col-1">
    <app-label-input-display [value]="drevina?.bonR" label="Bonita R" [numDecPlaces]="0" tooltip="Bonita relativní"></app-label-input-display>
  </div>
  <div class="p-col-1">
    <app-label-input-display [value]="drevina?.bonA" label="Bonita A" [numDecPlaces]="0" tooltip="Bonita absolutní"></app-label-input-display>
  </div>
  <div class="p-col-1">
    <app-ciselnik-field key="CGenKlas" [guid]="drevina?.genKlasGuid" label="Fen. klas." variant="short" tooltip="Fenotypová klasifikace"></app-ciselnik-field>
  </div>
  <div class="p-col-1">
    <app-label-input-display [value]="drevina?.drZasTab" label="Zás. tab." tooltip="Tabulková zásoba" [numDecPlaces]="0"></app-label-input-display>
  </div>
  <div class="p-col-1">
    <app-label-input-display [value]="drevina?.drZasHa" label="Zás. ha" tooltip="Zásoba na 1 ha" [numDecPlaces]="0"></app-label-input-display>
  </div>
  <div class="p-col-1">
    <app-label-input-display [value]="drevina?.drZasCel" label="Zás. celk." tooltip="Celková zásoba" [numDecPlaces]="0"></app-label-input-display>
  </div>

  <div class="p-col-1">
    <app-label-input-display [value]="drevina?.drCbp" label="CBP" [numDecPlaces]="0" tooltip="Celkový běžný přírůst"></app-label-input-display>
  </div>
  <div class="p-col-1">
    <app-label-input-display [value]="drevina?.drCpp" label="CPP" [numDecPlaces]="0" tooltip="Celkový průměrný přírůst"></app-label-input-display>
  </div>
  <div class="p-col-1">
    <app-label-input-display [value]="drevina?.drPmp" label="PMP" [numDecPlaces]="0" tooltip="Průměrný mýtný přírůst"></app-label-input-display>
  </div>
  <div class="p-col-1">
    <app-label-input-display [value]="drevina?.hmot" label="Hmot." tooltip="Hmotnatost"></app-label-input-display>
  </div>
  <div class="p-col-1">
    <app-label-input-display [value]="drevina?.hk" label="Výška NK" tooltip="Výška nasazení koruny"></app-label-input-display>
  </div>
  <div class="p-col-1">
    <app-ciselnik-field key="CImise" [guid]="drevina?.imiseGuid" label="Imise" variant="short"></app-ciselnik-field>
  </div>
  <div class="p-col-1">
    <app-label-input-display [value]="drevina?.drKval" label="Kval." tooltip="Kvalita dřeviny" [numDecPlaces]="0"></app-label-input-display>
  </div>
  <div class="p-col-1">
    <app-label-input-display [value]="drevina?.procSous" label="% souší" tooltip="Procento souší" [numDecPlaces]="0"></app-label-input-display>
  </div>
  <div class="p-col-1">
    <app-label-input-display [value]="drevina?.drTv" label="TV m<sup>3</sup>" tooltip="Objem těžby výchovní" [numDecPlaces]="0"></app-label-input-display>
  </div>
  <div class="p-col-1">
    <app-label-input-display [value]="drevina?.drTo" label="TO m<sup>3</sup>" tooltip="Objem těžby obnovní" [numDecPlaces]="0"></app-label-input-display>
  </div>
  <div class="p-col-1">
    <app-label-input-display [value]="drevina?.drTvyb" label="TVYB m<sup>3</sup>" tooltip="Objem těžby výběrné" [numDecPlaces]="0"></app-label-input-display>
  </div>
  <div class="p-col-1">
    <app-ciselnik-field key="CGenPuvod" [guid]="drevina?.genPuvodGuid" label="Gen. pův." variant="short" tooltip="Genetický původ"></app-ciselnik-field>
  </div>

  <div class="p-col-1">
    <app-label-input-display [value]="drevina?.rsKlon" label="RS/Klon" [numDecPlaces]="0" tooltip="Rodičovský strom/klon"></app-label-input-display>
  </div>
  <div class="p-col-11">
    <p-table [value]="drevina?.drevinPoskozs" styleClass="p-datatable-gridlines p-datatable-striped pds-table-compact p-mt-3">
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 18.2%">Druh poškození</th>
          <th>Popis</th>
          <th style="width: 18.2%">Rozsah (10%)</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-poskoz>
        <tr>
          <td>{{poskoz.poskozD}}</td>
          <td>{{poskoz.popis}}</td>
          <td>{{poskoz.poskozR}}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <div class="p-col-12">
    <span>Genetický původ - oblast</span>
    <div class="p-inputtext ui-corner-all two-row overflow-scroll">{{drevina?.genPuvodOblast}}</div>
  </div>

</div>
