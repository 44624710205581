import { Component, Input } from '@angular/core';
import { CLhcMajCiselnikListDto } from '../../../Dto/Lhp/CLhcMajCiselnikListDto';
import { PorDetailDto } from '../../../Dto/Lhp/PorDetailDto';
import { CiselnikyService } from '../../../Services/Shared/ciselniky.service'

@Component({
  selector: 'app-kniha-detail-por',
  templateUrl: './kniha-detail-por.component.html',
  styleUrls: ['./kniha-detail-por.component.css']
})
export class KnihaDetailPorComponent {

  constructor
  (
    private ciselnikyService: CiselnikyService
  )
  {
  }

  @Input() set porost(value: PorDetailDto) {
    this._porost = value;
    this._updateMaj();
  }

  get porost(): PorDetailDto {
    return this._porost;
  }

  maj: CLhcMajCiselnikListDto;

  private _porost: PorDetailDto;

  /**
   * Updatuje maj při každé změně porostu
  */
  private _updateMaj() {
    if (this._porost != undefined) {
      this.ciselnikyService.getCislenik("CLhcMaj", null).subscribe(res => {
        this.maj = (res.find(x => x.guid == this._porost.majGuid) as any) as CLhcMajCiselnikListDto;
      });
    }
  }
}
