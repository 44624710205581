import { MessageService } from 'primeng/api';
import { ResultInfoDto } from 'src/app/Dto/Shared/ResultInfoDto';
import { Injectable } from '@angular/core';

/**
 * Pomocná třída pro vypsání zpráv z ResultInfoDto.
**/
@Injectable({
  providedIn: 'root'
})
export class MessagesUtils {
  constructor(
    private messageService: MessageService,
  ) { }

  /**
   * Nastavení času zobrazení toastu v milisekundách
   **/
  public static TOAST_LIFE: number = 4500;


  /**
   * Vypsání zpráv z ResultInfoDto.
   * @param key {string} key message komponenty, kam se má zpráva vypsat
   * @param resultInfoDto {ResultInfoDto} dto s odpovědí na response
   */
  showResponseMessage(key: string, resultInfoDto: ResultInfoDto): void {
    if (resultInfoDto.success) {
      this.messageService.add({ key: key, severity: 'success', summary: 'Úspěch', detail: resultInfoDto.messages[0], life: MessagesUtils.TOAST_LIFE });
    }
    else {
      for (var i = 0; i < resultInfoDto.messages.length; i++) {
        this.messageService.add({ key: key, severity: 'error', summary: 'Chyba', detail: resultInfoDto.messages[i], life: MessagesUtils.TOAST_LIFE });
      }
    }
  };
}
