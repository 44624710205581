<p-table [value]="audioSources" styleClass="p-datatable-sm">
  <ng-template pTemplate="header">
    <tr>
      <th class="dat-time-col">Datum a čas</th>
      <th>Přehrávač</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-item>
    <tr>
      <td>{{item.nazev}}</td>
      <td>
        <audio controls style="margin-top:0.3rem;">
          <source [src]="item.src" />
        </audio>
      </td>
    </tr>
  </ng-template>
</p-table>
