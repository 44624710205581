import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { faEllipsisV, faBackspace, faUndo } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { ProvozniDenikListDto } from 'src/app/Dto/Vyroba/ProvozniDenikListDto';
import { ProvozniDenikService } from 'src/app/Services/Vyroba/provozni-denik.service';

@Component({
  selector: 'app-provozni-detail',
  templateUrl: './provozni-denik.component.html',
  styleUrls: ['./provozni-denik.component.css']
})
export class ProvozniDenikComponent implements OnInit {

  @ViewChild("colDatum", { static: true }) datumTemplate: TemplateRef<any>;

  constructor(
    private router: Router,
    private pdService: ProvozniDenikService
  ) { }

  block: boolean
  faEllipsisV = faEllipsisV;
  faBackspace = faBackspace;
  faUndo = faUndo;
  filter: string;
  cols: any[] = [];
  items: ProvozniDenikListDto[] = [];
  selectedGuid: string;

  ngOnInit(): void {
    this.cols = [
      { field: 'projektNazev', header: 'Název projektu', class: 'col-30' },
      { field: 'plocha', header: 'Plocha', class: 'col-10' },
      { field: 'popis', header: 'Popis', class: 'col-10' },
      { field: 'poznamka', header: 'Poznámka', class: 'col-10' },
      { field: 'vlozil', header: 'Vložil', class: 'col-10' },
      { field: 'vlozilKdy', header: 'Vloženo kdy', class: 'col-10', template: this.datumTemplate },
      { field: 'opravil', header: 'Opravil', class: 'col-10' },
      { field: 'opravilKdy', header: 'Opraveno kdy', class: 'col-10', template: this.datumTemplate }
    ];

    this.block = true;
    this.pdService.list().subscribe(resp => {
      this.block = false;
      this.items = resp;
    });
  }


  /**
   * Handler výběru řádku.
   * @param event
   */
  onRowSelected(event) {
    this.selectedGuid = event.guid;
  }


  /**
   * Handler odvybrání řádku.
   * @param event
   */
  onRowUnSelected(event) {
    this.selectedGuid = null;
  }


  /**
   * Návrat zpět na výrobu.
  **/
  back(): void {
    this.router.navigate(["/plocha"]);
  }
}
