import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { IPriloha } from '../../../Dto/Nacrty/IPriloha';
import { NacrtPrilohyChangedArgs } from '../../../Dto/Nacrty/NacrtPrilohyChangedArgs';
import { NacrtyInteractionService } from '../../../Services/Nacrty/nacrty-interaction.service';
import { KeyCode } from '../../../Utils/Shared/key-codes';

/**
 * Komponenta pro zobrazení fotografií náčrtu.
**/
@Component({
  selector: 'app-nacrt-priloha-foto',
  templateUrl: './nacrt-priloha-foto.component.html',
  styleUrls: ['./nacrt-priloha-foto.component.css']
})
export class NacrtPrilohaFotoComponent implements OnInit, OnDestroy
{
  constructor(
    private nacrtyInteractionService: NacrtyInteractionService)
  { }


  ngOnInit() {
    this._onAddSubs = this.nacrtyInteractionService.OnAdd.subscribe(this._procesPrilohy.bind(this));
    this._onInitViewSubs = this.nacrtyInteractionService.OnInitView.subscribe(this._procesPrilohy.bind(this));
    this._onDeleteSubs = this.nacrtyInteractionService.OnDelete.subscribe(this._procesPrilohy.bind(this));
  }


  ngOnDestroy() {
    this._onAddSubs.unsubscribe();
    this._onInitViewSubs.unsubscribe();
    this._onDeleteSubs.unsubscribe();
  }


  private _onAddSubs: Subscription;
  private _onInitViewSubs: Subscription;
  private _onDeleteSubs: Subscription;


  /**
   * Kolekce zobrazených příloh.
  **/
  photoSources: IPriloha[] = [];


  /**
   * Index aktuálně zobrazovaného obrázku v galerii.
  **/
  activeIndex: number = 0;


  /**
   * Zpracování příloh pro zobrazení v galerii.
   * @param args {NacrtPrilohyChangedArgs} argumenty příloh náčrtu
   */
  private _procesPrilohy(args: NacrtPrilohyChangedArgs): void {
    this.photoSources = args.prilohy.filter(x => x.typ == 'FOTO' && !x.sync.d)
      .map(x => { return { nazev: x.souborNazev, src: 'data:image/' + x.souborNazev.split('.')[1] + ';base64,' + x.soubor, id: x.guid }; });
  }


  /**
   * Registrace ovládání pohybu v galerii přes klávesnici.
   * @param event {KeyboardEvent} Událost stisku klávesy
   */
  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key == KeyCode.LEFT_KEY) this.activeIndex = this.activeIndex == 0 ? this.photoSources.length - 1 : this.activeIndex - 1;
    if (event.key == KeyCode.RIGHT_KEY) this.activeIndex = this.activeIndex == this.photoSources.length - 1 ? 0 : this.activeIndex + 1;
  }
}
