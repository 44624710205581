export class KubirovaniVstupDto {
  drevina: string; // pro účely čistě výpočtu - zkratka dřeviny
  delka: number;
  prumer: number;
  trida: number;
  oddenkyKs: number;
  celkemKs: number;
  delkaHrane: number;
  vyskaHrane: number;
  koeficient: number;
}

export class VypocetKubirovaniListDto extends KubirovaniVstupDto {
  guid: string;
  dodavatelGuid: string;
  zaznam: number;
  podvykonGuid: string;
  druhTezbyGuid: string;
  typVypoctuGuid: string;
  drevinaGuid: string;
  sortimentGuid: string;
  mnozstviRucne: number;
  mnozstvi: number;
  cisloHrane: number;
  cislo: number;
  pribVzd: number;
}

export class KubirovaniDetailDto {
  guid: string;
  datum: Date;
  orgUrGuid: string;
  cislo: number;
  vykonGuid: string;
  lokalitaGuid: string;
  lhc: number;
  lhcGuid: string;
  jprl: string;
  etaz: string;
  etazGuid: string;
  poznamka: string;
  druhMzdyGuid: string;
  tarif: number;
  zpusobProvedeniGuid: string;
  vypocty: VypocetKubirovaniListDto[];
  cisloKs: number;
}
