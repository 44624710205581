import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { MessageService } from 'primeng/api';
import { LhcListAdminDto } from '../../../Dto/Lhp/LhcListAdminDto';
import { LhpAdminServices } from '../../../Services/Lhp/lhp-admin.service';
import { MessagesUtils } from '../../../Utils/Shared/messages.utils';

/** Komponenta pro mazání Lhc */
@Component({
  selector: 'app-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.css']
})
export class DeleteComponent{

  constructor
  (
    private lhpAdminServices: LhpAdminServices,
    private messageService: MessageService
  )
  {
  }

  @Input() data: LhcListAdminDto;
  @Output() deleteSuccess = new EventEmitter();

  private readonly MESSAGE_KEY: string = "lhc-list-toast";

  displayDeleteLhc: boolean = false;
  displayDeleteLhcCheck: boolean = false;
  deleteBlock: boolean = false;

  faTrash = faTrash;


  /** Zobrazí dialog pro potvrzení uživatelova rozhodnutí smazat Lhc */
  deleteLhcCheck(): void
  {
    this.displayDeleteLhcCheck = true;
    this.displayDeleteLhc = false;
  }

  /** Při odmítnutí kontroly mazání zavře dialog mazání Lhc i kontrolu */
  onDeleteLhcCheckHide(): void
  {
    this.displayDeleteLhcCheck = false;
    this.displayDeleteLhc = false;
  }

  /** Smaže zvolené Lhc */
  deleteLhc(): void
  {

    this.displayDeleteLhcCheck = false;
    this.displayDeleteLhc = false;
    this.deleteBlock = true;

    this.lhpAdminServices.delete(this.data?.guid)
      .subscribe(res => {

        if (res.success)
        {
          this.deleteBlock = false;
          this.messageService.add({ severity: 'success', summary: 'Smazání LHC proběhlo úspěšně.', key: this.MESSAGE_KEY, life: MessagesUtils.TOAST_LIFE });
          this.deleteSuccess.emit();
        }
        else
        {
          this.messageService.add({ severity: 'error', summary: res.messages[0], key: this.MESSAGE_KEY, life: MessagesUtils.TOAST_LIFE });
          this.deleteBlock = false;
        }
      });
  }
}
